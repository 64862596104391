import {SearchParams} from "../../../utils/SearchParams";
import {SearchParamsField} from "../../../utils/SearchParamsField";
import {SearchQuery} from "../../interface/SearchQuery";

export class PartnerSearchQuery extends SearchQuery {
    status: string = "";
    type: string = "";
    service: string = "";
    owner: string = "";

    withSearchParams(params: SearchParams): PartnerSearchQuery {
        let newQuery = super.withSearchParams(params) as PartnerSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS) || "";
        newQuery.type = params.getString(SearchParamsField.TYPE) || "";
        newQuery.service = params.getString(SearchParamsField.SERVICE) || "";
        newQuery.owner = params.getString(SearchParamsField.OWNER) || "";
        return newQuery;
    }
}
