import {ContentBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentJustify, FlexContentSpacing, InputRadio} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigVendorList} from "../../../api/model/cmp/config/CmpConfigVendorList";
import {CMP_CONFIG_DISPLAY_MODES, CmpConfigVendorListDisplayMode} from "../../../api/model/cmp/config/CmpConfigVendorListDisplayMode";
import {CmpConfigVendorListField} from "../../../api/model/cmp/config/CmpConfigVendorListField";
import {TranslationPortalFile} from "../../../utils/constants";

type CmpConfigurationPurposesDisplayProps = {
    vendorList: CmpConfigVendorList;
    onChange: (vendorList: CmpConfigVendorList) => void;
};

const CmpConfigurationPurposesDisplay: FunctionComponent<CmpConfigurationPurposesDisplayProps> = ({vendorList, onChange}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    const handleChange = (displayMode: CmpConfigVendorListDisplayMode) => {
        const newVendorList = new CmpConfigVendorList(vendorList);
        newVendorList.displayMode = displayMode;
        onChange(newVendorList);
    };

    return (
        <ContentBlock header={{title: {label: textCmpConfigurations("section.purposes_display")}}}>
            <FlexContent
                direction={FlexContentDirection.ROW}
                spacing={FlexContentSpacing.LARGE}
                justify={FlexContentJustify.SPACE_EVENLY}
                cssClass="purposes-display-section"
            >
                {CMP_CONFIG_DISPLAY_MODES.map((mode) =>
                    <FlexContent
                        key={mode}
                        direction={FlexContentDirection.COLUMN}
                        spacing={FlexContentSpacing.SMALL}
                        alignment={FlexContentAlignment.CENTER}
                        cssClass="purposes-display-section__item"
                    >
                        <InputRadio
                            id={`display-mode-${mode.toLowerCase()}`}
                            value={mode}
                            checked={mode === vendorList.displayMode}
                            onChange={() => handleChange(mode)}
                        />
                        <label htmlFor={`display-mode-${mode.toLowerCase()}`}>
                            <img src={`/images/cmp/config/display-mode-${mode.toLowerCase()}.png`} alt=""/>
                        </label>
                        <div className="purposes-display-section__item__title">
                            <span>{textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.VENDORLIST}.${CmpConfigVendorListField.DISPLAY_MODE}.${mode}`)}</span>
                        </div>
                    </FlexContent>
                )}
            </FlexContent>
        </ContentBlock>
    );
};

export default CmpConfigurationPurposesDisplay;
