export enum DistributionField {
    ACTIVE = "active",
    NAME = "name",
    LABEL = "label",
    ON_CREATION = "on_creation",
    VISIBLE_TO_AUDIENCE = "visible_to_audience",
    USED_FOR_CATEGORY = "used_for_category",
    IMAGE_ID = "image_id",
    PLATFORM_NAME = "platform_name",
    DESTINATION_NAME = "destination_name",
    PROPERTIES = "properties",
    UPDATED_AT = "updated_at",
    LINK_COUNT = "link_count"
}
