import {Rest} from "../../common/api/rest/Rest";
import {PartnerBillingInfo} from "../model/partner/PartnerBillingInfo";

export class RestPartnerBillingInfo extends Rest {
    rootPath = "/console-api/partner";

    get(partnerId: number): Promise<PartnerBillingInfo> {
        return this._client.get(`${this.rootPath}/${partnerId}/billing-info`, PartnerBillingInfo) as Promise<PartnerBillingInfo>;
    }

    update(billingInfo: PartnerBillingInfo): Promise<PartnerBillingInfo> {
        return this._client.post(`${this.rootPath}/${billingInfo.partner_id}/billing-info`, billingInfo, PartnerBillingInfo) as Promise<PartnerBillingInfo>;
    }
}
