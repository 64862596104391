export enum PartnerConsentStatus {
    COMPLIANT = "COMPLIANT",
    TAG_FIRST_PAGE = "TAG_FIRST_PAGE",
    NON_COMPLIANT = "NON_COMPLIANT"
}

export const PARTNER_CONSENT_STATUSES = [
    PartnerConsentStatus.COMPLIANT,
    PartnerConsentStatus.TAG_FIRST_PAGE,
    PartnerConsentStatus.NON_COMPLIANT
];
