import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {Action, IconTooltip, TranslationLibFile} from "@sirdata/ui-lib";
import {CategoryLinkSegment} from "../../../api/model/audience/category/CategoryLinkSegment";
import {TagSegmentDataType} from "../index";
import {SegmentDataType} from "../../../api/model/audience/segment/SegmentDataType";

type TagCategoryLinkSegmentRowProps = {
    link: CategoryLinkSegment;
    isHighlighted?: boolean;
    onRemove?: () => void;
};

const TagCategoryLinkSegmentRow: FunctionComponent<TagCategoryLinkSegmentRowProps> = ({link, isHighlighted, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <div className={clsx("element-list__tag-row", {"element-list__tag-row--highlighted": isHighlighted})}>
            <span className="element-list__tag-row__infos">
                <TagSegmentDataType type={SegmentDataType.getByName(link.data_type)}/>
                <span>{link.segment_name} ({link.segment_id})</span>
            </span>
            {onRemove && <IconTooltip icon={Action.REMOVE.icon} text={textCommon(Action.REMOVE.labelKey)} onClick={onRemove}/>}
        </div>
    );
};

export default TagCategoryLinkSegmentRow;
