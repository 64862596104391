import {ApiModel} from "../../../../common/api/model/ApiModel";
import {SegmentDataType} from "../segment/SegmentDataType";
import {Module} from "../../../../utils/Module";

export class CategoryLinkSegment extends ApiModel {
    segment_id: number = 0;
    segment_name: string = "";
    category_id: number = 0;
    data_type: string = SegmentDataType.INTENT.name;
    min_score: number = 0;
    expiration_time: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            segment_id: this.segment_id,
            category_id: this.category_id,
            min_score: this.min_score,
            expiration_time: this.expiration_time
        };
    }

    getRoute(): string {
        return Module.SEGMENTS.buildRoute(this.segment_id);
    }
}
