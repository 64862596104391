import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Status} from "../../../../utils/Status";
import {ContentElement} from "../../../interface/ContentElement";
import {CustomerStorage} from "../storage/CustomerStorage";
import {CustomerCountingColumnFilter} from "./column-filter/CustomerCountingColumnFilter";
import {CustomerCountingTable} from "./CustomerCountingTable";
import {CustomerCountingType} from "./CustomerCountingType";
import {CustomerCountingSegmentFilter} from "./segment-filter/CustomerCountingSegmentFilter";
import {CustomerCountingStorageFilter} from "./storage-filter/CustomerCountingStorageFilter";
import moment from "moment";
import {Module} from "../../../../utils/Module";

export class CustomerCounting extends ApiModel {
    id: number = 0;
    name: string = "";
    creation_ts: string = "";
    last_update_ts: string = "";
    status: string = Status.PENDING.name;
    type: string = CustomerCountingType.SEGMENT;
    error_code: string = "";
    error_message: string = "";
    storage_id: number = 0;
    partner_id: number = 0;
    storage: CustomerStorage = new CustomerStorage();
    table: CustomerCountingTable = new CustomerCountingTable();
    private _dimension_indexes: number[] | null = [];
    private _storage_filter: CustomerCountingStorageFilter[] | null = [];
    private _segment_filter: CustomerCountingSegmentFilter | null = new CustomerCountingSegmentFilter();
    private _column_filter: CustomerCountingColumnFilter[] | null = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get dimension_indexes(): number[] {
        return this._dimension_indexes ? this._dimension_indexes : [];
    }

    set dimension_indexes(dimensionIndexes: number[]) {
        this._dimension_indexes = !!dimensionIndexes.length ? dimensionIndexes : null;
    }

    get storage_filter(): CustomerCountingStorageFilter[] {
        return this._storage_filter ? this._storage_filter : [];
    }

    set storage_filter(storageFilter: CustomerCountingStorageFilter[]) {
        this._storage_filter = !!storageFilter.length ? storageFilter : null;
    }

    get segment_filter(): CustomerCountingSegmentFilter {
        return this._segment_filter ? new CustomerCountingSegmentFilter(this._segment_filter) : new CustomerCountingSegmentFilter();
    }

    set segment_filter(segmentFilter: CustomerCountingSegmentFilter) {
        this._segment_filter = segmentFilter;
    }

    get column_filter(): CustomerCountingColumnFilter[] {
        return this._column_filter ? this._column_filter : [];
    }

    set column_filter(columnFilters: CustomerCountingColumnFilter[]) {
        this._column_filter = !!columnFilters.length ? columnFilters : null;
    }

    getStatus(): Status {
        switch (this.status) {
            case Status.OK.name:
                return Status.OK;
            case Status.ERROR.name:
                return Status.ERROR;
            case Status.PROCESSING.name:
                return Status.PROCESSING;
            case Status.DELETED.name:
                return Status.DELETED;
            default:
                return Status.PENDING;
        }
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            type: this.type,
            error_code: this.error_code,
            error_message: this.error_message,
            dimension_indexes: this.dimension_indexes,
            storage_filter: this.storage_filter.map((it) => it.getJsonParameters()),
            segment_filter: this.segment_filter.getJsonParameters(),
            column_filter: this.column_filter.map((it) => it.getJsonParameters())
        };
    }

    canBeRelaunched(): boolean {
        if ([Status.OK.name, Status.DELETED.name].includes(this.status)) {
            return false;
        }

        if (moment(this.creation_ts).isAfter(moment().add(-30, "minutes"))) {
            return false;
        }

        if (moment(this.creation_ts).isBefore(moment().add(-2, "weeks"))) {
            return false;
        }

        return true;
    }

    getRoute(): string {
        return Module.CUSTOMER_COUNTINGS.buildRoute(this.id);
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        element.status = this.getStatus();
        element.createdAt = new Date(this.creation_ts);
        element.lastUpdated = new Date(this.last_update_ts);
        return element;
    }

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }
}
