export class Language {
    name: string = "";
    flag: string = "";
    static FRENCH: Language = new Language("fr", "france.png");
    static ENGLISH: Language = new Language("en", "united-kingdom.png");

    constructor(name: string, flag: string) {
        this.name = name;
        this.flag = flag;
    }

    static values(): Language[] {
        return [
            Language.FRENCH,
            Language.ENGLISH
        ];
    }

    static getFlagUrlforName(name: string): string | undefined {
        return this.values().find((it) => it.name.toUpperCase() === name.toUpperCase())?.flagUrl;
    }

    get flagUrl(): string {
        return `/common/images/flags/${this.flag}`;
    }
}