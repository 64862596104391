export enum GlobalVendorListField {
    GVL_SPECIFICATION_VERSION = "gvlSpecificationVersion",
    VENDOR_LIST_VERSION = "vendorListVersion",
    TCF_POLICY_VERSION = "tcfPolicyVersion",
    LAST_UPDATED = "lastUpdated",
    PURPOSES = "purposes",
    SPECIAL_PURPOSES = "specialPurposes",
    FEATURES = "features",
    SPECIAL_FEATURES = "specialFeatures",
    STACKS = "stacks",
    VENDORS = "vendors"
}
