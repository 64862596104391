import {CategorizerConfig} from "./CategorizerConfig";
import {CategorizerConfigUrlCategorizationExtraction} from "./CategorizerConfigUrlCategorizationExtraction";

export class CategorizerConfigUrlCategorization extends CategorizerConfig {
    extractions: CategorizerConfigUrlCategorizationExtraction[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            extractions: !this.disabled ? this.extractions : undefined,
            disabled: this.disabled
        };
    }
}
