import {TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import {FC} from "react";
import {useTranslation} from "react-i18next";
import {DataleaksQuestion} from "../../../../api/model/dataleaks/question/DataleaksQuestion";
import {Locale} from "../../../../common/utils/Locale";
import {TranslationPortalFile} from "../../../../utils/constants";
import Tag from "../../tag/Tag";

type DataleaksQuestionRowProps = {
    question: DataleaksQuestion;
};

const DataleaksQuestionRow: FC<DataleaksQuestionRowProps> = ({question}) => {
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);

    return (
        <TableRow onClick={{link: question.getRoute()}}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{question.id}</TableColumn>
            <TableColumn>{question.texts.get(Locale.FRENCH)?.title}</TableColumn>
            <TableColumn>
                {question.trait &&
                    <Tag label={textDataleaksQuestions(`trait.${question.trait}`)} style={TagStyle.PRIMARY_OCEAN}/>
                }
            </TableColumn>
            <TableColumn>
                {question.regulations.map((regulation) =>
                    <Tag key={regulation} label={textDataleaksQuestions(`regulation.${regulation}`)} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                )}
            </TableColumn>
        </TableRow>
    );
};

export default DataleaksQuestionRow;
