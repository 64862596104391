import React, {FunctionComponent} from "react";
import {CodeBlock, CodeBlockLanguage, Color, FieldBlock, FlexContentLayout, FormLayoutColumns, IconTooltip, TableColumn, TableRow, Textarea} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../../utils/constants";
import {CmpLocaleItem} from "../../../../api/model/cmp/translation/CmpLocaleItem";
import {CmpLocaleConfigItem} from "../../../../api/model/cmp/translation/CmpLocaleConfigItem";

type CmpLocaleItemRowProps = {
    item: CmpLocaleItem;
    configItem: CmpLocaleConfigItem;
    onChange: (value: string) => void;
}

const CmpLocaleItemRow: FunctionComponent<CmpLocaleItemRowProps> = ({item, configItem, onChange}) => {
    const {t: textCmpTranslations} = useTranslation(TranslationPortalFile.CMP_TRANSLATIONS);

    return (
        <TableRow>
            <TableColumn>
                <strong>{item.name}</strong>
                {!item.value ?
                    <IconTooltip icon={{name: "warning", colorIcon: Color.WARNING_MAIN}} text={textCmpTranslations("message.required_value")}/> :
                    (!!configItem.tags?.length && item.isInvalid(configItem)) && <IconTooltip icon={{name: "warning", colorIcon: Color.WARNING_MAIN}} text={textCmpTranslations("message.invalid_tags")}/>
                }
            </TableColumn>
            <TableColumn>
                {!!configItem.getHTMLTags().length ?
                    <div style={{width: "100%"}}>
                        <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                            <Textarea
                                value={item.value}
                                rows={configItem.long_text ? 3 : 1}
                                onChange={onChange}
                            />
                            <FieldBlock label={textCmpTranslations("message.value_with_tags")}>
                                <CodeBlock
                                    code={configItem.getHTMLTags().map((it) => it).join("\n")}
                                    language={CodeBlockLanguage.HTML}
                                    wrapLongLines
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </div> :
                    <Textarea
                        value={item.value}
                        rows={configItem.long_text ? 3 : 1}
                        onChange={onChange}
                    />
                }
            </TableColumn>
        </TableRow>
    );
};

export default CmpLocaleItemRow;
