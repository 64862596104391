import {TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Tag} from "../../index";
import {CategoryDomain} from "../../../../api/model/audience/category/panel/CategoryDomain";

type CategoryDomainRowProps = {
    categoryDomain: CategoryDomain;
}

const CategoryDomainRow: FunctionComponent<CategoryDomainRowProps> = ({categoryDomain}) => {
    const {t: textCategoryDomains} = useTranslation(TranslationPortalFile.CATEGORY_DOMAINS);

    return (
        <TableRow>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {categoryDomain.id}
            </TableColumn>
            <TableColumn>
                {categoryDomain.domain}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <Tag label={textCategoryDomains(`device_type.${categoryDomain.device_type}`)} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {categoryDomain.points}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {categoryDomain.expiration_day}
            </TableColumn>
        </TableRow>
    );
};

export default CategoryDomainRow;
