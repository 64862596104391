export class CmpUIVersion {
    static GDPR_V3: CmpUIVersion = new CmpUIVersion("gdpr-v3", "GDPR v3");
    static CCPA_V3: CmpUIVersion = new CmpUIVersion("ccpa-v3", "CCPA v3");

    name: string;
    label: string;

    private constructor(name: string, label: string) {
        this.name = name;
        this.label = label;
    }

    static values(): CmpUIVersion[] {
        return [
            CmpUIVersion.GDPR_V3,
            CmpUIVersion.CCPA_V3
        ];
    }
}
