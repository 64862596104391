import {FunctionComponent} from "react";
import {Action, ActionsMenu, IconTooltip, TableActionColumn, TableColumn, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {SirdataListPurpose} from "../../../../api/model/cmp/list/sirdata-list/SirdataListPurpose";
import {SirdataListEntityState} from "../../../../api/model/cmp/list/sirdata-list/SirdataListEntityState";
import {RestrictedContent} from "../../../../common/component/widget";
import {CmpSirdataListEntityState} from "../../index";

type CmpSirdataListPurposeRowProps = {
    purpose: SirdataListPurpose;
    state?: SirdataListEntityState;
    onDelete?: () => void;
};

const CmpSirdataListPurposeRow: FunctionComponent<CmpSirdataListPurposeRowProps> = ({purpose, state, onDelete}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <TableRow>
            <TableColumn>
                {purpose.id}
                <CmpSirdataListEntityState state={state}/>
            </TableColumn>
            <TableColumn>
                {purpose.name}
                <IconTooltip
                    icon={{name: "help"}}
                    text={purpose.description}
                />
            </TableColumn>
            <TableActionColumn>
                {state === SirdataListEntityState.ADDED &&
                    <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                        <ActionsMenu
                            iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                            items={[
                                {label: textCommon(Action.DELETE.labelKey), onClick: () => onDelete && onDelete(), critical: true}
                            ]}
                        />
                    </RestrictedContent>
                }
            </TableActionColumn>
        </TableRow>
    );
};

export default CmpSirdataListPurposeRow;
