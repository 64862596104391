import {
    Action,
    Button,
    ButtonSize,
    ButtonStyle,
    FieldBlock,
    FormLayoutRows,
    InputDate,
    InputDateType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NotificationDispatch} from "../../../api/model/notifications/NotificationDispatch";
import {Formatter} from "../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../utils/constants";
import {NotificationDispatchField} from "../../../api/model/notifications/NotificationDispatchField";

export type ModalRescheduleDispatchesProps = {
    onSubmit: (notificationDispatch: NotificationDispatch) => void;
    onClose: () => void;
    active: boolean;
};

const ModalRescheduleDispatches: FunctionComponent<ModalRescheduleDispatchesProps> = ({onSubmit, onClose, active}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);

    const [notificationDispatch, setNotificationDispatch] = useState<NotificationDispatch>(new NotificationDispatch());

    useEffect(() => setNotificationDispatch(new NotificationDispatch()), [active]);

    const handleSubmit = () => {
        onSubmit(new NotificationDispatch({
            [NotificationDispatchField.DISPATCH_DATE]: Formatter.convertDateToUTC(notificationDispatch.dispatch_date)
        }));
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textNotifications("reschedule_dispatches")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows>
                    <FieldBlock label={textNotifications("field.dispatch_date")} tooltip={textNotifications("tooltip.dispatch_date_pending")}>
                        <InputDate
                            type={InputDateType.DATETIME_LOCAL}
                            value={notificationDispatch.dispatch_date}
                            onChange={(value) => setNotificationDispatch((prevState) => new NotificationDispatch({...prevState, [NotificationDispatchField.DISPATCH_DATE]: value}))}
                            required
                        />
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} onClick={handleSubmit} disabled={!notificationDispatch.dispatch_date}>
                    {textCommon(Action.SEND.labelKey)}
                </Button>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalRescheduleDispatches;
