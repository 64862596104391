import {ApiModel} from "../../../../common/api/model/ApiModel";
import {PartnerLicense} from "./PartnerLicense";
import {PartnerLicenseLinkActivation} from "./PartnerLicenseLinkActivation";
import {Status} from "../../../../utils/Status";

export class PartnerLicenseLinkPartner extends ApiModel {
    partner_id: number = 0;
    expires_at: string = "";
    active: boolean = false;
    partner_info?: { [key: string]: object };
    activations: PartnerLicenseLinkActivation[] = [];
    private _license: PartnerLicense = new PartnerLicense();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get license(): PartnerLicense {
        if (!this._license) {
            this._license = new PartnerLicense();
        }
        return this._license;
    }

    set license(license: PartnerLicense | undefined) {
        if (!license) {
            return;
        }
        this._license = new PartnerLicense(license);
    }

    getJsonParameters(): {} {
        return {
            partner_info: this.partner_info
        };
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }
}
