export enum RevenueImportField {
    UUID = "uuid",
    USER = "user",
    NAME = "name",
    COUNT_ROW_IMPORTED = "count_row_imported",
    TOTAL_REVENUE = "total_revenue",
    STATUS = "status",
    ERROR_MESSAGE = "error_message",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
    PLATFORM_ID = "platform_id",
    DATE_TYPE = "date_type",
    PERIOD_START = "period_start",
    PERIOD_END = "period_end"
}
