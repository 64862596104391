import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";
import {RevenueSeatField} from "./RevenueSeatField";
import {RevenueSeatSearchQueryOrder} from "./RevenueSeatSearchQueryOrder";

export enum RevenueSeatSearchParamsField {
    NEW_BUSINESS = "new_business"
}

export class RevenueSeatSearchQuery extends SearchQuery {
    organization_id: number = 0;
    owner_id: number = 0;
    partner_id: number = 0;
    platform: string = "";
    new_business: boolean = false;
    size: number = PAGE_SIZE;
    status: string = "";

    constructor(o?: {}) {
        super();
        this.load(o);
    }

    withSearchParams(params: SearchParams): RevenueSeatSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(RevenueSeatField.UPDATED_AT, true) as RevenueSeatSearchQuery;
        newQuery.organization_id = params.getNumber(SearchParamsField.ORGANIZATION) || 0;
        newQuery.platform = params.getString(SearchParamsField.PLATFORM) || "";
        newQuery.status = params.getString(SearchParamsField.STATUS) || "";
        newQuery.new_business = params.getBoolean(RevenueSeatSearchParamsField.NEW_BUSINESS) || false;
        newQuery.owner_id = params.getNumber(SearchParamsField.OWNER) || 0;
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            organization_id: this.organization_id,
            owner_id: this.owner_id,
            page: this.page,
            partner_id: this.partner_id,
            platform: this.platform,
            search: this.query,
            size: this.size,
            sort_by: this.sortBy,
            sort_order: this.reverseOrder ? RevenueSeatSearchQueryOrder.DESCENDING : RevenueSeatSearchQueryOrder.ASCENDING,
            status: this.status,
            new_business: this.new_business
        };
    }
}
