import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {DataleaksAudit} from "../model/dataleaks/audit/DataleaksAudit";
import {DataleaksAuditSearchQuery} from "../model/dataleaks/audit/DataleaksAuditSearchQuery";
import {DataleaksAuditSearchResult} from "../model/dataleaks/audit/DataleaksAuditSearchResult";

export class RestDataleaksAudit extends Rest implements RestService<DataleaksAudit> {
    rootPath = "/console-api/dataleaks/audit";

    search(searchQuery: DataleaksAuditSearchQuery): Promise<DataleaksAuditSearchResult> {
        return this._client.get(`${this.rootPath}`, DataleaksAuditSearchResult, {queryParams: searchQuery.getJsonParameters()}) as Promise<DataleaksAuditSearchResult>;
    };

    create(audit: DataleaksAudit): Promise<DataleaksAudit> {
        return this._client.post(`${this.rootPath}`, audit, DataleaksAudit) as Promise<DataleaksAudit>;
    }

    get(auditId: number): Promise<DataleaksAudit> {
        return this._client.get(`${this.rootPath}/${auditId}`, DataleaksAudit) as Promise<DataleaksAudit>;
    }

    update(audit: DataleaksAudit): Promise<DataleaksAudit> {
        return this._client.put(`${this.rootPath}/${audit.id}`, audit, DataleaksAudit) as Promise<DataleaksAudit>;
    }

    delete(auditId: number) {
        return this._client.delete(`${this.rootPath}/${auditId}`);
    }
}
