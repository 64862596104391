import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CurrencyType} from "../../currency/Currency";
import {CustomerCounting} from "../counting/CustomerCounting";
import {CustomerOrderTable} from "./CustomerOrderTable";
import {Status} from "../../../../utils/Status";
import {ContentElement} from "../../../interface/ContentElement";
import {CustomerOrderProduct} from "./CustomerOrderProduct";
import {Module} from "../../../../utils/Module";

export class CustomerOrder extends ApiModel {
    id: number = 0;
    status: string = Status.PENDING.name;
    partner_id: number = 0;
    filter_id: number = 0;
    comment: string = "";
    limit: number = 0;
    creation_ts: string = "";
    last_update_ts: string = "";
    ip_address: string = "";
    cost: number | undefined;
    discount: number | undefined;
    discount_reason: string | undefined;
    currency: CurrencyType = CurrencyType.EUR;
    error_code: string | undefined;
    error_message: string | undefined;
    download_date: string | undefined;
    table: CustomerOrderTable = new CustomerOrderTable();
    filter: CustomerCounting = new CustomerCounting();
    organization_id: number | undefined;
    product: string = CustomerOrderProduct.CRM.name;
    data_provider_organization_id?: number;
    seat_id: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            status: this.status,
            cost: this.cost,
            discount: this.discount,
            discount_reason: this.discount_reason,
            currency: this.currency,
            organization_id: this.organization_id,
            product: this.product,
            data_provider_organization_id: this.data_provider_organization_id,
            seat_id: this.seat_id
        };
    }

    getRoute(): string {
        return Module.CUSTOMER_ORDERS.buildRoute(this.id);
    }

    getStatus(): Status {
        switch (this.status) {
            case Status.ACCEPTED.name:
                return Status.ACCEPTED;
            case Status.ERROR.name:
                return Status.ERROR;
            case Status.PROCESSING.name:
                return Status.PROCESSING;
            case Status.WAITING_VALIDATION.name:
                return Status.WAITING_VALIDATION;
            case Status.REJECTED.name:
                return Status.REJECTED;
            case Status.CANCELED.name:
                return Status.CANCELED;
            default:
                return Status.PENDING;
        }
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.comment);
        element.status = this.getStatus();
        element.createdAt = new Date(this.creation_ts);
        element.lastUpdated = new Date(this.last_update_ts);
        return element;
    }
}
