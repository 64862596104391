import {Action, ContentBlock, ContentBlockAction, Paragraph, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Authorization} from "../../../api/model/account/Authorization";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigEntryGroup} from "../../../api/model/categorizer/CategorizerConfigEntryGroup";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigField} from "../../../api/model/categorizer/config/CategorizerConfigField";
import {CategorizerConfigSanitizer} from "../../../api/model/categorizer/config/CategorizerConfigSanitizer";
import {CategorizerConfigSanitizerField} from "../../../api/model/categorizer/config/CategorizerConfigSanitizerField";
import {RestrictedContent} from "../../../common/component/widget";
import {CategorizerConfigEntriesProps} from "../../../utils/categorizer/CategorizerConfigEntriesProps";
import {TranslationPortalFile} from "../../../utils/constants";
import ModalEditCategorizerConfigSanitizer from "../../modal/categorizer/ModalEditCategorizerConfigSanitizer";
import {CategorizerConfigSanitizerRow} from "../../snippet";
import ModalAddCategorizerConfigSanitizer from "../../modal/categorizer/ModalAddCategorizerConfigSanitizer";
import {CategorizerConfigEntryGroupField} from "../../../api/model/categorizer/CategorizerConfigEntryGroupField";

const CategorizerConfigSanitizerEntries: FC<CategorizerConfigEntriesProps<CategorizerConfigSanitizer>> = ({configs, params, onSubmit, onDelete, editable}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const navigate = useNavigate();
    const [activeEditCategorizerConfigEntry, setActiveEditCategorizerConfigEntry] = useState<CategorizerConfigEntry<CategorizerConfigSanitizer>>();
    const [isShowModalAddCategorizerConfigSanitizer, setShowModalAddCategorizerConfigSanitizer] = useState(false);

    const hasConfig = (): boolean => {
        return configs.some((config) => config.match(params));
    };

    const handleClickSanitizerRow = (entry: CategorizerConfigEntry<CategorizerConfigSanitizer>) => {
        if (entry.match(params) || editable) {
            setActiveEditCategorizerConfigEntry(entry);
            return;
        }
        const group = new CategorizerConfigEntryGroup({
            [CategorizerConfigEntryGroupField.DOMAIN]: entry.domain,
            [CategorizerConfigEntryGroupField.HOST]: entry.host,
            [CategorizerConfigEntryGroupField.PATH]: entry.path
        });
        navigate(group.getRoute());
    };

    return (
        <ContentBlock
            header={{
                title: {label: textCategorizer(`config_type.${CategorizerConfigType.SANITIZER.name}`), icon: {name: "cleaning_services", outlined: true}},
                actions: [
                    !hasConfig() &&
                    <RestrictedContent key="add_config_sanitizer" allowedTo={Authorization.CATEGORIZER.update}>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddCategorizerConfigSanitizer(true)}/>
                    </RestrictedContent>
                ]
            }}
        >
            <Paragraph>{textCategorizer(`config_type.description.${CategorizerConfigType.SANITIZER.name}`)}</Paragraph>
            <Table
                columns={[
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.DOMAIN}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.HOST}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.PATH}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 10, label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigField.DISABLED}`), styles: TableColumnStyle.ALIGN_CENTER},
                    {label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigSanitizerField.SANITIZER_VERSION}`), styles: TableColumnStyle.ALIGN_CENTER},
                    {width: 2}
                ]}
            >
                {configs.map((entry) =>
                    <CategorizerConfigSanitizerRow
                        key={entry.id}
                        entry={entry}
                        isEditable={entry.match(params) || !!editable}
                        onClick={() => handleClickSanitizerRow(entry)}
                        onDelete={() => onDelete(entry)}
                    />
                )}
            </Table>
            <ModalAddCategorizerConfigSanitizer
                params={params}
                active={isShowModalAddCategorizerConfigSanitizer}
                onSubmit={() => {
                    onSubmit();
                    setShowModalAddCategorizerConfigSanitizer(false);
                }}
                onClose={() => setShowModalAddCategorizerConfigSanitizer(false)}
            />
            <ModalEditCategorizerConfigSanitizer
                initEntry={activeEditCategorizerConfigEntry}
                onSubmit={() => {
                    onSubmit();
                    setActiveEditCategorizerConfigEntry(undefined);
                }}
                onClose={() => setActiveEditCategorizerConfigEntry(undefined)}
            />
        </ContentBlock>
    );
};

export default CategorizerConfigSanitizerEntries;
