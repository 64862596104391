export class CategorizerConfigUrlCategorizationExtractionMode {
    static DOMAIN: CategorizerConfigUrlCategorizationExtractionMode = new CategorizerConfigUrlCategorizationExtractionMode("domain");
    static FULL_PATH: CategorizerConfigUrlCategorizationExtractionMode = new CategorizerConfigUrlCategorizationExtractionMode("full_path");
    static HOST: CategorizerConfigUrlCategorizationExtractionMode = new CategorizerConfigUrlCategorizationExtractionMode("host");
    static LAST_PATH_SEGMENT: CategorizerConfigUrlCategorizationExtractionMode = new CategorizerConfigUrlCategorizationExtractionMode("last_path_segment");
    static QUERY_PARAM: CategorizerConfigUrlCategorizationExtractionMode = new CategorizerConfigUrlCategorizationExtractionMode("query_param");
    static SUBDOMAIN: CategorizerConfigUrlCategorizationExtractionMode = new CategorizerConfigUrlCategorizationExtractionMode("subdomain");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CategorizerConfigUrlCategorizationExtractionMode[] {
        return [
            CategorizerConfigUrlCategorizationExtractionMode.DOMAIN,
            CategorizerConfigUrlCategorizationExtractionMode.FULL_PATH,
            CategorizerConfigUrlCategorizationExtractionMode.HOST,
            CategorizerConfigUrlCategorizationExtractionMode.LAST_PATH_SEGMENT,
            CategorizerConfigUrlCategorizationExtractionMode.QUERY_PARAM,
            CategorizerConfigUrlCategorizationExtractionMode.SUBDOMAIN
        ];
    }

    static getByName(name: string): CategorizerConfigUrlCategorizationExtractionMode | undefined {
        return this.values().find((it) => it.name === name);
    }
}
