import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutRows, FormValidationType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ApiProfile} from "../../../api/model/auth/ApiProfile";
import {TranslationPortalFile} from "../../../utils/constants";
import {ApiProfileField} from "../../../api/model/auth/ApiProfileField";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {useNavigate} from "react-router-dom";
import useAlert from "../../../utils/hooks/useAlert";

export type ModalCreateUserProfileProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateUserProfile: FunctionComponent<ModalCreateUserProfileProps> = ({active, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textAuthorizations} = useTranslation(TranslationPortalFile.AUTHORIZATIONS);
    const [userProfile, setUserProfile] = useState<ApiProfile>(new ApiProfile());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-user-profile";

    useEffect(() => {
        if (active) {
            setUserProfile(new ApiProfile());
        }
    }, [active]);

    const handleChange = (field: ApiProfileField, value: any) => {
        setUserProfile((prevState) => new ApiProfile({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const newUserProfile = await session.restUserProfile.create(userProfile);
            navigate(newUserProfile.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("user profile", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textAuthorizations("create_profile")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textAuthorizations(`field.${ApiProfileField.NAME}`)} required>
                            <InputText
                                value={userProfile.name}
                                onChange={(value) => handleChange(ApiProfileField.NAME, value)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textAuthorizations(`field.${ApiProfileField.DESCRIPTION}`)}>
                            <InputText
                                value={userProfile.description}
                                onChange={(value) => handleChange(ApiProfileField.DESCRIPTION, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateUserProfile;
