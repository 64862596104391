import {
    Action,
    Alert,
    Box,
    BoxProps,
    ContentBlock,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormValidationType,
    InputText,
    InputTextNumber,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    SelectAutocomplete,
    Table,
    TableColumn,
    TableColumnStyle,
    TableRow
} from "@sirdata/ui-lib";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "react-phone-number-input/style.css";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {SearchResult} from "../../api/interface/SearchResult";
import {Authorization} from "../../api/model/account/Authorization";
import {Currency} from "../../api/model/currency/Currency";
import {CustomerOrder} from "../../api/model/customer/order/CustomerOrder";
import {CustomerOrderField} from "../../api/model/customer/order/CustomerOrderField";
import {CustomerOrderProduct} from "../../api/model/customer/order/CustomerOrderProduct";
import {CustomerOrderSeat} from "../../api/model/customer/order/CustomerOrderSeat";
import {CustomerOrderSeatField} from "../../api/model/customer/order/CustomerOrderSeatField";
import {CustomerOrderSegmentStat} from "../../api/model/customer/order/CustomerOrderSegmentStat";
import {PartnerOrganizationType} from "../../api/model/partner/organization/PartnerOrganizationType";
import {Partner} from "../../api/model/partner/Partner";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {ApiService} from "../../api/model/ApiService";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Formatter} from "../../common/utils/Formatter";
import {sortItems} from "../../common/utils/helper";
import ModalConfirmMessage from "../../component/modal/ModalConfirmMessage";
import {MainContentHeader, MainContentHeaderAction, SelectPartner} from "../../component/snippet";
import {PAGE_SIZE, TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";
import {detectChanges} from "../../common/utils/portal";

function CustomerOrdersDetails() {
    const {t: textCustomerOrders} = useTranslation(TranslationPortalFile.CUSTOMER_ORDERS);
    const alert = useAlert();
    const {id} = useParams() as {id: string};
    const [isLoading, setLoading] = useState(true);
    const [isLoadingStats, setLoadingStats] = useState(true);
    const [isShowModalValidate, setShowModalValidate] = useState(false);
    const [isShowModalUpdate, setShowModalUpdate] = useState(false);
    const [isShowModalCancel, setShowModalCancel] = useState(false);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);

    const FORM_ID = "form-edit-customer-order";
    const [order, setOrder] = useState<CustomerOrder>(new CustomerOrder());
    const [initOrder, setInitOrder] = useState<CustomerOrder>(new CustomerOrder());
    const [orderSegmentStats, setOrderSegmentStats] = useState<CustomerOrderSegmentStat[]>([]);
    const [orderSegmentStatSearchResult, setOrderSegmentStatSearchResult] = useState<SearchResult<CustomerOrderSegmentStat>>(new SearchResult(CustomerOrderSegmentStat));
    const [partner, setPartner] = useState<Partner>();
    const [seats, setSeats] = useState<CustomerOrderSeat[]>([]);

    const clearOrderOrganization = useCallback(() => {
        setOrder((prevState) => new CustomerOrder({...prevState, [CustomerOrderField.ORGANIZATION_ID]: 0}));
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const newOrder = await session.restCustomerOrder.get(id);
                setOrder(newOrder);
                setInitOrder(newOrder);

                const newPartner = await session.restPartner.get(newOrder.partner_id);
                setPartner(newPartner);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("order", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }

            try {
                const seats = await session.restCustomerOrder.getSeats();
                setSeats(sortItems(seats, CustomerOrderSeatField.ORGANIZATION_NAME));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("organizations", e.message);
                }
            }

            try {
                const stats = await session.restCustomerOrder.getSegmentStats(id);
                const newOrderSegmentStats = stats.hashes_by_segments;
                setOrderSegmentStats(newOrderSegmentStats);
                setOrderSegmentStatSearchResult(SearchResult.buildFromList(CustomerOrderSegmentStat, newOrderSegmentStats, 0, PAGE_SIZE));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("stats", e.message);
                }
            } finally {
                setLoadingStats(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        if (seats.length && order?.organization_id && !seats.find((it) => it.organization_id === order.organization_id)) {
            clearOrderOrganization();
        }
    }, [seats, order?.organization_id, clearOrderOrganization]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(order, initOrder));
    }, [order, initOrder]);

    const handleChange = (field: CustomerOrderField, value: any) => {
        setOrder((prevState) => new CustomerOrder({...prevState, [field]: value}));
    };

    const handleChangeOrganization = (seat: CustomerOrderSeat) => {
        handleChange(CustomerOrderField.ORGANIZATION_ID, seat.organization_id);
        handleChange(CustomerOrderField.SEAT_ID, seat.id);
    };

    const handleChangeProduct = (name: string) => {
        handleChange(CustomerOrderField.PRODUCT, name);
        handleChange(CustomerOrderField.DATA_PROVIDER_ORGANIZATION_ID, undefined);
    };

    const handleChangePage = (page: number) => {
        setOrderSegmentStatSearchResult(SearchResult.buildFromList(CustomerOrderSegmentStat, orderSegmentStats, page - 1, PAGE_SIZE));
    };

    const handleSave = (e: FormEvent) => {
        e.preventDefault();
        if (order.status === Status.WAITING_VALIDATION.name) {
            setShowModalValidate(true);
        } else {
            setShowModalUpdate(true);
        }
    };

    const handleUpdate = async () => {
        try {
            const updatedOrder = await session.restCustomerOrder.update(order);
            setOrder(updatedOrder);
            setInitOrder(new CustomerOrder(updatedOrder));
            alert.updateWithSuccess("order");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("order", e.message);
            }
        }
    };

    const handleCancel = async () => {
        try {
            order.status = Status.CANCELED.name;
            setOrder(await session.restCustomerOrder.update(order));
            alert.actionWithSuccess("order canceled");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("cancel order", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.CUSTOMER_ORDERS} element={initOrder.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.CUSTOMER_ORDERS.update}>
                    {[Status.WAITING_VALIDATION.name, Status.ACCEPTED.name].includes(order.status) &&
                        <>
                            {order.status === Status.WAITING_VALIDATION.name ?
                                <MainContentHeaderAction action={Action.VALIDATE} form={FORM_ID}/> :
                                <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                            }
                            <MainContentHeaderAction
                                action={new Action(textCustomerOrders("action.cancel_order"), Action.CANCEL.icon)}
                                onClick={() => setShowModalCancel(true)}
                            />
                        </>
                    }
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textCustomerOrders("section.information")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FieldBlock label={textCustomerOrders("field.partner")}>
                                                <SelectPartner
                                                    value={partner?.id}
                                                    onChange={undefined}
                                                    service={ApiService.CUSTOMER}
                                                    disabled
                                                />
                                            </FieldBlock>
                                            <FormLayoutRows inline>
                                                <FieldBlock label={textCustomerOrders("field.order")}>
                                                    {order.comment}
                                                </FieldBlock>
                                                <FieldBlock label={textCustomerOrders("field.counting")}>
                                                    {order.filter.name}
                                                </FieldBlock>
                                                <FieldBlock label={textCustomerOrders("field.storage")}>
                                                    {order.filter.storage.name}
                                                </FieldBlock>
                                                <FieldBlock label={textCustomerOrders("field.creation_ts")}>
                                                    {Formatter.formatDate(order.creation_ts, Formatter.DATETIME_FORMAT)}
                                                </FieldBlock>
                                                <FieldBlock label={textCustomerOrders("field.last_update_date")}>
                                                    {Formatter.formatDate(order.last_update_ts, Formatter.DATETIME_FORMAT)}
                                                </FieldBlock>
                                            </FormLayoutRows>
                                            <FormLayoutSeparator/>
                                            <FieldBlock label={textCustomerOrders("field.unique_contact_total_count")}>
                                                {!!order.table?.row_count ? Formatter.formatNumber(order.table?.row_count) : "-"}
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textCustomerOrders("section.order_billing")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <Alert text={textCustomerOrders("message.prices_mention")}/>
                                            <FormLayoutColumns columns={4}>
                                                <FieldBlock label={textCustomerOrders(`field.${CustomerOrderField.COST}`)}>
                                                    <InputTextNumber
                                                        value={Formatter.convertCentsToUnits(order.cost || 0)}
                                                        min={0}
                                                        onChange={(value) => handleChange(CustomerOrderField.COST, Formatter.convertUnitsToCents(value))}
                                                    />
                                                    <span>{Currency.fromType(order.currency)?.symbol || ""}</span>
                                                </FieldBlock>
                                                <FieldBlock label={textCustomerOrders(`field.${CustomerOrderField.DISCOUNT}`)}>
                                                    <InputTextNumber
                                                        value={Formatter.convertCentsToUnits(order.discount || 0)}
                                                        min={0}
                                                        max={100}
                                                        onChange={(value) => handleChange(CustomerOrderField.DISCOUNT, Formatter.convertUnitsToCents(value))}
                                                    />
                                                    <span>{Currency.fromType(order.currency)?.symbol || ""}</span>
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textCustomerOrders(`field.${CustomerOrderField.DISCOUNT_REASON}`)}>
                                                <InputText
                                                    value={order.discount_reason}
                                                    onChange={(value) => handleChange(CustomerOrderField.DISCOUNT_REASON, value)}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textCustomerOrders(`field.${CustomerOrderField.ORGANIZATION_ID}`)} required>
                                                <SelectAutocomplete
                                                    value={order.organization_id}
                                                    options={seats.map((seat) => ({value: seat.organization_id, label: seat.organization_name, seat: seat}))}
                                                    onChange={(option) => handleChangeOrganization(option?.seat)}
                                                    clearable
                                                />
                                            </FieldBlock>
                                            <FormLayoutColumns columns={2}>
                                                <FieldBlock label={textCustomerOrders(`field.${CustomerOrderField.PRODUCT}.label`)} required>
                                                    <Select
                                                        value={order.product}
                                                        options={CustomerOrderProduct.values().map((product) => ({
                                                            label: textCustomerOrders(`field.${CustomerOrderField.PRODUCT}.${product.name}`),
                                                            value: product.name
                                                        }))}
                                                        onChange={(option) => handleChangeProduct(option?.value as string)}
                                                    />
                                                </FieldBlock>
                                                {order.product === CustomerOrderProduct.ONBOARDING.name &&
                                                    <FieldBlock label={textCustomerOrders(`field.${CustomerOrderField.DATA_PROVIDER_ORGANIZATION_ID}`)} required>
                                                        <SelectAutocomplete
                                                            value={order.data_provider_organization_id}
                                                            options={
                                                                seats.filter((it) => it.organization_type === PartnerOrganizationType.DATA_PROVIDER.name)
                                                                    .map((it) => ({value: it.organization_id, label: it.organization_name}))
                                                            }
                                                            onChange={(option) => handleChange(CustomerOrderField.DATA_PROVIDER_ORGANIZATION_ID, option?.value)}
                                                            clearable
                                                        />
                                                    </FieldBlock>
                                                }
                                            </FormLayoutColumns>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                        </LayoutColumns>
                        <Loadable loading={isLoadingStats}>
                            {!!orderSegmentStats?.length &&
                                <ContentBlock header={{title: {label: textCustomerOrders("section.unique_contact_count_per_segment")}}}>
                                    <Table
                                        columns={[
                                            {width: 10, label: textCustomerOrders("field.segment_id"), styles: TableColumnStyle.ALIGN_CENTER},
                                            {width: 75, label: textCustomerOrders("field.segment_name")},
                                            {width: 15, label: textCustomerOrders("field.unique_contact_count"), styles: TableColumnStyle.ALIGN_CENTER}
                                        ]}
                                        pagination={orderSegmentStatSearchResult.getPagination(handleChangePage)}
                                    >
                                        {orderSegmentStats.map((value) => (
                                            <TableRow key={value.segment_id}>
                                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{value.segment_id}</TableColumn>
                                                <TableColumn>{value.segment_name}</TableColumn>
                                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{Formatter.formatNumber(value.count)}</TableColumn>
                                            </TableRow>
                                        ))}
                                    </Table>
                                </ContentBlock>
                            }
                        </Loadable>
                    </LayoutRows>
                </Form>
                <ModalConfirmMessage
                    active={isShowModalValidate}
                    message={textCustomerOrders("message.confirm_validate", {cost: `${Formatter.convertCentsToUnits(order.cost || 0)} ${Currency.fromType(order.currency)?.symbol}`})}
                    confirm={() => {
                        handleUpdate();
                        setShowModalValidate(false);
                    }}
                    confirmAction={Action.VALIDATE}
                    cancel={() => setShowModalValidate(false)}
                />
                <ModalConfirmMessage
                    active={isShowModalUpdate}
                    message={textCustomerOrders("message.confirm_update", {cost: `${Formatter.convertCentsToUnits(order.cost || 0)} ${Currency.fromType(order.currency)?.symbol}`})}
                    confirm={() => {
                        handleUpdate();
                        setShowModalUpdate(false);
                    }}
                    confirmAction={textCustomerOrders("action.update")}
                    cancel={() => setShowModalUpdate(false)}
                />
                <ModalConfirmMessage
                    active={isShowModalCancel}
                    message={textCustomerOrders("message.confirm_cancel")}
                    confirm={() => {
                        handleCancel();
                        setShowModalCancel(false);
                    }}
                    confirmAction={textCustomerOrders("action.cancel_order")}
                    cancel={() => setShowModalCancel(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default CustomerOrdersDetails;
