import React, {FunctionComponent} from "react";
import {CustomerOrder} from "../../../../api/model/customer/order/CustomerOrder";
import {TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import {Formatter} from "../../../../common/utils/Formatter";
import {LabelPartner, LabelStatus} from "../../index";
import {Currency} from "../../../../api/model/currency/Currency";

type CustomerOrderRowProps = {
    order: CustomerOrder;
}

const CustomerOrderRow: FunctionComponent<CustomerOrderRowProps> = ({order}) => {
    return (
        <TableRow onClick={{link: order.getRoute()}}>
            <TableColumn>
                <LabelStatus status={order.getStatus()}/>
            </TableColumn>
            <TableColumn>{order.id}</TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{order.comment}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}><LabelPartner partner={order.partner_id}/></TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{order.organization_id || "-"}</TableColumn>
            <TableColumn styles={[TableColumnStyle.ALIGN_CENTER, TableColumnStyle.HIDE_SCREEN_MEDIUM]}>{order.filter_id}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{Formatter.formatDate(order.creation_ts)}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{!!order.table?.row_count ? Formatter.formatNumber(order.table?.row_count) : "-"}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{Formatter.convertCentsToUnits(order.cost || 0)}{Currency.fromType(order.currency)?.symbol || ""}</TableColumn>
        </TableRow>
    );
};

export default CustomerOrderRow;
