import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputDate, InputDateType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {PartnerLicenseLinkPartner} from "../../../api/model/partner/license/PartnerLicenseLinkPartner";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectStatus} from "../../snippet";
import {PartnerLicenseLinkPartnerField} from "../../../api/model/partner/license/PartnerLicenseLinkPartnerField";
import useAlert from "../../../utils/hooks/useAlert";
import {PartnerLicenseField} from "../../../api/model/partner/license/PartnerLicenseField";
import {PartnerLicense} from "../../../api/model/partner/license/PartnerLicense";
import {Status} from "../../../utils/Status";

export type ModalAddPartnerLinkLicenseProps = {
    active: boolean;
    partnerId: number;
    alreadyLinkedIds: number[];
    onClose: (refresh: boolean) => void;
};

const ModalAddPartnerLinkLicense: FunctionComponent<ModalAddPartnerLinkLicenseProps> = ({active, partnerId, alreadyLinkedIds, onClose}) => {
    const alert = useAlert();
    const {t: textLicenses} = useTranslation(TranslationPortalFile.LICENSES);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [linkPartner, setLinkPartner] = useState<PartnerLicenseLinkPartner>(new PartnerLicenseLinkPartner());
    const [availableLicenses, setAvailableLicenses] = useState<PartnerLicense[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-add-partner-link-license";

    useEffect(() => {
        if (active) {
            setLinkPartner(new PartnerLicenseLinkPartner());
        }
    }, [active]);

    useEffect(() => {
        if (!active || availableLicenses.length) return;
        (async () => {
            try {
                const licenses = await session.restPartnerLicense.list();
                setAvailableLicenses(licenses);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("licenses", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [active, availableLicenses.length, alert]);

    const handleChange = (field: PartnerLicenseLinkPartnerField, value: any) => {
        setLinkPartner((prevState) => new PartnerLicenseLinkPartner({...prevState, [field]: value}));
    };

    const handleChangeLicense = (field: PartnerLicenseField, value: any) => {
        handleChange(PartnerLicenseLinkPartnerField.LICENSE, {...linkPartner.license, [field]: value});
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            linkPartner.partner_id = partnerId;
            await session.restPartnerLicense.addPartnerLink(linkPartner);
            alert.createWithSuccess("license");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("license", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active} loading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle title={textLicenses("modal.link.partner.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock
                            label={textLicenses(`field.link_partner.${PartnerLicenseLinkPartnerField.LICENSE}`)}
                            required
                        >
                            <Select
                                value={linkPartner.license.id}
                                options={availableLicenses.filter((it) => !alreadyLinkedIds.includes(it.id)).map((license) => ({label: license.name, value: license.id}))}
                                onChange={(option) => handleChangeLicense(PartnerLicenseField.ID, option ? +option.value : 0)}
                                clearable
                            />
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textLicenses("field.link_partner.status")}>
                                <SelectStatus
                                    value={linkPartner.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => handleChange(PartnerLicenseLinkPartnerField.ACTIVE, status === Status.ACTIVE)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textLicenses(`field.link_partner.${PartnerLicenseLinkPartnerField.EXPIRES_AT}`)}>
                                <InputDate
                                    type={InputDateType.DATETIME_LOCAL}
                                    value={linkPartner.expires_at}
                                    onChange={(value) => handleChange(PartnerLicenseLinkPartnerField.EXPIRES_AT, value)}
                                    disabled={!linkPartner.active}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddPartnerLinkLicense;
