import {
    ButtonLinkCancel,
    ButtonValidate,
    CodeBlock,
    CodeBlockLanguage,
    Color,
    ContentBlock,
    FieldBlock,
    FlexContentLayout,
    Form,
    FormLayoutColumns,
    FormValidationType,
    IconTooltip,
    LayoutRows,
    Loadable,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    ModalNewSize,
    SearchError,
    SelectAutocomplete,
    Table,
    TableColumn,
    TableRow,
    Textarea
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigText} from "../../../api/model/cmp/config/CmpConfigText";
import {CmpConfigLanguage} from "../../../api/model/cmp/config/CmpConfigLanguage";
import {CmpConfigLanguageToolbar} from "../../snippet";
import {CmpLocaleItemField} from "../../../api/model/cmp/translation/CmpLocaleItemField";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {CmpConfigVersion} from "../../../api/model/cmp/config/CmpConfigVersion";
import useAlert from "../../../utils/hooks/useAlert";
import {CmpLocaleConfig} from "../../../api/model/cmp/translation/CmpLocaleConfig";
import {CmpLocaleItem} from "../../../api/model/cmp/translation/CmpLocaleItem";
import {CmpLocaleConfigItem} from "../../../api/model/cmp/translation/CmpLocaleConfigItem";

type ModalEditPersonalizedTextsProps = {
    active: boolean;
    initValue: Map<string, CmpConfigText>;
    onSubmit: (texts: Map<string, CmpConfigText>) => void;
    onClose: () => void;
};

const ModalEditPersonalizedTexts: FunctionComponent<ModalEditPersonalizedTextsProps> = ({active, initValue, onClose, onSubmit}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const {t: textCmpTranslations} = useTranslation(TranslationPortalFile.CMP_TRANSLATIONS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(false);
    const [localeConfig, setLocaleConfig] = useState<CmpLocaleConfig>(new CmpLocaleConfig());
    const [texts, setTexts] = useState<Map<string, CmpConfigText>>(initValue || new Map<string, CmpConfigText>());
    const [currentLanguage, setCurrentLanguage] = useState<CmpConfigLanguage>(CmpConfigLanguage.FRENCH);
    const [currentLanguageTexts, setCurrentLanguageTexts] = useState<CmpConfigText>(new CmpConfigText());
    const [query, setQuery] = useState("");
    const FORM_ID = "form-edit-personalized-texts";

    useEffect(() => {
        (async () => {
            try {
                const localeConfig = await session.restCmpTranslations.getTemplate(CmpConfigVersion.DEFAULT);
                localeConfig.keys.set("main.customDescription", new CmpLocaleConfigItem());
                setLocaleConfig(localeConfig);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("locale config", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        if (!active) return;

        setTexts(initValue);
        setCurrentLanguage(CmpConfigLanguage.FRENCH);
        setQuery("");
        setLoading(true);
        setTimeout(() => {
            setCurrentLanguageTexts(new CmpConfigText(initValue.get(CmpConfigLanguage.FRENCH.name)));
            setLoading(false);
        }, 100);
    }, [initValue, active]);

    const handleChangeLanguage = (language: CmpConfigLanguage) => {
        setCurrentLanguage(language);
        setLoading(true);
        setTimeout(() => {
            setCurrentLanguageTexts(new CmpConfigText({...texts.get(language.name)}));
            setLoading(false);
        }, 100);
    };

    const handleChange = (key: string, value: string) => {
        const newTexts = new Map(texts);
        const newCurrentLanguageTexts = new CmpConfigText({...currentLanguageTexts, [key]: value});
        if (!value) {
            delete newCurrentLanguageTexts[key];
        }

        if (newCurrentLanguageTexts.entries().length === 0) {
            newTexts.delete(currentLanguage.name);
        } else {
            newTexts.set(currentLanguage.name, newCurrentLanguageTexts);
        }
        setCurrentLanguageTexts(newCurrentLanguageTexts);
        setTexts(newTexts);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const newTexts = new Map(texts);
        onSubmit(newTexts);
    };

    const getDefinedLanguages = (): CmpConfigLanguage[] => {
        return Array.from(texts).reduce((languages: CmpConfigLanguage[], [languageName, cmpConfigText]) => {
            if (cmpConfigText.entries().some((it) => !!it.value)) {
                languages.push(CmpConfigLanguage.getByName(languageName));
            }
            return languages;
        }, []);
    };

    return (
        <ModalNew onClose={onClose} active={active} size={ModalNewSize.FULLSCREEN}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpConfigurations("modal.edit_personalized_texts")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <CmpConfigLanguageToolbar
                            value={currentLanguage}
                            onChange={handleChangeLanguage}
                            highlightedLanguages={getDefinedLanguages()}
                        />
                        <ContentBlock>
                            <SelectAutocomplete
                                placeholder={textCmpTranslations("search.placeholder")}
                                value={query}
                                options={Array.from(localeConfig.keys.entries()).map(([key, _]) => ({value: key, label: key}))}
                                onChange={(option) => setQuery(`${option?.value || ""}`)}
                                clearable
                            />
                            <Loadable loading={isLoading}>
                                {!!Array.from(localeConfig.keys.entries()).filter(([key, _]) => key.includes(query)).length ?
                                    <Table
                                        columns={[
                                            {width: 20, label: textCmpTranslations(`field.${CmpLocaleItemField.NAME}`)},
                                            {width: 80, label: textCmpTranslations(`field.${CmpLocaleItemField.VALUE}`)}
                                        ]}
                                    >
                                        {Array.from(localeConfig.keys.entries()).filter(([key, _]) => key.includes(query)).map(([key, configItem]) => (
                                            <TableRow key={key}>
                                                <TableColumn>
                                                    <strong>{key}</strong>
                                                    {(!!currentLanguageTexts[key] && new CmpLocaleItem(key, currentLanguageTexts[key]).isInvalid(configItem)) &&
                                                        <IconTooltip
                                                            icon={{name: "warning", colorIcon: Color.WARNING_MAIN}}
                                                            text={textCmpTranslations("message.invalid_tags")}
                                                        />
                                                    }
                                                </TableColumn>
                                                <TableColumn>
                                                    {!!configItem.getHTMLTags().length ?
                                                        <div style={{width: "100%"}}>
                                                            <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                                <Textarea
                                                                    value={currentLanguageTexts[key] || ""}
                                                                    rows={configItem.long_text ? 3 : 1}
                                                                    onChange={(value) => handleChange(key, value)}
                                                                />
                                                                <FieldBlock label={textCmpTranslations("message.value_with_tags")}>
                                                                    <CodeBlock
                                                                        code={configItem.getHTMLTags().map((it) => it).join("\n")}
                                                                        language={CodeBlockLanguage.HTML}
                                                                        wrapLongLines
                                                                    />
                                                                </FieldBlock>
                                                            </FormLayoutColumns>
                                                        </div> :
                                                        <Textarea
                                                            value={currentLanguageTexts[key] || ""}
                                                            rows={configItem.long_text ? 3 : 1}
                                                            onChange={(value) => handleChange(key, value)}
                                                        />
                                                    }
                                                </TableColumn>
                                            </TableRow>
                                        ))}
                                    </Table> :
                                    <SearchError query={query}/>
                                }
                            </Loadable>
                        </ContentBlock>
                    </LayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditPersonalizedTexts;
