import {Rest} from "../../common/api/rest/Rest";
import {PortalContent} from "../model/portal-content/PortalContent";
import {PortalContentSearchQuery} from "../model/portal-content/PortalContentSearchQuery";
import {PortalContentSearchResult} from "../model/portal-content/PortalContentSearchResult";

export class RestPortalContent extends Rest {
    rootPath = "/console-api/portal-content";

    search<T>(M: new () => T, params: PortalContentSearchQuery): Promise<PortalContentSearchResult<T>> {
        return this._client.get(`${this.rootPath}`, PortalContentSearchResult, {queryParams: params.getJsonParameters(), newModelParams: M}) as Promise<PortalContentSearchResult<T>>;
    }

    create<T extends PortalContent>(M: new () => T, portalContent: T): Promise<T> {
        return this._client.post(`${this.rootPath}`, portalContent, M) as Promise<T>;
    }

    get<T extends PortalContent>(M: new () => T, id: number): Promise<T> {
        return this._client.get(`${this.rootPath}/${id}`, M) as Promise<T>;
    }

    update<T extends PortalContent>(M: new () => T, portalContent: T): Promise<T> {
        return this._client.put(`${this.rootPath}/${portalContent.id}`, portalContent, M) as Promise<T>;
    }

    delete<T extends PortalContent>(id: number): Promise<T> {
        return this._client.delete(`${this.rootPath}/${id}`) as Promise<T>;
    }
}
