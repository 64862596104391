export enum HelperDomainField {
    ID = "id",
    PARTNER_ID = "partner_id",
    NAME = "name",
    DOMAIN = "domain",
    PRIVACY_POLICY = "privacy_policy",
    GA_TID = "ga_tid",
    TAG = "tag",
    LEGACY_TAG = "legacy_tag",
    TIDS = "tids",
    STATUS = "status",
    PRIVACY_CONFIGURATION = "privacy_configuration"
}
