export enum CmpConfigThemeTextSize {
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    BIG = "BIG"
}

export const CMP_CONFIG_THEME_TEXT_SIZES: CmpConfigThemeTextSize[] = [
    CmpConfigThemeTextSize.SMALL,
    CmpConfigThemeTextSize.MEDIUM,
    CmpConfigThemeTextSize.BIG
];
