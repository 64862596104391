import React, {useEffect, useState} from "react";
import {
    Action,
    Box,
    BoxRadius,
    BoxSpacing,
    ButtonLink,
    ButtonLinkStyle,
    ButtonSize,
    ButtonValidate,
    Checkbox,
    Color,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentJustify,
    FlexContentSpacing,
    FormLayoutRows,
    IconTooltip,
    SearchBarClearable,
    TranslationLibFile,
    TranslationLibKey
} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";

type ItemWithId = {
    id: number;
    [key: string]: any;
}

type SearchItemsProps<T> = {
    items: T[];
    selectedItems: T[];
    searchField: string;
    onSubmit: (items: T[]) => void;
    loading?: boolean;
};

const SearchItems = <T extends ItemWithId>({items, selectedItems, searchField, onSubmit, loading}: SearchItemsProps<T>) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const [query, setQuery] = useState("");
    const [results, setResults] = useState<T[]>([]);
    const [currentSelectedItems, setCurrentSelectedItems] = useState<T[]>([]);
    const [unmatchedIds, setUnmatchedIds] = useState<string[]>([]);
    const [isShowResults, setShowResults] = useState(false);
    const SEPARATOR = ",";

    const resetSearch = () => {
        setQuery("");
        setResults([]);
        setUnmatchedIds([]);
        setShowResults(false);
    };

    useEffect(() => {
        resetSearch();
        setCurrentSelectedItems([]);
    }, [items, selectedItems]);

    const handleSearch = (query: string) => {
        setQuery(query);

        if (!query) {
            resetSearch();
            return;
        }

        let newResults: T[] = [];
        let newUnmatchedIds: string[] = [];

        const searchIds = query.split(SEPARATOR);
        if (searchIds.some((it) => isNaN(+it))) {
            newResults = items.filter((it: T) => `${it.id} ${it[searchField]}`.toLowerCase().includes(query.toLowerCase()));
            setResults(newResults);
            setUnmatchedIds([]);
        } else {
            searchIds.forEach((id) => {
                if (newUnmatchedIds.includes(id) || newResults.some((it) => it.id === +id)) {
                    return;
                }

                const item = items.find((it) => it.id === +id);
                if (item) {
                    newResults.push(item);
                } else {
                    newUnmatchedIds.push(id);
                }
            });
            setResults(newResults);
            setCurrentSelectedItems((prevState) => [...prevState, ...newResults.filter((it) => ![...prevState, ...selectedItems].some((item) => item.id === it.id))]);
            setUnmatchedIds(newUnmatchedIds);
        }

        setShowResults(true);
    };

    const handleToggleItem = (item: T) => {
        if (selectedItems.some((it) => it.id === item.id)) {
            return;
        }

        if (currentSelectedItems.some((it) => it.id === item.id)) {
            setCurrentSelectedItems((prevState) => prevState.filter((it) => it.id !== item.id));
        } else {
            setCurrentSelectedItems((prevState) => [...prevState, item]);
        }
    };

    return (
        <FormLayoutRows spacing={FlexContentSpacing.XSMALL} cssClass="search-items">
            <SearchBarClearable
                value={query}
                onSubmit={handleSearch}
                onChangeCapture={(e) => handleSearch(e.currentTarget.value)}
                placeholder={t("search_items.placeholder")}
                disabled={loading}
            />
            {isShowResults &&
                <Box radius={BoxRadius.MD} spacing={BoxSpacing.NONE} cssClass="search-items__results">
                    <FlexContent direction={FlexContentDirection.COLUMN}>
                        <FlexContent direction={FlexContentDirection.ROW} justify={FlexContentJustify.SPACE_BETWEEN} cssClass="search-items__results__header">
                            <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                <div className="search-items__results__header__label">
                                    <span>{t("search_items.selected_item", {count: currentSelectedItems.length})}</span>
                                    {!!currentSelectedItems.length &&
                                        <IconTooltip
                                            icon={{...Action.CLEAR.icon, cssClass: "search-items__results__header__icon"}}
                                            text={textCommon(Action.CLEAR.labelKey)}
                                            onClick={() => setCurrentSelectedItems([])}
                                        />
                                    }
                                </div>
                                {!!currentSelectedItems.length &&
                                    <ButtonValidate size={ButtonSize.XSMALL} onClick={() => onSubmit(currentSelectedItems)}/>
                                }
                            </FlexContent>
                            <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                {!!unmatchedIds.length &&
                                    <IconTooltip
                                        icon={{name: "warning", outlined: true, colorIcon: Color.WARNING_MAIN, cssClass: "search-items__results__header__icon"}}
                                        text={textCommon(TranslationLibKey.MESSAGE_ID_UNMATCHED, {count: unmatchedIds.length, ids: unmatchedIds.join(`${SEPARATOR} `)})}
                                    />
                                }
                                <ButtonLink style={ButtonLinkStyle.MIDNIGHT_LIGHT} onClick={() => setShowResults(false)}>
                                    {textCommon(Action.CLOSE.labelKey)}
                                </ButtonLink>
                            </FlexContent>
                        </FlexContent>
                        <FlexContent direction={FlexContentDirection.COLUMN} cssClass="search-items__results__list">
                            {!!results.length ?
                                results.map((item) =>
                                    <div key={item.id} onClick={() => handleToggleItem(item)} className="search-items__results__list__item">
                                        <Checkbox
                                            label={`${item.id} - ${item[searchField]}`}
                                            checked={[...currentSelectedItems, ...selectedItems].some((it) => it.id === item.id)}
                                            onChange={() => {}}
                                            cssClass="search-items__results__list__item__checkbox"
                                            disabled={selectedItems.some((it) => it.id === item.id)}
                                        />
                                    </div>
                                ) :
                                <div className="search-items__results__list__item">
                                    <span>{textCommon(TranslationLibKey.MESSAGE_NO_RESULT)}</span>
                                </div>
                            }
                        </FlexContent>
                    </FlexContent>
                </Box>
            }
        </FormLayoutRows>
    );
};

export default SearchItems;
