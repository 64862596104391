import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Select, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {PartnerOrganizationGroupField} from "../../api/model/partner/organization/PartnerOrganizationGroupField";
import {PartnerOrganizationGroup} from "../../api/model/partner/organization/PartnerOrganizationGroup";
import {PartnerOrganizationGroupSearchQuery} from "../../api/model/partner/organization/PartnerOrganizationGroupSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreatePartnerOrganizationGroup from "../../component/modal/organizations/ModalCreatePartnerOrganizationGroup";
import {MainContentHeader, MainContentHeaderAction, PartnerOrganizationGroupRow, SelectUser} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import useSearch from "../../utils/hooks/useSearch";
import useAlert from "../../utils/hooks/useAlert";
import {PartnerOrganizationType} from "../../api/model/partner/organization/PartnerOrganizationType";

function OrganizationGroups() {
    const alert = useAlert();
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const [isLoading, setLoading] = useState(true);
    const [organizationGroups, setOrganizationGroups] = useState<PartnerOrganizationGroup[]>([]);
    const [isShowModalCreatePartnerOrganizationGroup, setShowModalCreatePartnerOrganizationGroup] = useState(false);
    const {buildSearchResult, ...search} = useSearch(PartnerOrganizationGroup, PartnerOrganizationGroupSearchQuery);

    useEffect(() => {
        (async () => {
            try {
                setOrganizationGroups(await session.restPartnerOrganizationGroup.list());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("organization groups", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        let currentOrganizationGroups = (organizationGroups || []);
        if (search.searchQuery.query) {
            currentOrganizationGroups = currentOrganizationGroups.filter(({id, name, description}) => `${id} ${name} ${description}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        if (search.searchQuery.type) {
            currentOrganizationGroups = currentOrganizationGroups.filter((it) => it.type === search.searchQuery.type);
        }
        if (search.searchQuery.owner_id) {
            currentOrganizationGroups = currentOrganizationGroups.filter((it) => it.owner_id === search.searchQuery.owner_id);
        }
        buildSearchResult(currentOrganizationGroups);
    }, [organizationGroups, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.ORGANIZATION_GROUPS}>
                <RestrictedContent allowedTo={Authorization.ORGANIZATIONS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreatePartnerOrganizationGroup(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textOrganizations("search.placeholder_group"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters={true}
                        >
                            <SearchField label={textOrganizations(`field.${PartnerOrganizationGroupField.TYPE}`)}>
                                <Select
                                    value={search.searchQuery.type}
                                    options={PartnerOrganizationType.values().map((type) => ({value: type.name, label: textOrganizations(`type.${type.name}`)}))}
                                    onChange={(option) => search.changeParam(SearchParamsField.TYPE, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textOrganizations(`field.${PartnerOrganizationGroupField.OWNER_ID}`)}>
                                <SelectUser
                                    value={search.searchQuery.owner_id}
                                    onChange={(user) => search.changeParam(SearchParamsField.OWNER, user?.id)}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textOrganizations(`field.${PartnerOrganizationGroupField.ID}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: PartnerOrganizationGroupField.ID}},
                                        {width: 15, label: textOrganizations(`field.${PartnerOrganizationGroupField.TYPE}`), sort: {field: PartnerOrganizationGroupField.TYPE}},
                                        {width: 20, label: textOrganizations(`field.${PartnerOrganizationGroupField.NAME}`), sort: {field: PartnerOrganizationGroupField.NAME}},
                                        {width: 20, label: textOrganizations(`field.${PartnerOrganizationGroupField.DESCRIPTION}`)},
                                        {width: 25, label: textOrganizations(`field.${PartnerOrganizationGroupField.DOMAINS}`)},
                                        {width: 15, label: textOrganizations(`field.${PartnerOrganizationGroupField.OWNER_ID}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((organizationGroup: PartnerOrganizationGroup) =>
                                        <PartnerOrganizationGroupRow key={organizationGroup.id} organizationGroup={organizationGroup}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreatePartnerOrganizationGroup
                    active={isShowModalCreatePartnerOrganizationGroup}
                    onClose={() => setShowModalCreatePartnerOrganizationGroup(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default OrganizationGroups;
