import {Rest} from "../../common/api/rest/Rest";
import {AuthorizationDetails} from "../model/auth/AuthorizationDetails";
import {AuthorizationItems} from "../model/auth/AuthorizationItems";
import {ApiAuthorization} from "../model/auth/ApiAuthorization";

export class RestUserAuthorization extends Rest {
    rootPath = "/console-api/user";

    list(): Promise<ApiAuthorization[]> {
        return this._client.get(`${this.rootPath}/authorizations`, ApiAuthorization) as Promise<ApiAuthorization[]>;
    }

    getDetails(userId: number): Promise<AuthorizationDetails> {
        return this._client.get(`${this.rootPath}/${userId}/authorization`, AuthorizationDetails) as Promise<AuthorizationDetails>;
    }

    addAuthorization(userId: number, value: string) {
        return this._client.post(`${this.rootPath}/${userId}/authorization/${value}`);
    }

    removeAuthorization(userId: number, value: string) {
        return this._client.delete(`${this.rootPath}/${userId}/authorization/${value}`);
    }

    addProfile(userId: number, profileId: number): Promise<AuthorizationItems> {
        return this._client.post(`${this.rootPath}/${userId}/profile/${profileId}`, undefined, AuthorizationItems) as Promise<AuthorizationItems>;
    }

    removeProfile(userId: number, profileId: number) {
        return this._client.delete(`${this.rootPath}/${userId}/profile/${profileId}`);
    }
}
