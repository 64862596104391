export enum CategoryGroupLinkKeywordField {
    ID = "id",
    ID_GROUP_CATEGORY = "id_group_category",
    KEYWORD = "keyword",
    ID_OWNER = "id_owner",
    DESCRIPTION = "description",
    SOURCE = "source",
    THRESHOLD = "threshold",
    LAST_UPDATE = "last_update",
}
