import {Action, ContentBlock, ContentBlockAction, Loadable, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FC, RefObject, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {CategoryBQRequest} from "../../../api/model/audience/category/CategoryBQRequest";
import {CategoryBQRequestField} from "../../../api/model/audience/category/CategoryBQRequestField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {copyApiModelArray, sortItems} from "../../../common/utils/helper";
import {detectChanges} from "../../../common/utils/portal";
import {HandleSaveRef} from "../../../utils/audience/HandleSaveRef";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import ModalAddCategoryBQRequest from "../../modal/categories/ModalAddCategoryBQRequest";
import ModalEditCategoryBQRequest from "../../modal/categories/ModalEditCategoryBQRequest";
import ModalConfirmRemove from "../../modal/ModalConfirmRemove";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryBQRequestRow} from "../index";
import {CategoriesDetailsSubModule} from "../../../screen/categories/CategoriesDetails";

type CategoryBQRequestsProps = {
    category: Category;
    categoryName: string;
    handleSaveRef: RefObject<HandleSaveRef>;
    onChange: (module: CategoriesDetailsSubModule, hasChanges: boolean) => void;
};

const CategoryBQRequests: FC<CategoryBQRequestsProps> = ({category, categoryName, handleSaveRef, onChange}) => {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [requests, setRequests] = useState<CategoryBQRequest[]>([]);
    const [initRequests, setInitRequests] = useState<CategoryBQRequest[]>([]);
    const [isShowModalAddCategoryBQRequest, setShowModalAddCategoryBQRequest] = useState(false);
    const [activeEditCategoryBQRequest, setActiveEditCategoryBQRequest] = useState<CategoryBQRequest>();
    const [activeRemoveCategoryBQRequest, setActiveRemoveCategoryBQRequest] = useState<CategoryBQRequest>();

    useEffect(() => {
        (async function () {
            try {
                const newRequests = await session.restCategoryBQRequest.list(category.id);
                setRequests(newRequests);
                setInitRequests(copyApiModelArray(CategoryBQRequest, newRequests));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("BigQuery requests", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [category.id, alert]);

    useEffect(() => {
        setRequests((prevState) => {
            prevState.forEach((request) => request.active = category.active);
            return prevState;
        });
    }, [category.active]);

    useEffect(() => {
        onChange(CategoriesDetailsSubModule.BQ_REQUESTS, detectChanges(requests, initRequests));
    }, [onChange, requests, initRequests]);

    useImperativeHandle(handleSaveRef, () => ({
        handleSave: async () => {
            setLoading(true);
            try {
                const requestsIds = requests.map((it) => it.id);
                for (const request of initRequests.filter((it) => !requestsIds.includes(it.id))) {
                    await session.restCategoryBQRequest.delete(request);
                }
                const newRequests = [];
                for (const request of requests) {
                    if (request.id) {
                        newRequests.push(await session.restCategoryBQRequest.update(request));
                    } else {
                        newRequests.push(await session.restCategoryBQRequest.create(request));
                    }
                }

                setRequests(sortItems(newRequests, CategoryBQRequestField.ID));
                setInitRequests(copyApiModelArray(CategoryBQRequest, sortItems(newRequests, CategoryBQRequestField.ID)));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToUpdate("BigQuery requests", e.message);
                }
            } finally {
                setLoading(false);
            }
        }
    }));

    const handleAddBQRequest = (request: CategoryBQRequest) => {
        if (requests.some((it) => it.name === request.name)) {
            alert.failTo("add BigQuery request", textCategories("message.bq_request_already_added"));
            return;
        }

        setRequests((prevState) => [...prevState, request]);
        setShowModalAddCategoryBQRequest(false);
    };

    const handleEditBQRequest = (request: CategoryBQRequest) => {
        setRequests((prevState) => {
            const newState = [...prevState];
            newState.forEach((item, index) => {
                if (item.name === activeEditCategoryBQRequest?.name) {
                    newState[index] = request;
                }
            });
            return newState;
        });
        setActiveEditCategoryBQRequest(undefined);
    };

    const handleRemoveBQRequest = () => {
        if (!activeRemoveCategoryBQRequest) return;
        setRequests((prevState) => prevState.filter((it) => it.name !== activeRemoveCategoryBQRequest.name));
        setActiveRemoveCategoryBQRequest(undefined);
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{
                    title: {label: textCategories("section.big_query_sql")}, actions: [
                        <RestrictedContent key="add_category_link_segment" allowedTo={Authorization.CATEGORIES.update}>
                            {category.active &&
                                <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddCategoryBQRequest(true)}/>
                            }
                        </RestrictedContent>
                    ]
                }}
            >
                <Table
                    columns={[
                        {width: 5, label: textCategories("field.bq_request.status")},
                        {width: 5, label: textCategories(`field.bq_request.${CategoryBQRequestField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 65, label: textCategories(`field.bq_request.${CategoryBQRequestField.NAME}`), styles: TableColumnStyle.NOWRAP},
                        {width: 10, label: textCategories(`field.bq_request.${CategoryBQRequestField.POINTS}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 10, label: textCategories(`field.bq_request.${CategoryBQRequestField.EXPIRATION_DAY}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 5}
                    ]}
                >
                    {requests.map((request) =>
                        <CategoryBQRequestRow
                            key={request.name}
                            request={request}
                            onClick={() => setActiveEditCategoryBQRequest(request)}
                            onRemove={() => setActiveRemoveCategoryBQRequest(request)}
                        />
                    )}
                </Table>
                <ModalAddCategoryBQRequest
                    active={isShowModalAddCategoryBQRequest}
                    initRequest={new CategoryBQRequest({[CategoryBQRequestField.CATEGORY_ID]: category.id, [CategoryBQRequestField.NAME]: categoryName})}
                    onSubmit={handleAddBQRequest}
                    onClose={() => setShowModalAddCategoryBQRequest(false)}
                />
                <ModalEditCategoryBQRequest
                    initRequest={activeEditCategoryBQRequest}
                    onSubmit={handleEditBQRequest}
                    onClose={() => setActiveEditCategoryBQRequest(undefined)}
                />
                <ModalConfirmRemove
                    active={!!activeRemoveCategoryBQRequest}
                    entity={`request "${activeRemoveCategoryBQRequest?.name}"`}
                    confirm={handleRemoveBQRequest}
                    cancel={() => setActiveRemoveCategoryBQRequest(undefined)}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default CategoryBQRequests;
