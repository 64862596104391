import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CmpConfigExternalUtiq extends ApiModel {
    active: boolean = false;
    controller: string = "";
    noticeUrl: string = "/manage-utiq";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    isEnabled(): boolean {
        return !!(this.active && this.controller && this.noticeUrl);
    }

    getJsonParameters(): {} {
        return {
            active: this.active,
            controller: this.controller,
            noticeUrl: this.noticeUrl
        };
    }
}
