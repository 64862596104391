import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ButtonLinkCancel, ButtonValidate, ContentBlock, Form, FormValidationType, LayoutRows, Loadable, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, ModalNewSize} from "@sirdata/ui-lib";
import useAlert from "../../../utils/hooks/useAlert";
import {TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {CmpConfigLanguageToolbar, CmpSirdataListTranslationItem} from "../../snippet";
import {CmpConfigLanguage} from "../../../api/model/cmp/config/CmpConfigLanguage";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list/SirdataListLanguage";
import {SirdataListStackField} from "../../../api/model/cmp/list/sirdata-list/SirdataListStackField";
import {SirdataListStack} from "../../../api/model/cmp/list/sirdata-list/SirdataListStack";
import {detectChanges} from "../../../common/utils/portal";

type ModalTranslateCmpSirdataListStacksProps = {
    active: boolean;
    onClose: (refresh?: boolean) => void;
};

const ModalTranslateCmpSirdataListStacks: FunctionComponent<ModalTranslateCmpSirdataListStacksProps> = ({active, onClose}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [initListLanguages, setInitListLanguages] = useState<SirdataListLanguage[]>([]);
    const [listLanguages, setListLanguages] = useState<SirdataListLanguage[]>([]);
    const [currentLanguage, setCurrentLanguage] = useState<CmpConfigLanguage>(CmpConfigLanguage.ENGLISH);
    const [currentListLanguage, setCurrentListLanguage] = useState<SirdataListLanguage>();
    const FORM_ID = "form-translate-cmp-sirdata-list-stacks";

    useEffect(() => {
        if (!active) return;

        (async () => {
            try {
                setLoading(true);
                const newListLanguages = await session.restCmpSirdataListLanguage.list();
                setListLanguages(newListLanguages);
                setInitListLanguages([...newListLanguages]);
                setCurrentLanguage(CmpConfigLanguage.ENGLISH);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("languages", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [active, alert]);

    useEffect(() => {
        if (currentListLanguage?.language === currentLanguage.name) {
            return;
        }

        setLoading(true);
        setTimeout(() => {
            const listLanguage = listLanguages.find((it) => it.language === currentLanguage.name);
            if (listLanguage) {
                setCurrentListLanguage(listLanguage);
                setLoading(false);
            }
        }, 100);
    }, [listLanguages, currentLanguage, currentListLanguage]);

    const handleChangeListLanguages = (listLanguage: SirdataListLanguage) => {
        const newListLanguages = listLanguages.map((it) => {
            return (it.language === listLanguage.language) ? listLanguage : it;
        });
        setListLanguages(newListLanguages);
        setCurrentListLanguage(listLanguage);
    };

    const handleChangeStack = (stack: SirdataListStack, field: SirdataListStackField, value: any) => {
        const newListLanguage = new SirdataListLanguage(currentListLanguage);
        newListLanguage.stacks = newListLanguage.stacks.map((it) => {
            return (it.id === stack.id) ? new SirdataListStack({...stack, [field]: value}) : it;
        });
        handleChangeListLanguages(newListLanguage);
    };

    const getPlaceholder = (stack: SirdataListStack, field: SirdataListStackField): string | undefined => {
        const defaultList = initListLanguages.find((it) => it.language === CmpConfigLanguage.ENGLISH.name);
        const defaultStack = defaultList?.stacks.find((it) => it.id === stack.id);
        if (defaultStack) {
            return defaultStack[field] as string;
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            for (const list of listLanguages) {
                const initList = initListLanguages.find((it) => it.language === list.language);
                if (initList && detectChanges(list, initList)) {
                    await session.restCmpSirdataListLanguage.update(list);
                }
            }
            alert.updateWithSuccess("stacks");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("stacks", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    const getHighlightedLanguages = (): CmpConfigLanguage[] => {
        return CmpConfigLanguage.values().filter((language) => {
            const initList = initListLanguages.find((it) => it.language === language.name);
            const list = listLanguages.find((it) => it.language === language.name);
            return list && initList && detectChanges(list, initList);
        });
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active} size={ModalNewSize.FULLSCREEN}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpSirdataList("translate_stacks")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <CmpConfigLanguageToolbar
                            value={currentLanguage}
                            onChange={setCurrentLanguage}
                            highlightedLanguages={getHighlightedLanguages()}
                        />
                        <Loadable loading={isLoading}>
                            <ContentBlock
                                header={{
                                    title: {label: textCmpSirdataList("stacks_in_language", {language: textCmpConfigurations(`language.${currentLanguage.name}`)})}
                                }}
                                cssClass={"sirdata-list__translations"}
                            >
                                {currentListLanguage?.stacks.map((stack) =>
                                    <CmpSirdataListTranslationItem
                                        key={`${currentLanguage.name}-${stack.id}`}
                                        id={stack.id}
                                        name={{
                                            value: stack.name,
                                            onChange: (value) => handleChangeStack(stack, SirdataListStackField.NAME, value),
                                            placeholder: getPlaceholder(stack, SirdataListStackField.NAME)
                                        }}
                                        description={{
                                            value: stack.description,
                                            onChange: (value) => handleChangeStack(stack, SirdataListStackField.DESCRIPTION, value),
                                            placeholder: getPlaceholder(stack, SirdataListStackField.DESCRIPTION)
                                        }}
                                    />
                                )}
                            </ContentBlock>
                        </Loadable>
                    </LayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalTranslateCmpSirdataListStacks;
