import {Action, ContentBlock, ContentBlockAction, Table} from "@sirdata/ui-lib";
import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list/SirdataListLanguage";
import {SirdataListPurpose} from "../../../api/model/cmp/list/sirdata-list/SirdataListPurpose";
import {SirdataListPurposeField} from "../../../api/model/cmp/list/sirdata-list/SirdataListPurposeField";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersion";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersionField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {sortItems} from "../../../common/utils/helper";
import {CmpSirdataListPurposeRow} from "../../../component/snippet";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import ModalCreateCmpSirdataListPurpose from "../../modal/cmp-sirdata-list/ModalCreateCmpSirdataListPurpose";
import ModalTranslateCmpSirdataListPurposes from "../../modal/cmp-sirdata-list/ModalTranslateCmpSirdataListPurposes";
import ModalConfirmDelete from "../../modal/ModalConfirmDelete";

type CmpSirdataListPurposesEditableProps = {
    latestVersion: SirdataListVersion;
    listLanguage: SirdataListLanguage;
    onChange: () => void;
};

const CmpSirdataListPurposesEditable: FC<CmpSirdataListPurposesEditableProps> = ({latestVersion, listLanguage, onChange}) => {
    const alert = useAlert();
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const [purposes, setPurposes] = useState<SirdataListPurpose[]>([]);
    const [isShowModalCreateCmpSirdataListPurpose, setShowModalCreateCmpSirdataListPurpose] = useState(false);
    const [isShowModalTranslateCmpSirdataListPurposes, setShowModalTranslateCmpSirdataListPurposes] = useState(false);
    const [activeDeletePurpose, setActiveDeletePurpose] = useState<SirdataListPurpose>();

    useEffect(() => {
        setPurposes(listLanguage.purposes);
    }, [listLanguage.purposes]);

    const handleSortPurposes = (field: string, reverseOrder?: boolean) => {
        setPurposes((prevState) => sortItems([...prevState], field, reverseOrder));
    };

    const handleDeletePurpose = async () => {
        if (!activeDeletePurpose || latestVersion.purposes.some((it) => it.id === activeDeletePurpose.id)) return;
        try {
            const listLanguages = await session.restCmpSirdataListLanguage.list();
            for (const listLanguage of listLanguages) {
                listLanguage.purposes = listLanguage.purposes.filter((it) => it.id !== activeDeletePurpose.id);
                await session.restCmpSirdataListLanguage.update(listLanguage);
            }
            alert.deleteWithSuccess("purpose");
            onChange();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("purpose", e.message);
            }
        } finally {
            setActiveDeletePurpose(undefined);
        }
    };

    return (
        <ContentBlock
            header={{
                title: {label: textCmpSirdataList(`field.${SirdataListVersionField.PURPOSES}`)},
                actions: (
                    <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                        <ContentBlockAction action={new Action(textCmpSirdataList("action.translate"), {name: "language"})} onClick={() => setShowModalTranslateCmpSirdataListPurposes(true)}/>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalCreateCmpSirdataListPurpose(true)}/>
                    </RestrictedContent>
                )
            }}
        >
            <Table
                onSort={handleSortPurposes}
                columns={[
                    {width: 5, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.ID}`), sort: {field: SirdataListPurposeField.ID}},
                    {width: 90, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.NAME}`)},
                    {width: 5}
                ]}
                messageIfEmpty={textCmpSirdataList("message.no_purposes")}
            >
                {purposes.map((purpose) =>
                    <CmpSirdataListPurposeRow
                        key={purpose.id}
                        purpose={purpose}
                        state={purpose.getState(latestVersion)}
                        onDelete={() => setActiveDeletePurpose(purpose)}
                    />
                )}
            </Table>
            <ModalCreateCmpSirdataListPurpose
                active={isShowModalCreateCmpSirdataListPurpose}
                onClose={async (refresh) => {
                    setShowModalCreateCmpSirdataListPurpose(false);
                    refresh && onChange();
                }}
            />
            <ModalTranslateCmpSirdataListPurposes
                active={isShowModalTranslateCmpSirdataListPurposes}
                onClose={async (refresh) => {
                    setShowModalTranslateCmpSirdataListPurposes(false);
                    refresh && onChange();
                }}
            />
            <ModalConfirmDelete
                active={!!activeDeletePurpose}
                entity={`purpose ${activeDeletePurpose?.name}`}
                confirm={handleDeletePurpose}
                cancel={() => setActiveDeletePurpose(undefined)}
            />
        </ContentBlock>
    );
};

export default CmpSirdataListPurposesEditable;
