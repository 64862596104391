import {ConsentManagementPlatformField} from "./ConsentManagementPlatformField";
import {SearchQuery} from "../../../../interface/SearchQuery";
import {SearchParams} from "../../../../../utils/SearchParams";

export enum ConsentManagementPlatformSearchParamsField {
    COMMERCIAL = "commercial"
}

export class ConsentManagementPlatformSearchQuery extends SearchQuery {
    commercial?: boolean;

    withSearchParams(params: SearchParams): ConsentManagementPlatformSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(ConsentManagementPlatformField.ID, true) as ConsentManagementPlatformSearchQuery;
        newQuery.commercial = params.getBoolean(ConsentManagementPlatformSearchParamsField.COMMERCIAL);
        return newQuery;
    }
}
