import {ApiModel} from "../../../common/api/model/ApiModel";
import {CategorizerConfigEntryField} from "./CategorizerConfigEntryField";
import {CategorizerConfigEntryParams} from "./CategorizerConfigEntryParams";
import {CategorizerConfigType} from "./CategorizerConfigType";
import {CategorizerConfig} from "./config/CategorizerConfig";

export class CategorizerConfigEntry<T extends CategorizerConfig> extends ApiModel {
    id: number = 0;
    type: string = CategorizerConfigType.SANITIZER.name;
    domain?: string;
    host?: string;
    path?: string;
    config: T = {} as T;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === CategorizerConfigEntryField.CONFIG) {
                    const type = CategorizerConfigType.getByName(o[CategorizerConfigEntryField.TYPE]);
                    if (!type) continue;
                    const config = new type.modelConfig();
                    config.load(o[key]);
                    this.config = config as T;
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    static forType(type: CategorizerConfigType, o?: {}): CategorizerConfigEntry<any> {
        const entry = new CategorizerConfigEntry();
        entry.type = type.name;
        entry.config = new type.modelConfig();
        entry.load(o);
        return entry;
    }

    match(params: CategorizerConfigEntryParams): boolean {
        return this.domain === params.domain && this.host === params.host && this.path === params.path;
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            type: this.type,
            domain: this.domain,
            host: this.host,
            path: this.path,
            config: this.config.getJsonParameters()
        };
    }
}
