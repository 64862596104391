export enum PricingBillingPeriod {
    MONTH = "MONTH",
    YEAR = "YEAR",
    ONE_TIME = "ONE_TIME"
}

export const PRICING_BILLING_PERIODS = [
    PricingBillingPeriod.MONTH,
    PricingBillingPeriod.YEAR,
    PricingBillingPeriod.ONE_TIME
];
