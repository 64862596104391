import {
    Action,
    AlertSeverity,
    Box,
    BoxProps,
    CodeBlock,
    ContentBlock,
    FieldBlock,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    FormValidationType,
    InputDomain,
    InputText,
    InputUrl,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    ToggleSwitch
} from "@sirdata/ui-lib";
import {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {GoogleAnalyticsVersion} from "../../api/model/helper/GoogleAnalyticsVersion";
import {HelperDomain} from "../../api/model/helper/domain/HelperDomain";
import {HelperDomainField} from "../../api/model/helper/domain/HelperDomainField";
import {HelperDomainPartnerField} from "../../api/model/helper/domain/HelperDomainPartnerField";
import {HelperPrivacyConfiguration} from "../../api/model/helper/domain/HelperPrivacyConfiguration";
import {HelperPrivacyConfigurationClickId} from "../../api/model/helper/domain/HelperPrivacyConfigurationClickId";
import {HelperPrivacyConfigurationField} from "../../api/model/helper/domain/HelperPrivacyConfigurationField";
import {HelperPrivacyConfigurationReferer, PRIVACY_CONFIGURATION_REFERER_VALUES} from "../../api/model/helper/domain/HelperPrivacyConfigurationReferer";
import {HelperPrivacyConfigurationTransactionId} from "../../api/model/helper/domain/HelperPrivacyConfigurationTransactionId";
import {HelperPrivacyConfigurationTruncateIp, PRIVACY_CONFIGURATION_TRUNCATE_IP_VALUES} from "../../api/model/helper/domain/HelperPrivacyConfigurationTruncateIp";
import {HelperPrivacyConfigurationUrl, PRIVACY_CONFIGURATION_URL_VALUES} from "../../api/model/helper/domain/HelperPrivacyConfigurationUrl";
import {HelperPrivacyConfigurationUserAgent, PRIVACY_CONFIGURATION_USER_AGENT_VALUES} from "../../api/model/helper/domain/HelperPrivacyConfigurationUserAgent";
import {HelperPrivacyConfigurationUtm} from "../../api/model/helper/domain/HelperPrivacyConfigurationUtm";
import {HelperPrivacyConfigurationUtmField} from "../../api/model/helper/domain/HelperPrivacyConfigurationUtmField";
import {HelperPrivacyConfigurationUtmType} from "../../api/model/helper/domain/HelperPrivacyConfigurationUtmType";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {FormLayoutMessage, MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {detectChanges} from "../../common/utils/portal";
import {MainContentHeader, MainContentHeaderAction, SelectPartner, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import useAlert from "../../utils/hooks/useAlert";
import {Status} from "../../utils/Status";
import useFormValidator from "../../utils/hooks/useFormValidator";

function HelperDomainsDetails() {
    const {t: textHelperDomains} = useTranslation(TranslationPortalFile.HELPER_DOMAINS);
    const {id} = useParams() as {id: string};
    const navigate = useNavigate();
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);

    const FORM_ID = "form-edit-helper-domain";
    const [helperDomain, setHelperDomain] = useState<HelperDomain>(new HelperDomain());
    const [initHelperDomain, setInitHelperDomain] = useState<HelperDomain>(new HelperDomain());
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<HelperDomainField>();

    useEffect(() => {
        (async () => {
            try {
                const result = await session.restHelperDomain.get(id);
                setHelperDomain(result);
                setInitHelperDomain(new HelperDomain({...result}));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("domain", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(helperDomain, initHelperDomain));
    }, [helperDomain, initHelperDomain]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [HelperDomainField.GA_TID]: !!helperDomain.ga_tid && !GoogleAnalyticsVersion.values().some((it) => helperDomain.ga_tid.startsWith(it.prefix))
        }));
    }, [setErrors, helperDomain]);

    const handleChange = (field: HelperDomainField, value: any) => {
        setHelperDomain((prevState) => new HelperDomain({...prevState, [field]: value}));
    };

    const handleChangePrivacyConfiguration = (field: HelperPrivacyConfigurationField, value: any) => {
        handleChange(HelperDomainField.PRIVACY_CONFIGURATION, new HelperPrivacyConfiguration({...helperDomain.privacy_configuration, [field]: value}));
    };

    const handleChangeTransactionId = (pseudonymise: boolean) => {
        const value = pseudonymise ? HelperPrivacyConfigurationTransactionId.PSEUDONYMISE : HelperPrivacyConfigurationTransactionId.NO_ALTER;
        handleChangePrivacyConfiguration(HelperPrivacyConfigurationField.TRANSACTION_ID, value);
    };

    const handleChangeUrl = (url: HelperPrivacyConfigurationUrl) => {
        const newPrivacyConfiguration = new HelperPrivacyConfiguration({...helperDomain.privacy_configuration, [HelperPrivacyConfigurationField.URL]: url});
        switch (newPrivacyConfiguration.url) {
            case HelperPrivacyConfigurationUrl.NO_ALTER:
                newPrivacyConfiguration.utm = new HelperPrivacyConfigurationUtm();
                newPrivacyConfiguration.click_id = HelperPrivacyConfigurationClickId.NO_ALTER;
                break;
            case HelperPrivacyConfigurationUrl.REMOVE_ALL_PARAMETERS:
                newPrivacyConfiguration.utm = new HelperPrivacyConfigurationUtm({
                    [HelperPrivacyConfigurationUtmField.CAMPAIGN]: HelperPrivacyConfigurationUtmType.REMOVE,
                    [HelperPrivacyConfigurationUtmField.CONTENT]: HelperPrivacyConfigurationUtmType.REMOVE,
                    [HelperPrivacyConfigurationUtmField.MEDIUM]: HelperPrivacyConfigurationUtmType.REMOVE,
                    [HelperPrivacyConfigurationUtmField.SOURCE]: HelperPrivacyConfigurationUtmType.REMOVE,
                    [HelperPrivacyConfigurationUtmField.TERM]: HelperPrivacyConfigurationUtmType.REMOVE
                });
                newPrivacyConfiguration.click_id = HelperPrivacyConfigurationClickId.REMOVE;
                break;
            default:
                break;
        }
        handleChange(HelperDomainField.PRIVACY_CONFIGURATION, newPrivacyConfiguration);
    };

    const handleChangeClickId = (remove: boolean) => {
        const newPrivacyConfiguration = new HelperPrivacyConfiguration({
            ...helperDomain.privacy_configuration,
            [HelperPrivacyConfigurationField.CLICK_ID]: remove ? HelperPrivacyConfigurationClickId.REMOVE : HelperPrivacyConfigurationClickId.NO_ALTER,
            [HelperPrivacyConfigurationField.URL]: HelperPrivacyConfigurationUrl.REMOVE_CLICK_ID_AND_UTM
        });
        handleChange(HelperDomainField.PRIVACY_CONFIGURATION, newPrivacyConfiguration);
    };

    const handleChangeUtm = (field: HelperPrivacyConfigurationUtmField, remove: boolean) => {
        const utmValue = remove ? HelperPrivacyConfigurationUtmType.REMOVE : HelperPrivacyConfigurationUtmType.NO_ALTER;
        const newPrivacyConfiguration = new HelperPrivacyConfiguration({
            ...helperDomain.privacy_configuration,
            [HelperPrivacyConfigurationField.UTM]: new HelperPrivacyConfigurationUtm({...helperDomain.privacy_configuration.utm, [field]: utmValue})
        });
        if ((utmValue === HelperPrivacyConfigurationUtmType.REMOVE && helperDomain.privacy_configuration.url === HelperPrivacyConfigurationUrl.NO_ALTER) ||
            (utmValue === HelperPrivacyConfigurationUtmType.NO_ALTER && helperDomain.privacy_configuration.url === HelperPrivacyConfigurationUrl.REMOVE_ALL_PARAMETERS)) {
            newPrivacyConfiguration.url = HelperPrivacyConfigurationUrl.REMOVE_CLICK_ID_AND_UTM;
        }
        handleChange(HelperDomainField.PRIVACY_CONFIGURATION, newPrivacyConfiguration);
    };

    const handleSaveCapture = () => {
        setShowErrors(true);
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }

        try {
            const domain = await session.restHelperDomain.update(helperDomain);
            setHelperDomain(domain);
            setInitHelperDomain(new HelperDomain(domain));
            alert.updateWithSuccess("domain");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("domain", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restHelperDomain.delete(id);
            navigate(Module.HELPER_DOMAINS.path);
            alert.deleteWithSuccess("domain");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("domain", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.HELPER_DOMAINS} element={initHelperDomain.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.HELPER_DOMAINS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmitCapture={handleSaveCapture} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textHelperDomains("section.information")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns columns={4}>
                                                <FieldBlock label={textHelperDomains(`field.${HelperDomainField.STATUS}`)}>
                                                    <SelectStatus
                                                        value={helperDomain.status}
                                                        statuses={Status.getActiveStatuses()}
                                                        onChange={(status) => handleChange(HelperDomainField.STATUS, status?.name)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textHelperDomains(`field.partner.${HelperDomainPartnerField.ID}`)}>
                                                <SelectPartner
                                                    value={helperDomain.partner_id}
                                                    onChange={undefined}
                                                    disabled
                                                />
                                            </FieldBlock>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textHelperDomains(`field.${HelperDomainField.NAME}`)} required>
                                                    <InputText
                                                        value={helperDomain.name}
                                                        onChange={(value) => handleChange(HelperDomainField.NAME, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock
                                                    label={textHelperDomains(`field.${HelperDomainField.DOMAIN}`)}
                                                    content={{direction: FlexContentDirection.COLUMN}}
                                                    required
                                                >
                                                    <InputDomain
                                                        value={helperDomain.domain}
                                                        onChange={(value) => handleChange(HelperDomainField.DOMAIN, value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock
                                                label={textHelperDomains(`field.${HelperDomainField.PRIVACY_POLICY}`)}
                                                content={{direction: FlexContentDirection.COLUMN}}
                                                required
                                            >
                                                <InputUrl
                                                    placeholder={textHelperDomains(`placeholder.${HelperDomainField.PRIVACY_POLICY}`)}
                                                    value={helperDomain.privacy_policy}
                                                    onChange={(value) => handleChange(HelperDomainField.PRIVACY_POLICY, value)}
                                                />
                                            </FieldBlock>
                                            <FieldBlock
                                                label={textHelperDomains(`field.${HelperDomainField.GA_TID}`)}
                                                tooltip={textHelperDomains(`tooltip.${HelperDomainField.GA_TID}`)}
                                                content={{direction: FlexContentDirection.COLUMN}}
                                            >
                                                <InputText
                                                    placeholder={textHelperDomains(`placeholder.${HelperDomainField.GA_TID}`)}
                                                    value={helperDomain.ga_tid}
                                                    onChange={(value) => handleChange(HelperDomainField.GA_TID, value)}
                                                />
                                                {formValidator.isError(HelperDomainField.GA_TID) &&
                                                    <FormLayoutMessage message={textHelperDomains("message.invalid_ga_tid")} severity={AlertSeverity.DANGER} small/>
                                                }
                                            </FieldBlock>
                                            <FieldBlock label={textHelperDomains(`field.${HelperDomainField.TAG}`)}>
                                                <CodeBlock code={helperDomain.tag} wrapLongLines/>
                                            </FieldBlock>
                                            <FieldBlock label={textHelperDomains(`field.${HelperDomainField.LEGACY_TAG}`)}>
                                                <CodeBlock code={helperDomain.legacy_tag} wrapLongLines/>
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textHelperDomains("section.privacy_configuration")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutRows inline>
                                                <FieldBlock label={textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.TRUNCATE_IP}.title`)}>
                                                    <Select
                                                        value={helperDomain.privacy_configuration.truncate_ip || HelperPrivacyConfigurationTruncateIp.TRUNCATE_ONE_BYTE}
                                                        options={PRIVACY_CONFIGURATION_TRUNCATE_IP_VALUES.map((it) => ({value: it, label: textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.TRUNCATE_IP}.${it}`)}))}
                                                        onChange={(option) => handleChangePrivacyConfiguration(HelperPrivacyConfigurationField.TRUNCATE_IP, option?.value as HelperPrivacyConfigurationTruncateIp)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.USER_AGENT}.title`)}>
                                                    <Select
                                                        value={helperDomain.privacy_configuration.user_agent || HelperPrivacyConfigurationUserAgent.NO_ALTER}
                                                        options={PRIVACY_CONFIGURATION_USER_AGENT_VALUES.map((it) => ({value: it, label: textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.USER_AGENT}.${it}`)}))}
                                                        onChange={(option) => handleChangePrivacyConfiguration(HelperPrivacyConfigurationField.USER_AGENT, option?.value as HelperPrivacyConfigurationUserAgent)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.REFERER}.title`)}>
                                                    <Select
                                                        value={helperDomain.privacy_configuration.referer || HelperPrivacyConfigurationReferer.NO_ALTER}
                                                        options={PRIVACY_CONFIGURATION_REFERER_VALUES.map((it) => ({value: it, label: textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.REFERER}.${it}`)}))}
                                                        onChange={(option) => handleChangePrivacyConfiguration(HelperPrivacyConfigurationField.REFERER, option?.value as HelperPrivacyConfigurationReferer)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock name={HelperPrivacyConfigurationField.TRANSACTION_ID} label={textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.TRANSACTION_ID}`)}>
                                                    <ToggleSwitch
                                                        checked={helperDomain.privacy_configuration.transaction_id === HelperPrivacyConfigurationTransactionId.PSEUDONYMISE}
                                                        onChange={handleChangeTransactionId}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutRows>
                                            <FormLayoutSeparator/>
                                            <FormLayoutTitle>{textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.URL}.title`)}</FormLayoutTitle>
                                            <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                                <Select
                                                    value={helperDomain.privacy_configuration.url || HelperPrivacyConfigurationUrl.NO_ALTER}
                                                    options={PRIVACY_CONFIGURATION_URL_VALUES.map((it) => ({value: it, label: textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.URL}.${it}`)}))}
                                                    onChange={(option) => handleChangeUrl(option?.value as HelperPrivacyConfigurationUrl)}
                                                />
                                                <ToggleSwitch
                                                    name={HelperPrivacyConfigurationField.CLICK_ID}
                                                    label={textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.CLICK_ID}`)}
                                                    checked={helperDomain.privacy_configuration.click_id === HelperPrivacyConfigurationClickId.REMOVE}
                                                    onChange={handleChangeClickId}
                                                />
                                                <ToggleSwitch
                                                    name={HelperPrivacyConfigurationUtmField.SOURCE}
                                                    label={textHelperDomains(`field.privacy.utm.${HelperPrivacyConfigurationUtmField.SOURCE}`)}
                                                    checked={helperDomain.privacy_configuration.utm.source === HelperPrivacyConfigurationUtmType.REMOVE}
                                                    onChange={(value) => handleChangeUtm(HelperPrivacyConfigurationUtmField.SOURCE, value)}
                                                />
                                                <ToggleSwitch
                                                    name={HelperPrivacyConfigurationUtmField.CAMPAIGN}
                                                    label={textHelperDomains(`field.privacy.utm.${HelperPrivacyConfigurationUtmField.CAMPAIGN}`)}
                                                    checked={helperDomain.privacy_configuration.utm.campaign === HelperPrivacyConfigurationUtmType.REMOVE}
                                                    onChange={(value) => handleChangeUtm(HelperPrivacyConfigurationUtmField.CAMPAIGN, value)}
                                                />
                                                <ToggleSwitch
                                                    name={HelperPrivacyConfigurationUtmField.MEDIUM}
                                                    label={textHelperDomains(`field.privacy.utm.${HelperPrivacyConfigurationUtmField.MEDIUM}`)}
                                                    checked={helperDomain.privacy_configuration.utm.medium === HelperPrivacyConfigurationUtmType.REMOVE}
                                                    onChange={(value) => handleChangeUtm(HelperPrivacyConfigurationUtmField.MEDIUM, value)}
                                                />
                                                <ToggleSwitch
                                                    name={HelperPrivacyConfigurationUtmField.CONTENT}
                                                    label={textHelperDomains(`field.privacy.utm.${HelperPrivacyConfigurationUtmField.CONTENT}`)}
                                                    checked={helperDomain.privacy_configuration.utm.content === HelperPrivacyConfigurationUtmType.REMOVE}
                                                    onChange={(value) => handleChangeUtm(HelperPrivacyConfigurationUtmField.CONTENT, value)}
                                                />
                                                <ToggleSwitch
                                                    name={HelperPrivacyConfigurationUtmField.TERM}
                                                    label={textHelperDomains(`field.privacy.utm.${HelperPrivacyConfigurationUtmField.TERM}`)}
                                                    checked={helperDomain.privacy_configuration.utm.term === HelperPrivacyConfigurationUtmType.REMOVE}
                                                    onChange={(value) => handleChangeUtm(HelperPrivacyConfigurationUtmField.TERM, value)}
                                                />
                                            </FormLayoutRows>
                                            <FormLayoutSeparator/>
                                            <FormLayoutTitle>{textHelperDomains("field.privacy.other")}</FormLayoutTitle>
                                            <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                                <ToggleSwitch
                                                    name={HelperPrivacyConfigurationField.IGNORE_TCF}
                                                    label={textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.IGNORE_TCF}`)}
                                                    checked={helperDomain.privacy_configuration.ignore_tcf}
                                                    onChange={(value) => handleChangePrivacyConfiguration(HelperPrivacyConfigurationField.IGNORE_TCF, value)}
                                                />
                                                <ToggleSwitch
                                                    name={HelperPrivacyConfigurationField.DOMAIN_EXCLUDED_TO_HASH}
                                                    label={textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.DOMAIN_EXCLUDED_TO_HASH}`)}
                                                    checked={helperDomain.privacy_configuration.domain_excluded_to_hash}
                                                    onChange={(value) => handleChangePrivacyConfiguration(HelperPrivacyConfigurationField.DOMAIN_EXCLUDED_TO_HASH, value)}
                                                />
                                                <ToggleSwitch
                                                    name={HelperPrivacyConfigurationField.DISABLE_COOKIELESS_TRACKING}
                                                    label={textHelperDomains(`field.privacy.${HelperPrivacyConfigurationField.DISABLE_COOKIELESS_TRACKING}`)}
                                                    checked={helperDomain.privacy_configuration.disable_cookieless_tracking}
                                                    onChange={(value) => handleChangePrivacyConfiguration(HelperPrivacyConfigurationField.DISABLE_COOKIELESS_TRACKING, value)}
                                                />
                                            </FormLayoutRows>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                        </LayoutColumns>
                    </LayoutRows>
                </Form>
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="domain"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default HelperDomainsDetails;
