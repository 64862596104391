import {Table, TableColumnStyle} from "@sirdata/ui-lib";
import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {Stack} from "../../../api/model/cmp/list/global-vendor-list/Stack";
import {SearchResult} from "../../../api/interface/SearchResult";
import {StackField} from "../../../api/model/cmp/list/global-vendor-list/StackField";
import {CmpListsIABStackRow} from "../index";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";

type GlobalVendorListStacksProps = {
    globalVendorList: GlobalVendorList;
};

const GlobalVendorListStacks: FC<GlobalVendorListStacksProps> = ({globalVendorList}) => {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const [searchResult, setSearchResult] = useState<SearchResult<Stack>>(new SearchResult(Stack));
    const stacks = globalVendorList.stacks;

    useEffect(() => {
        setSearchResult(SearchResult.buildFromList(Stack, stacks, 0, PAGE_SIZE));
    }, [stacks]);

    const handleChangePage = (page: number) => {
        setSearchResult(SearchResult.buildFromList(Stack, stacks, page - 1, PAGE_SIZE));
    };

    return (
        <Table
            columns={[
                {width: 5},
                {width: 5, label: textCmpLists(`gvl.field.${StackField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                {width: 30, label: textCmpLists(`gvl.field.${StackField.NAME}`)},
                {width: 60, label: textCmpLists(`gvl.field.${StackField.DESCRIPTION}`)}
            ]}
            pagination={searchResult.getPagination(handleChangePage)}
        >
            {searchResult.elements.map((stack) =>
                <CmpListsIABStackRow
                    key={stack.id}
                    stack={stack}
                    globalVendorList={globalVendorList}
                />
            )}
        </Table>
    );
};

export default GlobalVendorListStacks;
