import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PartnerLicenseLinkPartner} from "../../../api/model/partner/license/PartnerLicenseLinkPartner";
import {Box, BoxRadius, FieldBlock, FlexContentSpacing, FormLayoutRows, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Table, TableColumn, TableRow, TagStyle} from "@sirdata/ui-lib";
import {MODAL_PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {Formatter} from "../../../common/utils/Formatter";
import {SearchResult} from "../../../api/interface/SearchResult";
import {PartnerLicenseLinkActivation} from "../../../api/model/partner/license/PartnerLicenseLinkActivation";
import {LabelStatus, Tag} from "../../snippet";
import {PartnerLicenseLinkPartnerField} from "../../../api/model/partner/license/PartnerLicenseLinkPartnerField";

export type ModalViewLicenseLinkPartnerProps = {
    link?: PartnerLicenseLinkPartner;
    onClose: () => void;
};

const ModalViewLicenseLinkPartner: FunctionComponent<ModalViewLicenseLinkPartnerProps> = ({link, onClose}) => {
    const {t: textLicenses} = useTranslation(TranslationPortalFile.LICENSES);

    const [searchResult, setSearchResult] = useState<SearchResult<PartnerLicenseLinkActivation>>(new SearchResult(PartnerLicenseLinkActivation));

    const handleChangePage = (page: number) => {
        if (!link) return;
        setSearchResult(SearchResult.buildFromList(PartnerLicenseLinkActivation, link.activations, page - 1, MODAL_PAGE_SIZE));
    };

    useEffect(() => {
        if (!link) return;
        setSearchResult(SearchResult.buildFromList(PartnerLicenseLinkActivation, link.activations, 0, MODAL_PAGE_SIZE));
    }, [link]);

    return (
        <ModalNew onClose={onClose} active={!!link}>
            <ModalHeader>
                <ModalHeaderTitle title={textLicenses("modal.link.title_view", {id: link?.partner_id})}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows>
                    {!!link &&
                    <Box radius={BoxRadius.MD}>
                        <FormLayoutRows spacing={FlexContentSpacing.SMALL} inline>
                            <FieldBlock label={textLicenses(`field.link_partner.${PartnerLicenseLinkPartnerField.LICENSE}`)} content={{noFullWidth: true}}>
                                <Tag label={link.license.name} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                            </FieldBlock>
                            <FieldBlock label={textLicenses("field.status")}>
                                <LabelStatus status={link.getStatus()}/>
                            </FieldBlock>
                        </FormLayoutRows>
                    </Box>
                    }
                    <FieldBlock label={textLicenses("section.activation_history")}>
                        <Table
                            columns={[
                                {label: textLicenses("field.activation_date")},
                                {label: textLicenses("field.expiration_date")}
                            ]}
                            pagination={searchResult.getPagination(handleChangePage)}
                        >
                            {searchResult.elements.map((activation) =>
                                <TableRow key={activation.created_at}>
                                    <TableColumn>{Formatter.formatUTCDate(activation.created_at, Formatter.DATETIME_FORMAT)}</TableColumn>
                                    <TableColumn>{activation.expires_at ? Formatter.formatUTCDate(activation.expires_at, Formatter.DATETIME_FORMAT) : "-"}</TableColumn>
                                </TableRow>
                            )}
                        </Table>
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalViewLicenseLinkPartner;
