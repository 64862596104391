import {ApiModel} from "../../common/api/model/ApiModel";
import {sortItems} from "../../common/utils/helper";
import {SearchParams} from "../../utils/SearchParams";
import {SearchParamsField} from "../../utils/SearchParamsField";

export class SearchQuery extends ApiModel {
    query: string = "";
    page: number = 0;
    sortBy?: string;
    reverseOrder?: boolean;

    constructor(o?: {}) {
        super();
        this.load(o);
    }

    withSearchParams(params: SearchParams): SearchQuery {
        this.query = params.getString(SearchParamsField.QUERY) || "";
        this.page = params.getNumber(SearchParamsField.PAGE) || 0;
        this.sortBy = params.getString(SearchParamsField.SORT_BY);
        this.reverseOrder = params.getBoolean(SearchParamsField.REVERSE_ORDER);
        return this;
    }

    withDefaultSorting(field: string, reverseOrder: boolean): SearchQuery {
        this.sortBy = this.sortBy || field;
        this.reverseOrder = this.reverseOrder !== undefined ? this.reverseOrder : reverseOrder;
        return this;
    }

    sortItems(items: any[]): any[] {
        if (!this.sortBy) return items;
        return sortItems(items, this.sortBy, this.reverseOrder);
    }
}
