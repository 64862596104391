export class CmpConfigCapping {
    static CHECK: CmpConfigCapping = new CmpConfigCapping("CHECK");
    static STRICT: CmpConfigCapping = new CmpConfigCapping("STRICT");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CmpConfigCapping[] {
        return [
            CmpConfigCapping.CHECK,
            CmpConfigCapping.STRICT
        ];
    }
}
