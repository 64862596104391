import {Action, ContentBlock, ContentBlockAction, ElementList, ElementListSize, Tag as LibTag, TagSize, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {TranslationPortalFile} from "../../../utils/constants";
import {ApiAuthorization} from "../../../api/model/auth/ApiAuthorization";
import ModalEditPartnerAuthorizations from "../../modal/authorizations/ModalEditPartnerAuthorizations";
import useAlert from "../../../utils/hooks/useAlert";
import {sortItems} from "../../../common/utils/helper";
import {ApiAuthorizationField} from "../../../api/model/auth/ApiAuthorizationField";

const PartnerAuthorizations: FunctionComponent<{ partnerId: number }> = ({partnerId}) => {
    const alert = useAlert();
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const [isShowModalEditPartnerAuthorizations, setShowModalEditPartnerAuthorizations] = useState(false);
    const [partnerAuthorizations, setPartnerAuthorizations] = useState<ApiAuthorization[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const authorizations = await session.restPartnerAuthorization.list();
                const authorizationDetails = await session.restPartnerAuthorization.getDetails(partnerId);
                setPartnerAuthorizations(authorizations.filter((it) => authorizationDetails.authorization_set.includes(it.name)));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partner's authorizations", e.message);
                }
            }
        })();
    }, [partnerId, alert]);

    return (
        <ContentBlock
            header={{
                title: {label: textPartners("section.authorizations")},
                actions: (
                    <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                        <ContentBlockAction action={Action.EDIT} onClick={() => setShowModalEditPartnerAuthorizations(true)}/>
                    </RestrictedContent>
                )
            }}
        >
            <ElementList placeholder={textPartners("placeholder.no_authorization_granted")} size={ElementListSize.BIG}>
                {sortItems(partnerAuthorizations, ApiAuthorizationField.NAME).map((authorization) =>
                    <LibTag
                        key={authorization.name}
                        size={TagSize.MEDIUM}
                        style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}
                        label={authorization.label}
                    />
                )}
            </ElementList>
            <ModalEditPartnerAuthorizations
                partnerId={partnerId}
                partnerAuthorizations={partnerAuthorizations}
                onSubmit={(authorizations) => {
                    setPartnerAuthorizations(authorizations);
                    setShowModalEditPartnerAuthorizations(false);
                }}
                onClose={() => setShowModalEditPartnerAuthorizations(false)}
                active={isShowModalEditPartnerAuthorizations}
            />
        </ContentBlock>
    );
};

export default PartnerAuthorizations;
