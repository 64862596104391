import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Action, Button, Tooltip, TranslationLibFile} from "@sirdata/ui-lib";
import clsx from "clsx";

type MainContentHeaderActionProps = {
    action: Action;
    label?: string;
    onClick?: (_?: any) => void;
    form?: string;
    disabled?: boolean;
}

const MainContentHeaderAction: FunctionComponent<MainContentHeaderActionProps> = ({action, label, onClick, form, disabled}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <Tooltip text={clsx(textCommon(action.labelKey), label)}>
            <Button
                style={action.style}
                icon={action.icon}
                onClick={onClick}
                form={form}
                type={form ? "submit" : "button"}
                disabled={disabled}
                cssClass={clsx("tools__actions__button")}
            />
        </Tooltip>
    );
};

export default MainContentHeaderAction;
