import {FC} from "react";
import {DataleaksAuditScoring} from "../../../api/model/dataleaks/audit/DataleaksAuditScoring";
import {formatPercentage} from "../../../utils/string";

export enum DataleaksScoreSize {
    BIG = "big-value",
    SMALL = "small-value"
}

type DataleaksScoreProps = {
    value: number | undefined;
    size?: DataleaksScoreSize;
};

const DataleaksScore: FC<DataleaksScoreProps> = ({value, size}) => {
    if (value === undefined) return <></>;

    return (
        <span
            className={size ? `dataleaks__scoring__item__score__${size}` : ""}
            style={{color: DataleaksAuditScoring.getColorForRate(value)}}
        >
            {formatPercentage(value)}
        </span>
    );
};

export default DataleaksScore;
