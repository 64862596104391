import {Dispatch, FunctionComponent, SetStateAction} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksAudit} from "../../../api/model/dataleaks/audit/DataleaksAudit";
import {DataleaksAuditField} from "../../../api/model/dataleaks/audit/DataleaksAuditField";
import {DataleaksAuditCompanyField} from "../../../api/model/dataleaks/audit/DataleaksAuditCompanyField";
import {DataleaksAuditCompany} from "../../../api/model/dataleaks/audit/DataleaksAuditCompany";
import {FieldBlock, FlexContentLayout, FormLayoutColumns, InputText, InputUrl} from "@sirdata/ui-lib";
import {ImagePreview, SelectPartner} from "../../snippet";

type ModalCreateDataleaksAuditPartnerProps = {
    audit: DataleaksAudit;
    setAudit: Dispatch<SetStateAction<DataleaksAudit>>;
};

const ModalCreateDataleaksAuditPartner: FunctionComponent<ModalCreateDataleaksAuditPartnerProps> = ({audit, setAudit}) => {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);

    const handleChange = (field: DataleaksAuditField, value: any) => {
        setAudit((prevState) => new DataleaksAudit({...prevState, [field]: value}));
    };

    const handleChangeCompany = (field: DataleaksAuditCompanyField, value: any) => {
        handleChange(DataleaksAuditField.COMPANY, new DataleaksAuditCompany({...audit.company, [field]: value}));
    };

    return (
        <>
            <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.COMPANY}.`)} required>
                <InputText
                    value={audit.company.name}
                    onChange={(value) => handleChangeCompany(DataleaksAuditCompanyField.NAME, value)}
                />
            </FieldBlock>
            <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.COMPANY}.${DataleaksAuditCompanyField.ADDRESS}`)}>
                    <InputText
                        value={audit.company.address}
                        onChange={(value) => handleChangeCompany(DataleaksAuditCompanyField.ADDRESS, value)}
                    />
                </FieldBlock>
                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.COMPANY}.${DataleaksAuditCompanyField.SIREN}`)}>
                    <InputText
                        value={audit.company.siren}
                        onChange={(value) => handleChangeCompany(DataleaksAuditCompanyField.SIREN, value)}
                    />
                </FieldBlock>
            </FormLayoutColumns>
            <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.COMPANY}.${DataleaksAuditCompanyField.LOGO}`)}>
                    <InputUrl
                        value={audit.company.logo}
                        onChange={(url) => handleChangeCompany(DataleaksAuditCompanyField.LOGO, url)}
                    />
                </FieldBlock>
                <ImagePreview url={audit.company.logo}/>
            </FormLayoutColumns>
            <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.PARTNER_ID}`)}>
                <SelectPartner
                    value={audit.partner_id}
                    onChange={(partner) => handleChange(DataleaksAuditField.PARTNER_ID, partner?.id)}
                />
            </FieldBlock>
        </>
    );
};

export default ModalCreateDataleaksAuditPartner;
