import {Box, ButtonLinkCancel, ButtonValidate, FieldBlock, FlexContentSpacing, Form, FormLayoutRows, FormValidationType, InputText, InputUrl, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, RadioButtons, Textarea, ToggleSwitch} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigPublisherCustomPurpose} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurpose";
import {CmpConfigPublisherCustomPurposeField} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeField";
import {CmpConfigPublisherCustomPurposeVendor} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeVendor";
import {CmpConfigPublisherCustomPurposeVendorField} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeVendorField";
import {CmpConfigPublisherField} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherField";
import {CmpConfigPublisherLegalBasis} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherLegalBasis";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalAddCustomPurposeProps = {
    active: boolean;
    onSubmit: (purpose: CmpConfigPublisherCustomPurpose) => void;
    onClose: () => void;
};

const ModalAddCustomPurpose: FunctionComponent<ModalAddCustomPurposeProps> = ({active, onSubmit, onClose}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [purpose, setPurpose] = useState<CmpConfigPublisherCustomPurpose>(new CmpConfigPublisherCustomPurpose());
    const FORM_ID = "form-add-custom-purpose";

    useEffect(() => {
        if (active) {
            setPurpose(new CmpConfigPublisherCustomPurpose());
        }
    }, [active]);

    const handleChange = (field: CmpConfigPublisherCustomPurposeField, value: any) => {
        setPurpose((prevState) => new CmpConfigPublisherCustomPurpose({...prevState, [field]: value}));
    };

    const handleChangeVendor = (field: CmpConfigPublisherCustomPurposeVendorField, value: any) => {
        const newVendor = new CmpConfigPublisherCustomPurposeVendor({...purpose.vendor, [field]: value});
        handleChange(CmpConfigPublisherCustomPurposeField.VENDOR, newVendor);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        onSubmit(purpose);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpConfigurations("purposes.custom.add_custom_purpose")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock
                            label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.NAME}`)}
                            required
                        >
                            <InputText
                                value={purpose.name}
                                onChange={(value) => handleChange(CmpConfigPublisherCustomPurposeField.NAME, value)}
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.DESCRIPTION}`)}
                            required
                        >
                            <Textarea
                                value={purpose.description}
                                rows={5}
                                onChange={(value) => handleChange(CmpConfigPublisherCustomPurposeField.DESCRIPTION, value)}
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.LEGAL_BASIS}`)}
                            required
                        >
                            <RadioButtons
                                id={CmpConfigPublisherCustomPurposeField.LEGAL_BASIS}
                                value={purpose.legalBasis}
                                options={[
                                    {value: CmpConfigPublisherLegalBasis.CONSENT, label: textCmpConfigurations(`purposes.${CmpConfigPublisherLegalBasis.CONSENT}`)},
                                    {value: CmpConfigPublisherLegalBasis.LEGITIMATE_INTEREST, label: textCmpConfigurations(`purposes.${CmpConfigPublisherLegalBasis.LEGITIMATE_INTEREST}`)}
                                ]}
                                onChange={(value) => handleChange(CmpConfigPublisherCustomPurposeField.LEGAL_BASIS, value)}
                            />
                        </FieldBlock>
                        <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                            <ToggleSwitch
                                name={CmpConfigPublisherCustomPurposeField.VENDOR}
                                label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.VENDOR}.label`)}
                                checked={!!purpose.vendor}
                                onChange={(value) => handleChange(CmpConfigPublisherCustomPurposeField.VENDOR, value ? new CmpConfigPublisherCustomPurposeVendor() : undefined)}
                            />
                            {purpose.vendor &&
                                <Box>
                                    <FormLayoutRows inline spacing={FlexContentSpacing.SMALL}>
                                        <FieldBlock
                                            label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.VENDOR}.${CmpConfigPublisherCustomPurposeVendorField.NAME}`)}
                                            required
                                        >
                                            <InputText
                                                value={purpose.vendor.name}
                                                onChange={(value) => handleChangeVendor(CmpConfigPublisherCustomPurposeVendorField.NAME, value)}
                                            />
                                        </FieldBlock>
                                        <FieldBlock
                                            label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.CUSTOM_PURPOSES}.${CmpConfigPublisherCustomPurposeField.VENDOR}.${CmpConfigPublisherCustomPurposeVendorField.POLICY_URL}`)}
                                            required
                                        >
                                            <InputUrl
                                                value={purpose.vendor.policyUrl}
                                                onChange={(value) => handleChangeVendor(CmpConfigPublisherCustomPurposeVendorField.POLICY_URL, value)}
                                            />
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            }
                        </FormLayoutRows>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddCustomPurpose;
