import {Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {PurposeField} from "../../../api/model/cmp/list/global-vendor-list/PurposeField";
import {Purpose} from "../../../api/model/cmp/list/global-vendor-list/Purpose";

type GlobalVendorListPurposesProps = {
    purposes: Purpose[];
};

const GlobalVendorListPurposes: FC<GlobalVendorListPurposesProps> = ({purposes}) => {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);

    return (
        <Table
            columns={[
                {width: 5, label: textCmpLists(`gvl.field.${PurposeField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                {width: 30, label: textCmpLists(`gvl.field.${PurposeField.NAME}`)},
                {width: 65, label: textCmpLists(`gvl.field.${PurposeField.DESCRIPTION}`)}
            ]}
        >
            {purposes.map((purpose) =>
                <TableRow key={purpose.id}>
                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{purpose.id}</TableColumn>
                    <TableColumn>{purpose.name}</TableColumn>
                    <TableColumn>{purpose.description}</TableColumn>
                </TableRow>
            )}
        </Table>
    );
};

export default GlobalVendorListPurposes;
