import {Rest} from "../../common/api/rest/Rest";
import {PartnerSite} from "../model/partner/PartnerSite";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";

export class RestPartnerSite extends Rest implements RestService<PartnerSite> {
    rootPath = "/console-api/partner";

    search(params: any): Promise<SearchResult<PartnerSite>> {
        return new Promise<SearchResult<PartnerSite>>(() => {}); // TODO Implement route
    }

    list(partnerId: number): Promise<PartnerSite[]> {
        return this._client.get(`${this.rootPath}/${partnerId}/sites`, PartnerSite) as Promise<PartnerSite[]>;
    }

    get(siteId: number): Promise<PartnerSite> {
        return new Promise<PartnerSite>(() => {}); // TODO Implement route
    }

    create(site: PartnerSite): Promise<PartnerSite> {
        return this._client.post(`${this.rootPath}/${site.partner_id}/sites`, site, PartnerSite) as Promise<PartnerSite>;
    }

    update(site: PartnerSite): Promise<PartnerSite> {
        return this._client.put(`${this.rootPath}/${site.partner_id}/sites/${site.site_id}`, site, PartnerSite) as Promise<PartnerSite>;
    }

    delete(site: PartnerSite) {
        return this._client.delete(`${this.rootPath}/${site.partner_id}/sites/${site.site_id}`);
    }
}
