import {PAGE_SIZE} from "../../../../../utils/constants";
import {SearchParams} from "../../../../../utils/SearchParams";
import {SearchQuery} from "../../../../interface/SearchQuery";

export enum CategoryGroupKeywordsSearchParamsField {
    STRICT = "strict",
    TIER1 = "tier1",
    LAST_UPDATE = "last_update",
}

export class CategoryGroupKeywordsSearchQuery extends SearchQuery {
    perPage: number = PAGE_SIZE;
    strict: boolean = false;
    tier1?: string;

    withSearchParams(params: SearchParams): CategoryGroupKeywordsSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(CategoryGroupKeywordsSearchParamsField.LAST_UPDATE, true) as CategoryGroupKeywordsSearchQuery;
        newQuery.strict = params.getBoolean(CategoryGroupKeywordsSearchParamsField.STRICT) || false;
        newQuery.tier1 = params.getString(CategoryGroupKeywordsSearchParamsField.TIER1);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            search: this.query,
            predicate: this.sortBy,
            reverse: this.reverseOrder,
            page: this.page,
            size: this.perPage,
            strict: this.strict,
            tier1: this.tier1
        };
    }
}
