import {RevenueImport} from "./RevenueImport";
import {RevenueImportSearchParam} from "./RevenueImportSearchParam";
import {SearchResult} from "../../../interface/SearchResult";

export class RevenueImportSearchResult extends SearchResult<RevenueImport> {
    result: RevenueImport[] = [];
    error: string = "";
    count: number = 0;
    request_param: RevenueImportSearchParam = new RevenueImportSearchParam();

    constructor(o?: {}) {
        super(RevenueImport, o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "result") {
                    (o[key] as RevenueImport[]).forEach((obj) => {
                        const it = new RevenueImport(obj);
                        this.result.push(it);
                    });
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    get page(): number {
        return this.request_param.page;
    }

    get size(): number {
        return this.request_param.size;
    }

    get total_elements(): number {
        return this.count;
    }

    get elements(): RevenueImport[] {
        return this.result;
    }
}
