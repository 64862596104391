import {Action, Box, BoxRadius, BoxSpacing, Icon, IconTooltip, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Media} from "../../../api/model/media/Media";
import {MediaType} from "../../../api/model/media/MediaType";
import {TranslationPortalFile} from "../../../utils/constants";
import ModalSelectMedia from "../../modal/medias/ModalSelectMedia";

type MediaPickerProps = {
    mediaId: number | undefined;
    onSelect: (media: Media) => void;
    onRemove: () => void;
    allowedType?: MediaType;
};

const MediaPicker: FunctionComponent<MediaPickerProps> = ({mediaId, onSelect, onRemove, allowedType}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textMedias} = useTranslation(TranslationPortalFile.MEDIAS);
    const [media, setMedia] = useState<Media | undefined>();
    const [isShowModalSelectMedia, setShowModalSelectMedia] = useState(false);

    const handleSelect = (media: Media) => {
        setMedia(media);
        onSelect(media);
    };

    useEffect(() => {
        if (!mediaId) {
            setMedia(undefined);
            return;
        }
        if (mediaId === media?.id) return;

        (async () => {
            try {
                const media = await session.restMedia.get(mediaId);
                setMedia(media);
            } catch (e) {
                setMedia(undefined);
            } finally {
            }
        })();
    }, [mediaId, media?.id]);

    const buildMediaPreview = () => {
        switch (media?.type) {
            case MediaType.IMAGE.name:
                return <img src={media.url} alt={media.name}/>;
            case MediaType.FONT.name:
                return <Icon name="format_size" cssClass="media-picker__media__content__icon"/>;
            case MediaType.VIDEO.name:
                return <Icon name="smart_display" cssClass="media-picker__media__content__icon"/>;
            default:
                return <></>;
        }
    };

    return (
        <>
            <div className="media-picker" onClick={() => setShowModalSelectMedia(true)}>
                {media ?
                    <Box radius={BoxRadius.MD} spacing={BoxSpacing.NONE} cssClass="media-picker__media">
                        <div className="media-picker__media__content">
                            {buildMediaPreview()}
                            <span className="media-picker__media__content__name">{media.title || media.name.split("/").pop()}</span>
                        </div>
                        <div className="media-picker__media__button" onClick={(e) => e.stopPropagation()}>
                            <IconTooltip
                                icon={Action.REMOVE.icon}
                                text={textCommon(Action.REMOVE.labelKey)}
                                onClick={onRemove}
                            />
                        </div>
                    </Box> :
                    <Box radius={BoxRadius.MD} spacing={BoxSpacing.SMALL} cssClass="media-picker__placeholder">
                        {textMedias(allowedType ? `placeholder.select_${allowedType.name}` : "placeholder.file")}
                    </Box>
                }
            </div>
            <ModalSelectMedia active={isShowModalSelectMedia} onSelect={handleSelect} onClose={() => setShowModalSelectMedia(false)} allowedType={allowedType}/>
        </>
    );
};

export default MediaPicker;
