export enum RevenueImportStatus {
    CREATE = "CREATE",
    DELETED = "DELETED",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

export const REVENUE_IMPORT_STATUSES = [
    RevenueImportStatus.CREATE,
    RevenueImportStatus.ERROR,
    RevenueImportStatus.DELETED,
    RevenueImportStatus.SUCCESS
];
