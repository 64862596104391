import {ApiModel} from "../../../../common/api/model/ApiModel";
import {ContentElement} from "../../../interface/ContentElement";
import {User} from "../../user/User";
import {PartnerOrganization} from "./PartnerOrganization";
import {PartnerOrganizationGroupField} from "./PartnerOrganizationGroupField";
import {Module} from "../../../../utils/Module";

export class PartnerOrganizationGroup extends ApiModel {
    id: number = 0;
    type?: string;
    name: string = "";
    description: string = "";
    owner: User | undefined;
    owner_id: number = 0;
    domains: string[] = [];
    organizations: PartnerOrganization[] = [];
    created_at: string = "";
    updated_at: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === PartnerOrganizationGroupField.ORGANIZATIONS) {
                    this.organizations = this.organizations || [];
                    (o[key] as PartnerOrganization[]).forEach((obj) => {
                        const it = new PartnerOrganization(obj);
                        this.organizations.push(it);
                    });
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            type: this.type,
            name: this.name,
            description: this.description,
            owner_id: this.owner_id > 0 ? this.owner_id : undefined,
            domains: this.domains
        };
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        element.createdAt = new Date(this.created_at);
        element.lastUpdated = new Date(this.updated_at);
        return element;
    }

    getRoute(): string {
        return Module.ORGANIZATION_GROUPS.buildRoute(this.id);
    }
}
