import {SegmentDataType} from "../../api/model/audience/segment/SegmentDataType";

export class SegmentTypeDefaultValue {
    static DECLARATIVE: SegmentTypeDefaultValue = new SegmentTypeDefaultValue(SegmentDataType.DECLARATIVE, 360, 1000);
    static INFERRED: SegmentTypeDefaultValue = new SegmentTypeDefaultValue(SegmentDataType.INFERRED, 90, 1000, 40);
    static INTENT: SegmentTypeDefaultValue = new SegmentTypeDefaultValue(SegmentDataType.INTENT, 30, 1000, 60);
    static INTEREST: SegmentTypeDefaultValue = new SegmentTypeDefaultValue(SegmentDataType.INTEREST, 90, 500, 20);
    static LOCATION: SegmentTypeDefaultValue = new SegmentTypeDefaultValue(SegmentDataType.LOCATION, 30, 0);
    static MODELING: SegmentTypeDefaultValue = new SegmentTypeDefaultValue(SegmentDataType.MODELING, 180, 1000);

    dataType: SegmentDataType;
    expirationTime: number;
    minScore: number;
    percentile?: number;

    private constructor(dataType: SegmentDataType, expirationTime: number, minScore: number, percentile?: number) {
        this.dataType = dataType;
        this.expirationTime = expirationTime;
        this.minScore = minScore;
        this.percentile = percentile;
    }

    static values(): SegmentTypeDefaultValue[] {
        return [
            SegmentTypeDefaultValue.DECLARATIVE,
            SegmentTypeDefaultValue.INFERRED,
            SegmentTypeDefaultValue.INTENT,
            SegmentTypeDefaultValue.INTEREST,
            SegmentTypeDefaultValue.LOCATION,
            SegmentTypeDefaultValue.MODELING
        ];
    }

    static getByDataType(dataType?: SegmentDataType): SegmentTypeDefaultValue | undefined {
        return this.values().find((it) => it.dataType.name === dataType?.name);
    }
}
