import {Action, ActionsMenu, FlexContent, FlexContentDirection, FlexContentSpacing, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {SirdataListEntityState} from "../../../../api/model/cmp/list/sirdata-list/SirdataListEntityState";
import {SirdataListStack} from "../../../../api/model/cmp/list/sirdata-list/SirdataListStack";
import {SirdataListStackField} from "../../../../api/model/cmp/list/sirdata-list/SirdataListStackField";
import {RestrictedContent} from "../../../../common/component/widget";
import {TranslationPortalFile} from "../../../../utils/constants";
import {CmpSirdataListEntityState, CmpSirdataListPurposesList} from "../../index";

type CmpSirdataListStackRowProps = {
    stack: SirdataListStack;
    state?: SirdataListEntityState;
    onEdit?: () => void;
    onDelete?: () => void;
};

const CmpSirdataListStackRow: FunctionComponent<CmpSirdataListStackRowProps> = ({stack, state, onEdit, onDelete}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);

    return (
        <TableRow>
            <TableColumn>
                {stack.id}
                <CmpSirdataListEntityState state={state}/>
            </TableColumn>
            <TableColumn>
                {stack.name}
                <IconTooltip
                    icon={{name: "help"}}
                    text={stack.description}
                />
            </TableColumn>
            <TableColumn>
                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                    <CmpSirdataListPurposesList title={textCmpSirdataList(`field.stack.${SirdataListStackField.PURPOSES}`)} values={stack.purposes}/>
                    <CmpSirdataListPurposesList title={textCmpSirdataList(`field.stack.${SirdataListStackField.REQUIRED_PURPOSES}`)} values={stack.requiredPurposes}/>
                    <CmpSirdataListPurposesList title={textCmpSirdataList(`field.stack.${SirdataListStackField.SPECIAL_FEATURES}`)} values={stack.specialFeatures}/>
                    <CmpSirdataListPurposesList title={textCmpSirdataList(`field.stack.${SirdataListStackField.EXTRA_PURPOSES}`)} values={stack.extraPurposes}/>
                </FlexContent>
            </TableColumn>
            {onEdit &&
                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                    <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                        <IconTooltip
                            icon={Action.EDIT.icon}
                            text={textCommon(Action.EDIT.labelKey)}
                            onClick={onEdit}
                        />
                        {state === SirdataListEntityState.ADDED &&
                            <ActionsMenu
                                iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                items={[
                                    {label: textCommon(Action.DELETE.labelKey), onClick: () => onDelete && onDelete(), critical: true}
                                ]}
                            />
                        }
                    </RestrictedContent>
                </TableActionColumn>
            }
        </TableRow>
    );
};

export default CmpSirdataListStackRow;
