import {SearchParams} from "../../../utils/SearchParams";
import {SearchQuery} from "../../interface/SearchQuery";
import {CategorizerConfigType} from "./CategorizerConfigType";

export enum CategorizerConfigSearchParamsField {
    STRICT = "strict",
}

export class CategorizerConfigSearchQuery extends SearchQuery {
    sanitizer?: string;
    intent_confidence?: string;
    filter?: string;
    enrichment?: string;
    url_categorization?: string;
    strict: boolean = false;

    withSearchParams(params: SearchParams): CategorizerConfigSearchQuery {
        let newQuery = super.withSearchParams(params) as CategorizerConfigSearchQuery;
        CategorizerConfigType.values().forEach((type) => {
            newQuery[type.name] = params.getString(type.name);
        });
        newQuery.strict = params.getBoolean(CategorizerConfigSearchParamsField.STRICT) || false;
        return newQuery;
    }
}
