export enum PricingDividerUnit {
    NONE = "NONE",
    COST_PER_MILLE = "COST_PER_MILLE",
    COST_PER_HUNDRED_THOUSAND = "COST_PER_HUNDRED_THOUSAND",
    COST_PER_MILLION = "COST_PER_MILLION"
}

export const PRICING_DIVIDER_UNITS = [
    {name: PricingDividerUnit.NONE, value: 1},
    {name: PricingDividerUnit.COST_PER_MILLE, value: 1000},
    {name: PricingDividerUnit.COST_PER_HUNDRED_THOUSAND, value: 100000},
    {name: PricingDividerUnit.COST_PER_MILLION, value: 1000000}
];
