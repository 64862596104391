export class RevenuePeriodType {
    static DAY: RevenuePeriodType = new RevenuePeriodType("DAY", "day");
    static WEEK: RevenuePeriodType = new RevenuePeriodType("WEEK", "week");
    static MONTH: RevenuePeriodType = new RevenuePeriodType("MONTH", "month");
    static DAY_BY_DAY: RevenuePeriodType = new RevenuePeriodType("DAY_BY_DAY", "day_by_day", true);
    static WEEK_BY_WEEK: RevenuePeriodType = new RevenuePeriodType("WEEK_BY_WEEK", "week_by_week", true);
    static MONTH_BY_MONTH: RevenuePeriodType = new RevenuePeriodType("MONTH_BY_MONTH", "month_by_month", true);

    name: string;
    field: string;
    isInterval: boolean;

    private constructor(name: string, field: string, isInterval: boolean = false) {
        this.name = name;
        this.field = field;
        this.isInterval = isInterval;
    }

    static values(): RevenuePeriodType[] {
        return [
            RevenuePeriodType.DAY,
            RevenuePeriodType.WEEK,
            RevenuePeriodType.MONTH,
            RevenuePeriodType.DAY_BY_DAY,
            RevenuePeriodType.WEEK_BY_WEEK,
            RevenuePeriodType.MONTH_BY_MONTH
        ];
    }

    static getByName(name: string): RevenuePeriodType | undefined {
        return this.values().find((it) => it.name === name);
    }
}
