import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {Segment} from "../model/audience/segment/Segment";
import {SegmentSearchQuery} from "../model/audience/segment/SegmentSearchQuery";
import {SegmentSearchResult} from "../model/audience/segment/SegmentSearchResult";

export class RestSegment extends Rest implements RestService<Segment> {
    rootPath = "/console-api/segment";

    search(params: SegmentSearchQuery): Promise<SegmentSearchResult> {
        return this._client.get(`${this.rootPath}/search?${params.toQueryString()}`, SegmentSearchResult) as Promise<SegmentSearchResult>;
    }

    get(segmentId: number): Promise<Segment> {
        return this._client.get(`${this.rootPath}/${segmentId}`, Segment) as Promise<Segment>;
    }

    create(segment: Segment): Promise<Segment> {
        return this._client.post(`${this.rootPath}`, segment, Segment) as Promise<Segment>;
    }

    duplicate(segment: Segment): Promise<Segment> {
        return this._client.post(`${this.rootPath}/${segment.id}/duplicate`, segment, Segment) as Promise<Segment>;
    }

    update(segment: Segment): Promise<Segment> {
        return this._client.put(`${this.rootPath}/${segment.id}`, segment, Segment) as Promise<Segment>;
    }

    delete(segmentId: number) {
        return this._client.delete( `${this.rootPath}/${segmentId}`);
    }

    export(params: SegmentSearchQuery): Promise<Blob> {
        return this._client.get(`${this.rootPath}/export?${params.toQueryString()}`) as Promise<Blob>;
    }

    getTiersOptions(level: number, ...parents: string[]): Promise<string[]> {
        const params = parents.map((tier: string, index: number) => `tiers${index+1}=${encodeURIComponent(tier)}`);
        return this._client.get(`${this.rootPath}/tiers/${level}?${params.join("&")}`) as Promise<string[]>;
    }

    refreshPrices() {
        return this._client.post(`${this.rootPath}/prices/refresh`);
    }
}
