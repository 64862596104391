import React, {FunctionComponent} from "react";

export type PrintViewerProps = {
    children: React.ReactNode;
};

const PrintViewer: FunctionComponent<PrintViewerProps> = ({children}) => {
    return (
        <div className="print-viewer">
            {children}
        </div>
    );
};

export default PrintViewer;
