import {Select, TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";
import {DataleaksQuestionTextsField} from "../../../api/model/dataleaks/question/DataleaksQuestionTextsField";
import {DataleaksVersionQuestion} from "../../../api/model/dataleaks/version/DataleaksVersionQuestion";
import {DataleaksVersionQuestionField} from "../../../api/model/dataleaks/version/DataleaksVersionQuestionField";
import {Locale} from "../../../common/utils/Locale";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksSeverity} from "../../../api/model/dataleaks/DataleaksSeverity";
import {DataleaksSeverityFlag, Tag} from "../index";

type DataleaksVersionQuestionItemProps = {
    item: DataleaksVersionQuestion;
    question: DataleaksQuestion;
    onChange: (field: DataleaksVersionQuestionField, value: any) => void;
};

const DataleaksVersionQuestionItem: FunctionComponent<DataleaksVersionQuestionItemProps> = ({item, question, onChange}) => {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);

    const getText = (field: DataleaksQuestionTextsField): string => {
        const texts = question.texts.get(Locale.FRENCH);
        if (!texts) return "";
        return texts![field];
    };

    return (
        <TableRow>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {item.index}
            </TableColumn>
            <TableColumn>
                {getText(DataleaksQuestionTextsField.TITLE)}
            </TableColumn>
            <TableColumn>
                {question.trait &&
                    <Tag label={textDataleaksQuestions(`trait.${question.trait}`)} style={TagStyle.PRIMARY_OCEAN}/>
                }
            </TableColumn>
            <TableColumn>
                <Select
                    value={item.severity}
                    options={Object.values(DataleaksSeverity).map((severity) => ({label: textDataleaksAudits(`severity.${severity}`), value: severity}))}
                    onChange={(option) => onChange(DataleaksVersionQuestionField.SEVERITY, option?.value)}
                    small
                />
                <DataleaksSeverityFlag severity={item.severity}/>
            </TableColumn>
        </TableRow>
    );
};

export default DataleaksVersionQuestionItem;
