import {Rest} from "../../common/api/rest/Rest";
import {JiraIssue} from "../model/ticketing/JiraIssue";
import {JiraIssueKey} from "../model/ticketing/JiraIssueKey";
import {JiraPriority} from "../model/ticketing/JiraPriority";
import {JiraProject} from "../model/ticketing/JiraProject";

export class RestJira extends Rest {
    rootPath = "/console-api/jira";

    getPriorities(): Promise<JiraPriority[]> {
        return this._client.get(`${this.rootPath}/priority`, JiraPriority) as Promise<JiraPriority[]>;
    }

    getProject(projectId: string): Promise<JiraProject> {
        return this._client.get(`${this.rootPath}/project/${projectId}`, JiraProject) as Promise<JiraProject>;
    }

    create(issue: JiraIssue): Promise<JiraIssueKey> {
        return this._client.post(`${this.rootPath}/issue`, issue, JiraIssueKey) as Promise<JiraIssueKey>;
    }
}
