export class CmpConfigThemeToolbarStyle {
    static TEXT: CmpConfigThemeToolbarStyle = new CmpConfigThemeToolbarStyle("TEXT");
    static TEXT_ICON: CmpConfigThemeToolbarStyle = new CmpConfigThemeToolbarStyle("TEXT_ICON");
    static ICON: CmpConfigThemeToolbarStyle = new CmpConfigThemeToolbarStyle("ICON");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CmpConfigThemeToolbarStyle[] {
        return [
            CmpConfigThemeToolbarStyle.TEXT,
            CmpConfigThemeToolbarStyle.TEXT_ICON,
            CmpConfigThemeToolbarStyle.ICON
        ];
    }

    static getByName(name: string): CmpConfigThemeToolbarStyle | undefined {
        return this.values().find((it) => it.name === name);
    }
}
