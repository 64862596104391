import {Rest} from "../../common/api/rest/Rest";
import {CategoryPanel} from "../model/audience/category/panel/CategoryPanel";
import {CategoryDomain} from "../model/audience/category/panel/CategoryDomain";
import {Category} from "../model/audience/category/Category";

export class RestModeling extends Rest {
    rootPath = "/console-api/modeling";

    getCategoryDomains(category: Category): Promise<CategoryDomain[]> {
        return this._client.get(`${this.rootPath}/category-domain/${category.id}`) as Promise<CategoryDomain[]>;
    }

    importPanel(panel: CategoryPanel): Promise<CategoryDomain[]> {
        return this._client.post(`${this.rootPath}/panel/${panel.type}/category/${panel.category_id}`,
            panel.file,
            CategoryDomain,
            {contentType: panel.content_type}) as Promise<CategoryDomain[]>;
    }

    populateCategories(...categoryIds: number[]) {
        return this._client.post(`${this.rootPath}/panel/rollback?categories=${categoryIds.join(",")}`);
    }
}
