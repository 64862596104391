import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Select, Table, ToggleSwitch} from "@sirdata/ui-lib";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {AdsAccessRow, MainContentHeader, MainContentHeaderAction, SelectPartner} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import useAlert from "../../utils/hooks/useAlert";
import {AdsAccess as AdsAccessModel} from "../../api/model/audience/ads/AdsAccess";
import {AdsAccessField} from "../../api/model/audience/ads/AdsAccessField";
import {AdsAccessConfigurationField} from "../../api/model/audience/ads/AdsAccessConfigurationField";
import {session} from "../../api/ApiSession";
import useSearch from "../../utils/hooks/useSearch";
import {AdsAccessSearchQuery} from "../../api/model/audience/ads/AdsAccessSearchQuery";
import {AdsAccessSearchResult} from "../../api/model/audience/ads/AdsAccessSearchResult";
import {Partner} from "../../api/model/partner/Partner";
import ModalCreateAdsAccess from "../../component/modal/ads-access/ModalCreateAdsAccess";
import {Authorization} from "../../api/model/account/Authorization";
import {AdsAccessAuthorization} from "../../api/model/audience/ads/AdsAccessAuthorization";

function AdsAccess() {
    const alert = useAlert();
    const {t: textAdsAccess} = useTranslation(TranslationPortalFile.ADS_ACCESS);
    const [isLoading, setLoading] = useState(true);
    const {setSearchResult, ...search} = useSearch(AdsAccessModel, AdsAccessSearchQuery, AdsAccessSearchResult);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [isShowModalCreateAdsAccess, setShowModalCreateAdsAccess] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const searchResult = await session.restAdsAccess.search(search.searchQuery);
                setSearchResult(searchResult);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("access", e.message);
                }
            }  finally {
                setLoading(false);
            }

            try {
                const partners = await session.getPartners();
                setPartners(partners);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partners", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, search.searchQuery, setSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.ADS}>
                <RestrictedContent allowedTo={Authorization.ADS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateAdsAccess(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textAdsAccess("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            onClearFilters={search.clearFilters}
                            canHideFilters
                        >
                            <SearchField label={textAdsAccess(`field.${AdsAccessField.FEATURE_ACCESS}`)}>
                                <Select
                                    value={search.searchQuery.feature_access}
                                    options={AdsAccessAuthorization.values().map((it) => ({value: it.name, label: it.label}))}
                                    onChange={(option) => search.changeParam(AdsAccessField.FEATURE_ACCESS, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.SHARED_TAXONOMY}`)}>
                                <SelectPartner
                                    value={search.searchQuery.shared_taxonomy}
                                    onChange={(partner) => search.changeParam(AdsAccessConfigurationField.SHARED_TAXONOMY, partner?.id)}
                                />
                            </SearchField>
                            <SearchField label={textAdsAccess(`field.${AdsAccessField.PUBLIC_TAXONOMY}`)} shortWidth>
                                <ToggleSwitch
                                    checked={!!search.searchQuery.public_taxonomy}
                                    onChange={(value) => search.changeParam(AdsAccessField.PUBLIC_TAXONOMY, +value)}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    columns={[
                                        {width: 5, label: textAdsAccess("field.status")},
                                        {width: 5, label: textAdsAccess(`field.${AdsAccessField.ID}`)},
                                        {width: 15, label: textAdsAccess(`field.${AdsAccessField.NAME}`)},
                                        {width: 20, label: textAdsAccess(`field.${AdsAccessField.PARTNER_ID}`)},
                                        {width: 30, label: textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.SHARED_TAXONOMY}`)},
                                        {width: 25, label: textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.GLOBAL_TAXONOMY}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((adsAccess) =>
                                        <AdsAccessRow
                                            key={adsAccess.id}
                                            adsAccess={adsAccess}
                                            partner={partners.find((partner) => adsAccess.partner_id === partner.id)}
                                            sharedTaxonomyPartners={partners.filter((partner) => adsAccess.configuration.shared_taxonomy?.includes(partner.id))}
                                            globalTaxonomyPartners={partners.filter((partner) => adsAccess.configuration.global_taxonomy?.includes(partner.id))}
                                        />
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateAdsAccess
                    active={isShowModalCreateAdsAccess}
                    onClose={() => setShowModalCreateAdsAccess(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default AdsAccess;
