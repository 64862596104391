export enum HelperPrivacyConfigurationTruncateIp {
    REMOVE = "REMOVE",
    TRUNCATE_ONE_BYTE = "TRUNCATE_ONE_BYTE",
    TRUNCATE_TWO_BYTE = "TRUNCATE_TWO_BYTE",
}

export const PRIVACY_CONFIGURATION_TRUNCATE_IP_VALUES = [
    HelperPrivacyConfigurationTruncateIp.TRUNCATE_ONE_BYTE,
    HelperPrivacyConfigurationTruncateIp.TRUNCATE_TWO_BYTE,
    HelperPrivacyConfigurationTruncateIp.REMOVE
];
