import {CategorizerConfig} from "./CategorizerConfig";

export class CategorizerConfigLinks extends CategorizerConfig {
    css_query: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            css_query: this.css_query
        };
    }
}
