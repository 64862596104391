export class PartnerBillingPaymentType {
    static BANK_TRANSFER: PartnerBillingPaymentType = new PartnerBillingPaymentType("BANK_TRANSFER");
    static DIRECT_DEBIT: PartnerBillingPaymentType = new PartnerBillingPaymentType("DIRECT_DEBIT");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): PartnerBillingPaymentType[] {
        return [
            PartnerBillingPaymentType.BANK_TRANSFER,
            PartnerBillingPaymentType.DIRECT_DEBIT
        ];
    }
}
