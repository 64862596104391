import {Action, ActionsMenu, TableColumn, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {LabelStatus} from "../..";
import {Authorization} from "../../../../api/model/account/Authorization";
import {SgtmContainerHostDetail} from "../../../../api/model/sgtm/container/SgtmContainerHostDetail";
import {RestrictedContent} from "../../../../common/component/widget";

type SgtmContainerHostRowProps = {
    host: SgtmContainerHostDetail;
    onRemove?: () => void;
};

const SgtmContainerHostRow: FunctionComponent<SgtmContainerHostRowProps> = ({host, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <TableRow>
            <TableColumn>
                <LabelStatus status={host.getStatus()}/>
            </TableColumn>
            <TableColumn>{host.subdomain}</TableColumn>
            <TableColumn>{host.fqdn}</TableColumn>
            <TableColumn>
                {onRemove &&
                    <RestrictedContent allowedTo={Authorization.SGTM.update}>
                        <ActionsMenu
                            iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                            items={[{label: textCommon(Action.REMOVE.labelKey), onClick: onRemove, critical: true, separator: true}]}
                        />
                    </RestrictedContent>
                }
            </TableColumn>
        </TableRow>
    );
};

export default SgtmContainerHostRow;
