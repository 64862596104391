export enum CmpConfigThemeField {
    LIGHT_MODE = "lightMode",
    DARK_MODE = "darkMode",
    BORDER_RADIUS = "borderRadius",
    TEXT_SIZE = "textSize",
    FONT_FAMILY = "fontFamily",
    FONT_FAMILY_TITLE = "fontFamilyTitle",
    OVERLAY = "overlay",
    WHITE_LABEL = "whiteLabel",
    NO_CONSENT_BUTTON = "noConsentButton",
    NO_CONSENT_BUTTON_STYLE = "noConsentButtonStyle",
    SET_CHOICES_STYLE = "setChoicesStyle",
    CLOSE_BUTTON = "closeButton",
    POSITION = "position",
    TOOLBAR = "toolbar",
}
