import {SearchParams} from "../../../../utils/SearchParams";
import {SearchQuery} from "../../../interface/SearchQuery";
import {SearchParamsField} from "../../../../utils/SearchParamsField";

export class PricingSearchQuery extends SearchQuery {
    service: string | undefined;

    withSearchParams(params: SearchParams): PricingSearchQuery {
        let newQuery = super.withSearchParams(params) as PricingSearchQuery;
        newQuery.service = params.getString(SearchParamsField.SERVICE) || "";
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            api_service: this.service
        };
    }
}
