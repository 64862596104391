import {ApiModel} from "../../../common/api/model/ApiModel";

export class BanProperty extends ApiModel {
    ban_axciom: boolean = false;
    ban_differ_hashmail: boolean = false;
    ban_hashmail: boolean = false;
    ban_mapping: boolean = false;
    ban_smart: boolean = false;
    ban_thisisbig: boolean = true;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
