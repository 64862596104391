import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ContentBlock, LayoutColumns, LayoutRows, SearchError, SearchToolbar, Table} from "@sirdata/ui-lib";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {sortItems} from "../../../common/utils/helper";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {session} from "../../../api/ApiSession";
import useAlert from "../../../utils/hooks/useAlert";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersion";
import {SirdataListVendor} from "../../../api/model/cmp/list/sirdata-list/SirdataListVendor";
import {SirdataListStack} from "../../../api/model/cmp/list/sirdata-list/SirdataListStack";
import {SirdataListPurpose} from "../../../api/model/cmp/list/sirdata-list/SirdataListPurpose";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersionField";
import {SirdataListVendorField} from "../../../api/model/cmp/list/sirdata-list/SirdataListVendorField";
import {SirdataListStackField} from "../../../api/model/cmp/list/sirdata-list/SirdataListStackField";
import {SirdataListPurposeField} from "../../../api/model/cmp/list/sirdata-list/SirdataListPurposeField";
import {CmpConfigLanguage} from "../../../api/model/cmp/config/CmpConfigLanguage";
import {CmpConfigLanguageToolbar, CmpSirdataListPurposeRow, CmpSirdataListStackRow, CmpSirdataListVendorRow} from "../index";

type CmpSirdataListCurrentVersionProps = {
    defaultLatestVersion: SirdataListVersion;
};

const CmpSirdataListCurrentVersion: FC<CmpSirdataListCurrentVersionProps> = ({defaultLatestVersion}) => {
    const alert = useAlert();
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const [latestVersion, setLatestVersion] = useState<SirdataListVersion>(new SirdataListVersion());
    const [currentLanguage, setCurrentLanguage] = useState<CmpConfigLanguage>(CmpConfigLanguage.ENGLISH);
    const [stacks, setStacks] = useState<SirdataListStack[]>([]);
    const [purposes, setPurposes] = useState<SirdataListPurpose[]>([]);
    const [vendorQuery, setVendorQuery] = useState("");
    const [filteredVendors, setFilteredVendors] = useState<SirdataListVendor[]>([]);

    useEffect(() => {
        setLatestVersion(defaultLatestVersion);
    }, [defaultLatestVersion]);

    useEffect(() => {
        setStacks(latestVersion.stacks);
        setPurposes(latestVersion.purposes);
    }, [latestVersion.stacks, latestVersion.purposes]);

    useEffect(() => {
        setFilteredVendors(defaultLatestVersion.vendors.filter((vendor) => vendor.name.toLowerCase().includes(vendorQuery.toLowerCase())));
    }, [defaultLatestVersion.vendors, vendorQuery]);

    const handleSortVendors = (field: string, reverseOrder?: boolean) => {
        setFilteredVendors((prevState) => sortItems([...prevState], field, reverseOrder));
    };

    const handleSortStacks = (field: string, reverseOrder?: boolean) => {
        setStacks((prevState) => sortItems([...prevState], field, reverseOrder));
    };

    const handleSortPurposes = (field: string, reverseOrder?: boolean) => {
        setPurposes((prevState) => sortItems([...prevState], field, reverseOrder));
    };

    const loadLatestVersionForLanguage = async (language: CmpConfigLanguage) => {
        try {
            const latestVersionForLanguage = await session.restCmpSirdataListVersion.getLatestVersion(language.name);
            setLatestVersion(latestVersionForLanguage);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("latest version", e.message);
            }
        }
    };

    return (
        <LayoutRows>
            <CmpConfigLanguageToolbar
                value={currentLanguage}
                onChange={async (language) => {
                    setCurrentLanguage(language);
                    await loadLatestVersionForLanguage(language);
                }}
            />
            <LayoutColumns>
                <ContentBlock header={{title: {label: textCmpSirdataList(`field.${SirdataListVersionField.STACKS}`)}}}>
                    <Table
                        onSort={handleSortStacks}
                        columns={[
                            {width: 5, label: textCmpSirdataList(`field.stack.${SirdataListStackField.ID}`), sort: {field: SirdataListStackField.ID, reverseOrder: false}},
                            {width: 45, label: textCmpSirdataList(`field.stack.${SirdataListStackField.NAME}`)},
                            {width: 50, label: textCmpSirdataList("purposes")}
                        ]}
                        messageIfEmpty={textCmpSirdataList("message.no_stacks")}
                    >
                        {stacks.map((stack) =>
                            <CmpSirdataListStackRow
                                key={stack.id}
                                stack={stack}
                            />
                        )}
                    </Table>
                </ContentBlock>
                <ContentBlock header={{title: {label: textCmpSirdataList(`field.${SirdataListVersionField.PURPOSES}`)}}}>
                    <Table
                        onSort={handleSortPurposes}
                        columns={[
                            {width: 5, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.ID}`), sort: {field: SirdataListPurposeField.ID, reverseOrder: false}},
                            {width: 95, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.NAME}`)}
                        ]}
                        messageIfEmpty={textCmpSirdataList("message.no_purposes")}
                    >
                        {purposes.map((purpose) =>
                            <CmpSirdataListPurposeRow
                                key={purpose.id}
                                purpose={purpose}
                            />
                        )}
                    </Table>
                </ContentBlock>
            </LayoutColumns>
            <ContentBlock header={{title: {label: textCmpSirdataList(`field.${SirdataListVersionField.VENDORS}`)}}}>
                <SearchToolbar
                    searchBar={{
                        value: vendorQuery,
                        placeholder: textCmpSirdataList(`search.placeholder.${SirdataListVersionField.VENDORS}`),
                        onSubmit: setVendorQuery
                    }}
                />
                {!!filteredVendors.length || !vendorQuery ?
                    <Table
                        onSort={handleSortVendors}
                        columns={[
                            {width: 5, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.ID}`), sort: {field: SirdataListVendorField.ID, reverseOrder: false}},
                            {width: 30, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.NAME}`)},
                            {width: 35, label: textCmpSirdataList("field.tcf_purposes")},
                            {width: 30, label: textCmpSirdataList("field.sirdata_purposes")}
                        ]}
                        pagination={PAGE_SIZE}
                        messageIfEmpty={textCmpSirdataList("message.no_vendors")}
                    >
                        {filteredVendors.map((vendor) =>
                            <CmpSirdataListVendorRow
                                key={vendor.id}
                                vendor={vendor}
                                latestDeletedDate={vendor.deletedDate}
                            />
                        )}
                    </Table> :
                    <SearchError query={vendorQuery}/>
                }
            </ContentBlock>
        </LayoutRows>
    );
};

export default CmpSirdataListCurrentVersion;
