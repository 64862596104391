import {ApiModel} from "../../../../../common/api/model/ApiModel";

export class Vendor extends ApiModel {
    id: number = 0;
    name: string = "";
    purposes: number[] = [];
    legIntPurposes: number[] = [];
    flexiblePurposes: number[] = [];
    specialPurposes: number[] = [];
    features: number[] = [];
    specialFeatures: number[] = [];
    policyUrl: string = "";
    deletedDate: string = "";
    usesCookies: boolean = false;
    cookieMaxAgeSeconds: number = 0;
    cookieRefresh: boolean = false;
    usesNonCookieAccess: boolean = false;
    deviceStorageDisclosureUrl: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
