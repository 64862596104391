import {ApiModel} from "../../../common/api/model/ApiModel";

export class JiraIssueKey extends ApiModel {
    key: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
