import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {SirdataListVersion} from "./SirdataListVersion";
import {detectChanges} from "../../../../../common/utils/portal";
import {SirdataListEntityState} from "./SirdataListEntityState";

export class SirdataListVendor extends ApiModel {
    id: number = 0;
    name: string = "";
    purposes: number[] = [];
    legIntPurposes: number[] = [];
    extraPurposes: number[] = [];
    legIntExtraPurposes: number[] = [];
    policyUrl: string = "";
    premium: boolean = false;
    deletedDate?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            name: this.name,
            purposes: this.purposes,
            legIntPurposes: this.legIntPurposes,
            extraPurposes: this.extraPurposes,
            legIntExtraPurposes: this.legIntExtraPurposes,
            policyUrl: this.policyUrl,
            premium: this.premium,
            deletedDate: this.deletedDate
        };
    }

    isValid(): boolean {
        if (!this.name || !this.policyUrl) {
            return false;
        }

        if (![...this.purposes, this.legIntPurposes, ...this.extraPurposes, ...this.legIntExtraPurposes].length) {
            return false;
        }

        return !(this.purposes.some((id) => this.legIntPurposes.includes(id))
            || this.legIntPurposes.some((id) => this.purposes.includes(id))
            || this.extraPurposes.some((id) => this.legIntExtraPurposes.includes(id))
            || this.legIntExtraPurposes.some((id) => this.extraPurposes.includes(id)));
    }

    getState(latestVersion: SirdataListVersion): SirdataListEntityState | undefined {
        const vendor = latestVersion.vendors.find((it) => it.id === this.id);
        if (!vendor) {
            return SirdataListEntityState.ADDED;
        } else if (!vendor.deletedDate && this.deletedDate) {
            return SirdataListEntityState.DELETED;
        } else if (vendor.deletedDate && !this.deletedDate) {
            return SirdataListEntityState.RESTORED;
        } else if (detectChanges(vendor, this)) {
            return SirdataListEntityState.UPDATED;
        }
    }
}
