import React, {FunctionComponent, useState} from "react";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";
import {Locale} from "../../../common/utils/Locale";
import {ButtonLink, ButtonLinkStyle, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentJustify, FlexContentSpacing, Icon, InputRadio, Paragraph} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksQuestionTextsField} from "../../../api/model/dataleaks/question/DataleaksQuestionTextsField";
import {DataleaksVersionQuestion} from "../../../api/model/dataleaks/version/DataleaksVersionQuestion";
import clsx from "clsx";
import {DataleaksSeverityFlag} from "../index";

type DataleaksAuditQuestionProps = {
    item: DataleaksVersionQuestion;
    question: DataleaksQuestion;
    answer: boolean | undefined;
    onChange: (questionId: number, answer: boolean) => void;
}

const DataleaksAuditQuestion: FunctionComponent<DataleaksAuditQuestionProps> = ({item, question, answer, onChange}) => {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);
    const [isShowDescriptionLegal, setShowDescriptionLegal] = useState(false);

    const getText = (field: DataleaksQuestionTextsField): string => {
        const texts = question.texts.get(Locale.FRENCH);
        if (!texts) return "";
        return texts![field];
    };

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            alignment={FlexContentAlignment.START}
            justify={FlexContentJustify.SPACE_BETWEEN}
            spacing={FlexContentSpacing.XLARGE}
            cssClass={"dataleaks__question"}
        >
            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL}>
                <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START}>
                    <span className="dataleaks__question__chip">{item.index}</span>
                    <span className="h3 dataleaks__question__title">
                        {getText(DataleaksQuestionTextsField.TITLE)}
                        <DataleaksSeverityFlag severity={item.severity} cssClass={"dataleaks__question__flag"}/>
                    </span>
                </FlexContent>
                <Paragraph>
                    {getText(DataleaksQuestionTextsField.DESCRIPTION)}
                    {!!getText(DataleaksQuestionTextsField.DESCRIPTION_LEGAL) &&
                    <>
                        <ButtonLink
                            style={ButtonLinkStyle.GREY}
                            onClick={() => setShowDescriptionLegal((prevState) => !prevState)}
                            cssClass="dataleaks__question__link"
                        >
                            {textDataleaksAudits(isShowDescriptionLegal ? "see_less" : "see_more")}
                        </ButtonLink>
                        {isShowDescriptionLegal && getText(DataleaksQuestionTextsField.DESCRIPTION_LEGAL)}
                    </>
                    }
                </Paragraph>
            </FlexContent>
            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL}>
                <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XSMALL} cssClass="dataleaks__question__buttons">
                    <div className="dataleaks__question__buttons__item">
                        <InputRadio
                            id={`radio-${question.id}-true`}
                            value={"true"}
                            checked={answer === true}
                            onChange={() => onChange(question?.id, true)}
                        />
                        <label htmlFor={`radio-${question.id}-true`} className="dataleaks__question__buttons__item__label">{textDataleaksAudits("answer.yes")}</label>
                    </div>
                    <div className="dataleaks__question__buttons__item">
                        <InputRadio
                            id={`radio-${question.id}-false`}
                            value={"false"}
                            checked={answer === false}
                            onChange={() => onChange(question?.id, false)}
                        />
                        <label htmlFor={`radio-${question.id}-false`} className="dataleaks__question__buttons__item__label">{textDataleaksAudits("answer.no")}</label>
                    </div>
                </FlexContent>
                {answer !== undefined && (
                    question.isValidAnswer(answer) ?
                        <div className={clsx("dataleaks__question__result", "dataleaks__question__result--success")}>
                            <Icon name="task_alt" cssClass="dataleaks__question__result__icon"/>
                            <span className="dataleaks__question__result__label">{textDataleaksAudits("good_answer")}</span>
                        </div> :
                        <div className={clsx("dataleaks__question__result", `dataleaks__question__result--${item.severity.toLowerCase()}`)}>
                            <Icon name="report" cssClass="dataleaks__question__result__icon"/>
                            <span className="dataleaks__question__result__label">{textDataleaksAudits("bad_answer")}</span>
                        </div>
                )}
            </FlexContent>
        </FlexContent>
    );
};

export default DataleaksAuditQuestion;
