import React, {FunctionComponent} from "react";
import {FlexContent, FlexContentDirection, FlexContentLayout, FlexContentSpacing, IconTooltip} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigTheme} from "../../../api/model/cmp/config/theme/CmpConfigTheme";
import {CmpConfigThemeModeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import CmpConfigurationImageManager from "./CmpConfigurationImageManager";

type CmpConfigurationThemeImagesProps = {
    theme: CmpConfigTheme;
    field: CmpConfigThemeModeField.LOGO | CmpConfigThemeModeField.SKIN | CmpConfigThemeModeField.WATERMARK;
    onChange: (mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE, field: CmpConfigThemeModeField, newImage: string, applyBothMode?: boolean) => void;
    onDelete: (mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE, field: CmpConfigThemeModeField) => void;
    partnerId: number;
    maxFileSize?: number;
    lightModeAsDefault?: boolean;
}

const CmpConfigurationThemeImages: FunctionComponent<CmpConfigurationThemeImagesProps> = ({
    theme,
    field,
    onChange,
    onDelete,
    partnerId,
    maxFileSize,
    lightModeAsDefault
}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            layout={FlexContentLayout.COLUMNS}
            spacing={FlexContentSpacing.MEDIUM}
            cssClass="theme-images"
        >
            <div className="theme-images__mode">
                <IconTooltip
                    icon={{name: "wb_sunny", cssClass: "theme-images__mode__icon"}}
                    text={textCmpConfigurations(lightModeAsDefault ? "theme_mode.light_default" : "theme_mode.light")}
                />
                <CmpConfigurationImageManager
                    field={field}
                    mode={CmpConfigThemeField.LIGHT_MODE}
                    currentImage={theme?.lightMode[field] || ""}
                    onSave={onChange}
                    onRemove={onDelete}
                    hasApplyBothModes
                    partnerId={partnerId}
                    maxFileSize={maxFileSize}
                />
            </div>
            <div className="theme-images__mode">
                <IconTooltip
                    icon={{name: "brightness_2", cssClass: "theme-images__mode__icon theme-images__mode__icon--dark"}}
                    text={textCmpConfigurations("theme_mode.dark")}
                />
                <CmpConfigurationImageManager
                    field={field}
                    mode={CmpConfigThemeField.DARK_MODE}
                    currentImage={theme?.darkMode[field] || ""}
                    onSave={onChange}
                    onRemove={onDelete}
                    hasApplyBothModes
                    partnerId={partnerId}
                    maxFileSize={maxFileSize}
                />
            </div>
        </FlexContent>
    );
};

export default CmpConfigurationThemeImages;
