export enum AdsAccessField {
    ID = "id",
    NAME = "name",
    PARTNER_ID = "partner_id",
    SALT = "salt",
    ACCESS_END = "access_end",
    CONSENT_OUTSIDE_TCF = "consent_outside_tcf",
    COLLECT_DATA = "collect_data",
    PUBLIC_TAXONOMY = "public_taxonomy",
    FEATURE_ACCESS = "feature_access",
    ACTIVE = "active",
    CONFIGURATION = "configuration",
    CREATION_DATE = "creation_date",
    LAST_UPDATE = "last_update"
}
