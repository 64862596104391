import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {Vendor} from "./Vendor";
import {Purpose} from "./Purpose";
import {Stack} from "./Stack";
import {GlobalVendorListField} from "./GlobalVendorListField";
import {GlobalVendorListVersion} from "./GlobalVendorListVersion";

export class GlobalVendorList extends ApiModel {
    gvlSpecificationVersion: number = GlobalVendorListVersion.V3;
    vendorListVersion: number = 0;
    tcfPolicyVersion: number = 0;
    lastUpdated: string = "";
    purposes: Purpose[] = [];
    specialPurposes: Purpose[] = [];
    features: Purpose[] = [];
    specialFeatures: Purpose[] = [];
    stacks: Stack[] = [];
    vendors: Vendor[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObjects(GlobalVendorListField.PURPOSES, Purpose);
        this.loadObjects(GlobalVendorListField.SPECIAL_PURPOSES, Purpose);
        this.loadObjects(GlobalVendorListField.FEATURES, Purpose);
        this.loadObjects(GlobalVendorListField.SPECIAL_FEATURES, Purpose);
        this.loadObjects(GlobalVendorListField.STACKS, Stack);
        this.loadObjects(GlobalVendorListField.VENDORS, Vendor);
    }

    getName(field: GlobalVendorListField.PURPOSES | GlobalVendorListField.SPECIAL_PURPOSES | GlobalVendorListField.FEATURES | GlobalVendorListField.SPECIAL_FEATURES, id: number): string {
        return this[field].find((it) => it.id === id)?.name || "";
    }
}
