import {ApiModel} from "../../../common/api/model/ApiModel";
import clsx from "clsx";
import {CountryIsoCode} from "../../../common/api/model/CountryIsoCode";
import {PartnerAccountType} from "./PartnerAccountType";
import {Status} from "../../../utils/Status";
import {Partner} from "./Partner";
import {CountryCode} from "../../../common/api/model/Country";
import {PartnerAccountProperties} from "./PartnerAccountProperties";

export class PartnerAccount extends ApiModel {
    id: number = 0;
    active: boolean = true;
    email: string = "";
    email_update: string = "";
    first_name: string = "";
    last_name: string = "";
    position: string = "";
    phone: string = "";
    phone_region: CountryIsoCode = CountryCode.FRANCE.code;
    type: PartnerAccountType = PartnerAccountType.OWNER;
    partner_id: number = 0;
    partner: Partner | undefined;
    properties: PartnerAccountProperties = {};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "partner") {
                    this[key] = new Partner(o[key]);
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    get fullName(): string {
        return clsx(this.first_name, this.last_name);
    }

    getJsonParameters(): {} {
        return {
            active: this.active,
            email: this.email,
            email_update: this.email_update,
            first_name: this.first_name,
            last_name: this.last_name,
            position: this.position,
            phone: this.phone,
            phone_region: this.phone_region,
            type: this.type
        };
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }
}
