export enum CategoryGroupField {
    ID = "id",
    ID_PARTNER = "id_partner",
    ID_PARENT = "id_parent",
    ID_EXTERNAL = "id_ext",
    NAME = "name",
    CODE_NAME = "code_name",
    DESCRIPTION = "description",
    TAXONOMY = "taxonomy",
    LAST_UPDATE = "last_update",
    CATS = "cats",
    LINKS = "links",
}
