import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {Pricing} from "../model/partner/pricing/Pricing";

export class RestPricing extends Rest implements RestService<Pricing> {
    rootPath = "/console-api/pricing";

    search(params: any): Promise<SearchResult<Pricing>> {
        return new Promise<SearchResult<Pricing>>(() => {}); // TODO Implement route
    }

    list(): Promise<Pricing[]> {
        return this._client.get(`${this.rootPath}`, Pricing) as Promise<Pricing[]>;
    }

    create(pricing: Pricing): Promise<Pricing> {
        return this._client.post(`${this.rootPath}`, pricing, Pricing) as Promise<Pricing>;
    }

    get(pricingId: number): Promise<Pricing> {
        return this._client.get(`${this.rootPath}/${pricingId}`, Pricing) as Promise<Pricing>;
    }

    update(pricing: Pricing): Promise<Pricing> {
        return this._client.put(`${this.rootPath}/${pricing.id}`, pricing, Pricing) as Promise<Pricing>;
    }

    delete(pricingId: number) {
        return this._client.delete(`${this.rootPath}/${pricingId}`);
    }
}
