import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Language} from "../../../../common/api/model/Language";
import {pathDataleaksReport} from "../../../../utils/constants";
import {Module} from "../../../../utils/Module";
import {ContentElement} from "../../../interface/ContentElement";
import {DataleaksAuditCompany} from "./DataleaksAuditCompany";
import {DataleaksAuditContact} from "./DataleaksAuditContact";
import {DataleaksAuditField} from "./DataleaksAuditField";
import {DataleaksAuditInfos} from "./DataleaksAuditInfos";
import {DataleaksAuditScoring} from "./DataleaksAuditScoring";
import {PathHelper} from "../../../../common/utils/PathHelper";

export class DataleaksAudit extends ApiModel {
    id: number = 0;
    partner_id?: number;
    company: DataleaksAuditCompany = new DataleaksAuditCompany();
    contact: DataleaksAuditContact = new DataleaksAuditContact();
    domain: string = "";
    version_id: number = 0;
    infos: DataleaksAuditInfos = new DataleaksAuditInfos();
    _answers: Map<number, boolean> = new Map<number, boolean>();
    scoring: DataleaksAuditScoring = new DataleaksAuditScoring();
    comment: string = "";
    notification_date?: string;
    user_id: number = 0;
    readonly created_at: string = "";
    readonly updated_at: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                switch (key) {
                    case DataleaksAuditField.COMPANY:
                        this[key] = new DataleaksAuditCompany(o[key]);
                        break;
                    case DataleaksAuditField.CONTACT:
                        this[key] = new DataleaksAuditContact(o[key]);
                        break;
                    case DataleaksAuditField.INFOS:
                        this[key] = new DataleaksAuditInfos(o[key]);
                        break;
                    case DataleaksAuditField.SCORING:
                        this[key] = new DataleaksAuditScoring(o[key]);
                        break;
                    default:
                        this[key] = o[key];
                }
            }
        }
    }

    get answers(): Map<number, boolean> {
        return this._answers;
    }

    set answers(answers: Map<number, boolean>) {
        if (answers instanceof Map || !answers) {
            this._answers = answers;
            return;
        }

        const entries = Object.entries<boolean>(answers);
        const mapAnswers = new Map<number, boolean>();
        if (entries.length) {
            entries.forEach(([questionId, answer]) => {
                mapAnswers.set(+questionId, answer);
            });
        }
        this._answers = mapAnswers;
    }

    getAnswersJson(): { [key: number]: boolean } {
        let t: { [key: number]: boolean } = {};
        this._answers.forEach((value, key) => {
            t[key] = value;
        });
        return t;
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            partner_id: this.partner_id,
            company: this.company,
            contact: this.contact,
            domain: this.domain,
            version_id: this.version_id,
            infos: this.infos,
            answers: this.getAnswersJson(),
            comment: this.comment,
            notification_date: this.notification_date,
            user_id: this.user_id
        };
    }

    getRoute(): string {
        return Module.DATALEAKS.buildRoute(this.id);
    }

    getRouteReport(language: Language, light?: boolean): string {
        return PathHelper.buildPathWithId(pathDataleaksReport, this.id) + `?locale=${language.name}${light ? "&light" : ""}`;
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.domain);
        if (this.created_at) {
            element.createdAt = new Date(this.created_at);
        }
        if (this.updated_at) {
            element.lastUpdated = new Date(this.updated_at);
        }
        return element;
    }
}
