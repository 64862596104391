import {HttpContentType} from "../common/api/http/HttpContentType";

export const downloadJSON = (fileName: string, content: BlobPart) => {
    const blob = new Blob([content], {type: `${HttpContentType.JSON};charset=utf-8`});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.json`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
};