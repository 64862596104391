import {
    Action,
    Box,
    BoxProps,
    Button,
    ButtonLink,
    ButtonStyle,
    ContentBlock,
    ContentBlockAction,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentLayout,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormValidationType,
    InputDomain,
    InputText,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    Table,
    TableColumn,
    TableRow,
    TagStyle,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {LabelPartner, MainContentHeader, MainContentHeaderAction, SelectUser, Tag, TagWithAction} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {session} from "../../api/ApiSession";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {PartnerOrganizationGroup} from "../../api/model/partner/organization/PartnerOrganizationGroup";
import {Authorization} from "../../api/model/account/Authorization";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {PartnerOrganizationGroupField} from "../../api/model/partner/organization/PartnerOrganizationGroupField";
import {PartnerAccount} from "../../api/model/partner/PartnerAccount";
import {detectChanges} from "../../common/utils/portal";
import {PartnerOrganizationField} from "../../api/model/partner/organization/PartnerOrganizationField";
import useAlert from "../../utils/hooks/useAlert";
import {PartnerOrganizationType} from "../../api/model/partner/organization/PartnerOrganizationType";
import ModalAddOrganizationToOrganizationGroup from "../../component/modal/organizations/ModalAddOrganizationToOrganizationGroup";

function OrganizationGroupsDetails() {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const {id} = useParams() as {id: string};
    const navigate = useNavigate();
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isShowModalDelete, setShowModalDelete] = useState(false);
    const [isShowModalAddOrganization, setShowModalAddOrganization] = useState(false);

    const FORM_ID = "form-edit-organization-group";
    const [organizationGroup, setOrganizationGroup] = useState<PartnerOrganizationGroup>(new PartnerOrganizationGroup());
    const [initOrganizationGroup, setInitOrganizationGroup] = useState<PartnerOrganizationGroup>(new PartnerOrganizationGroup());
    const [organizationGroupPartnerAccounts, setOrganizationGroupPartnerAccounts] = useState<PartnerAccount[]>([]);

    const [newDomain, setNewDomain] = useState<string>("");

    useEffect(() => {
        (async function () {
            try {
                const organizationGroup = await session.restPartnerOrganizationGroup.get(+id);
                setOrganizationGroup(organizationGroup);
                setInitOrganizationGroup(new PartnerOrganizationGroup(organizationGroup));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("organization group", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        if (!organizationGroup.id) return;
        (async function () {
            try {
                const organizationPartnerAccounts = await session.restPartnerOrganizationGroup.getPartnerAccounts(organizationGroup);
                setOrganizationGroupPartnerAccounts(organizationPartnerAccounts);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partners", e.message);
                }
            }
        })();
    }, [organizationGroup, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(organizationGroup, initOrganizationGroup));
    }, [organizationGroup, initOrganizationGroup]);

    const handleAddDomain = (domain: string) => {
        setNewDomain("");
        if (organizationGroup.domains.find((it) => it === domain)) return;
        let newDomains = [...organizationGroup.domains, domain];
        const newOrganizationGroup = new PartnerOrganizationGroup({...organizationGroup, domains: newDomains});
        setOrganizationGroup(newOrganizationGroup);
    };

    const handleRemoveDomain = (domain: string) => {
        let newDomains = [...organizationGroup.domains].filter((it) => it !== domain);
        const newOrganizationGroup = new PartnerOrganizationGroup({...organizationGroup, domains: newDomains});
        setOrganizationGroup(newOrganizationGroup);
    };

    const handleRemoveAllDomains = () => {
        const newOrganizationGroup = new PartnerOrganizationGroup({...organizationGroup, domains: []});
        setOrganizationGroup(newOrganizationGroup);
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newOrganizationGroup = await session.restPartnerOrganizationGroup.update(organizationGroup);
            setOrganizationGroup(newOrganizationGroup);
            setOrganizationGroup(new PartnerOrganizationGroup({...newOrganizationGroup}));
            alert.updateWithSuccess("organization group");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("organization group", e.message);
            }
        }
    };

    const doDeleteOrganizationGroup = async () => {
        try {
            await session.restPartnerOrganization.delete(organizationGroup.id);
            navigate(Module.ORGANIZATION_GROUPS.path);
            alert.deleteWithSuccess("organization group");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("organization group", e.message);
            }
        }
    };

    const handleAddOrganization = (organizationGroup: PartnerOrganizationGroup) => {
        setOrganizationGroup(organizationGroup);
        setShowModalAddOrganization(false);
        alert.actionWithSuccess("organization added");
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.ORGANIZATION_GROUPS} element={initOrganizationGroup.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.ORGANIZATIONS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setShowModalDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textOrganizations("section.information")}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                    <FieldBlock label={textOrganizations(`field.${PartnerOrganizationGroupField.NAME}`)} required>
                                                        <InputText
                                                            value={organizationGroup.name}
                                                            onChange={(value) => setOrganizationGroup((prevState) => new PartnerOrganizationGroup({...prevState, [PartnerOrganizationGroupField.NAME]: value}))}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textOrganizations(`field.${PartnerOrganizationGroupField.TYPE}`)} required>
                                                        <Select
                                                            value={organizationGroup.type}
                                                            options={PartnerOrganizationType.values().map((it) => ({value: it.name, label: textOrganizations(`type.${it.name}`)}))}
                                                            onChange={(option) => setOrganizationGroup((prevState) => new PartnerOrganizationGroup({...prevState, [PartnerOrganizationGroupField.TYPE]: `${option?.value || ""}`}))}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock label={textOrganizations(`field.${PartnerOrganizationGroupField.DESCRIPTION}`)}>
                                                    <InputText
                                                        value={organizationGroup.description}
                                                        onChange={(value) => setOrganizationGroup((prevState) => new PartnerOrganizationGroup({...prevState, [PartnerOrganizationGroupField.DESCRIPTION]: value}))}
                                                    />
                                                </FieldBlock>
                                                <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                                    <FieldBlock
                                                        label={textOrganizations(`field.${PartnerOrganizationGroupField.DOMAINS}`)}
                                                        actions={organizationGroup.domains.length ? <ButtonLink onClick={handleRemoveAllDomains}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink> : undefined}
                                                    >
                                                        <InputDomain
                                                            value={newDomain}
                                                            onChange={setNewDomain}
                                                        />
                                                        <Button style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={() => handleAddDomain(newDomain)} disabled={!newDomain.length}>
                                                            {textCommon(Action.ADD.labelKey)}
                                                        </Button>
                                                    </FieldBlock>
                                                    {!!organizationGroup.domains.length &&
                                                        <ElementList size={ElementListSize.SMALL} inline>
                                                            {organizationGroup.domains.map((domain) =>
                                                                <TagWithAction
                                                                    key={domain}
                                                                    value={domain}
                                                                    onDelete={() => handleRemoveDomain(domain)}
                                                                    active={true}
                                                                />
                                                            )}
                                                        </ElementList>
                                                    }
                                                </FormLayoutRows>
                                                <FormLayoutSeparator/>
                                                <FieldBlock label={textOrganizations(`field.${PartnerOrganizationGroupField.OWNER_ID}`)} required>
                                                    <SelectUser
                                                        value={organizationGroup.owner_id}
                                                        onChange={(user) => setOrganizationGroup((prevState) => new PartnerOrganizationGroup({...prevState, [PartnerOrganizationGroupField.OWNER_ID]: user?.id}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock
                                        header={{
                                            title: {label: textOrganizations("section.organizations")},
                                            actions: (
                                                <RestrictedContent allowedTo={Authorization.ORGANIZATIONS.update}>
                                                    <ContentBlockAction
                                                        action={Action.ADD}
                                                        onClick={() => setShowModalAddOrganization(true)}
                                                    />
                                                </RestrictedContent>
                                            )
                                        }}
                                    >
                                        <Table
                                            columns={[
                                                {width: 50, label: textOrganizations(`field.${PartnerOrganizationField.NAME}`)},
                                                {width: 50, label: textOrganizations(`field.${PartnerOrganizationField.TYPE}`)}
                                            ]}
                                        >
                                            {organizationGroup.organizations.map((item) =>
                                                <TableRow key={item.id} onClick={() => window.open(item.getRoute(), "_blank")}>
                                                    <TableColumn>{item.name}</TableColumn>
                                                    <TableColumn>
                                                        {item.type &&
                                                            <Tag label={textOrganizations(`type.${item.type}`)} style={TagStyle.PRIMARY_OCEAN}/>
                                                        }
                                                    </TableColumn>
                                                </TableRow>
                                            )}
                                        </Table>
                                    </ContentBlock>
                                </Loadable>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textOrganizations("section.partners")}}}>
                                        <Table
                                            columns={[
                                                {width: 30, label: textOrganizations("field.name")},
                                                {width: 50, label: textOrganizations("field.email")},
                                                {width: 20, label: textOrganizations("field.partner_id")}
                                            ]}
                                        >
                                            {organizationGroupPartnerAccounts.map((item) =>
                                                <TableRow key={item.id} onClick={() => window.open(item.partner?.getRoute(), "_blank")}>
                                                    <TableColumn>{item.fullName}</TableColumn>
                                                    <TableColumn>{item.email}</TableColumn>
                                                    <TableColumn>
                                                        <LabelPartner partner={item.partner?.id}/>
                                                    </TableColumn>
                                                </TableRow>
                                            )}
                                        </Table>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                        </LayoutColumns>
                    </LayoutRows>
                </Form>
                <ModalAddOrganizationToOrganizationGroup
                    active={isShowModalAddOrganization}
                    onSubmit={handleAddOrganization}
                    onClose={() => setShowModalAddOrganization(false)}
                    organizationGroup={organizationGroup}
                />
                <ModalConfirmDelete
                    active={isShowModalDelete}
                    entity="organization group"
                    confirm={doDeleteOrganizationGroup}
                    cancel={() => setShowModalDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default OrganizationGroupsDetails;
