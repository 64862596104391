import {ApiModel} from "../../../common/api/model/ApiModel";
import {PortalSettingContentFooterItem} from "./PortalSettingContentFooterItem";
import {Locale} from "../../../common/utils/Locale";

export class PortalSettingContentFooter extends ApiModel {
    legal_notice: boolean = false;
    cookies_settings: boolean = false;
    _items: Map<Locale, PortalSettingContentFooterItem[]> = new Map();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get items(): Map<Locale, PortalSettingContentFooterItem[]> {
        return this._items;
    }

    set items(items: Map<Locale, PortalSettingContentFooterItem[]>) {
        if (items instanceof Map || !items) {
            this._items = items;
            return;
        }

        const entries = Object.entries<[]>(items);
        const mapItems = new Map<Locale, PortalSettingContentFooterItem[]>();
        if (entries.length) {
            entries.forEach(([locale, values]) => {
                const items = values.map((item) => new PortalSettingContentFooterItem(item));
                mapItems.set(locale, items);
            });
        }
        this._items = mapItems;
    }

    getItemsForLocale(locale: Locale): PortalSettingContentFooterItem[] {
        return this.items?.get(locale) || [];
    }

    getItemsJson(): { [key: string]: object } {
        let t: { [key: string]: object } = {};
        if (this._items) {
            this._items.forEach((value, key) => {
                t[key as string] = value;
            });
        }
        return t;
    }

    getJsonParameters(): {} {
        return {
            legal_notice: this.legal_notice,
            cookies_settings: this.cookies_settings,
            items: this.getItemsJson()
        };
    }
}
