import {ApiModel} from "../../../common/api/model/ApiModel";
import {JiraIssueScope} from "./JiraIssueScope";

export class JiraIssue extends ApiModel {
    project_id: string = "";
    issuetype_id: string = "";
    priority_id: string = "";
    reporter_id: string = "";
    summary: string = "";
    description: string = "";
    scope?: JiraIssueScope;
    business_impact_id?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            project_id: this.project_id,
            issuetype_id: this.issuetype_id,
            priority_id: this.priority_id,
            reporter_id: this.reporter_id,
            summary: this.summary,
            description: this.description,
            scope: this.scope,
            business_impact_id: this.business_impact_id
        };
    }
}
