import {TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {PartnerLicense} from "../../../../api/model/partner/license/PartnerLicense";
import {Tag} from "../../index";

type PartnerLicenseRowProps = {
    license: PartnerLicense;
}

const PartnerLicenseRow: FunctionComponent<PartnerLicenseRowProps> = ({license}) => {
    return (
        <TableRow onClick={{link: license.getRoute()}}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{license.id}</TableColumn>
            <TableColumn>
                <Tag label={license.name} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{license.description}</TableColumn>
        </TableRow>
    );
};

export default PartnerLicenseRow;
