import {Rest} from "../../common/api/rest/Rest";
import {PartnerOrganizationEmailDomain} from "../model/partner/organization/PartnerOrganizationEmailDomain";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";

export class RestPartnerOrganizationEmailDomain extends Rest implements RestService<PartnerOrganizationEmailDomain> {
    rootPath = "/console-api/partner/organization/email-domain";

    search(params: any): Promise<SearchResult<PartnerOrganizationEmailDomain>> {
        return new Promise<SearchResult<PartnerOrganizationEmailDomain>>(() => {}); // TODO Implement route
    }

    list(): Promise<PartnerOrganizationEmailDomain[]> {
        return this._client.get(`${this.rootPath}`, PartnerOrganizationEmailDomain) as Promise<PartnerOrganizationEmailDomain[]>;
    }

    create(emailDomain: PartnerOrganizationEmailDomain): Promise<PartnerOrganizationEmailDomain> {
        return this._client.post(`${this.rootPath}`, emailDomain, PartnerOrganizationEmailDomain) as Promise<PartnerOrganizationEmailDomain>;
    }

    get(emailDomain: string): Promise<PartnerOrganizationEmailDomain> {
        return this._client.get(`${this.rootPath}/${emailDomain}`, PartnerOrganizationEmailDomain) as Promise<PartnerOrganizationEmailDomain>;
    }

    update(emailDomain: PartnerOrganizationEmailDomain): Promise<PartnerOrganizationEmailDomain> {
        return this._client.put(`${this.rootPath}/${emailDomain}`, emailDomain.domain, PartnerOrganizationEmailDomain) as Promise<PartnerOrganizationEmailDomain>;
    }

    delete(emailDomain: string) {
        return this._client.delete(`${this.rootPath}/${emailDomain}`);
    }
}
