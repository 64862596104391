import {ApiModel} from "../../../../common/api/model/ApiModel";

export enum CmpWebinarFaqItemField {
    ANSWER = "answer",
    QUESTION = "question"
}

export class CmpWebinarFaqItem extends ApiModel {
    question: string = "";
    answer: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
