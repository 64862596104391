import {ApiModel} from "../../../common/api/model/ApiModel";
import {Status} from "../../../utils/Status";
import {ContentElement} from "../../interface/ContentElement";

export class PortalContent extends ApiModel {
    id: number = 0;
    user_id?: number;
    service: string = "";
    string_identifier: string = "";
    title: string = "";
    content: any;
    type: string = "";
    status: string = Status.OFFLINE.name;
    start_date: string = "";
    end_date: string = "";
    creation_ts: string = "";
    last_update_ts: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    protected buildSearchIndex(items: [string, string][]): string {
        return items
            .filter(([_, value]) => !!value)
            .map(([name, value]) => `${name}:${value}`).join("|");
    }

    getJsonParameters(): {} {
        return {
            user_id: this.user_id,
            service: this.service,
            string_identifier: this.string_identifier,
            title: this.title,
            content: this.content,
            type: this.type,
            status: this.status,
            start_date: this.start_date,
            end_date: this.end_date || undefined,
            search_index: this.getSearchIndex()
        };
    }

    getRoute(): string {
        return "";
    }

    getSearchIndex() {
        return "";
    }

    getStatus(): Status {
        if (this.status === Status.ONLINE.name) {
            return Status.ONLINE;
        } else if (this.status === Status.OFFLINE.name) {
            return Status.OFFLINE;
        } else {
            return Status.DELETED;
        }
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.title);
        element.status = this.getStatus();
        element.createdAt = new Date(this.creation_ts);
        element.lastUpdated = new Date(this.last_update_ts);
        return element;
    }
}
