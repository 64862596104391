import {ApiModel} from "../../../../common/api/model/ApiModel";
import {ContentElement} from "../../../interface/ContentElement";
import {User} from "../../user/User";
import {PartnerOrganizationField} from "./PartnerOrganizationField";
import {PartnerOrganizationGroup} from "./PartnerOrganizationGroup";
import {PartnerOrganizationProperties} from "./PartnerOrganizationProperties";
import {Module} from "../../../../utils/Module";

export class PartnerOrganization extends ApiModel {
    id: number = 0;
    type?: string;
    name: string = "";
    description: string = "";
    owner: User | undefined;
    owner_id: number = 0;
    domains: string[] = [];
    group: PartnerOrganizationGroup | undefined;
    group_id: number = 0;
    seat_id: number = 0;
    created_at: string = "";
    updated_at: string = "";
    properties: PartnerOrganizationProperties = {};

    constructor(o?: {}) {
        super(o);
        this.load(o);
        this.loadObject(PartnerOrganizationField.OWNER, User);
        this.loadObject(PartnerOrganizationField.GROUP, PartnerOrganizationGroup);
    }

    getJsonParameters(): {} {
        return {
            type: this.type,
            name: this.name,
            description: this.description,
            owner_id: this.owner_id > 0 ? this.owner_id : undefined,
            group_id: this.group_id > 0 ? this.group_id : undefined,
            domains: this.domains
        };
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        element.createdAt = new Date(this.created_at);
        element.lastUpdated = new Date(this.updated_at);
        return element;
    }

    getRoute(): string {
        return Module.ORGANIZATIONS.buildRoute(this.id);
    }
}
