import {
    Action,
    Color,
    ContentBlock,
    IconTooltip,
    LayoutRows,
    Loadable,
    SearchError,
    SearchField,
    SearchToolbar,
    Select,
    Table,
    TableActionColumn,
    TableColumn,
    TableColumnStyle,
    TableRow
} from "@sirdata/ui-lib";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {PartnerOrganization} from "../../api/model/partner/organization/PartnerOrganization";
import {PartnerOrganizationField} from "../../api/model/partner/organization/PartnerOrganizationField";
import {PartnerOrganizationRequest} from "../../api/model/partner/organization/PartnerOrganizationRequest";
import {PartnerOrganizationSearchQuery} from "../../api/model/partner/organization/PartnerOrganizationSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreatePartnerOrganization from "../../component/modal/organizations/ModalCreatePartnerOrganization";
import {IframeBlock, LabelPartner, LabelService, MainContentHeader, MainContentHeaderAction, PartnerOrganizationRow, SelectUser} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import ModalVerifyOrganizationRequest from "../../component/modal/organizations/ModalVerifyOrganizationRequest";
import {Formatter} from "../../common/utils/Formatter";
import useSearch from "../../utils/hooks/useSearch";
import useAlert from "../../utils/hooks/useAlert";
import {PartnerOrganizationPropertiesField} from "../../api/model/partner/organization/PartnerOrganizationPropertiesField";
import {PartnerOrganizationRequestStatus} from "../../api/model/partner/organization/PartnerOrganizationRequestStatus";
import {PartnerOrganizationType} from "../../api/model/partner/organization/PartnerOrganizationType";
import {Looker} from "../../utils/Looker";

function Organizations() {
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const [isLoadingOrganizations, setLoadingOrganizations] = useState(true);
    const [isLoadingOrganizationRequests, setLoadingOrganizationRequests] = useState(true);
    const [organizations, setOrganizations] = useState<PartnerOrganization[]>([]);
    const [organizationRequests, setOrganizationRequests] = useState<PartnerOrganizationRequest[]>([]);
    const [isShowModalCreation, setShowModalCreation] = useState(false);
    const [activeOrganizationRequest, setActiveOrganizationRequest] = useState<PartnerOrganizationRequest>();
    const alert = useAlert();
    const {buildSearchResult, ...search} = useSearch(PartnerOrganization, PartnerOrganizationSearchQuery);

    const doLoadOrganizations = useCallback(async () => {
        try {
            setLoadingOrganizations(true);
            setOrganizations(await session.restPartnerOrganization.list());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("organizations", e.message);
            }
        } finally {
            setLoadingOrganizations(false);
        }

        try {
            setLoadingOrganizationRequests(true);
            setOrganizationRequests(await session.restPartnerOrganizationRequest.list(PartnerOrganizationRequestStatus.REQUESTED));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("pending organizations", e.message);
            }
        } finally {
            setLoadingOrganizationRequests(false);
        }
    }, [alert]);

    useEffect(() => {
        (async () => {
            await doLoadOrganizations();
        })();
    }, [doLoadOrganizations]);

    useEffect(() => {
        let currentOrganizations = (organizations || []);
        if (search.searchQuery.query) {
            currentOrganizations = currentOrganizations.filter(({id, group_id, name, description}) => `${id} ${group_id} ${name} ${description}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        if (search.searchQuery.type) {
            currentOrganizations = currentOrganizations.filter((it) => it.type === search.searchQuery.type);
        }
        if (search.searchQuery.owner_id) {
            currentOrganizations = currentOrganizations.filter((it) => it.owner_id === search.searchQuery.owner_id);
        }
        buildSearchResult(currentOrganizations);
    }, [organizations, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.ORGANIZATIONS}>
                <RestrictedContent allowedTo={Authorization.ORGANIZATIONS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreation(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <Loadable loading={isLoadingOrganizationRequests}>
                        {!!organizationRequests.length &&
                            <ContentBlock header={{title: {label: textOrganizations("pending_organization", {count: organizationRequests.length})}}}>
                                <Table
                                    columns={[
                                        {width: 5, label: textOrganizations(`field.${PartnerOrganizationField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 15, label: textOrganizations(`field.${PartnerOrganizationField.GROUP}`)},
                                        {width: 20, label: textOrganizations(`field.${PartnerOrganizationField.NAME}`)},
                                        {width: 10, label: textOrganizations("field.partner_id")},
                                        {width: 20, label: textOrganizations("field.email")},
                                        {width: 10, label: textOrganizations("field.service")},
                                        {width: 15, label: textOrganizations(`field.${PartnerOrganizationField.CREATED_AT}`)},
                                        {width: 5}
                                    ]}
                                >
                                    {organizationRequests.map((request: PartnerOrganizationRequest) =>
                                        <TableRow key={request.id}>
                                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{request.id}</TableColumn>
                                            <TableColumn>{request.group_name}</TableColumn>
                                            <TableColumn>{request.name}</TableColumn>
                                            <TableColumn><LabelPartner partner={request.partner_account?.partner?.id}/></TableColumn>
                                            <TableColumn>{request.partner_account?.email}</TableColumn>
                                            <TableColumn>
                                                {request.partner_account?.partner?.creation_service &&
                                                    <LabelService service={request.partner_account?.partner?.creation_service}/>
                                                }
                                            </TableColumn>
                                            <TableColumn>{Formatter.formatDate(request.created_at, Formatter.DATETIME_FORMAT)}</TableColumn>
                                            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                                <RestrictedContent allowedTo={Authorization.ORGANIZATIONS.update}>
                                                    <IconTooltip
                                                        icon={{name: "verified", colorIcon: Color.ORANGE}}
                                                        text={textOrganizations("actions.verify")}
                                                        onClick={() => setActiveOrganizationRequest(request)}
                                                    />
                                                </RestrictedContent>
                                            </TableActionColumn>
                                        </TableRow>
                                    )}
                                </Table>
                            </ContentBlock>
                        }
                    </Loadable>
                    <ContentBlock>
                        <SearchToolbar searchBar={{placeholder: textOrganizations("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}} canHideFilters>
                            <SearchField label={textOrganizations(`field.${PartnerOrganizationField.TYPE}`)}>
                                <Select
                                    value={search.searchQuery.type}
                                    options={PartnerOrganizationType.values().map((type) => ({value: type.name, label: textOrganizations(`type.${type.name}`)}))}
                                    onChange={(option) => search.changeParam(SearchParamsField.TYPE, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textOrganizations(`field.${PartnerOrganizationField.OWNER_ID}`)}>
                                <SelectUser
                                    value={search.searchQuery.owner_id}
                                    onChange={(user) => search.changeParam(SearchParamsField.OWNER, user?.id)}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoadingOrganizations}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textOrganizations(`field.${PartnerOrganizationField.ID}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: PartnerOrganizationField.ID}},
                                        {width: 10, label: textOrganizations(`field.${PartnerOrganizationField.TYPE}`), sort: {field: PartnerOrganizationField.TYPE}},
                                        {width: 20, label: textOrganizations(`field.${PartnerOrganizationField.NAME}`), sort: {field: PartnerOrganizationField.NAME}},
                                        {width: 20, label: textOrganizations(`field.${PartnerOrganizationField.GROUP}`)},
                                        {width: 15, label: textOrganizations(`field.${PartnerOrganizationField.DOMAINS}`)},
                                        {width: 15, label: textOrganizations(`field.${PartnerOrganizationField.PROPERTIES}.${PartnerOrganizationPropertiesField.FRESHSALES_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 15, label: textOrganizations(`field.${PartnerOrganizationField.OWNER_ID}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((organization: PartnerOrganization) =>
                                        <PartnerOrganizationRow key={organization.id} organization={organization}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                    <IframeBlock
                        src={Looker.getDashboardIframeUrl(256, "Month+Date=this+year&Date+Type=MONTH")}
                        title="organizations-iframe"
                        height={"95%"}
                    />
                </LayoutRows>
                <ModalCreatePartnerOrganization
                    active={isShowModalCreation}
                    onClose={() => setShowModalCreation(false)}
                />
                <ModalVerifyOrganizationRequest
                    request={activeOrganizationRequest}
                    requestList={organizationRequests}
                    onUpdate={doLoadOrganizations}
                    onClose={() => setActiveOrganizationRequest(undefined)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Organizations;
