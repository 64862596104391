import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Formatter} from "../../../../common/utils/Formatter";
import {ContentElement} from "../../../interface/ContentElement";
import {Module} from "../../../../utils/Module";

export class CmpVersion extends ApiModel {
    version: string = "";
    date: string = Formatter.formatDate(new Date(), Formatter.API_DATE_FORMAT);
    text_fr: string = "";
    text_en: string = "";
    last_update: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            version: this.version,
            date: this.date,
            text_fr: this.text_fr,
            text_en: this.text_en
        };
    }

    getRoute(): string {
        return Module.CMP_VERSIONS.buildRoute(this.version);
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.version, this.date);
        element.createdAt = new Date(Formatter.convertDateToUTC(this.date));
        if (this.last_update) {
            element.lastUpdated = new Date(this.last_update);
        }
        return element;
    }
}
