export class CustomerOrderProduct {
    static CRM: CustomerOrderProduct = new CustomerOrderProduct("CRM");
    static ONBOARDING: CustomerOrderProduct = new CustomerOrderProduct("ONBOARDING");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CustomerOrderProduct[] {
        return [
            CustomerOrderProduct.CRM,
            CustomerOrderProduct.ONBOARDING
        ];
    }
}