import {Model} from "../../../../common/api/interface/Model";
import {ApiModel} from "../../../../common/api/model/ApiModel";
import {UserAccount} from "../UserAccount";

export class GoogleAuthResponse extends ApiModel implements Model {
    token: string = "";
    user: UserAccount = new UserAccount();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
