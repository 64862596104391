export enum DistributionType {
    ALL_LINKED_SEGMENTS = "ALL_LINKED_SEGMENTS",
    ALL_PUBLIC_SEGMENTS = "ALL_PUBLIC_SEGMENTS",
    SPECIFIC_SEGMENTS = "SPECIFIC_SEGMENTS",
}

export const DISTRIBUTION_TYPES: DistributionType[] = [
    DistributionType.ALL_LINKED_SEGMENTS,
    DistributionType.ALL_PUBLIC_SEGMENTS,
    DistributionType.SPECIFIC_SEGMENTS
];
