import {ApiModel} from "../../../common/api/model/ApiModel";
import {CountryCode} from "../../../common/api/model/Country";
import {CountryIsoCode} from "../../../common/api/model/CountryIsoCode";
import {PartnerBillingVatType} from "./PartnerBillingVatType";
import {PartnerBillingPaymentMethod} from "./PartnerBillingPaymentMethod";
import {PartnerBillingPaymentType} from "./PartnerBillingPaymentType";

export class PartnerBillingInfo extends ApiModel {
    partner_id: number = 0;
    company_name: string = "";
    contact_lastname: string = "";
    contact_firstname: string = "";
    address: string = "";
    postal_code: string = "";
    city: string = "";
    country_iso: CountryIsoCode = CountryCode.FRANCE.code;
    vat: string = "";
    vat_type: string = PartnerBillingVatType.FR;
    bic: string = "";
    iban: string = "";
    business_id: string = "";
    payment_method: string = PartnerBillingPaymentMethod.MONTHLY.name;
    payment_type: string = PartnerBillingPaymentType.DIRECT_DEBIT.name;
    small_business: boolean = false;
    axonaut_id?: number;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            partner_id: this.partner_id,
            company_name: this.company_name,
            contact_lastname: this.contact_lastname,
            contact_firstname: this.contact_firstname,
            address: this.address,
            postal_code: this.postal_code,
            city: this.city,
            country_iso: this.country_iso,
            vat: this.vat,
            bic: this.bic,
            iban: this.iban,
            business_id: this.business_id,
            payment_method: this.payment_method,
            payment_type: this.payment_type,
            small_business: this.small_business,
            axonaut_id: this.axonaut_id
        };
    }
}

