import {Box, BoxSpacing, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentLayout, FlexContentSpacing, SvgSirdataLogoMidnight} from "@sirdata/ui-lib";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {NetworkField} from "../../../api/model/cmp/list/network-list/NetworkField";
import {Network} from "../../../api/model/cmp/list/network-list/Network";

type NetworkListNetworkProps = {
    network: Network;
    onClick: (network: Network) => void;
};

const NetworkListNetwork: FC<NetworkListNetworkProps> = ({network, onClick}) => {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);

    return (
        <Box key={network.id} spacing={BoxSpacing.LARGE} cssClass="network-item" onClick={() => onClick(network)}>
            <FlexContent
                direction={FlexContentDirection.ROW}
                layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}
                spacing={FlexContentSpacing.LARGE}
                alignment={FlexContentAlignment.CENTER}
            >
                <div className="network-item__img">
                    {network.id === 1 ? <SvgSirdataLogoMidnight/> : <img src={`/images/cmp/networks/logo-${network.id}.png`} alt={network.name}/>}
                </div>
                <div className="network-item__info">
                    <span className="network-item__info__name">{network.name}</span>
                    <span>{textCmpLists(`network.field.network.${NetworkField.VENDORS}`, {count: network.vendors.length})}</span>
                    <span>{textCmpLists(`network.field.network.${NetworkField.PROVIDERS}`, {count: network.providers.length})}</span>
                    <span>{textCmpLists(`network.field.network.${NetworkField.SIRDATA_VENDORS}`, {count: network.sirdataVendors.length})}</span>
                </div>
            </FlexContent>
        </Box>
    );
};

export default NetworkListNetwork;
