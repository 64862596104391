export enum SegmentField {
    ACTIVE = "active",
    COOKIELESS = "cookieless",
    COMMENT = "comment",
    CUSTOMER_PRICE_INDEX = "customer_price_index",
    DATA_TYPE = "data_type",
    DESCRIPTION = "description",
    EXPIRATION_DAY = "expiration_day",
    ID = "id",
    NAME = "name",
    PARTNER_ID = "partner_id",
    PERCENTILE = "percentile",
    PRICE = "price",
    PRICE_GBP = "price_gbp",
    PRICE_USD = "price_usd",
    PRIVATE = "private",
    TIERS1 = "tiers1",
    TIERS2 = "tiers2",
    TIERS3 = "tiers3",
    TIERS4 = "tiers4",
    TIERS5 = "tiers5",
    TIERS_NAME = "tiers_name",
}
