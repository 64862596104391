import {ApiModel} from "../../../common/api/model/ApiModel";
import {RevenueFileFormat} from "./RevenueFileFormat";
import {RevenuePlatformSource} from "./RevenuePlatformSource";

const REVENUE_SEAT_PLATFORM_CURATOR = "Curator";
export const REVENUE_SEAT_PLATFORM_DIRECT = "Direct";

export class RevenuePlatform extends ApiModel {
    name: string = "";
    code_name: string = "";
    revenue_format?: string = "";
    source: string = "";
    destination_name?: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get acceptedFormats(): string[] {
        if (!this.revenue_format) {
            return [];
        }

        return this.revenue_format.split(",").reduce((acc: string[], item: string) => {
            const fileFormat = RevenueFileFormat.getByName(item);
            if (fileFormat) {
                acc.push(fileFormat.extension);
            }
            return acc;
        }, []);
    }

    isCurator(): boolean {
        return this.name.includes(REVENUE_SEAT_PLATFORM_CURATOR);
    }
}

export const defaultMultiPlatform: RevenuePlatform = new RevenuePlatform({
    name: "Multi-platform",
    code_name: "MULTI-PLATFORM",
    revenue_format: RevenueFileFormat.CSV.name,
    source: RevenuePlatformSource.FILE
});
