import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Action, Color, IconTooltip, TranslationLibFile} from "@sirdata/ui-lib";
import {PartnerOrganization} from "../../../api/model/partner/organization/PartnerOrganization";
import clsx from "clsx";
import {Tag} from "../index";

type TagOrganizationRowProps = {
    organization: PartnerOrganization;
    isHighlighted?: boolean;
    onRemove?: () => void;
};

const TagOrganizationRow: FunctionComponent<TagOrganizationRowProps> = ({organization, isHighlighted, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <div className={clsx("element-list__tag-row", {"element-list__tag-row--highlighted": isHighlighted})}>
            <span className="element-list__tag-row__infos">
                <Tag label={`${organization.id}`} customColor={{backgroundColor: Color.MIDNIGHT}}/>
                <span>{organization.name}</span>
            </span>
            {onRemove && <IconTooltip icon={Action.REMOVE.icon} text={textCommon(Action.REMOVE.labelKey)} onClick={onRemove}/>}
        </div>
    );
};

export default TagOrganizationRow;
