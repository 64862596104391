import {IconProps} from "@sirdata/ui-lib";

export class SegmentDataType {
    static DECLARATIVE: SegmentDataType = new SegmentDataType("Declarative", {name: "fact_check", outlined: true}, "Sirdata [Declarative] ", 360, 1000);
    static INFERRED: SegmentDataType = new SegmentDataType("Inferred", {name: "data_usage", outlined: true}, "Sirdata [Inferred] ", 90, 1000);
    static INTENT: SegmentDataType = new SegmentDataType("Intent", {name: "ads_click", outlined: true}, "Sirdata [Intent] ", 30, 1000);
    static INTEREST: SegmentDataType = new SegmentDataType("Interest", {name: "lightbulb", outlined: true}, "Sirdata [Interest] ", 90, 500);
    static LOCATION: SegmentDataType = new SegmentDataType("Location", {name: "location_on", outlined: true}, "Sirdata | Proxistore [Location] ", 30, 1000);
    static MODELING: SegmentDataType = new SegmentDataType("Modeling", {name: "view_in_ar", outlined: true}, "Sirdata | Kantar TGI [Modeling] ", 45, 1000);

    name: string;
    icon: IconProps;
    prefix: string;
    expirationDay: number;
    minScore: number;

    private constructor(name: string, icon: IconProps, prefix: string, expirationDay: number, minScore: number) {
        this.name = name;
        this.icon = icon;
        this.prefix = prefix;
        this.expirationDay = expirationDay;
        this.minScore = minScore;
    }

    static values(): SegmentDataType[] {
        return [
            SegmentDataType.INTENT,
            SegmentDataType.INTEREST,
            SegmentDataType.DECLARATIVE,
            SegmentDataType.INFERRED,
            SegmentDataType.MODELING,
            SegmentDataType.LOCATION
        ];
    }

    static getByName(name: string): SegmentDataType | undefined {
        return this.values().find((it) => it.name === name);
    }
}
