import {PortalContentSearchQuery} from "../portal-content/PortalContentSearchQuery";
import {PortalSetting} from "./PortalSetting";
import {SearchParams} from "../../../utils/SearchParams";
import {SearchParamsField} from "../../../utils/SearchParamsField";

export class PortalSettingSearchQuery extends PortalContentSearchQuery {
    withSearchParams(params: SearchParams): PortalContentSearchQuery {
        const newQuery = super.withSearchParams(params);
        newQuery.service = params.getString(SearchParamsField.SERVICE);
        return newQuery;
    };

    constructor(o?: {}) {
        super(o);
        this.load(o);
        this.type = PortalSetting.TYPE;
    }
}
