import {ButtonLinkCancel, ButtonValidate, ContentBlock, InputCheckbox, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, SearchBarClearable, Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {MODAL_PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {Category} from "../../../api/model/audience/category/Category";
import {session} from "../../../api/ApiSession";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {sortItems} from "../../../common/utils/helper";
import {CategoryField} from "../../../api/model/audience/category/CategoryField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {CategoryGroupField} from "../../../api/model/audience/category/CategoryGroupField";

type ModalPopulateCategoriesProps = {
    active: boolean;
    onClose: () => void;
}

const ModalPopulateCategories: FunctionComponent<ModalPopulateCategoriesProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const [isLoading, setLoading] = useState(true);
    const [isPopulating, setPopulating] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const [currentCategories, setCurrentCategories] = useState<Category[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
    const [query, setQuery] = useState("");

    useEffect(() => {
        (async function () {
            try {
                let categories = await session.getCategoriesByType(CategoryType.MODELING);
                categories = sortItems(categories, CategoryField.NAME);
                setCategories(categories);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("modeling categories", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        if (active) {
            setSelectedCategories([]);
            setQuery("");
        }
    }, [active]);

    useEffect(() => {
        const newCurrentCategories = categories.filter((it) => it.id.toString().includes(query) || it.code_name.toLowerCase().includes(query.toLowerCase()));
        setCurrentCategories(newCurrentCategories);
    }, [query, categories]);

    const handleClickCategory = (category: Category) => {
        let newSelectedCategories = [...selectedCategories];
        if (newSelectedCategories.find((it) => category.id === it.id)) {
            newSelectedCategories = newSelectedCategories.filter((it) => category.id !== it.id);
        } else {
            newSelectedCategories.push(category);
        }
        setSelectedCategories(newSelectedCategories);
    };

    const handleSubmit = async () => {
        try {
            setPopulating(true);
            const categoryIds: number[] = selectedCategories.map((it) => it.id);
            await session.restModeling.populateCategories(...categoryIds);
            alert.actionWithSuccess(`${categoryIds.length} categories populated`);
            onClose();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("populate categories", e.message);
            }
        } finally {
            setPopulating(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active} loading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategories("modal.populate_categories.title")}/>
            </ModalHeader>
            <ModalContent>
                <ContentBlock>
                    <SearchBarClearable
                        placeholder={textCategories("search.placeholder")}
                        value={query}
                        onSubmit={setQuery}
                        form={""}
                    />
                    <Table
                        columns={[
                            {width: 5},
                            {width: 15, label: textCategories(`field.${CategoryGroupField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                            {width: 80, label: textCategories(`field.${CategoryGroupField.CODE_NAME}`)}
                        ]}
                        pagination={MODAL_PAGE_SIZE}
                    >
                        {currentCategories.map((category) =>
                            <TableRow key={category.id} onClick={() => handleClickCategory(category)}>
                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                    <InputCheckbox
                                        checked={selectedCategories.some((it) => category.id === it.id)}
                                        onChange={() => {}}
                                    />
                                </TableColumn>
                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                    {category.id}
                                </TableColumn>
                                <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                                    {category.name}
                                </TableColumn>
                            </TableRow>
                        )}
                    </Table>
                </ContentBlock>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate onClick={handleSubmit} disabled={!selectedCategories.length} loading={isPopulating}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalPopulateCategories;
