import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Segment} from "../../../api/model/audience/segment/Segment";
import {SegmentDataType} from "../../../api/model/audience/segment/SegmentDataType";
import {SegmentField} from "../../../api/model/audience/segment/SegmentField";
import {TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {useNavigate} from "react-router-dom";

export type ModalCreateSegmentProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateSegment: FunctionComponent<ModalCreateSegmentProps> = ({active, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const [segment, setSegment] = useState<Segment>(new Segment());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-segment";

    useEffect(() => {
        if (active) {
            setSegment(new Segment());
        }
    }, [active]);

    useEffect(() => {
        setSegment((prevState) => {
            const dataType = SegmentDataType.getByName(prevState.data_type);
            return new Segment({
                ...prevState,
                [SegmentField.NAME]: dataType?.prefix || "",
                [SegmentField.EXPIRATION_DAY]: dataType?.expirationDay || 0
            });
        });
    }, [segment.data_type]);

    const handleChange = (field: SegmentField, value: any) => {
        setSegment((prevState) => new Segment({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const newSegment = await session.restSegment.create(segment);
            navigate(newSegment.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("segment", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textSegments("modal.create_segment")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={3}>
                            <FieldBlock label={textSegments(`field.${SegmentField.DATA_TYPE}`)} required>
                                <Select
                                    value={segment.data_type}
                                    options={SegmentDataType.values().map((item) => ({label: item.name, value: item.name}))}
                                    onChange={(option) => handleChange(SegmentField.DATA_TYPE, option?.value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textSegments(`field.${SegmentField.NAME}`)} required>
                            <InputText
                                value={segment.name}
                                onChange={(value) => handleChange(SegmentField.NAME, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateSegment;
