import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {ApiService} from "../../api/model/ApiService";
import {Partner} from "../../api/model/partner/Partner";
import {PartnerField} from "../../api/model/partner/PartnerField";
import {PartnerSearchQuery} from "../../api/model/partner/PartnerSearchQuery";
import {UserField} from "../../api/model/user/UserField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Formatter} from "../../common/utils/Formatter";
import {buildAndDownloadCSV} from "../../common/utils/portal";
import ModalCreatePartner from "../../component/modal/partners/ModalCreatePartner";
import {MainContentHeader, MainContentHeaderAction, PartnerRow, SelectService, SelectStatus, SelectUser} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {Status} from "../../utils/Status";

function Partners() {
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreatePartner, setShowModalCreatePartner] = useState(false);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [cmpPremiumPartners, setCmpPremiumPartners] = useState<Partner[]>([]);
    const alert = useAlert();
    const {buildSearchResult, ...search} = useSearch(Partner, PartnerSearchQuery);

    useEffect(() => {
        (async () => {
            try {
                const premiums = await session.restPartner.getCmpPremiums();

                const partners = await session.restPartner.list();
                setPartners(partners);
                setCmpPremiumPartners(partners.filter((partner) => premiums.includes(partner.id)));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partners", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        let currentPartners = (partners || []);
        if (search.searchQuery.query) {
            currentPartners = currentPartners.filter(({id, fullName, company, email}) => `${id} ${fullName} ${company} ${email}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        if (search.searchQuery.status) {
            currentPartners = currentPartners.filter((it) => it.getStatus().name === search.searchQuery.status);
        }
        if (search.searchQuery.type) {
            currentPartners = currentPartners.filter(({type}) => type === search.searchQuery.type);
        }
        if (search.searchQuery.service) {
            currentPartners = currentPartners.filter(({creation_service}) => creation_service === search.searchQuery.service);
        }
        if (search.searchQuery.owner) {
            currentPartners = currentPartners.filter(({user}) => user === search.searchQuery.owner);
        }
        buildSearchResult(currentPartners);
    }, [partners, search.searchQuery, buildSearchResult]);

    const exportCMPPremiumPartners = () => {
        const headers = [
            "ID",
            "Email",
            "Company"
        ];
        const items = cmpPremiumPartners.map((partner) => [
            partner.id,
            partner.email,
            partner.company
        ]);
        buildAndDownloadCSV("partners-cmp-premium", headers, items);
    };

    const exportPartnersList = () => {
        const headers = [
            "ID",
            "Company",
            "Name",
            "Email",
            "Creation Date",
            "Creation Service",
            "Owner",
            "Country",
            "Axciom ID",
            "Branded",
            "Consent",
            "Data",
            "Categorizer",
            "Active"
        ];
        const items = partners.map((partner) => [
            partner.id,
            partner.company,
            partner.fullName,
            partner.email,
            Formatter.formatDate(partner.created_at, Formatter.DATETIME_FORMAT),
            partner.creation_service,
            partner.user,
            partner.country,
            partner.axciom_id,
            partner.branded ? "YES" : "NO",
            partner.consent_status,
            partner.data,
            partner.categorize_request,
            partner.active
        ]);
        buildAndDownloadCSV("partners", headers, items);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.PARTNERS}>
                <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                    <MainContentHeaderAction action={Action.DOWNLOAD} label={"CMP Premium"} onClick={exportCMPPremiumPartners}/>
                    <MainContentHeaderAction action={Action.DOWNLOAD_ALL} onClick={exportPartnersList}/>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreatePartner(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textPartners("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters
                        >
                            <SearchField label={textPartners("field.status")}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textPartners(`field.${PartnerField.CREATION_SERVICE}`)}>
                                <SelectService
                                    value={search.searchQuery.service}
                                    services={ApiService.portals()}
                                    onChange={(service) => search.changeParam(SearchParamsField.SERVICE, service?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textPartners(`field.${PartnerField.USER}`)}>
                                <SelectUser
                                    value={search.searchQuery.owner}
                                    field={UserField.EMAIL}
                                    onChange={(user) => search.changeParam(SearchParamsField.OWNER, user?.email)}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textPartners("field.status"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5, label: textPartners(`field.${PartnerField.ID}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: PartnerField.ID}},
                                        {width: 20, label: textPartners(`field.${PartnerField.COMPANY}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM, sort: {field: PartnerField.COMPANY}},
                                        {width: 20, label: textPartners("field.name"), sort: {field: "fullName"}},
                                        {width: 25, label: textPartners(`field.${PartnerField.EMAIL}`), styles: [TableColumnStyle.FIXED_WIDTH, TableColumnStyle.HIDE_SCREEN_MEDIUM], sort: {field: PartnerField.EMAIL}},
                                        {width: 10, label: textPartners(`field.${PartnerField.CREATION_DATE}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM, sort: {field: PartnerField.CREATION_DATE}},
                                        {width: 5, label: textPartners(`field.${PartnerField.CREATION_SERVICE}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                        {width: 5, label: textPartners(`field.${PartnerField.USER}`), styles: [TableColumnStyle.HIDE_SCREEN_MEDIUM]},
                                        {width: 5}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((partner: Partner) =>
                                        <PartnerRow key={partner.id} partner={partner}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreatePartner
                    active={isShowModalCreatePartner}
                    onClose={() => setShowModalCreatePartner(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Partners;
