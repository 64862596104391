import {TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {HelperDomain} from "../../../../api/model/helper/domain/HelperDomain";
import {LabelPartner, LabelStatus} from "../../index";

type HelperDomainTableRowProps = {
    domain: HelperDomain;
}

const HelperDomainRow: FunctionComponent<HelperDomainTableRowProps> = ({domain}) => {
    return (
        <TableRow onClick={{link: domain.getRoute()}}>
            <TableColumn><LabelStatus status={domain.getStatus()}/></TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{domain.id}</TableColumn>
            <TableColumn>{domain.name}</TableColumn>
            <TableColumn>{domain.domain}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}><LabelPartner partner={domain.partner_id}/></TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{domain.partner?.name}</TableColumn>
        </TableRow>
    );
};

export default HelperDomainRow;
