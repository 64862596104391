import {Status} from "../../../../utils/Status";

export const CUSTOMER_STORAGE_STATUSES = [
    Status.PENDING,
    Status.LOADING_TABLE,
    Status.PROCESSING,
    Status.OK,
    Status.ERROR,
    Status.DELETED
];
