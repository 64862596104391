import {Rest} from "../../common/api/rest/Rest";
import {CategoryGroupLinkKeyword} from "../model/audience/category/CategoryGroupLinkKeyword";
import {CategoryGroupKeywordsSearchQuery} from "../model/audience/category/keywords/CategoryGroupKeywordsSearchQuery";
import {CategoryGroupKeywordsSearchResult} from "../model/audience/category/keywords/CategoryGroupKeywordsSearchResult";

export class RestCategoryGroupLinkKeyword extends Rest {
    rootPath = "/console-api/category-keyword/group";

    list(categoryGroupId: number): Promise<CategoryGroupLinkKeyword[]> {
        return this._client.get(`${this.rootPath}/${categoryGroupId}`, CategoryGroupLinkKeyword) as Promise<CategoryGroupLinkKeyword[]>;
    }

    search(params: CategoryGroupKeywordsSearchQuery): Promise<CategoryGroupKeywordsSearchResult> {
        return this._client.get(`${this.rootPath}/search`, CategoryGroupKeywordsSearchResult, {queryParams: params.getJsonParameters()}) as Promise<CategoryGroupKeywordsSearchResult>;
    }

    updateThreshold(categoryGroupId: number, threshold: number) {
        return this._client.post(`${this.rootPath}/${categoryGroupId}/threshold`, {threshold: threshold}, CategoryGroupLinkKeyword);
    }

    clearKeywords(categoryGroupId: number) {
        return this._client.delete(`${this.rootPath}/${categoryGroupId}`);
    }

    saveKeywords(links: CategoryGroupLinkKeyword[]): Promise<CategoryGroupLinkKeyword[]> {
        return this._client.post(`${this.rootPath}/multi`, links, CategoryGroupLinkKeyword) as Promise<CategoryGroupLinkKeyword[]>;
    }

    deleteKeywords(links: CategoryGroupLinkKeyword[]) {
        return this._client.post(`${this.rootPath}/multi-delete`, links);
    }
}
