export enum PartnerOrganizationField {
    ID = "id",
    TYPE = "type",
    NAME = "name",
    DESCRIPTION = "description",
    OWNER_ID = "owner_id",
    OWNER = "owner",
    GROUP_ID = "group_id",
    GROUP = "group",
    DOMAINS = "domains",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
    PROPERTIES = "properties"
}
