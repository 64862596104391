export enum CmpConfigThemeNoConsentButton {
    NONE = "NONE",
    CONTINUE = "CONTINUE",
    REJECT = "REJECT",
    ASK_LATER = "ASK_LATER"
}

export const CMP_CONFIG_THEME_NO_CONSENT_BUTTONS: CmpConfigThemeNoConsentButton[] = [
    CmpConfigThemeNoConsentButton.NONE,
    CmpConfigThemeNoConsentButton.CONTINUE,
    CmpConfigThemeNoConsentButton.REJECT,
    CmpConfigThemeNoConsentButton.ASK_LATER
];
