import {ApiModel} from "../../../common/api/model/ApiModel";

export class AuthorizationItems extends ApiModel {
    authorization_set: string[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            authorization_set: this.authorization_set
        };
    }

}
