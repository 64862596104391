import {ApiModel} from "../../../common/api/model/ApiModel";

export class PartnerProperties extends ApiModel {
    aws_customer_identifier: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            aws_customer_identifier: this.aws_customer_identifier
        };
    }
}
