import {Color, Tag, TagSize, TagTextTransform} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {TaxonomyTag} from "../../../utils/audience/TaxonomyTag";
import {TaxonomyTargetingType} from "../../../utils/audience/TaxonomyTargetingType";

type TagTaxonomyCountProps = {
    type: TaxonomyTargetingType;
    count: number;
};

const TagTaxonomyCount: FunctionComponent<TagTaxonomyCountProps> = ({type, count}) => {
    const tag = TaxonomyTag.getTaxonomyTag(type);
    if (!tag) return <></>;

    return (
        <span className="tag-taxonomy-count">
            <Tag
                cssClass="tag-taxonomy-count__name"
                size={TagSize.SMALL}
                customColor={{
                    backgroundColor: tag.color,
                    color: Color.WHITE,
                    borderColor: tag.color
                }}
                label={tag.name}
                textTransform={TagTextTransform.CAPITALIZE}
                icon={{...tag.icon}}
            />
            <Tag
                cssClass={"tag-taxonomy-count__count"}
                size={TagSize.SMALL}
                customColor={{
                    backgroundColor: Color.WHITE,
                    color: tag.color,
                    borderColor: tag.color
                }}
                label={`${count}`}
            />
        </span>
    );
};

export default TagTaxonomyCount;
