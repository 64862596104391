import {Alert, AlertSeverity, ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutRows, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Textarea} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {useNavigate} from "react-router-dom";
import {DataleaksVersion} from "../../../api/model/dataleaks/version/DataleaksVersion";
import {DataleaksVersionField} from "../../../api/model/dataleaks/version/DataleaksVersionField";

type ModalCreateDataleaksVersionProps = {
    active: boolean;
    source: DataleaksVersion;
    onClose: () => void;
};

const ModalCreateDataleaksVersion: FunctionComponent<ModalCreateDataleaksVersionProps> = ({active, source, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textDataleaksVersions} = useTranslation(TranslationPortalFile.DATALEAKS_VERSIONS);
    const [version, setVersion] = useState<DataleaksVersion>(new DataleaksVersion());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-dataleaks-version";

    useEffect(() => {
        if (active) {
            const newVersion = new DataleaksVersion(source);
            newVersion.comment = "";
            setVersion(newVersion);
        }
    }, [active, source]);

    const handleChange = (field: DataleaksVersionField, value: any) => {
        setVersion((prevState) => new DataleaksVersion({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const versions = await session.restDataleaksVersion.list();
            version.id = Math.max(...versions.map((it) => it.id)) + 1;
            const newVersion = await session.restDataleaksVersion.create(version);
            navigate(newVersion.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("version", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textDataleaksVersions("action.create_version")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <Alert text={textDataleaksVersions("message.create_warning")} severity={AlertSeverity.WARNING}/>
                        <FieldBlock label={textDataleaksVersions("message.create_description")} required>
                            <Textarea
                                required={true}
                                value={version.comment}
                                onChange={(value) => handleChange(DataleaksVersionField.COMMENT, value)}
                                rows={5}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateDataleaksVersion;
