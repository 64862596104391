import {ApiModel} from "../../../../common/api/model/ApiModel";
import {RevenueImportQueryParam} from "./RevenueImportQueryParam";
import {defaultMultiPlatform, RevenuePlatform} from "../RevenuePlatform";
import {RevenuePeriodType} from "../RevenuePeriodType";

export class RevenueImportRequest extends ApiModel {
    platform: RevenuePlatform = new RevenuePlatform();
    period_type: RevenuePeriodType = RevenuePeriodType.WEEK;
    start_date: string = "";
    end_date?: string = "";
    delete: boolean = true;
    file_buffer?: ArrayBuffer = undefined;
    file_extension?: string = undefined;
    user: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getApiRoute(): string {
        if (this.platform.code_name === defaultMultiPlatform.code_name) {
            return `/multiplatform/import/file?${RevenueImportQueryParam.USER}=${this.user}`;
        }
        let path = "";
        if (this.platform.destination_name) {
            path = this.getApiPath();
        } else {
            path = this.getOldApiPath();
        }
        let params = [];
        params.push(`${RevenueImportQueryParam.DELETE}=${this.delete}`);
        if (this.file_extension) {
            params.push(`${RevenueImportQueryParam.FILE_EXTENSION}=${this.file_extension}`);
        }
        params.push(`${RevenueImportQueryParam.USER}=${this.user}`);

        return params.length ? `${path}?${params.join("&")}` : path;
    }

    getOldApiPath() : string {
        const parts = [];
        parts.push("platform", this.platform.code_name);
        parts.push("import", !!this.file_buffer ? "file" : "api");
        parts.push("start", this.start_date);
        if (this.period_type.isInterval) {
            parts.push("end", this.end_date || "");
        }
        parts.push("periodType", this.period_type.name);

        return parts.map((it) => `/${it}`).join("");
    }

    getApiPath() : string {
        const parts = [];
        parts.push(this.platform.destination_name || "");
        parts.push("start", this.start_date);
        if (this.period_type.isInterval) {
            parts.push("end", this.end_date || "");
        }
        parts.push("period", this.period_type.name);
        parts.push("standardize");
        return parts.map((it) => `/${it}`).join("");
    }
}
