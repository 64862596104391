import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DistributionBilling} from "./DistributionBilling";
import {DistributionContact} from "./DistributionContact";
import {DistributionData} from "./DistributionData";
import {DistributionPlatformType} from "./DistributionPlatformType";
import {DistributionPropertiesField} from "./DistributionPropertiesField";
import {DistributionTechnical} from "./DistributionTechnical";

export class DistributionProperties extends ApiModel {
    platform_types: DistributionPlatformType[] = [];
    business_model: string = "";
    documentation_url: string = "";
    comment: string = "";
    contacts: DistributionContact[] = [];
    billing: DistributionBilling = new DistributionBilling();
    data: DistributionData = new DistributionData();
    technical: DistributionTechnical = new DistributionTechnical();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                switch (key) {
                    case DistributionPropertiesField.BILLING:
                        this[key] = new DistributionBilling(o[key]);
                        break;
                    case DistributionPropertiesField.CONTACTS:
                        this[key] = Object.values(o[key] as object[]).map((it) => new DistributionContact(it));
                        break;
                    case DistributionPropertiesField.DATA:
                        this[key] = new DistributionData(o[key]);
                        break;
                    case DistributionPropertiesField.TECHNICAL:
                        this[key] = new DistributionTechnical(o[key]);
                        break;
                    default:
                        this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            platform_types: this.platform_types,
            business_model: this.business_model,
            documentation_url: this.documentation_url,
            comment: this.comment,
            contacts: this.contacts.map((it) => it.getJsonParameters()),
            billing: this.billing.getJsonParameters(),
            data: this.data.getJsonParameters(),
            technical: this.technical.getJsonParameters()
        };
    }
}
