import {ApiModel} from "../../../common/api/model/ApiModel";
import {DealPlatform} from "./DealPlatform";
import {DealDeviceType} from "./DealDeviceType";
import {DealCountry} from "./DealCountry";
import {DealInventoryType} from "./DealInventoryType";
import {DealTiers} from "./DealTiers";
import {DealTargetingOrigin} from "./DealTargetingOrigin";
import {DealFloorPrice} from "./DealFloorPrice";
import {Status} from "../../../utils/Status";
import {Origin} from "../../../common/api/model/Origin";
import {ContentElement} from "../../interface/ContentElement";
import {Module} from "../../../utils/Module";

export class Deal extends ApiModel {
    id: number = 0;
    name: string = "";
    active: boolean = true;
    private: boolean = false;
    private _partner_id: number | undefined;
    description: string = "";
    targeting_origin: DealTargetingOrigin = new DealTargetingOrigin();
    device_type: DealDeviceType[] = [];
    inventory_type: string[] = [DealInventoryType.WEB.name];
    floor_price: DealFloorPrice = {
        usd: 0,
        eur: 0
    };
    viewability: number = 50;
    contextual_relevancy: number = 50;
    country: string[] = [DealCountry.FRANCE.name];
    tiers: DealTiers = new DealTiers();
    _platform: Map<string, DealPlatform[]> = new Map<string, DealPlatform[]>();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get partner_id(): number {
        return this._partner_id ? this._partner_id : 0;
    }

    set partner_id(partner_id: number) {
        this._partner_id = !!partner_id ? partner_id : undefined;
    }

    set platform(platform: Map<string, DealPlatform[]>) {
        if (!platform) return;

        const values = Array.from(Object.entries(platform).length ? new Map(Object.entries(platform)) : platform);
        const mapPlatform: Map<string, DealPlatform[]> = new Map();
        values.forEach(([curationPlatform, dealPlatform]) => {
            let dealPlatforms = Array.from(dealPlatform) as DealPlatform[];
            dealPlatforms = dealPlatforms.map((it) => new DealPlatform({...it}));
            mapPlatform.set(curationPlatform as string, dealPlatforms);
        });
        this._platform = mapPlatform;
    }

    get platform(): Map<string, DealPlatform[]> {
        return this._platform;
    }

    getPlatformItems(): DealPlatform[] {
        let items: DealPlatform[] = [];
        this._platform.forEach((values) => items = [...items, ...values]);
        return items;
    }

    getPlatformJson(): { [key: string]: object } | undefined {
        if (!this._platform) return undefined;
        let t: { [key: string]: object } = {};
        this._platform.forEach((value, key) => {
            t[key] = value;
        });
        return t;
    }

    getJsonParameters(): {} {
        return {
            platform: this.getPlatformJson(),
            name: this.name,
            description: this.description,
            targeting_origin: this.targeting_origin,
            device_type: this.device_type,
            inventory_type: this.inventory_type,
            floor_price: this.floor_price,
            viewability: this.viewability,
            contextual_relevancy: this.contextual_relevancy,
            country: this.country,
            tiers: this.tiers,
            active: this.active,
            private: this.private,
            partner_id: this.partner_id ? this.partner_id : undefined
        };
    }

    getRoute(): string {
        return Module.DEALS.buildRoute(this.id);
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        element.status = this.getStatus();
        if (this.active && !this.private) {
            element.url = `${Origin.AUDIENCE.url}/deal/${this.id}`;
        }
        return element;
    }
}
