export class GoogleAnalyticsVersion {
    static UA_GA3: GoogleAnalyticsVersion = new GoogleAnalyticsVersion("UA / GA3", "UA-");
    static GA4: GoogleAnalyticsVersion = new GoogleAnalyticsVersion("GA4", "G-");

    name: string;
    prefix: string;

    private constructor(name: string, prefix: string) {
        this.name = name;
        this.prefix = prefix;
    }

    static values(): GoogleAnalyticsVersion[] {
        return [
            GoogleAnalyticsVersion.UA_GA3,
            GoogleAnalyticsVersion.GA4
        ];
    }

    static getByName(name: string): GoogleAnalyticsVersion | undefined {
        return this.values().find((it) => it.name === name);
    }
}