import {Rest} from "../../common/api/rest/Rest";
import {PartnerAccount} from "../model/partner/PartnerAccount";
import {PartnerAccountIdBody} from "../model/partner/PartnerAccountIdBody";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {PartnerAccountProperties} from "../model/partner/PartnerAccountProperties";

export class RestPartnerAccount extends Rest implements RestService<PartnerAccount> {
    rootPath = "/console-api/partner";

    search(params: any): Promise<SearchResult<PartnerAccount>> {
        return new Promise<SearchResult<PartnerAccount>>(() => {}); // TODO Implement route
    }

    list(partnerId: number): Promise<PartnerAccount[]> {
        return this._client.get(`${this.rootPath}/${partnerId}/account`, PartnerAccount) as Promise<PartnerAccount[]>;
    }

    get(accountId: number): Promise<PartnerAccount> {
        return new Promise<PartnerAccount>(() => {}); // TODO Implement route
    }

    create(account: PartnerAccount): Promise<PartnerAccount> {
        return this._client.post(`${this.rootPath}/${account.partner_id}/account`, account, PartnerAccount) as Promise<PartnerAccount>;
    }

    update(account: PartnerAccount): Promise<PartnerAccount> {
        return this._client.put(`${this.rootPath}/${account.partner_id}/account`, account, PartnerAccount) as Promise<PartnerAccount>;
    }

    updateProperties(accountId: number, properties: PartnerAccountProperties): Promise<PartnerAccount> {
        return this._client.patch(`${this.rootPath}/account/${accountId}/properties`, properties, PartnerAccount) as Promise<PartnerAccount>;
    }

    delete(accountId: number) {
        // TODO Implement route
    }

    activate(partnerAccountId: number) {
        const body = new PartnerAccountIdBody();
        body.partner_account_id = partnerAccountId;
        return this._client.post(`${this.rootPath}/activate`, body, PartnerAccount) as Promise<PartnerAccount>;
    }
}
