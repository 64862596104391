export enum CmpConfigThemeNoConsentButtonStyle {
    BUTTON_BIG_DEFAULT = "BUTTON_BIG_DEFAULT",
    BUTTON_BIG_PRIMARY = "BUTTON_BIG_PRIMARY",
    LINK = "LINK",
    CLOSE = "CLOSE"
}

export const CMP_CONFIG_THEME_NO_CONSENT_BUTTON_STYLES: CmpConfigThemeNoConsentButtonStyle[] = [
    CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_DEFAULT,
    CmpConfigThemeNoConsentButtonStyle.BUTTON_BIG_PRIMARY,
    CmpConfigThemeNoConsentButtonStyle.LINK,
    CmpConfigThemeNoConsentButtonStyle.CLOSE
];
