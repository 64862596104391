import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {DistributionLicenseCustomFieldType} from "./DistributionLicenseCustomFieldType";

export class DistributionLicenseCustomField extends ApiModel {
    name: string = "";
    label: string = "";
    type: DistributionLicenseCustomFieldType = DistributionLicenseCustomFieldType.TEXT;
    values?: string[];
    from_seat: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
