export class Looker {
    static HOST = "https://looker.sirdata.io";

    static getDashboardIframeUrl(id: number, params: string) {
        return `${this.HOST}/embed/dashboards/${id}?allow_login_screen=true&${params}`;
    }

    static goToDashboard(id: number, params: string) {
        window.open(`${this.HOST}/dashboards/${id}?${params}`, "_blank");
    }
}
