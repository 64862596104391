import {TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {PartnerOrganization} from "../../../../api/model/partner/organization/PartnerOrganization";
import {User} from "../../../../api/model/user/User";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelUser, Tag, ValueCopier} from "../../index";

type PartnerOrganizationRowProps = {
    organization: PartnerOrganization;
}

const PartnerOrganizationRow: FunctionComponent<PartnerOrganizationRowProps> = ({organization}) => {
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);

    return (
        <TableRow onClick={{link: organization.getRoute()}}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{organization.id}</TableColumn>
            <TableColumn>
                {organization.type &&
                    <Tag label={textOrganizations(`type.${organization.type}`)} style={TagStyle.PRIMARY_OCEAN}/>
                }
            </TableColumn>
            <TableColumn>{organization.name}</TableColumn>
            <TableColumn>{organization.group?.name}</TableColumn>
            <TableColumn>
                {organization.domains.map((domain) =>
                    <Tag key={domain} label={domain} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                )}
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {organization.properties.freshsales_id &&
                    <ValueCopier value={organization.properties.freshsales_id} tooltip={textOrganizations("actions.copy_id")}/>
                }
            </TableActionColumn>
            <TableColumn><LabelUser user={new User(organization.owner)}/></TableColumn>
        </TableRow>
    );
};

export default PartnerOrganizationRow;
