import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Action, Button, ButtonSize, ButtonStyle, FormLayoutButtons, FormLayoutRows, ModalContent, ModalDescription, ModalDescriptionAlignment, ModalNew, TranslationLibFile} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalWarningUnsavedNotificationProps = {
    active: boolean;
    onClose: () => void;
}

const ModalWarningUnsavedNotification: FunctionComponent<ModalWarningUnsavedNotificationProps> = ({active, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);

    return (
        <ModalNew active={active}>
            <ModalContent>
                <FormLayoutRows>
                    <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                        <span dangerouslySetInnerHTML={{__html: textNotifications("message.warning_unsaved_notification")}}/>
                    </ModalDescription>
                    <FormLayoutButtons>
                        <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={onClose}>
                            {textCommon(Action.BACK.labelKey)}
                        </Button>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>

    );
};

export default ModalWarningUnsavedNotification;
