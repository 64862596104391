import React, {FC, useEffect, useState} from "react";
import {SelectAutocomplete} from "@sirdata/ui-lib";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {Segment} from "../../../api/model/audience/segment/Segment";

type SelectSegmentProps = {
    value: number | undefined;
    onChange: ((segment?: Segment) => void) | undefined;
    excludedSegments?: Segment[];
    onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
    onInvalid?: (e: React.FormEvent<HTMLInputElement>) => void;
    small?: boolean;
    disabled?: boolean;
};

const SelectSegment: FC<SelectSegmentProps> = ({value, onChange, excludedSegments, ...rest}) => {
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [segments, setSegments] = useState<Segment[]>([]);
    const [filteredSegments, setFilteredSegments] = useState<Segment[]>([]);

    useEffect(() => {
        (async () => {
            try {
                setSegments(await session.getSegments());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("segments", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        if (!!excludedSegments?.length) {
            const newFilteredSegments = segments.filter((segment) => !excludedSegments?.some((it) => it.id === segment.id));
            setFilteredSegments(newFilteredSegments);
        } else {
            setFilteredSegments(segments);
        }
    }, [segments, excludedSegments]);

    return (
        <SelectAutocomplete
            value={value}
            options={filteredSegments.map((it) => ({
                value: it.id,
                label: `${it.id} - ${it.name}`,
                segment: it
            }))}
            onChange={(option) => onChange && onChange(option?.segment)}
            isLoading={isLoading}
            clearable
            {...rest}
        />
    );
};

export default SelectSegment;
