import {Color} from "@sirdata/ui-lib";
import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DataleaksRegulation} from "../DataleaksRegulation";
import {DataleaksTrait} from "../DataleaksTrait";

export class DataleaksAuditScoring extends ApiModel {
    _regulations: Map<DataleaksRegulation, number> = new Map<DataleaksRegulation, number>();
    _regulations_smart_tags: Map<DataleaksRegulation, number> = new Map<DataleaksRegulation, number>();
    _traits: Map<DataleaksTrait, number> = new Map<DataleaksTrait, number>();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get regulations(): Map<DataleaksRegulation, number> {
        return this._regulations;
    }

    set regulations(regulations: Map<DataleaksRegulation, number>) {
        if (Object.entries(regulations).length) {
            const values = new Map<DataleaksRegulation, number>();
            Object.entries(regulations).forEach(([regulation, score]) => {
                values.set(regulation as DataleaksRegulation, score);
            });
            this._regulations = values;
        } else {
            this._regulations = regulations;
        }
    }

    get regulations_smart_tags(): Map<DataleaksRegulation, number> {
        return this._regulations_smart_tags;
    }

    set regulations_smart_tags(regulations_smart_tags: Map<DataleaksRegulation, number>) {
        if (Object.entries(regulations_smart_tags).length) {
            const values = new Map<DataleaksRegulation, number>();
            Object.entries(regulations_smart_tags).forEach(([regulation, score]) => {
                values.set(regulation as DataleaksRegulation, score);
            });
            this._regulations_smart_tags = values;
        } else {
            this._regulations_smart_tags = regulations_smart_tags;
        }
    }

    get traits(): Map<DataleaksTrait, number> {
        return this._traits;
    }

    set traits(traits: Map<DataleaksTrait, number>) {
        if (Object.entries(traits).length) {
            const values = new Map<DataleaksTrait, number>();
            Object.entries(traits).forEach(([trait, score]) => {
                values.set(trait as DataleaksTrait, score);
            });
            this._traits = values;
        } else {
            this._traits = traits;
        }
    }

    static getColorForRate = (rate: number): Color => {
        switch (true) {
            case rate >= 70:
                return Color.SUCCESS_MAIN;
            case rate >= 50:
                return Color.WARNING_MAIN;
            default:
                return Color.DANGER_MAIN;
        }
    };
}
