import {ApiModel} from "../../../../../common/api/model/ApiModel";

export class Network extends ApiModel {
    id: number = 0;
    name: string = "";
    vendors: number[] = [];
    providers: number[] = [];
    sirdataVendors: number[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    hasIABVendor(id: number) {
        return this.vendors.includes(id);
    }

    hasGoogleProvider(id: number) {
        return this.providers.includes(id);
    }

    hasSirdataVendor(id: number) {
        return this.sirdataVendors.includes(id);
    }
}
