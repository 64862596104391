import {ApiModel} from "../../../../common/api/model/ApiModel";
import {PartnerAccount} from "../PartnerAccount";
import {PartnerOrganizationGroup} from "./PartnerOrganizationGroup";
import {PartnerOrganization} from "./PartnerOrganization";

export class PartnerOrganizationRequestDetails extends ApiModel {
    partner_account: PartnerAccount | undefined;
    organization_group: PartnerOrganizationGroup | undefined;
    organization: PartnerOrganization | undefined;
    existing_organization: PartnerOrganization | undefined;
    domain_organization_group: PartnerOrganizationGroup | undefined;
    domain_organizations: PartnerOrganization[] = [];
    domain_partners_and_accounts: any[] = []; // TODO See DomainPartnerAndAccounts in API doc
    email_domain: string = "";
    is_domain_reserved: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
