import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchQuery} from "../../../interface/SearchQuery";

export enum TaxonomyTagSearchParamsField {
    LANGUAGE = "language",
    PURPOSE = "purpose",
}

export class TaxonomyTagSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    language?: string;
    purpose?: string;

    withSearchParams(params: SearchParams): TaxonomyTagSearchQuery {
        let newQuery = super.withSearchParams(params) as TaxonomyTagSearchQuery;
        newQuery.language = params.getString(TaxonomyTagSearchParamsField.LANGUAGE);
        newQuery.purpose = params.getString(TaxonomyTagSearchParamsField.PURPOSE);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            query: this.query,
            size: this.size,
            page: this.page,
            sort: this.sortBy,
            reverse: this.reverseOrder,
            language: this.language,
            purpose: this.purpose
        };
    }
}
