import {Action, Color, IconTooltip, Loadable, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useNavigate} from "react-router-dom";
import {RevenueSeat} from "../../../../api/model/revenue/seat/RevenueSeat";
import {onClickLink} from "../../../../utils/helper";
import {LabelStatus, LabelUser, Tag} from "../../index";
import {User} from "../../../../api/model/user/User";
import {RestrictedContent} from "../../../../common/component/widget";
import {Authorization} from "../../../../api/model/account/Authorization";
import {useTranslation} from "react-i18next";
import {Status} from "../../../../utils/Status";

type RevenueSeatRowProps = {
    seat: RevenueSeat;
    user: User | undefined;
    onValidate: (seat: RevenueSeat) => void;
    isLoading: boolean;
}

const RevenueSeatRow: FunctionComponent<RevenueSeatRowProps> = ({seat, user, onValidate, isLoading}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const navigate = useNavigate();

    return (
        <TableRow onClick={(e) => onClickLink(e, seat.getRoute(), navigate)}>
            <TableColumn>
                <LabelStatus status={seat.getStatus()}/>
            </TableColumn>
            <TableColumn>
                <Tag label={seat.platform} style={TagStyle.PRIMARY_OCEAN}/>
            </TableColumn>
            <TableColumn>{seat.seat_id}</TableColumn>
            <TableColumn>{seat.organization?.name}</TableColumn>
            <TableColumn>{seat.organization?.group?.name}</TableColumn>
            <TableColumn>{seat.advertiser}</TableColumn>
            <TableColumn><LabelUser user={user?.name}/></TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <RestrictedContent allowedTo={Authorization.SEATS_VALIDATE.name}>
                    {seat.status === Status.PENDING.name &&
                        <Loadable loading={isLoading} loaderOptions={{small: true}}>
                            <IconTooltip
                                icon={{...Action.VALIDATE.icon, colorIcon: Color.ORANGE}}
                                text={textCommon(Action.VALIDATE.labelKey)}
                                onClick={() => onValidate(seat)}
                            />
                        </Loadable>
                    }
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default RevenueSeatRow;
