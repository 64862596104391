export class CategoryTopTier {
    static APPAREL: CategoryTopTier = new CategoryTopTier("Apparel");
    static ARTS_AND_ENTERTAINMENT: CategoryTopTier = new CategoryTopTier("Arts & Entertainment");
    static AUDIENCE_EXTENSION: CategoryTopTier = new CategoryTopTier("AUDIENCE EXTENSION");
    static AUDIENCE_MODELING: CategoryTopTier = new CategoryTopTier("AUDIENCE MODELING");
    static AUTO_AND_VEHICLES: CategoryTopTier = new CategoryTopTier("Auto & Vehicles");
    static BANK_AND_INSURANCE: CategoryTopTier = new CategoryTopTier("Bank & Insurance");
    static BEAUTY_AND_PERSONAL_CARE: CategoryTopTier = new CategoryTopTier("Beauty & Personal Care");
    static BRAND: CategoryTopTier = new CategoryTopTier("Brand");
    static BUSINESS_AND_INDUSTRY: CategoryTopTier = new CategoryTopTier("Business & Industry");
    static CHILDCARE: CategoryTopTier = new CategoryTopTier("Childcare");
    static CUSTOM: CategoryTopTier = new CategoryTopTier("CUSTOM");
    static DEMOGRAPHICS: CategoryTopTier = new CategoryTopTier("Demographics");
    static DONOTUSE: CategoryTopTier = new CategoryTopTier("DONOTUSE");
    static ECOLOGY_AND_ENVIRONMENT: CategoryTopTier = new CategoryTopTier("Ecology & Environment");
    static EDUCATION: CategoryTopTier = new CategoryTopTier("Education");
    static FMCG_FOOD_AND_DRINK: CategoryTopTier = new CategoryTopTier("FMCG I Food & Drink");
    static HIGH_TECH: CategoryTopTier = new CategoryTopTier("High Tech");
    static HOME_AND_GARDEN: CategoryTopTier = new CategoryTopTier("Home & Garden");
    static LANGUAGES: CategoryTopTier = new CategoryTopTier("Languages");
    static LIFE_EVENTS: CategoryTopTier = new CategoryTopTier("Life Events");
    static LOOK_ALIKE: CategoryTopTier = new CategoryTopTier("LOOK ALIKE");
    static MEDICAL_HEALTH: CategoryTopTier = new CategoryTopTier("Medical Health");
    static NEWS: CategoryTopTier = new CategoryTopTier("News");
    static PETS_AND_ANIMAL: CategoryTopTier = new CategoryTopTier("Pets & Animals");
    static PRIVACY_SAFETY: CategoryTopTier = new CategoryTopTier("Privacy Safety");
    static SPORTS: CategoryTopTier = new CategoryTopTier("Sports");
    static TRAVEL: CategoryTopTier = new CategoryTopTier("Travel");
    static VIDEO_GAMING: CategoryTopTier = new CategoryTopTier("Video Gaming");
    static WELLBEING: CategoryTopTier = new CategoryTopTier("Wellbeing");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CategoryTopTier[] {
        return [
            CategoryTopTier.APPAREL,
            CategoryTopTier.ARTS_AND_ENTERTAINMENT,
            CategoryTopTier.AUDIENCE_EXTENSION,
            CategoryTopTier.AUDIENCE_MODELING,
            CategoryTopTier.AUTO_AND_VEHICLES,
            CategoryTopTier.BANK_AND_INSURANCE,
            CategoryTopTier.BEAUTY_AND_PERSONAL_CARE,
            CategoryTopTier.BRAND,
            CategoryTopTier.BUSINESS_AND_INDUSTRY,
            CategoryTopTier.CHILDCARE,
            CategoryTopTier.CUSTOM,
            CategoryTopTier.DEMOGRAPHICS,
            CategoryTopTier.DONOTUSE,
            CategoryTopTier.ECOLOGY_AND_ENVIRONMENT,
            CategoryTopTier.EDUCATION,
            CategoryTopTier.FMCG_FOOD_AND_DRINK,
            CategoryTopTier.HIGH_TECH,
            CategoryTopTier.HOME_AND_GARDEN,
            CategoryTopTier.LANGUAGES,
            CategoryTopTier.LIFE_EVENTS,
            CategoryTopTier.LOOK_ALIKE,
            CategoryTopTier.MEDICAL_HEALTH,
            CategoryTopTier.NEWS,
            CategoryTopTier.PETS_AND_ANIMAL,
            CategoryTopTier.PRIVACY_SAFETY,
            CategoryTopTier.SPORTS,
            CategoryTopTier.TRAVEL,
            CategoryTopTier.VIDEO_GAMING,
            CategoryTopTier.WELLBEING
        ];
    }
}
