import {Action, ContentBlock, ContentBlockAction, Loadable, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FC, RefObject, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {CategoryLinkExternalRow} from "..";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {CategoryLinkExternal} from "../../../api/model/audience/category/CategoryLinkExternal";
import {CategoryLinkExternalField} from "../../../api/model/audience/category/CategoryLinkExternalField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {copyApiModelArray, sortItems} from "../../../common/utils/helper";
import {detectChanges} from "../../../common/utils/portal";
import {HandleSaveRef} from "../../../utils/audience/HandleSaveRef";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import ModalAddCategoryLinksExternal from "../../modal/categories/ModalAddCategoryLinksExternal";
import ModalConfirmRemove from "../../modal/ModalConfirmRemove";
import ModalConfirmMessage from "../../modal/ModalConfirmMessage";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoriesDetailsSubModule} from "../../../screen/categories/CategoriesDetails";

type CategoryExternalLinksProps = {
    category: Category;
    handleSaveRef: RefObject<HandleSaveRef>;
    onChange: (module: CategoriesDetailsSubModule, hasChanges: boolean) => void;
};

const CategoryExternalLinks: FC<CategoryExternalLinksProps> = ({category, handleSaveRef, onChange}) => {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);

    const [externalLinks, setExternalLinks] = useState<CategoryLinkExternal[]>([]);
    const [initExternalLinks, setInitExternalLinks] = useState<CategoryLinkExternal[]>([]);

    const [isShowModalAddCategoryLinksExternal, setShowModalAddCategoryLinksExternal] = useState(false);
    const [activeDisableLink, setActiveDisableLink] = useState<CategoryLinkExternal>();
    const [activeRemoveLink, setActiveRemoveLink] = useState<CategoryLinkExternal>();

    useEffect(() => {
        if (!category.id) return;
        (async function () {
            try {
                const newExternalLinks = await session.restCategoryLinkExternal.list(category.id);
                const distributions = await session.restList.getDistributions();
                const mandatoryDistributions = distributions.filter((it) => it.on_creation).map((it) => it.name);
                const links = newExternalLinks.filter((it) => it.active || it.value.length > 0 || mandatoryDistributions.includes(it.map_name));
                const sortedLinks = sortItems(links, CategoryLinkExternalField.MAP_NAME);
                setExternalLinks(sortedLinks);
                setInitExternalLinks(copyApiModelArray(CategoryLinkExternal, sortedLinks));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("distributions", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [category.id, alert]);

    useEffect(() => {
        onChange(CategoriesDetailsSubModule.LINKS_EXTERNAL, detectChanges(externalLinks, initExternalLinks));
    }, [onChange, externalLinks, initExternalLinks]);

    useImperativeHandle(handleSaveRef, () => ({
        handleSave: async () => {
            setLoading(true);
            try {
                const deletedLinks = initExternalLinks.filter((link) => !externalLinks.find((it) => it.map_name === link.map_name));
                for (const deletedLink of deletedLinks) {
                    await session.restCategoryLinkExternal.delete(category.id, deletedLink.id);
                }

                for (const link of externalLinks) {
                    const newExternalLink = await session.restCategoryLinkExternal.update(category.id, link);
                    if (!link.id) {
                        const index = externalLinks.findIndex((it) => it.map_name === link.map_name);
                        externalLinks[index] = newExternalLink;
                    }
                }
                const sortedLinks = sortItems(externalLinks, CategoryLinkExternalField.MAP_NAME);
                setExternalLinks(sortedLinks);

                setInitExternalLinks(copyApiModelArray(CategoryLinkExternal, sortedLinks));
                alert.updateWithSuccess("distributions");
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToUpdate("distributions", e.message);
                }
            } finally {
                setLoading(false);
            }
        }
    }));

    const handleAddLinks = (links: CategoryLinkExternal[]) => {
        const newExternalLinks = sortItems([...externalLinks, ...links], CategoryLinkExternalField.MAP_NAME);
        setExternalLinks(newExternalLinks);
        setShowModalAddCategoryLinksExternal(false);
    };

    const handleChange = (link: CategoryLinkExternal) => {
        const newExternalLinks = [...externalLinks];
        const index = newExternalLinks.findIndex((it) => it.map_name === link.map_name);
        newExternalLinks[index] = link;
        setExternalLinks(newExternalLinks);
    };

    const handleDisableLink = (link?: CategoryLinkExternal) => {
        if (!link) return;
        link.active = false;
        handleChange(link);
        setActiveDisableLink(undefined);
    };

    const handleRemoveLink = (link?: CategoryLinkExternal) => {
        if (!link) return;
        const newExternalLinks = externalLinks.filter((it) => it.map_name !== link.map_name);
        setExternalLinks(newExternalLinks);
        setActiveRemoveLink(undefined);
    };

    const handleActivateAllDistributions = () => {
        setExternalLinks((prevState) => prevState.map((link) => new CategoryLinkExternal({...link, active: true})));
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{
                    title: {label: textCategories("section.distribution")}, actions: [
                        <RestrictedContent key="add_category_group_link_external" allowedTo={Authorization.CATEGORIES.update}>
                            {externalLinks.some((link) => !link.active) &&
                                <ContentBlockAction
                                    action={new Action(textCategories("action.activate_all"), Action.ACTIVATE.icon)}
                                    onClick={handleActivateAllDistributions}
                                />
                            }
                            <ContentBlockAction
                                action={Action.ADD}
                                onClick={() => setShowModalAddCategoryLinksExternal(true)}
                            />
                        </RestrictedContent>
                    ]
                }}
            >
                <Table
                    columns={[
                        {label: textCategories("field.external.status"), width: 20, styles: TableColumnStyle.ALIGN_CENTER},
                        {label: textCategories("field.external.platform"), width: 30},
                        {label: textCategories("field.external.platform_id"), width: 40},
                        {width: 10}
                    ]}
                >
                    {externalLinks.map((link) =>
                        <CategoryLinkExternalRow
                            key={link.id}
                            link={link}
                            onChangeStatus={(item) => item.active ? handleChange(item) : setActiveDisableLink(item)}
                            onChangeValue={handleChange}
                            onRemove={() => setActiveRemoveLink(link)}
                        />
                    )}
                </Table>
                <ModalAddCategoryLinksExternal
                    active={isShowModalAddCategoryLinksExternal}
                    categoryId={category.id}
                    currentLinks={externalLinks}
                    onSubmit={handleAddLinks}
                    onClose={() => setShowModalAddCategoryLinksExternal(false)}
                />
                <ModalConfirmMessage
                    active={!!activeDisableLink}
                    message={textCategories("message.confirm_link_external_disable", {platform: activeDisableLink?.getPlatformName()})}
                    confirm={() => handleDisableLink(activeDisableLink)}
                    confirmAction={Action.DEACTIVATE.labelKey}
                    cancel={() => setActiveDisableLink(undefined)}
                />
                <ModalConfirmRemove
                    active={!!activeRemoveLink}
                    entity={`distribution "${activeRemoveLink?.getPlatformName()}"`}
                    confirm={() => handleRemoveLink(activeRemoveLink)}
                    cancel={() => setActiveRemoveLink(undefined)}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default CategoryExternalLinks;
