import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Partner} from "../../api/model/partner/Partner";
import {SgtmContainer} from "../../api/model/sgtm/container/SgtmContainer";
import {SgtmContainerField} from "../../api/model/sgtm/container/SgtmContainerField";
import {SgtmContainerSearchQuery} from "../../api/model/sgtm/container/SgtmContainerSearchQuery";
import {SgtmContainerSearchResult} from "../../api/model/sgtm/container/SgtmContainerSearchResult";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreateSgtmContainer from "../../component/modal/sgtm-containers/ModalCreateSgtmContainer";
import {MainContentHeader, MainContentHeaderAction, SelectPartner, SelectStatus, SgtmContainerRow} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {Status} from "../../utils/Status";

function SgtmContainers() {
    const alert = useAlert();
    const {t: textSgtmContainers} = useTranslation(TranslationPortalFile.SGTM_CONTAINERS);
    const [isLoading, setLoading] = useState(true);
    const [partners, setPartners] = useState<Partner[]>([]);
    const {setSearchResult, ...search} = useSearch(SgtmContainer, SgtmContainerSearchQuery, SgtmContainerSearchResult);
    const [isShowModalCreateSgtmContainer, setShowModalCreateSgtmContainer] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const partners = await session.getPartners();
                setPartners(partners);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partners", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                setSearchResult(await session.restSgtmContainer.search(search.searchQuery));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("containers", e.message);
                }
            }
        })();
    }, [alert, search.searchQuery, setSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.SGTM_CONTAINERS}>
                <RestrictedContent allowedTo={Authorization.SGTM.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateSgtmContainer(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textSgtmContainers("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters
                        >
                            <SearchField label={textSgtmContainers(`field.${SgtmContainerField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={SgtmContainer.statuses().filter((it) => it !== Status.DELETED)}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textSgtmContainers(`field.${SgtmContainerField.PARTNER_ID}`)}>
                                <SelectPartner
                                    value={search.searchQuery.partner_id}
                                    onChange={(partner) => search.changeParam(SearchParamsField.PARTNER, partner?.id)}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    columns={[
                                        {width: 5, label: textSgtmContainers(`field.${SgtmContainerField.STATUS}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5, label: textSgtmContainers(`field.${SgtmContainerField.ID}`), sort: {field: SgtmContainerField.ID, reverseOrder: true}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 25, label: textSgtmContainers(`field.${SgtmContainerField.NAME}`), sort: {field: SgtmContainerField.NAME}},
                                        {width: 20, label: textSgtmContainers(`field.${SgtmContainerField.PARTNER_ID}`)},
                                        {width: 35, label: textSgtmContainers(`field.${SgtmContainerField.INSTANCE_HOSTS}`)},
                                        {width: 10, label: textSgtmContainers(`field.${SgtmContainerField.CREATION_TS}`), styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    onSort={search.changeSortOrder}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((container) =>
                                        <SgtmContainerRow
                                            key={container.id}
                                            container={container}
                                            partner={partners.find((partner) => container.partner_id === partner.id)}
                                        />
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateSgtmContainer
                    active={isShowModalCreateSgtmContainer}
                    onClose={() => setShowModalCreateSgtmContainer(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default SgtmContainers;
