import {ApiModel} from "../../../common/api/model/ApiModel";
import {QueryString} from "../../../common/api/model/QueryString";
import {ContentElement} from "../../interface/ContentElement";
import {CategorizerConfigEntry} from "./CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "./CategorizerConfigEntryField";
import {CategorizerConfig} from "./config/CategorizerConfig";
import {Module} from "../../../utils/Module";

export class CategorizerConfigEntryGroup extends ApiModel {
    domain?: string;
    host?: string;
    path?: string;
    configs: Map<string, CategorizerConfigEntry<CategorizerConfig>[]> = new Map<string, CategorizerConfigEntry<CategorizerConfig>[]>();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getRoute(): string {
        const params = QueryString.build({domain: this.domain || "''", host: this.host, path: this.path});
        return `${Module.CATEGORIZER.buildRoute("details")}?${params}`;
    }

    static buildFromSearchParams = (searchParams: URLSearchParams): CategorizerConfigEntryGroup => {
        return new CategorizerConfigEntryGroup({
            domain: searchParams.get(CategorizerConfigEntryField.DOMAIN) || "",
            host: searchParams.get(CategorizerConfigEntryField.HOST) || undefined,
            path: searchParams.get(CategorizerConfigEntryField.PATH) || undefined
        });
    };

    toContentElement(): ContentElement {
        let title = this.host || this.domain || "";
        if (this.path) title += this.path;
        return new ContentElement("", title);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "configs") {
                    const objConfigs = o[key];
                    this.configs = new Map<string, CategorizerConfigEntry<CategorizerConfig>[]>();
                    for (let configType in objConfigs) {
                        let configs: CategorizerConfigEntry<CategorizerConfig>[] = [];
                        (objConfigs[configType] as CategorizerConfigEntry<CategorizerConfig>[]).forEach((item) => {
                            configs.push(new CategorizerConfigEntry(item));
                        });
                        this.configs.set(configType, configs);
                    }
                } else this[key] = o[key];
            }
        }
    }
}
