import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {Status} from "../../../../utils/Status";
import {SearchQuery} from "../../../interface/SearchQuery";
import {PartnerDomainField} from "./PartnerDomainField";

export enum PartnerDomainSearchParamsField {
    TARGET_SERVICE = "target_service",
}

export class PartnerDomainSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    status?: string ;
    id_partner?: number;
    target_service?: string;

    withSearchParams(params: SearchParams): PartnerDomainSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(PartnerDomainField.ID, true) as PartnerDomainSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS);
        newQuery.id_partner = params.getNumber(SearchParamsField.PARTNER);
        newQuery.target_service = params.getString(PartnerDomainSearchParamsField.TARGET_SERVICE);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            query: this.query,
            size: this.size,
            page: this.page,
            sort: this.sortBy,
            reverse: this.reverseOrder,
            id_partner: this.id_partner,
            available: !!this.status ? `${this.status === Status.AVAILABLE.name}` : undefined,
            target_service: this.target_service
        };
    }
}
