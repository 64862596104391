import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {ConsentManagementPlatform} from "./ConsentManagementPlatform";
import {ConsentManagementPlatformListField} from "./ConsentManagementPlatformListField";

export class ConsentManagementPlatformList extends ApiModel {
    lastUpdated: string = "";
    cmps: ConsentManagementPlatform[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObjects(ConsentManagementPlatformListField.CMPS, ConsentManagementPlatform);
    }
}
