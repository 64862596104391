import {Color, Icon, TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../../api/ApiSession";
import {CategorizerConfigEntryField} from "../../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigEntryGroup} from "../../../../api/model/categorizer/CategorizerConfigEntryGroup";
import {CategorizerConfigSearchResultItem} from "../../../../api/model/categorizer/CategorizerConfigSearchResultItem";
import {CategorizerConfigType} from "../../../../api/model/categorizer/CategorizerConfigType";
import {ErrorResponse} from "../../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../../utils/constants";
import useAlert from "../../../../utils/hooks/useAlert";
import {Tag} from "../../index";

type CategorizerConfigEntriesRowProps = {
    item: CategorizerConfigSearchResultItem;
};

const CategorizerConfigEntriesRow: FC<CategorizerConfigEntriesRowProps> = ({item}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const alert = useAlert();

    const [entryGroups, setEntryGroups] = useState<CategorizerConfigEntryGroup[]>();
    const [isShowConfigurations, setShowConfigurations] = useState(false);

    useEffect(() => {
        setEntryGroups(undefined);
    }, [item.domain]);

    const loadDomainConfigs = async () => {
        try {
            const configs = await session.restCategorizerDomain.getEntryGroup(item.domain);
            setEntryGroups(configs);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("domain configs", e.message);
            }
        }
    };

    const handleShowConfigurations = async () => {
        if (!entryGroups) {
            await loadDomainConfigs();
        }
        setShowConfigurations((prevState) => !prevState);
    };

    return (
        <>
            <TableRow onClick={handleShowConfigurations}>
                <TableColumn>{item.domain || "-"}</TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{<Icon name="circle" colorIcon={item.hasType(CategorizerConfigType.SANITIZER) ? Color.GREEN : Color.GREY}/>}</TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{<Icon name="circle" colorIcon={item.hasType(CategorizerConfigType.INTENT_CONFIDENCE) ? Color.GREEN : Color.GREY}/>}</TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{<Icon name="circle" colorIcon={item.hasType(CategorizerConfigType.FILTER) ? Color.GREEN : Color.GREY}/>}</TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{<Icon name="circle" colorIcon={item.hasType(CategorizerConfigType.ENRICHMENT) ? Color.GREEN : Color.GREY}/>}</TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{<Icon name="circle" colorIcon={item.hasType(CategorizerConfigType.URL_CATEGORIZATION) ? Color.GREEN : Color.GREY}/>}</TableColumn>
            </TableRow>
            {isShowConfigurations && entryGroups?.map((item, index) =>
                <TableRow key={`entry-${index.toString()}`} onClick={{link: item.getRoute()}}>
                    <TableColumn>
                        {!(item.host || item.path) && <><Icon name="keyboard_arrow_right"/> <b>{textCategorizer(`field.${CategorizerConfigEntryField.DOMAIN}`)}</b>: <Tag style={TagStyle.PRIMARY_OCEAN} label={item.domain || "-"}/></>}
                        {item.host && <><Icon name="keyboard_arrow_right"/> <b>{textCategorizer(`field.${CategorizerConfigEntryField.HOST}`)}</b>: <Tag style={TagStyle.PRIMARY_OCEAN} label={item.host}/></>}
                        {item.path && <><Icon name="keyboard_arrow_right"/> <b>{textCategorizer(`field.${CategorizerConfigEntryField.PATH}`)}</b>: <Tag style={TagStyle.PRIMARY_OCEAN} label={item.path}/></>}
                    </TableColumn>
                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{<Icon name="circle" colorIcon={item.configs.has(CategorizerConfigType.SANITIZER.name) ? Color.GREEN : Color.GREY}/>}</TableColumn>
                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{<Icon name="circle" colorIcon={item.configs.has(CategorizerConfigType.INTENT_CONFIDENCE.name) ? Color.GREEN : Color.GREY}/>}</TableColumn>
                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{<Icon name="circle" colorIcon={item.configs.has(CategorizerConfigType.FILTER.name) ? Color.GREEN : Color.GREY}/>}</TableColumn>
                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{<Icon name="circle" colorIcon={item.configs.has(CategorizerConfigType.ENRICHMENT.name) ? Color.GREEN : Color.GREY}/>}</TableColumn>
                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{<Icon name="circle" colorIcon={item.configs.has(CategorizerConfigType.URL_CATEGORIZATION.name) ? Color.GREEN : Color.GREY}/>}</TableColumn>
                </TableRow>
            )}
        </>
    );
};

export default CategorizerConfigEntriesRow;
