import {ApiModel} from "../../../common/api/model/ApiModel";
import {ApiService} from "../ApiService";
import {capitalize} from "../../../common/utils/string";

export class ApiAuthorization extends ApiModel {
    name: string = "";
    service: string = "";
    on_service_activation: boolean = false;

    static UPDATE_SUFFIX = ".UPDATE";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static partnerServices(): ApiService[] {
        return [
            ApiService.API_DATA,
            ApiService.API_PROXY_CATEGORIZER,
            ApiService.ACCOUNT,
            ApiService.ADS,
            ApiService.AUDIENCE,
            ApiService.CMP,
            ApiService.CUSTOMER,
            ApiService.GTM_SERVER_SIDE,
            ApiService.HELPER,
            ApiService.LIVE_TARGETING
        ];
    }

    static userServices(): ApiService[] {
        return [
            ApiService.CONSOLE,
            ApiService.AUTH
        ];
    }

    get shortName(): string {
        return this.name.replace(`${this.service}.`, "");
    }

    get label(): string {
        let parts: any[] = [ApiService.getLabelFromName(this.service)];

        const shortNameParts = this.shortName.split(".");
        const labels = shortNameParts.map((item) => {
            return item
                .split("_")
                .map((it) => capitalize(it.toLowerCase()))
                .join(" ");
        });
        parts.push(...labels);

        return parts.join(" → ");
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            service: this.service,
            on_service_activation: this.on_service_activation
        };
    }
}
