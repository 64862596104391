import {ApiModel} from "../../../common/api/model/ApiModel";
import {Locale} from "../../../common/utils/Locale";
import {NotificationContent} from "./NotificationContent";
import {NotificationType} from "./NotificationType";
import {Status} from "../../../utils/Status";
import {Formatter} from "../../../common/utils/Formatter";
import moment from "moment";
import {ContentElement} from "../../interface/ContentElement";
import {ApiService} from "../ApiService";
import {Module} from "../../../utils/Module";

export class Notification extends ApiModel {
    id: number = 0;
    type: NotificationType = NotificationType.DEFAULT;
    user_id: number | undefined;
    service: string = "";
    name: string = "";
    expires_at: string = "";
    is_broadcast: boolean = false;
    clear_expires_at: boolean = false;
    first_dispatch_date: string | undefined;
    readonly created_at: string = "";
    readonly updated_at: string = "";
    _locales: Map<Locale, NotificationContent> = new Map<Locale, NotificationContent>();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get locales() {
        return this._locales;
    }

    set locales(locales: Map<Locale, NotificationContent>) {
        if (Object.entries(locales).length) {
            const values = new Map<Locale, NotificationContent>();
            Object.entries(locales).forEach(([locale, content]) => {
                values.set(locale, new NotificationContent(content));
            });
            this._locales = values;
        } else {
            this._locales = locales;
        }
    }

    getJsonParameters(): {} {
        const locales: { [key: string]: any } = {};

        Locale.values().forEach((locale) => {
            locales[locale as string] = this.locales.get(locale)?.filterByType(this.type);
        });

        return {
            type: this.type,
            user_id: this.user_id,
            service: this.service,
            name: this.name,
            is_broadcast: this.is_broadcast,
            expires_at: this.expires_at,
            clear_expires_at: this.clear_expires_at,
            locales: locales
        };
    }

    getRoute(): string {
        return Module.NOTIFICATIONS.buildRoute(this.id);
    }

    getStatus(): Status {
        if (!this.expires_at || Formatter.convertUTCToDate(this.expires_at).diff(moment()) > 0) {
            return Status.ACTIVE;
        } else {
            return Status.INACTIVE;
        }
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        element.status = this.getStatus();
        if (this.created_at) {
            element.createdAt = new Date(this.created_at);
        }
        if (this.updated_at) {
            element.lastUpdated = new Date(this.updated_at);
        }
        return element;
    }

    isInternal() : boolean {
        return this.service === ApiService.CONSOLE.name;
    }

    isDispatched(): boolean {
        return !!this.first_dispatch_date;
    }
}
