import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CustomerOrderSegmentStat extends ApiModel {
    segment_id: number = 0;
    count: number = 0;
    segment_name: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
