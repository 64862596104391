import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Locale} from "../../../../common/utils/Locale";
import {Module} from "../../../../utils/Module";
import {ContentElement} from "../../../interface/ContentElement";
import {DataleaksRegulation} from "../DataleaksRegulation";
import {DataleaksTrait} from "../DataleaksTrait";
import {DataleaksQuestionTexts} from "./DataleaksQuestionTexts";

export class DataleaksQuestion extends ApiModel {
    id: number = 0;
    regulations: DataleaksRegulation[] = [];
    trait?: DataleaksTrait;
    reverse_answer: boolean = false;
    _texts: Map<Locale, DataleaksQuestionTexts> = new Map<Locale, DataleaksQuestionTexts>();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get texts(): Map<Locale, DataleaksQuestionTexts> {
        return this._texts;
    }

    set texts(texts: Map<Locale, DataleaksQuestionTexts>) {
        if (Object.entries(texts).length) {
            const values = new Map<Locale, DataleaksQuestionTexts>();
            Object.entries(texts).forEach(([locale, localeTexts]) => {
                values.set(locale as Locale, new DataleaksQuestionTexts(localeTexts));
            });
            this._texts = values;
        } else {
            this._texts = new Map<Locale, DataleaksQuestionTexts>(texts);
        }
    }

    isValidAnswer(answer: boolean): boolean {
        return this.reverse_answer ? !answer : answer;
    }

    getTextsJson(): { [key: string]: object } {
        let t: { [key: string]: object } = {};
        this._texts.forEach((value, key) => {
            t[key as string] = value;
        });
        return t;
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            regulations: this.regulations,
            trait: this.trait,
            reverse_answer: this.reverse_answer,
            texts: this.getTextsJson()
        };
    }

    getRoute(): string {
        return Module.DATALEAKS_QUESTIONS.buildRoute(this.id);
    }

    toContentElement(): ContentElement {
        return new ContentElement(this.id, this.texts.get(Locale.FRENCH)?.title || "");
    }
}
