import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import qs from "qs";
import {session} from "../../api/ApiSession";
import {GoogleAuthParams} from "../../api/model/account/google/GoogleAuthParams";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {pathPartnersConnect, TranslationPortalFile} from "../../utils/constants";
import {goToLoginGoogle} from "../../utils/helper";
import {usePreprod} from "../../common/utils/helper";
import {Origin} from "../../common/api/model/Origin";

export const PARTNER_CONNECT_ORIGINS: Origin[] = [
    Origin.AUDIENCE,
    Origin.CMP,
    Origin.CUSTOMER,
    Origin.HELPER,
    Origin.GTM_SERVER_SIDE
];

function PartnersConnect() {
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const {search} = useLocation();
    const LOCAL_STORAGE_CONNECT_PARAMS = "connect_params";

    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        const params = qs.parse(search.substr(1));
        if (!params.code) {
            if (!params.origin) setErrorMsg("");
            localStorage.setItem(LOCAL_STORAGE_CONNECT_PARAMS, JSON.stringify(params));
            goToLoginGoogle(pathPartnersConnect);
        } else {
            (async () => {
                try {
                    const connectParams = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONNECT_PARAMS) || "");

                    const origin = Origin.forOrigin(connectParams.origin);
                    if (!origin) {
                        setErrorMsg(textPartners("error.partner_connect.missing_platform"));
                        return;
                    }

                    const googleAuthParams = new GoogleAuthParams({
                        code: params.code,
                        redirect_uri: window.location.origin + window.location.pathname,
                        partner_id: connectParams.partner_id,
                        partner_account_id: connectParams.partner_account_id
                    });

                    const ssoToken = await session.loginGoogleAsPartner(googleAuthParams);
                    const ssoUrl = origin.getSsoUrl(usePreprod);

                    window.location.href = `${ssoUrl}?token=${ssoToken}`;
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        setErrorMsg(textPartners("error.partner_connect.connection_failed", {message: e.message}));
                    }
                } finally {
                    localStorage.removeItem(LOCAL_STORAGE_CONNECT_PARAMS);
                }
            })();
        }
    }, [search, textPartners]);

    return (
        <>{errorMsg ? errorMsg : "Connecting to platform..."}</>
    );
}

export default PartnersConnect;
