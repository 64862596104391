import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {TranslationPortalFile} from "../../../utils/constants";
import {Icon, Tooltip} from "@sirdata/ui-lib";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {detectChanges} from "../../../common/utils/portal";
import {CmpConfigVersion} from "../../../api/model/cmp/config/CmpConfigVersion";
import {CmpPreviewHelper} from "../../../utils/cmp/CmpPreviewHelper";

enum CmpConfigurationPreviewMode {
    LIGHT = "LIGHT",
    DARK = "DARK"
}

enum CmpConfigurationPreviewDevice {
    DESKTOP = "DESKTOP",
    MOBILE = "MOBILE"
}

enum CmpConfigurationPreviewRegulation {
    GDPR = "GDPR",
    CCPA = "CCPA"
}

type CmpConfigurationPreviewProps = {
    cmpConfig: CmpConfig;
};

const CmpConfigurationPreview: FunctionComponent<CmpConfigurationPreviewProps> = ({cmpConfig}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [isLoadingGdpr, setLoadingGdpr] = useState(true);
    const [isLoadingCcpa, setLoadingCcpa] = useState(true);
    const [mode, setMode] = useState<CmpConfigurationPreviewMode>(CmpConfigurationPreviewMode.LIGHT);
    const [device, setDevice] = useState<CmpConfigurationPreviewDevice>(CmpConfigurationPreviewDevice.DESKTOP);
    const [regulation, setRegulation] = useState<CmpConfigurationPreviewRegulation>(CmpConfigurationPreviewRegulation.GDPR);
    const [previewMessage, setPreviewMessage] = useState<{}>({});

    const iframeGdprRef = useRef<HTMLIFrameElement>(null);
    const iframeCcpaRef = useRef<HTMLIFrameElement>(null);
    let timeoutRef = useRef<NodeJS.Timeout>();

    const refreshPreview = useCallback((previewMessage: {}) => {
        setPreviewMessage(previewMessage);
        iframeGdprRef.current?.contentWindow?.postMessage(previewMessage, "*");
        iframeCcpaRef.current?.contentWindow?.postMessage(previewMessage, "*");
    }, []);

    useEffect(() => {
        if (isLoadingGdpr || isLoadingCcpa) {
            return;
        }

        timeoutRef.current = setTimeout(() => {
            const message = cmpConfig.getPreviewMessage(mode === CmpConfigurationPreviewMode.DARK);

            if (detectChanges(message, previewMessage)) {
                setPreviewMessage(message);
            }
            timeoutRef.current = undefined;
        }, 500);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [isLoadingGdpr, isLoadingCcpa, cmpConfig, mode, previewMessage, refreshPreview]);

    useEffect(() => {
        refreshPreview(previewMessage);
    }, [device, previewMessage, refreshPreview]);

    return (
        <div className="preview">
            <div className="preview__actions">
                <div className="preview__toggle">
                    {[CmpConfigurationPreviewDevice.DESKTOP, CmpConfigurationPreviewDevice.MOBILE].map((it) =>
                        <Tooltip
                            key={it}
                            cssClass={clsx("preview__toggle__button", {"preview__toggle__button--active": device === it})}
                            text={textCmpConfigurations(`preview.device.${it}`)}
                            onClick={() => setDevice(it)}
                        >
                            {it === CmpConfigurationPreviewDevice.DESKTOP ?
                                <Icon name="computer" outlined/> :
                                <Icon name="smartphone"/>
                            }
                        </Tooltip>
                    )}
                </div>
                <div className="preview__toggle">
                    {[CmpConfigurationPreviewMode.LIGHT, CmpConfigurationPreviewMode.DARK].map((it) =>
                        <Tooltip
                            key={it}
                            cssClass={clsx("preview__toggle__button", {"preview__toggle__button--active": mode === it})}
                            text={textCmpConfigurations(`preview.mode.${it}`)}
                            onClick={() => setMode(it)}
                        >
                            <Icon name={it === CmpConfigurationPreviewMode.LIGHT ? "wb_sunny" : "brightness_2"}/>
                        </Tooltip>
                    )}
                </div>
                <div className="preview__toggle">
                    {[CmpConfigurationPreviewRegulation.GDPR, CmpConfigurationPreviewRegulation.CCPA].map((it) =>
                        <Tooltip
                            key={it}
                            cssClass={clsx("preview__toggle__button", {"preview__toggle__button--active": regulation === it})}
                            text={textCmpConfigurations(`preview.regulation.${it}`)}
                            onClick={() => setRegulation(it)}
                        >
                            <span className="preview__toggle__label">{it}</span>
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className={clsx("preview__container", {"preview__container--mobile": device === CmpConfigurationPreviewDevice.MOBILE})}>
                <div className="preview__container__iframe">
                    <iframe
                        ref={iframeGdprRef}
                        title={`preview ${CmpConfigVersion.DEFAULT} ${CmpConfigurationPreviewRegulation.GDPR}`}
                        src={CmpPreviewHelper.getUrlForVersion(CmpConfigVersion.DEFAULT)}
                        frameBorder="0"
                        scrolling="yes"
                        height="700"
                        onLoad={() => setLoadingGdpr(false)}
                        className={clsx({"iframe--hidden": regulation === CmpConfigurationPreviewRegulation.CCPA})}
                    />
                    <iframe
                        ref={iframeCcpaRef}
                        title={`preview ${CmpConfigVersion.DEFAULT} ${CmpConfigurationPreviewRegulation.CCPA}`}
                        src={CmpPreviewHelper.getUrlForVersion(CmpConfigVersion.DEFAULT, true)}
                        frameBorder="0"
                        scrolling="yes"
                        height="700"
                        onLoad={() => setLoadingCcpa(false)}
                        className={clsx({"iframe--hidden": regulation !== CmpConfigurationPreviewRegulation.CCPA})}
                    />
                </div>
            </div>
        </div>
    );
};

export default CmpConfigurationPreview;
