import {CategorizerConfig} from "./CategorizerConfig";
import {CategorizerConfigUrlCleanerField} from "./CategorizerConfigUrlCleanerField";

export class CategorizerConfigUrlCleaner extends CategorizerConfig {
    rejected_paths: string[] = [];
    rejected_urls: string[] = [];
    excluded_query_args: string[] = [];
    retained_query_args: string[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                switch (key) {
                    case CategorizerConfigUrlCleanerField.REJECTED_PATHS:
                    case CategorizerConfigUrlCleanerField.EXCLUDED_QUERY_ARGS:
                    case CategorizerConfigUrlCleanerField.REJECTED_URLS:
                    case CategorizerConfigUrlCleanerField.RETAINED_QUERY_ARGS:
                        this[key] = [...o[key]];
                        break;
                    default:
                        this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            rejected_paths: this.rejected_paths,
            rejected_urls: this.rejected_urls,
            excluded_query_args: this.excluded_query_args.length > 0 ? this.excluded_query_args : undefined,
            retained_query_args: this.retained_query_args.length > 0 ? this.retained_query_args : undefined
        };
    }
}
