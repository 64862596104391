import {Alert, FieldBlock, FlexContentSpacing, FormLayoutRows, InputText, Tabs, Textarea} from "@sirdata/ui-lib";
import {Dispatch, FunctionComponent, SetStateAction} from "react";
import {useTranslation} from "react-i18next";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";
import {DataleaksQuestionField} from "../../../api/model/dataleaks/question/DataleaksQuestionField";
import {DataleaksQuestionTexts} from "../../../api/model/dataleaks/question/DataleaksQuestionTexts";
import {DataleaksQuestionTextsField} from "../../../api/model/dataleaks/question/DataleaksQuestionTextsField";
import {TranslationCommonFile} from "../../../common/utils/constants";
import {Locale} from "../../../common/utils/Locale";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalCreateDataleaksQuestionTextsProps = {
    question: DataleaksQuestion;
    setQuestion: Dispatch<SetStateAction<DataleaksQuestion>>;
};

const ModalCreateDataleaksQuestionTexts: FunctionComponent<ModalCreateDataleaksQuestionTextsProps> = ({question, setQuestion}) => {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);

    const handleChange = (field: DataleaksQuestionField, value: any) => {
        setQuestion((prevState) => new DataleaksQuestion({...prevState, [field]: value}));
    };

    const handleChangeText = (locale: Locale, field: DataleaksQuestionTextsField, value: string) => {
        const newTexts = new Map<Locale, DataleaksQuestionTexts>(question.texts);
        const newTextsForLocale = new DataleaksQuestionTexts({...newTexts.get(locale), [field]: value});
        newTexts.set(locale, newTextsForLocale);
        handleChange(DataleaksQuestionField.TEXTS, newTexts);
    };

    const getText = (locale: Locale, field: DataleaksQuestionTextsField): string => {
        const texts = question.texts.get(locale);
        if (!texts) return "";
        return texts[field];
    };

    return (
        <>
            <Alert text={textDataleaksQuestions("message.content_mention")}/>
            <Tabs headers={Locale.values().map((locale) => ({label: textMain(`language.${locale}`)}))}>
                {Locale.values().map((locale) =>
                    <FormLayoutRows key={`${locale}`} spacing={FlexContentSpacing.MEDIUM}>
                        <FieldBlock
                            label={textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.TITLE}`)}
                            required
                        >
                            <InputText
                                value={getText(locale, DataleaksQuestionTextsField.TITLE)}
                                onChange={(value) => handleChangeText(locale, DataleaksQuestionTextsField.TITLE, value)}
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.SHORT_TITLE}`)}
                            required
                        >
                            <InputText
                                value={getText(locale, DataleaksQuestionTextsField.SHORT_TITLE)}
                                onChange={(value) => handleChangeText(locale, DataleaksQuestionTextsField.SHORT_TITLE, value)}
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.TEXT_SUCCESS}`)}
                            required
                        >
                            <Textarea
                                value={getText(locale, DataleaksQuestionTextsField.TEXT_SUCCESS)}
                                rows={5}
                                onChange={(value) => handleChangeText(locale, DataleaksQuestionTextsField.TEXT_SUCCESS, value)}
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.TEXT_FAILED}`)}
                            required
                        >
                            <Textarea
                                value={getText(locale, DataleaksQuestionTextsField.TEXT_FAILED)}
                                rows={5}
                                onChange={(value) => handleChangeText(locale, DataleaksQuestionTextsField.TEXT_FAILED, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                )}
            </Tabs>
        </>
    );
};

export default ModalCreateDataleaksQuestionTexts;
