import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Alert,
    AlertSeverity,
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutRows,
    FormValidationType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Textarea
} from "@sirdata/ui-lib";
import useAlert from "../../../utils/hooks/useAlert";
import {TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {SirdataListPurposeField} from "../../../api/model/cmp/list/sirdata-list/SirdataListPurposeField";
import {SirdataListPurpose} from "../../../api/model/cmp/list/sirdata-list/SirdataListPurpose";
import {CmpConfigLanguage} from "../../../api/model/cmp/config/CmpConfigLanguage";

type ModalCreateCmpSirdataListPurposeProps = {
    active: boolean;
    onClose: (refresh?: boolean) => void;
};

const ModalCreateCmpSirdataListPurpose: FunctionComponent<ModalCreateCmpSirdataListPurposeProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const [isSubmitting, setSubmitting] = useState(false);
    const [purpose, setPurpose] = useState<SirdataListPurpose>(new SirdataListPurpose());
    const FORM_ID = "form-create-cmp-sirdata-list-purpose";

    useEffect(() => {
        if (active) {
            setPurpose(new SirdataListPurpose());
        }
    }, [active]);

    const handleChange = (field: SirdataListPurposeField, value: any) => {
        setPurpose((prevState) => new SirdataListPurpose({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            for (const language of CmpConfigLanguage.values()) {
                let newPurpose = purpose;
                if (language !== CmpConfigLanguage.ENGLISH) {
                    newPurpose = new SirdataListPurpose({
                        ...purpose,
                        [SirdataListPurposeField.NAME]: "",
                        [SirdataListPurposeField.DESCRIPTION]: ""
                    });
                }
                await session.restCmpSirdataListLanguage.upsertPurpose(language.name, newPurpose);
            }
            alert.createWithSuccess("purpose");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("purpose", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpSirdataList("create_purpose")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <Alert
                            severity={AlertSeverity.INFO}
                            text={textCmpSirdataList("message.create_purpose_info")}
                        />
                        <FieldBlock label={textCmpSirdataList(`field.purpose.${SirdataListPurposeField.NAME}`)} required>
                            <InputText
                                value={purpose.name}
                                onChange={(value) => handleChange(SirdataListPurposeField.NAME, value)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.stack.${SirdataListPurposeField.DESCRIPTION}`)} required>
                            <Textarea
                                value={purpose.description}
                                onChange={(value) => handleChange(SirdataListPurposeField.DESCRIPTION, value)}
                                rows={5}
                                isExpandable
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateCmpSirdataListPurpose;
