import {Action, ContentBlock, Icon, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {GlobalVendorList} from "../../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {Vendor} from "../../../../api/model/cmp/list/global-vendor-list/Vendor";
import {Formatter} from "../../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../../utils/constants";
import {GlobalVendorListField} from "../../../../api/model/cmp/list/global-vendor-list/GlobalVendorListField";

type CmpListsIABVendorRowProps = {
    vendor: Vendor;
    globalVendorList: GlobalVendorList;
};

const CmpListsIABVendorRow: FC<CmpListsIABVendorRowProps> = ({vendor, globalVendorList}) => {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const [isOpenDetails, setOpenDetails] = useState(false);

    return (
        <>
            <TableRow onClick={() => setOpenDetails((prevState) => !prevState)}>
                <TableColumn>
                    <Icon {...(isOpenDetails ? Action.COLLAPSE.icon : Action.EXPAND.icon)}/>
                </TableColumn>
                <TableColumn>{vendor.id}</TableColumn>
                <TableColumn>{vendor.name}</TableColumn>
                <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{vendor.policyUrl}</TableColumn>
                <TableColumn>{vendor.deletedDate ? Formatter.formatUTCDate(vendor.deletedDate, Formatter.DATETIME_FORMAT) : ""}</TableColumn>
            </TableRow>
            {isOpenDetails &&
                <TableRow>
                    <TableColumn colSpan={5}>
                        <ContentBlock>
                            {(!!vendor.purposes.length || !!vendor.specialFeatures.length) &&
                                <>
                                    <span>{textCmpLists("gvl.vendor_consent")}</span>
                                    <ul>
                                        {vendor.purposes.map((id) =>
                                            <li key={id}>
                                                <b>{textCmpLists("purpose_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.PURPOSES, id)}
                                            </li>
                                        )}
                                        {vendor.specialFeatures.map((id) =>
                                            <li key={id}>
                                                <b>{textCmpLists("special_feature_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.SPECIAL_FEATURES, id)}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                            {!!vendor.legIntPurposes.length &&
                                <>
                                    <span>{textCmpLists("gvl.vendor_legitimate_interest")}</span>
                                    <ul>
                                        {vendor.legIntPurposes.map((id) =>
                                            <li key={id}>
                                                <b>{textCmpLists("purpose_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.PURPOSES, id)}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                            {(!!vendor.specialPurposes.length || !!vendor.features.length) &&
                                <>
                                    <span>{textCmpLists("gvl.vendor_also_uses")}</span>
                                    <ul>
                                        {vendor.specialPurposes.map((id) =>
                                            <li key={id}>
                                                <b>{textCmpLists("special_purpose_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.SPECIAL_PURPOSES, id)}
                                            </li>
                                        )}
                                        {vendor.features.map((id) =>
                                            <li key={id}>
                                                <b>{textCmpLists("feature_with_id", {id: id})}</b>: {globalVendorList.getName(GlobalVendorListField.FEATURES, id)}
                                            </li>
                                        )}
                                    </ul>
                                </>
                            }
                        </ContentBlock>
                    </TableColumn>
                </TableRow>
            }
        </>
    );
};

export default CmpListsIABVendorRow;
