import {ApiModel} from "../../../common/api/model/ApiModel";

export class JiraIssueScope extends ApiModel {
    id: string = "";
    child?: JiraIssueScope;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            child: this.child
        };
    }
}
