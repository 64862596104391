import {SearchParams} from "../../../utils/SearchParams";
import {SearchParamsField} from "../../../utils/SearchParamsField";
import {SearchQuery} from "../../interface/SearchQuery";

export class UserSearchQuery extends SearchQuery {
    status?: string;

    withSearchParams(params: SearchParams): UserSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting("id", true) as UserSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS);
        return newQuery;
    }
}
