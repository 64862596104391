import {ApiModel} from "../../../common/api/model/ApiModel";
import {ContentElement} from "../../interface/ContentElement";
import {Module} from "../../../utils/Module";

export class ApiProfile extends ApiModel {
    id: number = 0;
    name: string = "";
    description: string = "";
    authorization_set: string[] = [];
    readonly created_at: string = "";
    readonly updated_at: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            authorization_set: this.authorization_set
        };
    }

    getRoute(): string {
        return Module.USER_PROFILES.buildRoute(this.id);
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        if (this.created_at) {
            element.createdAt = new Date(this.created_at);
        }
        if (this.updated_at) {
            element.lastUpdated = new Date(this.updated_at);
        }
        return element;
    }
}
