import {SelectAutocomplete} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {ApiService} from "../../../api/model/ApiService";

type SelectServiceProps = {
    value: string | undefined;
    services: ApiService[];
    onChange: ((service?: ApiService) => void) | undefined;
    onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
    onInvalid?: (e: React.FormEvent<HTMLInputElement>) => void;
    small?: boolean;
    disabled?: boolean;
    clearable?: boolean;
};

const SelectService: FunctionComponent<SelectServiceProps> = ({value, services, onChange, ...rest}) => {
    return (
        <SelectAutocomplete
            value={value}
            options={services.map((it) => ({label: it.label, value: it.name, service: it}))}
            onChange={(option) => onChange && onChange(option?.service)}
            {...rest}
        />
    );
};

export default SelectService;
