import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Action, Color, IconTooltip, TranslationLibFile} from "@sirdata/ui-lib";
import clsx from "clsx";
import {Partner} from "../../../api/model/partner/Partner";
import {Tag} from "../index";

type TagPartnerRowProps = {
    partner: Partner;
    isHighlighted?: boolean;
    onRemove?: () => void;
};

const TagPartnerRow: FunctionComponent<TagPartnerRowProps> = ({partner, isHighlighted, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <div className={clsx("element-list__tag-row", {"element-list__tag-row--highlighted": isHighlighted})}>
            <span className="element-list__tag-row__infos">
                <Tag label={`${partner.id}`} customColor={{backgroundColor: Color.GREEN}}/>
                <span>{partner.nameWithCompany}</span>
            </span>
            {onRemove && <IconTooltip icon={Action.REMOVE.icon} text={textCommon(Action.REMOVE.labelKey)} onClick={onRemove}/>}
        </div>
    );
};

export default TagPartnerRow;
