import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DataleaksRegulation} from "../DataleaksRegulation";
import {DataleaksTrait} from "../DataleaksTrait";

export class DataleaksVersionScoringDetails extends ApiModel {
    rate: number = 0;
    _regulations: Map<DataleaksRegulation, number> = new Map<DataleaksRegulation, number>();
    _regulations_smart_tags: Map<DataleaksRegulation, number> = new Map<DataleaksRegulation, number>();
    _traits: Map<DataleaksTrait, number> = new Map<DataleaksTrait, number>();
    _questions: Map<number, number> = new Map<number, number>();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get regulations(): Map<DataleaksRegulation, number> {
        return this._regulations;
    }

    set regulations(regulations: Map<DataleaksRegulation, number>) {
        if (Object.entries(regulations).length) {
            const values = new Map<DataleaksRegulation, number>();
            Object.entries(regulations).forEach(([regulation, score]) => {
                values.set(regulation as DataleaksRegulation, score);
            });
            this._regulations = values;
        } else {
            this._regulations = regulations;
        }
    }

    get regulations_smart_tags(): Map<DataleaksRegulation, number> {
        return this._regulations_smart_tags;
    }

    set regulations_smart_tags(regulations_smart_tags: Map<DataleaksRegulation, number>) {
        if (Object.entries(regulations_smart_tags).length) {
            const values = new Map<DataleaksRegulation, number>();
            Object.entries(regulations_smart_tags).forEach(([regulation, score]) => {
                values.set(regulation as DataleaksRegulation, score);
            });
            this._regulations_smart_tags = values;
        } else {
            this._regulations_smart_tags = regulations_smart_tags;
        }
    }

    get traits(): Map<DataleaksTrait, number> {
        return this._traits;
    }

    set traits(traits: Map<DataleaksTrait, number>) {
        if (Object.entries(traits).length) {
            const values = new Map<DataleaksTrait, number>();
            Object.entries(traits).forEach(([trait, score]) => {
                values.set(trait as DataleaksTrait, score);
            });
            this._traits = values;
        } else {
            this._traits = traits;
        }
    }

    get questions(): Map<number, number> {
        return this._questions;
    }

    set questions(questions: Map<number, number>) {
        if (Object.entries(questions).length) {
            const values = new Map<number, number>();
            Object.entries(questions).forEach(([questionId, score]) => {
                values.set(+questionId, score);
            });
            this._questions = values;
        } else {
            this._questions = questions;
        }
    }
}
