import {IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ApiProfile} from "../../../../api/model/auth/ApiProfile";
import {TranslationPortalFile} from "../../../../utils/constants";
import {onClickLink} from "../../../../utils/helper";

type UserProfileRowProps = {
    profile: ApiProfile;
    onShowDetails: (profile: ApiProfile) => void;
}

const UserProfileRow: FunctionComponent<UserProfileRowProps> = ({profile, onShowDetails}) => {
    const {t: textAuthorizations} = useTranslation(TranslationPortalFile.AUTHORIZATIONS);
    const navigate = useNavigate();

    return (
        <>
            <TableRow onClick={(e) => onClickLink(e, profile.getRoute(), navigate)}>
                <TableColumn>{profile.name}</TableColumn>
                <TableColumn>{profile.description}</TableColumn>
                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                    <IconTooltip
                        icon={{name: "group"}}
                        text={textAuthorizations("actions.see_users_with_profile")}
                        onClick={() => onShowDetails(profile)}
                    />
                </TableActionColumn>
            </TableRow>
        </>
    );
};

export default UserProfileRow;
