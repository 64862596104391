import {ButtonLinkCancel, ButtonValidate, FieldBlock, FlexContentLayout, Form, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, FormValidationType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select, SelectAutocomplete} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {PartnerOrganization} from "../../../api/model/partner/organization/PartnerOrganization";
import {PartnerOrganizationField} from "../../../api/model/partner/organization/PartnerOrganizationField";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectUser} from "../../snippet";
import {PartnerOrganizationPropertiesField} from "../../../api/model/partner/organization/PartnerOrganizationPropertiesField";
import {PartnerOrganizationType} from "../../../api/model/partner/organization/PartnerOrganizationType";
import {PartnerOrganizationGroup} from "../../../api/model/partner/organization/PartnerOrganizationGroup";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";

export type ModalCreatePartnerOrganizationProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreatePartnerOrganization: FunctionComponent<ModalCreatePartnerOrganizationProps> = ({active, onClose}) => {
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const alert = useAlert();
    const navigate = useNavigate();
    const [organization, setOrganization] = useState<PartnerOrganization>(new PartnerOrganization());
    const [organizationGroups, setOrganizationGroups] = useState<PartnerOrganizationGroup[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-partner-organization";

    useEffect(() => {
        if (active) {
            setOrganization(new PartnerOrganization({[PartnerOrganizationField.TYPE]: PartnerOrganizationType.ADTECH.name}));
        }
    }, [active]);

    useEffect(() => {
        (async () => {
            try {
                const organizationGroups = await session.restPartnerOrganizationGroup.list();
                setOrganizationGroups(organizationGroups);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("organization groups", e.message);
                }
            }
        })();
    }, [alert]);

    const handleChange = (field: PartnerOrganizationField, value: any) => {
        setOrganization((prevState) => new PartnerOrganization({...prevState, [field]: value}));
    };

    const handleChangeProperties = (field: PartnerOrganizationPropertiesField, value: any) => {
        const newProperties = {
            ...organization.properties,
            [field]: value
        };
        handleChange(PartnerOrganizationField.PROPERTIES, newProperties);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            let newOrganization = await session.restPartnerOrganization.create(organization);
            newOrganization = await session.restPartnerOrganization.updateProperties(newOrganization.id, organization.properties);
            navigate(newOrganization.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("organization", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textOrganizations("create_organization")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                            <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.NAME}`)} required>
                                <InputText
                                    value={organization.name}
                                    onChange={(value) => handleChange(PartnerOrganizationField.NAME, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.TYPE}`)} required>
                                <Select
                                    value={organization.type}
                                    options={PartnerOrganizationType.values().map((type) => ({value: type.name, label: textOrganizations(`type.${type.name}`)}))}
                                    onChange={(option) => handleChange(PartnerOrganizationField.TYPE, `${option?.value || ""}`)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.DESCRIPTION}`)}>
                            <InputText
                                value={organization.description}
                                onChange={(value) => handleChange(PartnerOrganizationField.DESCRIPTION, value)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns columns={2}>
                            <FieldBlock label={textOrganizations("field.organization_group")}>
                                <SelectAutocomplete
                                    value={organization.group_id}
                                    options={organizationGroups.map((it) => ({value: it.id, label: it.name}))}
                                    onChange={(option) => handleChange(PartnerOrganizationField.GROUP_ID, option ? +option?.value : 0)}
                                    clearable
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutSeparator/>
                        <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                            <FieldBlock
                                label={textOrganizations(`field.${PartnerOrganizationField.OWNER_ID}`)}
                                required
                            >
                                <SelectUser
                                    value={organization.owner_id}
                                    onChange={(user) => handleChange(PartnerOrganizationField.OWNER_ID, user?.id || 0)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.PROPERTIES}.${PartnerOrganizationPropertiesField.FRESHSALES_ID}`)}>
                                <InputText
                                    value={organization.properties.freshsales_id || ""}
                                    onChange={(value) =>  handleChangeProperties(PartnerOrganizationPropertiesField.FRESHSALES_ID, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreatePartnerOrganization;
