import {Box, BoxRadius, BoxSpacing, FieldBlock, FlexContentDirection, FlexContentSpacing, FormLayoutColumns, FormLayoutRows, Icon, IconTooltip, InputDomain, InputText} from "@sirdata/ui-lib";
import clsx from "clsx";
import {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {SgtmContainer} from "../../../api/model/sgtm/container/SgtmContainer";
import {SgtmContainerField} from "../../../api/model/sgtm/container/SgtmContainerField";
import {SgtmContainerHostDetail} from "../../../api/model/sgtm/container/SgtmContainerHostDetail";
import {SgtmContainerHostDetailsField} from "../../../api/model/sgtm/container/SgtmContainerHostDetailsField";
import {TranslationPortalFile} from "../../../utils/constants";

type SgtmContainerHostnameProps = {
    onChange: (hostname: string) => void;
};

const SgtmContainerHostname: FC<SgtmContainerHostnameProps> = ({onChange}) => {
    const {t: textSgtmContainers} = useTranslation(TranslationPortalFile.SGTM_CONTAINERS);
    const [hostDetail, setHostDetail] = useState<SgtmContainerHostDetail>(new SgtmContainerHostDetail());

    const handleChange = (field: SgtmContainerHostDetailsField, value: any) => {
        const newHostDetails = new SgtmContainerHostDetail({...hostDetail, [field]: value});
        setHostDetail(newHostDetails);
        onChange(newHostDetails.getHostname());
    };

    return (
        <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
            <FormLayoutColumns>
                <FieldBlock
                    name={textSgtmContainers(`field.${SgtmContainerField.HOST_DETAILS}.${SgtmContainerHostDetailsField.SUBDOMAIN}`)}
                    label={textSgtmContainers(`field.${SgtmContainerField.HOST_DETAILS}.${SgtmContainerHostDetailsField.SUBDOMAIN}`)}
                    tooltip={textSgtmContainers("tooltip.host")}
                    content={{spacing: FlexContentSpacing.SMALL}}
                    required
                >
                    <IconTooltip
                        icon={{name: "rotate_right"}}
                        text={textSgtmContainers("action.generate_randomly")}
                        onClick={() => handleChange(SgtmContainerHostDetailsField.SUBDOMAIN, SgtmContainer.getRandomHost())}
                    />
                    <InputText
                        placeholder={textSgtmContainers(`placeholder.${SgtmContainerHostDetailsField.SUBDOMAIN}`)}
                        value={hostDetail.subdomain}
                        onChange={(value) => handleChange(SgtmContainerHostDetailsField.SUBDOMAIN, value.trim().toLowerCase().replace(" ", ""))}
                    />
                </FieldBlock>
                <FieldBlock
                    name={textSgtmContainers(`field.${SgtmContainerField.HOST_DETAILS}.${SgtmContainerHostDetailsField.DOMAIN}`)}
                    label={textSgtmContainers(`field.${SgtmContainerField.HOST_DETAILS}.${SgtmContainerHostDetailsField.DOMAIN}`)}
                    content={{direction: FlexContentDirection.COLUMN}}
                    tooltip={textSgtmContainers(`tooltip.${SgtmContainerHostDetailsField.DOMAIN}`)}
                    required
                >
                    <InputDomain
                        placeholder={textSgtmContainers(`placeholder.${SgtmContainerHostDetailsField.DOMAIN}`)}
                        value={hostDetail.domain}
                        onChange={(value) => handleChange(SgtmContainerHostDetailsField.DOMAIN, value.trim().toLowerCase().replace(" ", ""))}
                    />
                </FieldBlock>
            </FormLayoutColumns>
            <Box radius={BoxRadius.MD} spacing={BoxSpacing.SMALL} cssClass={clsx("hostname-form", {"hostname-form--verified": hostDetail.hasValidHostname()})}>
                <div>
                    {"> "}
                    <span className={clsx({"hostname-form__result--highlighted": hostDetail.subdomain})}>{hostDetail.subdomain || textSgtmContainers(`placeholder.${SgtmContainerHostDetailsField.SUBDOMAIN}`)}</span>
                    <span className={clsx({"hostname-form__result--highlighted": hostDetail.hasHostnameFilled()})}>.</span>
                    <span className={clsx({"hostname-form__result--highlighted": hostDetail.domain})}>{hostDetail.domain || textSgtmContainers(`placeholder.${SgtmContainerHostDetailsField.DOMAIN}`)}</span>
                </div>
                <Icon name="check_circle" outlined/>
            </Box>
        </FormLayoutRows>
    );
};

export default SgtmContainerHostname;
