import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CustomerCountingTable extends ApiModel {
    row_count: number = 0;
    distinct_mapping_column_values: number = 0;
    column_names: string[] = [];
    row_sample: string[][] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
