export enum DistributionLicenseField {
    ID = "id",
    ID_SEAT = "id_seat",
    ACTIVE = "active",
    EXTERNAL_MAP_NAME = "external_map_name",
    SEAT = "seat",
    SEGMENT_LINK_TYPE = "segment_link_type",
    SEGMENT_LINK_DATATYPE = "segment_link_datatype",
    SEGMENTS = "segments",
    CURRENCY = "currency",
    PRICE_CPM = "price_cpm",
    PRICE_CPM_MODIFIER = "price_cpm_modifier",
    PRICE_MEDIA_COST = "price_media_cost",
    CUSTOM_FIELDS = "custom_fields",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at",
}