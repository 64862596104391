import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";

export enum DataleaksAuditSearchParamsField {
    VERSION_ID = "version_id",
    CMP_ID = "cmp_id",
}

export class DataleaksAuditSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    partner_id?: number;
    version_id?: number;
    user_id?: number;
    cmp_id?: number;

    withSearchParams(params: SearchParams): DataleaksAuditSearchQuery {
        const newQuery = super.withSearchParams(params) as DataleaksAuditSearchQuery;
        newQuery.partner_id = params.getNumber(SearchParamsField.PARTNER);
        newQuery.version_id = params.getNumber(DataleaksAuditSearchParamsField.VERSION_ID);
        newQuery.user_id = params.getNumber(SearchParamsField.OWNER);
        newQuery.cmp_id = params.getNumber(DataleaksAuditSearchParamsField.CMP_ID);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            query: this.query,
            page: this.page,
            size: this.size,
            sort_by: this.sortBy,
            reverse_order: this.reverseOrder,
            partner_id: this.partner_id,
            version_id: this.version_id,
            user_id: this.user_id,
            cmp_id: this.cmp_id
        };
    }
}
