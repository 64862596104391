import {CategorizerConfig} from "./CategorizerConfig";

export class CategorizerConfigSanitizer extends CategorizerConfig {
    sanitizer_version: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            sanitizer_version: !this.disabled ? this.sanitizer_version : undefined,
            disabled: this.disabled
        };
    }
}
