import {ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {AccountWithAuthorizationItem} from "../../../api/model/auth/AccountWithAuthorizationItem";
import {ApiAuthorization} from "../../../api/model/auth/ApiAuthorization";
import {ApiProfile} from "../../../api/model/auth/ApiProfile";
import {AuthorizationType} from "../../../screen/authorizations/AuthorizationsPartner";
import {MODAL_PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {SearchResult} from "../../../api/interface/SearchResult";
import {AccountWithAuthorizationItemField} from "../../../api/model/auth/AccountWithAuthorizationItemField";
import {Module} from "../../../utils/Module";

export type ModalAuthDetailsProps = {
    active: boolean;
    authz?: ApiAuthorization | ApiProfile;
    type?: AuthorizationType;
    onClose: () => void;
};

const ModalAuthDetails: FunctionComponent<ModalAuthDetailsProps> = ({active, authz, type, onClose}) => {
    const {t: textAuthorizations} = useTranslation(TranslationPortalFile.AUTHORIZATIONS);
    const [items, setItems] = useState<AccountWithAuthorizationItem[]>([]);
    const [searchResult, setSearchResult] = useState<SearchResult<AccountWithAuthorizationItem>>(new SearchResult(AccountWithAuthorizationItem));
    const [isLoading, setLoading] = useState(false);

    const goToItem = (item: AccountWithAuthorizationItem) => {
        if (type === AuthorizationType.USER) {
            window.open(Module.USERS.buildRoute(item.id), "_blank");
        } else {
            window.open(Module.PARTNERS.buildRoute(item.id), "_blank");
        }
    };

    const handleChangePage = (page: number) => {
        setSearchResult(SearchResult.buildFromList(AccountWithAuthorizationItem, items, page - 1, MODAL_PAGE_SIZE));
    };

    useEffect(() => {
        if (!active || !type || !authz) {
            setItems([]);
            setSearchResult(new SearchResult(AccountWithAuthorizationItem));
            return;
        }

        (async () => {
            try {
                setLoading(true);
                let items = [];
                if (authz instanceof ApiAuthorization) {
                    if (type === AuthorizationType.PARTNER) {
                        items = await session.restAuthorizationPartner.getDetails(authz);
                    } else {
                        items = await session.restAuthorizationUser.getDetails(authz);
                    }
                } else {
                    items = await session.restUserProfile.getDetails(authz.id);
                }
                setItems(items);
                setSearchResult(SearchResult.buildFromList(AccountWithAuthorizationItem, items, 0, MODAL_PAGE_SIZE));
            } catch {
            } finally {
                setLoading(false);
            }
        })();
    }, [active, authz, type]);

    return (
        <ModalNew onClose={onClose} active={active} loading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle title={textAuthorizations(authz instanceof ApiAuthorization ? "list_auth_granted_to" : "list_profile_granted_to", {type: type, name: authz?.name})}/>
            </ModalHeader>
            <ModalContent>
                <Table
                    columns={[
                        {label: textAuthorizations(`field.${AccountWithAuthorizationItemField.ID}`), width: 20, styles: TableColumnStyle.ALIGN_CENTER},
                        {label: textAuthorizations(`field.${AccountWithAuthorizationItemField.NAME}`), width: 80}
                    ]}
                    pagination={searchResult.getPagination(handleChangePage)}
                >
                    {searchResult.elements.map((item) =>
                        <TableRow key={item.id} onClick={() => goToItem(item)}>
                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{item.id}</TableColumn>
                            <TableColumn>{item.company || item.name || "-"}</TableColumn>
                        </TableRow>
                    )}
                </Table>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalAuthDetails;
