import {Action, ActionsMenu, Color, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import copy from "copy-to-clipboard";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CategoryGroup} from "../../../../api/model/audience/category/CategoryGroup";
import {CategoryType} from "../../../../api/model/audience/category/CategoryType";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Tag} from "../../index";
import useAlert from "../../../../utils/hooks/useAlert";

type CategoryGroupRowProps = {
    categoryGroup: CategoryGroup;
    hasKeywords: boolean;
    hasSql: boolean;
    hasConfigEnrichment: boolean;
}

const CategoryGroupRow: FunctionComponent<CategoryGroupRowProps> = ({categoryGroup, hasKeywords, hasSql, hasConfigEnrichment}) => {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const alert = useAlert();

    const handleCopyName = () => {
        copy(`${categoryGroup.name}`);
        alert.copied("category Name");
    };

    const handleCopyId = () => {
        copy(`${categoryGroup.id}`);
        alert.copied("category ID");
    };

    return (
        <TableRow onClick={{link: categoryGroup.getRoute()}}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <Tag label={textCategories(`taxonomy.${categoryGroup.taxonomy}`)} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
            </TableColumn>
            <TableColumn>
                {CategoryType.values().map((item) =>
                    <IconTooltip
                        key={item.name}
                        icon={{...item.icon, colorIcon: categoryGroup.getColorIcon(item)}}
                        text={textCategories(`category_type.${item.name}`)}
                    />
                )}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{categoryGroup.id}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{categoryGroup.id_parent || "-"}</TableColumn>
            <TableColumn>{categoryGroup.fullName}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <IconTooltip
                    icon={{name: "link", colorIcon: !!categoryGroup.links?.length ? Color.MIDNIGHT_LIGHT : Color.GREY}}
                    text={textCategories(`${!!categoryGroup.links?.length ? "has_links" : "has_no_links"}`)}
                />
                <IconTooltip
                    icon={{name: "settings", colorIcon: hasSql ? Color.MIDNIGHT_LIGHT : Color.GREY}}
                    text={textCategories(`${hasSql ? "has_bq_request" : "has_no_bq_request"}`)}
                />
                <IconTooltip
                    icon={{name: "key", colorIcon: hasKeywords ? Color.MIDNIGHT_LIGHT : Color.GREY}}
                    text={textCategories(`${hasKeywords ? "has_keywords" : "has_no_keyword"}`)}
                />
                <IconTooltip
                    icon={{name: "tune", colorIcon: hasConfigEnrichment ? Color.MIDNIGHT_LIGHT : Color.GREY}}
                    text={textCategories(`${hasConfigEnrichment ? "has_config_enrichment" : "has_no_config_enrichment"}`)}
                />
            </TableColumn>
            <TableActionColumn>
                <ActionsMenu
                    iconTooltip={{icon: Action.COPY.icon, text: textCommon(Action.COPY.labelKey)}}
                    items={[
                        {label: textCategories("action.copy_id"), onClick: handleCopyId},
                        {label: textCategories("action.copy_name"), onClick: handleCopyName}
                    ]}
                />
            </TableActionColumn>
        </TableRow>
    );
};

export default CategoryGroupRow;
