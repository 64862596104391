import {ApiModel} from "../../../../common/api/model/ApiModel";
import {SgtmContainerProperties} from "./SgtmContainerProperties";

export class SgtmContainerHostProperties extends ApiModel {
    id: number = 0;
    hosts: string[] = [];
    properties: SgtmContainerProperties = new SgtmContainerProperties();
    active: boolean = true;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            hosts: this.hosts,
            properties: this.properties,
            active: this.active
        };
    }
}
