import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {SirdataListVendor} from "../model/cmp/list/sirdata-list/SirdataListVendor";

export class RestCmpSirdataListVendor extends Rest implements RestService<SirdataListVendor> {
    rootPath = "/console-api/cmp/list/sirdata/vendor";

    search(params: any): Promise<SearchResult<SirdataListVendor>> {
        return new Promise<SearchResult<SirdataListVendor>>(() => {}); // Not necessary
    }

    list(): Promise<SirdataListVendor[]> {
        return this._client.get(`${this.rootPath}`, SirdataListVendor) as Promise<SirdataListVendor[]>;
    }

    create(vendor: SirdataListVendor): Promise<SirdataListVendor> {
        return this._client.post(`${this.rootPath}`, vendor, SirdataListVendor) as Promise<SirdataListVendor>;
    }

    get(vendorId: number): Promise<SirdataListVendor> {
        return this._client.get(`${this.rootPath}/${vendorId}`, SirdataListVendor) as Promise<SirdataListVendor>;
    }

    update(vendor: SirdataListVendor): Promise<SirdataListVendor> {
        return this._client.put(`${this.rootPath}/${vendor.id}`, vendor, SirdataListVendor) as Promise<SirdataListVendor>;
    }

    delete(vendorId: number): Promise<SirdataListVendor> {
        return this._client.delete(`${this.rootPath}/${vendorId}`) as Promise<SirdataListVendor>;
    }
}
