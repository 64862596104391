import {SearchParams} from "../../../utils/SearchParams";
import {SearchParamsField} from "../../../utils/SearchParamsField";
import {SearchQuery} from "../../interface/SearchQuery";

export class AuthorizationSearchQuery extends SearchQuery {
    service: string = "";

    withSearchParams(params: SearchParams): AuthorizationSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting("name", false) as AuthorizationSearchQuery;
        newQuery.service = params.getString(SearchParamsField.SERVICE) || "";
        return newQuery;
    }
}
