import {Action, ActionsMenu, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {DistributionLicense} from "../../../../api/model/audience/distribution/license/DistributionLicense";
import {DistributionLicenseSegmentLinkType} from "../../../../api/model/audience/distribution/license/DistributionLicenseSegmentLinkType";
import {SegmentDataType} from "../../../../api/model/audience/segment/SegmentDataType";
import {Currency} from "../../../../api/model/currency/Currency";
import {RestrictedContent} from "../../../../common/component/widget";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelStatus, Tag, TagSegmentDataType} from "../../index";

type DistributionRowProps = {
    license: DistributionLicense;
    label: string;
    onClick: () => void;
    onDelete: () => void;
};

const DistributionLicenseRow: FunctionComponent<DistributionRowProps> = ({license, label, onClick, onDelete}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textDistributionsLicenses} = useTranslation(TranslationPortalFile.DISTRIBUTIONS_LICENSES);

    return (
        <TableRow onClick={onClick}>
            <TableColumn>
                <LabelStatus status={license.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <Tag label={label} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {license.seat.seat_id}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {(license.segment_link_type === DistributionLicenseSegmentLinkType.DATATYPE.name && license.segment_link_datatype) ?
                    <TagSegmentDataType type={SegmentDataType.getByName(license.segment_link_datatype)}/> :
                    <Tag label={license.segment_link_type} style={TagStyle.PRIMARY_CYAN_LIGHT}/>
                }
            </TableColumn>
            <TableColumn>
                {license.segments.length ? textDistributionsLicenses("field.segments_count", {count: license.segments.length}) : "-"}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {license.seat.organization_name}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {`${license.price_cpm || 0} ${Currency.fromType(license.currency)?.symbol}`}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {license.price_cpm_modifier ? `${license.price_cpm_modifier*100}%` : "-"}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {`${license.price_media_cost || 0} ${Currency.fromType(license.currency)?.symbol}`}
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <RestrictedContent allowedTo={Authorization.DISTRIBUTIONS_LICENSES.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={[
                            {label: textCommon(Action.DELETE.labelKey), onClick: onDelete, critical: true, separator: true}
                        ]}
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default DistributionLicenseRow;
