import {Action, ActionsMenu, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {PartnerLicenseInfo} from "../../../../api/model/partner/license/PartnerLicenseInfo";

type PartnerLicenseInfoRowProps = {
    info: PartnerLicenseInfo;
    onEdit: () => void;
    onRemove: () => void;
}

const PartnerLicenseInfoRow: FunctionComponent<PartnerLicenseInfoRowProps> = ({info, onEdit, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    return (
        <TableRow>
            <TableColumn>{info.key}</TableColumn>
            <TableColumn>
                {
                    typeof info.value !== "object" ? info.value :
                        Object.entries(info.value).map(([key, value]: any) => (
                            <div key={key}>{key}: {value}</div>
                        ))
                }
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <ActionsMenu
                    iconTooltip={{icon: Action.EDIT.icon, text: textCommon(Action.EDIT.labelKey)}}
                    items={[
                        {label: textCommon(Action.EDIT.labelKey), onClick: onEdit},
                        {label: textCommon(Action.REMOVE.labelKey), onClick: onRemove, critical: true, separator: true}
                    ]}
                />
            </TableActionColumn>
        </TableRow>
    );
};

export default PartnerLicenseInfoRow;
