import {FlexContent, FlexContentDirection, TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {DataleaksAudit} from "../../../../api/model/dataleaks/audit/DataleaksAudit";
import {DataleaksRegulation} from "../../../../api/model/dataleaks/DataleaksRegulation";
import {DataleaksVersion} from "../../../../api/model/dataleaks/version/DataleaksVersion";
import {DataleaksVersionField} from "../../../../api/model/dataleaks/version/DataleaksVersionField";
import {User} from "../../../../api/model/user/User";
import {Formatter} from "../../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../../utils/constants";
import {DataleaksScore, LabelPartner, Tag} from "../../index";

type DataleaksAuditRowProps = {
    audit: DataleaksAudit;
    owner?: User;
};

const DataleaksAuditRow: FunctionComponent<DataleaksAuditRowProps> = ({audit, owner}) => {
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);

    const buildScores = (regulation: DataleaksRegulation) => {
        const rate = audit.scoring.regulations.get(regulation);
        const rateSmartTags = audit.scoring.regulations_smart_tags.get(regulation);
        if (!rate) return;

        return (
            <span>
                {textDataleaksQuestions(`regulation.${regulation}`)} : <DataleaksScore value={rate}/>
                {rateSmartTags && rateSmartTags !== rate &&
                    <span> (<DataleaksScore value={rateSmartTags}/>)</span>
                }
            </span>
        );
    };

    return (
        <TableRow onClick={{link: audit.getRoute()}}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{audit.id}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <LabelPartner partner={audit.partner_id}/>
            </TableColumn>
            <TableColumn>{audit.company.name}</TableColumn>
            <TableColumn>{audit.domain}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <Tag label={new DataleaksVersion({[DataleaksVersionField.ID]: audit.version_id}).label} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
            </TableColumn>
            <TableColumn>
                <FlexContent direction={FlexContentDirection.COLUMN}>
                    {buildScores(DataleaksRegulation.GDPR)}
                    {buildScores(DataleaksRegulation.EPRIVACY)}
                </FlexContent>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{owner?.initials.toUpperCase() || "-"}</TableColumn>
            <TableColumn>{Formatter.formatDate(audit.created_at, Formatter.DATETIME_FORMAT)}</TableColumn>
        </TableRow>
    );
};

export default DataleaksAuditRow;
