import {Action, ActionsMenu, ContentBlock, ContentBlockAction, Loadable, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CmpConfigPublisher} from "../../../api/model/cmp/config/publisher/CmpConfigPublisher";
import {CmpConfigPublisherCustomPurpose} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurpose";
import {CmpConfigPublisherCustomPurposeField} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurposeField";
import {CmpConfigPublisherLegalBasis} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherLegalBasis";
import {CmpConfigPublisherStandardPurpose} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherStandardPurpose";
import {CmpConfigPublisherStandardPurposeField} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherStandardPurposeField";
import {Purpose} from "../../../api/model/cmp/list/global-vendor-list/Purpose";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {sortItems} from "../../../common/utils/helper";
import {CmpPurposeType} from "../../../utils/cmp/CmpPurposeType";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import ModalEditCustomPurpose from "../../modal/cmp-configurations/ModalEditCustomPurpose";
import ModalAddStandardPurpose from "../../modal/cmp-configurations/ModalAddStandardPurpose";
import {Tag} from "../index";
import ModalAddCustomPurpose from "../../modal/cmp-configurations/ModalAddCustomPurpose";
import {CmpConfigPublisherField} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherField";

type CmpConfigurationPublisherPurposesProps = {
    publisherPurposes?: CmpConfigPublisher;
    onChangePublisherPurposes: (publisherPurposes: CmpConfigPublisher) => void;
};

const CmpConfigurationPublisherPurposes: FunctionComponent<CmpConfigurationPublisherPurposesProps> = ({publisherPurposes, onChangePublisherPurposes}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const alert = useAlert();

    const [isLoading, setLoading] = useState(true);
    const [purposes, setPurposes] = useState<Purpose[]>([]);
    const [customPurposes, setCustomPurposes] = useState<CmpConfigPublisherCustomPurpose[]>([]);
    const [standardPurposes, setStandardPurposes] = useState<CmpConfigPublisherStandardPurpose[]>([]);
    const [activeEditCustomPurpose, setActiveEditCustomPurpose] = useState<CmpConfigPublisherCustomPurpose>();
    const [isShowModalAddCustomPurpose, setShowModalAddCustomPurpose] = useState(false);
    const [isShowModalAddStandardPurpose, setShowModalAddStandardPurpose] = useState(false);

    const PURPOSE_1_ID = 1;

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const globalVendorList = await session.restCmpList.getGlobalVendorList();
                setPurposes(globalVendorList.purposes);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("purposes", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        setStandardPurposes(sortItems(publisherPurposes?.standardPurposes || [], CmpConfigPublisherStandardPurposeField.ID));
        setCustomPurposes(sortItems(publisherPurposes?.customPurposes || [], CmpConfigPublisherCustomPurposeField.ID));
    }, [publisherPurposes]);

    const handleChangePublisher = (field: CmpConfigPublisherField, value: any) => {
        onChangePublisherPurposes(new CmpConfigPublisher({...publisherPurposes, [field]: value}));
    };

    const handleChangeStandardPurposes = (standardPurposes: CmpConfigPublisherStandardPurpose[]) => {
        handleChangePublisher(CmpConfigPublisherField.STANDARD_PURPOSES, standardPurposes);
    };

    const handleChangeCustomPurposes = (customPurposes: CmpConfigPublisherCustomPurpose[]) => {
        handleChangePublisher(CmpConfigPublisherField.CUSTOM_PURPOSES, customPurposes);
    };

    const handleAddCustomPurpose = (purpose: CmpConfigPublisherCustomPurpose) => {
        setShowModalAddCustomPurpose(false);
        purpose.id = Math.max(...customPurposes.map((it) => it.id), 0) + 1;
        const newCustomPurposes = [...customPurposes, purpose];
        handleChangeCustomPurposes(newCustomPurposes);
    };

    const handleChangeCustomPurpose = (purpose: CmpConfigPublisherCustomPurpose) => {
        setActiveEditCustomPurpose(undefined);
        const newCustomPurposes = [...customPurposes].map((item) => item.id === purpose.id ? purpose : item);
        handleChangeCustomPurposes(newCustomPurposes);
    };

    const handleRemoveCustomPurpose = (purposeId: number) => {
        const newCustomPurposes = customPurposes.filter((purpose) => purpose.id !== purposeId);
        handleChangeCustomPurposes(newCustomPurposes);
    };

    const handleAddStandardPurpose = (standardPurpose: CmpConfigPublisherStandardPurpose) => {
        setShowModalAddStandardPurpose(false);
        const newStandardPurposes = [...standardPurposes, standardPurpose];
        handleChangeStandardPurposes(newStandardPurposes);
    };

    const handleChangeLegalBasis = (purposeId: number) => {
        const newStandardPurposes = [...standardPurposes];
        newStandardPurposes.forEach((purpose) => {
            if (purpose.id === purposeId) {
                if (purpose.legalBasis === CmpConfigPublisherLegalBasis.CONSENT) {
                    purpose.legalBasis = CmpConfigPublisherLegalBasis.LEGITIMATE_INTEREST;
                } else {
                    purpose.legalBasis = CmpConfigPublisherLegalBasis.CONSENT;
                }
            }
        });
        handleChangeStandardPurposes(newStandardPurposes);
    };

    const handleRemoveStandardPurpose = (purposeId: number) => {
        const newStandardPurposes = standardPurposes.filter((purpose) => purpose.id !== purposeId);
        handleChangeStandardPurposes(newStandardPurposes);
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{
                    title: {label: textCmpConfigurations("section.publisher_standard_purposes")},
                    actions: (
                        <ContentBlockAction
                            action={Action.ADD}
                            onClick={() => setShowModalAddStandardPurpose(true)}
                        />
                    )
                }}
            >
                <Table
                    columns={[
                        {width: 20, label: textCmpConfigurations(`purposes.${CmpConfigPublisherStandardPurposeField.ID}`)},
                        {width: 50, label: textCmpConfigurations("purposes.name")},
                        {width: 25, label: textCmpConfigurations(`purposes.${CmpConfigPublisherStandardPurposeField.LEGAL_BASIS}`)},
                        {width: 5}
                    ]}
                >
                    {standardPurposes.map((purpose) =>
                        <TableRow key={`${CmpPurposeType.PURPOSE}-${purpose.id}`}>
                            <TableColumn>
                                <Tag label={`${textCmpConfigurations(`purposes.${CmpPurposeType.PURPOSE}`)} ${purpose.id}`} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                            </TableColumn>
                            <TableColumn>{purposes.find((it) => it.id === purpose.id)?.name}</TableColumn>
                            <TableColumn>
                                <Tag label={textCmpConfigurations(`purposes.${purpose.legalBasis}`)} style={TagStyle.PRIMARY_GREY_LIGHT}/>
                            </TableColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                <ActionsMenu
                                    key={`action-${purpose.id}`}
                                    iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                    items={purpose.id !== PURPOSE_1_ID ?
                                        [
                                            {
                                                label: textCmpConfigurations(purpose.legalBasis === CmpConfigPublisherLegalBasis.CONSENT ? "purposes.action.use_legitimate_interest" : "purposes.action.use_consent"),
                                                onClick: () => handleChangeLegalBasis(purpose.id)
                                            },
                                            {label: textCommon(Action.REMOVE.labelKey), onClick: () => handleRemoveStandardPurpose(purpose.id), critical: true, separator: true}
                                        ] :
                                        [{label: textCommon(Action.REMOVE.labelKey), onClick: () => handleRemoveStandardPurpose(purpose.id), critical: true, separator: true}]
                                    }
                                />
                            </TableActionColumn>
                        </TableRow>
                    )}
                </Table>
                <ModalAddStandardPurpose
                    active={isShowModalAddStandardPurpose}
                    purposes={purposes.filter((purpose) => !standardPurposes.find((it) => it.id === purpose.id))}
                    onSubmit={handleAddStandardPurpose}
                    onClose={() => setShowModalAddStandardPurpose(false)}
                />
            </ContentBlock>
            <ContentBlock
                header={{
                    title: {label: textCmpConfigurations("section.publisher_custom_purposes")},
                    actions: (
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddCustomPurpose(true)}/>
                    )
                }}
            >
                <Table
                    columns={[
                        {width: 20, label: textCmpConfigurations(`purposes.${CmpConfigPublisherCustomPurposeField.ID}`)},
                        {width: 50, label: textCmpConfigurations(`purposes.${CmpConfigPublisherCustomPurposeField.NAME}`)},
                        {width: 25, label: textCmpConfigurations(`purposes.${CmpConfigPublisherCustomPurposeField.LEGAL_BASIS}`)},
                        {width: 5}
                    ]}
                >
                    {customPurposes.map((purpose) =>
                        <TableRow key={`${CmpPurposeType.CUSTOM_PURPOSE}-${purpose.id}`}>
                            <TableColumn>
                                <Tag label={`${textCmpConfigurations(`purposes.${CmpPurposeType.CUSTOM_PURPOSE}`)} ${purpose.id}`} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                            </TableColumn>
                            <TableColumn>{purpose.name}</TableColumn>
                            <TableColumn>
                                <Tag
                                    label={textCmpConfigurations(`purposes.${purpose.legalBasis}`)}
                                    style={TagStyle.PRIMARY_GREY_LIGHT}
                                />
                            </TableColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                <ActionsMenu
                                    key={`action-${purpose.id}`}
                                    iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                    items={[
                                        {label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveEditCustomPurpose(purpose)},
                                        {label: textCommon(Action.REMOVE.labelKey), onClick: () => handleRemoveCustomPurpose(purpose.id), critical: true, separator: true}
                                    ]}
                                />
                            </TableActionColumn>
                        </TableRow>
                    )}
                </Table>
                <ModalAddCustomPurpose
                    active={isShowModalAddCustomPurpose}
                    onSubmit={handleAddCustomPurpose}
                    onClose={() => setShowModalAddCustomPurpose(false)}
                />
                <ModalEditCustomPurpose
                    initPurpose={activeEditCustomPurpose}
                    onSubmit={handleChangeCustomPurpose}
                    onClose={() => setActiveEditCustomPurpose(undefined)}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default CmpConfigurationPublisherPurposes;
