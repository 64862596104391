import React, {FunctionComponent, useState} from "react";
import {Action, IconTooltip} from "@sirdata/ui-lib";
import copy from "copy-to-clipboard";

type ValueCopierProps = {
    value: string | number;
    tooltip: string;
};

const ValueCopier: FunctionComponent<ValueCopierProps> = ({value, tooltip}) => {
    const [isCopied, setCopied] = useState(false);

    const handleCopy = () => {
        copy(value.toString());
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
    };

    if (!value) return (<></>);

    return (
        <div className="value-copier">
            <span className="value-copier__value">{value}</span>
            <IconTooltip
                icon={isCopied ? Action.COPIED.icon : Action.COPY.icon}
                text={tooltip}
                cssClass="value-copier__icon"
                onClick={handleCopy}
            />
        </div>
    );
};

export default ValueCopier;
