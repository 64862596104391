import {Action, ActionsMenu, ContentBlock, ContentBlockAction, IconTooltip, Loadable, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {SearchResult} from "../../../api/interface/SearchResult";
import {Authorization} from "../../../api/model/account/Authorization";
import {PartnerLicenseLinkPartner} from "../../../api/model/partner/license/PartnerLicenseLinkPartner";
import {RestrictedContent} from "../../../common/component/widget";
import {Formatter} from "../../../common/utils/Formatter";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import ModalConfirmMessage from "../../modal/ModalConfirmMessage";
import ModalAddLicenseLinkPartner from "../../modal/licenses/ModalAddLicenseLinkPartner";
import ModalEditLicenseLinkPartner from "../../modal/licenses/ModalEditLicenseLinkPartner";
import ModalViewLicenseLinkPartner from "../../modal/licenses/ModalViewLicenseLinkPartner";
import {LabelPartner, LabelStatus} from "../index";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {PartnerLicenseLinkPartnerField} from "../../../api/model/partner/license/PartnerLicenseLinkPartnerField";

type LicensePartnersLinksProps = {
    licenseId: number;
};

const LicensePartnersLinks: FunctionComponent<LicensePartnersLinksProps> = ({licenseId}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textLicenses} = useTranslation(TranslationPortalFile.LICENSES);
    const [isLoading, setLoading] = useState(true);
    const [licenseLinks, setLicenseLinks] = useState<PartnerLicenseLinkPartner[]>([]);
    const [searchResult, setSearchResult] = useState<SearchResult<PartnerLicenseLinkPartner>>(new SearchResult(PartnerLicenseLinkPartner));
    const [activeViewPartnerLicense, setActiveViewPartnerLicense] = useState<PartnerLicenseLinkPartner>();
    const [activeEditPartnerLicense, setActiveEditPartnerLicense] = useState<PartnerLicenseLinkPartner>();
    const [activeDeletePartnerLicense, setActiveDeletePartnerLicense] = useState<PartnerLicenseLinkPartner>();
    const [isShowModalAddLicenseLinkPartner, setShowModalAddLicenseLinkPartner] = useState(false);

    const loadLicenseLinks = useCallback(async () => {
        if (!licenseId) return;
        setLoading(true);
        try {
            const licenseDetails = await session.restPartnerLicense.getDetails(licenseId);
            const licenseLinks: PartnerLicenseLinkPartner[] = [];
            licenseDetails.partner_licenses.forEach((value) => {
                value.license = licenseDetails.global_license;
                licenseLinks.push(new PartnerLicenseLinkPartner(value));
            });
            setLicenseLinks(licenseLinks);
            setSearchResult(SearchResult.buildFromList(PartnerLicenseLinkPartner, licenseLinks, 0, PAGE_SIZE));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("partners with this license", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [alert, licenseId]);

    useEffect(() => {
        (async () => {
            await loadLicenseLinks();
        })();
    }, [loadLicenseLinks]);

    const handleChangePage = (page: number) => {
        setSearchResult(SearchResult.buildFromList(PartnerLicenseLinkPartner, licenseLinks, page - 1, PAGE_SIZE));
    };

    const handleDeactivatePartnerLicense = async (link?: PartnerLicenseLinkPartner) => {
        if (!link) return;
        try {
            await session.restPartnerLicense.deactivate(link.license.id, link.partner_id);
            alert.updateWithSuccess("license");
            await loadLicenseLinks();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("license", e.message);
            }
        } finally {
            setActiveDeletePartnerLicense(undefined);
        }
    };

    if (!licenseId) return <></>;

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{
                    title: {label: textLicenses("section.partners")}, actions: (
                        <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                            <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddLicenseLinkPartner(true)}/>
                        </RestrictedContent>
                    )
                }}
            >
                <Table
                    columns={[
                        {width: 15, label: textLicenses("field.status"), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 40, label: textLicenses(`field.link_partner.${PartnerLicenseLinkPartnerField.PARTNER_ID}`)},
                        {width: 40, label: textLicenses(`field.link_partner.${PartnerLicenseLinkPartnerField.EXPIRES_AT}`)},
                        {width: 5}
                    ]}
                    pagination={searchResult.getPagination(handleChangePage)}
                >
                    {searchResult.elements.map((link) =>
                        <TableRow key={link.partner_id}>
                            <TableColumn>
                                <LabelStatus status={link.getStatus()}/>
                            </TableColumn>
                            <TableColumn>
                                <LabelPartner partner={link.partner_id} withLink/>
                            </TableColumn>
                            <TableColumn>
                                {link.activations[0]?.expires_at ? Formatter.formatUTCDate(link.activations[0].expires_at, Formatter.DATETIME_FORMAT) : "-"}
                            </TableColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                <RestrictedContent allowedTo={Authorization.LICENSES.update}>
                                    <IconTooltip
                                        icon={{name: "visibility"}}
                                        text={textLicenses("actions.view_details")}
                                        onClick={() => setActiveViewPartnerLicense(link)}
                                    />
                                </RestrictedContent>
                                <RestrictedContent allowedTo={Authorization.LICENSES.update}>
                                    <ActionsMenu
                                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                        items={
                                            link.active ?
                                                ([
                                                    {label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveEditPartnerLicense(link)},
                                                    {label: textCommon(Action.DEACTIVATE.labelKey), critical: true, separator: true, onClick: () => setActiveDeletePartnerLicense(link)}
                                                ]) :
                                                ([
                                                    {label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveEditPartnerLicense(link)}
                                                ])}
                                    />
                                </RestrictedContent>
                            </TableActionColumn>
                        </TableRow>
                    )}
                </Table>
                <ModalAddLicenseLinkPartner
                    active={isShowModalAddLicenseLinkPartner}
                    licenseId={licenseId}
                    onClose={(refresh) => {
                        setShowModalAddLicenseLinkPartner(false);
                        refresh && loadLicenseLinks();
                    }}
                />
                <ModalViewLicenseLinkPartner
                    link={activeViewPartnerLicense}
                    onClose={() => setActiveViewPartnerLicense(undefined)}
                />
                <ModalEditLicenseLinkPartner
                    initLink={activeEditPartnerLicense}
                    onClose={(refresh) => {
                        setActiveEditPartnerLicense(undefined);
                        refresh && loadLicenseLinks();
                    }}
                />
                <ModalConfirmMessage
                    active={!!activeDeletePartnerLicense}
                    message={textLicenses("message.confirm_deactivate_license")}
                    confirm={() => handleDeactivatePartnerLicense(activeDeletePartnerLicense)}
                    confirmAction={Action.DEACTIVATE.labelKey}
                    cancel={() => setActiveDeletePartnerLicense(undefined)}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default LicensePartnersLinks;
