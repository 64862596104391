import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {Status} from "../../../../utils/Status";
import {SearchQuery} from "../../../interface/SearchQuery";
import {SegmentLinkExternalField} from "../segment/SegmentLinkExternalField";

export enum DistributionSegmentsSearchParamsField {
    DATA_TYPES = "data_types",
}

export class DistributionSegmentsSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    status: string = Status.OPEN.name;
    data_types: string[] = [];

    withSearchParams(params: SearchParams): DistributionSegmentsSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(SegmentLinkExternalField.LAST_UPDATE, true) as DistributionSegmentsSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS) || Status.OPEN.name;
        newQuery.data_types = params.getString(DistributionSegmentsSearchParamsField.DATA_TYPES)?.split(",") || [];
        return newQuery;
    }
}
