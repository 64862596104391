import {ApiModel} from "../../../../common/api/model/ApiModel";
import {ContentElement} from "../../../interface/ContentElement";

export class PartnerOrganizationEmailDomain extends ApiModel {
    domain: string = "";
    reserved: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            domain: this.domain,
            reserved: this.reserved
        };
    }

    toContentElement(): ContentElement {
        return new ContentElement("", this.domain);
    }
}
