import {PAGE_SIZE} from "../../../utils/constants";
import {SearchParams} from "../../../utils/SearchParams";
import {SearchParamsField} from "../../../utils/SearchParamsField";
import {SearchQuery} from "../../interface/SearchQuery";
import {Status} from "../../../utils/Status";

export class DealSearchQuery extends SearchQuery {
    per_page: number = PAGE_SIZE;
    status?: string;
    private: boolean | undefined;
    partner_id: number | undefined;
    platform_names: string | undefined; // comma separated
    target_origin_types: string | undefined; // comma separated
    device_types: string | undefined; // comma separated
    inventory_types: string | undefined; // comma separated
    countries: string | undefined; // comma separated
    tiers1: string | undefined; // comma separated
    tiers2: string | undefined; // comma separated

    withSearchParams(params: SearchParams): DealSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting("contextual_relevancy,viewability", true) as DealSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            q: this.query,
            sort_by: this.sortBy ? `${this.sortBy}:${this.reverseOrder ? "desc" : "asc"}` : undefined,
            page: this.page + 1,
            per_page: this.per_page,
            active: !!this.status ? `${this.status === Status.ACTIVE.name}` : undefined,
            private: this.private,
            partner_id: this.partner_id,
            platform_names: this.platform_names,
            target_origin_types: this.target_origin_types,
            device_types: this.device_types,
            inventory_types: this.inventory_types,
            countries: this.countries,
            tiers1: this.tiers1,
            tiers2: this.tiers2
        };
    }

}
