import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CategoryGroupLinkKeywordSource} from "./CategoryGroupLinkKeywordSource";

export class CategoryGroupLinkKeyword extends ApiModel {
    id: number = 0;
    id_group_category: number = 0;
    keyword: string = "";
    id_owner?: number;
    description: string = "";
    source: CategoryGroupLinkKeywordSource = CategoryGroupLinkKeywordSource.DIRECT;
    threshold: number = CategoryGroupLinkKeyword.DEFAULT_THRESHOLD;
    last_update: string = "";

    static DEFAULT_THRESHOLD = 500;
    static MIN_THRESHOLD = 0;
    static MAX_THRESHOLD = 1000;
    static THRESHOLD_STEP = 100;

    constructor(o?: {}) {
        super();
        this.load(o);
    }
}
