import {Box, BoxRadius, BoxSpacing, FieldBlock, FlexContentSpacing, FormLayoutColumns, FormLayoutRows, IconTooltip, ToggleSwitch} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {ApiAuthorization} from "../../../../api/model/auth/ApiAuthorization";
import {ApiService} from "../../../../api/model/ApiService";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../../utils/constants";
import {capitalize} from "../../../../common/utils/string";

type UserProfileAuthorizationsProps = {
    authorizations: ApiAuthorization[];
    selectedAuthorizations: string[];
    onChange: (value: string) => void;
};

const UserProfileAuthorizations: FunctionComponent<UserProfileAuthorizationsProps> = ({authorizations, selectedAuthorizations, onChange}) => {
    const {t: textAuthorizations} = useTranslation(TranslationPortalFile.AUTHORIZATIONS);

    const getAuthorizationGroups = (service: ApiService): ApiAuthorization[] => {
        return authorizations
            .filter((authorization) => authorization.service === service.name && authorization.shortName.indexOf(".") === -1);
    };

    const buildAuthorizationItems = (authorization: ApiAuthorization): ApiAuthorization[] => {
        return [
            authorization,
            ...authorizations.filter((it) => it.name.startsWith(`${authorization.name}.`) && !it.name.endsWith(ApiAuthorization.UPDATE_SUFFIX))
        ];
    };

    const renderToggle = (item: ApiAuthorization | undefined, label?: string) => {
        if (!item) return <></>;

        if (!label) {
            label = item.shortName.split(".").pop() || "";
            label = label.toLowerCase().replaceAll("_", " ");
        }

        return (
            <ToggleSwitch
                name={item.name}
                label={capitalize(label)}
                checked={selectedAuthorizations.includes(item.name)}
                onChange={() => onChange(item.name)}
            />
        );
    };

    return (
        <div className="user-profiles__groups">
            {ApiAuthorization.userServices().map((service) => getAuthorizationGroups(service)
                .map((group) =>
                    <Box key={group.name} spacing={BoxSpacing.MEDIUM} radius={BoxRadius.MD}>
                        <FieldBlock
                            label={group.shortName.replaceAll("_", " ")}
                            actions={<IconTooltip icon={{name: "info"}} text={group.name}/>}
                        >
                            <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                {buildAuthorizationItems(group).map((item) =>
                                    <FormLayoutColumns key={item.name}>
                                        {renderToggle(item, item.name === group.name ? textAuthorizations("label.access") : undefined)}
                                        {renderToggle(authorizations.find((it) => it.name === `${item.name}${ApiAuthorization.UPDATE_SUFFIX}`))}
                                    </FormLayoutColumns>
                                )}
                            </FormLayoutRows>
                        </FieldBlock>
                    </Box>
                ))}
        </div>
    );
};

export default UserProfileAuthorizations;
