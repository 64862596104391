export class PartnerOrganizationType {
    static ADTECH: PartnerOrganizationType = new PartnerOrganizationType("ADTECH");
    static ADVERTISER: PartnerOrganizationType = new PartnerOrganizationType("ADVERTISER");
    static AGENCY: PartnerOrganizationType = new PartnerOrganizationType("AGENCY");
    static DATA_PROVIDER: PartnerOrganizationType = new PartnerOrganizationType("DATA_PROVIDER");
    static PUBLISHER: PartnerOrganizationType = new PartnerOrganizationType("PUBLISHER");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): PartnerOrganizationType[] {
        return [
            PartnerOrganizationType.ADTECH,
            PartnerOrganizationType.ADVERTISER,
            PartnerOrganizationType.AGENCY,
            PartnerOrganizationType.DATA_PROVIDER,
            PartnerOrganizationType.PUBLISHER
        ];
    }

    static getByName(name: string): PartnerOrganizationType | undefined {
        return this.values().find((it) => it.name === name);
    }
}
