import {Action, ActionsMenu, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {PartnerDomain} from "../../../../api/model/partner/domain/PartnerDomain";
import {RestrictedContent} from "../../../../common/component/widget";
import {LabelPartner, LabelService, LabelStatus} from "../../index";

type PartnerDomainTableRowProps = {
    domain: PartnerDomain;
    onClick: () => void;
    onDelete: () => void;
};

const PartnerDomainRow: FunctionComponent<PartnerDomainTableRowProps> = ({domain, onClick, onDelete}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <TableRow onClick={onClick}>
            <TableColumn><LabelStatus status={domain.getStatus()}/></TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{domain.id}</TableColumn>
            <TableColumn>{domain.domain_name}</TableColumn>
            <TableColumn>
                {domain.target_service ? <LabelService service={domain.target_service}/> : ""}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}><LabelPartner partner={domain.id_partner}/></TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <RestrictedContent allowedTo={Authorization.PARTNER_DOMAINS.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={[{label: textCommon(Action.DELETE.labelKey), onClick: onDelete, critical: true, separator: true}]}
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default PartnerDomainRow;
