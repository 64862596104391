import React, {FunctionComponent} from "react";
import {User} from "../../../api/model/user/User";

export type LabelUserProps = {
    user: User | string | number | undefined;
};

const LabelUser: FunctionComponent<LabelUserProps> = ({user}) => {
    if (!user) return <>-</>;

    return (
        <span>
            {typeof user === "string" || typeof user === "number" ?
                user :
                user.fullName
            }
        </span>
    );
};

export default LabelUser;
