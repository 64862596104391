import {Action, ActionsMenu, Color, IconTooltip, InputText, TableActionColumn, TableColumn, TableColumnStyle, TableImage, TableRow, ToggleSwitch, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../../api/ApiSession";
import {Authorization} from "../../../../api/model/account/Authorization";
import {AudiencePlatform} from "../../../../api/model/audience/AudiencePlatform";
import {Distribution} from "../../../../api/model/audience/distribution/Distribution";
import {SegmentLinkExternal} from "../../../../api/model/audience/segment/SegmentLinkExternal";
import {SegmentLinkExternalField} from "../../../../api/model/audience/segment/SegmentLinkExternalField";
import {RestrictedContent} from "../../../../common/component/widget";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelStatus} from "../../index";

type SegmentLinkExternalRowProps = {
    link: SegmentLinkExternal;
    onChangeStatus: (link: SegmentLinkExternal) => void;
    onChangeValue: (link: SegmentLinkExternal) => void;
    onRemove: () => void;
};

const SegmentLinkExternalRow: FunctionComponent<SegmentLinkExternalRowProps> = ({link, onChangeStatus, onChangeValue, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textDistributions} = useTranslation(TranslationPortalFile.DISTRIBUTIONS);
    const [distribution, setDistribution] = useState<Distribution>(new Distribution());

    useEffect(() => {
        (async () => {
            try {
                setDistribution(await session.restDistribution.get(link.map_name));
            } catch (e) {
            }
        })();
    }, [link.map_name]);

    const buildPlatform = () => {
        const platform = AudiencePlatform.getByName(link.map_name);
        if (platform) {
            return (
                <>
                    <TableImage image={platform.logo}/>
                    {platform.tooltip && <IconTooltip icon={{name: "help", outlined: true, colorIcon: Color.OCEAN}} text={platform.tooltip}/>}
                </>
            );
        }
        return (<span>{link.map_name}</span>);
    };

    return (
        <TableRow>
            <TableColumn>
                <LabelStatus status={link.getStatus()}/>
            </TableColumn>
            <TableColumn>
                {buildPlatform()}
                {!!distribution.properties.documentation_url &&
                    <IconTooltip
                        icon={{name: "description", outlined: true, colorIcon: Color.OCEAN}}
                        text={textDistributions("action.documentation")}
                        onClick={() => window.open(distribution.properties.documentation_url, "_blank")}
                    />
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.NO_VERTICAL_PADDING}>
                <InputText
                    value={link.value}
                    onChange={(value) => onChangeValue(new SegmentLinkExternal({...link, [SegmentLinkExternalField.VALUE]: value}))}
                    small
                />
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <ToggleSwitch
                    checked={link.active}
                    onChange={(value) => onChangeStatus(new SegmentLinkExternal({...link, [SegmentLinkExternalField.ACTIVE]: value}))}
                />
                <RestrictedContent allowedTo={Authorization.SEGMENTS.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={[
                            {label: textCommon(Action.REMOVE.labelKey), onClick: onRemove, critical: true, separator: true}
                        ]}
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default SegmentLinkExternalRow;
