import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Action, ActionsMenu, Color, FlexContent, FlexContentDirection, FlexContentSpacing, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {SirdataListVendor} from "../../../../api/model/cmp/list/sirdata-list/SirdataListVendor";
import {SirdataListVendorField} from "../../../../api/model/cmp/list/sirdata-list/SirdataListVendorField";
import {SirdataListEntityState} from "../../../../api/model/cmp/list/sirdata-list/SirdataListEntityState";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Formatter} from "../../../../common/utils/Formatter";
import {RestrictedContent} from "../../../../common/component/widget";
import {Authorization} from "../../../../api/model/account/Authorization";
import {CmpSirdataListEntityState, CmpSirdataListPurposesList} from "../../index";

type CmpSirdataListVendorRowProps = {
    vendor: SirdataListVendor;
    latestDeletedDate?: string;
    state?: SirdataListEntityState;
    onEdit?: () => void;
    onDelete?: () => void;
    onRestore?: () => void;
};

const CmpSirdataListVendorRow: FunctionComponent<CmpSirdataListVendorRowProps> = ({vendor, latestDeletedDate, state, onEdit, onDelete, onRestore}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);

    return (
        <TableRow>
            <TableColumn>
                {vendor.id}
                <CmpSirdataListEntityState state={state}/>
            </TableColumn>
            <TableColumn>
                {vendor.name}
                {vendor.premium &&
                    <IconTooltip
                        icon={{name: "card_membership", outlined: true, colorIcon: Color.GREEN}}
                        text={textCmpSirdataList(`field.vendor.${SirdataListVendorField.PREMIUM}`)}
                    />
                }
                {latestDeletedDate &&
                    <IconTooltip
                        icon={{name: "block", colorIcon: Color.RED}}
                        text={textCmpSirdataList("tooltip.vendor_deleted_on_date", {date: Formatter.formatUTCDate(latestDeletedDate, Formatter.DATETIME_FORMAT)})}
                    />
                }
            </TableColumn>
            <TableColumn>
                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                    <CmpSirdataListPurposesList title={textCmpSirdataList("consent")} values={vendor.purposes}/>
                    <CmpSirdataListPurposesList title={textCmpSirdataList("legitimate_interest")} values={vendor.legIntPurposes}/>
                </FlexContent>
            </TableColumn>
            <TableColumn>
                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                    <CmpSirdataListPurposesList title={textCmpSirdataList("consent")} values={vendor.extraPurposes}/>
                    <CmpSirdataListPurposesList title={textCmpSirdataList("legitimate_interest")} values={vendor.legIntExtraPurposes}/>
                </FlexContent>
            </TableColumn>
            {onEdit &&
                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                    {!vendor.deletedDate &&
                        <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                            <IconTooltip
                                icon={Action.EDIT.icon}
                                text={textCommon(Action.EDIT.labelKey)}
                                onClick={onEdit}
                            />
                        </RestrictedContent>
                    }
                    <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                        <ActionsMenu
                            iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                            items={[vendor.deletedDate ?
                                {label: textCmpSirdataList("action.restore"), onClick: () => onRestore && onRestore()} :
                                {label: textCommon(Action.DELETE.labelKey), onClick: () => onDelete && onDelete(), critical: true}
                            ]}
                        />
                    </RestrictedContent>
                </TableActionColumn>
            }
        </TableRow>
    );
};

export default CmpSirdataListVendorRow;
