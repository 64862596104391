import {Action, ActionsMenu, Color, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {ApiAuthorization} from "../../../../api/model/auth/ApiAuthorization";
import {RestrictedContent} from "../../../../common/component/widget";
import {AuthorizationType} from "../../../../screen/authorizations/AuthorizationsPartner";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelService} from "../../index";
import {ApiAuthorizationField} from "../../../../api/model/auth/ApiAuthorizationField";

type ApiAuthorizationRowProps = {
    type: AuthorizationType;
    authorization: ApiAuthorization;
    onShowDetails: (authorization: ApiAuthorization) => void;
    onDelete: (authorization: ApiAuthorization) => void;
}

const ApiAuthorizationRow: FunctionComponent<ApiAuthorizationRowProps> = ({type, authorization, onShowDetails, onDelete}) => {
    const {t: textAuthorizations} = useTranslation(TranslationPortalFile.AUTHORIZATIONS);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <TableRow>
            <TableColumn>
                <LabelService service={authorization.service}/>
            </TableColumn>
            <TableColumn>
                {authorization.name}
                {authorization.on_service_activation &&
                    <IconTooltip
                        icon={{name: "verified", colorIcon: Color.GREEN}}
                        text={textAuthorizations(`field.${ApiAuthorizationField.ON_SERVICE_ACTIVATION}`)}
                    />
                }
            </TableColumn>
            <TableColumn>
                {authorization.label}
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <IconTooltip
                    icon={{name: "group"}}
                    text={type === AuthorizationType.PARTNER ? textAuthorizations("actions.see_partners_with_authorization") : textAuthorizations("actions.see_users_with_authorization")}
                    onClick={() => onShowDetails(authorization)}
                />
                <RestrictedContent allowedTo={Authorization.AUTHORIZATIONS.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={[{label: textCommon(Action.DELETE.labelKey), onClick: () => onDelete(authorization), critical: true, separator: true}]}
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default ApiAuthorizationRow;
