import {CategorizerConfig} from "./CategorizerConfig";

export class CategorizerConfigScraperApi extends CategorizerConfig {
    premium: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            premium: this.premium
        };
    }
}
