import {ApiModel} from "../../../common/api/model/ApiModel";

export class ApiService extends ApiModel {
    name: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    isSirdataService(): boolean {
        return this.name.startsWith("SIRDATA.");
    }
}
