import {Model} from "../../../../common/api/interface/Model";
import {ApiModel} from "../../../../common/api/model/ApiModel";

export class GoogleAuthParams extends ApiModel implements Model {
    code: string = "";
    redirect_uri?: string;
    partner_id: string = "";
    partner_account_id: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            code: this.code,
            redirect_uri: this.redirect_uri,
            partner_id: this.partner_id,
            partner_account_id: this.partner_account_id
        };
    }
}
