import {ApiModel} from "../../../common/api/model/ApiModel";
import {MediaType} from "./MediaType";

export class MediaCreateRequest extends ApiModel {
    file: File | undefined = undefined;
    name: string = "";
    type: string = MediaType.IMAGE.name;
    mime_type: string = "";
    private _title: string | undefined = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get title(): string {
        return this._title ? this._title : "";
    }

    set title(title: string) {
        this._title = !!title ? title : undefined;
    }

    getFormData(): FormData | undefined {
        if (!!this.file) {
            const formData = new FormData();
            formData.append("file", this.file, this.name);
            formData.append("type", this.type);
            formData.append("mime_type", this.mime_type);
            formData.append("title", this.title);
            return formData;
        }
    }
}
