import {RevenueSeat} from "./RevenueSeat";
import {SearchResult} from "../../../interface/SearchResult";

export class RevenueSeatSearchResult extends SearchResult<RevenueSeat> {
    count: number = 0;
    seats: RevenueSeat[] = [];

    constructor(o?: {}) {
        super(RevenueSeat, o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "seats") {
                    (o[key] as RevenueSeat[]).forEach((obj) => {
                        const it = new RevenueSeat(obj);
                        this.seats.push(it);
                    });
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    get total_elements(): number {
        return this.count;
    }

    get elements(): RevenueSeat[] {
        return this.seats;
    }
}
