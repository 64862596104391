import {ApiModel} from "../../../../common/api/model/ApiModel";
import {HelperPrivacyConfigurationUtmType} from "./HelperPrivacyConfigurationUtmType";

export class HelperPrivacyConfigurationUtm extends ApiModel {
    campaign: HelperPrivacyConfigurationUtmType = HelperPrivacyConfigurationUtmType.NO_ALTER;
    content: HelperPrivacyConfigurationUtmType = HelperPrivacyConfigurationUtmType.NO_ALTER;
    medium: HelperPrivacyConfigurationUtmType = HelperPrivacyConfigurationUtmType.NO_ALTER;
    source: HelperPrivacyConfigurationUtmType = HelperPrivacyConfigurationUtmType.NO_ALTER;
    term: HelperPrivacyConfigurationUtmType = HelperPrivacyConfigurationUtmType.NO_ALTER;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            campaign: this.campaign,
            content: this.content,
            medium: this.medium,
            source: this.source,
            term: this.term
        };
    }
}

