import {Rest} from "../../common/api/rest/Rest";
import {DocItem} from "../model/api-documentation/DocItem";

export class RestDocs extends Rest {
    rootPath = "/console-api/docs";

    list(): Promise<DocItem[]> {
        return this._client.get(`${this.rootPath}`, DocItem) as Promise<DocItem[]>;
    };
}
