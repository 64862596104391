import {Rest} from "../../common/api/rest/Rest";
import {CmpVersion} from "../model/cmp/version/CmpVersion";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";

export class RestCmpVersion extends Rest implements RestService<CmpVersion> {
    rootPath = "/console-api/cmp/version";

    search(params: any): Promise<SearchResult<CmpVersion>> {
        return new Promise<SearchResult<CmpVersion>>(() => {}); // TODO Implement route
    }

    list(): Promise<CmpVersion[]> {
        return this._client.get(`${this.rootPath}`, CmpVersion) as Promise<CmpVersion[]>;
    }

    create(cmpVersion: CmpVersion): Promise<CmpVersion> {
        return this._client.post(`${this.rootPath}`, cmpVersion, CmpVersion) as Promise<CmpVersion>;
    }

    get(version: string): Promise<CmpVersion> {
        return this._client.get(`${this.rootPath}/${version}`, CmpVersion) as Promise<CmpVersion>;
    }

    update(cmpVersion: CmpVersion): Promise<CmpVersion> {
        return this._client.put(`${this.rootPath}/${cmpVersion.version}`, cmpVersion, CmpVersion) as Promise<CmpVersion>;
    }

    delete(version: string) {
        // TODO Implement route
    }
}
