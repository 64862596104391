export enum CmpConfigSettingsField {
    LOGO = "logo",
    LOGO_DARK = "logoDark",
    PRIVACY_POLICY = "privacyPolicy",
    LANGUAGE = "language",
    DEFAULT_LANGUAGE = "defaultLanguage",
    SCOPE = "scope",
    TCSTRING_LOCATION = "tcStringLocation",
    COOKIE_DOMAIN = "cookieDomain",
    DOMAIN_LIST = "domainList",
    COOKIE_MAX_AGE_IN_DAYS = "cookieMaxAgeInDays",
    CAPPING = "capping",
    CAPPING_IN_DAYS = "cappingInDays",
    FORCE_GDPR = "forceGdpr",
    TEXTS = "texts",
    THEME = "theme",
    VENDORLIST = "vendorList",
    PUBLISHER_RESTRICTIONS = "publisherRestrictions",
    PUBLISHER_PURPOSES = "publisherPurposes",
    COOKIEWALL = "cookieWall",
    DISABLE_TCF = "disableTcf",
    FLEXIBLE_REFUSAL = "flexibleRefusal",
    CCPA = "ccpa",
    EXTERNAL = "external"
}
