import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {DealRecommendation} from "../../../../api/model/deal/DealRecommendation";
import {TranslationPortalFile} from "../../../../utils/constants";
import {FieldBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, FormLayoutRows, FormLayoutTitle, InputTextNumber} from "@sirdata/ui-lib";
import {DealRecommendationField} from "../../../../api/model/deal/DealRecommendationField";
import {Currency} from "../../../../api/model/currency/Currency";

type DealPlatformRecommendationRowProps = {
    recommendation: DealRecommendation;
    onChange: (field: DealRecommendationField, value: any) => void;
}

const DealPlatformRecommendationRow: FunctionComponent<DealPlatformRecommendationRowProps> = ({recommendation, onChange}) => {
    const {t: textDeals} = useTranslation(TranslationPortalFile.DEALS);

    return (
        <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
            <FormLayoutTitle>
                {textDeals(`recommendation_format_type.${recommendation.format_type}`)}
            </FormLayoutTitle>
            <FieldBlock label={textDeals("field.impressions")}>
                <InputTextNumber
                    value={recommendation.impression}
                    onChange={(value) => onChange(DealRecommendationField.IMPRESSION, value)}
                />
            </FieldBlock>
            <FieldBlock label={textDeals("field.cpm")} content={{direction: FlexContentDirection.COLUMN}}>
                <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                    <InputTextNumber
                        value={recommendation.cpm_eur}
                        onChange={(value) => onChange(DealRecommendationField.CPM_EUR, value)}
                    />
                    <span>{textDeals("currency_cents", {currency: Currency.EUR.currency})}</span>
                </FlexContent>
                <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                    <InputTextNumber
                        value={recommendation.cpm_usd}
                        onChange={(value) => onChange(DealRecommendationField.CPM_USD, value)}
                    />
                    <span>{textDeals("currency_cents", {currency: Currency.USD.currency})}</span>
                </FlexContent>
            </FieldBlock>
        </FormLayoutRows>
    );
};

export default DealPlatformRecommendationRow;
