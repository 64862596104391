import {ApiModel} from "../../../common/api/model/ApiModel";
import {NotificationType} from "./NotificationType";

export class NotificationContent extends ApiModel {
    title: string = "";
    subtitle: string = "";
    text: string = "";
    image_id: number | undefined;
    url: string = "";
    call_to_action: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    filterByType(type: NotificationType) {
        switch (type) {
            case NotificationType.MODAL:
                return {
                    title: this.title,
                    subtitle: this.subtitle,
                    text: this.text,
                    image_id: this.image_id,
                    url: this.url,
                    call_to_action: this.call_to_action
                };
            case NotificationType.SLIDER:
                return {
                    title: this.title,
                    subtitle: this.subtitle,
                    text: this.text,
                    image_id: this.image_id,
                    url: this.url
                };
            default:
                return {
                    text: this.text,
                    url: this.url
                };
        }
    }
}
