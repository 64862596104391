import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CmpLocaleConfigItem} from "./CmpLocaleConfigItem";

export class CmpLocaleConfig extends ApiModel {
    _keys: Map<string, CmpLocaleConfigItem> = new Map();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get keys(): Map<string, CmpLocaleConfigItem> {
        return this._keys;
    }

    set keys(keys: Map<string, CmpLocaleConfigItem>) {
        const entries = Object.entries<CmpLocaleConfigItem>(keys as {});
        if (entries.length) {
            const map = new Map<string, CmpLocaleConfigItem>();
            entries.forEach(([key, value]) => {
                map.set(key, new CmpLocaleConfigItem(value.tags, value.long_text));
            });
            this._keys = map;
        }
    }

    getItemByKey(key: string): CmpLocaleConfigItem {
        const item = this.keys.get(key);
        return new CmpLocaleConfigItem(item?.tags, item?.long_text);
    }
}
