import moment from "moment";
import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Locale} from "../../../../common/utils/Locale";
import {CmpWebinarInfo} from "./CmpWebinarInfo";
import {CmpWebinarFaqItem} from "./CmpWebinarFaqItem";
import {ContentElement} from "../../../interface/ContentElement";
import {Status} from "../../../../utils/Status";
import {Module} from "../../../../utils/Module";
import {Formatter} from "../../../../common/utils/Formatter";

export class CmpWebinar extends ApiModel {
    id: number = 0;
    date: string = Formatter.formatDate(new Date(), Formatter.API_DATE_FORMAT);
    _info: Map<Locale, CmpWebinarInfo> | undefined;
    _faq: Map<Locale, CmpWebinarFaqItem[]> | undefined;
    status: string = Status.INACTIVE.name;
    last_update: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get info(): Map<Locale, CmpWebinarInfo> {
        return this._info || new Map<Locale, CmpWebinarInfo>();
    }

    set info(info: Map<Locale, CmpWebinarInfo> | undefined) {
        if (!info) {
            return;
        }

        this._info = Object.entries(info).length ? new Map(Object.entries(info)) : info;
    }

    get faq(): Map<Locale, CmpWebinarFaqItem[]> {
        const faq = new Map<Locale, CmpWebinarFaqItem[]>();
        this._faq?.forEach((values, key) => {
            faq.set(key, values.map((it) => new CmpWebinarFaqItem(it)));
        });
        return faq;
    }

    set faq(faq: Map<Locale, CmpWebinarFaqItem[]> | undefined) {
        if (!faq) {
            return;
        }

        this._faq = Object.entries(faq).length ? new Map(Object.entries(faq)) : faq;
    }

    getInfoForLanguage(locale: Locale): CmpWebinarInfo {
        return new CmpWebinarInfo(this._info?.get(locale));
    }

    getFaqForLanguage(locale: Locale): CmpWebinarFaqItem[] {
        return this._faq?.get(locale) || [];
    }

    getRoute(): string {
        return Module.CMP_WEBINARS.buildRoute(this.id);
    }

    getMapJson(map?: Map<Locale, object>): { [key: string]: object } | undefined {
        if (!map) return undefined;
        let t: { [key: string]: object } = {};
        map.forEach((value, key) => {
            t[key as string] = value;
        });
        return t;
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            date: moment(this.date).format(),
            info: this.getMapJson(this._info),
            faq: this.getMapJson(this._faq),
            status: this.status
        };
    }

    getStatus(): Status {
        if (this.status === Status.ACTIVE.name) {
            return Status.ACTIVE;
        } else if (this.status === Status.INACTIVE.name) {
            return Status.INACTIVE;
        } else {
            return Status.DELETED;
        }
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.getInfoForLanguage(Locale.FRENCH)?.title);
        element.status = this.getStatus();
        element.createdAt = new Date(Formatter.convertDateToUTC(this.date));
        element.lastUpdated = new Date(this.last_update);
        return element;
    }
}
