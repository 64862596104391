import {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, BoxRadius, ButtonLinkCancel, ButtonValidate, Checkbox, ContentBlock, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Paragraph} from "@sirdata/ui-lib";
import {session} from "../../../api/ApiSession";
import {CmpCloudflareEndpoint} from "../../../api/model/cmp/cloudflare/CmpCloudflareEndpoint";
import {CmpCloudflareRequest} from "../../../api/model/cmp/cloudflare/CmpCloudflareRequest";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {usePreprod} from "../../../common/utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";

export type ModalFlushCmpConfigurationsProps = {
    active: boolean;
    onClose: () => void;
};

const ModalFlushCmpConfigurations: FunctionComponent<ModalFlushCmpConfigurationsProps> = ({active, onClose}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [isSubmitting, setSubmitting] = useState(false);
    const alert = useAlert();

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            const request = new CmpCloudflareRequest();
            request.global_purge = true;
            await session.restCmpCloudflare.flush(request);
            alert.actionWithSuccess("cache flushed");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("flush cache", e.message);
            }
        } finally {
            setSubmitting(false);
            onClose();
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpConfigurations("modal.flush_cmp_configurations.title")}/>
            </ModalHeader>
            <ModalContent>
                <ContentBlock>
                    <Paragraph>{textCmpConfigurations("modal.flush_cmp_configurations.description")}</Paragraph>
                    <Box radius={BoxRadius.MD}>
                        {CmpCloudflareEndpoint.values().map((endpoint) =>
                            <Checkbox
                                key={endpoint.name}
                                name={endpoint.name}
                                label={endpoint.getUrl(usePreprod)}
                                checked
                                onChange={() => {}}
                                disabled
                            />
                        )}
                    </Box>
                </ContentBlock>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate onClick={handleSubmit} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalFlushCmpConfigurations;
