import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select, Textarea} from "@sirdata/ui-lib";
import {TaxonomyTag} from "../../../api/model/taxonomy/tag/TaxonomyTag";
import {TaxonomyTagField} from "../../../api/model/taxonomy/tag/TaxonomyTagField";
import {TAXONOMY_TAG_PURPOSES, TaxonomyTagPurpose} from "../../../api/model/taxonomy/tag/TaxonomyTagPurpose";
import {TranslationPortalFile} from "../../../utils/constants";
import {Language} from "../../../common/api/model/Language";
import {TranslationCommonFile} from "../../../common/utils/constants";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {useNavigate} from "react-router-dom";

type ModalCreateTaxonomyTagProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateTaxonomyTag: FunctionComponent<ModalCreateTaxonomyTagProps> = ({active, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textTags} = useTranslation(TranslationPortalFile.TAXONOMY_TAGS);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const [tag, setTag] = useState<TaxonomyTag>(new TaxonomyTag());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-taxonomy-tag";

    useEffect(() => {
        if (active) {
            setTag(new TaxonomyTag());
        }
    }, [active]);

    const handleChange = (field: TaxonomyTagField, value: any) => {
        setTag((prevState) => new TaxonomyTag({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const newTag = await session.restTaxonomyTag.create(tag);
            navigate(newTag.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("tag", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textTags("modal.create_tag")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns>
                            <FieldBlock label={textTags(`field.${TaxonomyTagField.NAME}`)} required>
                                <InputText
                                    value={tag.name}
                                    onChange={(value) => handleChange(TaxonomyTagField.NAME, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textTags(`field.${TaxonomyTagField.LANGUAGE}`)} required>
                                <Select
                                    value={tag.language}
                                    options={Language.values().map((language) => ({label: textMain(`language.${language.name}`), value: language.name}))}
                                    onChange={(option) => handleChange(TaxonomyTagField.LANGUAGE, option?.value || "")}
                                />
                            </FieldBlock>
                            <FieldBlock label={textTags(`field.${TaxonomyTagField.PURPOSE}`)} required>
                                <Select
                                    value={tag.purpose}
                                    options={TAXONOMY_TAG_PURPOSES.map((purpose) => ({label: textTags(`purpose.${purpose}`), value: purpose}))}
                                    onChange={(option) => handleChange(TaxonomyTagField.PURPOSE, option?.value as TaxonomyTagPurpose)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textTags(`field.${TaxonomyTagField.DESCRIPTION}`)}>
                            <Textarea
                                value={tag.description}
                                rows={3}
                                onChange={(value) => handleChange(TaxonomyTagField.DESCRIPTION, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateTaxonomyTag;
