import {ButtonLinkCancel, ButtonValidate, FlexContentSpacing, FormLayoutRows, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Distribution} from "../../../api/model/audience/distribution/Distribution";
import {SegmentLinkExternal} from "../../../api/model/audience/segment/SegmentLinkExternal";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {sortItems} from "../../../common/utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SegmentLinkExternalRow} from "../../snippet";

export type ModalAddSegmentLinksExternalProps = {
    active: boolean;
    segmentId: number;
    initLink?: SegmentLinkExternal;
    currentLinks: SegmentLinkExternal[];
    onSubmit: (links: SegmentLinkExternal[]) => void;
    onClose: () => void;
};

const ModalAddSegmentLinksExternal: FunctionComponent<ModalAddSegmentLinksExternalProps> = ({active, segmentId, currentLinks, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const [availableDistributions, setAvailableDistributions] = useState<Distribution[]>([]);
    const [optionsDistributions, setOptionsDistributions] = useState<{ label: string; value: string }[]>([]);
    const [currentItems, setCurrentItems] = useState<SegmentLinkExternal[]>([]);

    useEffect(() => {
        if (!active) {
            setCurrentItems([]);
        }
    }, [active]);

    useEffect(() => {
        (async () => {
            try {
                const distributions = await session.restList.getDistributions();
                const currentLinksDistributions = currentLinks.map((it) => it.map_name);
                setAvailableDistributions(distributions.filter((item) => !currentLinksDistributions.includes(item.name)));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("distributions", e.message);
                }
            }
        })();
    }, [currentLinks, alert]);

    useEffect(() => {
        setOptionsDistributions(() => {
            const currentSelectedDistributions = currentItems.map((it) => it.map_name);
            const items = availableDistributions.filter((it) => !currentSelectedDistributions.includes(it.name))
                .map((item) => ({label: item.label, value: item.name}));
            return sortItems(items, "label");
        });
    }, [currentItems, availableDistributions]);

    const addDistribution = (mapName: string) => {
        if (mapName === "") return;

        const newLink = new SegmentLinkExternal();
        newLink.segment_id = segmentId;
        newLink.map_name = mapName;
        newLink.map_label = availableDistributions.find((it) => it.name === mapName)?.label || "";
        setCurrentItems((prevState) => ([newLink, ...prevState]));
    };

    const handleChange = (link: SegmentLinkExternal) => {
        setCurrentItems((prevState) => {
            const newState = [...prevState];
            const index = newState.findIndex((it) => it.map_name === link.map_name);
            newState[index] = link;
            return newState;
        });
    };

    const handleDelete = (item: SegmentLinkExternal) => {
        setCurrentItems((prevState) => prevState.filter((it) => it.map_name !== item.map_name));
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textSegments("modal.add_distributions")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                    <Select
                        placeholder={textSegments("actions.select_distribution")}
                        options={optionsDistributions}
                        onChange={(option) => option && addDistribution(option.value.toString())}
                    />
                    <Table
                        columns={[
                            {label: textSegments("field.status"), width: 20, styles: TableColumnStyle.ALIGN_CENTER},
                            {label: textSegments("field.platform"), width: 30},
                            {label: textSegments("field.platform_id"), width: 45},
                            {width: 5}
                        ]}
                    >
                        {currentItems.map((item) =>
                            <SegmentLinkExternalRow
                                key={item.map_name}
                                link={item}
                                onChangeStatus={handleChange}
                                onChangeValue={handleChange}
                                onRemove={() => handleDelete(item)}
                            />
                        )}
                    </Table>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate onClick={() => onSubmit(currentItems)} disabled={!currentItems.length}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddSegmentLinksExternal;
