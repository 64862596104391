import {ApiModel} from "../../../../common/api/model/ApiModel";

export class FreemiumList extends ApiModel {
    purposes: number[] = [];
    sirdataVendors: number[] = [];
    vendors: number[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
