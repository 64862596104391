export class SegmentLinkAreaCodeType {
    static POSTAL_CODE: SegmentLinkAreaCodeType = new SegmentLinkAreaCodeType("POSTAL CODE");
    static IRIS_CODE: SegmentLinkAreaCodeType = new SegmentLinkAreaCodeType("IRIS CODE");
    static NISS_CODE: SegmentLinkAreaCodeType = new SegmentLinkAreaCodeType("NISS CODE");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): SegmentLinkAreaCodeType[] {
        return [
            SegmentLinkAreaCodeType.POSTAL_CODE,
            SegmentLinkAreaCodeType.IRIS_CODE,
            SegmentLinkAreaCodeType.NISS_CODE
        ];
    }
}
