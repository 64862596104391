import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormValidationType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DealCurationPlatform} from "../../../api/model/deal/DealCurationPlatform";
import {DealPlatform} from "../../../api/model/deal/DealPlatform";
import {DealPlatformField} from "../../../api/model/deal/DealPlatformField";
import {DealRecommendationField} from "../../../api/model/deal/DealRecommendationField";
import {DealRecommendationFormatType} from "../../../api/model/deal/DealRecommendationFormatType";
import {TranslationPortalFile} from "../../../utils/constants";
import {DealPlatformRecommendationRow, SelectStatus} from "../../snippet";
import {Status} from "../../../utils/Status";

export type ModalDuplicateDealPlatformProps = {
    initPlatform: DealPlatform | undefined;
    onSubmit: (platform: DealPlatform) => void;
    onClose: () => void;
};

const ModalDuplicateDealPlatform: FunctionComponent<ModalDuplicateDealPlatformProps> = ({initPlatform, onSubmit, onClose}) => {
    const {t: textDeals} = useTranslation(TranslationPortalFile.DEALS);
    const [platform, setPlatform] = useState<DealPlatform>(new DealPlatform());
    const FORM_ID = "form-duplicate-deal-platform";

    useEffect(() => {
        if (!!initPlatform) {
            setPlatform(new DealPlatform({...initPlatform, [DealPlatformField.DEAL_ID]: ""}));
        }
    }, [initPlatform]);

    const handleChange = (field: DealPlatformField, value: any) => {
        setPlatform((prevState) => new DealPlatform({...prevState, [field]: value}));
    };

    const handleChangeRecommendation = (type: DealRecommendationFormatType) => (field: DealRecommendationField, value: number) => {
        const newPlatform = new DealPlatform({...platform});
        const recommendation = [...newPlatform.recommendation];
        const index = platform.recommendation.findIndex((it) => it.format_type === type);
        recommendation[index] = {...recommendation[index], [field]: value};
        newPlatform.recommendation = recommendation;
        setPlatform(newPlatform);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(platform);
    };

    return (
        <ModalNew onClose={onClose} active={!!initPlatform}>
            <ModalHeader>
                <ModalHeaderTitle title={textDeals("duplicate_deal_id")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={3}>
                            <FieldBlock label={textDeals("field.platform.status")}>
                                <SelectStatus
                                    value={platform.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => handleChange(DealPlatformField.ACTIVE, status === Status.ACTIVE)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textDeals(`field.platform.${DealPlatformField.LABEL}`)} required>
                                <Select
                                    value={platform.name}
                                    options={DealCurationPlatform.values().map((item) => ({value: item.name, label: item.label}))}
                                    onChange={() => {}}
                                    disabled
                                />
                            </FieldBlock>
                            <FieldBlock label={textDeals(`field.platform.${DealPlatformField.DEAL_ID}`)} required>
                                <InputText
                                    value={platform.deal_id}
                                    onChange={(value) => handleChange(DealPlatformField.DEAL_ID, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutSeparator/>
                        <FormLayoutColumns>
                            {platform.recommendation.map((item) =>
                                <DealPlatformRecommendationRow
                                    key={item.format_type}
                                    recommendation={item}
                                    onChange={handleChangeRecommendation(item.format_type)}
                                />)}
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalDuplicateDealPlatform;
