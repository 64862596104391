import clsx from "clsx";
import {ApiModel} from "../../../common/api/model/ApiModel";
import {Status} from "../../../utils/Status";
import {ContentElement} from "../../interface/ContentElement";
import {UserProperties} from "./UserProperties";
import {Module} from "../../../utils/Module";

export class User extends ApiModel {
    id: number = 0;
    email: string = "";
    name: string = "";
    properties: UserProperties = {};
    first_name: string = "";
    last_name: string = "";
    phone: string = ""; // international phone number, can be local during creation or updates, will be updated
    phone_region: string = ""; // ISO 3166-1 alpha-2 region for the phone number
    position: string = "";
    active: boolean = true;
    readonly created_at: string = "";
    readonly updated_at: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get fullName(): string {
        return clsx(this.first_name, this.last_name) || this.name;
    }

    get initials(): string {
        return this.email.substr(0, 3);
    }

    getJsonParameters(): {} {
        return {
            email: this.email,
            name: this.first_name + " " + this.last_name.toUpperCase(),
            first_name: this.first_name,
            last_name: this.last_name.toUpperCase(),
            position: this.position,
            phone: this.phone || "",
            phone_region: this.phone_region,
            active: this.active
        };
    }

    getRoute(): string {
        return Module.USERS.buildRoute(this.id);
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.fullName);
        element.status = this.getStatus();
        if (this.created_at) {
            element.createdAt = new Date(this.created_at);
        }
        if (this.updated_at) {
            element.lastUpdated = new Date(this.updated_at);
        }
        return element;
    }
}
