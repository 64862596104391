export class PartnerOrganizationRequestStatus {
    static REJECTED: PartnerOrganizationRequestStatus = new PartnerOrganizationRequestStatus("REJECTED");
    static REQUESTED: PartnerOrganizationRequestStatus = new PartnerOrganizationRequestStatus("REQUESTED");
    static VALIDATED: PartnerOrganizationRequestStatus = new PartnerOrganizationRequestStatus("VALIDATED");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): PartnerOrganizationRequestStatus[] {
        return [
            PartnerOrganizationRequestStatus.REJECTED,
            PartnerOrganizationRequestStatus.REQUESTED,
            PartnerOrganizationRequestStatus.VALIDATED
        ];
    }

    static getByName(name: string): PartnerOrganizationRequestStatus | undefined {
        return this.values().find((it) => it.name === name);
    }
}
