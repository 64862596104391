import {ApiModel} from "../../../common/api/model/ApiModel";
import {DealRecommendation} from "./DealRecommendation";
import {DealRecommendationFormatType} from "./DealRecommendationFormatType";
import {Status} from "../../../utils/Status";
import {DealCurationPlatform} from "./DealCurationPlatform";

export class DealPlatform extends ApiModel {
    name: string = DealCurationPlatform.XANDR.name;
    label: string = "";
    deal_id: string = "";
    active: boolean = false;
    recommendation: DealRecommendation[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }
}

export const INIT_PLATFORM: DealPlatform = new DealPlatform({
    name: DealCurationPlatform.XANDR.name,
    label: DealCurationPlatform.XANDR.label,
    deal_id: "",
    active: true,
    recommendation: [
        {
            format_type: DealRecommendationFormatType.BANNER,
            impression: 0,
            cpm_eur: 0,
            cpm_usd: 0
        }, {
            format_type: DealRecommendationFormatType.NATIVE,
            impression: 0,
            cpm_eur: 0,
            cpm_usd: 0
        }, {
            format_type: DealRecommendationFormatType.VIDEO,
            impression: 0,
            cpm_eur: 0,
            cpm_usd: 0
        }
    ]
});
