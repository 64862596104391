export enum DataleaksAuditField {
    ID = "id",
    PARTNER_ID = "partner_id",
    COMPANY = "company",
    CONTACT = "contact",
    DOMAIN = "domain",
    VERSION_ID = "version_id",
    INFOS = "infos",
    ANSWERS = "answers",
    SCORING = "scoring",
    COMMENT = "comment",
    NOTIFICATION_DATE = "notification_date",
    USER_ID = "user_id",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at"
}
