export enum UserField {
    ID = "id",
    EMAIL = "email",
    FIRST_NAME = "first_name",
    LAST_NAME = "last_name",
    PHONE = "phone",
    PHONE_REGION = "phone_region",
    POSITION = "position",
    ACTIVE = "active",
    NAME = "name",
    PROPERTIES = "properties",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at"
}
