import {ApiModel} from "../../../../common/api/model/ApiModel";
import {ContentElement} from "../../../interface/ContentElement";
import {Module} from "../../../../utils/Module";

export class PartnerLicense extends ApiModel {
    id: number = 0;
    name: string = "";
    description: string = "";
    _info: Map<string, any> = new Map<string, any>();
    _authorizations: Map<string, boolean> = new Map<string, boolean>();
    readonly created_at: string = "";
    readonly updated_at: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get info(): Map<string, any> {
        return this._info || new Map<string, any>();
    }

    set info(info: Map<string, any> | undefined) {
        if (!info) {
            return;
        }

        this._info = Object.entries(info).length ? new Map(Object.entries(info)) : info;
    }

    get authorizations(): Map<string, boolean> {
        return this._authorizations || new Map<string, boolean>();
    }

    set authorizations(authorizations: Map<string, boolean> | undefined) {
        if (!authorizations) {
            return;
        }

        this._authorizations = Object.entries(authorizations).length ? new Map(Object.entries(authorizations)) : authorizations;
    }

    getMapJson(map?: Map<string, any>): { [key: string]: object } | undefined {
        if (!map) return undefined;
        let t: { [key: string]: object } = {};
        map.forEach((value, key) => {
            t[key] = value;
        });
        return t;
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            info: this.getMapJson(this._info),
            authorizations: this.getMapJson(this._authorizations)
        };
    }

    getRoute(): string {
        return Module.LICENSES.buildRoute(this.id);
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        if (this.created_at) {
            element.createdAt = new Date(this.created_at);
        }
        if (this.updated_at) {
            element.lastUpdated = new Date(this.updated_at);
        }
        return element;
    }
}
