import {Segment} from "./Segment";
import {SearchResult} from "../../../interface/SearchResult";

export class SegmentSearchResult extends SearchResult<Segment> {
    total: number = 0;
    results: Segment[] = [];

    constructor(o?: {}) {
        super(Segment, o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "results") {
                    (o[key] as Segment[]).forEach((obj) => {
                        const it = new Segment(obj);
                        this.results.push(it);
                    });
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    get total_elements(): number {
        return this.total;
    }

    get elements(): Segment[] {
        return this.results;
    }
}
