import {TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {User} from "../../../../api/model/user/User";
import {LabelStatus, Tag} from "../../index";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../../utils/constants";

type UserRowProps = {
    user: User;
}

const UserRow: FunctionComponent<UserRowProps> = ({user}) => {
    const {t: textUsers} = useTranslation(TranslationPortalFile.USERS);

    return (
        <TableRow onClick={{link: user.getRoute()}}>
            <TableColumn>
                <LabelStatus status={user.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{user.id}</TableColumn>
            <TableColumn>{user.fullName}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{user.email}</TableColumn>
            <TableColumn>
                {user.properties.team &&
                    <Tag label={textUsers(`team.${user.properties.team}`)} style={TagStyle.PRIMARY_OCEAN}/>
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{user.position}</TableColumn>
        </TableRow>
    );
};

export default UserRow;
