import {FlexContent, FlexContentDirection, FlexContentJustify, FlexContentSpacing} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {DataleaksAuditScoring} from "../../../api/model/dataleaks/audit/DataleaksAuditScoring";
import {DataleaksRegulation} from "../../../api/model/dataleaks/DataleaksRegulation";
import {DataleaksTrait} from "../../../api/model/dataleaks/DataleaksTrait";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksScore, ScoreCircle} from "../index";
import {Locale} from "../../../common/utils/Locale";

type DataleaksAuditReportScoringProps = {
    scoring: DataleaksAuditScoring;
    locale: Locale;
};

const DataleaksAuditReportScoring: FunctionComponent<DataleaksAuditReportScoringProps> = ({scoring, locale}) => {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS, {lng: locale.toString()});
    const {t: textDataleaksAuditsReport} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS_REPORT, {lng: locale.toString()});
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS, {lng: locale.toString()});

    const buildRegulationScoring = (regulation: DataleaksRegulation) => {
        const score = scoring.regulations.get(regulation) || 0;
        const scoreSmartTags = scoring.regulations_smart_tags.get(regulation) || 0;

        return (
            <div className="dataleaks__report__scores__item">
                <ScoreCircle
                    title={textDataleaksQuestions(`regulation.${regulation}`)}
                    value={score}
                    color={DataleaksAuditScoring.getColorForRate(score)}
                />
                {scoreSmartTags && scoreSmartTags !== score &&
                    <span className="dataleaks__report__scores__item__title">
                        <DataleaksScore value={scoreSmartTags}/> {textDataleaksAudits("with_smart_tags")}
                    </span>
                }
            </div>
        );
    };

    const buildTraitScoring = (trait: DataleaksTrait) => {
        const score = scoring.traits.get(trait) || 0;

        return (
            <div className="dataleaks__report__scores__item">
                <ScoreCircle
                    title={textDataleaksQuestions(`trait.${trait}`)}
                    value={score}
                    color={DataleaksAuditScoring.getColorForRate(score)}
                    small
                />
            </div>
        );
    };

    return (
        <div className="dataleaks__report__section">
            <div className="dataleaks__report__section__title">{textDataleaksAuditsReport("section.scores")}</div>
            <div className="dataleaks__report__scores">
                <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.LARGE} justify={FlexContentJustify.SPACE_EVENLY}>
                    {buildRegulationScoring(DataleaksRegulation.GDPR)}
                    {buildRegulationScoring(DataleaksRegulation.EPRIVACY)}
                </FlexContent>
                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL}>
                    <span className="dataleaks__report__scores__title">{textDataleaksAuditsReport("scores_by_trait")}</span>
                    <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.MEDIUM} justify={FlexContentJustify.CENTER} allowWrap={true}>
                        {Object.values(DataleaksTrait).map((trait) =>
                            <React.Fragment key={trait}>
                                {buildTraitScoring(trait)}
                            </React.Fragment>
                        )}
                    </FlexContent>
                </FlexContent>
            </div>
        </div>
    );
};

export default DataleaksAuditReportScoring;
