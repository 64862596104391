import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Status} from "../../../../utils/Status";
import {ApiService} from "../../ApiService";
import {ContentElement} from "../../../interface/ContentElement";

export class PartnerDomain extends ApiModel {
    readonly id: number = 0;
    id_partner: number = 0;
    domain_name: string = "";
    target_service?: string;
    available: boolean = false;
    override_target_api_service_application_name?: string;
    readonly creation_ts: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static targetServices(): ApiService[] {
        return [
            ApiService.CMP,
            ApiService.GTM_SERVER_SIDE,
            ApiService.HELPER
        ];
    }

    getJsonParameters(): {} {
        return {
            id_partner: this.id_partner,
            domain_name: this.domain_name,
            target_service: this.target_service,
            available: this.available,
            override_target_api_service_application_name: this.override_target_api_service_application_name
        };
    }

    getStatus(): Status {
        return this.available ? Status.AVAILABLE : Status.UNAVAILABLE;
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.domain_name);
        element.status = this.getStatus();
        if (this.creation_ts) {
            element.createdAt = new Date(this.creation_ts);
        }
        return element;
    }
}
