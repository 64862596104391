import React from "react";
import {Color, Icon} from "@sirdata/ui-lib";

type ImagePreviewProps = {
    url: string | undefined;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({url}) => {
    return (
        <div className="image-preview">
            <div
                className="image-preview__content"
                style={{
                    backgroundImage: `url(${url})`,
                    backgroundColor: url ? Color.WHITE : Color.GREY_LIGHT
                }}
            >
                {!url && <Icon name="block" cssClass="image-preview__content__icon"/>}
            </div>
        </div>
    );
};

export default ImagePreview;
