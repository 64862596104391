import {Rest} from "../../common/api/rest/Rest";
import {CategoryLinkExternal} from "../model/audience/category/CategoryLinkExternal";

export class RestCategoryLinkExternal extends Rest {
    rootPath = "/console-api/category";

    list(categoryId: number): Promise<CategoryLinkExternal[]> {
        return this._client.get(`${this.rootPath}/${categoryId}/external`, CategoryLinkExternal) as Promise<CategoryLinkExternal[]>;
    }

    update(categoryId: number, link: CategoryLinkExternal): Promise<CategoryLinkExternal> {
        return this._client.post(`${this.rootPath}/${categoryId}/external`, link, CategoryLinkExternal) as Promise<CategoryLinkExternal>;
    }

    delete(categoryId: number, linkId: number) {
        return this._client.delete(`${this.rootPath}/${categoryId}/external/${linkId}`);
    }
}
