import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {CmpConfigThemeBorderRadius} from "./CmpConfigThemeBorderRadius";
import {CmpConfigThemeFontFamily} from "./CmpConfigThemeFontFamily";
import {CmpConfigThemeTextSize} from "./CmpConfigThemeTextSize";
import {CmpConfigThemeNoConsentButton} from "./CmpConfigThemeNoConsentButton";
import {CmpConfigThemeNoConsentButtonStyle} from "./CmpConfigThemeNoConsentButtonStyle";
import {CmpConfigThemePosition} from "./CmpConfigThemePosition";
import {CmpConfigThemeToolbar} from "./CmpConfigThemeToolbar";
import {CmpConfigThemeField} from "./CmpConfigThemeField";
import {CmpConfigThemeMode} from "./CmpConfigThemeMode";
import {CmpConfigThemeModeField} from "./CmpConfigThemeModeField";
import {CmpConfigThemeSetChoicesStyle} from "./CmpConfigThemeSetChoicesStyle";

export class CmpConfigTheme extends ApiModel {
    lightMode: CmpConfigThemeMode = new CmpConfigThemeMode().withDefaultColors();
    darkMode: CmpConfigThemeMode = new CmpConfigThemeMode();
    borderRadius: CmpConfigThemeBorderRadius = CmpConfigThemeBorderRadius.STRONG;
    textSize: CmpConfigThemeTextSize = CmpConfigThemeTextSize.MEDIUM;
    fontFamily: CmpConfigThemeFontFamily = CmpConfigThemeFontFamily.HELVETICA;
    fontFamilyTitle: CmpConfigThemeFontFamily = CmpConfigThemeFontFamily.HELVETICA;
    overlay: boolean = false;
    whiteLabel: boolean = false;
    noConsentButton: CmpConfigThemeNoConsentButton = CmpConfigThemeNoConsentButton.NONE;
    noConsentButtonStyle: CmpConfigThemeNoConsentButtonStyle = CmpConfigThemeNoConsentButtonStyle.LINK;
    setChoicesStyle: CmpConfigThemeSetChoicesStyle = CmpConfigThemeSetChoicesStyle.BUTTON;
    closeButton: boolean = false;
    position: CmpConfigThemePosition = CmpConfigThemePosition.CENTER;
    toolbar: CmpConfigThemeToolbar = new CmpConfigThemeToolbar();

    constructor(o?: {}) {
        super(o);
        this.load(o);
        if (o === undefined) {
            this.textSize = CmpConfigThemeTextSize.SMALL;
            this.overlay = true;
            this.noConsentButton = CmpConfigThemeNoConsentButton.CONTINUE;
            this.noConsentButtonStyle = CmpConfigThemeNoConsentButtonStyle.LINK;
            this.setChoicesStyle = CmpConfigThemeSetChoicesStyle.BUTTON;
        }
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                switch (key) {
                    case CmpConfigThemeField.TOOLBAR:
                        this[key] = new CmpConfigThemeToolbar(o[key]);
                        break;
                    case CmpConfigThemeField.LIGHT_MODE:
                    case CmpConfigThemeField.DARK_MODE:
                        this[key] = new CmpConfigThemeMode(o[key]);
                        break;
                    default:
                        this[key] = o[key];
                }
            }
        }
    }

    getColor(field: CmpConfigThemeModeField, forDarkMode?: boolean) {
        let color = this.lightMode[field];
        if (forDarkMode) {
            color = this.darkMode[field] || color;
        }
        return color;
    }

    getCssImage(field: CmpConfigThemeModeField, forDarkMode?: boolean) {
        const image = forDarkMode ? this.darkMode[field] : this.lightMode[field];
        if (image) {
            return `url(${image})`;
        }
        return "";
    }

    getJsonParameters(): {} {
        return {
            lightMode: this.lightMode.getJsonParameters(),
            darkMode: this.darkMode.getJsonParameters(),
            borderRadius: this.borderRadius,
            textSize: this.textSize,
            fontFamily: this.fontFamily,
            fontFamilyTitle: this.fontFamilyTitle,
            overlay: this.overlay,
            whiteLabel: this.whiteLabel,
            noConsentButton: this.noConsentButton,
            noConsentButtonStyle: this.noConsentButtonStyle,
            setChoicesStyle: this.setChoicesStyle,
            closeButton: this.closeButton,
            position: this.position,
            toolbar: this.toolbar.getJsonParameters()
        };
    }
}
