import React, {FunctionComponent, memo, useEffect, useState} from "react";
import clsx from "clsx";
import {Carousel} from "@sirdata/ui-lib";
import {Link} from "react-router-dom";
import {session} from "../../../api/ApiSession";
import {UserNotification} from "../../api/model/notification/UserNotification";
import {UserNotificationContent} from "../../api/model/notification/UserNotificationContent";

type SlideLinkProps = {
    content: UserNotificationContent;
    children: React.ReactNode;
}

const SlideLink: FunctionComponent<SlideLinkProps> = ({content, children}) => {
    if (!content.url) return <>{children}</>;

    return content.isUrlExternal() ? (
        <a
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
            className="notification-carousel__slide--clickable"
        >
            {children}
        </a>
    ) : (
        <Link
            to={content.getUrlPathname()}
            className="notification-carousel__slide--clickable"
        >
            {children}
        </Link>
    );
};

type NotificationCarouselProps = {
    cssClass?: string;
}

const NotificationCarousel: FunctionComponent<NotificationCarouselProps> = ({cssClass}) => {
    const [isNotificationsLoading, setNotificationsLoading] = useState<boolean>(true);
    const [notifications, setNotifications] = useState<UserNotification[]>([]);

    useEffect(() => {
        (async () => {
            try {
                setNotifications(await session.restUserNotifications.getSliderNotifications());
            } catch (e) {
            } finally {
                setNotificationsLoading(false);
            }
        })();
    }, []);

    const buildNotificationSlides = (): React.ReactNode[] => {
        const slides: React.ReactNode[] = [];

        notifications.forEach(({content}) => {
            const contentHasText = content.title || content.subtitle || content.text;

            if (content.image) {
                slides.push(
                    <SlideLink content={content}>
                        <div className="notification-carousel__slide" style={{backgroundImage: `url("${content.image}")`}}>
                            {contentHasText &&
                            <div className="notification-carousel__slide__content">
                                {content.title &&
                                    <span className="notification-carousel__slide__content__title">{content.title}</span>
                                }
                                {content.subtitle &&
                                    <span className="notification-carousel__slide__content__subtitle">{content.subtitle}</span>
                                }
                                {content.text &&
                                    <span className="notification-carousel__slide__content__text">{content.text}</span>
                                }
                            </div>
                            }
                        </div>
                    </SlideLink>
                );
            }
        });

        return slides;
    };

    if (!isNotificationsLoading && !notifications.length) {
        return <></>;
    }

    return (
        <Carousel
            ariaLabel="notification-carousel"
            isLoading={isNotificationsLoading}
            slides={buildNotificationSlides()}
            cssClass={clsx(cssClass, {"notification-carousel--has-no-pagination": notifications.length === 1})}
        />
    );
};

export default memo(NotificationCarousel);
