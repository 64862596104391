import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {Distribution} from "../model/audience/distribution/Distribution";
import {DistributionSearchQuery} from "../model/audience/distribution/DistributionSearchQuery";
import {DistributionSearchResult} from "../model/audience/distribution/DistributionSearchResult";
import {DistributionSegmentsStatusUpdate} from "../model/audience/distribution/DistributionSegmentsStatusUpdate";
import {SegmentLinkExternal} from "../model/audience/segment/SegmentLinkExternal";

export class RestDistribution extends Rest implements RestService<Distribution> {
    rootPath = "/console-api/distribution";

    search(search: DistributionSearchQuery): Promise<DistributionSearchResult> {
        return this._client.get(`${this.rootPath}`, DistributionSearchResult, {queryParams: search.getJsonParameters()}) as Promise<DistributionSearchResult>;
    };

    get(distributionName: string): Promise<Distribution> {
        return this._client.get(`${this.rootPath}/${distributionName}`, Distribution) as Promise<Distribution>;
    }

    create(distribution: Distribution): Promise<Distribution> {
        return this._client.post(`${this.rootPath}`, distribution, Distribution) as Promise<Distribution>;
    }

    update(distribution: Distribution): Promise<Distribution> {
        return this._client.put(`${this.rootPath}/${distribution.name}`, distribution, Distribution) as Promise<Distribution>;
    }

    delete(distributionName: string) {
        return this._client.delete(`${this.rootPath}/${distributionName}`);
    }

    getSegments(distributionName: string): Promise<SegmentLinkExternal[]> {
        return this._client.get(`${this.rootPath}/${distributionName}/segments`, SegmentLinkExternal) as Promise<SegmentLinkExternal[]>;
    }

    updateSegmentsStatus(distributionSegmentsStatusUpdate: DistributionSegmentsStatusUpdate) {
        return this._client.post(`${this.rootPath}/segment-status`, distributionSegmentsStatusUpdate);
    }
}