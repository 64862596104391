import {ApiModel} from "../../../common/api/model/ApiModel";

export type DealTargetingOriginItem = {
    id: string;
}

export class DealTargetingOrigin extends ApiModel {
    user_centric?: DealTargetingOriginItem[];
    contextual?: DealTargetingOriginItem[];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
