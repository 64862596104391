import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CustomerOrderSegmentStat} from "./CustomerOrderSegmentStat";

export class CustomerOrderSegmentStats extends ApiModel {
    hashes_by_segments: CustomerOrderSegmentStat[] = [];
    code: string = "";
    message: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
