import {ApiModel} from "../../../../common/api/model/ApiModel";

export class DataleaksAuditCompany extends ApiModel {
    name: string = "";
    address: string = "";
    logo?: string;
    siren: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
