import {Rest} from "../../common/api/rest/Rest";
import {PartnerOrganizationGroup} from "../model/partner/organization/PartnerOrganizationGroup";
import {Partner} from "../model/partner/Partner";
import {PartnerAccount} from "../model/partner/PartnerAccount";
import {SearchResult} from "../interface/SearchResult";
import {RestService} from "../interface/RestService";

export class RestPartnerOrganizationGroup extends Rest implements RestService<PartnerOrganizationGroup> {
    rootPath = "/console-api/partner/organization/group";

    search(params: any): Promise<SearchResult<PartnerOrganizationGroup>> {
        return new Promise<SearchResult<PartnerOrganizationGroup>>(() => {}); // TODO Implement route
    }

    list(): Promise<PartnerOrganizationGroup[]> {
        return this._client.get(`${this.rootPath}`, PartnerOrganizationGroup) as Promise<PartnerOrganizationGroup[]>;
    }

    create(group: PartnerOrganizationGroup): Promise<PartnerOrganizationGroup> {
        return this._client.post(`${this.rootPath}`, group, PartnerOrganizationGroup) as Promise<PartnerOrganizationGroup>;
    }

    get(groupId: number): Promise<PartnerOrganizationGroup> {
        return this._client.get(`${this.rootPath}/${groupId}`, PartnerOrganizationGroup) as Promise<PartnerOrganizationGroup>;
    }

    update(group: PartnerOrganizationGroup): Promise<PartnerOrganizationGroup> {
        return this._client.put(`${this.rootPath}/${group.id}`, group, PartnerOrganizationGroup) as Promise<PartnerOrganizationGroup>;
    }

    delete(groupId: number) {
        return this._client.delete(`${this.rootPath}/${groupId}`);
    }

    getPartners(group: PartnerOrganizationGroup): Promise<Partner[]> {
        return this._client.get(`${this.rootPath}/${group.id}/partner`, Partner) as Promise<Partner[]>;
    }

    getPartnerAccounts(group: PartnerOrganizationGroup): Promise<PartnerAccount[]> {
        return this._client.get(`${this.rootPath}/${group.id}/partner-account`, PartnerAccount) as Promise<PartnerAccount[]>;
    }
}
