import {Action, ActionsMenu, Color, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow, Tooltip, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {CategoryType} from "../../../../api/model/audience/category/CategoryType";
import {SegmentLinkCategory} from "../../../../api/model/audience/segment/SegmentLinkCategory";
import {RestrictedContent} from "../../../../common/component/widget";
import {Formatter} from "../../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../../utils/constants";
import {TagCategoryType} from "../../index";

type SegmentLinkCategoryRowProps = {
    link: SegmentLinkCategory;
    onRemove: (link: SegmentLinkCategory) => void;
    showCategoryType?: boolean;
}

const SegmentLinkCategoryRow: FunctionComponent<SegmentLinkCategoryRowProps> = ({link, onRemove, showCategoryType}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);

    const goToDetails = (link: SegmentLinkCategory) => {
        window.open(link.getRoute(), "_blank");
    };

    return (
        <TableRow onClick={() => goToDetails(link)}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{link.category_id}</TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                <Tooltip text={link.category_code_name}>
                    {link.category_name}
                </Tooltip>
                {showCategoryType && <TagCategoryType type={CategoryType.getByName(link.category_type)}/>}
                {link.has_keywords &&
                    <IconTooltip icon={{name: "key", colorIcon: Color.OCEAN}} text={textCategories("has_keywords")}/>
                }
                {link.has_bq_request &&
                    (link.has_bq_request_active ?
                        <IconTooltip
                            icon={{name: "settings", colorIcon: Color.OCEAN}}
                            text={textCategories("has_bq_request_active")}
                        /> :
                        <IconTooltip
                            icon={{name: "settings", colorIcon: Color.GREY_DARK}}
                            text={textCategories("has_bq_request")}
                        />)
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {link.volume ? Formatter.formatNumber(link.volume) : "-"}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {CategoryType.getByName(link.category_type)?.useAutoMinScore ? link.auto_min_score || link.min_score : link.min_score}
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <RestrictedContent allowedTo={Authorization.SEGMENTS.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={[
                            {label: textCommon(Action.REMOVE.labelKey), onClick: () => onRemove(link), critical: true, separator: true}
                        ]}
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default SegmentLinkCategoryRow;
