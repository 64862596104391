import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {session} from "../../../api/ApiSession";
import {PartnerLicense} from "../../../api/model/partner/license/PartnerLicense";
import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutRows,
    FormValidationType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {PartnerLicenseField} from "../../../api/model/partner/license/PartnerLicenseField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";

export type ModalCreatePartnerLicenseProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreatePartnerLicense: FunctionComponent<ModalCreatePartnerLicenseProps> = ({active, onClose}) => {
    const {t: textLicenses} = useTranslation(TranslationPortalFile.LICENSES);
    const [license, setLicense] = useState<PartnerLicense>(new PartnerLicense());
    const navigate = useNavigate();
    const alert = useAlert();
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-partner-license";

    useEffect(() => {
        if (active) {
            setLicense(new PartnerLicense());
        }
    }, [active]);

    const handleChange = (field: PartnerLicenseField, value: any) => {
        setLicense((prevState) => new PartnerLicense({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const newPartnerLicense = await session.restPartnerLicense.create(license);
            navigate(newPartnerLicense.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("license", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textLicenses("modal.creation.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textLicenses(`field.${PartnerLicenseField.NAME}`)} required>
                            <InputText
                                value={license.name}
                                onChange={(value) => handleChange(PartnerLicenseField.NAME, value)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textLicenses(`field.${PartnerLicenseField.DESCRIPTION}`)}>
                            <InputText
                                value={license.description}
                                onChange={(value) => handleChange(PartnerLicenseField.DESCRIPTION, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreatePartnerLicense;
