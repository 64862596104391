export class DistributionLicenseSegmentLinkType {
    static ALL: DistributionLicenseSegmentLinkType = new DistributionLicenseSegmentLinkType("ALL");
    static DATATYPE: DistributionLicenseSegmentLinkType = new DistributionLicenseSegmentLinkType("DATATYPE");
    static LIST: DistributionLicenseSegmentLinkType = new DistributionLicenseSegmentLinkType("LIST");

    name: string;

    constructor(name: string) {
        this.name = name;
    }

    static values(): DistributionLicenseSegmentLinkType[] {
        return [
            DistributionLicenseSegmentLinkType.ALL,
            DistributionLicenseSegmentLinkType.DATATYPE,
            DistributionLicenseSegmentLinkType.LIST
        ];
    }
}