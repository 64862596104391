import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Pricing} from "./Pricing";
import {Partner} from "../Partner";

export class PartnerPricing extends ApiModel {
    id: number = 0;
    id_partner: number = 0;
    id_pricing: number = 0;
    start_date: string = "";
    end_date?: string;
    discount: number = 0;
    active: boolean = true;
    _pricing: Pricing = new Pricing();
    _partner: Partner = new Partner();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get pricing(): Pricing {
        return new Pricing(this._pricing);
    }

    set pricing(pricing: Pricing) {
        this._pricing = pricing;
    }

    get partner(): Partner {
        const partner = new Partner(this._partner);
        partner.id = partner.id || this.id_partner;
        return partner;
    }

    set partner(partner: Partner) {
        this._partner = partner;
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            id_partner: this.id_partner,
            id_pricing: this.id_pricing,
            start_date: this.start_date,
            end_date: this.end_date || undefined,
            discount: this.discount,
            active: this.active
        };
    }
}
