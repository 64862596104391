import {ApiModel} from "../../../common/api/model/ApiModel";
import moment from "moment";
import {Formatter} from "../../../common/utils/Formatter";
import {Status} from "../../../utils/Status";

export class NotificationItem extends ApiModel {
    id: number = 0;
    dispatch_date: string | undefined;
    is_read: boolean = false;
    partner_account_id: number | undefined;
    partner_id: number | undefined;
    user_id: number | undefined;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get isPending(): boolean {
        return !this.dispatch_date || Formatter.convertUTCToDate(this.dispatch_date).diff(moment()) > 0;
    }

    getStatus(): Status {
        if (this.is_read) {
            return Status.READ;
        } else if (!this.dispatch_date) {
            return Status.PENDING;
        } else if (Formatter.convertUTCToDate(this.dispatch_date).diff(moment()) > 0) {
            return Status.SCHEDULED;
        } else {
            return Status.UNREAD;
        }
    };
}
