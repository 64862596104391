import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputEmail,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {Partner} from "../../../api/model/partner/Partner";
import {PartnerAccount} from "../../../api/model/partner/PartnerAccount";
import {session} from "../../../api/ApiSession";
import {PartnerAccountField} from "../../../api/model/partner/PartnerAccountField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {SelectStatus} from "../../snippet";
import {Status} from "../../../utils/Status";
import useAlert from "../../../utils/hooks/useAlert";

export type ModalCreatePartnerProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreatePartner: FunctionComponent<ModalCreatePartnerProps> = ({active, onClose}) => {
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const [account, setAccount] = useState<PartnerAccount>(new PartnerAccount());
    const alert = useAlert();
    const navigate = useNavigate();
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-partner";

    useEffect(() => {
        if (active) {
            setAccount(new PartnerAccount());
        }
    }, [active]);

    const handleChange = (field: PartnerAccountField, value: any) => {
        setAccount((prevState) => new PartnerAccount({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const partner = new Partner();
            partner.user = (await session.getAccount()).email;
            partner.accounts.push(account);
            const newPartner = await session.restPartner.create(partner);
            navigate(newPartner.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("partner", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textPartners("create_partner")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={3}>
                            <FieldBlock label={textPartners("field.status")}>
                                <SelectStatus
                                    value={account.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => handleChange(PartnerAccountField.ACTIVE, status === Status.ACTIVE)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textPartners(`field.partner_account.${PartnerAccountField.EMAIL}`)} required>
                            <InputEmail
                                value={account.email}
                                onChange={(value) => handleChange(PartnerAccountField.EMAIL, value)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textPartners(`field.partner_account.${PartnerAccountField.FIRST_NAME}`)}>
                                <InputText
                                    value={account.first_name}
                                    onChange={(value) => handleChange(PartnerAccountField.FIRST_NAME, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textPartners(`field.partner_account.${PartnerAccountField.LAST_NAME}`)}>
                                <InputText
                                    value={account.last_name}
                                    onChange={(value) => handleChange(PartnerAccountField.LAST_NAME, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreatePartner;
