export enum CmpConfigThemeModeField {
    BACKGROUND_COLOR = "backgroundColor",
    MAIN_COLOR = "mainColor",
    TITLE_COLOR = "titleColor",
    TEXT_COLOR = "textColor",
    BORDER_COLOR = "borderColor",
    OVERLAY_COLOR = "overlayColor",
    LOGO = "logo",
    WATERMARK = "watermark",
    SKIN = "skin"
}
