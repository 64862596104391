export class DealCountry {
    static FRANCE: DealCountry = new DealCountry("FRANCE");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): DealCountry[] {
        return [
            DealCountry.FRANCE
        ];
    }
}
