import {ApiModel} from "../../../common/api/model/ApiModel";

export class NotificationDispatch extends ApiModel {
    ids: number[] | undefined;
    dispatch_now: boolean | undefined;
    dispatch_date: string | undefined;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            ids: this.ids,
            dispatch_now: this.dispatch_now,
            dispatch_date: this.dispatch_date
        };
    };
}
