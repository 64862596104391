import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {CmpConfigPublisherLegalBasis} from "./CmpConfigPublisherLegalBasis";
import {CmpConfigPublisherCustomPurposeTranslation} from "./CmpConfigPublisherCustomPurposeTranslation";
import {CmpConfigPublisherCustomPurposeVendor} from "./CmpConfigPublisherCustomPurposeVendor";

export class CmpConfigPublisherCustomPurpose extends ApiModel {
    id: number = 0;
    name: string = "";
    description: string = "";
    legalBasis: CmpConfigPublisherLegalBasis = CmpConfigPublisherLegalBasis.CONSENT;
    vendor: CmpConfigPublisherCustomPurposeVendor | undefined;
    _translations: Map<string, CmpConfigPublisherCustomPurposeTranslation> | undefined;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get translations(): Map<string, CmpConfigPublisherCustomPurposeTranslation> | undefined {
        return this._translations;
    }

    set translations(t: Map<string, CmpConfigPublisherCustomPurposeTranslation> | undefined) {
        if (!t) {
            this._translations = t;
            return;
        }

        this._translations = Object.entries(t).length ? new Map(Object.entries(t)) : t;
    }

    getTranslationsJson(): { [key: string]: object } | undefined {
        if (!this._translations) return undefined;

        let t: { [key: string]: object } = {};
        this._translations.forEach((value, key) => {
            t[key] = value.getJsonParameters ? value.getJsonParameters() : value;
        });
        return t;
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            legalBasis: this.legalBasis,
            vendor: this.vendor,
            translations: this.getTranslationsJson()
        };
    }
}
