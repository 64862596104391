import {
    Action,
    ContentBlock,
    LayoutRows,
    Loadable,
    SearchError,
    SearchField,
    SearchToolbar,
    Select,
    SelectAutocomplete,
    SelectMultiple,
    Table,
    TableColumnStyle,
    ToggleSwitch
} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Distribution} from "../../api/model/audience/distribution/Distribution";
import {DistributionField} from "../../api/model/audience/distribution/DistributionField";
import {Segment} from "../../api/model/audience/segment/Segment";
import {SegmentDataType} from "../../api/model/audience/segment/SegmentDataType";
import {SegmentField} from "../../api/model/audience/segment/SegmentField";
import {SegmentSearchParamsField, SegmentSearchQuery} from "../../api/model/audience/segment/SegmentSearchQuery";
import {SegmentSearchResult} from "../../api/model/audience/segment/SegmentSearchResult";
import {SegmentTopTier} from "../../api/model/audience/segment/SegmentTopTier";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {sortItems} from "../../common/utils/helper";
import {downloadCSV} from "../../common/utils/portal";
import ModalCreateSegment from "../../component/modal/segments/ModalCreateSegment";
import ModalEditCurrencyRatesSegments from "../../component/modal/segments/ModalEditCurrencyRatesSegments";
import {MainContentHeader, MainContentHeaderAction, SegmentRow, SelectStatus} from "../../component/snippet";
import {PAGE_SIZE, TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {Status} from "../../utils/Status";

function Segments() {
    const alert = useAlert();
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const [isLoading, setLoading] = useState(true);
    const [distributions, setDistributions] = useState<Distribution[]>([]);
    const [isShowModalCreateSegment, setShowModalCreateSegment] = useState(false);
    const [isShowModalCurrencyRatesSegments, setShowModalCurrencyRatesSegments] = useState(false);
    const {setSearchResult, ...search} = useSearch(Segment, SegmentSearchQuery, SegmentSearchResult);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const newSearchResult = await session.restSegment.search(search.searchQuery);
                newSearchResult.size = PAGE_SIZE;
                newSearchResult.page = search.searchQuery.page;
                setSearchResult(newSearchResult);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("segments", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [search.searchQuery, setSearchResult, alert]);

    useEffect(() => {
        (async () => {
            try {
                const distributions = await session.restList.getDistributions();
                setDistributions(sortItems(distributions, DistributionField.LABEL));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("distributions", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    const handleDownloadSearch = async () => {
        try {
            const content = await session.restSegment.export(search.searchQuery);
            downloadCSV("segments", content);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("export segments", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.SEGMENTS}>
                <RestrictedContent allowedTo={Authorization.CURRENCIES.name}>
                    <MainContentHeaderAction action={new Action(textSegments("section.currency_rates"), {name: "currency_exchange"})} onClick={() => setShowModalCurrencyRatesSegments(true)}/>
                </RestrictedContent>
                <MainContentHeaderAction action={Action.DOWNLOAD} onClick={handleDownloadSearch}/>
                <RestrictedContent allowedTo={Authorization.SEGMENTS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateSegment(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textSegments("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters={true}
                        >
                            <SearchField label={textSegments("filters.strict_search")} shortWidth>
                                <ToggleSwitch
                                    checked={search.searchQuery.strict}
                                    onChange={(value) => search.changeParam(SegmentSearchParamsField.STRICT, value)}
                                />
                            </SearchField>
                            <SearchField label={textSegments("field.status")}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textSegments(`field.${SegmentSearchParamsField.DATA_TYPES}`)}>
                                <SelectMultiple
                                    values={search.searchQuery.data_types}
                                    options={SegmentDataType.values().map((type) => ({label: `${textSegments(`datatype.${type.name}`)}`, value: type.name}))}
                                    onChange={(options) => search.changeParam(SegmentSearchParamsField.DATA_TYPES, options.map((it) => it.value))}
                                />
                            </SearchField>
                            <SearchField label={textSegments("field.vertical")}>
                                <Select
                                    value={search.searchQuery.tiers1}
                                    options={SegmentTopTier.values().map((tiers) => ({value: tiers.name, label: tiers.name}))}
                                    onChange={(option) => search.changeParam(SegmentSearchParamsField.TIERS1, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textSegments("field.visibility")}>
                                <Select
                                    value={search.searchQuery.public}
                                    options={[
                                        {value: true, label: textSegments("field.public")},
                                        {value: false, label: textSegments("field.private")}
                                    ]}
                                    onChange={(option) => search.changeParam(SegmentSearchParamsField.PUBLIC, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textSegments("field.platform")}>
                                <SelectAutocomplete
                                    value={search.searchQuery.platform}
                                    options={distributions.map((distribution) => ({value: distribution.name, label: distribution.label}))}
                                    onChange={(option) => search.changeParam(SearchParamsField.PLATFORM, option?.value)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textSegments("field.status"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5, label: textSegments(`field.${SegmentField.ID}`), sort: {field: SegmentField.ID, reverseOrder: true}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textSegments(`field.${SegmentField.DATA_TYPE}`), sort: {field: SegmentField.DATA_TYPE, reverseOrder: false}},
                                        {width: 65, label: textSegments(`field.${SegmentField.NAME}`), sort: {field: SegmentField.NAME, reverseOrder: false}, styles: TableColumnStyle.FIXED_WIDTH},
                                        {width: 5, label: textSegments(`field.${SegmentField.PRICE}`), sort: {field: SegmentField.PRICE, reverseOrder: false}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textSegments("field.platform_id"), styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((segment) =>
                                        <SegmentRow key={segment.id} segment={segment}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateSegment
                    active={isShowModalCreateSegment}
                    onClose={() => setShowModalCreateSegment(false)}
                />
                <ModalEditCurrencyRatesSegments
                    active={isShowModalCurrencyRatesSegments}
                    onClose={() => setShowModalCurrencyRatesSegments(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Segments;
