import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";

export class PartnerOrganizationSearchQuery extends SearchQuery {
    type?: string;
    owner_id?: number;

    withSearchParams(params: SearchParams): PartnerOrganizationSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting("id", true) as PartnerOrganizationSearchQuery;
        newQuery.type = params.getString(SearchParamsField.TYPE);
        newQuery.owner_id = params.getNumber(SearchParamsField.OWNER);
        return newQuery;
    }
}
