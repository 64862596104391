import {Action, ContentBlock, ContentBlockAction, Loadable, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import moment from "moment";
import React, {FC, RefObject, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {SearchQuery} from "../../../api/interface/SearchQuery";
import {SearchResult} from "../../../api/interface/SearchResult";
import {Authorization} from "../../../api/model/account/Authorization";
import {CategoryGroupLinkKeyword} from "../../../api/model/audience/category/CategoryGroupLinkKeyword";
import {CategoryGroupLinkKeywordField} from "../../../api/model/audience/category/CategoryGroupLinkKeywordField";
import {CategoryGroupLinkKeywordSource} from "../../../api/model/audience/category/CategoryGroupLinkKeywordSource";
import {User} from "../../../api/model/user/User";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {LoggedAccount} from "../../../common/api/interface/LoggedAccount";
import {RestrictedContent} from "../../../common/component/widget";
import {Formatter} from "../../../common/utils/Formatter";
import {copyApiModelArray} from "../../../common/utils/helper";
import {detectChanges} from "../../../common/utils/portal";
import {CategoriesDetailsSubModule} from "../../../screen/categories/CategoriesDetails";
import {HandleSaveRef} from "../../../utils/audience/HandleSaveRef";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import ModalAddCategoryGroupLinksKeywords from "../../modal/categories/ModalAddCategoryGroupLinksKeywords";
import ModalEditKeywordsThreshold from "../../modal/categories/ModalEditKeywordsThreshold";
import ModalConfirmRemove from "../../modal/ModalConfirmRemove";
import {CategoryGroupLinkKeywordRow} from "../index";

type CategoryGroupKeywordsLinksProps = {
    categoryGroupId: number;
    handleSaveRef: RefObject<HandleSaveRef>;
    onChange: (module: CategoriesDetailsSubModule, hasChanges: boolean) => void;
};

const CategoryGroupKeywordsLinks: FC<CategoryGroupKeywordsLinksProps> = ({categoryGroupId, handleSaveRef, onChange}) => {
    const {t: textKeywords} = useTranslation(TranslationPortalFile.CATEGORY_KEYWORDS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [keywords, setKeywords] = useState<CategoryGroupLinkKeyword[]>([]);
    const [initKeywords, setInitKeywords] = useState<CategoryGroupLinkKeyword[]>([]);
    const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
    const [user, setUser] = useState<LoggedAccount>();
    const [users, setUsers] = useState<User[]>([]);
    const [isShowModalAddCategoryLinksKeywords, setShowModalAddCategoryLinksKeywords] = useState(false);
    const [isShowModalEditKeywordsThreshold, setShowModalEditKeywordsThreshold] = useState(false);
    const [isShowModalConfirmRemoveSelectedKeywords, setShowModalConfirmRemoveSelectedKeywords] = useState(false);
    const [searchQuery, setSearchQuery] = useState<SearchQuery>(new SearchQuery({sortBy: CategoryGroupLinkKeywordField.KEYWORD}));
    const [searchResult, setSearchResult] = useState<SearchResult<CategoryGroupLinkKeyword>>(new SearchResult(CategoryGroupLinkKeyword));

    useEffect(() => {
        (async () => {
            try {
                setUser(await session.getAccount());
                setUsers(await session.getUsers());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("users", e.message);
                }
            }
        })();
    }, [alert]);

    const getKeywords = useCallback(async () => {
        try {
            const results = await session.restCategoryGroupLinkKeyword.list(categoryGroupId);
            setKeywords(results);
            setInitKeywords(copyApiModelArray(CategoryGroupLinkKeyword, results));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("keywords", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [categoryGroupId, alert]);

    const handleSaveLinks = async (linksKeyword?: CategoryGroupLinkKeyword[]) => {
        if (!linksKeyword) {
            linksKeyword = keywords;
        }
        setLoading(true);
        try {
            const keywordsIds = linksKeyword.map((it) => it.id);
            const keywordsToDelete = initKeywords.filter((it) => it.source === CategoryGroupLinkKeywordSource.DIRECT && !keywordsIds.includes(it.id));
            if (keywordsToDelete.length) {
                await session.restCategoryGroupLinkKeyword.deleteKeywords(keywordsToDelete);
            }

            const initKeywordsIds = initKeywords.map((it) => it.id);
            const keywordsToSave = linksKeyword.filter((k) => k.source === CategoryGroupLinkKeywordSource.DIRECT && (!initKeywordsIds.includes(k.id) || detectChanges(k, initKeywords.find((it) => it.id === k.id) || {})));
            if (keywordsToSave.length) {
                keywordsToSave.forEach((keyword) => keyword.id_owner = user?.id);
                await session.restCategoryGroupLinkKeyword.saveKeywords(keywordsToSave);
            }
            await getKeywords();
            alert.updateWithSuccess("keywords");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("keywords", e.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!categoryGroupId) return;
        (async () => await getKeywords())();
    }, [categoryGroupId, getKeywords]);

    useImperativeHandle(handleSaveRef, () => ({
        handleSave: handleSaveLinks
    }));

    useEffect(() => {
        onChange(CategoriesDetailsSubModule.LINKS_KEYWORD, detectChanges(keywords, initKeywords));
    }, [onChange, keywords, initKeywords]);

    useEffect(() => {
        let filteredKeywords = (keywords || []);
        if (searchQuery.query) {
            filteredKeywords = filteredKeywords.filter((it) => `${it.keyword}`.toLowerCase().includes(searchQuery.query.toLowerCase()));
        }
        filteredKeywords = searchQuery.sortItems(filteredKeywords);
        const result = SearchResult.buildFromList(CategoryGroupLinkKeyword, filteredKeywords, searchQuery.page, PAGE_SIZE);
        setSearchResult(result);
    }, [keywords, searchQuery]);

    const handleChangeSortOrder = (sortBy: string, reverseOrder?: boolean) => {
        setSearchQuery((prevState) => new SearchQuery({...prevState, sortBy: sortBy, reverseOrder: reverseOrder}));
    };

    const handleChangeQuery = (value: string) => {
        setSearchQuery((prevState) => new SearchQuery({...prevState, query: value, page: 0}));
    };

    const handleChangePage = (page: number) => {
        setSearchQuery((prevState) => new SearchQuery({...prevState, page: page - 1}));
    };

    const handleChangeThreshold = (threshold: number) => {
        setShowModalEditKeywordsThreshold(false);
        setKeywords((prevState) => {
            const newState = [...prevState];
            newState.filter((link) => link.source === CategoryGroupLinkKeywordSource.DIRECT).forEach((link) => link.threshold = threshold);
            return newState;
        });
    };

    const handleAddLinks = async (keywordsToAdd: string[], threshold: number) => {
        const newLinks = keywordsToAdd.filter((keyword) => !keywords.find((it) => it.keyword === keyword))
            .map((keyword) => {
                const newLink = new CategoryGroupLinkKeyword();
                newLink.id_group_category = categoryGroupId;
                newLink.keyword = keyword;
                newLink.id_owner = user?.id;
                newLink.threshold = threshold;
                newLink.last_update = moment().format(Formatter.API_DATETIME_FORMAT);
                return newLink;
            });
        setKeywords([...keywords, ...newLinks]);
        await handleSaveLinks([...keywords, ...newLinks]);
        alert.actionWithSuccess(textKeywords("message.keyword_added", {count: newLinks.length}));
        setSearchQuery((prevState) => new SearchQuery({...prevState, sortBy: CategoryGroupLinkKeywordField.LAST_UPDATE, reverseOrder: true}));
        setShowModalAddCategoryLinksKeywords(false);
    };

    const handleSelectLink = (link: CategoryGroupLinkKeyword) => {
        const index = selectedKeywords.findIndex((it) => it === link.keyword);
        const selection = index === -1 ? [...selectedKeywords, link.keyword] : selectedKeywords.filter((it) => it !== link.keyword);
        setSelectedKeywords(selection);
    };

    const handleRemoveLink = (link: CategoryGroupLinkKeyword) => {
        setKeywords((prevState) => prevState.filter((it) => it.keyword !== link.keyword));
    };

    const handleRemoveSelectedLinks = () => {
        setShowModalConfirmRemoveSelectedKeywords(false);
        setKeywords(keywords.filter((it) => !selectedKeywords.includes(it.keyword)));
        setSelectedKeywords([]);
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{
                    title: {label: textKeywords("title")}, actions: [
                        <RestrictedContent key="remove_selected_keywords" allowedTo={Authorization.KEYWORDS.update}>
                            {keywords.some((it) => it.source === CategoryGroupLinkKeywordSource.DIRECT) &&
                                <>
                                    <ContentBlockAction
                                        action={new Action(textKeywords("action.remove_selected", {count: selectedKeywords.length}), {...Action.DELETE.icon, colorIcon: ""})}
                                        onClick={() => setShowModalConfirmRemoveSelectedKeywords(true)}
                                        isDisabled={!selectedKeywords.length}
                                    />
                                    <ContentBlockAction
                                        action={new Action(textKeywords("action.edit_threshold"), {name: "data_thresholding", outlined: true})}
                                        onClick={() => setShowModalEditKeywordsThreshold(true)}
                                    />
                                </>
                            }
                            <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddCategoryLinksKeywords(true)}/>
                        </RestrictedContent>
                    ]
                }}
            >
                <SearchToolbar searchBar={{placeholder: textKeywords("search.placeholder"), value: searchQuery.query, onSubmit: handleChangeQuery}}/>
                <Table
                    pagination={searchResult.getPagination(handleChangePage)}
                    onSort={handleChangeSortOrder}
                    columns={[
                        {width: 1},
                        {label: textKeywords(`field.${CategoryGroupLinkKeywordField.KEYWORD}`), width: 58, sort: {field: CategoryGroupLinkKeywordField.KEYWORD, reverseOrder: false}},
                        {label: textKeywords(`field.${CategoryGroupLinkKeywordField.THRESHOLD}`), width: 20},
                        {label: textKeywords(`field.${CategoryGroupLinkKeywordField.ID_OWNER}`), width: 10, styles: TableColumnStyle.ALIGN_CENTER},
                        {label: textKeywords(`field.${CategoryGroupLinkKeywordField.LAST_UPDATE}`), width: 10, styles: TableColumnStyle.ALIGN_CENTER, sort: {field: CategoryGroupLinkKeywordField.LAST_UPDATE}},
                        {width: 1}
                    ]}
                >
                    {searchResult.elements.map((link) =>
                        <CategoryGroupLinkKeywordRow
                            key={link.id}
                            link={link}
                            isSelected={selectedKeywords.includes(link.keyword)}
                            onChangeThreshold={(value) => setKeywords((prevState) => [...prevState.map((it) => it.keyword === link.keyword ? value : it)])}
                            owner={users.find((user) => user.id === link.id_owner)}
                            onSelect={() => handleSelectLink(link)}
                            onRemove={() => handleRemoveLink(link)}
                        />
                    )}
                </Table>
                <ModalAddCategoryGroupLinksKeywords
                    active={isShowModalAddCategoryLinksKeywords}
                    categoryGroupId={categoryGroupId}
                    existingKeywords={keywords.map((it) => it.keyword)}
                    onSubmit={handleAddLinks}
                    onClose={() => setShowModalAddCategoryLinksKeywords(false)}
                />
                <ModalEditKeywordsThreshold
                    active={isShowModalEditKeywordsThreshold}
                    initValue={keywords[0]?.threshold || CategoryGroupLinkKeyword.DEFAULT_THRESHOLD}
                    onSubmit={handleChangeThreshold}
                    onClose={() => setShowModalEditKeywordsThreshold(false)}
                />
                <ModalConfirmRemove
                    active={isShowModalConfirmRemoveSelectedKeywords}
                    entity={textKeywords("modal.selected_keyword", {count: selectedKeywords.length})}
                    confirm={handleRemoveSelectedLinks}
                    cancel={() => setShowModalConfirmRemoveSelectedKeywords(false)}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default CategoryGroupKeywordsLinks;
