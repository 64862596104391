import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutRows, FormValidationType, ModalActions, ModalContent, ModalNew, Select} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";
import {Locale} from "../../../common/utils/Locale";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalDataleaksVersionAddQuestionProps = {
    active: boolean;
    availableQuestions: DataleaksQuestion[];
    onSubmit: (question: DataleaksQuestion) => void;
    onClose: () => void;
};

const ModalDataleaksVersionAddQuestion: FunctionComponent<ModalDataleaksVersionAddQuestionProps> = ({active, availableQuestions, onSubmit, onClose}) => {
    const {t: textDataleaksVersions} = useTranslation(TranslationPortalFile.DATALEAKS_VERSIONS);
    const FORM_ID = "form-dataleaks-version-add-question";
    const [selectedQuestion, setSelectedQuestion] = useState<DataleaksQuestion>();

    useEffect(() => {
        if (active) {
            setSelectedQuestion(undefined);
        }
    }, [active]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!selectedQuestion) return;
        onSubmit(selectedQuestion);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textDataleaksVersions("select_question")} required>
                            <Select
                                value={selectedQuestion?.id}
                                options={availableQuestions.map((it) => ({label: it.id+" - "+it.texts.get(Locale.FRENCH)?.title || "", value: it.id, question: it}))}
                                onChange={(option) => setSelectedQuestion(option?.question)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalDataleaksVersionAddQuestion;
