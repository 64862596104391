import React, {useEffect, useState} from "react";
import {
    Action,
    ButtonLink,
    ButtonLinkStyle,
    ButtonValidate,
    Color,
    Icon,
    ModalActions,
    ModalActionsAlignment,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    ToggleSwitch,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import {CmpConfigThemeModeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import {TranslationPortalFile} from "../../../utils/constants";
import CmpConfigurationImageSelector from "./CmpConfigurationImageSelector";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";

type CmpConfigurationImageManagerProps = {
    field: CmpConfigThemeModeField.LOGO | CmpConfigThemeModeField.SKIN | CmpConfigThemeModeField.WATERMARK;
    mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE;
    currentImage: string;
    onSave: (mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE, field: CmpConfigThemeModeField, newImage: string, applyBothMode?: boolean) => void;
    onRemove: (mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE, field: CmpConfigThemeModeField) => void;
    maxFileSize?: number;
    hasApplyBothModes?: boolean;
    partnerId: number;
}

const CmpConfigurationImageManager: React.FC<CmpConfigurationImageManagerProps> = ({field, mode, currentImage, onSave, onRemove, maxFileSize, hasApplyBothModes = false, partnerId}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    const [isShowEdition, setShowEdition] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [applyBothModes, setApplyBothModes] = useState(mode === CmpConfigThemeField.LIGHT_MODE);

    useEffect(() => {
        setSelectedImage(currentImage);
    }, [currentImage]);

    const handleValidate = () => {
        if (selectedImage !== currentImage) {
            onSave(mode, field, selectedImage, applyBothModes);
        }
        setShowEdition(false);
    };

    return (
        <div className="image-manager">
            <div className="image-manager__preview" onClick={() => setShowEdition(true)}>
                <div
                    className="image-manager__preview__content"
                    style={{
                        backgroundImage: `url(${currentImage})`,
                        backgroundColor: currentImage ? Color.WHITE : Color.GREY_LIGHT
                    }}
                >
                    {!currentImage && <Icon {...Action.ADD.icon} cssClass="image-manager__preview__content__icon"/>}
                </div>
            </div>
            {currentImage &&
                <div className="image-manager__actions">
                    <ButtonLink icon={Action.EDIT.icon} reverse style={ButtonLinkStyle.MIDNIGHT_LIGHT} onClick={() => setShowEdition(true)}>
                        {textCommon(Action.EDIT.labelKey)}
                    </ButtonLink>
                    <ButtonLink icon={Action.REMOVE.icon} reverse style={ButtonLinkStyle.DANGER} onClick={() => onRemove(mode, field)}>
                        {textCommon(Action.REMOVE.labelKey)}
                    </ButtonLink>
                </div>
            }
            <ModalNew active={isShowEdition} onClose={() => setShowEdition(false)} keepMounted>
                <ModalHeader>
                    <ModalHeaderTitle title={textCmpConfigurations(`image.edit_${field}`)}/>
                </ModalHeader>
                <ModalContent>
                    <CmpConfigurationImageSelector
                        field={field}
                        currentImage={selectedImage}
                        onChange={(newImage) => setSelectedImage(newImage)}
                        maxFileSize={maxFileSize}
                        allowCustomImage={[CmpConfigThemeModeField.LOGO, CmpConfigThemeModeField.SKIN, CmpConfigThemeModeField.WATERMARK].includes(field)}
                        partnerId={partnerId}
                    />
                </ModalContent>
                <ModalActions alignment={ModalActionsAlignment.SPACE_BETWEEN}>
                    {hasApplyBothModes ?
                        <>
                            <ToggleSwitch
                                label={textCmpConfigurations("image.apply_to_mode", {mode: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${mode === CmpConfigThemeField.LIGHT_MODE ? CmpConfigThemeField.DARK_MODE : CmpConfigThemeField.LIGHT_MODE}`)})}
                                name="toggle-switch-mode"
                                checked={applyBothModes}
                                onChange={setApplyBothModes}
                            />
                            <ButtonValidate onClick={handleValidate}/>
                        </>
                        :
                        <ButtonValidate onClick={handleValidate}/>
                    }
                </ModalActions>
            </ModalNew>
        </div>
    );
};

export default CmpConfigurationImageManager;
