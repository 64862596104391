export enum SgtmContainerPropertiesField {
    PROCESSING_OVERLAY = "processing_overlay",
    GTM_PATH = "gtm_path",
    GTM_PATH_ACTIVE = "gtm_path_active",
    GTM_ID = "gtm_id",
    GTM_DATALAYER = "gtm_datalayer",
    GTM_ORDER_DATALAYER = "gtm_order_datalayer",
    GA4_PATH = "ga4_path",
    GA4_PATH_ACTIVE = "ga4_path_active",
    GA4_COLLECT_PATH = "ga4_collect_path",
    GA4_ID = "ga4_id",
    HELPER_ACTIVE = "helper_active",
    HELPER_PARTNER_ID = "helper_partner_id",
    HELPER_CONFIG_ID = "helper_config_id",
    HELPER_EMBED = "helper_embed",
    AVOID_COOKIES = "avoid_cookies",
    AVOID_REQUEST_COOKIES = "avoid_request_cookies",
    CMP_EMBED = "cmp_embed",
    CMP_PARTNER_ID = "cmp_partner_id",
    CMP_CONFIG_ID = "cmp_config_id",
    COOKIELESS_ID_1P = "cookieless_id_1p",
    COOKIELESS_ID_3P = "cookieless_id_3p",
    APPLY_TRANSPARENCY = "apply_transparency",
    EXTEND_COOKIES = "extend_cookies",
    SERVICE_URL = "service_url",
    LOADER_PATH = "loader_path",
    CMP_PATH = "cmp_path"
}
