import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputDate,
    InputDateType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    RadioButtons
} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {ApiService} from "../../../api/model/ApiService";
import {DataSelection} from "../../../api/model/audience/data-selection/DataSelection";
import {DataSelectionContentField} from "../../../api/model/audience/data-selection/DataSelectionContentField";
import {DATA_SELECTION_THEMES} from "../../../api/model/audience/data-selection/DataSelectionTheme";
import {PortalContentField} from "../../../api/model/portal-content/PortalContentField";
import {slugify} from "../../../common/utils/string";
import {DataSelectionContent} from "../../../api/model/audience/data-selection/DataSelectionContent";
import useAlert from "../../../utils/hooks/useAlert";
import {useNavigate} from "react-router-dom";
import {Formatter} from "../../../common/utils/Formatter";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";

export type ModalCreateDataSelectionProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateDataSelection: FunctionComponent<ModalCreateDataSelectionProps> = ({active, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textPortalContent} = useTranslation(TranslationPortalFile.PORTAL_CONTENT);
    const {t: textAudienceDataSelections} = useTranslation(TranslationPortalFile.AUDIENCE_DATA_SELECTIONS);
    const [dataSelection, setDataSelection] = useState<DataSelection>(new DataSelection());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-data-selection";

    useEffect(() => {
        if (active) {
            setDataSelection(new DataSelection());
        }
    }, [active]);

    const handleChange = (field: PortalContentField, value: any) => {
        setDataSelection((prevState) => new DataSelection({...prevState, [field]: value}));
    };

    const handleChangeContent = (field: DataSelectionContentField, value: any) => {
        handleChange(PortalContentField.CONTENT, new DataSelectionContent({
            ...dataSelection.content,
            [field]: value
        }));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            dataSelection.user_id = session.userId;
            dataSelection.service = ApiService.AUDIENCE.name;
            dataSelection.string_identifier = slugify(dataSelection.title);
            dataSelection.start_date = Formatter.convertDateToUTC(dataSelection.start_date);
            if (dataSelection.end_date) {
                dataSelection.end_date = Formatter.convertDateToUTC(dataSelection.end_date);
            }
            const newDataSelection = await session.restPortalContent.create(DataSelection, dataSelection);
            navigate(newDataSelection.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("data selection", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textAudienceDataSelections("create_data_selection")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textPortalContent(`field.${PortalContentField.TITLE}`)} required>
                            <InputText
                                value={dataSelection.title}
                                onChange={(value) => handleChange(PortalContentField.TITLE, value)}
                            />
                        </FieldBlock>
                        <FormLayoutColumns>
                            <FieldBlock label={textPortalContent(`field.${PortalContentField.START_DATE}`)} required>
                                <InputDate
                                    type={InputDateType.DATETIME_LOCAL}
                                    value={dataSelection.start_date}
                                    onChange={(value) => handleChange(PortalContentField.START_DATE, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textPortalContent(`field.${PortalContentField.END_DATE}`)}>
                                <InputDate
                                    type={InputDateType.DATETIME_LOCAL}
                                    value={dataSelection.end_date}
                                    onChange={(value) => handleChange(PortalContentField.END_DATE, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textAudienceDataSelections(`field.${DataSelectionContentField.THEME}`)} required>
                            <RadioButtons
                                id={DataSelectionContentField.THEME}
                                value={dataSelection.content.theme}
                                options={DATA_SELECTION_THEMES.map((it) => ({value: it, label: textAudienceDataSelections(`theme.${it}`)}))}
                                onChange={(value) => handleChangeContent(DataSelectionContentField.THEME, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateDataSelection;
