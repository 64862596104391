import {ApiModel} from "../../../../common/api/model/ApiModel";
import {PartnerOrganization} from "./PartnerOrganization";
import {PartnerOrganizationGroup} from "./PartnerOrganizationGroup";
import {PartnerOrganizationRequestDetails} from "./PartnerOrganizationRequestDetails";

export class PartnerOrganizationRequestValidation extends ApiModel {
    existing_organization: PartnerOrganization | undefined;
    organization: PartnerOrganization | undefined;
    organization_group?: PartnerOrganizationGroup;
    associate_email_domain_to_organization: boolean = false;
    associate_email_domain_to_organization_group: boolean = false;
    partner_ids: number[] = [];

    static fromRequestDetails(details: PartnerOrganizationRequestDetails): PartnerOrganizationRequestValidation {
        const requestValidation = new PartnerOrganizationRequestValidation();
        if (details.existing_organization?.name) {
            requestValidation.existing_organization = new PartnerOrganization(details.existing_organization);
        } else if (details.organization?.name) {
            requestValidation.organization = new PartnerOrganization({...details.organization, group_id: details.organization_group?.id});
        }
        if (details.organization_group?.name) {
            requestValidation.organization_group = new PartnerOrganizationGroup(details.organization_group);
        }
        return requestValidation;
    }

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            organization: this.organization?.getJsonParameters(),
            organization_group: this.organization_group?.id ? undefined : this.organization_group?.getJsonParameters(),
            associate_email_domain_to_organization: this.associate_email_domain_to_organization,
            associate_email_domain_to_organization_group: this.associate_email_domain_to_organization_group,
            partner_ids: this.partner_ids
        };
    }
}
