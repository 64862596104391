export const escapeHtml = (value: string) => {
    return String(value).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;");
};

export const formatPercentage = (value?: number): string => {
    if (value === undefined) return "";
    return `${value}%`;
};

export const isBase64 = (value: string): boolean =>
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/.test(value);


export const isValidDomain = (domain: string): boolean => {
    const regex = new RegExp("^[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,6}$", "i");
    return regex.test(domain);
};
