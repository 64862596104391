import {ApiModel} from "../../common/api/model/ApiModel";
import {CmpPurposeType} from "./CmpPurposeType";
import {Purpose} from "../../api/model/cmp/list/global-vendor-list/Purpose";
import {CmpConfigPublisherCustomPurpose} from "../../api/model/cmp/config/publisher/CmpConfigPublisherCustomPurpose";

export class CmpPurpose extends ApiModel {
    id: number = 0;
    name: string = "";
    type: CmpPurposeType = CmpPurposeType.PURPOSE;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static create(purpose: Purpose | CmpConfigPublisherCustomPurpose, type: CmpPurposeType): CmpPurpose {
        const cmpPurpose = new CmpPurpose();
        cmpPurpose.id = purpose.id;
        cmpPurpose.name = purpose.name;
        cmpPurpose.type = type;
        return cmpPurpose;
    }
}
