import React, {FunctionComponent} from "react";
import {CountryCode} from "../../common/api/model/Country";
import {CountryIsoCode} from "../../common/api/model/CountryIsoCode";
import {TableImage} from "@sirdata/ui-lib";

export type CountryFlagProps = {
    code: CountryIsoCode;
};

const CountryFlag: FunctionComponent<CountryFlagProps> = ({code}) => {
    const country = CountryCode.getCountryFromCode(code);
    if (!country) {
        return <></>;
    }

    return (
        <>
            <TableImage image={country.flagUrl}/>
            <span>{country.name}</span>
        </>
    );
};

export default CountryFlag;
