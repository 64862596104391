import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Status} from "../../../../utils/Status";

export const DEFAULT_EXPIRATION_DAY = 30;
export const DEFAULT_POINTS = 1000;
export const MIN_VALUE_POINTS = 0;
export const MAX_VALUE_POINTS = 1000;
export const STEP_VALUE_POINTS = 100;

const DEFAULT_REQUEST = `SELECT user_id, TIMESTAMP_TO_SEC(max(ts)) as ts
FROM TABLE_DATE_RANGE(raw_data.daily_,
    TIMESTAMP('{tableStart}'),
    TIMESTAMP('{tableEnd}'))
WHERE (
    data=TRUE
)
AND (


)
group each by user_id`;

export class CategoryBQRequest extends ApiModel {
    id: number = 0;
    active: boolean = true;
    category_id: number = 0;
    name: string = "";
    request: string = DEFAULT_REQUEST;
    points: number = DEFAULT_POINTS;
    expiration_day: number = DEFAULT_EXPIRATION_DAY;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            active: this.active,
            category_id: this.category_id,
            name: this.name,
            request: this.request,
            points: this.points,
            expiration_day: this.expiration_day
        };
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }
}
