import {Action, ContentBlock, ContentBlockAction, Loadable, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FC, RefObject, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {CategoryLinkSegmentRow} from "..";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryLinkSegment} from "../../../api/model/audience/category/CategoryLinkSegment";
import {CategoryLinkSegmentField} from "../../../api/model/audience/category/CategoryLinkSegmentField";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {copyApiModelArray} from "../../../common/utils/helper";
import {detectChanges} from "../../../common/utils/portal";
import {HandleSaveRef} from "../../../utils/audience/HandleSaveRef";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import ModalEditCategoryLinkedSegments from "../../modal/categories/ModalEditCategoryLinkedSegments";
import ModalConfirmRemove from "../../modal/ModalConfirmRemove";
import {CategoriesDetailsSubModule} from "../../../screen/categories/CategoriesDetails";

type CategorySegmentLinksProps = {
    category: Category;
    handleSaveRef: RefObject<HandleSaveRef>;
    onChange: (module: CategoriesDetailsSubModule, hasChanges: boolean) => void;
};

const CategorySegmentLinks: FC<CategorySegmentLinksProps> = ({category, handleSaveRef, onChange}) => {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [segmentLinks, setSegmentLinks] = useState<CategoryLinkSegment[]>([]);
    const [initSegmentLinks, setInitSegmentLinks] = useState<CategoryLinkSegment[]>([]);
    const [isShowModalEditCategoryLinksSegment, setShowModalEditCategoryLinksSegment] = useState(false);
    const [linkSegmentToRemove, setLinkSegmentToRemove] = useState<CategoryLinkSegment>();

    useEffect(() => {
        if (!category.id) return;
        (async () => {
            try {
                const newSegmentLinks = await session.restCategoryLinkSegment.list(category.id);
                setSegmentLinks(newSegmentLinks);
                setInitSegmentLinks(copyApiModelArray(CategoryLinkSegment, newSegmentLinks));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("linked segments", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [category.id, alert]);

    useEffect(() => {
        setSegmentLinks(category.active ? [...initSegmentLinks] : []);
    }, [category.active, initSegmentLinks]);

    useEffect(() => {
        onChange(CategoriesDetailsSubModule.LINKS_SEGMENTS, detectChanges(segmentLinks, initSegmentLinks));
    }, [onChange, segmentLinks, initSegmentLinks]);

    useImperativeHandle(handleSaveRef, () => ({
        handleSave: async () => {
            setLoading(true);
            try {
                await session.restCategoryLinkSegment.updateLinksSegments(category.id, segmentLinks);
                setInitSegmentLinks(copyApiModelArray(CategoryLinkSegment, segmentLinks));
                alert.updateWithSuccess("linked segments");
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToUpdate("linked segments", e.message);
                }
            } finally {
                setLoading(false);
            }
        }
    }));

    const handleRemoveSegmentLink = () => {
        if (!linkSegmentToRemove) return;
        setSegmentLinks((prevState) => prevState.filter((it) => it.segment_id !== linkSegmentToRemove.segment_id));
        setLinkSegmentToRemove(undefined);
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{
                    title: {label: textCategories("section.linked_segments")}, actions: [
                        <RestrictedContent key="add_category_link_segment" allowedTo={Authorization.CATEGORIES.update}>
                            {category.active &&
                                <ContentBlockAction action={Action.EDIT} onClick={() => setShowModalEditCategoryLinksSegment(true)}/>
                            }
                        </RestrictedContent>
                    ]
                }}
            >
                <Table
                    columns={[
                        {width: 5, label: textCategories(`field.link_segment.${CategoryLinkSegmentField.SEGMENT_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 10, label: textCategories(`field.link_segment.${CategoryLinkSegmentField.DATA_TYPE}`)},
                        {width: 55, label: textCategories(`field.link_segment.${CategoryLinkSegmentField.SEGMENT_NAME}`)},
                        {width: 10, label: textCategories(`field.link_segment.${CategoryLinkSegmentField.MIN_SCORE}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 10, label: textCategories(`field.link_segment.${CategoryLinkSegmentField.EXPIRATION_TIME}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 5}
                    ]}
                >
                    {segmentLinks.map((link) =>
                        <CategoryLinkSegmentRow key={link.segment_id} link={link} onRemove={() => setLinkSegmentToRemove(link)}/>
                    )}
                </Table>
                <ModalEditCategoryLinkedSegments
                    active={isShowModalEditCategoryLinksSegment}
                    categoryId={category.id}
                    categoryType={CategoryType.getByName(category.type)}
                    currentLinks={segmentLinks}
                    onSubmit={(links) => {
                        setSegmentLinks(links);
                        setShowModalEditCategoryLinksSegment(false);
                    }}
                    onClose={() => setShowModalEditCategoryLinksSegment(false)}
                />
                <ModalConfirmRemove
                    active={!!linkSegmentToRemove}
                    entity={`segment "${linkSegmentToRemove?.segment_name}"`}
                    confirm={handleRemoveSegmentLink}
                    cancel={() => setLinkSegmentToRemove(undefined)}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default CategorySegmentLinks;
