import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Module} from "../../../../utils/Module";
import {ContentElement} from "../../../interface/ContentElement";
import {DataleaksRegulation} from "../DataleaksRegulation";
import {DataleaksVersionField} from "./DataleaksVersionField";
import {DataleaksVersionQuestion} from "./DataleaksVersionQuestion";
import {DataleaksVersionSeveritiesWeights} from "./DataleaksVersionSeveritiesWeights";
import {DataleaksSeverity} from "../DataleaksSeverity";

export class DataleaksVersion extends ApiModel {
    id: number = 0;
    comment: string = "";
    _regulation_weights: Map<DataleaksRegulation, DataleaksVersionSeveritiesWeights> = new Map<DataleaksRegulation, DataleaksVersionSeveritiesWeights>();
    questions: DataleaksVersionQuestion[] = [];
    readonly created_at: string = "";
    readonly updated_at: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                switch (key) {
                    case DataleaksVersionField.QUESTIONS:
                        if (!o[key]) {
                            this[key] = [];
                            break;
                        }
                        this[key] = Object.values(o[key]).map((it) => new DataleaksVersionQuestion(it!));
                        break;
                    default:
                        this[key] = o[key];
                }
            }
        }
    }

    get label() : string {
        return `v${this.id}`;
    }

    get regulation_weights(): Map<DataleaksRegulation, DataleaksVersionSeveritiesWeights> {
        return this._regulation_weights;
    }

    set regulation_weights(regulationWeights: Map<DataleaksRegulation, DataleaksVersionSeveritiesWeights>) {
        if (regulationWeights instanceof Map || !regulationWeights) {
            this._regulation_weights = regulationWeights;
            return;
        }

        const entries = Object.entries<Map<DataleaksSeverity, number>>(regulationWeights);
        const mapRegulationWeights = new Map<DataleaksRegulation, DataleaksVersionSeveritiesWeights>();
        if (entries.length) {
            entries.forEach(([regulation, mapSeverities]) => {
                const severityWeights = new DataleaksVersionSeveritiesWeights();
                Object.entries(mapSeverities).forEach(([severity, weight]) => {
                    severityWeights.setWeight(severity as DataleaksSeverity, weight as number);
                });
                mapRegulationWeights.set(regulation as DataleaksRegulation, severityWeights);
            });
        }
        this._regulation_weights = mapRegulationWeights;
    }

    getSeveritiesWeightsForRegulation(regulation: DataleaksRegulation): DataleaksVersionSeveritiesWeights {
        return this._regulation_weights.get(regulation) || new DataleaksVersionSeveritiesWeights();
    }

    getRegulationWeightsJson(): { [key: string]: { [key: string]: number } } {
        let t: { [key: string]: { [key: string]: number } } = {};
        this._regulation_weights.forEach((severityWeights, regulation) => {
            t[regulation] = severityWeights;
        });
        return t;
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            comment: this.comment,
            regulation_weights: this.getRegulationWeightsJson(),
            questions: this.questions
        };
    }

    getRoute(): string {
        return Module.DATALEAKS_VERSIONS.buildRoute(this.id);
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.label);
        if (this.created_at) {
            element.createdAt = new Date(this.created_at);
        }
        if (this.updated_at) {
            element.lastUpdated = new Date(this.updated_at);
        }
        return element;
    }
}
