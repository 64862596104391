import {QueryString} from "../../../../common/api/model/QueryString";
import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";
import {CategoryGroupField} from "./CategoryGroupField";

export enum CategoryGroupSearchParamsField {
    TAXONOMY = "taxonomy",
    TIER1 = "tier1",
}

export class CategoryGroupSearchQuery extends SearchQuery {
    perPage: number = PAGE_SIZE;
    taxonomy?: string;
    type?: string;
    tier1?: string;

    withSearchParams(params: SearchParams): CategoryGroupSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(CategoryGroupField.ID, true) as CategoryGroupSearchQuery;
        newQuery.taxonomy = params.getString(CategoryGroupSearchParamsField.TAXONOMY);
        newQuery.type = params.getString(SearchParamsField.TYPE);
        newQuery.tier1 = params.getString(CategoryGroupSearchParamsField.TIER1);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            search: this.query,
            predicate: this.sortBy,
            reverse: this.reverseOrder,
            page: this.page,
            size: this.perPage,
            taxonomy: this.taxonomy,
            type: this.type,
            tier1: this.tier1
        };
    }

    toQueryString(): string {
        return QueryString.build({
            query: this.query,
            taxonomy: this.taxonomy,
            type: this.type,
            tier1: this.tier1
        });
    }

}
