import React, {FunctionComponent} from "react";
import {Action, Tag as LibTag, TagSize, TagStyle} from "@sirdata/ui-lib";

type TagActionProps = {
    value: string;
    active?: boolean;
    onSelect?: () => void;
    onDelete?: () => void;
};

const TagWithAction: FunctionComponent<TagActionProps> = ({value, active, onSelect, onDelete}) => {
    return (
        <>
            {onDelete ?
                <LibTag
                    size={TagSize.XSMALL}
                    style={active ? TagStyle.PRIMARY_MIDNIGHT_LIGHT : TagStyle.DEFAULT_MIDNIGHT_LIGHT}
                    label={value}
                    icon={{...Action.REMOVE.icon, onClick: onDelete}}
                    reverse={true}
                /> :
                <LibTag
                    size={TagSize.XSMALL}
                    style={active ? TagStyle.PRIMARY_MIDNIGHT_LIGHT : TagStyle.DEFAULT_MIDNIGHT_LIGHT}
                    label={value}
                    onClick={onSelect}
                />
            }
        </>
    );
};

export default TagWithAction;
