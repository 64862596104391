import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksVersion} from "../../../api/model/dataleaks/version/DataleaksVersion";
import {DataleaksAudit} from "../../../api/model/dataleaks/audit/DataleaksAudit";
import {DataleaksAuditReportQuestion} from "../index";
import {FlexContent, FlexContentDirection, FlexContentSpacing} from "@sirdata/ui-lib";
import {DataleaksMechanism} from "../../../api/model/dataleaks/DataleaksMechanism";
import {Locale} from "../../../common/utils/Locale";

type DataleaksAuditReportQuestionsProps = {
    audit: DataleaksAudit;
    version: DataleaksVersion;
    questions: DataleaksQuestion[];
    locale: Locale;
}

const DataleaksAuditReportQuestions: FunctionComponent<DataleaksAuditReportQuestionsProps> = ({audit, version, questions, locale}) => {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS, {lng: locale.toString()});
    const {t: textDataleaksAuditsReport} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS_REPORT, {lng: locale.toString()});

    return (
        <div className="dataleaks__report__section">
            <div className="dataleaks__report__section__title">{textDataleaksAuditsReport("section.questions")}</div>
            <div className="dataleaks__report__questions">
                <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XLARGE}>
                    <div className="dataleaks__report__website-info">
                        <span className="h3 dataleaks__report__website-info__name">{textDataleaksAuditsReport("field.mechanism")}</span>
                        <span className="dataleaks__report__website-info__value">{textDataleaksAudits(`mechanism.${audit.infos.mechanism}`)}</span>
                    </div>
                    {audit.infos.mechanism === DataleaksMechanism.TCF_CMP &&
                        <div className="dataleaks__report__website-info">
                            <span className="h3 dataleaks__report__website-info__name">{textDataleaksAuditsReport("field.cmp")}</span>
                            <span className="dataleaks__report__website-info__value">{audit.infos.cmp_name}</span>
                        </div>
                    }
                    {audit.infos.mechanism === DataleaksMechanism.TCF_CMP && audit.infos.scope &&
                        <div className="dataleaks__report__website-info">
                            <span className="h3 dataleaks__report__website-info__name">{textDataleaksAuditsReport("field.scope")}</span>
                            <span className="dataleaks__report__website-info__value">{textDataleaksAudits(`scope.${audit.infos.scope}`)}</span>
                        </div>
                    }
                </FlexContent>
                <hr/>
                {version.questions.map((item) =>
                    <DataleaksAuditReportQuestion
                        key={item.question_id}
                        question={questions.find((it) => it.id === item.question_id) || new DataleaksQuestion()}
                        answer={audit.answers.get(item.question_id)}
                        locale={locale}
                    />
                )}
            </div>
        </div>
    );
};

export default DataleaksAuditReportQuestions;
