export enum HelperPrivacyConfigurationUrl {
    NO_ALTER = "NO_ALTER",
    REMOVE_CLICK_ID_AND_UTM = "REMOVE_CLICK_ID_AND_UTM",
    REMOVE_ALL_PARAMETERS = "REMOVE_ALL_PARAMETERS"
}

export const PRIVACY_CONFIGURATION_URL_VALUES = [
    HelperPrivacyConfigurationUrl.NO_ALTER,
    HelperPrivacyConfigurationUrl.REMOVE_CLICK_ID_AND_UTM,
    HelperPrivacyConfigurationUrl.REMOVE_ALL_PARAMETERS
];
