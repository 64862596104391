import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Status} from "../../../../utils/Status";
import {AdsAccessConfiguration} from "./AdsAccessConfiguration";
import {AdsAccessField} from "./AdsAccessField";
import {Module} from "../../../../utils/Module";
import {ContentElement} from "../../../interface/ContentElement";

export class AdsAccess extends ApiModel {
    id: number = 0;
    name: string = "";
    partner_id: number = 0;
    salt?: string;
    access_end?: string;
    consent_outside_tcf: boolean = false;
    collect_data: boolean = false;
    public_taxonomy: boolean = false;
    feature_access: string[] = [];
    active: boolean = true;
    configuration: AdsAccessConfiguration = new AdsAccessConfiguration();
    creation_date?: string;
    last_update?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === AdsAccessField.CONFIGURATION) {
                    this[key] = new AdsAccessConfiguration(o[key]);
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            name: this.name,
            partner_id: this.partner_id,
            salt: this.salt,
            access_end: this.access_end,
            consent_outside_tcf: this.consent_outside_tcf,
            collect_data: this.collect_data,
            public_taxonomy: this.public_taxonomy,
            feature_access: this.feature_access,
            active: this.active,
            configuration: this.configuration.getJsonParameters(),
            creation_date: this.creation_date,
            last_update: this.last_update
        };
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }

    getRoute(): string {
        return Module.ADS.buildRoute(this.id);
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        element.status = this.getStatus();

        if (this.creation_date) {
            element.createdAt = new Date(this.creation_date);
        }
        if (this.last_update) {
            element.lastUpdated = new Date(this.last_update);
        }
        return element;
    }
}
