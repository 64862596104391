import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DataSelectionTheme} from "./DataSelectionTheme";

export class DataSelectionContent extends ApiModel {
    name_fr: string = "";
    name_en: string = "";
    description_fr: string = "";
    description_en: string = "";
    description: string = "";
    main_image_id: number | undefined;
    preview_image_id: number | undefined;
    theme: DataSelectionTheme = DataSelectionTheme.SPECIAL_EVENTS;
    tags: string[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            name_fr: this.name_fr,
            name_en: this.name_en,
            description_fr: this.description_fr,
            description_en: this.description_en,
            description: this.description,
            main_image_id: this.main_image_id,
            preview_image_id: this.preview_image_id,
            theme: this.theme,
            tags: this.tags
        };
    }
}
