import {Rest} from "../../common/api/rest/Rest";
import {CategoryLinkSegment} from "../model/audience/category/CategoryLinkSegment";

export class RestCategoryLinkSegment extends Rest {
    rootPath = "/console-api/category";

    list(categoryId: number): Promise<CategoryLinkSegment[]> {
        return this._client.get(`${this.rootPath}/${categoryId}/segments`, CategoryLinkSegment) as Promise<CategoryLinkSegment[]>;
    }

    updateLinksSegments(categoryId: number, links: CategoryLinkSegment[]): Promise<void> {
        return this._client.post(`${this.rootPath}/${categoryId}/segments`, links);
    }
}
