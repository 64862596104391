import {Box, BoxRadius, Color, ContentBlock, Icon, Loadable} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile} from "../../utils/constants";
import {onClickLink} from "../../utils/helper";
import {Module} from "../../utils/Module";

export type ModuleGroup = {
    label: string;
    color?: Color;
    modules: Module[];
}

export const MODULES_GROUPS: ModuleGroup[] = [
    {label: "audience", color: Color.CYAN, modules: [
        Module.CATEGORIES,
        Module.KEYWORDS,
        Module.SEGMENTS,
        Module.DISTRIBUTIONS,
        Module.DISTRIBUTIONS_LICENSES,
        Module.DEALS,
        Module.AUDIENCE_DATA_SELECTIONS,
        Module.CATEGORIZER,
        Module.TAXONOMY_TAGS,
        Module.ADS
    ]},
    {label: "cmp", color: Color.PURPLE, modules: [
        Module.CMP_CONFIGURATIONS,
        Module.CMP_GLOBAL_VENDOR_LIST,
        Module.CMP_GOOGLE_AC_PROVIDER_LIST,
        Module.CMP_SIRDATA_LIST,
        Module.CMP_NETWORKS,
        Module.CMP_CONSENT_MANAGEMENT_PLATFORMS,
        Module.CMP_VERSIONS,
        Module.CMP_WEBINARS,
        Module.CMP_TRANSLATIONS,
        Module.DATALEAKS
    ]},
    {label: "customer", color: Color.BROWN_LIGHT, modules: [Module.CUSTOMER_STORAGES, Module.CUSTOMER_COUNTINGS, Module.CUSTOMER_ORDERS]},
    {label: "helper", color: Color.RUST_LIGHT, modules: [Module.HELPER_DOMAINS]},
    {label: "partners", color: Color.RASPBERRY_LIGHT, modules: [Module.PARTNERS, Module.PARTNER_DOMAINS, Module.ORGANIZATION_GROUPS, Module.ORGANIZATIONS, Module.LICENSES, Module.PRICINGS]},
    {label: "revenues", color: Color.YELLOW, modules: [Module.REVENUES_IMPORTS, Module.SEATS]},
    {label: "settings", color: Color.OCEAN, modules: [Module.AUTHORIZATIONS_PARTNER, Module.AUTHORIZATIONS_USER, Module.USER_PROFILES, Module.USERS]},
    {label: "sgtm", color: Color.RUST, modules: [Module.SGTM_CONTAINERS]},
    {label: "web_content", color: Color.VIOLINE_LIGHT, modules: [Module.MEDIAS, Module.NOTIFICATIONS, Module.PORTAL_SETTINGS]},
    {label: "other", color: Color.MIDNIGHT_LIGHT, modules: [Module.API_DOCUMENTATIONS]}
];

type ModulesProps = {
    onChange?: () => void;
}

const Modules: FunctionComponent<ModulesProps> = ({onChange}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [moduleGroups, setModuleGroups] = useState<ModuleGroup[]>([]);

    useEffect(() => {
        (async function () {
            try {
                const account = await session.getAccount();
                const moduleGroups = MODULES_GROUPS.map((group) => {
                    group.modules = group.modules.filter(({authorization}: Module) => !authorization || account.hasAuthorization(authorization.name));
                    return group;
                });
                setModuleGroups(moduleGroups);
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <Loadable loading={isLoading}>
            <ContentBlock header={{title: {label: t("modules.title").toUpperCase()}}} cssClass={"modules"}>
                <div className="modules__content">
                    {moduleGroups.filter((it) => !!it.modules.length).map((group) =>
                        <Box key={group.label} radius={BoxRadius.LG} cssClass="modules__group">
                            <span className="modules__group__title" style={{color: group.color}}>
                                <Icon name="circle" colorIcon={group.color}/>
                                <span>{t(`menu.groups.${group.label}`)}</span>
                            </span>
                            <ul className="modules__group__items">
                                {group.modules.map((item) =>
                                    <li
                                        key={item.label}
                                        onClick={(e) => {
                                            !!onChange && onChange();
                                            onClickLink(e, item.path, navigate);
                                        }}
                                    >
                                        <span>{t(`menu.${item.label}`)}</span>
                                    </li>
                                )}
                            </ul>
                        </Box>
                    )}
                </div>
            </ContentBlock>
        </Loadable>
    );
};

export default Modules;
