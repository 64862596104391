import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Deal} from "../../api/model/deal/Deal";
import {DealSearchQuery} from "../../api/model/deal/DealSearchQuery";
import {DealSearchResult} from "../../api/model/deal/DealSearchResult";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreateDeal from "../../component/modal/deals/ModalCreateDeal";
import {DealRow, MainContentHeader, MainContentHeaderAction, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import useSearch from "../../utils/hooks/useSearch";
import {DealField} from "../../api/model/deal/DealField";
import useAlert from "../../utils/hooks/useAlert";
import {DealTiersField} from "../../api/model/deal/DealTiersField";
import {Status} from "../../utils/Status";

function Deals() {
    const {t: textDeals} = useTranslation(TranslationPortalFile.DEALS);
    const [isLoading, setLoading] = useState(true);
    const alert = useAlert();
    const {setSearchResult, ...search} = useSearch(Deal, DealSearchQuery, DealSearchResult);
    const [isShowModalCreateDeal, setShowModalCreateDeal] = useState(false);

    useEffect(() => {
        (async function () {
            try {
                const newSearchResult = await session.restDeal.search(search.searchQuery);
                newSearchResult.page = newSearchResult.page - 1;
                setSearchResult(newSearchResult);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("deals", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [search.searchQuery, setSearchResult, alert]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.DEALS}>
                <RestrictedContent allowedTo={Authorization.DEALS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateDeal(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textDeals("search.placeholder"), value: search.searchQuery.query || "", onSubmit: search.changeQuery}}
                            canHideFilters={true}
                        >
                            <SearchField label={textDeals("field.status")}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textDeals("field.status"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textDeals(`field.${DealField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 50, label: textDeals(`field.${DealField.NAME}`), sort: {field: DealField.NAME}},
                                        {width: 35, label: textDeals(`field.${DealField.TIERS}.${DealTiersField.TIERS1}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((deal: Deal) =>
                                        <DealRow key={deal.id} deal={deal}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateDeal active={isShowModalCreateDeal} onClose={() => setShowModalCreateDeal(false)}/>
            </MainContent>
        </Wrapper>
    );
}

export default Deals;
