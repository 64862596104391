import {
    Action,
    ActionsMenu,
    AlertSeverity,
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    Slider,
    Table,
    TableActionColumn,
    TableColumn,
    TableColumnStyle,
    TableRow,
    ToggleSwitch,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigEnrichment} from "../../../api/model/categorizer/config/CategorizerConfigEnrichment";
import {CategorizerConfigEnrichmentField} from "../../../api/model/categorizer/config/CategorizerConfigEnrichmentField";
import {CategorizerConfigEnrichmentMode} from "../../../api/model/categorizer/config/CategorizerConfigEnrichmentMode";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {CategorizerConfigEntryInfo, SelectCategory, TagCategoryType} from "../../snippet";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {CategorizerConfigEntryParams} from "../../../api/model/categorizer/CategorizerConfigEntryParams";

type ModalAddCategorizerConfigEnrichmentProps = {
    params: CategorizerConfigEntryParams;
    active: boolean;
    onSubmit: () => void;
    onClose: () => void;
};

const ModalAddCategorizerConfigEnrichment: FunctionComponent<ModalAddCategorizerConfigEnrichmentProps> = ({params, active, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const [isLoading, setLoading] = useState(true);
    const [entry, setEntry] = useState<CategorizerConfigEntry<CategorizerConfigEnrichment>>(CategorizerConfigEntry.forType(CategorizerConfigType.ENRICHMENT, params));
    const [categories, setCategories] = useState<Category[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-add-categorizer-config-enrichment";
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<CategorizerConfigEnrichmentField>();

    useEffect(() => {
        if (!active || categories.length) return;
        (async () => {
            try {
                setCategories(await session.getCategories());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("categories", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [active, categories.length, alert]);

    useEffect(() => {
        if (active) {
            setEntry(CategorizerConfigEntry.forType(CategorizerConfigType.ENRICHMENT, params));
            setShowErrors(false);
        }
    }, [active, params, setShowErrors]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [CategorizerConfigEnrichmentField.POINTS]: !entry.config.points.size
        }));
    }, [setErrors, entry.config.points]);

    const handleChange = (field: CategorizerConfigEntryField, value: any) => {
        setEntry((prevState) => new CategorizerConfigEntry<CategorizerConfigEnrichment>({...prevState, [field]: value}));
    };

    const handleChangeConfig = (field: CategorizerConfigEnrichmentField, value: any) => {
        handleChange(CategorizerConfigEntryField.CONFIG, new CategorizerConfigEnrichment({...entry.config, [field]: value}));
    };

    const handleChangePoints = (category: Category, points: number) => {
        const newEntry = new CategorizerConfigEntry<CategorizerConfigEnrichment>({...entry});
        const newConfigPoints = new Map(newEntry.config.points);
        const newConfigPointsGroup = new Map(newConfigPoints.get(category.id_group.toString()));
        newConfigPointsGroup.set(category.id.toString(), points);
        newConfigPoints.set(category.id_group.toString(), newConfigPointsGroup);
        handleChangeConfig(CategorizerConfigEnrichmentField.POINTS, newConfigPoints);
    };

    const handleAddCategory = (category: Category) => {
        handleChangePoints(category, CategorizerConfigEnrichment.MAX_POINTS_VALUE);
    };

    const handleRemoveCategory = (category: Category) => {
        const newEntry = new CategorizerConfigEntry<CategorizerConfigEnrichment>({...entry});
        const newConfigPoints = new Map(newEntry.config.points);
        const newConfigPointsGroup = new Map(newConfigPoints.get(category.id_group.toString()));
        newConfigPointsGroup.delete(category.id.toString());
        if (newConfigPointsGroup.size) {
            newConfigPoints.set(category.id_group.toString(), newConfigPointsGroup);
        } else {
            newConfigPoints.delete(category.id_group.toString());
        }
        handleChangeConfig(CategorizerConfigEnrichmentField.POINTS, newConfigPoints);
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        setSubmitting(true);
        try {
            await session.restCategorizerEntry.create(entry);
            alert.createWithSuccess(`${textCategorizer(`config_type.${CategorizerConfigType.ENRICHMENT.name}`)} configuration`);
            onSubmit();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate(`${textCategorizer(`config_type.${CategorizerConfigType.ENRICHMENT.name}`)} configuration`, e.message);
            }
        } finally {
            setSubmitting(false);
            setShowErrors(false);
        }
    };

    const getPointCategories = () => {
        const pointCategories: Category[] = [];
        Array.from(entry.config.points.values()).forEach((mapCategories) =>
            Array.from(mapCategories.keys()).forEach((categoryId) => {
                const category = categories.find((it) => it.id === +categoryId);
                if (category) {
                    pointCategories.push(category);
                }
            })
        );
        return pointCategories;
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategorizer("modal.add_new_configuration", {type: textCategorizer(`config_type.${CategorizerConfigType.ENRICHMENT.name}`)})}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <CategorizerConfigEntryInfo currentEntry={entry}/>
                        <FormLayoutColumns>
                            <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigEnrichmentField.MODE}`)} required>
                                <Select
                                    value={entry.config.mode}
                                    options={CategorizerConfigEnrichmentMode.values().map((mode) => ({label: textCategorizer(`enrichment_mode.${mode.name}`), value: mode.name}))}
                                    onChange={(option) => handleChangeConfig(CategorizerConfigEnrichmentField.MODE, `${option?.value || ""}`)}
                                />
                            </FieldBlock>
                            <FieldBlock
                                label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigEnrichmentField.PROPAGATE_POINTS}`)}
                                name={CategorizerConfigEnrichmentField.PROPAGATE_POINTS}
                            >
                                <ToggleSwitch
                                    checked={entry.config.propagate_points}
                                    onChange={(checked) => handleChangeConfig(CategorizerConfigEnrichmentField.PROPAGATE_POINTS, checked)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                            <FieldBlock
                                label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigEnrichmentField.POINTS}`)}
                                content={{direction: FlexContentDirection.COLUMN}}
                            >
                                <SelectCategory
                                    value={undefined}
                                    onChange={(category) => category && handleAddCategory(category)}
                                    isLoading={isLoading}
                                    excludedCategories={getPointCategories()}
                                    keepMenuOpen
                                    keepSearchAfterSelect
                                />
                                {formValidator.isError(CategorizerConfigEnrichmentField.POINTS) &&
                                    <FormLayoutMessage message={textCategorizer("message.category_required")} small severity={AlertSeverity.DANGER}/>
                                }
                            </FieldBlock>
                            {!!getPointCategories().length &&
                                <Table
                                    columns={[
                                        {width: 45, label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.category`)},
                                        {width: 20, label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.category_type`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 30, label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigEnrichmentField.POINTS}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5}
                                    ]}
                                >
                                    {getPointCategories().map((category) =>
                                        <TableRow key={category.id}>
                                            <TableColumn>{category.fullName}</TableColumn>
                                            <TableColumn><TagCategoryType type={CategoryType.getByName(category.type)}/></TableColumn>
                                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                                <Slider
                                                    value={entry.config.points.get(category.id_group.toString())?.get(category.id.toString()) || 0}
                                                    min={CategorizerConfigEnrichment.MIN_POINTS_VALUE}
                                                    max={CategorizerConfigEnrichment.MAX_POINTS_VALUE}
                                                    step={CategorizerConfigEnrichment.POINT_STEP}
                                                    onChange={(value) => handleChangePoints(category, value)}
                                                />
                                            </TableColumn>
                                            <TableActionColumn>
                                                <ActionsMenu
                                                    iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                                    items={[
                                                        {label: textCommon(Action.REMOVE.labelKey), critical: true, separator: true, onClick: () => handleRemoveCategory(category)}
                                                    ]}
                                                />
                                            </TableActionColumn>
                                        </TableRow>
                                    )}
                                </Table>
                            }
                        </FormLayoutRows>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddCategorizerConfigEnrichment;
