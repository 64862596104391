import {ApiModel} from "../../../common/api/model/ApiModel";

export class PartnerSite extends ApiModel {
    site_id: number = 1;
    partner_id: number = 0;
    name: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            site_id: this.site_id,
            partner_id: this.partner_id,
            name: this.name
        };
    }
}

