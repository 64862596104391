import React, {FunctionComponent} from "react";
import {ColorPicker, FlexContent, FlexContentDirection, FlexContentLayout, FlexContentSpacing, IconTooltip} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigTheme} from "../../../api/model/cmp/config/theme/CmpConfigTheme";
import {CmpConfigThemeModeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";

type CmpConfigurationThemeColorsProps = {
    theme: CmpConfigTheme;
    field: CmpConfigThemeModeField;
    onChange: (mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE, field: CmpConfigThemeModeField, value: string) => void;
}

const CmpConfigurationThemeColors: FunctionComponent<CmpConfigurationThemeColorsProps> = ({theme, field, onChange}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            layout={FlexContentLayout.COLUMNS}
            spacing={FlexContentSpacing.MEDIUM}
            cssClass="theme-colors"
        >
            <div className="theme-colors__mode">
                <IconTooltip
                    icon={{name: "wb_sunny", cssClass: "theme-colors__mode__icon"}}
                    text={textCmpConfigurations("theme_mode.light_default")}
                />
                <ColorPicker
                    value={theme?.lightMode[field] || ""}
                    onChange={(value) => onChange(CmpConfigThemeField.LIGHT_MODE, field, value)}
                />
            </div>
            <div className="theme-colors__mode">
                <IconTooltip
                    icon={{name: "brightness_2", cssClass: "theme-colors__mode__icon theme-colors__mode__icon--dark"}}
                    text={textCmpConfigurations("theme_mode.dark")}
                />
                <ColorPicker
                    value={theme?.darkMode[field] || ""}
                    placeholder={theme?.lightMode[field]}
                    onChange={(value) => onChange(CmpConfigThemeField.DARK_MODE, field, value)}
                />
            </div>
        </FlexContent>
    );
};

export default CmpConfigurationThemeColors;
