import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {TaxonomyTag} from "../model/taxonomy/tag/TaxonomyTag";
import {TaxonomyTagLinkCategoryGroup} from "../model/taxonomy/tag/TaxonomyTagLinkCategoryGroup";
import {TaxonomyTagLinkSegment} from "../model/taxonomy/tag/TaxonomyTagLinkSegment";
import {TaxonomyTagSearchQuery} from "../model/taxonomy/tag/TaxonomyTagSearchQuery";
import {TaxonomyTagSearchResult} from "../model/taxonomy/tag/TaxonomyTagSearchResult";

export class RestTaxonomyTag extends Rest implements RestService<TaxonomyTag> {
    rootPath = "/console-api/taxonomy-tag";

    search(params: TaxonomyTagSearchQuery): Promise<TaxonomyTagSearchResult> {
        return this._client.get(`${this.rootPath}`, TaxonomyTagSearchResult, {queryParams: params.getJsonParameters()}) as Promise<TaxonomyTagSearchResult>;
    }

    create(tag: TaxonomyTag): Promise<TaxonomyTag> {
        return this._client.post(`${this.rootPath}`, tag, TaxonomyTag) as Promise<TaxonomyTag>;
    }

    get(tagName: string): Promise<TaxonomyTag> {
        return this._client.get(`${this.rootPath}/${tagName}`, TaxonomyTag) as Promise<TaxonomyTag>;
    }

    update(tag: TaxonomyTag): Promise<TaxonomyTag> {
        return this._client.post(`${this.rootPath}`, tag, TaxonomyTag) as Promise<TaxonomyTag>;
    }

    delete(tag: TaxonomyTag) {
        return this._client.delete(`${this.rootPath}/${tag.name}`);
    }

    getCategoryGroups(tagName: string): Promise<TaxonomyTagLinkCategoryGroup[]> {
        return this._client.get(`${this.rootPath}/${tagName}/category-group`, TaxonomyTagLinkCategoryGroup) as Promise<TaxonomyTagLinkCategoryGroup[]>;
    }

    getSegments(tagName: string): Promise<TaxonomyTagLinkSegment[]> {
        return this._client.get(`${this.rootPath}/${tagName}/segment`, TaxonomyTagLinkSegment) as Promise<TaxonomyTagLinkSegment[]>;
    }
}
