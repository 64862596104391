import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigLanguage} from "../../../api/model/cmp/config/CmpConfigLanguage";
import {TranslationPortalFile} from "../../../utils/constants";
import clsx from "clsx";
import {FlexContent, FlexContentDirection} from "@sirdata/ui-lib";

type CmpConfigLanguageToolbarProps = {
    value: CmpConfigLanguage;
    onChange: (language: CmpConfigLanguage) => void;
    highlightedLanguages?: CmpConfigLanguage[];
}

const CmpConfigLanguageToolbar: FunctionComponent<CmpConfigLanguageToolbarProps> = ({value, onChange, highlightedLanguages}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    return (
        <FlexContent direction={FlexContentDirection.ROW} allowWrap cssClass="cmp-languages">
            {CmpConfigLanguage.values().map((language) =>
                <div key={language.name} className={clsx("cmp-languages__item", {"cmp-languages__item--active": value === language})} onClick={() => onChange(language)}>
                    <span className="cmp-languages__item__image-container">
                        <img src={`/images/cmp/flags/${language.name}.png`} alt=""/>
                    </span>
                    <span className={clsx("cmp-languages__item__name", {"cmp-languages__item__name--highlighted": highlightedLanguages?.some((it) => it === language)})}>
                        {textCmpConfigurations(`language.${language.name}`)}
                    </span>
                </div>
            )}
        </FlexContent>
    );
};

export default CmpConfigLanguageToolbar;
