import {Rest} from "../../common/api/rest/Rest";

export class RestCategory extends Rest {
    rootPath = "/console-api/category";

    updatePopulation(categoryId: number, population: number) {
        return this._client.post(`${this.rootPath}/${categoryId}/population?value=${population}`);
    }

    refreshPrices() {
        return this._client.post(`${this.rootPath}/prices/refresh`);
    }
}
