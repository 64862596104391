import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {CustomerCounting} from "../model/customer/counting/CustomerCounting";
import {CustomerCountingSearchResult} from "../model/customer/counting/search/CustomerCountingSearchResult";
import {CustomerStorageSearchQuery} from "../model/customer/storage/search/CustomerStorageSearchQuery";

export class RestCustomerCounting extends Rest implements RestService<CustomerCounting>  {
    rootPath = "/console-api/customer/filter";

    search(params: CustomerStorageSearchQuery): Promise<CustomerCountingSearchResult> {
        return this._client.get(`${this.rootPath}`, CustomerCountingSearchResult, {queryParams: params.getJsonParameters()}) as Promise<CustomerCountingSearchResult>;
    }

    get(countingId: string): Promise<CustomerCounting> {
        return this._client.get(`${this.rootPath}/${countingId}`, CustomerCounting) as Promise<CustomerCounting>;
    }

    create(counting: CustomerCounting): Promise<CustomerCounting> {
        return new Promise<CustomerCounting>(() => {});
    }

    update(counting: CustomerCounting): Promise<CustomerCounting> {
        return new Promise<CustomerCounting>(() => {});
    }

    delete(obj: any): void {
    }

    executeJob(counting: CustomerCounting) {
        return this._client.post(`${this.rootPath}/${counting.id}/exec`);
    }
}
