import {FormLayoutRows, Loader, ModalContent, ModalDescription, ModalHeader, ModalHeaderTitle, ModalNew} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalPopulateCategoryProps = {
    active: boolean;
};

const ModalPopulateCategory: FunctionComponent<ModalPopulateCategoryProps> = ({active}) => {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);

    return (
        <ModalNew active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategories("modal.populate.title")}/>
            </ModalHeader>
            <ModalContent centered>
                <FormLayoutRows>
                    <Loader/>
                    <ModalDescription>{textCategories("modal.populate.description")}</ModalDescription>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalPopulateCategory;
