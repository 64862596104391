import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CMP_CONFIG_THEME_POSITIONS, CmpConfigThemePosition} from "../../../api/model/cmp/config/theme/CmpConfigThemePosition";
import {FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, RadioButton} from "@sirdata/ui-lib";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";

type CmpConfigurationThemePositionProps = {
    value: CmpConfigThemePosition;
    onChange: (position: CmpConfigThemePosition) => void;
}

const CmpConfigurationThemePosition: FunctionComponent<CmpConfigurationThemePositionProps> = ({value, onChange}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            spacing={FlexContentSpacing.LARGE}
            alignment={FlexContentAlignment.START}
            allowWrap
            cssClass="theme-position"
        >
            <div className="theme-position__preview">
                <img src={`/images/cmp/config/position_${value.toLowerCase()}.svg`} alt={value}/>
            </div>
            <div className="theme-position__options">
                {CMP_CONFIG_THEME_POSITIONS.map((position) =>
                    <RadioButton
                        key={position}
                        id={position}
                        value={position}
                        label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.POSITION}.${position}`)}
                        checked={value === position}
                        onChange={() => onChange(position)}
                    />
                )}
            </div>
        </FlexContent>
    );
};

export default CmpConfigurationThemePosition;
