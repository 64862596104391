import {ButtonLinkCancel, ButtonValidate, FormLayoutRows, FieldBlock, FormLayoutColumns, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select, ToggleSwitch} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {ApiAuthorization} from "../../../api/model/auth/ApiAuthorization";
import {PartnerLicenseAuthorization} from "../../../api/model/partner/license/PartnerLicenseAuthorization";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import {PartnerLicenseAuthorizationField} from "../../../api/model/partner/license/PartnerLicenseAuthorizationField";
import useAlert from "../../../utils/hooks/useAlert";

type ModalAddLicenseAuthorizationProps = {
    onSubmit: (license: PartnerLicenseAuthorization) => void;
    onClose: () => void;
    active: boolean;
};

const ModalAddLicenseAuthorization: FunctionComponent<ModalAddLicenseAuthorizationProps> = ({onSubmit, onClose, active}) => {
    const alert = useAlert();
    const {t: textLicenses} = useTranslation(TranslationPortalFile.LICENSES);

    const [isLoading, setLoading] = useState<boolean>(true);
    const [licenseAuth, setLicenseAuth] = useState<PartnerLicenseAuthorization>({authorization: "", remove_on_deactivation: true});
    const [authorizations, setAuthorizations] = useState<ApiAuthorization[]>([]);

    useEffect(() => {
        if (!active) {
            setLicenseAuth({authorization: "", remove_on_deactivation: true});
            return;
        }

        (async () => {
            try {
                const authorizations = await session.restPartnerAuthorization.list();
                setAuthorizations(authorizations);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partner authorizations", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [active, alert]);

    if (!licenseAuth) return <></>;

    const hasEmptyField = () => !licenseAuth.authorization;

    return (
        <ModalNew onClose={onClose} active={active} loading={isLoading}>
            <ModalHeader>
                <ModalHeaderTitle title={textLicenses("modal.authorization.title")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows>
                    <FormLayoutColumns>
                        <FieldBlock label={textLicenses(`field.${PartnerLicenseAuthorizationField.AUTHORIZATION}`)} required>
                            <Select
                                value={licenseAuth.authorization}
                                options={authorizations.map((authz) => ({label: authz.name, value: authz.name}))}
                                onChange={(option) => setLicenseAuth((prevState) => ({...prevState, [PartnerLicenseAuthorizationField.AUTHORIZATION]: `${option?.value || ""}`}))}
                                clearable
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textLicenses(`field.${PartnerLicenseAuthorizationField.REMOVE_ON_DEACTIVATION}`)}
                            name={PartnerLicenseAuthorizationField.REMOVE_ON_DEACTIVATION}
                        >
                            <ToggleSwitch
                                checked={licenseAuth.remove_on_deactivation}
                                onChange={(value) => setLicenseAuth((prevState) => ({...prevState, [PartnerLicenseAuthorizationField.REMOVE_ON_DEACTIVATION]: value}))}
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate onClick={() => onSubmit(licenseAuth)} disabled={hasEmptyField()}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddLicenseAuthorization;
