import {Action, IconTooltip} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Partner} from "../../../api/model/partner/Partner";
import {TranslationPortalFile} from "../../../utils/constants";
import {Module} from "../../../utils/Module";

export type LabelPartnerProps = {
    partner: Partner | number | undefined;
    withLink?: boolean;
};

const LabelPartner: FunctionComponent<LabelPartnerProps> = ({partner, withLink}) => {
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);

    if (!partner) return <>-</>;

    return (
        <>
            {partner instanceof Partner ?
                <span>{`${partner.id} - ${partner.nameWithCompany}`}</span> :
                <span>{partner}</span>
            }
            {withLink &&
                <span>
                    <IconTooltip
                        icon={Action.OPEN.icon}
                        text={textPartners("actions.view_partner")}
                        onClick={() => window.open(partner instanceof Partner ? partner.getRoute() : Module.PARTNERS.buildRoute(partner), "_blank")}
                    />
                </span>
            }
        </>
    );
};

export default LabelPartner;
