import {Rest} from "../../common/api/rest/Rest";
import {AuthorizationItems} from "../model/auth/AuthorizationItems";
import {ApiAuthorization} from "../model/auth/ApiAuthorization";

export class RestPartnerAuthorization extends Rest {
    rootPath = "/console-api/partner";

    list(): Promise<ApiAuthorization[]> {
        return this._client.get(`${this.rootPath}/authorizations`, ApiAuthorization) as Promise<ApiAuthorization[]>;
    }

    getDetails(partnerId: number): Promise<AuthorizationItems> {
        return this._client.get(`${this.rootPath}/${partnerId}/authorization`, AuthorizationItems) as Promise<AuthorizationItems>;
    }

    add(partnerId: number, values: string[]): Promise<AuthorizationItems> {
        return this._client.post(`${this.rootPath}/${partnerId}/authorization-set`, values, AuthorizationItems) as Promise<AuthorizationItems>;
    }

    remove(partnerId: number, value: string) {
        return this._client.delete(`${this.rootPath}/${partnerId}/authorization/${value}`);
    }
}
