export enum CustomerOrderSeatField {
    ID = "id",
    SEAT_ID = "seat_id",
    ORGANIZATION_ID = "organization_id",
    ORGANIZATION_NAME = "organization_name",
    ORGANIZATION_TYPE = "organization_type",
    OWNER_ID = "owner_id",
    OWNER_NAME = "owner_name",
    BUYER_TYPE = "buyer_type",
}
