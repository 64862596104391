import {Rest} from "../../common/api/rest/Rest";
import {CategorizerSanitizerVersion} from "../model/categorizer/CategorizerSanitizerVersion";

export class RestCategorizer extends Rest {
    rootPath = "/console-api/categorizer";

    getSanitizerVersions(): Promise<CategorizerSanitizerVersion[]> {
        return this._client.get(`${this.rootPath}/sanitizer-version`, CategorizerSanitizerVersion) as Promise<CategorizerSanitizerVersion[]>;
    }
}
