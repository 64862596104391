import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutRows,
    FormValidationType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigEntryGroup} from "../../../api/model/categorizer/CategorizerConfigEntryGroup";
import {CategorizerConfigEntryGroupField} from "../../../api/model/categorizer/CategorizerConfigEntryGroupField";
import {CategorizerConfigEntryParams} from "../../../api/model/categorizer/CategorizerConfigEntryParams";
import {TranslationPortalFile} from "../../../utils/constants";
import {CategorizerConfigEntryParamsField} from "../../../api/model/categorizer/CategorizerConfigEntryParamsField";

type ModalInitCategorizerConfigParamsProps = {
    active: boolean;
    onClose: (doRefresh: boolean) => void;
};

const ModalInitCategorizerConfigParams: FunctionComponent<ModalInitCategorizerConfigParamsProps> = ({active, onClose}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const navigate = useNavigate();
    const [params, setParams] = useState<CategorizerConfigEntryParams>({[CategorizerConfigEntryParamsField.DOMAIN]: ""});
    const FORM_ID = "form-init-categorizer-config-params";

    useEffect(() => {
        if (active) {
            setParams({[CategorizerConfigEntryParamsField.DOMAIN]: ""});
        }
    }, [active]);

    const handleChange = (field: CategorizerConfigEntryField, value: any) => {
        setParams((prevState) => ({...prevState, [field]: value}) as CategorizerConfigEntryParams);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const newCategorizerConfigEntryGroup = new CategorizerConfigEntryGroup({
            [CategorizerConfigEntryGroupField.DOMAIN]: params.domain,
            [CategorizerConfigEntryGroupField.HOST]: params.host,
            [CategorizerConfigEntryGroupField.PATH]: params.path
        });
        navigate(newCategorizerConfigEntryGroup.getRoute());
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategorizer("modal.init_new_configuration")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows inline>
                        <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryField.DOMAIN}`)}>
                            <InputText
                                value={params.domain}
                                onChange={(value) => handleChange(CategorizerConfigEntryField.DOMAIN, value)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryField.HOST}`)}>
                            <InputText
                                value={params.host}
                                onChange={(value) => handleChange(CategorizerConfigEntryField.HOST, value)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryField.PATH}`)}>
                            <InputText
                                value={params.path}
                                onChange={(value) => handleChange(CategorizerConfigEntryField.PATH, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalInitCategorizerConfigParams;
