export class CategoryPanelType {
    static KANTAR_TGI: CategoryPanelType = new CategoryPanelType("kantar");
    static KANTAR_WORLD_PANEL: CategoryPanelType = new CategoryPanelType("world_panel");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CategoryPanelType[] {
        return [
            CategoryPanelType.KANTAR_TGI,
            CategoryPanelType.KANTAR_WORLD_PANEL
        ];
    }
}
