export class AudiencePlatform {
    static ADOBE: AudiencePlatform = new AudiencePlatform("Adobe", "Adobe", "/images/platforms/adobe.png");
    static AMAZON: AudiencePlatform = new AudiencePlatform("Amazon_compte_public", "Amazon", "/images/platforms/amazon.png");
    static CONVERGED: AudiencePlatform = new AudiencePlatform("Mediarithmics_1374", "Converged", "/images/platforms/converged.png", "Mediarithmics");
    static DAILYMOTION: AudiencePlatform = new AudiencePlatform("Mediarithmics_1437", "Dailymotion", "/images/platforms/dailymotion.png", "Mediarithmics");
    static EQUATIV: AudiencePlatform = new AudiencePlatform("Smart", "Equativ", "/images/platforms/equativ.png");
    static EYEOTA: AudiencePlatform = new AudiencePlatform("Eyeota", "Eyeota", "/images/platforms/eyeota.png");
    static FACEBOOK: AudiencePlatform = new AudiencePlatform("Facebook", "Facebook", "/images/platforms/facebook.png");
    static FREEWHEEL: AudiencePlatform = new AudiencePlatform("Freewheel", "Freewheel", "/images/platforms/freewheel.png");
    static GAYA_MARKETING: AudiencePlatform = new AudiencePlatform("Gaya", "Gaya Marketing", "/images/platforms/gaya.png");
    static GOOGLE_DV360: AudiencePlatform = new AudiencePlatform("Google", "Google DV360/GAM", "/images/platforms/google.png");
    static INVIBES: AudiencePlatform = new AudiencePlatform("Invibes", "Invibes", "/images/platforms/invibes.png");
    static LIVERAMP: AudiencePlatform = new AudiencePlatform("Liveramp", "Liveramp", "/images/platforms/liveramp.png");
    static LOTAME: AudiencePlatform = new AudiencePlatform("Lotame", "Lotame", "/images/platforms/lotame.png");
    static MARKET_ESPACE: AudiencePlatform = new AudiencePlatform("MarketEspace", "Market Espace", "/images/platforms/market_espace.png");
    static MEDIARITHMICS: AudiencePlatform = new AudiencePlatform("Mediarithmics", "Mediarithmics", "/images/platforms/mediarithmics.png");
    static NIELSEN: AudiencePlatform = new AudiencePlatform("Exelate", "Nielsen", "/images/platforms/nielsen.png");
    static NIELSEN_UK: AudiencePlatform = new AudiencePlatform("ExelateUK", "Nielsen UK", "/images/platforms/nielsen.png");
    static ORACLE: AudiencePlatform = new AudiencePlatform("Bluekai", "Oracle", "/images/platforms/oracle.png");
    static PROXISTORE: AudiencePlatform = new AudiencePlatform("Proxistore_dv360", "Proxistore", "/images/platforms/proxistore.png", "Google DV360");
    static PUBMATIC: AudiencePlatform = new AudiencePlatform("Pubmatic", "Pubmatic", "/images/platforms/pubmatic.png");
    static TF1: AudiencePlatform = new AudiencePlatform("Mediarithmics_1497", "TF1", "/images/platforms/tf1.png", "Mediarithmics");
    static THE_TRADE_DESK: AudiencePlatform = new AudiencePlatform("TheTradeDesk", "TheTradeDesk", "/images/platforms/the_trade_desk.png");
    static XANDR: AudiencePlatform = new AudiencePlatform("Nexus", "Xandr", "/images/platforms/xandr.png");
    static YAHOO: AudiencePlatform = new AudiencePlatform("Yahoo", "Yahoo", "/images/platforms/yahoo.png");

    name: string;
    label: string;
    logo: string;
    tooltip?: string;

    private constructor(name: string, label: string, logo: string, tooltip?: string) {
        this.name = name;
        this.label = label;
        this.logo = logo;
        this.tooltip = tooltip;
    }

    static values(): AudiencePlatform[] {
        return [
            AudiencePlatform.ADOBE,
            AudiencePlatform.AMAZON,
            AudiencePlatform.CONVERGED,
            AudiencePlatform.DAILYMOTION,
            AudiencePlatform.EQUATIV,
            AudiencePlatform.EYEOTA,
            AudiencePlatform.FACEBOOK,
            AudiencePlatform.FREEWHEEL,
            AudiencePlatform.GAYA_MARKETING,
            AudiencePlatform.GOOGLE_DV360,
            AudiencePlatform.INVIBES,
            AudiencePlatform.LIVERAMP,
            AudiencePlatform.LOTAME,
            AudiencePlatform.MARKET_ESPACE,
            AudiencePlatform.MEDIARITHMICS,
            AudiencePlatform.NIELSEN,
            AudiencePlatform.NIELSEN_UK,
            AudiencePlatform.ORACLE,
            AudiencePlatform.PROXISTORE,
            AudiencePlatform.PUBMATIC,
            AudiencePlatform.TF1,
            AudiencePlatform.THE_TRADE_DESK,
            AudiencePlatform.XANDR,
            AudiencePlatform.YAHOO
        ];
    }

    static getByName(name: string): AudiencePlatform | undefined {
        return this.values().find((it) => it.name === name);
    }
}
