import {SearchResult} from "../../interface/SearchResult";
import {Deal} from "./Deal";
import {DealFacets} from "./DealFacets";

export class DealSearchResult extends SearchResult<Deal> {
    per_page: number = 0;
    found: number = 0;
    total: number = 0;
    results: Deal[] = [];
    facets: DealFacets = new DealFacets();

    constructor(o?: {}) {
        super(Deal, o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === "results") {
                    (o[key] as Deal[]).forEach((obj) => {
                        const it = new Deal(obj);
                        this.results.push(it);
                    });
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    get size(): number {
        return this.per_page;
    }

    get total_elements(): number {
        return this.found;
    }

    get elements(): Deal[] {
        return this.results;
    }
}
