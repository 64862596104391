import {PortalContent} from "../portal-content/PortalContent";
import {PortalSettingContent} from "./PortalSettingContent";
import {PortalContentField} from "../portal-content/PortalContentField";
import {Module} from "../../../utils/Module";

export class PortalSetting extends PortalContent {
    content: PortalSettingContent = new PortalSettingContent();
    type = PortalSetting.TYPE;

    static TYPE = "PORTAL_SETTING";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === PortalContentField.CONTENT) {
                    this[key] = new PortalSettingContent(o[key]);
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getRoute(): string {
        return Module.PORTAL_SETTINGS.buildRoute(this.id);
    }

    getJsonParameters(): {} {
        return {
            ...super.getJsonParameters(),
            content: this.content.getJsonParameters(),
            type: this.type
        };
    }
}
