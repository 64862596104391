import {Rest} from "../../common/api/rest/Rest";
import {SegmentLinkCategory} from "../model/audience/segment/SegmentLinkCategory";

export class RestSegmentLinkCategory extends Rest {
    rootPath = "/console-api/segment";

    list(segmentId: number): Promise<SegmentLinkCategory[]> {
        return this._client.get(`${this.rootPath}/${segmentId}/categories`, SegmentLinkCategory) as Promise<SegmentLinkCategory[]>;
    }

    update(segmentId: number, links: SegmentLinkCategory[]): Promise<SegmentLinkCategory[]> {
        return this._client.post(`${this.rootPath}/${segmentId}/categories`, links, SegmentLinkCategory) as Promise<SegmentLinkCategory[]>;
    }

    getCategoryVolumes(segmentId: number): Promise<any> {
        return this._client.get(`${this.rootPath}/${segmentId}/categories/volumes`);
    }
}
