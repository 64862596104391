import {
    Action,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    FormLayoutRows,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {SegmentDataType} from "../../../api/model/audience/segment/SegmentDataType";
import {SegmentLinkCategory} from "../../../api/model/audience/segment/SegmentLinkCategory";
import {SegmentLinkCategoryField} from "../../../api/model/audience/segment/SegmentLinkCategoryField";
import {sortItems} from "../../../common/utils/helper";
import {SegmentTypeDefaultValue} from "../../../utils/audience/SegmentTypeDefaultValue";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectCategory, TagCategoryRow} from "../../snippet";
import {CategoryField} from "../../../api/model/audience/category/CategoryField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";

export type ModalEditSegmentLinkCategoryProps = {
    active: boolean;
    currentLinks: SegmentLinkCategory[];
    segmentId: number;
    segmentType?: SegmentDataType;
    percentile?: string;
    onClose: (refresh: boolean) => void;
};

const ModalAddSegmentLinksCategory: FunctionComponent<ModalEditSegmentLinkCategoryProps> = ({active, currentLinks, segmentId, segmentType, percentile, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const alert = useAlert();
    const [categories, setCategories] = useState<Category[]>([]);
    const [excludedCategories, setExcludedCategories] = useState<Category[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (active) {
            (async () => {
                try {
                    const categories = await session.getCategories();
                    setCategories(categories);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("categories", e.message);
                    }
                }
            })();
            setSelectedCategories([]);
        }
    }, [active, alert]);

    useEffect(() => {
        if (!segmentType) return;
        const linkedCategoryIds = currentLinks.map((it) => it.category_id);

        let excludedCategories = categories.filter((category) => {
            if (linkedCategoryIds.includes(category.id)) return true;

            if (segmentType.name === SegmentDataType.INFERRED.name) {
                return ![CategoryType.INTENT, CategoryType.INTEREST, CategoryType.DECLARATIVE].map((type) => type.name).includes(category.type);
            }
            return category.type !== segmentType.name.toUpperCase();
        });
        excludedCategories = sortItems(excludedCategories, CategoryField.CODE_NAME);
        setExcludedCategories(excludedCategories);
    }, [segmentType, currentLinks, categories]);

    const handleAddCategory = (category: Category) => {
        setSelectedCategories([category, ...selectedCategories]);
        addChildren(category);
    };

    const addChildren = (parent: Category) => {
        let children = categories.filter((category) => category.id_parent === parent.id_group && category.type === parent.type);
        setSelectedCategories((prevState) => ([...children, ...prevState]));
        children.forEach((category) => addChildren(category));
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        let volumes = new Map<string, number>();
        try {
            const result = await session.restSegmentLinkCategory.getCategoryVolumes(segmentId);
            volumes = new Map(Object.entries(result));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("categories' volumes", e.message);
            }
        }

        const defaultValues = SegmentTypeDefaultValue.getByDataType(segmentType);
        const links = selectedCategories.map((item) => {
            return new SegmentLinkCategory({
                [SegmentLinkCategoryField.SEGMENT_ID]: segmentId,
                [SegmentLinkCategoryField.CATEGORY_ID]: item.id,
                [SegmentLinkCategoryField.CATEGORY_NAME]: item.name,
                [SegmentLinkCategoryField.CATEGORY_CODE_NAME]: item.code_name,
                [SegmentLinkCategoryField.CATEGORY_TYPE]: item.type,
                [SegmentLinkCategoryField.VOLUME]: volumes.get(item.id.toString()) || 0,
                [SegmentLinkCategoryField.MIN_SCORE]: defaultValues?.minScore,
                [SegmentLinkCategoryField.EXPIRATION_DAY]: defaultValues?.expirationTime,
                [SegmentLinkCategoryField.PERCENTILE]: !!percentile ? percentile : undefined
            });
        });
        try {
            await session.restSegmentLinkCategory.update(segmentId, [...links, ...currentLinks]);
            alert.updateWithSuccess("categories");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("categories", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textSegments("modal.add_categories")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                    <SelectCategory
                        value={undefined}
                        excludedCategories={[...excludedCategories, ...selectedCategories]}
                        onChange={(category) => category && handleAddCategory(category)}
                    />
                    <FieldBlock
                        label={textSegments("modal.selected", {count: selectedCategories.length})}
                        actions={<ButtonLink onClick={() => setSelectedCategories([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>}
                    >
                        <ElementList placeholder={textSegments("modal.search_result")} size={ElementListSize.BIG}>
                            {selectedCategories.map((item) =>
                                <TagCategoryRow
                                    key={item.id}
                                    category={item}
                                    onRemove={() => setSelectedCategories((prevState) => prevState.filter((it) => it.id !== item.id))}
                                />
                            )}
                        </ElementList>
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate onClick={handleSubmit} disabled={!selectedCategories.length} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddSegmentLinksCategory;
