export enum DistributionContactType {
    MAIN = "main",
    SECONDARY = "secondary",
    TECHNICAL = "technical",
    BILLING = "billing",
}

export const DISTRIBUTION_CONTACT_TYPES: DistributionContactType[] = [
    DistributionContactType.MAIN,
    DistributionContactType.SECONDARY,
    DistributionContactType.TECHNICAL,
    DistributionContactType.BILLING
];