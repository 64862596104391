import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CustomerStorageTableIntersectSegment extends ApiModel {
    dataset: string = "";
    table_id: string = "";
    row_count: number = 0;
    distinct_mapping_column_values: number = 0;
    column_names: string[] = [];
    mapping_column_index: number = 0;
}

export class CustomerStorageTableIntersect extends ApiModel {
    segment: CustomerStorageTableIntersectSegment = new CustomerStorageTableIntersectSegment();
}
