import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";

export class MediaSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    private _type: string | undefined;
    private _user_id: number | undefined;

    withSearchParams(params: SearchParams): MediaSearchQuery {
        let newQuery = super.withSearchParams(params) as MediaSearchQuery;
        newQuery.type = params.getString(SearchParamsField.TYPE) || "";
        return newQuery;
    }

    get type(): string {
        return this._type ? this._type : "";
    }

    set type(type: string) {
        this._type = !!type ? type : undefined;
    }

    get user_id(): number {
        return this._user_id ? this._user_id : 0;
    }

    set user_id(userId: number) {
        this._user_id = userId;
    }

    getJsonParameters(): {} {
        return {
            search: this.query,
            type: this._type,
            user_id: this._user_id,
            size: this.size,
            page: this.page
        };
    }

}
