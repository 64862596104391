export enum DataSelectionContentField {
    NAME_FRENCH = "name_fr",
    NAME_ENGLISH = "name_en",
    DESCRIPTION_FRENCH = "description_fr",
    DESCRIPTION_ENGLISH = "description_en",
    MAIN_IMAGE_ID = "main_image_id",
    PREVIEW_IMAGE_ID = "preview_image_id",
    TAGS = "tags",
    THEME = "theme",
}
