import {ApiModel} from "../../../../common/api/model/ApiModel";
import {PartnerLicense} from "./PartnerLicense";
import {PartnerLicenseLinkPartner} from "./PartnerLicenseLinkPartner";

export class PartnerLicenseDetailsResponse extends ApiModel {
    _global_license: PartnerLicense = new PartnerLicense();
    _partner_licenses?: Map<string, PartnerLicenseLinkPartner>;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get global_license(): PartnerLicense {
        return this._global_license || new PartnerLicense();
    }

    set global_license(license: PartnerLicense | undefined) {
        this._global_license = new PartnerLicense(license);
    }

    get partner_licenses(): Map<string, PartnerLicenseLinkPartner> {
        return this._partner_licenses || new Map<string, PartnerLicenseLinkPartner>();
    }

    set partner_licenses(links: Map<string, PartnerLicenseLinkPartner> | undefined) {
        if (!links) {
            return;
        }

        this._partner_licenses = Object.entries(links).length ? new Map(Object.entries(links)) : links;
    }


}
