import {ContentBlock, SearchError, SearchToolbar, Table} from "@sirdata/ui-lib";
import React, {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {Vendor} from "../../../api/model/cmp/list/global-vendor-list/Vendor";
import {VendorField} from "../../../api/model/cmp/list/global-vendor-list/VendorField";
import {TranslationPortalFile} from "../../../utils/constants";
import useSearch from "../../../utils/hooks/useSearch";
import {CmpListsIABVendorRow} from "../index";

type GlobalVendorListVendorsProps = {
    globalVendorList: GlobalVendorList;
};

const GlobalVendorListVendors: FC<GlobalVendorListVendorsProps> = ({globalVendorList}) => {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const {buildSearchResult, ...search} = useSearch(Vendor);

    useEffect(() => {
        let currentVendors = (globalVendorList.vendors || []);
        if (search.searchQuery.query) {
            currentVendors = currentVendors.filter((item) => `${item.id} ${item.name}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentVendors);
    }, [globalVendorList, search.searchQuery, buildSearchResult]);

    return (
        <ContentBlock>
            <SearchToolbar searchBar={{placeholder: textCmpLists("gvl.search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}/>
            {!!search.searchResult.elements.length ?
                <Table
                    onSort={search.changeSortOrder}
                    columns={[
                        {width: 5},
                        {width: 5, label: textCmpLists(`gvl.field.vendor.${VendorField.ID}`)},
                        {width: 25, label: textCmpLists(`gvl.field.vendor.${VendorField.NAME}`)},
                        {width: 50, label: textCmpLists(`gvl.field.vendor.${VendorField.POLICY_URL}`)},
                        {width: 15, label: textCmpLists(`gvl.field.vendor.${VendorField.DELETED_DATE}`)}
                    ]}
                    pagination={search.searchResult.getPagination(search.changePage)}
                >
                    {search.searchResult.elements.map((vendor) =>
                        <CmpListsIABVendorRow key={vendor.id} vendor={vendor} globalVendorList={globalVendorList}/>
                    )}
                </Table> :
                <SearchError query={search.searchQuery.query}/>
            }
        </ContentBlock>
    );
};

export default GlobalVendorListVendors;
