import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutRows, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {SirdataListStackField} from "../../../api/model/cmp/list/sirdata-list/SirdataListStackField";
import {SirdataListStack} from "../../../api/model/cmp/list/sirdata-list/SirdataListStack";
import {CmpSirdataListPurposesCheckboxes} from "../../snippet";
import {session} from "../../../api/ApiSession";
import useAlert from "../../../utils/hooks/useAlert";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataListPurpose} from "../../../api/model/cmp/list/sirdata-list/SirdataListPurpose";

type ModalEditCmpSirdataListStackProps = {
    initStack: SirdataListStack | undefined;
    globalVendorList: GlobalVendorList;
    extraPurposes: SirdataListPurpose[];
    onClose: (refresh?: boolean) => void;
};

const ModalEditCmpSirdataListStack: FunctionComponent<ModalEditCmpSirdataListStackProps> = ({initStack, globalVendorList, extraPurposes, onClose}) => {
    const alert = useAlert();
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const [isSubmitting, setSubmitting] = useState(false);
    const [stack, setStack] = useState<SirdataListStack>(new SirdataListStack());
    const [selectedPurposes, setSelectedPurposes] = useState<number[]>([]);
    const [selectedRequiredPurposes, setSelectedRequiredPurposes] = useState<number[]>([]);
    const [selectedSpecialFeatures, setSelectedSpecialFeatures] = useState<number[]>([]);
    const [selectedExtraPurposes, setSelectedExtraPurposes] = useState<number[]>([]);
    const FORM_ID = "form-edit-cmp-sirdata-list-stack";

    useEffect(() => {
        if (!!initStack) {
            setStack(initStack);
            setSelectedPurposes(initStack.purposes);
            setSelectedRequiredPurposes(initStack.requiredPurposes);
            setSelectedSpecialFeatures(initStack.specialFeatures);
            setSelectedExtraPurposes(initStack.extraPurposes);
        }
    }, [initStack]);

    const handleChange = (field: SirdataListStackField, value: any) => {
        setStack((prevState) => new SirdataListStack({...prevState, [field]: value}));
    };

    const handleTogglePurposes = (purposes: number[]) => {
        setSelectedPurposes(purposes);
        handleChange(SirdataListStackField.PURPOSES, purposes);

        if (selectedRequiredPurposes.some((id) => purposes.includes(id))) {
            const updatedRequiredPurposes = selectedRequiredPurposes.filter((id) => !purposes.includes(id));
            setSelectedRequiredPurposes(updatedRequiredPurposes);
            handleChange(SirdataListStackField.REQUIRED_PURPOSES, updatedRequiredPurposes);
        }
    };

    const handleToggleRequiredPurposes = (requiredPurposes: number[]) => {
        setSelectedRequiredPurposes(requiredPurposes);
        handleChange(SirdataListStackField.REQUIRED_PURPOSES, requiredPurposes);

        if (selectedPurposes.some((id) => requiredPurposes.includes(id))) {
            const updatedPurposes = selectedPurposes.filter((id) => !requiredPurposes.includes(id));
            setSelectedPurposes(updatedPurposes);
            handleChange(SirdataListStackField.PURPOSES, updatedPurposes);
        }
    };

    const handleToggleSpecialFeatures = (specialFeatures: number[]) => {
        setSelectedSpecialFeatures(specialFeatures);
        handleChange(SirdataListStackField.SPECIAL_FEATURES, specialFeatures);
    };

    const handleToggleExtraPurposes = (extraPurposes: number[]) => {
        setSelectedExtraPurposes(extraPurposes);
        handleChange(SirdataListStackField.EXTRA_PURPOSES, extraPurposes);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const listLanguages = await session.restCmpSirdataListLanguage.list();
            for (const list of listLanguages) {
                const currentStack = list.stacks.find((it) => it.id === stack.id);
                if (currentStack) {
                    currentStack.purposes = stack.purposes;
                    currentStack.requiredPurposes = stack.requiredPurposes;
                    currentStack.specialFeatures = stack.specialFeatures;
                    currentStack.extraPurposes = stack.extraPurposes;

                    await session.restCmpSirdataListLanguage.upsertStack(list.language, currentStack);
                }
            }
            alert.updateWithSuccess("stack");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("stack", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={!!initStack}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpSirdataList("edit_stack")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textCmpSirdataList(`field.stack.${SirdataListStackField.PURPOSES}`)}>
                            <CmpSirdataListPurposesCheckboxes
                                purposes={globalVendorList.purposes}
                                selectedPurposes={selectedPurposes}
                                onChange={handleTogglePurposes}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.stack.${SirdataListStackField.REQUIRED_PURPOSES}`)}>
                            <CmpSirdataListPurposesCheckboxes
                                purposes={globalVendorList.purposes}
                                selectedPurposes={selectedRequiredPurposes}
                                onChange={handleToggleRequiredPurposes}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.stack.${SirdataListStackField.SPECIAL_FEATURES}`)}>
                            <CmpSirdataListPurposesCheckboxes
                                purposes={globalVendorList.specialFeatures}
                                selectedPurposes={selectedSpecialFeatures}
                                onChange={handleToggleSpecialFeatures}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.stack.${SirdataListStackField.EXTRA_PURPOSES}`)}>
                            <CmpSirdataListPurposesCheckboxes
                                purposes={extraPurposes}
                                selectedPurposes={selectedExtraPurposes}
                                onChange={handleToggleExtraPurposes}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditCmpSirdataListStack;
