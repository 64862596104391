import {Action, ActionsMenu, Box, BoxProps, Checkbox, ContentBlock, ContentBlockAction, FieldBlock, FlexContentDirection, FlexContentLayout, FlexContentSpacing, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, IconTooltip, InputNumber, InputText, InputUrl, LayoutColumns, LayoutRows, Loadable, RadioButtons, Select, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, ToggleSwitch, TranslationLibFile} from "@sirdata/ui-lib";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "react-phone-number-input/style.css";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Currency} from "../../api/model/currency/Currency";
import {BanPropertyField} from "../../api/model/partner/BanPropertyField";
import {PartnerOrganization} from "../../api/model/partner/organization/PartnerOrganization";
import {Partner} from "../../api/model/partner/Partner";
import {PartnerAccount} from "../../api/model/partner/PartnerAccount";
import {PartnerAccountField} from "../../api/model/partner/PartnerAccountField";
import {PartnerAccountPropertiesField} from "../../api/model/partner/PartnerAccountPropertiesField";
import {PartnerBillingInfo} from "../../api/model/partner/PartnerBillingInfo";
import {PartnerBillingInfoField} from "../../api/model/partner/PartnerBillingInfoField";
import {PartnerBillingPaymentMethod} from "../../api/model/partner/PartnerBillingPaymentMethod";
import {PartnerBillingPaymentType} from "../../api/model/partner/PartnerBillingPaymentType";
import {PartnerBillingVatType} from "../../api/model/partner/PartnerBillingVatType";
import {PARTNER_CONSENT_STATUSES} from "../../api/model/partner/PartnerConsentStatus";
import {PartnerField} from "../../api/model/partner/PartnerField";
import {PartnerProperties} from "../../api/model/partner/PartnerProperties";
import {PartnerPropertiesField} from "../../api/model/partner/PartnerPropertiesField";
import {PartnerSite} from "../../api/model/partner/PartnerSite";
import {PartnerSiteField} from "../../api/model/partner/PartnerSiteField";
import {PARTNER_TYPES, PartnerType} from "../../api/model/partner/PartnerType";
import {UserField} from "../../api/model/user/UserField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {COUNTRY_ISO_CODES_EU, CountryIsoCode} from "../../common/api/model/CountryIsoCode";
import {Country} from "../../common/api/model/Country";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {TranslationCommonFile} from "../../common/utils/constants";
import {detectChanges} from "../../common/utils/portal";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import ModalCreatePartnerAccount from "../../component/modal/partners/ModalCreatePartnerAccount";
import ModalCreatePartnerSite from "../../component/modal/partners/ModalCreatePartnerSite";
import ModalEditPartnerAccount from "../../component/modal/partners/ModalEditPartnerAccount";
import ModalEditPartnerSite from "../../component/modal/partners/ModalEditPartnerSite";
import ModalPartnerPricingLinks from "../../component/modal/partners/ModalPartnerPricingLinks";
import {LabelStatus, MainContentHeader, MainContentHeaderAction, MainContentHeaderActionsMenu, PartnerAuthorizations, PartnerLicensesLinks, SelectStatus, SelectUser, Tag, ValueCopier} from "../../component/snippet";
import LabelService from "../../component/snippet/label/LabelService";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";
import {PARTNER_CONNECT_ORIGINS} from "./PartnersConnect";

function PartnersDetails() {
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const [isLoading, setLoading] = useState(true);
    const [isLoadingPartnerAccounts, setLoadingPartnerAccounts] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const alert = useAlert();
    const navigate = useNavigate();
    const {id} = useParams() as { id: string };
    const [activeEditPartnerAccount, setActiveEditPartnerAccount] = useState<PartnerAccount>();
    const [activeEditPartnerSite, setActiveEditPartnerSite] = useState<PartnerSite>();
    const [isShowModalPartnerPricingLinks, setShowModalPartnerPricingLinks] = useState(false);
    const [isShowModalCreatePartnerSite, setShowModalCreatePartnerSite] = useState(false);
    const [isShowModalCreatePartnerAccount, setShowModalCreatePartnerAccount] = useState(false);
    const [isShowModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
    const [partner, setPartner] = useState<Partner>(new Partner());
    const [initPartner, setInitPartner] = useState<Partner>(new Partner());
    const [accounts, setAccounts] = useState<PartnerAccount[]>([]);
    const [sites, setSites] = useState<PartnerSite[]>([]);
    const [billingInfo, setBillingInfo] = useState<PartnerBillingInfo>(new PartnerBillingInfo({partner_id: +id!}));
    const [initBillingInfo, setInitBillingInfo] = useState<PartnerBillingInfo>(new PartnerBillingInfo({partner_id: +id!}));
    const [countries, setCountries] = useState<Country[]>([]);

    const loadPartnerAccounts = useCallback(async () => {
        setLoadingPartnerAccounts(true);
        try {
            const partnerAccounts = await session.restPartnerAccount.list(+id);
            setAccounts(partnerAccounts);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("partner accounts", e.message);
            }
        } finally {
            setLoadingPartnerAccounts(false);
        }
    }, [alert, id]);

    useEffect(() => {
        (async () => {
            await loadPartnerAccounts();
        })();
    }, [loadPartnerAccounts]);

    useEffect(() => {
        (async () => {
            try {
                const countries = await session.restPartner.getCountries();
                setCountries(countries);

                const partner = await session.restPartner.get(+id);
                setPartner(partner);
                setInitPartner(new Partner(partner));

                const sites = await session.restPartnerSite.list(+id);
                setSites(sites);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partner", e.message);
                    return;
                }
            } finally {
                setLoading(false);
            }

            const account = await session.getAccount();
            if (account.hasAuthorization(Authorization.BILLINGS.name)) {
                try {
                    const billing = await session.restPartnerBillingInfo.get(+id);
                    setBillingInfo(billing);
                    setInitBillingInfo(new PartnerBillingInfo(billing));
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        if (e.statusCode !== HttpStatusCode.NOT_FOUND) {
                            alert.failToLoad("billing info", e.message);
                            return;
                        }
                    }
                }
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(partner, initPartner) || detectChanges(billingInfo, initBillingInfo));
    }, [partner, initPartner, billingInfo, initBillingInfo]);

    useEffect(() => {
        let vatType: PartnerBillingVatType;
        switch (true) {
            case billingInfo.country_iso === CountryIsoCode.FRANCE:
                vatType = PartnerBillingVatType.FR;
                break;
            case COUNTRY_ISO_CODES_EU.includes(billingInfo.country_iso):
                vatType = PartnerBillingVatType.EU;
                break;
            default:
                vatType = PartnerBillingVatType.ROW;
        }
        handleChangeBillingInfo(PartnerBillingInfoField.VAT_TYPE, vatType);
    }, [billingInfo.country_iso]);

    const handleChange = (field: PartnerField, value: any) => {
        setPartner((prevState) => new Partner({...prevState, [field]: value}));
    };

    const handleChangeType = (type: PartnerType) => {
        setPartner((prevState) => {
            const newPartner = new Partner({...prevState, [PartnerField.TYPE]: type});
            if (newPartner.type === PartnerType.PUBLISHER) {
                newPartner.data = true;
                newPartner.categorize_request = true;
            }
            return newPartner;
        });
    };

    const handleChangeProperty = (field: PartnerPropertiesField, value: any) => {
        handleChange(PartnerField.PROPERTIES, new PartnerProperties({...partner.properties, [field]: value}));
    };

    const handleChangeBillingInfo = (field: PartnerBillingInfoField, value: any) => {
        setBillingInfo((prevState) => new PartnerBillingInfo({...prevState, [field]: value}));
    };

    const handleSave = async () => {
        try {
            await session.restPartner.updateProperties(partner.id, partner.properties);
            const updatedPartner = await session.restPartner.update(partner);
            setPartner(updatedPartner);
            setInitPartner(new Partner(updatedPartner));
            alert.updateWithSuccess("partner");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("partner", e.message);
            }
        }
        try {
            const account = await session.getAccount();
            if (Authorization.BILLINGS.update && account.hasAuthorization(Authorization.BILLINGS.update)) {
                const updatedBillingInfo = await session.restPartnerBillingInfo.update(billingInfo);
                setBillingInfo(updatedBillingInfo);
                setInitBillingInfo(new PartnerBillingInfo(updatedBillingInfo));
                alert.updateWithSuccess("billing info");
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("billing info", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isShowModalConfirmDelete) return;
        try {
            await session.restPartner.delete(partner.id);
            navigate(Module.PARTNERS.path);
            alert.deleteWithSuccess("partner");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("partner", e.message);
            }
        } finally {
            setShowModalConfirmDelete(false);
        }
    };

    const handleRefreshPartnerSites = async () => {
        try {
            const sites = await session.restPartnerSite.list(partner.id);
            setSites(sites);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.statusCode !== HttpStatusCode.NOT_FOUND) {
                    alert.failToLoad("sites", e.message);
                    return;
                }
            }
        }
    };

    const handleDeletePartnerSite = async (site: PartnerSite) => {
        try {
            await session.restPartnerSite.delete(site);
            setSites((prevState) => {
                const newState = [...prevState];
                const index = newState.findIndex((it) => it.site_id === site.site_id);
                if (index !== -1) newState.splice(index, 1);
                return newState;
            });
        } catch {

        } finally {
            setActiveEditPartnerSite(undefined);
        }
    };

    const activateAccount = async (account: PartnerAccount) => {
        try {
            await session.restPartnerAccount.activate(account.id);
            setAccounts((prevState) => {
                const newState = prevState ? [...prevState] : [];
                const index = newState.findIndex((it) => it.id === account.id);
                if (index !== -1) newState[index].active = true;
                return newState;
            });
        } catch {
        }
    };

    const goToOrganization = () => {
        window.open(new PartnerOrganization({id: partner.organization_id}).getRoute());
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.PARTNERS} element={initPartner.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.PRICINGS.name}>
                    <MainContentHeaderAction action={new Action(textPartners("section.pricing_settings"), {name: "euro"})} onClick={() => setShowModalPartnerPricingLinks(true)}/>
                </RestrictedContent>
                <RestrictedContent allowedTo={Authorization.LOG_AS_PARTNER.name}>
                    <MainContentHeaderActionsMenu
                        action={Action.OPEN}
                        label={"Partner portal"}
                        items={
                            PARTNER_CONNECT_ORIGINS.map((origin) => (
                                {label: textMain(`menu.origins.${origin.name}`), onClick: () => window.open(partner.getPartnerAreaRoute(origin), "_blank")}
                            ))}
                    />
                </RestrictedContent>
                <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                    <MainContentHeaderAction action={Action.SAVE} onClick={handleSave} disabled={!isUnsavedChanges}/>
                </RestrictedContent>
                <RestrictedContent allowedTo={Authorization.PARTNERS_DELETE.name}>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setShowModalConfirmDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <LayoutColumns>
                        <LayoutRows>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textPartners("section.information")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns columns={4}>
                                                <FieldBlock label={textPartners("field.status")}>
                                                    <SelectStatus
                                                        value={partner.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                                        statuses={Status.getActiveStatuses()}
                                                        onChange={(status) => setPartner((prevState) => new Partner({...prevState, [PartnerField.ACTIVE]: status === Status.ACTIVE}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns>
                                                <FieldBlock
                                                    label={textPartners(`field.${PartnerField.COMPANY}`)}
                                                    actions={
                                                        partner.organization_id ? <IconTooltip icon={{...Action.OPEN.icon, onClick: goToOrganization}} text={textPartners("actions.go_to_organization_page")}/> : undefined
                                                    }
                                                >
                                                    <InputText
                                                        value={partner.company}
                                                        onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.COMPANY]: value}))}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPartners(`field.${PartnerField.TYPE}`)}>
                                                    <RadioButtons
                                                        id={PartnerField.TYPE}
                                                        value={partner.type}
                                                        options={PARTNER_TYPES.map((it) => ({value: it, label: it}))}
                                                        onChange={(value) => handleChangeType(value as PartnerType)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textPartners(`field.${PartnerField.COUNTRY_ID}`)}>
                                                    <Select
                                                        value={partner.country_id}
                                                        options={countries.map((it) => ({value: it.id, label: it.name}))}
                                                        onChange={(option) => setPartner((prevState) => new Partner({...prevState, [PartnerField.COUNTRY_ID]: option ? +option?.value : 0}))}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPartners(`field.${PartnerField.CURRENCY_ID}`)}>
                                                    <Select
                                                        value={+partner.currency_id}
                                                        options={Currency.values().map((it) => ({value: it.id, label: `${it.currency} (${it.symbol})`}))}
                                                        onChange={(option) => setPartner((prevState) => new Partner({...prevState, [PartnerField.CURRENCY_ID]: option?.value}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textPartners(`field.${PartnerField.PRIVACY_POLICY_URL}`)}>
                                                <InputUrl
                                                    value={partner.privacy_policy_url || ""}
                                                    onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.PRIVACY_POLICY_URL]: value}))}
                                                />
                                            </FieldBlock>
                                            <FormLayoutSeparator/>
                                            <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                <FieldBlock label={textPartners(`field.${PartnerField.USER}`)}>
                                                    <SelectUser
                                                        value={partner.user}
                                                        field={UserField.EMAIL}
                                                        onChange={(user) => setPartner((prevState) => new Partner({...prevState, [PartnerField.USER]: user?.email}))}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPartners(`field.${PartnerField.CREATION_SERVICE}`)} content={{noFullWidth: true}}>
                                                    <LabelService service={partner.creation_service}/>
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                            <RestrictedContent allowedTo={Authorization.BILLINGS.name}>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textPartners("section.billing")}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.COMPANY_NAME}`)}>
                                                        <InputText
                                                            value={billingInfo.company_name}
                                                            onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.COMPANY_NAME, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textPartners("field.billing_info.contact")}>
                                                        <InputText
                                                            value={billingInfo.contact_firstname}
                                                            placeholder={textPartners(`field.billing_info.${PartnerBillingInfoField.CONTACT_FIRSTNAME}`)}
                                                            onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.CONTACT_FIRSTNAME, value)}
                                                        />
                                                        <InputText
                                                            value={billingInfo.contact_lastname}
                                                            placeholder={textPartners(`field.billing_info.${PartnerBillingInfoField.CONTACT_LASTNAME}`)}
                                                            onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.CONTACT_LASTNAME, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                                        <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.ADDRESS}`)}>
                                                            <InputText
                                                                value={billingInfo.address}
                                                                onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.ADDRESS, value)}
                                                            />
                                                        </FieldBlock>
                                                        <FormLayoutColumns>
                                                            <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.POSTAL_CODE}`)}>
                                                                <InputText
                                                                    value={billingInfo.postal_code}
                                                                    onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.POSTAL_CODE, value)}
                                                                />
                                                            </FieldBlock>
                                                            <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.CITY}`)}>
                                                                <InputText
                                                                    value={billingInfo.city}
                                                                    onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.CITY, value)}
                                                                />
                                                            </FieldBlock>
                                                        </FormLayoutColumns>
                                                        <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.COUNTRY_ISO}`)}>
                                                            <Select
                                                                value={billingInfo.country_iso}
                                                                options={countries.map((it) => ({value: it.isocode, label: it.name}))}
                                                                onChange={(option) => handleChangeBillingInfo(PartnerBillingInfoField.COUNTRY_ISO, option?.value)}
                                                            />
                                                        </FieldBlock>
                                                    </FormLayoutRows>
                                                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                                        <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.BUSINESS_ID}`)}>
                                                            <InputText
                                                                value={billingInfo.business_id}
                                                                onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.BUSINESS_ID, value)}
                                                            />
                                                        </FieldBlock>
                                                        <FormLayoutColumns>
                                                            <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.VAT}`)}>
                                                                <InputText
                                                                    value={billingInfo.vat}
                                                                    onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.VAT, value)}
                                                                />
                                                            </FieldBlock>
                                                            <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.VAT_TYPE}`)}>
                                                                <Select
                                                                    value={billingInfo.vat_type}
                                                                    options={Object.values(PartnerBillingVatType).map((type) => ({label: type, value: type}))}
                                                                    onChange={undefined}
                                                                    disabled
                                                                />
                                                            </FieldBlock>
                                                        </FormLayoutColumns>
                                                        <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.AXONAUT_ID}`)}>
                                                            <InputNumber
                                                                value={billingInfo.axonaut_id}
                                                                onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.AXONAUT_ID, value)}
                                                            />
                                                        </FieldBlock>
                                                    </FormLayoutRows>
                                                </FormLayoutColumns>
                                                <FormLayoutSeparator/>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.PAYMENT_METHOD}`)}>
                                                        <Select
                                                            value={billingInfo.payment_method}
                                                            options={PartnerBillingPaymentMethod.values().map((method) => ({label: textPartners(`payment_method.${method.name}`), value: method.name}))}
                                                            onChange={(option) => handleChangeBillingInfo(PartnerBillingInfoField.PAYMENT_METHOD, option?.value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.PAYMENT_TYPE}`)}>
                                                        <Select
                                                            value={billingInfo.payment_type}
                                                            options={PartnerBillingPaymentType.values().map((type) => ({label: textPartners(`payment_type.${type.name}`), value: type.name}))}
                                                            onChange={(option) => handleChangeBillingInfo(PartnerBillingInfoField.PAYMENT_TYPE, option?.value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                    <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.IBAN}`)}>
                                                        <InputText
                                                            value={billingInfo.iban}
                                                            onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.IBAN, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textPartners(`field.billing_info.${PartnerBillingInfoField.BIC}`)}>
                                                        <InputText
                                                            value={billingInfo.bic}
                                                            onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.BIC, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock name={PartnerBillingInfoField.SMALL_BUSINESS} label={textPartners(`field.billing_info.${PartnerBillingInfoField.SMALL_BUSINESS}`)}>
                                                    <ToggleSwitch
                                                        checked={billingInfo.small_business}
                                                        onChange={(value) => handleChangeBillingInfo(PartnerBillingInfoField.SMALL_BUSINESS, value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                            </RestrictedContent>
                        </LayoutRows>
                        <LayoutRows>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textPartners("section.settings")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FieldBlock name={PartnerField.DATA} label={textPartners(`field.${PartnerField.ACTIVE}`)}>
                                                <ToggleSwitch
                                                    checked={partner.data}
                                                    onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.DATA]: value}))}
                                                />
                                            </FieldBlock>
                                            <FormLayoutColumns>
                                                <FieldBlock name={PartnerField.CATEGORIZE_REQUEST} label={textPartners(`field.${PartnerField.CATEGORIZE_REQUEST}`)}>
                                                    <ToggleSwitch
                                                        checked={partner.categorize_request}
                                                        onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.CATEGORIZE_REQUEST]: value}))}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPartners(`field.${PartnerField.MAX_SYNC_PER_REQUEST}`)}>
                                                    <InputNumber
                                                        value={partner.max_sync_per_request}
                                                        min={1}
                                                        onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.MAX_SYNC_PER_REQUEST]: value}))}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPartners(`field.${PartnerField.AXCIOM_ID}`)}>
                                                    <InputText
                                                        value={partner.axciom_id || ""}
                                                        onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.AXCIOM_ID]: value}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textPartners("section.disable_sync")} content={{spacing: FlexContentSpacing.SMALL}}>
                                                <ToggleSwitch
                                                    name={BanPropertyField.BAN_HASHMAIL}
                                                    label={textPartners(`field.${PartnerField.BAN_PROPERTY}.${BanPropertyField.BAN_HASHMAIL}`)}
                                                    checked={partner.ban_property.ban_hashmail}
                                                    onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.BAN_PROPERTY]: {...prevState?.ban_property, [BanPropertyField.BAN_HASHMAIL]: value}}))}
                                                />
                                                <ToggleSwitch
                                                    name={BanPropertyField.BAN_AXCIOM}
                                                    label={textPartners(`field.${PartnerField.BAN_PROPERTY}.${BanPropertyField.BAN_AXCIOM}`)}
                                                    checked={partner.ban_property.ban_axciom}
                                                    onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.BAN_PROPERTY]: {...prevState?.ban_property, [BanPropertyField.BAN_AXCIOM]: value}}))}
                                                />
                                                <ToggleSwitch
                                                    name={BanPropertyField.BAN_SMART}
                                                    label={textPartners(`field.${PartnerField.BAN_PROPERTY}.${BanPropertyField.BAN_SMART}`)}
                                                    checked={partner.ban_property.ban_smart}
                                                    onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.BAN_PROPERTY]: {...prevState?.ban_property, [BanPropertyField.BAN_SMART]: value}}))}
                                                />
                                                <ToggleSwitch
                                                    name={BanPropertyField.BAN_MAPPING}
                                                    label={textPartners(`field.${PartnerField.BAN_PROPERTY}.${BanPropertyField.BAN_MAPPING}`)}
                                                    checked={partner.ban_property.ban_mapping}
                                                    onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.BAN_PROPERTY]: {...prevState?.ban_property, [BanPropertyField.BAN_MAPPING]: value}}))}
                                                />
                                            </FieldBlock>
                                            <FormLayoutSeparator/>
                                            <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                <FormLayoutRows inline spacing={FlexContentSpacing.SMALL}>
                                                    <FieldBlock name={PartnerField.IS_MERCHANT} label={textPartners(`field.${PartnerField.IS_MERCHANT}`)} content={{direction: FlexContentDirection.ROW}}>
                                                        <ToggleSwitch
                                                            name={PartnerField.IS_MERCHANT}
                                                            checked={partner.is_merchant}
                                                            onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.IS_MERCHANT]: value}))}
                                                        />
                                                        <>
                                                            <Checkbox
                                                                name={PartnerField.IS_BRAZIL_MERCHANT}
                                                                checked={partner.is_brazil_merchant}
                                                                label={textPartners(`field.${PartnerField.IS_BRAZIL_MERCHANT}`)}
                                                                onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.IS_BRAZIL_MERCHANT]: value}))}
                                                                disabled={!partner.is_merchant}
                                                            />
                                                        </>
                                                    </FieldBlock>
                                                    <FieldBlock name={PartnerField.BRANDED} label={textPartners(`field.${PartnerField.BRANDED}`)}>
                                                        <ToggleSwitch
                                                            checked={partner.branded}
                                                            onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.BRANDED]: value}))}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutRows>
                                                <FieldBlock label={textPartners(`field.${PartnerField.CONSENT_STATUS}`)}>
                                                    <Select
                                                        value={partner.consent_status}
                                                        options={PARTNER_CONSENT_STATUSES.map((it) => ({value: it, label: it}))}
                                                        onChange={(option) => setPartner((prevState) => new Partner({...prevState, [PartnerField.CONSENT_STATUS]: option?.value}))}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutSeparator/>
                                            <FieldBlock name={PartnerField.FORBID_USER_CREATION} label={textPartners(`field.${PartnerField.FORBID_USER_CREATION}`)}>
                                                <ToggleSwitch
                                                    checked={partner.forbid_user_creation}
                                                    onChange={(value) => setPartner((prevState) => new Partner({...prevState, [PartnerField.FORBID_USER_CREATION]: value}))}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textPartners(`field.${PartnerField.PROPERTIES}.${PartnerPropertiesField.AWS_CUSTOMER_IDENTIFIER}`)}>
                                                <InputText
                                                    value={partner.properties.aws_customer_identifier}
                                                    onChange={(value) => handleChangeProperty(PartnerPropertiesField.AWS_CUSTOMER_IDENTIFIER, value)}
                                                />
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                            <Loadable loading={isLoading}>
                                <PartnerAuthorizations partnerId={partner.id}/>
                            </Loadable>
                            <RestrictedContent allowedTo={Authorization.LICENSES.name}>
                                <PartnerLicensesLinks partnerId={partner.id}/>
                            </RestrictedContent>
                            <Loadable loading={isLoading}>
                                <ContentBlock
                                    header={{
                                        title: {label: textPartners("section.sites")}, actions: (
                                            <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                                                <ContentBlockAction
                                                    action={Action.ADD}
                                                    onClick={() => setShowModalCreatePartnerSite(true)}
                                                />
                                            </RestrictedContent>
                                        )
                                    }}
                                >
                                    <Table
                                        columns={[
                                            {width: 20, label: textPartners(`field.site.${PartnerSiteField.SITE_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                            {width: 75, label: textPartners(`field.site.${PartnerSiteField.NAME}`)},
                                            {width: 5}
                                        ]}
                                    >
                                        {sites.map((site) =>
                                            <TableRow key={site.name}>
                                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{site.site_id}</TableColumn>
                                                <TableColumn>{site.name}</TableColumn>
                                                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                                    <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                                                        <ActionsMenu
                                                            iconTooltip={{icon: Action.EDIT.icon, text: textCommon(Action.EDIT.labelKey)}}
                                                            items={[
                                                                {label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveEditPartnerSite(site)},
                                                                {label: textCommon(Action.DELETE.labelKey), critical: true, separator: true, onClick: () => handleDeletePartnerSite(site)}
                                                            ]}
                                                        />
                                                    </RestrictedContent>
                                                </TableActionColumn>
                                            </TableRow>
                                        )}
                                    </Table>
                                </ContentBlock>
                            </Loadable>
                        </LayoutRows>
                    </LayoutColumns>
                    <Loadable loading={isLoadingPartnerAccounts}>
                        {accounts?.length &&
                            <ContentBlock
                                header={{
                                    title: {label: textPartners("section.linked_accounts")}, actions: (
                                        <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                                            <ContentBlockAction action={Action.ADD} onClick={() => setShowModalCreatePartnerAccount(true)}/>
                                        </RestrictedContent>
                                    )
                                }}
                            >
                                <Table
                                    columns={[
                                        {width: 5, label: textPartners("field.partner_account.status"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5, label: textPartners(`field.partner_account.${PartnerAccountField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textPartners(`field.partner_account.${PartnerAccountField.TYPE}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 15, label: textPartners(`field.partner_account.${PartnerAccountField.EMAIL}`)},
                                        {width: 20, label: textPartners("field.partner_account.name")},
                                        {width: 15, label: textPartners(`field.partner_account.${PartnerAccountField.PHONE}`)},
                                        {width: 15, label: textPartners(`field.partner_account.${PartnerAccountField.POSTIION}`)},
                                        {width: 10, label: textPartners(`field.partner_account.${PartnerAccountField.PROPERTIES}.${PartnerAccountPropertiesField.FRESHSALES_ID}`)},
                                        {width: 5}
                                    ]}
                                >
                                    {accounts.map((account) =>
                                        <TableRow key={account.id}>
                                            <TableColumn>
                                                <LabelStatus status={account.getStatus()}/>
                                            </TableColumn>
                                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{account.id}</TableColumn>
                                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                                <Tag label={account.type} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                                            </TableColumn>
                                            <TableColumn>{account.email}</TableColumn>
                                            <TableColumn>{account.fullName}</TableColumn>
                                            <TableColumn>{account.phone}</TableColumn>
                                            <TableColumn>{account.position}</TableColumn>
                                            <TableActionColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                                {account.properties.freshsales_id &&
                                                    <ValueCopier value={account.properties.freshsales_id} tooltip={textPartners("actions.copy_id")}/>
                                                }
                                            </TableActionColumn>
                                            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                                <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                                                    <ActionsMenu
                                                        iconTooltip={{icon: Action.EDIT.icon, text: textCommon(Action.EDIT.labelKey)}}
                                                        items={account.active ?
                                                            ([{label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveEditPartnerAccount(account)}]) :
                                                            ([
                                                                {label: textCommon(Action.ACTIVATE.labelKey), onClick: () => activateAccount(account)},
                                                                {label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveEditPartnerAccount(account)}
                                                            ])
                                                        }
                                                    />
                                                </RestrictedContent>
                                            </TableActionColumn>
                                        </TableRow>
                                    )}
                                </Table>
                            </ContentBlock>
                        }
                    </Loadable>
                </LayoutRows>
                <ModalCreatePartnerAccount
                    partnerId={partner.id}
                    active={isShowModalCreatePartnerAccount}
                    onClose={(refresh) => {
                        setShowModalCreatePartnerAccount(false);
                        refresh && loadPartnerAccounts();
                    }}
                />
                <ModalEditPartnerAccount
                    initAccount={activeEditPartnerAccount}
                    onClose={(refresh) => {
                        setActiveEditPartnerAccount(undefined);
                        refresh && loadPartnerAccounts();
                    }}
                />
                <ModalPartnerPricingLinks
                    partnerId={isShowModalPartnerPricingLinks ? partner.id : undefined}
                    onSubmit={() => setShowModalPartnerPricingLinks(false)}
                    onClose={() => setShowModalPartnerPricingLinks(false)}
                />
                <ModalCreatePartnerSite
                    active={isShowModalCreatePartnerSite}
                    partnerId={partner.id}
                    existingSites={sites}
                    onClose={(refresh) => {
                        setShowModalCreatePartnerSite(false);
                        refresh && handleRefreshPartnerSites();
                    }}
                />
                <ModalEditPartnerSite
                    initSite={activeEditPartnerSite}
                    existingSites={sites}
                    onClose={(refresh) => {
                        setActiveEditPartnerSite(undefined);
                        refresh && handleRefreshPartnerSites();
                    }}
                />
                <ModalConfirmDelete
                    active={isShowModalConfirmDelete}
                    entity="partner"
                    confirm={handleDelete}
                    cancel={() => setShowModalConfirmDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default PartnersDetails;
