import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {CategoryGroup} from "../model/audience/category/CategoryGroup";
import {TaxonomyTagLinkCategoryGroup} from "../model/taxonomy/tag/TaxonomyTagLinkCategoryGroup";

export class RestCategoryGroupLinkTag extends Rest implements RestService<TaxonomyTagLinkCategoryGroup> {
    rootPath = "/console-api/taxonomy-tag";

    list(categoryGroupId: number): Promise<TaxonomyTagLinkCategoryGroup[]> {
        return this._client.get(`${this.rootPath}/category-group/${categoryGroupId}`) as Promise<TaxonomyTagLinkCategoryGroup[]>;
    }

    search(params: any): Promise<SearchResult<TaxonomyTagLinkCategoryGroup>> {
        return new Promise<SearchResult<TaxonomyTagLinkCategoryGroup>>(() => {});
    }

    create(link: TaxonomyTagLinkCategoryGroup): Promise<TaxonomyTagLinkCategoryGroup> {
        return this._client.post(`${this.rootPath}/${link.tag_name}/category-group/${link.category_group_id}`);
    }

    get(id: string | number): Promise<TaxonomyTagLinkCategoryGroup> {
        return new Promise<TaxonomyTagLinkCategoryGroup>(() => {});
    }

    update(obj: TaxonomyTagLinkCategoryGroup): Promise<TaxonomyTagLinkCategoryGroup> {
        return new Promise<TaxonomyTagLinkCategoryGroup>(() => {});
    }

    delete(link: TaxonomyTagLinkCategoryGroup) {
        return this._client.delete(`${this.rootPath}/${link.tag_name}/category-group/${link.category_group_id}`);
    }

    updateTagsForCategoryGroup(categoryGroup: CategoryGroup, tags: string[]): Promise<TaxonomyTagLinkCategoryGroup[]> {
        return this._client.post(`${this.rootPath}/category-group/${categoryGroup.id}`, tags) as Promise<TaxonomyTagLinkCategoryGroup[]>;
    }
}
