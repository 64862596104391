import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Pricing} from "../../api/model/partner/pricing/Pricing";
import {PricingField} from "../../api/model/partner/pricing/PricingField";
import {PricingSearchQuery} from "../../api/model/partner/pricing/PricingSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreatePricing from "../../component/modal/pricings/ModalCreatePricing";
import {MainContentHeader, MainContentHeaderAction, PricingRow, SelectService} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";

function Pricings() {
    const {t: textPricings} = useTranslation(TranslationPortalFile.PRICINGS);
    const [isLoading, setLoading] = useState(true);
    const [pricings, setPricings] = useState<Pricing[]>([]);
    const [isShowModalCreatePricing, setShowModalCreatePricing] = useState(false);

    const alert = useAlert();
    const {buildSearchResult, ...search} = useSearch(Pricing, PricingSearchQuery);

    useEffect(() => {
        (async function () {
            try {
                const result = await session.restPricing.list();
                setPricings(result);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("pricings", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        let currentPricings = (pricings || []);
        if (search.searchQuery.query) {
            currentPricings = currentPricings.filter((it) => `${it.id} ${it.api_service} ${it.name} ${it.description}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        if (search.searchQuery.service) {
            currentPricings = currentPricings.filter((it) => it.api_service === search.searchQuery.service);
        }
        buildSearchResult(currentPricings);
    }, [pricings, search.searchQuery, buildSearchResult]);

    const handleSetAsDefault = async (pricing: Pricing) => {
        try {
            pricing.default = true;
            await session.restPricing.update(pricing);
            const newPricings = await session.restPricing.list();
            setPricings(newPricings);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("rate card", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.PRICINGS}>
                <RestrictedContent allowedTo={Authorization.PRICINGS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreatePricing(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textPricings("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters={true}
                        >
                            <SearchField label={textPricings(`field.${PricingField.API_SERVICE}`)}>
                                <SelectService
                                    value={search.searchQuery.service}
                                    services={Pricing.apiServices()}
                                    onChange={(service) => search.changeParam(SearchParamsField.SERVICE, service?.name)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textPricings(`field.${PricingField.STATUS}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textPricings(`field.${PricingField.ID}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: PricingField.ID, reverseOrder: false}},
                                        {width: 15, label: textPricings(`field.${PricingField.API_SERVICE}`), sort: {field: PricingField.NAME}},
                                        {width: 30, label: textPricings(`field.${PricingField.NAME}`), sort: {field: PricingField.NAME}},
                                        {width: 35, label: textPricings(`field.${PricingField.DESCRIPTION}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                        {width: 5}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((pricing: Pricing) => (
                                        <PricingRow
                                            key={pricing.id}
                                            pricing={pricing}
                                            onSetAsDefault={() => handleSetAsDefault(pricing)}
                                        />
                                    ))}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreatePricing
                    active={isShowModalCreatePricing}
                    onClose={() => setShowModalCreatePricing(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Pricings;
