import React, {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import {goToLoginGoogle} from "../../utils/helper";
import {LoggedAccount} from "../../common/api/interface/LoggedAccount";
import {pathLogin} from "../../common/utils/constants";
import {PORTAL} from "../../utils/Portal";
import {session} from "../../api/ApiSession";

type PrivateRouteProps = {
    children: JSX.Element;
    requiredAuthorization?: string;
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({children, requiredAuthorization}) => {
    const [account, setAccount] = useState<LoggedAccount>();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const isLogged = await session.isLogged();
                if (!isLogged) {
                    goToLoginGoogle(pathLogin, window.location.pathname + window.location.search);
                    return;
                }

                const account = await session.getAccount();
                setAccount(account);
            } catch (e) {
            } finally {
                setLoaded(true);
            }
        })();
    }, []);

    if (!loaded || !account) return <></>;

    if (requiredAuthorization && !account.hasAuthorization(requiredAuthorization)) {
        return <Navigate to={PORTAL.defaultPath}/>;
    }

    return children;
};

export default PrivateRoute;
