import {Action, ContentBlock, ContentBlockAction, Paragraph, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Authorization} from "../../../api/model/account/Authorization";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigEntryGroup} from "../../../api/model/categorizer/CategorizerConfigEntryGroup";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigField} from "../../../api/model/categorizer/config/CategorizerConfigField";
import {CategorizerConfigUrlCategorization} from "../../../api/model/categorizer/config/CategorizerConfigUrlCategorization";
import {CategorizerConfigUrlCategorizationField} from "../../../api/model/categorizer/config/CategorizerConfigUrlCategorizationField";
import {RestrictedContent} from "../../../common/component/widget";
import {CategorizerConfigEntriesProps} from "../../../utils/categorizer/CategorizerConfigEntriesProps";
import {TranslationPortalFile} from "../../../utils/constants";
import ModalEditCategorizerConfigUrlCategorization from "../../modal/categorizer/ModalEditCategorizerConfigUrlCategorization";
import {CategorizerConfigUrlCategorizationRow} from "../../snippet";
import ModalAddCategorizerConfigUrlCategorization from "../../modal/categorizer/ModalAddCategorizerConfigUrlCategorization";
import {CategorizerConfigEntryGroupField} from "../../../api/model/categorizer/CategorizerConfigEntryGroupField";

const CategorizerConfigUrlCategorizationEntries: FC<CategorizerConfigEntriesProps<CategorizerConfigUrlCategorization>> = ({configs, params, onSubmit, onDelete, editable}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const navigate = useNavigate();
    const [activeEditCategorizerConfigEntry, setActiveEditCategorizerConfigEntry] = useState<CategorizerConfigEntry<CategorizerConfigUrlCategorization>>();
    const [isShowModalAddCategorizerConfigUrlCategorization, setShowModalAddCategorizerConfigUrlCategorization] = useState(false);

    const hasConfig = (): boolean => {
        return configs.some((config) => config.match(params));
    };

    const handleClickUrlCategorizationRow = (entry: CategorizerConfigEntry<CategorizerConfigUrlCategorization>) => {
        if (entry.match(params) || editable) {
            setActiveEditCategorizerConfigEntry(entry);
            return;
        }
        const group = new CategorizerConfigEntryGroup({
            [CategorizerConfigEntryGroupField.DOMAIN]: entry.domain,
            [CategorizerConfigEntryGroupField.HOST]: entry.host,
            [CategorizerConfigEntryGroupField.PATH]: entry.path
        });
        navigate(group.getRoute());
    };

    return (
        <ContentBlock
            header={{
                title: {label: textCategorizer(`config_type.${CategorizerConfigType.URL_CATEGORIZATION.name}`), icon: {name: "link"}},
                actions: [
                    !hasConfig() &&
                    <RestrictedContent key="add_config_url_categorization" allowedTo={Authorization.CATEGORIZER.update}>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddCategorizerConfigUrlCategorization(true)}/>
                    </RestrictedContent>
                ]
            }}
        >
            <Paragraph>{textCategorizer(`config_type.description.${CategorizerConfigType.URL_CATEGORIZATION.name}`)}</Paragraph>
            <Table
                columns={[
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.DOMAIN}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.HOST}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.PATH}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 10, label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigField.DISABLED}`), styles: TableColumnStyle.ALIGN_CENTER},
                    {label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigUrlCategorizationField.EXTRACTIONS}`), styles: TableColumnStyle.ALIGN_CENTER},
                    {width: 2}
                ]}
            >
                {configs.map((entry) =>
                    <CategorizerConfigUrlCategorizationRow
                        key={entry.id}
                        entry={entry}
                        isEditable={entry.match(params) || !!editable}
                        onClick={() => handleClickUrlCategorizationRow(entry)}
                        onDelete={() => onDelete(entry)}
                    />
                )}
            </Table>
            <ModalAddCategorizerConfigUrlCategorization
                params={params}
                active={isShowModalAddCategorizerConfigUrlCategorization}
                onSubmit={() => {
                    onSubmit();
                    setShowModalAddCategorizerConfigUrlCategorization(false);
                }}
                onClose={() => setShowModalAddCategorizerConfigUrlCategorization(false)}
            />
            <ModalEditCategorizerConfigUrlCategorization
                initEntry={activeEditCategorizerConfigEntry}
                onSubmit={() => {
                    onSubmit();
                    setActiveEditCategorizerConfigEntry(undefined);
                }}
                onClose={() => setActiveEditCategorizerConfigEntry(undefined)}
            />
        </ContentBlock>
    );
};

export default CategorizerConfigUrlCategorizationEntries;
