import {UIEventManager} from "../../common/utils/UIEventManager";
import {AlertSeverity} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../constants";
import {useMemo} from "react";
import {MainAlertItem} from "../../common/component/widget/MainAlerts";
import {capitalize} from "../../common/utils/string";

enum AlertType {
    LOAD = "load",
    CREATE = "create",
    DELETE = "delete",
    DUPLICATE = "duplicate",
    UPDATE = "update",
    OTHER = "other"
}

function useAlert() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);

    return useMemo(() => {
        const push = (...alert: [any, AlertSeverity] | [MainAlertItem]) => {
            UIEventManager.alert(...alert);
        };

        const error = (type: AlertType, label: string, message: string) => {
            const key = `message.error.${type}`;
            push({
                text: capitalize(message),
                severity: AlertSeverity.DANGER,
                title: t(key, {label: label}),
                isCopiable: true,
                isClosable: true
            });
        };

        const success = (type: AlertType, label: string, action?: string) => {
            const key = `message.success.${type}`;
            push({
                text: capitalize(t(key, {label: label, action: action})),
                severity: AlertSeverity.SUCCESS
            });
        };

        const info = (type: string, label: string) => {
            const key = `message.info.${type}`;
            push({
                text: capitalize(t(key, {label: label})),
                severity: AlertSeverity.INFO
            });
        };

        return {
            createWithSuccess: (label: string) => success(AlertType.CREATE, label),
            deleteWithSuccess: (label: string) => success(AlertType.DELETE, label),
            duplicateWithSuccess: (label: string) => success(AlertType.DUPLICATE, label),
            updateWithSuccess: (label: string) => success(AlertType.UPDATE, label),
            actionWithSuccess: (label: string) => success(AlertType.OTHER, label),

            failToLoad: (label: string, message: string) => error(AlertType.LOAD, label, message),
            failToCreate: (label: string, message: string) => error(AlertType.CREATE, label, message),
            failToDelete: (label: string, message: string) => error(AlertType.DELETE, label, message),
            failToDuplicate: (label: string, message: string) => error(AlertType.DUPLICATE, label, message),
            failToUpdate: (label: string, message: string) => error(AlertType.UPDATE, label, message),
            failTo: (label: string, message: string) => error(AlertType.OTHER, label, message),

            copied: (label: string) => info("copied", label),

            push: push
        };
    }, [t]);
}

export default useAlert;
