import {SearchResult} from "../../../interface/SearchResult";
import {CategoryGroup} from "./CategoryGroup";

export class CategoryGroupSearchResult extends SearchResult<CategoryGroup> {
    total: number = 0;
    data: CategoryGroup[] = [];

    constructor(o?: {}) {
        super(CategoryGroup, o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            this.data = [];
            for (let key in o) {
                if (key === "data") {
                    if (o[key]) {
                        (o[key] as CategoryGroup[]).forEach((obj) => {
                            const it = new CategoryGroup(obj);
                            this.data.push(it);
                        });
                    } else this.data = [];
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    get total_elements(): number {
        return this.total;
    }

    get elements(): CategoryGroup[] {
        return this.data;
    }
}
