import {ApiModel} from "../../../../common/api/model/ApiModel";

export class DataleaksAuditContact extends ApiModel {
    first_name: string = "";
    last_name: string = "";
    position: string = "";
    email: string = "";
    phone: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
