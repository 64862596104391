import {ApiModel} from "../../../common/api/model/ApiModel";
import {DealTopTier} from "./DealTopTier";

export class DealTiers extends ApiModel {
    tiers1: string = DealTopTier.APPAREL.name;
    tiers2?: string;
    tiers3?: string;
    tiers4?: string;
    tiers5?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
