import {FC} from "react";
import {
    CategorizerConfigEnrichmentEntries,
    CategorizerConfigFilterEntries,
    CategorizerConfigIntentConfidenceEntries,
    CategorizerConfigLinksEntries,
    CategorizerConfigPrivacyEntries,
    CategorizerConfigSanitizerEntries,
    CategorizerConfigScraperApiEntries,
    CategorizerConfigUrlCategorizationEntries,
    CategorizerConfigUrlCleanerEntries
} from "../../../component/snippet";
import {CategorizerConfigEntriesProps} from "../../../utils/categorizer/CategorizerConfigEntriesProps";
import {CategorizerConfig} from "./config/CategorizerConfig";
import {CategorizerConfigEnrichment} from "./config/CategorizerConfigEnrichment";
import {CategorizerConfigFilter} from "./config/CategorizerConfigFilter";
import {CategorizerConfigIntentConfidence} from "./config/CategorizerConfigIntentConfidence";
import {CategorizerConfigLinks} from "./config/CategorizerConfigLinks";
import {CategorizerConfigPrivacy} from "./config/CategorizerConfigPrivacy";
import {CategorizerConfigSanitizer} from "./config/CategorizerConfigSanitizer";
import {CategorizerConfigScraperApi} from "./config/CategorizerConfigScraperApi";
import {CategorizerConfigUrlCategorization} from "./config/CategorizerConfigUrlCategorization";
import {CategorizerConfigUrlCleaner} from "./config/CategorizerConfigUrlCleaner";

export class CategorizerConfigType {
    static ENRICHMENT: CategorizerConfigType = new CategorizerConfigType("enrichment", CategorizerConfigEnrichment, CategorizerConfigEnrichmentEntries);
    static FILTER: CategorizerConfigType = new CategorizerConfigType("filter", CategorizerConfigFilter, CategorizerConfigFilterEntries);
    static INTENT_CONFIDENCE: CategorizerConfigType = new CategorizerConfigType("intent_confidence", CategorizerConfigIntentConfidence, CategorizerConfigIntentConfidenceEntries);
    static LINKS: CategorizerConfigType = new CategorizerConfigType("links", CategorizerConfigLinks, CategorizerConfigLinksEntries);
    static PRIVACY: CategorizerConfigType = new CategorizerConfigType("privacy", CategorizerConfigPrivacy, CategorizerConfigPrivacyEntries);
    static SANITIZER: CategorizerConfigType = new CategorizerConfigType("sanitizer", CategorizerConfigSanitizer, CategorizerConfigSanitizerEntries);
    static SCRAPER_API: CategorizerConfigType = new CategorizerConfigType("scraper_api", CategorizerConfigScraperApi, CategorizerConfigScraperApiEntries);
    static URL_CATEGORIZATION: CategorizerConfigType = new CategorizerConfigType("url_categorization", CategorizerConfigUrlCategorization, CategorizerConfigUrlCategorizationEntries);
    static URL_CLEANER: CategorizerConfigType = new CategorizerConfigType("url_cleaner", CategorizerConfigUrlCleaner, CategorizerConfigUrlCleanerEntries);

    name: string;
    component: FC<CategorizerConfigEntriesProps<CategorizerConfig>>;
    modelConfig: new () => CategorizerConfig;

    private constructor(name: string, modelConfig: new () => CategorizerConfig, component: FC<CategorizerConfigEntriesProps<any>>) {
        this.name = name;
        this.component = component;
        this.modelConfig = modelConfig;
    }

    static values(includesAllTypes?: boolean): CategorizerConfigType[] {
        if (includesAllTypes) {
            return [
                CategorizerConfigType.SANITIZER,
                CategorizerConfigType.INTENT_CONFIDENCE,
                CategorizerConfigType.FILTER,
                CategorizerConfigType.ENRICHMENT,
                CategorizerConfigType.URL_CATEGORIZATION,
                CategorizerConfigType.LINKS,
                CategorizerConfigType.PRIVACY,
                CategorizerConfigType.SCRAPER_API,
                CategorizerConfigType.URL_CLEANER
            ];
        }

        return [
            CategorizerConfigType.SANITIZER,
            CategorizerConfigType.INTENT_CONFIDENCE,
            CategorizerConfigType.FILTER,
            CategorizerConfigType.ENRICHMENT,
            CategorizerConfigType.URL_CATEGORIZATION
        ];
    }

    static getByName(name: string): CategorizerConfigType | undefined {
        return this.values(true).find((it) => it.name === name);
    }
}
