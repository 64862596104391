export class DealTopTier {
    static APPAREL: DealTopTier = new DealTopTier("Apparel");
    static ARTS_AND_ENTERTAINMENT: DealTopTier = new DealTopTier("Arts & Entertainment");
    static AUTO_AND_VEHICLES: DealTopTier = new DealTopTier("Auto & Vehicles");
    static BANK_AND_INSURANCE: DealTopTier = new DealTopTier("Bank & Insurance");
    static BEAUTY: DealTopTier = new DealTopTier("Beauty");
    static BUSINESS: DealTopTier = new DealTopTier("Business");
    static CHILDCARE: DealTopTier = new DealTopTier("Childcare");
    static CONSUMER_NON_PACKAGED_GOODS: DealTopTier = new DealTopTier("Consumer Non Packaged Goods");
    static CONSUMER_PACKAGED_GOODS: DealTopTier = new DealTopTier("Consumer Packaged Goods");
    static CUSTOM_AUDIENCE: DealTopTier = new DealTopTier("Custom Audience");
    static DEMOGRAPHICS: DealTopTier = new DealTopTier("Demographics");
    static FOOD_AND_DRINK: DealTopTier = new DealTopTier("FMCG I Food & Drink");
    static FASHION: DealTopTier = new DealTopTier("Fashion");
    static HABITS_AND_LIFESTYLE: DealTopTier = new DealTopTier("Habits & Lifestyle");
    static HIGH_TECH: DealTopTier = new DealTopTier("High Tech");
    static HOME_AND_GARDEN: DealTopTier = new DealTopTier("Home & Garden");
    static LIFE_EVENTS: DealTopTier = new DealTopTier("Life Events");
    static LOCATION_AREAS: DealTopTier = new DealTopTier("Location Areas");
    static PERSONAL_CARE_AND_BEAUTY: DealTopTier = new DealTopTier("Personal Care and Beauty");
    static PETS_AND_ANIMAL: DealTopTier = new DealTopTier("Pets & Animal");
    static SPECIAL_EVENTS: DealTopTier = new DealTopTier("Special Events");
    static SPORTS: DealTopTier = new DealTopTier("Sports");
    static TRAVEL: DealTopTier = new DealTopTier("Travel");
    static WELLBEING: DealTopTier = new DealTopTier("Wellbeing");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): DealTopTier[] {
        return [
            DealTopTier.APPAREL,
            DealTopTier.ARTS_AND_ENTERTAINMENT,
            DealTopTier.AUTO_AND_VEHICLES,
            DealTopTier.BANK_AND_INSURANCE,
            DealTopTier.BEAUTY,
            DealTopTier.BUSINESS,
            DealTopTier.CHILDCARE,
            DealTopTier.CONSUMER_NON_PACKAGED_GOODS,
            DealTopTier.CONSUMER_PACKAGED_GOODS,
            DealTopTier.CUSTOM_AUDIENCE,
            DealTopTier.DEMOGRAPHICS,
            DealTopTier.FOOD_AND_DRINK,
            DealTopTier.FASHION,
            DealTopTier.HABITS_AND_LIFESTYLE,
            DealTopTier.HIGH_TECH,
            DealTopTier.HOME_AND_GARDEN,
            DealTopTier.LIFE_EVENTS,
            DealTopTier.LOCATION_AREAS,
            DealTopTier.PERSONAL_CARE_AND_BEAUTY,
            DealTopTier.PETS_AND_ANIMAL,
            DealTopTier.SPECIAL_EVENTS,
            DealTopTier.SPORTS,
            DealTopTier.TRAVEL,
            DealTopTier.WELLBEING
        ];
    }
}
