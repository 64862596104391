import moment from "moment";
import {CommonApiSession} from "../common/api/CommonApiSession";
import {Cookie} from "../common/api/model/Cookie";
import {sortItems} from "../common/utils/helper";
import {ApiClient} from "./ApiClient";
import {GoogleAuthParams} from "./model/account/google/GoogleAuthParams";
import {CategoryGroup} from "./model/audience/category/CategoryGroup";
import {Partner} from "./model/partner/Partner";
import {User} from "./model/user/User";
import {RestAdsAccess} from "./rest/RestAdsAccess";
import {RestAuthorizationPartner} from "./rest/RestAuthorizationPartner";
import {RestAuthorizationUser} from "./rest/RestAuthorizationUser";
import {RestCategorizer} from "./rest/RestCategorizer";
import {RestCategorizerDomain} from "./rest/RestCategorizerDomain";
import {RestCategorizerEntry} from "./rest/RestCategorizerEntry";
import {RestCategory} from "./rest/RestCategory";
import {RestCategoryBQRequest} from "./rest/RestCategoryBQRequest";
import {RestCategoryGroup} from "./rest/RestCategoryGroup";
import {RestCategoryGroupLinkKeyword} from "./rest/RestCategoryGroupLinkKeyword";
import {RestCategoryGroupLinkTag} from "./rest/RestCategoryGroupLinkTag";
import {RestCategoryLinkExternal} from "./rest/RestCategoryLinkExternal";
import {RestCategoryLinkSegment} from "./rest/RestCategoryLinkSegment";
import {RestCmpCloudflare} from "./rest/RestCmpCloudflare";
import {RestCmpConfiguration} from "./rest/RestCmpConfiguration";
import {RestCmpList} from "./rest/RestCmpList";
import {RestCmpSirdataListLanguage} from "./rest/RestCmpSirdataListLanguage";
import {RestCmpSirdataListVendor} from "./rest/RestCmpSirdataListVendor";
import {RestCmpSirdataListVersion} from "./rest/RestCmpSirdataListVersion";
import {RestCmpTranslations} from "./rest/RestCmpTranslations";
import {RestCmpVersion} from "./rest/RestCmpVersion";
import {RestCmpWebinar} from "./rest/RestCmpWebinar";
import {RestCurrency} from "./rest/RestCurrency";
import {RestCustomerCounting} from "./rest/RestCustomerCounting";
import {RestCustomerOrder} from "./rest/RestCustomerOrder";
import {RestCustomerStorage} from "./rest/RestCustomerStorage";
import {RestDataleaksAudit} from "./rest/RestDataleaksAudit";
import {RestDataleaksQuestion} from "./rest/RestDataleaksQuestion";
import {RestDataleaksVersion} from "./rest/RestDataleaksVersion";
import {RestDeal} from "./rest/RestDeal";
import {RestDistribution} from "./rest/RestDistribution";
import {RestDistributionLicense} from "./rest/RestDistributionLicense";
import {RestDocs} from "./rest/RestDocs";
import {RestHelperDomain} from "./rest/RestHelperDomain";
import {RestJira} from "./rest/RestJira";
import {RestList} from "./rest/RestList";
import {RestMedia} from "./rest/RestMedia";
import {RestModeling} from "./rest/RestModeling";
import {RestNotification} from "./rest/RestNotification";
import {RestPartner} from "./rest/RestPartner";
import {RestPartnerAccount} from "./rest/RestPartnerAccount";
import {RestPartnerAuthorization} from "./rest/RestPartnerAuthorization";
import {RestPartnerBillingInfo} from "./rest/RestPartnerBillingInfo";
import {RestPartnerDomain} from "./rest/RestPartnerDomain";
import {RestPartnerLicense} from "./rest/RestPartnerLicense";
import {RestPartnerOrganization} from "./rest/RestPartnerOrganization";
import {RestPartnerOrganizationEmailDomain} from "./rest/RestPartnerOrganizationEmailDomain";
import {RestPartnerOrganizationGroup} from "./rest/RestPartnerOrganizationGroup";
import {RestPartnerOrganizationRequest} from "./rest/RestPartnerOrganizationRequest";
import {RestPartnerPricing} from "./rest/RestPartnerPricing";
import {RestPartnerSite} from "./rest/RestPartnerSite";
import {RestPortalContent} from "./rest/RestPortalContent";
import {RestPricing} from "./rest/RestPricing";
import {RestRevenuesImport} from "./rest/RestRevenuesImport";
import {RestSeat} from "./rest/RestSeat";
import {RestSegment} from "./rest/RestSegment";
import {RestSegmentLinkAreaCode} from "./rest/RestSegmentLinkAreaCode";
import {RestSegmentLinkCategory} from "./rest/RestSegmentLinkCategory";
import {RestSegmentLinkExternal} from "./rest/RestSegmentLinkExternal";
import {RestSegmentLinkTag} from "./rest/RestSegmentLinkTag";
import {RestSgtmContainer} from "./rest/RestSgtmContainer";
import {RestTaxonomyTag} from "./rest/RestTaxonomyTag";
import {RestUser} from "./rest/RestUser";
import {RestUserAccount} from "./rest/RestUserAccount";
import {RestUserAuthorization} from "./rest/RestUserAuthorization";
import {RestUserProfile} from "./rest/RestUserProfile";
import {Segment} from "./model/audience/segment/Segment";
import {PartnerField} from "./model/partner/PartnerField";
import {ApiService} from "./model/ApiService";
import {Category} from "./model/audience/category/Category";
import {CategoryField} from "./model/audience/category/CategoryField";
import {CategoryType} from "./model/audience/category/CategoryType";
import {SegmentField} from "./model/audience/segment/SegmentField";
import {CategoryGroupField} from "./model/audience/category/CategoryGroupField";

export class ApiSession extends CommonApiSession {

    _client: ApiClient;

    private _restAdsAccess: RestAdsAccess | undefined;
    private _restAuthorizationPartner: RestAuthorizationPartner | undefined;
    private _restAuthorizationUser: RestAuthorizationUser | undefined;
    private _restCategorizer: RestCategorizer | undefined;
    private _restCategorizerDomain: RestCategorizerDomain | undefined;
    private _restCategorizerEntry: RestCategorizerEntry | undefined;
    private _restCategory: RestCategory | undefined;
    private _restCategoryBQRequest: RestCategoryBQRequest | undefined;
    private _restCategoryGroup: RestCategoryGroup | undefined;
    private _restCategoryGroupLinkKeyword: RestCategoryGroupLinkKeyword | undefined;
    private _restCategoryGroupLinkTag: RestCategoryGroupLinkTag | undefined;
    private _restCategoryLinkExternal: RestCategoryLinkExternal | undefined;
    private _restCategoryLinkSegment: RestCategoryLinkSegment | undefined;
    private _restCmpCloudflare: RestCmpCloudflare | undefined;
    private _restCmpConfiguration: RestCmpConfiguration | undefined;
    private _restCmpList: RestCmpList | undefined;
    private _restCmpSirdataListLanguage: RestCmpSirdataListLanguage | undefined;
    private _restCmpSirdataListVendor: RestCmpSirdataListVendor | undefined;
    private _restCmpSirdataListVersion: RestCmpSirdataListVersion | undefined;
    private _restCmpTranslations: RestCmpTranslations | undefined;
    private _restCmpVersion: RestCmpVersion | undefined;
    private _restCmpWebinar: RestCmpWebinar | undefined;
    private _restCurrency: RestCurrency | undefined;
    private _restCustomerCounting: RestCustomerCounting | undefined;
    private _restCustomerOrder: RestCustomerOrder | undefined;
    private _restCustomerStorage: RestCustomerStorage | undefined;
    private _restDataleaksAudit: RestDataleaksAudit | undefined;
    private _restDataleaksQuestion: RestDataleaksQuestion | undefined;
    private _restDataleaksVersion: RestDataleaksVersion | undefined;
    private _restDeal: RestDeal | undefined;
    private _restDistribution: RestDistribution | undefined;
    private _restDistributionLicense: RestDistributionLicense | undefined;
    private _restDocs: RestDocs | undefined;
    private _restHelperDomain: RestHelperDomain | undefined;
    private _restJira: RestJira | undefined;
    private _restList: RestList | undefined;
    private _restMedia: RestMedia | undefined;
    private _restModeling: RestModeling | undefined;
    private _restNotification: RestNotification | undefined;
    private _restPartner: RestPartner | undefined;
    private _restPartnerAccount: RestPartnerAccount | undefined;
    private _restPartnerAuthorization: RestPartnerAuthorization | undefined;
    private _restPartnerBillingInfo: RestPartnerBillingInfo | undefined;
    private _restPartnerDomain: RestPartnerDomain | undefined;
    private _restPartnerLicense: RestPartnerLicense | undefined;
    private _restPartnerOrganization: RestPartnerOrganization | undefined;
    private _restPartnerOrganizationEmailDomain: RestPartnerOrganizationEmailDomain | undefined;
    private _restPartnerOrganizationGroup: RestPartnerOrganizationGroup | undefined;
    private _restPartnerOrganizationRequest: RestPartnerOrganizationRequest | undefined;
    private _restPartnerPricing: RestPartnerPricing | undefined;
    private _restPartnerSite: RestPartnerSite | undefined;
    private _restPortalContent: RestPortalContent | undefined;
    private _restPricing: RestPricing | undefined;
    private _restRevenuesImport: RestRevenuesImport | undefined;
    private _restSeat: RestSeat | undefined;
    private _restSegment: RestSegment | undefined;
    private _restSegmentLinkAreaCode: RestSegmentLinkAreaCode | undefined;
    private _restSegmentLinkCategory: RestSegmentLinkCategory | undefined;
    private _restSegmentLinkExternal: RestSegmentLinkExternal | undefined;
    private _restSegmentLinkTag: RestSegmentLinkTag | undefined;
    private _restSgtmContainer: RestSgtmContainer | undefined;
    private _restTaxonomyTag: RestTaxonomyTag | undefined;
    private _restUser: RestUser | undefined;
    private _restUserAccount: RestUserAccount | undefined;
    private _restUserAuthorization: RestUserAuthorization | undefined;
    private _restUserProfile: RestUserProfile | undefined;

    private _categories: Category[] | undefined;
    private _categoriesByType: Map<string, Category[]> = new Map<string, Category[]>();
    private _categoryGroups: CategoryGroup[] | undefined;
    private _partners: Partner[] | undefined;
    private _partnersByService: Map<string, Partner[]> = new Map<string, Partner[]>();
    private _segments: Segment[] | undefined;
    private _users: User[] | undefined;
    private _activeUsers: User[] | undefined;
    private _userId: number = 0;

    constructor() {
        super("si_token");
        this._client = new ApiClient();
    }

    get restAdsAccess(): RestAdsAccess {
        return this._restAdsAccess !== undefined ? this._restAdsAccess : this._restAdsAccess = new RestAdsAccess(this._commonClient);
    }

    get restAuthorizationPartner(): RestAuthorizationPartner {
        return this._restAuthorizationPartner !== undefined ? this._restAuthorizationPartner : this._restAuthorizationPartner = new RestAuthorizationPartner(this._commonClient);
    }

    get restAuthorizationUser(): RestAuthorizationUser {
        return this._restAuthorizationUser !== undefined ? this._restAuthorizationUser : this._restAuthorizationUser = new RestAuthorizationUser(this._commonClient);
    }

    get restCategorizer(): RestCategorizer {
        return this._restCategorizer !== undefined ? this._restCategorizer : this._restCategorizer = new RestCategorizer(this._commonClient);
    }

    get restCategorizerDomain(): RestCategorizerDomain {
        return this._restCategorizerDomain !== undefined ? this._restCategorizerDomain : this._restCategorizerDomain = new RestCategorizerDomain(this._commonClient);
    }

    get restCategorizerEntry(): RestCategorizerEntry {
        return this._restCategorizerEntry !== undefined ? this._restCategorizerEntry : this._restCategorizerEntry = new RestCategorizerEntry(this._commonClient);
    }

    get restCategory(): RestCategory {
        return this._restCategory !== undefined ? this._restCategory : this._restCategory = new RestCategory(this._commonClient);
    }

    get restCategoryBQRequest(): RestCategoryBQRequest {
        return this._restCategoryBQRequest !== undefined ? this._restCategoryBQRequest : this._restCategoryBQRequest = new RestCategoryBQRequest(this._commonClient);
    }

    get restCategoryGroup(): RestCategoryGroup {
        return this._restCategoryGroup !== undefined ? this._restCategoryGroup : this._restCategoryGroup = new RestCategoryGroup(this._commonClient);
    }

    get restCategoryGroupLinkKeyword(): RestCategoryGroupLinkKeyword {
        return this._restCategoryGroupLinkKeyword !== undefined ? this._restCategoryGroupLinkKeyword : this._restCategoryGroupLinkKeyword = new RestCategoryGroupLinkKeyword(this._commonClient);
    }

    get restCategoryGroupLinkTag(): RestCategoryGroupLinkTag {
        return this._restCategoryGroupLinkTag !== undefined ? this._restCategoryGroupLinkTag : this._restCategoryGroupLinkTag = new RestCategoryGroupLinkTag(this._commonClient);
    }

    get restCategoryLinkExternal(): RestCategoryLinkExternal {
        return this._restCategoryLinkExternal !== undefined ? this._restCategoryLinkExternal : this._restCategoryLinkExternal = new RestCategoryLinkExternal(this._commonClient);
    }

    get restCategoryLinkSegment(): RestCategoryLinkSegment {
        return this._restCategoryLinkSegment !== undefined ? this._restCategoryLinkSegment : this._restCategoryLinkSegment = new RestCategoryLinkSegment(this._commonClient);
    }

    get restCmpCloudflare(): RestCmpCloudflare {
        return this._restCmpCloudflare !== undefined ? this._restCmpCloudflare : this._restCmpCloudflare = new RestCmpCloudflare(this._commonClient);
    }

    get restCmpConfiguration(): RestCmpConfiguration {
        return this._restCmpConfiguration !== undefined ? this._restCmpConfiguration : this._restCmpConfiguration = new RestCmpConfiguration(this._commonClient);
    }

    get restCmpList(): RestCmpList {
        return this._restCmpList !== undefined ? this._restCmpList : this._restCmpList = new RestCmpList(this._commonClient);
    }

    get restCmpSirdataListLanguage(): RestCmpSirdataListLanguage {
        return this._restCmpSirdataListLanguage !== undefined ? this._restCmpSirdataListLanguage : this._restCmpSirdataListLanguage = new RestCmpSirdataListLanguage(this._commonClient);
    }

    get restCmpSirdataListVendor(): RestCmpSirdataListVendor {
        return this._restCmpSirdataListVendor !== undefined ? this._restCmpSirdataListVendor : this._restCmpSirdataListVendor = new RestCmpSirdataListVendor(this._commonClient);
    }

    get restCmpSirdataListVersion(): RestCmpSirdataListVersion {
        return this._restCmpSirdataListVersion !== undefined ? this._restCmpSirdataListVersion : this._restCmpSirdataListVersion = new RestCmpSirdataListVersion(this._commonClient);
    }

    get restCmpTranslations(): RestCmpTranslations {
        return this._restCmpTranslations !== undefined ? this._restCmpTranslations : this._restCmpTranslations = new RestCmpTranslations(this._commonClient);
    }

    get restCmpVersion(): RestCmpVersion {
        return this._restCmpVersion !== undefined ? this._restCmpVersion : this._restCmpVersion = new RestCmpVersion(this._commonClient);
    }

    get restCmpWebinar(): RestCmpWebinar {
        return this._restCmpWebinar !== undefined ? this._restCmpWebinar : this._restCmpWebinar = new RestCmpWebinar(this._commonClient);
    }

    get restCurrency(): RestCurrency {
        return this._restCurrency !== undefined ? this._restCurrency : this._restCurrency = new RestCurrency(this._commonClient);
    }

    get restCustomerCounting(): RestCustomerCounting {
        return this._restCustomerCounting !== undefined ? this._restCustomerCounting : this._restCustomerCounting = new RestCustomerCounting(this._commonClient);
    }

    get restCustomerOrder(): RestCustomerOrder {
        return this._restCustomerOrder !== undefined ? this._restCustomerOrder : this._restCustomerOrder = new RestCustomerOrder(this._commonClient);
    }

    get restCustomerStorage(): RestCustomerStorage {
        return this._restCustomerStorage !== undefined ? this._restCustomerStorage : this._restCustomerStorage = new RestCustomerStorage(this._commonClient);
    }

    get restDataleaksAudit(): RestDataleaksAudit {
        return this._restDataleaksAudit !== undefined ? this._restDataleaksAudit : this._restDataleaksAudit = new RestDataleaksAudit(this._commonClient);
    }

    get restDataleaksQuestion(): RestDataleaksQuestion {
        return this._restDataleaksQuestion !== undefined ? this._restDataleaksQuestion : this._restDataleaksQuestion = new RestDataleaksQuestion(this._commonClient);
    }

    get restDataleaksVersion(): RestDataleaksVersion {
        return this._restDataleaksVersion !== undefined ? this._restDataleaksVersion : this._restDataleaksVersion = new RestDataleaksVersion(this._commonClient);
    }

    get restDeal(): RestDeal {
        return this._restDeal !== undefined ? this._restDeal : this._restDeal = new RestDeal(this._commonClient);
    }

    get restDistribution(): RestDistribution {
        return this._restDistribution !== undefined ? this._restDistribution : this._restDistribution = new RestDistribution(this._commonClient);
    }

    get restDistributionLicense(): RestDistributionLicense {
        return this._restDistributionLicense !== undefined ? this._restDistributionLicense : this._restDistributionLicense = new RestDistributionLicense(this._commonClient);
    }

    get restDocs(): RestDocs {
        return this._restDocs !== undefined ? this._restDocs : this._restDocs = new RestDocs(this._commonClient);
    }

    get restHelperDomain(): RestHelperDomain {
        return this._restHelperDomain !== undefined ? this._restHelperDomain : this._restHelperDomain = new RestHelperDomain(this._commonClient);
    }

    get restJira(): RestJira {
        return this._restJira !== undefined ? this._restJira : this._restJira = new RestJira(this._commonClient);
    }

    get restList(): RestList {
        return this._restList !== undefined ? this._restList : this._restList = new RestList(this._commonClient);
    }

    get restMedia(): RestMedia {
        return this._restMedia !== undefined ? this._restMedia : this._restMedia = new RestMedia(this._commonClient);
    }

    get restModeling(): RestModeling {
        return this._restModeling !== undefined ? this._restModeling : this._restModeling = new RestModeling(this._commonClient);
    }

    get restNotification(): RestNotification {
        return this._restNotification !== undefined ? this._restNotification : this._restNotification = new RestNotification(this._commonClient);
    }

    get restPartner(): RestPartner {
        return this._restPartner !== undefined ? this._restPartner : this._restPartner = new RestPartner(this._commonClient);
    }

    get restPartnerAccount(): RestPartnerAccount {
        return this._restPartnerAccount !== undefined ? this._restPartnerAccount : this._restPartnerAccount = new RestPartnerAccount(this._commonClient);
    }

    get restPartnerAuthorization(): RestPartnerAuthorization {
        return this._restPartnerAuthorization !== undefined ? this._restPartnerAuthorization : this._restPartnerAuthorization = new RestPartnerAuthorization(this._commonClient);
    }

    get restPartnerBillingInfo(): RestPartnerBillingInfo {
        return this._restPartnerBillingInfo !== undefined ? this._restPartnerBillingInfo : this._restPartnerBillingInfo = new RestPartnerBillingInfo(this._commonClient);
    }

    get restPartnerDomain(): RestPartnerDomain {
        return this._restPartnerDomain !== undefined ? this._restPartnerDomain : this._restPartnerDomain = new RestPartnerDomain(this._commonClient);
    }

    get restPartnerLicense(): RestPartnerLicense {
        return this._restPartnerLicense !== undefined ? this._restPartnerLicense : this._restPartnerLicense = new RestPartnerLicense(this._commonClient);
    }

    get restPartnerOrganization(): RestPartnerOrganization {
        return this._restPartnerOrganization !== undefined ? this._restPartnerOrganization : this._restPartnerOrganization = new RestPartnerOrganization(this._commonClient);
    }

    get restPartnerOrganizationEmailDomain(): RestPartnerOrganizationEmailDomain {
        return this._restPartnerOrganizationEmailDomain !== undefined ? this._restPartnerOrganizationEmailDomain : this._restPartnerOrganizationEmailDomain = new RestPartnerOrganizationEmailDomain(this._commonClient);
    }

    get restPartnerOrganizationGroup(): RestPartnerOrganizationGroup {
        return this._restPartnerOrganizationGroup !== undefined ? this._restPartnerOrganizationGroup : this._restPartnerOrganizationGroup = new RestPartnerOrganizationGroup(this._commonClient);
    }

    get restPartnerOrganizationRequest(): RestPartnerOrganizationRequest {
        return this._restPartnerOrganizationRequest !== undefined ? this._restPartnerOrganizationRequest : this._restPartnerOrganizationRequest = new RestPartnerOrganizationRequest(this._commonClient);
    }

    get restPartnerPricing(): RestPartnerPricing {
        return this._restPartnerPricing !== undefined ? this._restPartnerPricing : this._restPartnerPricing = new RestPartnerPricing(this._commonClient);
    }

    get restPartnerSite(): RestPartnerSite {
        return this._restPartnerSite !== undefined ? this._restPartnerSite : this._restPartnerSite = new RestPartnerSite(this._commonClient);
    }

    get restPortalContent(): RestPortalContent {
        return this._restPortalContent !== undefined ? this._restPortalContent : this._restPortalContent = new RestPortalContent(this._commonClient);
    }

    get restPricing(): RestPricing {
        return this._restPricing !== undefined ? this._restPricing : this._restPricing = new RestPricing(this._commonClient);
    }

    get restRevenuesImport(): RestRevenuesImport {
        return this._restRevenuesImport !== undefined ? this._restRevenuesImport : this._restRevenuesImport = new RestRevenuesImport(this._commonClient);
    }

    get restSeat(): RestSeat {
        return this._restSeat !== undefined ? this._restSeat : this._restSeat = new RestSeat(this._commonClient);
    }

    get restSegment(): RestSegment {
        return this._restSegment !== undefined ? this._restSegment : this._restSegment = new RestSegment(this._commonClient);
    }

    get restSegmentLinkAreaCode(): RestSegmentLinkAreaCode {
        return this._restSegmentLinkAreaCode !== undefined ? this._restSegmentLinkAreaCode : this._restSegmentLinkAreaCode = new RestSegmentLinkAreaCode(this._commonClient);
    }

    get restSegmentLinkCategory(): RestSegmentLinkCategory {
        return this._restSegmentLinkCategory !== undefined ? this._restSegmentLinkCategory : this._restSegmentLinkCategory = new RestSegmentLinkCategory(this._commonClient);
    }

    get restSegmentLinkExternal(): RestSegmentLinkExternal {
        return this._restSegmentLinkExternal !== undefined ? this._restSegmentLinkExternal : this._restSegmentLinkExternal = new RestSegmentLinkExternal(this._commonClient);
    }

    get restSegmentLinkTag(): RestSegmentLinkTag {
        return this._restSegmentLinkTag !== undefined ? this._restSegmentLinkTag : this._restSegmentLinkTag = new RestSegmentLinkTag(this._commonClient);
    }

    get restSgtmContainer(): RestSgtmContainer {
        return this._restSgtmContainer !== undefined ? this._restSgtmContainer : this._restSgtmContainer = new RestSgtmContainer(this._commonClient);
    }

    get restTaxonomyTag(): RestTaxonomyTag {
        return this._restTaxonomyTag !== undefined ? this._restTaxonomyTag : this._restTaxonomyTag = new RestTaxonomyTag(this._commonClient);
    }

    get restUser(): RestUser {
        return this._restUser !== undefined ? this._restUser : this._restUser = new RestUser(this._commonClient);
    }

    get restUserAccount(): RestUserAccount {
        return this._restUserAccount !== undefined ? this._restUserAccount : this._restUserAccount = new RestUserAccount(this._commonClient);
    }

    get restUserAuthorization(): RestUserAuthorization {
        return this._restUserAuthorization !== undefined ? this._restUserAuthorization : this._restUserAuthorization = new RestUserAuthorization(this._commonClient);
    }

    get restUserProfile(): RestUserProfile {
        return this._restUserProfile !== undefined ? this._restUserProfile : this._restUserProfile = new RestUserProfile(this._commonClient);
    }

    async loadAccount() {
        const account = await this.restUserAccount.get();
        this.setAccount(account);
        this.setUserId(account.id);
    }

    async isLogged() {
        return this._client.checkLogged();
    }

    async loginGoogle(code: string, redirect_uri: string): Promise<CommonApiSession> {
        await this._client.loginGoogle(code, redirect_uri);
        Cookie.write(this._tokenCookie, this.token || "", moment().add(3, "months").toDate());
        return this;
    }

    async loginGoogleAsPartner(params: GoogleAuthParams): Promise<string> {
        return await this._client.loginGoogleAsPartner(params);
    }

    async getCategories(): Promise<Category[]> {
        if (!this._categories) {
            let categories = await session.restList.getCategories();
            categories = sortItems(categories, CategoryField.CODE_NAME);
            this._categories = categories;
        }
        return this._categories;
    }

    async getCategoriesByType(type?: CategoryType): Promise<Category[]> {
        if (!type) {
            return this.getCategories();
        }

        if (!this._categoriesByType.get(type.name)) {
            let categories = await session.restList.getCategories(type);
            categories = sortItems(categories, CategoryField.CODE_NAME);
            this._categoriesByType.set(type.name, categories);
        }
        return this._categoriesByType.get(type.name) || [];
    }

    async getCategoryGroups(): Promise<CategoryGroup[]> {
        if (!this._categoryGroups) {
            let categoryGroups = await session.restList.getCategoryGroups();
            categoryGroups = sortItems(categoryGroups, CategoryGroupField.CODE_NAME);
            this._categoryGroups = categoryGroups;
        }
        return this._categoryGroups;
    }

    async getPartners(): Promise<Partner[]> {
        if (!this._partners) {
            let partners = await session.restList.getPartners();
            this._partners = sortItems(partners, PartnerField.ID);
        }
        return this._partners;
    }

    async getPartnersByService(service?: ApiService): Promise<Partner[]> {
        if (!service) {
            return this.getPartners();
        }

        if (!this._partnersByService.get(service.name)) {
            let partners = await session.restList.getPartners(service.name);
            partners = sortItems(partners, PartnerField.ID);
            this._partnersByService.set(service.name, partners);
        }
        return this._partnersByService.get(service.name) || [];
    }

    async getSegments(): Promise<Segment[]> {
        if (!this._segments) {
            let segments = await session.restList.getSegments();
            segments = sortItems(segments, SegmentField.TIERS_NAME);
            this._segments = segments;
        }
        return this._segments;
    }

    get userId(): number {
        return this._userId;
    }

    setUserId(id: number) {
        this._userId = id;
    }

    async getUsers(): Promise<User[]> {
        if (!this._users) {
            let users = await session.restList.getUsers(true);
            this._users = sortItems(users, "fullName");
        }
        return this._users;
    }

    async getActiveUsers(): Promise<User[]> {
        if (!this._activeUsers) {
            let users = await session.restList.getUsers();
            this._activeUsers = sortItems(users, "fullName");
        }
        return this._activeUsers;
    }

    async getUserById(id: number): Promise<User | undefined> {
        const users = await this.getUsers();
        return users.find((it) => it.id === id);
    }

    async getPartnerById(id: number): Promise<Partner | undefined> {
        const partners = await this.getPartners();
        return partners.find((it) => it.id === id);
    }
}

export const session = new ApiSession();
