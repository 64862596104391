import {SvgSirdataLogoWhite} from "@sirdata/ui-lib";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useLocation} from "react-router-dom";
import {session} from "../api/ApiSession";
import {ErrorResponse} from "../common/api/http/ErrorResponse";
import {QueryString} from "../common/api/model/QueryString";
import {MainAlerts, Wrapper} from "../common/component/widget";
import {pathLogin} from "../common/utils/constants";
import {TranslationPortalFile} from "../utils/constants";
import {goToLoginGoogle} from "../utils/helper";
import {PORTAL} from "../utils/Portal";
import useAlert from "../utils/hooks/useAlert";

function Login() {
    const {t: textLogin} = useTranslation(TranslationPortalFile.LOGIN);
    const navigate = useNavigate();
    const location = useLocation();
    const alert = useAlert();

    useEffect(() => {
        (async () => {
            const queryString = location.search;
            const code = QueryString.getParam(queryString, "code");
            const goTo = QueryString.getParam(queryString, "state");
            if (!code) return;

            try {
                await session.loginGoogle(code, window.location.origin + window.location.pathname);
                await session.loadAccount();
                if (goTo) {
                    window.location.href = goTo;
                } else navigate(PORTAL.defaultPath);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failTo("log in", e.message);
                }
                navigate(pathLogin);
            }
        })();
    }, [navigate, location.search, alert]);

    useEffect(() => {
        if (session) {
            session.isLogged()
                .then((logged) => {
                    if (logged) navigate(PORTAL.defaultPath);
                })
                .catch(() => {
                });
        }
    }, [navigate]);

    return (
        <Wrapper>
            <div className="login-1col">
                <div className="login-1col-content">
                    <div className="main-logo">
                        <SvgSirdataLogoWhite/>
                    </div>
                    <section className="login-1col-content-section">
                        <div className="login-1col-content-section-text">{textLogin("description")}</div>
                        <div className="btn-google" onClick={() => goToLoginGoogle(pathLogin)}>
                            <span>Sign in with Google</span>
                        </div>
                    </section>
                </div>
            </div>
            <MainAlerts/>
        </Wrapper>
    );
}

export default Login;
