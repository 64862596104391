import {Color, IconTooltip, TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Notification} from "../../../../api/model/notifications/Notification";
import {Formatter} from "../../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelService, LabelStatus, Tag} from "../../index";

type NotificationRowProps = {
    notification: Notification;
}

const NotificationRow: FunctionComponent<NotificationRowProps> = ({notification}) => {
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);

    return (
        <TableRow onClick={{link: notification.getRoute()}}>
            <TableColumn>
                <LabelStatus status={notification.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{notification.id}</TableColumn>
            <TableColumn>
                <LabelService service={notification.service}/>
            </TableColumn>
            <TableColumn>
                <Tag label={textNotifications(`type.${notification.type}`)} style={TagStyle.PRIMARY_OCEAN}/>
            </TableColumn>
            <TableColumn>{notification.name || "-"}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>
                {notification.expires_at ? Formatter.formatUTCDate(notification.expires_at, Formatter.DATETIME_FORMAT) : "-"}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <IconTooltip
                    icon={{name: "cell_tower", colorIcon: notification.is_broadcast ? Color.GREEN : Color.GREY_DARK}}
                    text={textNotifications(notification.is_broadcast ? "tooltip.broadcast_enabled" : "tooltip.broadcast_disabled")}
                />
            </TableColumn>
        </TableRow>
    );
};

export default NotificationRow;
