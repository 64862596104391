import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {SegmentDataType} from "../../../api/model/audience/segment/SegmentDataType";
import {TranslationPortalFile} from "../../../utils/constants";
import {Color} from "@sirdata/ui-lib";
import {Tag} from "../index";

type TagSegmentDataTypeProps = {
    type: SegmentDataType | undefined;
};

const TagSegmentDataType: FunctionComponent<TagSegmentDataTypeProps> = ({type}) => {
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);

    if (!type) return (<></>);

    return (
        <Tag
            label={textSegments(`datatype.${type.name}`)}
            icon={type.icon}
            customColor={{
                backgroundColor: Color.CYAN_LIGHTER,
                color: Color.CYAN,
                borderColor: Color.CYAN_LIGHT
            }}
        />
    );
};

export default TagSegmentDataType;
