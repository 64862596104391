import {ApiModel} from "../../common/api/model/ApiModel";
import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {CategoryBQRequest} from "../model/audience/category/CategoryBQRequest";

export class RestCategoryBQRequest extends Rest implements RestService<CategoryBQRequest> {
    rootPath = "/console-api/category";

    search(params: ApiModel): Promise<SearchResult<CategoryBQRequest>> {
        return Promise.resolve({} as SearchResult<CategoryBQRequest>);
    }

    list(categoryId: number): Promise<CategoryBQRequest[]> {
        return this._client.get(`${this.rootPath}/${categoryId}/bq-request`, CategoryBQRequest) as Promise<CategoryBQRequest[]>;
    }

    create(request: CategoryBQRequest): Promise<CategoryBQRequest> {
        return this._client.post(`${this.rootPath}/${request.category_id}/bq-request`, request, CategoryBQRequest) as Promise<CategoryBQRequest>;
    }

    get(id: string | number): Promise<CategoryBQRequest> {
        return Promise.resolve({} as CategoryBQRequest);
    }

    update(request: CategoryBQRequest): Promise<CategoryBQRequest> {
        return this._client.put(`${this.rootPath}/${request.category_id}/bq-request/${request.id}`, request, CategoryBQRequest) as Promise<CategoryBQRequest>;
    }

    delete(request: CategoryBQRequest) {
        return this._client.delete(`${this.rootPath}/${request.category_id}/bq-request/${request.id}`);
    }
}
