import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {session} from "../../../api/ApiSession";
import {SgtmContainer} from "../../../api/model/sgtm/container/SgtmContainer";
import {SgtmContainerField} from "../../../api/model/sgtm/container/SgtmContainerField";
import {SgtmContainerDeploymentDestination} from "../../../api/model/sgtm/container/SgtmContainerDeploymentDestination";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {Status} from "../../../utils/Status";
import {SelectPartner, SelectStatus, SgtmContainerHostname} from "../../snippet";

export type ModalCreateSgtmContainerProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateSgtmContainer: FunctionComponent<ModalCreateSgtmContainerProps> = ({active, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textSgtmContainers} = useTranslation(TranslationPortalFile.SGTM_CONTAINERS);
    const [container, setContainer] = useState<SgtmContainer>(new SgtmContainer());
    const [hostname, setHostname] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-sgtm-container";

    useEffect(() => {
        if (active) {
            setContainer(new SgtmContainer());
        }
    }, [active]);

    const handleChange = (field: SgtmContainerField, value: any) => {
        setContainer((prevState) => new SgtmContainer({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            container.instance_hosts = [hostname];
            const newContainer = await session.restSgtmContainer.create(container);
            navigate(newContainer.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("sGTM container", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textSgtmContainers("modal_create.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={4}>
                            <FieldBlock label={textSgtmContainers(`field.${SgtmContainerField.STATUS}`)} required>
                                <SelectStatus
                                    value={container.status}
                                    statuses={SgtmContainer.statuses().filter((it) => it !== Status.DELETED)}
                                    onChange={(status) => handleChange(SgtmContainerField.STATUS, status?.name)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textSgtmContainers(`field.${SgtmContainerField.NAME}`)} required>
                            <InputText
                                value={container.name}
                                onChange={(value) => handleChange(SgtmContainerField.NAME, value)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textSgtmContainers(`field.${SgtmContainerField.CONTAINER_CONFIG_ID}`)} required>
                            <InputText
                                value={container.container_config_id}
                                onChange={(value) => handleChange(SgtmContainerField.CONTAINER_CONFIG_ID, value)}
                                placeholder={textSgtmContainers(`placeholder.${SgtmContainerField.CONTAINER_CONFIG_ID}`)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textSgtmContainers(`field.${SgtmContainerField.DEPLOYMENT_DESTINATION}`)}>
                            <Select
                                value={container.deployment_destination}
                                options={Object.values(SgtmContainerDeploymentDestination).map((it) => ({value: it, label: it}))}
                                onChange={(option) => handleChange(SgtmContainerField.DEPLOYMENT_DESTINATION, option?.value)}
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textSgtmContainers(`field.${SgtmContainerField.PARTNER_ID}`)}
                            required
                        >
                            <SelectPartner
                                value={container.partner_id}
                                onChange={(partner) => handleChange(SgtmContainerField.PARTNER_ID, partner?.id)}
                            />
                        </FieldBlock>
                        <SgtmContainerHostname onChange={(hostname) => setHostname(hostname)}/>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateSgtmContainer;
