export enum CmpConfigThemeSetChoicesStyle {
    BUTTON = "BUTTON",
    LINK = "LINK",
    IN_TEXT = "IN_TEXT"
}

export const CMP_CONFIG_THEME_SET_CHOICES_STYLES: CmpConfigThemeSetChoicesStyle[] = [
    CmpConfigThemeSetChoicesStyle.BUTTON,
    CmpConfigThemeSetChoicesStyle.LINK,
    CmpConfigThemeSetChoicesStyle.IN_TEXT
];

