import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {CmpConfigThemeToolbarPosition} from "./CmpConfigThemeToolbarPosition";
import {CmpConfigThemeToolbarSize} from "./CmpConfigThemeToolbarSize";
import {CmpConfigThemeToolbarStyle} from "./CmpConfigThemeToolbarStyle";

export const TOOLBAR_OFFSET_SUFFIX = "px";
const DEFAULT_TOOLBAR_OFFSET = "30" + TOOLBAR_OFFSET_SUFFIX;

export class CmpConfigThemeToolbar extends ApiModel {
    active: boolean = true;
    position: CmpConfigThemeToolbarPosition = CmpConfigThemeToolbarPosition.RIGHT;
    size: CmpConfigThemeToolbarSize = CmpConfigThemeToolbarSize.MEDIUM;
    style: string = CmpConfigThemeToolbarStyle.TEXT.name;
    offset: string = DEFAULT_TOOLBAR_OFFSET;

    constructor(o?: {}) {
        super(o);
        this.load(o);
        if (o === undefined) {
            this.size = CmpConfigThemeToolbarSize.SMALL;
            this.style = CmpConfigThemeToolbarStyle.ICON.name;
        }
    }

    getJsonParameters(): {} {
        return {
            active: this.active,
            position: this.position,
            size: this.size,
            style: this.style,
            offset: this.offset
        };
    }
}
