import React, {Dispatch, FunctionComponent, SetStateAction, useEffect, useState} from "react";
import {DataleaksAudit} from "../../../api/model/dataleaks/audit/DataleaksAudit";
import {DataleaksAuditField} from "../../../api/model/dataleaks/audit/DataleaksAuditField";
import {DataleaksAuditQuestion} from "../../snippet";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";
import {DataleaksVersion} from "../../../api/model/dataleaks/version/DataleaksVersion";
import {DataleaksVersionQuestion} from "../../../api/model/dataleaks/version/DataleaksVersionQuestion";

type ModalCreateDataleaksAuditQuestionsProps = {
    audit: DataleaksAudit;
    setAudit: Dispatch<SetStateAction<DataleaksAudit>>;
    version: DataleaksVersion;
    questions: DataleaksQuestion[];
};

const ModalCreateDataleaksAuditQuestions: FunctionComponent<ModalCreateDataleaksAuditQuestionsProps> = ({audit, setAudit, version, questions}) => {
    const [nextQuestion, setNextQuestion] = useState<DataleaksVersionQuestion>();

    useEffect(() => {
        const nextQuestion = version.questions.find((it) => !audit.answers.has(it.question_id));
        setNextQuestion(nextQuestion);
    }, [audit, version.questions]);

    const handleChange = (field: DataleaksAuditField, value: any) => {
        setAudit((prevState) => new DataleaksAudit({...prevState, [field]: value}));
    };

    const handleChangeAnswers = (questionId: number, value: boolean) => {
        const newAnswers = new Map(audit.answers);
        newAnswers.set(questionId, value);
        handleChange(DataleaksAuditField.ANSWERS, newAnswers);
    };

    return (
        <>
            {version.questions.filter((it) => !nextQuestion || it.index <= nextQuestion.index).map((item) =>
                <DataleaksAuditQuestion
                    key={item.question_id}
                    item={item}
                    question={questions.find((it) => it.id === item.question_id) || new DataleaksQuestion()}
                    answer={audit.answers.get(item.question_id)}
                    onChange={handleChangeAnswers}
                />
            )}
        </>
    );
};

export default ModalCreateDataleaksAuditQuestions;
