import {ApiModel} from "../../../common/api/model/ApiModel";

export class PortalSettingContentFooterItem extends ApiModel {
    label: string = "";
    path: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static create(label: string, path: string): PortalSettingContentFooterItem {
        const item = new PortalSettingContentFooterItem();
        item.label = label;
        item.path = path;
        return item;
    }

    getJsonParameters(): {} {
        return {
            label: this.label,
            path: this.path
        };
    }
}
