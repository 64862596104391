import {FlexContent, FlexContentDirection, FlexContentSpacing, LayoutColumns, Loadable} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Network} from "../../api/model/cmp/list/network-list/Network";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainContentHeader, NetworkListNetwork} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {NetworkList} from "../../api/model/cmp/list/network-list/NetworkList";
import ModalNetwork from "../../component/modal/cmp-lists/ModalNetwork";

function CmpNetworks() {
    const {t: textCmpLists} = useTranslation(TranslationPortalFile.CMP_LISTS);
    const alert = useAlert();

    const [isLoading, setLoading] = useState(true);
    const [networkList, setNetworkList] = useState<NetworkList>(new NetworkList());
    const [activeNetwork, setActiveNetwork] = useState<Network>();
    const {buildSearchResult, ...search} = useSearch(Network);

    useEffect(() => {
        (async function () {
            try {
                const networkList = await session.restCmpList.getNetworkList();
                setNetworkList(networkList);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("networks", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, textCmpLists]);

    useEffect(() => {
        let currentNetworks = (networkList.networks || []);
        if (search.searchQuery.query) {
            currentNetworks = currentNetworks.filter((item) => `${item.id} ${item.name}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentNetworks);
    }, [networkList, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_NETWORKS}/>
            <MainContent>
                <Loadable loading={isLoading}>
                    <LayoutColumns cssClass={"networks"}>
                        <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                            {networkList.networks.slice(0, networkList.networks.length / 2 + 1).map((network) =>
                                <NetworkListNetwork
                                    key={network.id}
                                    network={network}
                                    onClick={(network: Network) => setActiveNetwork(network)}
                                />
                            )}
                        </FlexContent>
                        <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM}>
                            {networkList.networks.slice(networkList.networks.length / 2 + 1).map((network) =>
                                <NetworkListNetwork
                                    key={network.id}
                                    network={network}
                                    onClick={(network: Network) => setActiveNetwork(network)}
                                />
                            )}
                        </FlexContent>
                    </LayoutColumns>
                </Loadable>
                <ModalNetwork network={activeNetwork} onClose={() => setActiveNetwork(undefined)}/>
            </MainContent>
        </Wrapper>
    );
}

export default CmpNetworks;
