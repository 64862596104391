import React, {FunctionComponent, useEffect, useState} from "react";
import {Box, BoxRadius, Color, ContentBlock, Icon, Loadable} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {Formatter} from "../../common/utils/Formatter";
import useAlert from "../../utils/hooks/useAlert";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";

type EphemerisItem = {
    date: string;
    name: string;
    text?: string;
    isBirthday?: boolean;
}

const Ephemeris: FunctionComponent = () => {
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const {t: textHome} = useTranslation(TranslationPortalFile.HOME);
    const [dailyItems, setDailyItems] = useState<EphemerisItem[]>([]);
    const DATE_FORMAT = "DD/MM";

    useEffect(() => {
        (async function () {
            try {
                let items: EphemerisItem[] = [];
                items.push({date: "01/01", name: "the first day of the year", text: "How long will you keep your resolutions?"});
                items.push({date: "25/12", name: "Christmas", text: "Did Santa get you some presents?"});
                items.push({date: "28/12", name: "Sirdata", isBirthday: true});
                items.push({date: "29/12", name: "Tyrion", isBirthday: true});
                setDailyItems(items.filter((it) => it.date === Formatter.formatDate(new Date(), DATE_FORMAT)));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("ephemeris", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    return (
        <Loadable loading={isLoading}>
            <ContentBlock header={{title: {label: textHome("ephemeris.title", {date: Formatter.formatDate(new Date(), "LL")}).toUpperCase()}}} cssClass={"ephemeris"}>
                <Box radius={BoxRadius.LG} cssClass="ephemeris__content">
                    {dailyItems.length ?
                        dailyItems.map((item, index) =>
                            item.isBirthday ?
                                <div key={item.name} className="ephemeris__item">
                                    <Icon name="cake" colorIcon={Color.OCEAN} cssClass="ephemeris__item__icon"/>
                                    <div className="ephemeris__item__content">
                                        <div className="ephemeris__item__content__title" dangerouslySetInnerHTML={{__html: textHome("ephemeris.birthday.title", {name: item.name, count: index + 1})}}/>
                                        <div className="ephemeris__item__content__text">{item.text || textHome("ephemeris.birthday.text", {count: index + 1})}</div>
                                    </div>
                                </div> :
                                <div key={item.name} className="ephemeris__item">
                                    <Icon name="today" colorIcon={Color.RED} cssClass="ephemeris__item__icon"/>
                                    <div className="ephemeris__item__content">
                                        <div className="ephemeris__item__content__title" dangerouslySetInnerHTML={{__html: textHome("ephemeris.default.title", {name: item.name})}}/>
                                        {item.text && <div className="ephemeris__item__content__text">{item.text}</div>}
                                    </div>
                                </div>
                        ) :
                        (
                            <div className="ephemeris__item">
                                <Icon name="today" colorIcon={Color.GREY_DARK} cssClass="ephemeris__item__icon"/>
                                <div className="ephemeris__item__content">
                                    <div className="ephemeris__item__content__title" dangerouslySetInnerHTML={{__html: textHome("ephemeris.nothing.title")}}/>
                                    <div className="ephemeris__item__content__text">{textHome("ephemeris.nothing.text")}</div>
                                </div>
                            </div>
                        )
                    }
                </Box>
            </ContentBlock>
        </Loadable>
    );
};

export default Ephemeris;
