import {Action, ActionsMenu, ElementList, ElementListSize, FlexContent, FlexContentDirection, FlexContentSpacing, TableActionColumn, TableColumn, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {CategorizerConfigEntry} from "../../../../api/model/categorizer/CategorizerConfigEntry";
import {RestrictedContent} from "../../../../common/component/widget";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Tag} from "../../index";
import {CategorizerConfigEntryField} from "../../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigUrlCleanerField} from "../../../../api/model/categorizer/config/CategorizerConfigUrlCleanerField";
import {CategorizerConfigUrlCleaner} from "../../../../api/model/categorizer/config/CategorizerConfigUrlCleaner";

type CategorizerConfigUrlCleanerRowProps = {
    entry: CategorizerConfigEntry<CategorizerConfigUrlCleaner>;
    isEditable: boolean;
    onClick: () => void;
    onDelete: () => void;
};

const CategorizerConfigUrlCleanerRow: FC<CategorizerConfigUrlCleanerRowProps> = ({entry, isEditable, onClick, onDelete}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);

    return (
        <TableRow onClick={onClick}>
            <TableColumn>{entry.domain || "-"}</TableColumn>
            <TableColumn>{entry.host || "-"}</TableColumn>
            <TableColumn>{entry.path || "-"}</TableColumn>
            <TableColumn>
                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL}>
                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                        <span>{textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigUrlCleanerField.REJECTED_PATHS}`)}</span>
                        {!!entry.config.rejected_paths.length ?
                            <ElementList size={ElementListSize.SMALL}>
                                {entry.config.rejected_paths.map((it) => <Tag key={it} label={it} style={TagStyle.PRIMARY_GREY_LIGHT}/>)}
                            </ElementList> : "-"
                        }
                    </FlexContent>
                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                        <span>{textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigUrlCleanerField.REJECTED_URLS}`)}</span>
                        {!!entry.config.rejected_urls.length ?
                            <ElementList size={ElementListSize.SMALL} inline>
                                {entry.config.rejected_urls.map((it) => <Tag key={it} label={it} style={TagStyle.PRIMARY_GREY_LIGHT}/>)}
                            </ElementList> : "-"
                        }
                    </FlexContent>
                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                        <span>{textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigUrlCleanerField.EXCLUDED_QUERY_ARGS}`)}</span>
                        {!!entry.config.excluded_query_args.length ?
                            <ElementList size={ElementListSize.SMALL} inline>
                                {entry.config.excluded_query_args.map((it) => <Tag key={it} label={it} style={TagStyle.PRIMARY_GREY_LIGHT}/>)}
                            </ElementList> : "-"
                        }
                    </FlexContent>
                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                        <span>{textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigUrlCleanerField.RETAINED_QUERY_ARGS}`)}</span>
                        {!!entry.config.retained_query_args.length ?
                            <ElementList size={ElementListSize.SMALL} inline>
                                {entry.config.retained_query_args.map((it) => <Tag key={it} label={it} style={TagStyle.PRIMARY_GREY_LIGHT}/>)}
                            </ElementList> : "-"
                        }
                    </FlexContent>
                </FlexContent>
            </TableColumn>
            <TableActionColumn>
                {isEditable &&
                    <RestrictedContent allowedTo={Authorization.CATEGORIZER.update}>
                        <ActionsMenu
                            iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                            items={[
                                {label: textCommon(Action.DELETE.labelKey), critical: true, separator: true, onClick: onDelete}
                            ]}
                        />
                    </RestrictedContent>
                }
            </TableActionColumn>
        </TableRow>
    );
};

export default CategorizerConfigUrlCleanerRow;
