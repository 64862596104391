import {Rest} from "../../common/api/rest/Rest";
import {DistributionLicense} from "../model/audience/distribution/license/DistributionLicense";
import {DistributionLicenseCustomField} from "../model/audience/distribution/license/DistributionLicenseCustomField";
import {DistributionLicenseSearchResult} from "../model/audience/distribution/license/DistributionLicenseSearchResult";
import {DistributionSearchQuery} from "../model/audience/distribution/DistributionSearchQuery";

export class RestDistributionLicense extends Rest {
    rootPath = "/console-api/distribution";

    search(search: DistributionSearchQuery): Promise<DistributionLicenseSearchResult> {
        return this._client.get(`${this.rootPath}/custom-license`, DistributionLicenseSearchResult, {queryParams: search.getJsonParameters()}) as Promise<DistributionLicenseSearchResult>;
    };

    get(licenseId: number): Promise<DistributionLicense> {
        return this._client.get(`${this.rootPath}/custom-license/${licenseId}`, DistributionLicense) as Promise<DistributionLicense>;
    }

    create(destinationName: string, license: DistributionLicense): Promise<DistributionLicense> {
        return this._client.post(`${this.rootPath}/destination/${destinationName}/custom-license`, license, DistributionLicense) as Promise<DistributionLicense>;
    }

    update(destinationName: string, license: DistributionLicense): Promise<DistributionLicense> {
        return this._client.put(`${this.rootPath}/destination/${destinationName}/custom-license/${license.id}`, license, DistributionLicense) as Promise<DistributionLicense>;
    }

    delete(destinationName: string, license: DistributionLicense) {
        return this._client.delete( `${this.rootPath}/destination/${destinationName}/custom-license/${license.id}`);
    }

    getCustomFields(destinationName: string): Promise<DistributionLicenseCustomField[]> {
        return this._client.get(`${this.rootPath}/destination/${destinationName}/custom-license/custom-fields`, DistributionLicenseCustomField) as Promise<DistributionLicenseCustomField[]>;
    }
}