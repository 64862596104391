import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CmpLocaleItem} from "./CmpLocaleItem";
import {CmpLocaleConfig} from "./CmpLocaleConfig";

export class CmpLocale extends ApiModel {
    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get items(): CmpLocaleItem[] {
        return Object.entries(this).map(([name, value]) => new CmpLocaleItem(name, value));
    }

    isInvalid(config: CmpLocaleConfig): boolean {
        return this.items.some((it) => {
            const itemConfig = config.getItemByKey(it.name);
            return it.isInvalid(itemConfig);
        });
    }
}
