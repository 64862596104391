import {
    ButtonLinkCancel,
    ButtonValidate,
    ContentBlock,
    FormLayoutRows,
    FormLayoutSeparator,
    Loadable,
    ModalActions,
    ModalContent,
    ModalDescription,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    SvgUpload,
    Table,
    TableColumn,
    TableRow,
    TagStyle,
    UploadPanel
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SegmentLinkAreaCodes} from "../../../api/model/audience/segment/SegmentLinkAreaCodes";
import {SegmentLinkAreaCodesField} from "../../../api/model/audience/segment/SegmentLinkAreaCodesField";
import {HttpContentType} from "../../../common/api/http/HttpContentType";
import {TranslationPortalFile} from "../../../utils/constants";
import {CountryFlag, Tag} from "../../snippet";

export type ModalImportSegmentLinkCategoryProps = {
    active: boolean;
    onSubmit: (links: SegmentLinkAreaCodes[]) => void;
    onClose: () => void;
};

const ModalImportSegmentLinksAreaCodes: FunctionComponent<ModalImportSegmentLinkCategoryProps> = ({active, onSubmit, onClose}) => {
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const [isLoading, setLoading] = useState(false);
    const [fileName, setFileName] = useState<string>("");
    const [links, setLinks] = useState<SegmentLinkAreaCodes[]>([]);

    useEffect(() => {
        if (!active) {
            setFileName("");
            setLinks([]);
        }
    }, [active]);

    const handleImportFile = (file?: File) => {
        if (!file) return;
        setLoading(true);

        try {
            setFileName(file.name);

            const reader = new FileReader();
            reader.onloadend = (evt) => {
                let lines = evt.target?.result as string;
                if (!lines) {
                    return;
                }

                const importedLinks = [];
                for (const line of lines.split("\n")) {
                    if (!line) break;
                    const [type, country, codes] = line.split(";");
                    importedLinks.push(new SegmentLinkAreaCodes({
                        active: true,
                        iso_country: country,
                        type: type,
                        codes: codes
                    }));
                }
                setLinks(importedLinks);
            };
            reader.readAsBinaryString(file);
        } catch {

        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFile = () => {
        setFileName("");
        setLinks([]);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textSegments("modal.import_area_codes")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows>
                    <ModalDescription>
                        <span dangerouslySetInnerHTML={{__html: textSegments("message.import_area_codes")}}/>
                    </ModalDescription>
                    <UploadPanel
                        fileName={fileName}
                        image={SvgUpload}
                        onChange={handleImportFile}
                        onRemove={handleRemoveFile}
                        acceptType={[HttpContentType.CSV]}
                    />
                    <Loadable loading={isLoading}>
                        {!!links.length &&
                            <>
                                <FormLayoutSeparator/>
                                <ContentBlock header={{title: {label: textSegments("field.result")}}}>
                                    <Table
                                        columns={[
                                            {label: textSegments(`field.area_codes.${SegmentLinkAreaCodesField.TYPE}`), width: 30},
                                            {label: textSegments(`field.area_codes.${SegmentLinkAreaCodesField.ISO_COUNTRY}`), width: 35},
                                            {label: textSegments(`field.area_codes.${SegmentLinkAreaCodesField.CODES}`), width: 35}
                                        ]}
                                    >
                                        {links.map((link, index) =>
                                            <TableRow key={`area-code-link-${index.toString()}`}>
                                                <TableColumn>
                                                    <Tag label={link.type} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                                                </TableColumn>
                                                <TableColumn>
                                                    <CountryFlag code={link.iso_country}/>
                                                </TableColumn>
                                                <TableColumn>
                                                    {textSegments("count_codes_import", {count: link.getCodes().length})}
                                                </TableColumn>
                                            </TableRow>
                                        )}
                                    </Table>
                                </ContentBlock>
                            </>
                        }
                    </Loadable>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate onClick={() => onSubmit(links)} disabled={!links.length}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalImportSegmentLinksAreaCodes;
