import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {CategoryDomainDeviceType} from "./CategoryDomainDeviceType";

export class CategoryDomain extends ApiModel {
    id: number = 0;
    domain: string = "";
    category_id: number = 0;
    points: number = 0;
    url_conditions: string | undefined;
    expiration_day: number = 0;
    device_type: CategoryDomainDeviceType = CategoryDomainDeviceType.DESKTOP;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
