import {QueryString} from "../../../../common/api/model/QueryString";
import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {Status} from "../../../../utils/Status";
import {SearchQuery} from "../../../interface/SearchQuery";

export enum SegmentSearchParamsField {
    STRICT = "strict",
    PUBLIC = "public",
    DATA_TYPES = "data_types",
    TIERS1 = "tiers1",
}

export class SegmentSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    strict: boolean = false;
    platform?: string;
    public?: boolean;
    status?: string;
    data_types: string[] = [];
    tiers1?: string;

    withSearchParams(params: SearchParams): SegmentSearchQuery {
        let newQuery = super.withSearchParams(params) as SegmentSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS);
        newQuery.platform = params.getString(SearchParamsField.PLATFORM);
        newQuery.strict = params.getBoolean(SegmentSearchParamsField.STRICT) || false;
        newQuery.public = params.getBoolean(SegmentSearchParamsField.PUBLIC);
        newQuery.data_types = params.getString(SegmentSearchParamsField.DATA_TYPES)?.split(",") || [];
        newQuery.tiers1 = params.getString(SegmentSearchParamsField.TIERS1);
        return newQuery;
    }

    toQueryString(): string {
        return QueryString.build({
            page: this.page,
            size: this.size,
            data_types: !!this.data_types.length ? this.data_types.join(",") : undefined,
            sort_by: this.sortBy,
            reverse: `${this.reverseOrder}`,
            platform: this.platform,
            query: this.query,
            strict: `${this.strict}`,
            active: !!this.status && `${this.status === Status.ACTIVE.name}`,
            private: this.public !== undefined && `${!this.public}`,
            tiers1: this.tiers1
        });
    }
}
