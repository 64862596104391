import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Action, Color, IconTooltip, TranslationLibFile} from "@sirdata/ui-lib";
import clsx from "clsx";
import {User} from "../../../api/model/user/User";
import {Tag} from "../index";

type TagUserRowProps = {
    user: User;
    isHighlighted?: boolean;
    onRemove?: () => void;
};

const TagUserRow: FunctionComponent<TagUserRowProps> = ({user, isHighlighted, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <div className={clsx("element-list__tag-row", {"element-list__tag-row--highlighted": isHighlighted})}>
            <span className="element-list__tag-row__infos">
                <Tag label={`${user.id}`} customColor={{backgroundColor: Color.GREEN}}/>
                <span>{user.fullName}</span>
            </span>
            {onRemove && <IconTooltip icon={Action.REMOVE.icon} text={textCommon(Action.REMOVE.labelKey)} onClick={onRemove}/>}
        </div>
    );
};

export default TagUserRow;
