import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {AdsAccess} from "../model/audience/ads/AdsAccess";
import {AdsAccessSearchQuery} from "../model/audience/ads/AdsAccessSearchQuery";
import {AdsAccessSearchResult} from "../model/audience/ads/AdsAccessSearchResult";

export class RestAdsAccess extends Rest implements RestService<AdsAccess> {
    rootPath = "/console-api/ads/access";

    search(params: AdsAccessSearchQuery): Promise<AdsAccessSearchResult> {
        return this._client.get(`${this.rootPath}`, AdsAccessSearchResult, {queryParams: params.getJsonParameters()}) as Promise<AdsAccessSearchResult>;
    }

    create(access: AdsAccess): Promise<AdsAccess> {
        return this._client.post(`${this.rootPath}`, access, AdsAccess) as Promise<AdsAccess>;
    }

    get(accessId: number): Promise<AdsAccess> {
        return this._client.get(`${this.rootPath}/${accessId}`, AdsAccess) as Promise<AdsAccess>;
    }

    update(access: AdsAccess): Promise<AdsAccess> {
        return this._client.put(`${this.rootPath}/${access.id}`, access, AdsAccess) as Promise<AdsAccess>;
    }

    delete(accessId: number) {
        return this._client.delete(`${this.rootPath}/${accessId}`);
    }

    updateSharedTaxonomy(accessId: number, sharedTaxonomy: number[]): Promise<AdsAccess> {
        return this._client.put(`${this.rootPath}/${accessId}/taxonomy/shared`, sharedTaxonomy, AdsAccess) as Promise<AdsAccess>;
    }

    updateGlobalTaxonomy(accessId: number, globalTaxonomy: number[]): Promise<AdsAccess> {
        return this._client.put(`${this.rootPath}/${accessId}/taxonomy/global`, globalTaxonomy, AdsAccess) as Promise<AdsAccess>;
    }
}
