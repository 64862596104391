export enum CmpConfigVendorListField {
    VENDORS = "vendors",
    STACKS = "stacks",
    DISABLED_PURPOSES = "disabledPurposes",
    DISABLED_SPECIAL_FEATURES = "disabledSpecialFeatures",
    GOOGLE_PROVIDERS = "googleProviders",
    SIRDATA_VENDORS = "sirdataVendors",
    NETWORKS = "networks",
    CONSENT_ONLY = "consentOnly",
    DISPLAY_MODE = "displayMode"
}
