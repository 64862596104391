import React, {FunctionComponent} from "react";
import {Box, BoxRadius, FieldBlock, FlexContentSpacing, FormLayoutRows} from "@sirdata/ui-lib";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfig} from "../../../api/model/categorizer/config/CategorizerConfig";

type CategorizerConfigEntryInfoProps = {
    currentEntry: CategorizerConfigEntry<CategorizerConfig>;
};

const CategorizerConfigEntryInfo: FunctionComponent<CategorizerConfigEntryInfoProps> = ({currentEntry}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);

    return (
        <Box radius={BoxRadius.MD}>
            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryField.DOMAIN}`)} inline>
                    {currentEntry.domain || "-"}
                </FieldBlock>
                <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryField.HOST}`)} inline>
                    {currentEntry.host || "-"}
                </FieldBlock>
                <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryField.PATH}`)} inline>
                    {currentEntry.path || "-"}
                </FieldBlock>
            </FormLayoutRows>
        </Box>
    );
};

export default CategorizerConfigEntryInfo;