import {PAGE_SIZE} from "../../../utils/constants";
import {SearchParams} from "../../../utils/SearchParams";
import {SearchParamsField} from "../../../utils/SearchParamsField";
import {SearchQuery} from "../../interface/SearchQuery";

export class PortalContentSearchQuery extends SearchQuery {
    string_identifier: string | undefined;
    type: string | undefined;
    service: string | undefined;
    user_id: number | undefined;
    status: string | undefined;
    size: number = PAGE_SIZE;
    theme: string | undefined;
    private _searchIndex: Map<string, string> = new Map<string, string>();

    withSearchParams (params: SearchParams): PortalContentSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting("last_update_ts", true) as PortalContentSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS);
        return newQuery;
    }

    getSearchIndex(name: string): string {
        return this._searchIndex.get(name) || "";
    }

    setSearchIndex(name: string, value: string) {
        this._searchIndex.set(name, value);
    }

    getSearchIndexItems() {
        return Array.from(this._searchIndex)
            .filter(([_, value]) => !!value)
            .map(([name, value]) => `${name}:${value}`);
    }

    getJsonParameters(): {} {
        return {
            search: this.query,
            string_identifier: this.string_identifier,
            type: this.type,
            service: this.service,
            user_id: this.user_id,
            status: this.status,
            predicate: this.sortBy,
            reverse: this.reverseOrder,
            page: this.page,
            size: this.size,
            search_index: this.getSearchIndexItems()
        };
    }
}
