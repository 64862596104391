import {
    Action,
    AlertSeverity,
    Box,
    BoxProps,
    ContentBlock,
    FieldBlock,
    FlexContentDirection,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormValidationType,
    InputEmail,
    InputPhone,
    InputPhoneCountry,
    InputPhoneLocale,
    InputText,
    LayoutColumns,
    LayoutRows,
    Select
} from "@sirdata/ui-lib";
import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "react-phone-number-input/style.css";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {User} from "../../api/model/user/User";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {FormLayoutMessage, MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Locale} from "../../common/utils/Locale";
import {MainContentHeader, MainContentHeaderAction, SelectPartner, SelectStatus, UserAuthorizations, UserProfiles} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {USER_TEAMS} from "../../api/model/user/UserTeam";
import {detectChanges} from "../../common/utils/portal";
import {UserField} from "../../api/model/user/UserField";
import {UserPropertiesField} from "../../api/model/user/UserPropertiesField";
import useAlert from "../../utils/hooks/useAlert";
import {Status} from "../../utils/Status";

function UsersDetails() {
    const {t: textUsers} = useTranslation(TranslationPortalFile.USERS);
    const {id} = useParams() as {id: string};
    const alert = useAlert();
    const [errors, setErrors] = useState<{ phone?: boolean }>({phone: false});
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);

    const FORM_ID = "form-edit-user";
    const [user, setUser] = useState<User>(new User());
    const [initUser, setInitUser] = useState<User>(new User());

    useEffect(() => {
        (async function () {
            try {
                const user = await session.restUser.get(+id);
                setUser(user);
                setInitUser(new User(user));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("user", e.message);
                    }
                }
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(user, initUser));
    }, [user, initUser]);

    const handleChangeProperty = (field: UserPropertiesField, value: any) => {
        setUser((prevState) => new User({
            ...prevState,
            [UserField.PROPERTIES]: {
                ...prevState?.properties,
                [field]: value
            }
        }));
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await session.restUser.update(user);
            await session.restUser.updateProperties(user.id, user.properties);
            setInitUser(new User(user));
            alert.updateWithSuccess("user");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.statusCode === HttpStatusCode.BAD_REQUEST && e.message.includes("invalid phone number")) {
                    setErrors((prevState) => ({...prevState, phone: true}));
                } else {
                    alert.failToUpdate("user", e.message);
                }
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.USERS} element={initUser.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.USERS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <ContentBlock header={{title: {label: textUsers("section.information")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FormLayoutColumns columns={4}>
                                            <FieldBlock label={textUsers("field.status")}>
                                                <SelectStatus
                                                    value={user.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                                    statuses={Status.getActiveStatuses()}
                                                    onChange={(status) => setUser((prevState) => new User({...prevState, [UserField.ACTIVE]: status === Status.ACTIVE}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutColumns columns={2}>
                                            <FieldBlock label={textUsers(`field.${UserField.EMAIL}`)}>
                                                <InputEmail
                                                    value={user.email}
                                                    onChange={() => {}}
                                                    disabled
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutColumns>
                                            <FieldBlock label={textUsers(`field.${UserField.LAST_NAME}`)} required>
                                                <InputText
                                                    value={user.last_name}
                                                    onChange={(value) => setUser((prevState) => new User({...prevState, [UserField.LAST_NAME]: value}))}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textUsers(`field.${UserField.FIRST_NAME}`)} required>
                                                <InputText
                                                    value={user.first_name}
                                                    onChange={(value) => setUser((prevState) => new User({...prevState, [UserField.FIRST_NAME]: value}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutColumns>
                                            <FieldBlock label={textUsers(`field.${UserField.PROPERTIES}.${UserPropertiesField.TEAM}`)}>
                                                <Select
                                                    value={user.properties.team}
                                                    options={USER_TEAMS.map((it) => ({value: it, label: textUsers(`team.${it}`)}))}
                                                    onChange={(option) => handleChangeProperty(UserPropertiesField.TEAM, `${option?.value || ""}`)}
                                                    clearable
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textUsers(`field.${UserField.POSITION}`)}>
                                                <InputText
                                                    value={user.position}
                                                    onChange={(value) => setUser((prevState) => new User({...prevState, [UserField.POSITION]: value}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutColumns columns={2}>
                                            <FieldBlock label={textUsers(`field.${UserField.PHONE}`)} content={{direction: FlexContentDirection.COLUMN}}>
                                                <InputPhone
                                                    value={user.phone}
                                                    onChange={(value) => setUser((prevState) => new User({...prevState, [UserField.PHONE]: value}))}
                                                    locale={Locale.isFrench() ? InputPhoneLocale.FRENCH : InputPhoneLocale.ENGLISH}
                                                    country={user.phone_region as InputPhoneCountry}
                                                    onCountryChange={(value) => setUser((prevState) => new User({...prevState, [UserField.PHONE_REGION]: value}))}
                                                />
                                                {!!errors.phone &&
                                                    <FormLayoutMessage message={textUsers("message.invalid_phone")} severity={AlertSeverity.DANGER}/>
                                                }
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutSeparator/>
                                        <FormLayoutRows inline>
                                            <FieldBlock label={textUsers(`field.${UserField.PROPERTIES}.${UserPropertiesField.PARTNER_ID}`)}>
                                                <SelectPartner
                                                    value={user.properties.partner_id ? +user.properties.partner_id : undefined}
                                                    onChange={(partner) => handleChangeProperty(UserPropertiesField.PARTNER_ID, `${partner?.id || ""}`)}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textUsers(`field.${UserField.PROPERTIES}.${UserPropertiesField.TRELLO_ID}`)}>
                                                <InputText
                                                    value={user.properties.trello_id}
                                                    onChange={(value) => handleChangeProperty(UserPropertiesField.TRELLO_ID, value)}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textUsers(`field.${UserField.PROPERTIES}.${UserPropertiesField.JIRA_ID}`)}>
                                                <InputText
                                                    value={user.properties.jira_id}
                                                    onChange={(value) => handleChangeProperty(UserPropertiesField.JIRA_ID, value)}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textUsers(`field.${UserField.PROPERTIES}.${UserPropertiesField.FRESHSALES_ID}`)}>
                                                <InputText
                                                    value={user.properties.freshsales_id}
                                                    onChange={(value) => handleChangeProperty(UserPropertiesField.FRESHSALES_ID, value)}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textUsers(`field.${UserField.PROPERTIES}.${UserPropertiesField.SUB_TEAM}`)}>
                                                <InputText
                                                    value={user.properties.sub_team}
                                                    onChange={(value) => handleChangeProperty(UserPropertiesField.SUB_TEAM, value)}
                                                />
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                            <LayoutRows>
                                <UserAuthorizations userId={+id!}/>
                                <UserProfiles userId={+id!}/>
                            </LayoutRows>
                        </LayoutColumns>
                    </LayoutRows>
                </Form>
            </MainContent>
        </Wrapper>
    );
}

export default UsersDetails;
