import {ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Select, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Distribution} from "../../api/model/audience/distribution/Distribution";
import {DistributionField} from "../../api/model/audience/distribution/DistributionField";
import {DistributionLicense} from "../../api/model/audience/distribution/license/DistributionLicense";
import {DistributionLicenseField} from "../../api/model/audience/distribution/license/DistributionLicenseField";
import {DistributionLicenseSearchParamsField, DistributionLicenseSearchQuery} from "../../api/model/audience/distribution/license/DistributionLicenseSearchQuery";
import {DistributionLicenseSeatField} from "../../api/model/audience/distribution/license/DistributionLicenseSeatField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {sortItems} from "../../common/utils/helper";
import ModalCreateDistributionLicense from "../../component/modal/distributions/ModalCreateDistributionLicense";
import ModalEditDistributionLicense from "../../component/modal/distributions/ModalEditDistributionLicense";
import {DistributionLicenseRow, MainContentHeader, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {Status} from "../../utils/Status";

function DistributionsLicenses() {
    const alert = useAlert();
    const {t: textDistributionsLicenses} = useTranslation(TranslationPortalFile.DISTRIBUTIONS_LICENSES);
    const [isLoading, setLoading] = useState(true);
    const [isLoadingDistributions, setLoadingDistributions] = useState(true);
    const [distributions, setDistributions] = useState<Distribution[]>([]);
    const [isShowModalCreateDistributionLicense, setShowModalCreateDistributionLicense] = useState(false);
    const [activeEditDistributionLicense, setActiveEditDistributionLicense] = useState<DistributionLicense>();
    const {setSearchResult, ...search} = useSearch(DistributionLicense, DistributionLicenseSearchQuery);

    useEffect(() => {
        (async () => {
            try {
                setLoadingDistributions(true);
                const result = await session.restList.getDistributions();
                setDistributions(sortItems(result.filter((it) => !!it.destination_name), DistributionField.LABEL));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("distributions", e.message);
                }
            } finally {
                setLoadingDistributions(false);
            }
        })();
    }, [alert]);

    const loadDistributionLicenses = useCallback(async () => {
        try {
            setLoading(true);
            const result = await session.restDistributionLicense.search(search.searchQuery);
            setSearchResult(result);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("distributions licenses", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [alert, search.searchQuery, setSearchResult]);

    useEffect(() => {
        (async () => {
            await loadDistributionLicenses();
        })();
    }, [loadDistributionLicenses]);

    const handleDelete = async (license: DistributionLicense) => {
        const distribution = distributions.find((it) => it.name === license.external_map_name);
        if (!distribution || !distribution.destination_name) {
            alert.failToLoad("distribution", "");
            return;
        }
        try {
            await session.restDistributionLicense.delete(distribution.destination_name, license);
            await loadDistributionLicenses();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("distribution license", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.DISTRIBUTIONS_LICENSES}/>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            canHideFilters
                            searchBar={{placeholder: textDistributionsLicenses("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                        >
                            <SearchField label={textDistributionsLicenses(`field.${SearchParamsField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textDistributionsLicenses(`search.${DistributionLicenseSearchParamsField.EXTERNAL_MAP_NAME}`)}>
                                <Select
                                    value={search.searchQuery.external_map_name}
                                    options={distributions.map((it) => ({value: it.name, label: it.label}))}
                                    onChange={(option) => search.changeParam(DistributionLicenseSearchParamsField.EXTERNAL_MAP_NAME, option?.value)}
                                    isLoading={isLoadingDistributions}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 10, label: textDistributionsLicenses("field.status")},
                                        {width: 10, label: textDistributionsLicenses(`field.${DistributionLicenseField.EXTERNAL_MAP_NAME}`), sort: {field: DistributionLicenseField.EXTERNAL_MAP_NAME}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5, label: textDistributionsLicenses(`field.${DistributionLicenseField.SEAT}.${DistributionLicenseSeatField.SEAT_ID}`), sort: {field: DistributionLicenseField.ID}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textDistributionsLicenses(`field.${DistributionLicenseField.SEGMENT_LINK_TYPE}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textDistributionsLicenses(`field.${DistributionLicenseField.SEGMENTS}`)},
                                        {width: 10, label: textDistributionsLicenses(`field.${DistributionLicenseField.SEAT}.${DistributionLicenseSeatField.ORGANIZATION_NAME}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textDistributionsLicenses(`field.${DistributionLicenseField.PRICE_CPM}`), sort: {field: DistributionLicenseField.PRICE_CPM}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 15, label: textDistributionsLicenses(`field.${DistributionLicenseField.PRICE_CPM_MODIFIER}`), sort: {field: DistributionLicenseField.PRICE_CPM_MODIFIER}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 15, label: textDistributionsLicenses(`field.${DistributionLicenseField.PRICE_MEDIA_COST}`), sort: {field: DistributionLicenseField.PRICE_MEDIA_COST}, styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((license: DistributionLicense) =>
                                        <DistributionLicenseRow
                                            key={license.id}
                                            license={license}
                                            label={distributions.find((it) => it.name === license.external_map_name)?.label || license.external_map_name}
                                            onClick={() => setActiveEditDistributionLicense(license)}
                                            onDelete={() => handleDelete(license)}
                                        />
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateDistributionLicense
                    active={isShowModalCreateDistributionLicense}
                    distributions={distributions}
                    onClose={(refresh) => {
                        setShowModalCreateDistributionLicense(false);
                        refresh && loadDistributionLicenses();
                    }}
                />
                <ModalEditDistributionLicense
                    initialValue={activeEditDistributionLicense}
                    distributions={distributions}
                    onClose={(refresh) => {
                        setActiveEditDistributionLicense(undefined);
                        refresh && loadDistributionLicenses();
                    }}
                />
            </MainContent>
        </Wrapper>
    );
}

export default DistributionsLicenses;
