export enum UserTeam {
    DATA = "DATA",
    IT = "IT",
    MARKETING_COMMUNICATION = "MARKETING_COMMUNICATION",
    MEDIA = "MEDIA",
    PUBLISHERS = "PUBLISHERS",
    SALES = "SALES"
}

export const USER_TEAMS = [
    UserTeam.DATA,
    UserTeam.IT,
    UserTeam.MARKETING_COMMUNICATION,
    UserTeam.MEDIA,
    UserTeam.PUBLISHERS,
    UserTeam.SALES
];
