import {ApiModel} from "../../../common/api/model/ApiModel";
import {PortalSettingContentFooter} from "./PortalSettingContentFooter";
import {Theme} from "../../../common/utils/Theme";
import {PortalSettingContentHeader} from "./PortalSettingContentHeader";
import {PortalSettingContentField} from "./PortalSettingContentField";
import {PortalSettingContentProperties} from "./PortalSettingContentProperties";

export class PortalSettingContent extends ApiModel {
    header: PortalSettingContentHeader = new PortalSettingContentHeader();
    footer: PortalSettingContentFooter = new PortalSettingContentFooter();
    theme: string = Theme.SIRDATA_MIDNIGHT.name;
    properties: PortalSettingContentProperties = new PortalSettingContentProperties();

    constructor(o?: {}) {
        super(o);
        this.load(o);
        this.loadObject(PortalSettingContentField.HEADER, PortalSettingContentHeader);
        this.loadObject(PortalSettingContentField.FOOTER, PortalSettingContentFooter);
        this.loadObject(PortalSettingContentField.PROPERTIES, PortalSettingContentProperties);
    }

    getJsonParameters(): {} {
        return {
            header: this.header.getJsonParameters(),
            footer: this.footer.getJsonParameters(),
            theme: this.theme,
            properties: this.properties.getJsonParameters()
        };
    }
}
