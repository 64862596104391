import {ButtonLinkCancel, ButtonValidate, FieldBlock, FormLayoutColumns, FormLayoutRows, InputEmail, InputPhone, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, RadioButtons} from "@sirdata/ui-lib";
import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DistributionContact} from "../../../api/model/audience/distribution/DistributionContact";
import {DistributionContactField} from "../../../api/model/audience/distribution/DistributionContactField";
import {DISTRIBUTION_CONTACT_TYPES} from "../../../api/model/audience/distribution/DistributionContactType";
import {DistributionField} from "../../../api/model/audience/distribution/DistributionField";
import {DistributionPropertiesField} from "../../../api/model/audience/distribution/DistributionPropertiesField";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalManageDistributionContactProps = {
    active: boolean;
    initContact?: DistributionContact;
    onSubmit: (initContact: DistributionContact) => void;
    onClose: () => void;
};

const ModalManageDistributionContact: FC<ModalManageDistributionContactProps> = ({active, initContact, onSubmit, onClose}) => {
    const {t: textDistribution} = useTranslation(TranslationPortalFile.DISTRIBUTIONS);
    const [contact, setContact] = useState<DistributionContact>(new DistributionContact());

    useEffect(() => {
        if (initContact) {
            setContact(initContact);
        }
    }, [active, initContact]);

    const textContact = (field: string) => {
        return textDistribution(`field.${DistributionField.PROPERTIES}.${DistributionPropertiesField.CONTACTS}.${field}`);
    };

    return (
        <ModalNew onClose={onClose} active={!!initContact}>
            <ModalHeader>
                <ModalHeaderTitle title={textDistribution("modal.edit_contact")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows>
                    <FieldBlock label={textContact(DistributionContactField.TYPE)}>
                        <RadioButtons
                            id={DistributionContactField.TYPE}
                            value={contact.type}
                            options={DISTRIBUTION_CONTACT_TYPES.map((it) => ({label: textContact(`type_options.${it}`), value: it}))}
                            onChange={(value) => setContact((prevState) => new DistributionContact({...prevState, [DistributionContactField.TYPE]: value}))}
                        />
                    </FieldBlock>
                    <FormLayoutColumns>
                        <FieldBlock label={textContact(DistributionContactField.FIRST_NAME)} required>
                            <InputText
                                value={contact.first_name}
                                onChange={(value) => setContact((prevState) => new DistributionContact({...prevState, [DistributionContactField.FIRST_NAME]: value}))}
                            />
                        </FieldBlock>
                        <FieldBlock label={textContact(DistributionContactField.LAST_NAME)} required>
                            <InputText
                                value={contact.last_name}
                                onChange={(value) => setContact((prevState) => new DistributionContact({...prevState, [DistributionContactField.LAST_NAME]: value}))}
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FormLayoutColumns>
                        <FieldBlock label={textContact(DistributionContactField.EMAIL)}>
                            <InputEmail
                                value={contact.email}
                                onChange={(value) => setContact((prevState) => new DistributionContact({...prevState, [DistributionContactField.EMAIL]: value}))}
                            />
                        </FieldBlock>
                        <FieldBlock label={textContact(DistributionContactField.PHONE)}>
                            <InputPhone
                                value={contact.phone}
                                onChange={(value) => setContact((prevState) => new DistributionContact({...prevState, [DistributionContactField.PHONE]: value}))}
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate
                    onClick={() => onSubmit(contact)}
                    disabled={!(contact.first_name && contact.last_name)}
                />
            </ModalActions>
        </ModalNew>
    );
};

export default ModalManageDistributionContact;
