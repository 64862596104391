import {ButtonBack, ButtonNext, ButtonSize, ButtonValidate, Form, FormLayoutRows, FormLayoutTitle, FormValidationType, ModalActions, ModalActionsAlignment, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {session} from "../../../api/ApiSession";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksQuestionStep} from "../../../utils/dataleaks/DataleaksQuestionStep";
import useAlert from "../../../utils/hooks/useAlert";
import ModalCreateDataleaksQuestionInformation from "./ModalCreateDataleaksQuestionInformation";
import ModalCreateDataleaksQuestionTexts from "./ModalCreateDataleaksQuestionTexts";

type ModalCreateDataleaksQuestionProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateDataleaksQuestion: FunctionComponent<ModalCreateDataleaksQuestionProps> = ({active, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);
    const [question, setQuestion] = useState<DataleaksQuestion>(new DataleaksQuestion());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-dataleaks-question";
    const [currentStep, setCurrentStep] = useState<DataleaksQuestionStep>(DataleaksQuestionStep.firstStep());

    useEffect(() => {
        if (active) {
            setQuestion(new DataleaksQuestion());
            setCurrentStep(DataleaksQuestionStep.firstStep());
        }
    }, [active]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const nextStep = currentStep.next;
        if (nextStep) {
            setCurrentStep(nextStep);
        } else {
            setSubmitting(true);
            try {
                const newQuestion = await session.restDataleaksQuestion.create(question);
                navigate(newQuestion.getRoute());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToCreate("question", e.message);
                }
            } finally {
                setSubmitting(false);
            }
        }
    };

    const handleBack = () => {
        const previousStep = currentStep.previous;
        if (previousStep) {
            setCurrentStep(previousStep);
        }
    };

    const buildStepComponent = () => {
        switch (currentStep) {
            case DataleaksQuestionStep.INFORMATION:
                return (
                    <ModalCreateDataleaksQuestionInformation
                        question={question}
                        setQuestion={setQuestion}
                    />
                );
            case DataleaksQuestionStep.TEXTS:
                return (
                    <ModalCreateDataleaksQuestionTexts
                        question={question}
                        setQuestion={setQuestion}
                    />
                );
        }
        return <></>;
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textDataleaksQuestions("modal_create.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutTitle>{textDataleaksQuestions(`modal_create.step.${currentStep.name}`)}</FormLayoutTitle>
                        {buildStepComponent()}
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions alignment={ModalActionsAlignment.SPACE_BETWEEN}>
                <span style={{visibility: currentStep.previous ? "visible" : "hidden"}}>
                    <ButtonBack onClick={handleBack} noIcon/>
                </span>
                {currentStep === DataleaksQuestionStep.lastStep() ?
                    <ButtonValidate form={FORM_ID} size={ButtonSize.MEDIUM} loading={isSubmitting}/> :
                    <ButtonNext form={FORM_ID} noIcon/>
                }
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateDataleaksQuestion;
