export class DataleaksQuestionGroup {
    static MAIN_SCREEN: DataleaksQuestionGroup = new DataleaksQuestionGroup("Informations sur l'écran principal", [1, 2, 3, 4, 5, 6, 7, 8, 9]);
    static SETTINGS: DataleaksQuestionGroup = new DataleaksQuestionGroup("Paramétrages", [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 27]);
    static CONDITIONING: DataleaksQuestionGroup = new DataleaksQuestionGroup("Conditionnement", [23, 24]);
    static AVAILABLE_ACTIONS: DataleaksQuestionGroup = new DataleaksQuestionGroup("Actions possibles", [25, 26]);

    label: string = "";
    questions: number[] = [];

    constructor(label: string, questions: number[]) {
        this.label = label;
        this.questions = questions;
    }

    static values(): DataleaksQuestionGroup[] {
        return [
            DataleaksQuestionGroup.MAIN_SCREEN,
            DataleaksQuestionGroup.SETTINGS,
            DataleaksQuestionGroup.CONDITIONING,
            DataleaksQuestionGroup.AVAILABLE_ACTIONS
        ];
    }
}


