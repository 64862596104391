import {ApiModel} from "../../../../common/api/model/ApiModel";

export class DataleaksQuestionTexts extends ApiModel {
    title: string = "";
    short_title: string = "";
    description: string = "";
    description_legal: string = "";
    text_success: string = "";
    text_failed: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
