import {Rest} from "../../common/api/rest/Rest";
import {Notification} from "../model/notifications/Notification";
import {NotificationDispatch} from "../model/notifications/NotificationDispatch";
import {NotificationItem} from "../model/notifications/NotificationItem";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";

export class RestNotification extends Rest implements RestService<Notification> {
    rootPath = "/console-api/notification";

    search(params: any): Promise<SearchResult<Notification>> {
        return new Promise<SearchResult<Notification>>(() => {}); // TODO Implement route
    }

    list(): Promise<Notification[]> {
        return this._client.get(`${this.rootPath}`, Notification) as Promise<Notification[]>;
    }

    create(notification: Notification): Promise<Notification> {
        return this._client.post(`${this.rootPath}`, notification, Notification) as Promise<Notification>;
    }

    get(notificationId: number): Promise<Notification> {
        return this._client.get(`${this.rootPath}/${notificationId}`, Notification) as Promise<Notification>;
    }

    update(notification: Notification): Promise<Notification> {
        return this._client.patch(`${this.rootPath}/${notification.id}`, notification, Notification) as Promise<Notification>;
    }

    delete(notificationId: number) {
        return this._client.delete(`${this.rootPath}/${notificationId}`);
    }

    dispatch(notificationId: number, notificationDispatch: NotificationDispatch) {
        return this._client.post(`${this.rootPath}/${notificationId}/dispatch`, notificationDispatch);
    }

    getUserItems(notificationId: number) {
        return this._client.get(`${this.rootPath}/${notificationId}/user`, NotificationItem) as Promise<NotificationItem[]>;
    }

    getPartnerAccountItems(notificationId: number) {
        return this._client.get(`${this.rootPath}/${notificationId}/partner-account`, NotificationItem) as Promise<NotificationItem[]>;
    }

    addUsers(notificationId: number, notificationDispatch: NotificationDispatch) {
        return this._client.post(`${this.rootPath}/${notificationId}/user`, notificationDispatch);
    }

    addPartnerAccounts(notificationId: number, notificationDispatch: NotificationDispatch) {
        return this._client.post(`${this.rootPath}/${notificationId}/partner-account`, notificationDispatch);
    }

    addPartners(notificationId: number, notificationDispatch: NotificationDispatch) {
        return this._client.post(`${this.rootPath}/${notificationId}/partner`, notificationDispatch);
    }

    removeUserItem(notificationId: number, userId: number) {
        return this._client.delete(`${this.rootPath}/${notificationId}/user/${userId}`);
    }

    removePartnerAccountItem(notificationId: number, partnerAccountId: number) {
        return this._client.delete(`${this.rootPath}/${notificationId}/partner-account/${partnerAccountId}`);
    }
}
