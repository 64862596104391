import {ApiModel} from "../../common/api/model/ApiModel";
import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {Currency} from "../model/currency/Currency";

export class RestCurrency extends Rest implements RestService<Currency> {
    rootPath = "/console-api/currency";

    search(params: ApiModel): Promise<SearchResult<Currency>> {
        return new Promise<SearchResult<Currency>>(() => {});
    }

    list(): Promise<Currency[]> {
        return this._client.get(`${this.rootPath}`, Currency) as Promise<Currency[]>;
    }

    create(currency: Currency): Promise<Currency> {
        return this._client.post(`${this.rootPath}`, currency, Currency) as Promise<Currency>;
    }

    get(id: string | number): Promise<Currency> {
        return new Promise<Currency>(() => {});
    }

    update(currency: Currency): Promise<Currency> {
        return this._client.patch(`${this.rootPath}/${currency.id}`, currency, Currency) as Promise<Currency>;
    }

    delete(obj: any): void {
    }
}
