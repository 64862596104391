import {Action, ContentBlock, Loadable, SearchError, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {SearchQuery} from "../../api/interface/SearchQuery";
import {Authorization} from "../../api/model/account/Authorization";
import {DataleaksQuestion} from "../../api/model/dataleaks/question/DataleaksQuestion";
import {DataleaksQuestionField} from "../../api/model/dataleaks/question/DataleaksQuestionField";
import {DataleaksQuestionTextsField} from "../../api/model/dataleaks/question/DataleaksQuestionTextsField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Locale} from "../../common/utils/Locale";
import ModalCreateDataleaksQuestion from "../../component/modal/dataleaks/ModalCreateDataleaksQuestion";
import {MainContentHeader, DataleaksQuestionRow, MainContentHeaderAction} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";

function DataleaksQuestions() {
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [questions, setQuestions] = useState<DataleaksQuestion[]>([]);
    const {buildSearchResult, ...search} = useSearch(DataleaksQuestion, SearchQuery);
    const [isShowModalCreateDataleaksQuestion, setShowModalCreateDataleaksQuestion] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setQuestions(await session.restDataleaksQuestion.list());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("questions", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        let currentQuestions = (questions || []);
        if (search.searchQuery.query) {
            currentQuestions = currentQuestions.filter(({texts}) => `${texts.get(Locale.FRENCH)!.title} ${texts.get(Locale.ENGLISH)?.title}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentQuestions);
    }, [questions, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.DATALEAKS_QUESTIONS}>
                <RestrictedContent allowedTo={Authorization.DATALEAKS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateDataleaksQuestion(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <ContentBlock>
                    <SearchToolbar searchBar={{placeholder: textDataleaksQuestions("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}/>
                    <Loadable loading={isLoading}>
                        {!!search.searchResult.elements.length ?
                            <Table
                                columns={[
                                    {width: 5, label: textDataleaksQuestions(`field.${DataleaksQuestionField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                    {width: 65, label: textDataleaksQuestions(`field.${DataleaksQuestionField.TEXTS}.${DataleaksQuestionTextsField.TITLE}`)},
                                    {width: 15, label: textDataleaksQuestions(`field.${DataleaksQuestionField.TRAIT}`)},
                                    {width: 15, label: textDataleaksQuestions(`field.${DataleaksQuestionField.REGULATIONS}`)}
                                ]}
                                pagination={search.searchResult.getPagination(search.changePage)}
                            >
                                {search.searchResult.elements.map((question) =>
                                    <DataleaksQuestionRow key={question.id} question={question}/>
                                )}
                            </Table> :
                            <SearchError query={search.searchQuery.query}/>
                        }
                    </Loadable>
                </ContentBlock>
                <ModalCreateDataleaksQuestion
                    active={isShowModalCreateDataleaksQuestion}
                    onClose={() => setShowModalCreateDataleaksQuestion(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default DataleaksQuestions;
