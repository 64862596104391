import {ApiModel} from "../../../../../common/api/model/ApiModel";

export class ConsentManagementPlatform extends ApiModel {
    id: number = 0;
    name: string = "";
    isCommercial: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
