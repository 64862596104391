import {ApiModel} from "../../../../common/api/model/ApiModel";
import {AdsAccessOrigin} from "./AdsAccessOrigin";

export class AdsAccessConfiguration extends ApiModel {
    modeling_access: boolean = false;
    reject_taxonomy?: string[];
    reject_tiers?: string[];
    retargeting_partners?: string[];
    restrict_public_access: boolean = false;
    segment_access?: number[];
    origin_ignored: boolean = true;
    origin: AdsAccessOrigin[] = [];
    global_taxonomy?: number[];
    shared_taxonomy?: number[];
    use_http_request_cache: boolean = false;
    segment_taxonomy_id?: number;
    category_taxonomy_id?: number;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            modeling_access: this.modeling_access,
            reject_taxonomy: this.reject_taxonomy,
            reject_tiers: this.reject_tiers,
            retargeting_partners: this.retargeting_partners,
            restrict_public_access: this.restrict_public_access,
            segment_access: this.segment_access,
            origin_ignored: this.origin_ignored,
            origin: this.origin,
            global_taxonomy: this.global_taxonomy,
            shared_taxonomy: this.shared_taxonomy,
            use_http_request_cache: this.use_http_request_cache,
            segment_taxonomy_id: this.segment_taxonomy_id,
            category_taxonomy_id: this.category_taxonomy_id
        };
    }
}
