import {Action, ButtonLinkCancel, ButtonValidate, ElementList, ElementListSize, FlexContentSpacing, Form, FormLayoutRows, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, SelectAutocomplete, Tag as LibTag, TagSize, TagStyle} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {ApiProfile} from "../../../api/model/auth/ApiProfile";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";

type ModalEditProfilesProps = {
    userId: number;
    userProfiles: ApiProfile[];
    onSubmit: (profiles: ApiProfile[]) => void;
    onClose: () => void;
    active: boolean;
};

const ModalEditProfiles: FunctionComponent<ModalEditProfilesProps> = ({userId, userProfiles, onSubmit, onClose, active}) => {
    const alert = useAlert();
    const {t: textUsers} = useTranslation(TranslationPortalFile.USERS);
    const [profiles, setProfiles] = useState<ApiProfile[]>([]);
    const [selectedProfiles, setSelectedProfiles] = useState<ApiProfile[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-edit-profiles";

    useEffect(() => {
        if (!active) return;

        setSelectedProfiles([...userProfiles]);

        (async () => {
            try {
                setProfiles(await session.restUserProfile.list());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("profiles", e.message);
                }
            }
        })();
    }, [userProfiles, active, alert]);

    const handleAddProfile = (profile: ApiProfile) => {
        setSelectedProfiles((prevState) => ([...prevState, profile]));
    };

    const handleRemoveProfile = (profile: ApiProfile) => {
        setSelectedProfiles((prevState) => prevState.filter((it) => it.id !== profile.id));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const profilesToRemove = userProfiles.filter((profile) => !selectedProfiles.some((it) => it.id === profile.id));
            for (let profile of profilesToRemove) {
                await session.restUserAuthorization.removeProfile(userId, profile.id);
            }
            const profilesToAdd = selectedProfiles.filter((profile) => !userProfiles.some((it) => it.id === profile.id));
            for (let profile of profilesToAdd) {
                await session.restUserAuthorization.addProfile(userId, profile.id);
            }
            alert.updateWithSuccess("user's profiles");
            onSubmit(selectedProfiles);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("user's profiles", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textUsers("section.profiles")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                        <SelectAutocomplete
                            options={profiles.filter((it) => !selectedProfiles.some((profile) => profile.id === it.id)).map((it) =>
                                ({label: it.name, value: it.id, profile: it}))
                            }
                            onChange={(option) => option && handleAddProfile(option.profile as ApiProfile)}
                            closeMenuOnSelect={false}
                        />
                        <ElementList size={ElementListSize.MEDIUM}>
                            {selectedProfiles.map((profile) =>
                                <LibTag
                                    key={profile.id}
                                    size={TagSize.MEDIUM}
                                    style={userProfiles.some((it) => it.id === profile.id) ? TagStyle.PRIMARY_MIDNIGHT_LIGHT : TagStyle.DEFAULT_MIDNIGHT_LIGHT}
                                    label={profile.name}
                                    icon={{...Action.REMOVE.icon, onClick: () => handleRemoveProfile(profile)}}
                                    reverse
                                />
                            )}
                        </ElementList>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditProfiles;
