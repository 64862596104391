import {CategorizerConfig} from "./CategorizerConfig";

export class CategorizerConfigIntentConfidence extends CategorizerConfig {
    intent_confidence: number = CategorizerConfigIntentConfidence.MIN_INTENT_CONFIDENCE_VALUE;

    static MIN_INTENT_CONFIDENCE_VALUE = 0;
    static MAX_INTENT_CONFIDENCE_VALUE = 10;
    static INTENT_CONFIDENCE_STEP = 1;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            intent_confidence: !this.disabled ? this.intent_confidence : undefined,
            disabled: this.disabled
        };
    }
}
