import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Status} from "../../../../utils/Status";
import {ContentElement} from "../../../interface/ContentElement";
import {CustomFields} from "../../CustomFields";
import {PartnerOrganization} from "../../partner/organization/PartnerOrganization";
import {User} from "../../user/User";
import {RevenueSeatField} from "./RevenueSeatField";
import {Module} from "../../../../utils/Module";

export class RevenueSeat extends ApiModel {
    id: number = 0;
    seat_id: string = "";
    platform: string = "";
    buyer: string = "";
    buyer_organization: string = "";
    buyer_type: string = "";
    brand: string = "";
    advertiser: string = "";
    advertiser_id: string = "";
    client_side: string = "";
    sales: string = "";
    end_date_new_business?: string;
    country: string = "";
    created_at?: string = "";
    updated_at?: string = "";
    hubspot_id?: string = "";
    partner_id?: number = 0;
    status: string = Status.PENDING.name;
    organization_id: number = 0;
    organization?: PartnerOrganization = undefined;
    owner_id: number = 0;
    owner?: User = undefined;
    custom_fields: CustomFields = {};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            this[RevenueSeatField.CUSTOM_FIELDS] = {};
            for (let key in o) {
                if (key === RevenueSeatField.CUSTOM_FIELDS) {
                    if (typeof o[key] === "string") {
                        this[key] = JSON.parse(o[key]);
                    } else this[key] = o[key];
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            seat_id: this.seat_id,
            platform: this.platform,
            buyer: this.buyer,
            buyer_organization: this.buyer_organization,
            buyer_type: this.buyer_type,
            brand: this.brand,
            advertiser: this.advertiser,
            advertiser_id: this.advertiser_id,
            client_side: this.client_side,
            sales: this.sales,
            end_date_new_business: this.end_date_new_business ? new Date(this.end_date_new_business).toISOString() : undefined,
            country: this.country,
            hubspot_id: this.hubspot_id,
            partner_id: this.partner_id,
            status: this.status,
            organization_id: this.organization_id,
            owner_id: this.owner_id,
            custom_fields: JSON.stringify(this.custom_fields)
        };
    }

    getRoute(): string {
        return Module.SEATS.buildRoute(this.id);
    }

    getStatus(): Status {
        switch (this.status) {
            case Status.APPROVED.name:
                return Status.APPROVED;
            case Status.PENDING.name:
                return Status.PENDING;
            default:
                return Status.PENDING;
        }
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, `${this?.organization?.name || ""} - ${this.platform}`);
        element.status = this.getStatus();
        if (this.created_at) {
            element.createdAt = new Date(this.created_at);
        }
        if (this.updated_at) {
            element.lastUpdated = new Date(this.updated_at);
        }
        return element;
    }

    isDifferentOwners(): boolean {
        return !!this.organization && this.owner_id !== this.organization.owner_id;
    }
}
