import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    FlexContent,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    RadioButtons,
    Slider,
    ToggleSwitch
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigField} from "../../../api/model/categorizer/config/CategorizerConfigField";
import {CategorizerConfigIntentConfidence} from "../../../api/model/categorizer/config/CategorizerConfigIntentConfidence";
import {CategorizerConfigIntentConfidenceField} from "../../../api/model/categorizer/config/CategorizerConfigIntentConfidenceField";
import {CategorizerConfigIntentConfidencePreset} from "../../../api/model/categorizer/config/CategorizerConfigIntentConfidencePreset";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {CategorizerConfigEntryInfo} from "../../snippet";
import {CategorizerConfigEntryParams} from "../../../api/model/categorizer/CategorizerConfigEntryParams";

type ModalAddCategorizerConfigIntentConfidenceProps = {
    params: CategorizerConfigEntryParams;
    active: boolean;
    onSubmit: () => void;
    onClose: () => void;
};

const ModalAddCategorizerConfigIntentConfidence: FunctionComponent<ModalAddCategorizerConfigIntentConfidenceProps> = ({params, active, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const [entry, setEntry] = useState<CategorizerConfigEntry<CategorizerConfigIntentConfidence>>(CategorizerConfigEntry.forType(CategorizerConfigType.INTENT_CONFIDENCE, params));
    const [isShowCustomIntentConfidence, setShowCustomIntentConfidence] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-add-categorizer-config-intent-confidence";

    useEffect(() => {
        if (active) {
            setEntry(CategorizerConfigEntry.forType(CategorizerConfigType.INTENT_CONFIDENCE, params));
            setShowCustomIntentConfidence(false);
        }
    }, [active, params]);

    const handleChange = (field: CategorizerConfigEntryField, value: any) => {
        setEntry((prevState) => new CategorizerConfigEntry<CategorizerConfigIntentConfidence>({...prevState, [field]: value}));
    };

    const handleChangeConfig = (field: CategorizerConfigIntentConfidenceField | CategorizerConfigField, value: any) => {
        handleChange(CategorizerConfigEntryField.CONFIG, new CategorizerConfigIntentConfidence({...entry.config, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await session.restCategorizerEntry.create(entry);
            alert.createWithSuccess(`${textCategorizer(`config_type.${CategorizerConfigType.INTENT_CONFIDENCE.name}`)} configuration`);
            onSubmit();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate(`${textCategorizer(`config_type.${CategorizerConfigType.INTENT_CONFIDENCE.name}`)} configuration`, e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategorizer("modal.add_new_configuration", {type: textCategorizer(`config_type.${CategorizerConfigType.INTENT_CONFIDENCE.name}`)})}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <CategorizerConfigEntryInfo currentEntry={entry}/>
                        <FieldBlock
                            label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigField.DISABLED}`)}
                            name={`${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigField.DISABLED}`}
                        >
                            <ToggleSwitch
                                checked={entry.config.disabled}
                                onChange={(value) => handleChangeConfig(CategorizerConfigField.DISABLED, value)}
                            />
                        </FieldBlock>
                        {!entry.config.disabled &&
                            <>
                                <FieldBlock label={textCategorizer("field.preset")}>
                                    <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XSMALL}>
                                        <RadioButtons
                                            id={"preset"}
                                            value={entry.config.intent_confidence}
                                            options={CategorizerConfigIntentConfidencePreset.values().map((it) => ({value: it.intentConfidence, label: textCategorizer(`preset.${it.name}`)}))}
                                            onChange={(value) => handleChangeConfig(CategorizerConfigIntentConfidenceField.INTENT_CONFIDENCE, value)}
                                        />
                                    </FlexContent>
                                </FieldBlock>
                                <FormLayoutColumns>
                                    <FieldBlock
                                        label={textCategorizer("field.custom_intent_confidence")}
                                        name={CategorizerConfigIntentConfidenceField.INTENT_CONFIDENCE}
                                    >
                                        <ToggleSwitch
                                            checked={isShowCustomIntentConfidence}
                                            onChange={setShowCustomIntentConfidence}
                                        />
                                    </FieldBlock>
                                    {isShowCustomIntentConfidence &&
                                        <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigIntentConfidenceField.INTENT_CONFIDENCE}`)}>
                                            <Slider
                                                value={(entry.config.intent_confidence) * 10}
                                                min={CategorizerConfigIntentConfidence.MIN_INTENT_CONFIDENCE_VALUE}
                                                max={CategorizerConfigIntentConfidence.MAX_INTENT_CONFIDENCE_VALUE}
                                                step={CategorizerConfigIntentConfidence.INTENT_CONFIDENCE_STEP}
                                                onChange={(value) => handleChangeConfig(CategorizerConfigIntentConfidenceField.INTENT_CONFIDENCE, value / 10)}
                                            />
                                        </FieldBlock>
                                    }
                                </FormLayoutColumns>
                            </>
                        }
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddCategorizerConfigIntentConfidence;
