import {PartnerLicenseInfoValueType} from "./PartnerLicenseInfoValueType";
import {ApiModel} from "../../../../common/api/model/ApiModel";

export class PartnerLicenseInfo extends ApiModel {
    key: string = "";
    value: any = "";
    valueType: PartnerLicenseInfoValueType = PartnerLicenseInfoValueType.STRING;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            key: this.key,
            value: this.value,
            valueType: this.valueType
        };
    }
}

export const LICENSE_INFO_VALUE_TYPES = [
    PartnerLicenseInfoValueType.STRING,
    PartnerLicenseInfoValueType.NUMBER,
    PartnerLicenseInfoValueType.OBJECT
];
