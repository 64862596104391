import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Status} from "../../../../utils/Status";
import {ContentElement} from "../../../interface/ContentElement";
import {HelperDomainField} from "./HelperDomainField";
import {HelperDomainPartner} from "./HelperDomainPartner";
import {HelperPrivacyConfiguration} from "./HelperPrivacyConfiguration";
import {Module} from "../../../../utils/Module";

export class HelperDomain extends ApiModel {
    readonly id: number = 0;
    readonly partner_id: number = 0;
    name: string = "";
    domain: string = "";
    status: string = Status.ACTIVE.name;
    privacy_policy: string = "";
    ga_tid: string = "";
    readonly creation_ts: string = "";
    readonly last_update_ts: string = "";
    readonly tag?: string;
    readonly legacy_tag?: string;
    readonly tids: string[] = [""];
    readonly partner?: HelperDomainPartner;
    privacy_configuration: HelperPrivacyConfiguration = new HelperPrivacyConfiguration();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === HelperDomainField.PRIVACY_CONFIGURATION) {
                    this.privacy_configuration = new HelperPrivacyConfiguration(o[key]);
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            domain: this.domain,
            status: this.status,
            privacy_policy: this.privacy_policy,
            ga_tid: this.ga_tid,
            privacy_configuration: this.privacy_configuration.getJsonParameters()
        };
    }

    getRoute(): string {
        return Module.HELPER_DOMAINS.buildRoute(this.id);
    }

    getStatus(): Status {
        return Status.getActiveStatuses().find((it) => it.name === this.status) || Status.ACTIVE;
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        element.status = this.getStatus();
        element.createdAt = new Date(this.creation_ts);
        element.lastUpdated = new Date(this.last_update_ts);
        return element;
    }
}
