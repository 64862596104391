import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Select, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {TaxonomyTag} from "../../api/model/taxonomy/tag/TaxonomyTag";
import {TaxonomyTagCountField} from "../../api/model/taxonomy/tag/TaxonomyTagCountField";
import {TaxonomyTagField} from "../../api/model/taxonomy/tag/TaxonomyTagField";
import {TAXONOMY_TAG_PURPOSES} from "../../api/model/taxonomy/tag/TaxonomyTagPurpose";
import {TaxonomyTagSearchQuery} from "../../api/model/taxonomy/tag/TaxonomyTagSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {Language} from "../../common/api/model/Language";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {TranslationCommonFile} from "../../common/utils/constants";
import ModalCreateTaxonomyTag from "../../component/modal/taxonomy-tags/ModalCreateTaxonomyTag";
import {MainContentHeader, MainContentHeaderAction} from "../../component/snippet";
import TaxonomyTagRow from "../../component/snippet/table/taxonomy-tags/TaxonomyTagRow";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";

function TaxonomyTags() {
    const alert = useAlert();
    const {t: textTags} = useTranslation(TranslationPortalFile.TAXONOMY_TAGS);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreateTaxonomyTag, setShowModalCreateTaxonomyTag] = useState(false);
    const {setSearchResult, ...search} = useSearch(TaxonomyTag, TaxonomyTagSearchQuery);

    useEffect(() => {
        (async function () {
            try {
                const newSearchResult = await session.restTaxonomyTag.search(search.searchQuery);
                setSearchResult(newSearchResult);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("tags", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [search.searchQuery, setSearchResult, alert]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.TAXONOMY_TAGS}>
                <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateTaxonomyTag(true)}/>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar searchBar={{placeholder: textTags("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}} canHideFilters>
                            <SearchField label={textTags(`field.${TaxonomyTagField.PURPOSE}`)}>
                                <Select
                                    value={search.searchQuery.purpose}
                                    options={TAXONOMY_TAG_PURPOSES.map((purpose) => ({label: textTags(`purpose.${purpose}`), value: purpose}))}
                                    onChange={(option) => search.changeParam(TaxonomyTagField.PURPOSE, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textTags(`field.${TaxonomyTagField.LANGUAGE}`)}>
                                <Select
                                    value={search.searchQuery.language}
                                    options={Language.values().map((language) => ({label: textMain(`language.${language.name}`), value: language.name}))}
                                    onChange={(option) => search.changeParam(TaxonomyTagField.LANGUAGE, option?.value)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 10, label: textTags(`field.${TaxonomyTagField.PURPOSE}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textTags(`field.${TaxonomyTagField.LANGUAGE}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 30, label: textTags(`field.${TaxonomyTagField.NAME}`), sort: {field: TaxonomyTagField.NAME}},
                                        {width: 25, label: textTags(`field.count.${TaxonomyTagCountField.SEGMENT}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 25, label: textTags(`field.count.${TaxonomyTagCountField.CATEGORY}`), styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((tag: TaxonomyTag) =>
                                        <TaxonomyTagRow key={tag.name} tag={tag}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateTaxonomyTag
                    active={isShowModalCreateTaxonomyTag}
                    onClose={() => setShowModalCreateTaxonomyTag(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default TaxonomyTags;
