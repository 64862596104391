import {Action, ActionMenuItem, ActionsMenu, Box, BoxRadius, BoxSpacing, Icon, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Media} from "../../api/model/media/Media";
import {MediaType} from "../../api/model/media/MediaType";

type MediaCardProps = {
    media: Media;
    onClick: (e: any) => void;
    onDelete?: () => void;
};

const MediaCard: FunctionComponent<MediaCardProps> = ({media, onClick}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    const buildMediaPreview = () => {
        switch (media?.type) {
            case MediaType.IMAGE.name:
                return <div style={{backgroundImage: `url(${media.url}`}}/>;
            case MediaType.FONT.name:
                return <Icon name="format_size" cssClass="card-media__content__icon"/>;
            case MediaType.VIDEO.name:
                return <Icon name="smart_display" cssClass="card-media__content__icon"/>;
            default:
                return <></>;
        }
    };

    const buildActions = () => {
        const actions: ActionMenuItem[] = [];
        actions.push( {label: textCommon(Action.OPEN.labelKey), onClick: () => window.open(media.url, "_blank")});
        return actions;
    };

    return (
        <div onClick={onClick}>
            <Box radius={BoxRadius.LG} spacing={BoxSpacing.NONE} cssClass="card-media">
                <div className="card-media__menu" onClick={(e) => e.stopPropagation()}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={buildActions()}
                    />
                </div>
                <div className="card-media__content">
                    {buildMediaPreview()}
                </div>
                <span className="card-media__name">{media.title || media.name.split("/").pop()}</span>
            </Box>
        </div>
    );
};

export default MediaCard;
