import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {PartnerLicense} from "../../api/model/partner/license/PartnerLicense";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreatePartnerLicense from "../../component/modal/licenses/ModalCreatePartnerLicense";
import {MainContentHeader, MainContentHeaderAction, PartnerLicenseRow} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import useSearch from "../../utils/hooks/useSearch";
import {PartnerLicenseField} from "../../api/model/partner/license/PartnerLicenseField";
import useAlert from "../../utils/hooks/useAlert";

function Licenses() {
    const {t: textLicenses} = useTranslation(TranslationPortalFile.LICENSES);
    const [isLoading, setLoading] = useState(true);
    const alert = useAlert();
    const [licenses, setLicenses] = useState<PartnerLicense[]>([]);
    const [isShowModalCreatePartnerLicense, setShowModalCreatePartnerLicense] = useState(false);
    const {buildSearchResult, ...search} = useSearch(PartnerLicense);

    useEffect(() => {
        (async () => {
            try {
                const result = await session.restPartnerLicense.list();
                setLicenses(result);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("licenses", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        let currentLicenses = (licenses || []);
        if (search.searchQuery.query) {
            currentLicenses = currentLicenses.filter(({id, name}) => `${id} ${name}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentLicenses);
    }, [licenses, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.LICENSES}>
                <RestrictedContent allowedTo={Authorization.LICENSES.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreatePartnerLicense(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar searchBar={{placeholder: textLicenses("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}/>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textLicenses(`field.${PartnerLicenseField.ID}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: PartnerLicenseField.ID}},
                                        {width: 30, label: textLicenses(`field.${PartnerLicenseField.NAME}`), sort: {field: PartnerLicenseField.NAME}},
                                        {width: 65, label: textLicenses(`field.${PartnerLicenseField.DESCRIPTION}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((license: PartnerLicense) => <PartnerLicenseRow key={license.id} license={license}/>)}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreatePartnerLicense
                    active={isShowModalCreatePartnerLicense}
                    onClose={() => setShowModalCreatePartnerLicense(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Licenses;
