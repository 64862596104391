import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {PartnerPricing} from "../model/partner/pricing/PartnerPricing";
import {SearchResult} from "../interface/SearchResult";
import {PartnerPricingSearchQuery} from "../model/partner/pricing/PartnerPricingSearchQuery";

export class RestPartnerPricing extends Rest implements RestService<PartnerPricing> {
    rootPath = "/console-api/pricing/partner";

    search(params: any): Promise<SearchResult<PartnerPricing>> {
        return new Promise<SearchResult<PartnerPricing>>(() => {}); // TODO Implement route
    }

    list(params: PartnerPricingSearchQuery): Promise<PartnerPricing[]> {
        return this._client.get(`${this.rootPath}`, PartnerPricing, {queryParams: params.getJsonParameters()}) as Promise<PartnerPricing[]>;
    }

    create(partnerPricing: PartnerPricing): Promise<PartnerPricing> {
        return this._client.post(`${this.rootPath}`, partnerPricing, PartnerPricing) as Promise<PartnerPricing>;
    }

    get(partnerPricingId: number): Promise<PartnerPricing> {
        return this._client.get(`${this.rootPath}/${partnerPricingId}`, PartnerPricing) as Promise<PartnerPricing>;
    }

    update(partnerPricing: PartnerPricing): Promise<PartnerPricing> {
        return this._client.put(`${this.rootPath}/${partnerPricing.id}`, partnerPricing, PartnerPricing) as Promise<PartnerPricing>;
    }

    delete(partnerPricingId: number): Promise<PartnerPricing>  {
        return this._client.delete(`${this.rootPath}/${partnerPricingId}`) as Promise<PartnerPricing>;
    }
}
