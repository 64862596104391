import {TableColumn, TableRow} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {AdsAccess} from "../../../../api/model/audience/ads/AdsAccess";
import {LabelPartner, LabelStatus} from "../..";
import {Partner} from "../../../../api/model/partner/Partner";

type AdsAccessRowProps = {
    adsAccess: AdsAccess;
    partner?: Partner;
    sharedTaxonomyPartners: Partner[];
    globalTaxonomyPartners: Partner[];
};

const AdsAccessRow: FunctionComponent<AdsAccessRowProps> = ({adsAccess, partner, sharedTaxonomyPartners, globalTaxonomyPartners}) => {
    return (
        <TableRow onClick={{link: adsAccess.getRoute()}}>
            <TableColumn>
                <LabelStatus status={adsAccess.getStatus()}/>
            </TableColumn>
            <TableColumn>{adsAccess.id}</TableColumn>
            <TableColumn>{adsAccess.name}</TableColumn>
            <TableColumn>
                <LabelPartner partner={partner}/>
            </TableColumn>
            <TableColumn>
                {sharedTaxonomyPartners.length ? sharedTaxonomyPartners.map((partner) => partner.fullName).join(", ") : "-"}
            </TableColumn>
            <TableColumn>
                {globalTaxonomyPartners.length ? globalTaxonomyPartners.map((partner) => partner.fullName).join(", ") : "-"}
            </TableColumn>
        </TableRow>
    );
};

export default AdsAccessRow;
