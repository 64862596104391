import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";
import {PartnerPricingField} from "./PartnerPricingField";

export class PartnerPricingSearchQuery extends SearchQuery {
    partner_id: number | undefined;
    pricing_id: number | undefined;

    withSearchParams(params: SearchParams): PartnerPricingSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(PartnerPricingField.START_DATE, true) as PartnerPricingSearchQuery;
        newQuery.partner_id = params.getNumber(SearchParamsField.PARTNER);
        newQuery.pricing_id = params.getNumber("pricing_id");
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            id_partner: this.partner_id,
            id_pricing: this.pricing_id
        };
    }
}
