import {ApiModel} from "../../../common/api/model/ApiModel";
import {ApiProfile} from "./ApiProfile";
import {AuthorizationDetailsField} from "./AuthorizationDetailsField";

export class AuthorizationDetails extends ApiModel {
    id: number = 0;
    authorization_set: string[] = [];
    profiles: ApiProfile[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObjects(AuthorizationDetailsField.PROFILES, ApiProfile);
    }
}
