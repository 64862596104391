import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ButtonLinkCancel, ButtonValidate, ContentBlock, Form, FormValidationType, InputCheckbox, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, SearchBarClearable, Table, TableColumn, TableColumnStyle, TableImage, TableRow, Tooltip} from "@sirdata/ui-lib";
import {CmpPartner} from "../../../utils/cmp/CmpPartner";
import {CmpPartnerField} from "../../../utils/cmp/CmpPartnerField";
import {MODAL_PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";

type ModalEditCmpConfigPartnersProps = {
    active: boolean;
    partners: CmpPartner[];
    currentSelectedPartners: CmpPartner[];
    onSubmit: (partners: CmpPartner[]) => void;
    onClose: () => void;
};

const ModalEditCmpConfigPartners: FunctionComponent<ModalEditCmpConfigPartnersProps> = ({active, partners, currentSelectedPartners, onSubmit, onClose}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [currentPartners, setCurrentPartners] = useState<CmpPartner[]>([]);
    const [selectedPartners, setSelectedPartners] = useState<CmpPartner[]>(currentSelectedPartners);
    const [query, setQuery] = useState("");
    const FORM_ID = "form-edit-cmp-config-partners";

    useEffect(() => {
        if (active) {
            setSelectedPartners(currentSelectedPartners);
            setQuery("");
        }
    }, [active, currentSelectedPartners]);

    useEffect(() => {
        const newCurrentPartners = partners.filter((item) => `${item.id} ${item.name}`.toLowerCase().includes(query.toLowerCase()));
        setCurrentPartners(newCurrentPartners);
    }, [query, partners]);

    const isAllCurrentPartnersSelected = () => {
        return !!currentPartners.length && currentPartners.filter(({id}) => selectedPartners.find((selected) => selected.id === id)).length === currentPartners.length;
    };

    const handleSelectAll = () => {
        const selectableCurrentPartners = currentPartners.filter((it) => !it.isUtiqVendor);
        if (isAllCurrentPartnersSelected()) {
            setSelectedPartners((prevState) => prevState.filter(({id}) => !selectableCurrentPartners.map(({id}) => id).includes(id)));
        } else {
            setSelectedPartners((prevState) => [...prevState, ...selectableCurrentPartners]);
        }
    };

    const handleSelectPartner = (partner: CmpPartner) => {
        let newSelectedPartners = [...selectedPartners];
        if (newSelectedPartners.find(({id, type}) => partner.id === id && partner.type === type)) {
            newSelectedPartners = newSelectedPartners.filter(({id, type}) => partner.id !== id || partner.type !== type);
        } else {
            newSelectedPartners.push(partner);
        }
        setSelectedPartners(newSelectedPartners);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        onSubmit(selectedPartners);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpConfigurations("edit_partners")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <ContentBlock>
                        <SearchBarClearable
                            placeholder={textCmpConfigurations("search.partner")}
                            value={query}
                            onSubmit={setQuery}
                            form={""}
                        />
                        <Table
                            columns={[
                                {width: 10, label: <InputCheckbox checked={isAllCurrentPartnersSelected()} onChange={handleSelectAll}/>, styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 10, label: textCmpConfigurations(`partners.${CmpPartnerField.TYPE}`), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 15, label: textCmpConfigurations(`partners.${CmpPartnerField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 65, label: textCmpConfigurations(`partners.${CmpPartnerField.NAME}`)}
                            ]}
                            pagination={MODAL_PAGE_SIZE}
                        >
                            {currentPartners.map((partner) =>
                                <TableRow
                                    key={`${partner.type}-${partner.id}`}
                                    onClick={partner.isUtiqVendor ? undefined : () => handleSelectPartner(partner)}
                                >
                                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                        <InputCheckbox
                                            checked={selectedPartners.some(({id, type}) => partner.id === id && partner.type === type)}
                                            onChange={() => {}}
                                            disabled={partner.isUtiqVendor}
                                        />
                                    </TableColumn>
                                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}><TableImage image={partner.logo}/></TableColumn>
                                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{partner.id}</TableColumn>
                                    <TableColumn>
                                        {partner.isUtiqVendor ?
                                            <Tooltip text={textCmpConfigurations("message.utiq_vendor")}>
                                                <TableImage image={"/images/logos/utiq.png"}/>
                                            </Tooltip> :
                                            partner.name
                                        }
                                    </TableColumn>
                                </TableRow>
                            )}
                        </Table>
                    </ContentBlock>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditCmpConfigPartners;
