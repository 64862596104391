import {SearchParamsField} from "./SearchParamsField";
import {SearchParamsValue} from "./SearchParamsValue";

export class SearchParams extends URLSearchParams {
    withBoolean(key: string, value?: boolean): SearchParams {
        value !== undefined ? this.set(key, value ? SearchParamsValue.TRUE : SearchParamsValue.FALSE) : this.delete(key);
        this.delete(SearchParamsField.PAGE);
        return this;
    }

    withNumber(key: string, value?: number): SearchParams {
        !!value ? this.set(key, `${value}`) : this.delete(key);
        if (key !== SearchParamsField.PAGE) {
            this.delete(SearchParamsField.PAGE);
        }
        return this;
    }

    withString(key: string, value?: string): SearchParams {
        !!value ? this.set(key, value) : this.delete(key);
        this.delete(SearchParamsField.PAGE);
        return this;
    }

    with(obj: {[key: string]: boolean | number | string}): SearchParams {
        let params = new SearchParams(this);
        for (let [key, value] of Object.entries(obj)) {
            if (typeof value === "boolean") {
                params = params.withBoolean(key, value);
            } else if (typeof value === "number") {
                params = params.withNumber(key, value);
            } else {
                params = params.withString(key, value);
            }
        }
        return params;
    }

    withDelete(key: string): SearchParams {
        this.delete(key);
        return this;
    }

    private getParam(key: string): string | undefined {
        if (!this.has(key)) return undefined;
        const value = this.get(key);
        return value !== null ? value : undefined;
    }

    getBoolean(key: string): boolean | undefined {
        const value = this.getParam(key);
        return value !== undefined ? value === SearchParamsValue.TRUE : undefined;
    }

    getNumber(key: string): number | undefined {
        const param = this.getParam(key);
        return param ? Number(param) : undefined;
    }

    getString(key: string): string | undefined {
        return this.getParam(key);
    }
}
