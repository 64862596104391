import {Action, ContentBlock, ContentBlockAction, Loadable, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import copy from "copy-to-clipboard";
import React, {FC, RefObject, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {SegmentDataType} from "../../../api/model/audience/segment/SegmentDataType";
import {SegmentLinkCategory} from "../../../api/model/audience/segment/SegmentLinkCategory";
import {SegmentLinkCategoryField} from "../../../api/model/audience/segment/SegmentLinkCategoryField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {copyApiModelArray} from "../../../common/utils/helper";
import {detectChanges} from "../../../common/utils/portal";
import {SegmentsDetailsSubModule} from "../../../screen/segments/SegmentsDetails";
import {HandleSaveRef} from "../../../utils/audience/HandleSaveRef";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import useSearch from "../../../utils/hooks/useSearch";
import ModalConfirmRemove from "../../modal/ModalConfirmRemove";
import ModalAddSegmentLinksCategory from "../../modal/segments/ModalAddSegmentLinksCategory";
import {SegmentLinkCategoryRow} from "../index";

type SegmentCategoryLinksProps = {
    segmentId: number;
    segmentType?: SegmentDataType;
    percentile?: string;
    handleSaveRef: RefObject<HandleSaveRef>;
    onChange: (module: SegmentsDetailsSubModule, hasChanges: boolean) => void;
};

const SegmentCategoryLinks: FC<SegmentCategoryLinksProps> = ({segmentId, segmentType, percentile, handleSaveRef, onChange}) => {
    const alert = useAlert();
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const [isLoading, setLoading] = useState(true);
    const [currentPercentile, setCurrentPercentile] = useState<string>();

    const [categoryLinks, setCategoryLinks] = useState<SegmentLinkCategory[]>([]);
    const [initCategoryLinks, setInitCategoryLinks] = useState<SegmentLinkCategory[]>([]);
    const {buildSearchResult, ...search} = useSearch(SegmentLinkCategory);

    const [isShowModalAddSegmentLinksCategory, setShowModalAddSegmentLinksCategory] = useState(false);
    const [activeRemoveLink, setActiveRemoveLink] = useState<SegmentLinkCategory>();

    const loadCategories = useCallback(async () => {
        if (!segmentId) return;
        try {
            const newCategoryLinks = await session.restSegmentLinkCategory.list(segmentId);
            setCategoryLinks(newCategoryLinks);
            setInitCategoryLinks(copyApiModelArray(SegmentLinkCategory, newCategoryLinks));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("categories", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [segmentId, alert]);

    const updateCategoryLinks = useCallback((newCategoryLinks: SegmentLinkCategory[]) => {
        setCategoryLinks(newCategoryLinks);
    }, []);

    const handleSaveCategoryLinks = async (newCategoryLinks?: SegmentLinkCategory[]) => {
        if (!newCategoryLinks) {
            newCategoryLinks = categoryLinks;
        }
        setLoading(true);
        try {
            await session.restSegmentLinkCategory.update(segmentId, newCategoryLinks);
            alert.updateWithSuccess("categories");
            await loadCategories();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("categories", e.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            await loadCategories();
        })();
    }, [segmentId, loadCategories]);

    useEffect(() => {
        onChange(SegmentsDetailsSubModule.LINKS_CATEGORIES, detectChanges(categoryLinks, initCategoryLinks));
    }, [onChange, categoryLinks, initCategoryLinks]);

    useEffect(() => {
        if (!percentile || currentPercentile === percentile) return;
        setCurrentPercentile(percentile);
        if (categoryLinks.length && initCategoryLinks.length) {
            const newCategoryLinks = categoryLinks.map((link) => new SegmentLinkCategory({...link, percentile: percentile}));
            updateCategoryLinks(newCategoryLinks);
        }
    }, [percentile, currentPercentile, initCategoryLinks.length, categoryLinks, updateCategoryLinks]);

    useEffect(() => {
        let currentCategoryLinks = (categoryLinks || []);
        if (search.searchQuery.query) {
            currentCategoryLinks = currentCategoryLinks.filter((it) => `${it.category_id} ${it.category_name}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentCategoryLinks);
    }, [categoryLinks, search.searchQuery, buildSearchResult]);

    useImperativeHandle(handleSaveRef, () => ({
        handleSave: handleSaveCategoryLinks
    }));

    const handleCopyCategoryIds = () => {
        const categoryIdsAsString = categoryLinks.map((item) => item.category_id).sort().join(",");
        copy(categoryIdsAsString);
        alert.copied("category IDs");
    };

    const handleDeleteCategoryLink = async () => {
        if (activeRemoveLink) {
            const newCategoryLinks = categoryLinks.filter((it) => it.category_id !== activeRemoveLink.category_id);
            await handleSaveCategoryLinks(newCategoryLinks);
            search.changePage(1);
        }
        setActiveRemoveLink(undefined);
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{title: {label: textSegments("section.categories")}, actions: [
                    <ContentBlockAction
                        key="action-copy-category-ids"
                        action={Action.COPY}
                        label={"Category IDs"}
                        onClick={handleCopyCategoryIds}
                    />,
                    <RestrictedContent key="action-add-segment-link-category" allowedTo={Authorization.SEGMENTS.update}>
                        <ContentBlockAction
                            action={Action.ADD}
                            onClick={() => setShowModalAddSegmentLinksCategory(true)}
                        />
                    </RestrictedContent>
                ]}}
            >
                <SearchToolbar searchBar={{placeholder: textCategories("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}/>
                <Table
                    columns={[
                        {width: 15, label: textSegments(`field.link_category.${SegmentLinkCategoryField.CATEGORY_ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 50, label: textSegments(`field.link_category.${SegmentLinkCategoryField.CATEGORY_NAME}`), styles: TableColumnStyle.FIXED_WIDTH},
                        {width: 10, label: textSegments(`field.link_category.${SegmentLinkCategoryField.VOLUME}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 10, label: textSegments(`field.link_category.${SegmentLinkCategoryField.MIN_SCORE}`), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.NOWRAP]},
                        {width: 5}
                    ]}
                    pagination={search.searchResult.getPagination(search.changePage)}
                >
                    {search.searchResult.elements.map((link) =>
                        <SegmentLinkCategoryRow
                            key={link.category_id}
                            link={link}
                            onRemove={() => setActiveRemoveLink(link)}
                            showCategoryType={segmentType?.name === SegmentDataType.INFERRED.name}
                        />
                    )}
                </Table>
                <ModalAddSegmentLinksCategory
                    active={isShowModalAddSegmentLinksCategory}
                    segmentId={segmentId}
                    segmentType={segmentType}
                    percentile={percentile}
                    currentLinks={categoryLinks}
                    onClose={(refresh) => {
                        setShowModalAddSegmentLinksCategory(false);
                        refresh && loadCategories();
                    }}
                />
                <ModalConfirmRemove
                    active={!!activeRemoveLink}
                    entity={`link to category "${activeRemoveLink?.category_name}"`}
                    confirm={handleDeleteCategoryLink}
                    cancel={() => setActiveRemoveLink(undefined)}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default SegmentCategoryLinks;
