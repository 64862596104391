import {TableColumn, TableColumnStyle, TableImage, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TaxonomyTag} from "../../../../api/model/taxonomy/tag/TaxonomyTag";
import {Language} from "../../../../common/api/model/Language";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Tag} from "../../index";

type TaxonomyTagRowProps = {
    tag: TaxonomyTag;
}

const TaxonomyTagRow: FunctionComponent<TaxonomyTagRowProps> = ({tag}) => {
    const {t: textTags} = useTranslation(TranslationPortalFile.TAXONOMY_TAGS);

    const getLanguageFlag = (language: string) => {
        const flagUrl = Language.getFlagUrlforName(language);
        if (flagUrl) {
            return <TableImage image={flagUrl}/>;
        }
        return <></>;
    };

    return (
        <TableRow key={tag.name} onClick={{link: tag.getRoute()}}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <Tag label={textTags(`purpose.${tag.purpose}`)} style={TagStyle.PRIMARY_OCEAN}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{getLanguageFlag(tag.language)}</TableColumn>
            <TableColumn>
                <Tag label={tag.name} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{tag.count.segment ? textTags("count.count_segment", {count: tag.count.segment}) : "-"}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{tag.count.category ? textTags("count.count_category", {count: tag.count.category}) : "-"}</TableColumn>
        </TableRow>
    );
};

export default TaxonomyTagRow;
