import {ApiModel} from "../../../common/api/model/ApiModel";
import {CategorizerConfigType} from "./CategorizerConfigType";

export class CategorizerConfigSearchResultItem extends ApiModel {
    domain: string = "";
    types: string[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    hasType(type: CategorizerConfigType) {
        return this.types.includes(type.name);
    }
}
