export enum HelperPrivacyConfigurationReferer {
    NO_ALTER = "NO_ALTER",
    KEEP_DOMAIN = "KEEP_DOMAIN",
    REMOVE = "REMOVE",
}

export const PRIVACY_CONFIGURATION_REFERER_VALUES = [
    HelperPrivacyConfigurationReferer.NO_ALTER,
    HelperPrivacyConfigurationReferer.KEEP_DOMAIN,
    HelperPrivacyConfigurationReferer.REMOVE
];
