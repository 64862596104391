export enum PortalContentField {
    ID = "id",
    SERVICE = "service",
    STATUS = "status",
    STRING_IDENTIFIER = "string_identifier",
    TITLE = "title",
    CONTENT = "content",
    TYPE = "type",
    USER_ID = "user_id",
    START_DATE = "start_date",
    END_DATE = "end_date",
    CREATION_TS = "creation_ts",
    LAST_UPDATE_TS = "last_update_ts",
    SEARCH_INDEX = "search_index",
}
