export enum PricingField {
    ID = "id",
    ID_USER = "id_user",
    API_SERVICE = "api_service",
    DEFAULT = "default",
    NAME = "name",
    INVOICE_LABEL = "invoice_label",
    DESCRIPTION = "description",
    BILLING_PERIOD = "billing_period",
    BILLING_DAY = "billing_day",
    FEE = "fee",
    VOLUME_COST_PER_UNIT = "volume_cost_per_unit",
    DIVIDER_UNIT = "divider_unit",
    STEP_PRICE_IS_PER_UNIT = "step_price_is_per_unit",
    AXONAUT_PRODUCT_ID = "axonaut_product_id",
    STEPS_PRICE = "steps_price",
    BARTER_AGREEMENT = "barter_agreement",
    STATUS = "status",
    ONLY_LAST_STEP_PRICE_BILLABLE = "only_last_step_price_billable",
    CREATION_TS = "creation_ts",
    LAST_UPDATE_TS = "last_update_ts"
}
