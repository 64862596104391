import {ButtonLinkCancel, ButtonValidate, Form, FormValidationType, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Slider} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CategoryGroupLinkKeyword} from "../../../api/model/audience/category/CategoryGroupLinkKeyword";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalEditKeywordsThresholdProps = {
    active: boolean;
    initValue: number;
    onSubmit: (value: number) => void;
    onClose: () => void;
};

const ModalEditKeywordsThreshold: FunctionComponent<ModalEditKeywordsThresholdProps> = ({active, initValue, onSubmit, onClose}) => {
    const {t: textKeywords} = useTranslation(TranslationPortalFile.CATEGORY_KEYWORDS);
    const [threshold, setThreshold] = useState<number>(initValue);
    const FORM_ID = "form-edit-keywords-threshold";

    useEffect(() => {
        setThreshold(initValue);
    }, [active, initValue]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(threshold);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textKeywords("modal.edit_global_threshold")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <Slider
                        value={threshold}
                        onChange={(value) => setThreshold(value)}
                        min={CategoryGroupLinkKeyword.MIN_THRESHOLD}
                        max={CategoryGroupLinkKeyword.MAX_THRESHOLD}
                        step={CategoryGroupLinkKeyword.THRESHOLD_STEP}
                    />
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditKeywordsThreshold;
