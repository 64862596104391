import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {CmpConfigPublisherLegalBasis} from "./CmpConfigPublisherLegalBasis";

export class CmpConfigPublisherStandardPurpose extends ApiModel {
    id: number = 0;
    legalBasis: CmpConfigPublisherLegalBasis = CmpConfigPublisherLegalBasis.CONSENT;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
