export enum MediaField {
    ID = "id",
    USER_ID = "user_id",
    NAME = "name",
    TITLE = "title",
    TYPE = "type",
    MIME_TYPE = "mime_type",
    FILE_SIZE = "file_size",
    CDN_HOST = "cdn_host",
    URL_PATH = "url_path",
    STATUS = "status",
    CREATION_TS = "creation_ts",
    LAST_UPDATE_TS = "last_update_ts"
}