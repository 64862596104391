import {AlertSeverity, ContentBlock, FieldBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, InputNumber, Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksSeverity} from "../../../api/model/dataleaks/DataleaksSeverity";
import {DataleaksRegulation} from "../../../api/model/dataleaks/DataleaksRegulation";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {DataleaksVersion} from "../../../api/model/dataleaks/version/DataleaksVersion";
import {DataleaksSeverityFlag} from "../index";

type DataleaksVersionRegulationWeightsProps = {
    version: DataleaksVersion;
    onChange: (regulation: DataleaksRegulation, severity: DataleaksSeverity, value: number) => void;
};

const DataleaksVersionRegulationWeights: FunctionComponent<DataleaksVersionRegulationWeightsProps> = ({version, onChange}) => {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);
    const {t: textDataleaksVersions} = useTranslation(TranslationPortalFile.DATALEAKS_VERSIONS);

    const handleChange = (regulation: DataleaksRegulation, severity: DataleaksSeverity, value: number) => {
        onChange(regulation, severity, value);
    };

    const getTotalWeightForRegulation = (regulation: DataleaksRegulation): number => {
        let total = 0;
        const severitiesWeights = version.getSeveritiesWeightsForRegulation(regulation);
        Object.values(DataleaksSeverity).forEach((severity) => {
            total += severitiesWeights.getWeight(severity);
        });
        return total;
    };

    return (
        <ContentBlock header={{title: {label: textDataleaksVersions("section.weights_per_regulation")}}}>
            <Table
                columns={[
                    {width: 20},
                    {width: 40, label: textDataleaksQuestions(`regulation.${DataleaksRegulation.GDPR}`), styles: TableColumnStyle.ALIGN_CENTER},
                    {width: 40, label: textDataleaksQuestions(`regulation.${DataleaksRegulation.EPRIVACY}`), styles: TableColumnStyle.ALIGN_CENTER}
                ]}
            >
                {Object.values(DataleaksSeverity).map((severity) =>
                    <TableRow key={severity}>
                        <TableColumn>
                            <DataleaksSeverityFlag severity={severity}/>
                            <span>{textDataleaksAudits(`severity.${severity}`)}</span>
                        </TableColumn>
                        <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                            <InputNumber
                                value={version.getSeveritiesWeightsForRegulation(DataleaksRegulation.GDPR).getWeight(severity)}
                                onChange={(value) => handleChange(DataleaksRegulation.GDPR, severity, value)}
                                min={0}
                                max={100}
                                small
                            />
                            <span>%</span>
                        </TableColumn>
                        <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                            <InputNumber
                                value={version.getSeveritiesWeightsForRegulation(DataleaksRegulation.EPRIVACY).getWeight(severity)}
                                onChange={(value) => handleChange(DataleaksRegulation.EPRIVACY, severity, value)}
                                min={0}
                                max={100}
                                small
                            />
                            <span>%</span>
                        </TableColumn>
                    </TableRow>
                )}
                <TableRow>
                    <TableColumn>
                        <strong>{textDataleaksVersions("total")}</strong>
                    </TableColumn>
                    <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                        <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                            <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                                <InputNumber
                                    value={getTotalWeightForRegulation(DataleaksRegulation.GDPR)}
                                    onChange={() => {}}
                                    small
                                    max={100}
                                    disabled
                                />
                                <span>%</span>
                            </FlexContent>
                            {getTotalWeightForRegulation(DataleaksRegulation.GDPR) !== 100 &&
                                <FormLayoutMessage
                                    message={textDataleaksVersions("message.invalid_total_weight")}
                                    severity={AlertSeverity.DANGER}
                                    small
                                />
                            }
                        </FieldBlock>
                    </TableColumn>
                    <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                        <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                            <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                                <InputNumber
                                    value={getTotalWeightForRegulation(DataleaksRegulation.EPRIVACY)}
                                    onChange={() => {}}
                                    small
                                    max={100}
                                    disabled
                                />
                                <span>%</span>
                            </FlexContent>
                            {getTotalWeightForRegulation(DataleaksRegulation.EPRIVACY) !== 100 &&
                                <FormLayoutMessage
                                    message={textDataleaksVersions("message.invalid_total_weight")}
                                    severity={AlertSeverity.DANGER}
                                    small
                                />
                            }
                        </FieldBlock>
                    </TableColumn>
                </TableRow>
            </Table>
        </ContentBlock>
    );
};

export default DataleaksVersionRegulationWeights;
