export class DealInventoryType {
    static WEB: DealInventoryType = new DealInventoryType("WEB");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): DealInventoryType[] {
        return [
            DealInventoryType.WEB
        ];
    }
}
