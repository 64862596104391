import {Action, ActionsMenu, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {CategoryBQRequest} from "../../../../api/model/audience/category/CategoryBQRequest";
import {RestrictedContent} from "../../../../common/component/widget";
import {LabelStatus} from "../../index";

type CategoryBQRequestRowProps = {
    request: CategoryBQRequest;
    onClick: () => void;
    onRemove: (request: CategoryBQRequest) => void;
};

const CategoryBQRequestRow: FunctionComponent<CategoryBQRequestRowProps> = ({request, onClick, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <TableRow onClick={onClick}>
            <TableColumn><LabelStatus status={request.getStatus()}/></TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{request.id || "-"}</TableColumn>
            <TableColumn>{request.name}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{request.points}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{request.expiration_day}</TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <RestrictedContent allowedTo={Authorization.CATEGORIES.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={[
                            {label: textCommon(Action.REMOVE.labelKey), onClick: () => onRemove(request), critical: true, separator: true}
                        ]}
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default CategoryBQRequestRow;
