import {Color, Icon, TagStyle} from "@sirdata/ui-lib";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {CategoryGroup} from "../../../api/model/audience/category/CategoryGroup";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import clsx from "clsx";
import {Tag} from "../index";
import {CategoryField} from "../../../api/model/audience/category/CategoryField";

type CategoryItemProps = {
    categoryGroup: CategoryGroup;
    categoryType: CategoryType;
    onClick: (type: CategoryType) => void;
    isSelected: boolean;
};

const CategoryItem: FC<CategoryItemProps> = ({categoryGroup, categoryType, onClick, isSelected}) => {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const category = categoryGroup.getCategory(categoryType);

    return (
        <div
            className={clsx("categories__types__item", {"categories__types__item--active": !!category}, {"categories__types__item--selected": isSelected})}
            onClick={() => onClick(categoryType)}
        >
            <div className="categories__types__item__name">
                <Icon
                    {...categoryType.icon}
                    colorIcon={!!category ? categoryType.getIconColor() : Color.GREY_DARK}
                    cssClass={"categories__types__item__icon"}
                />
                <span>{textCategories(`category_type.${categoryType.name}`)}</span>
            </div>
            {category ?
                <Tag label={`${textCategories(`field.category.${CategoryField.ID}`)} : ${category.id}`} style={TagStyle.DEFAULT_OCEAN}/> :
                <Tag label={textCategories("message.click_to_activate")} style={TagStyle.PRIMARY_GREY_DARK}/>
            }
        </div>
    );
};

export default CategoryItem;
