import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DistributionContactType} from "./DistributionContactType";

export class DistributionContact extends ApiModel {
    id: number = 0;
    type: DistributionContactType = DistributionContactType.MAIN;
    first_name: string = "";
    last_name: string = "";
    email: string = "";
    phone: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get name(): string {
        return `${this.first_name} ${this.last_name}`;
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            type: this.type,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            phone: this.phone
        };
    }

}