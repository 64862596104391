import {ApiModel} from "../../../../common/api/model/ApiModel";
import {RevenuePlatform} from "../RevenuePlatform";

export class RevenueImportJob extends ApiModel {
    platform: RevenuePlatform = new RevenuePlatform();
    uuid: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
