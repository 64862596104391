import {Status} from "../../utils/Status";

export class ContentElement {
    id: string | number;
    title: string;
    status?: Status;
    createdAt?: Date;
    lastUpdated?: Date;
    url?: string;

    constructor(id: string | number, title: string) {
        this.id = id;
        this.title = title;
    }
}
