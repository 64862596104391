import {CategorizerConfig} from "./CategorizerConfig";

export class CategorizerConfigFilter extends CategorizerConfig {
    filtered: boolean = true;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            filtered: this.filtered
        };
    }
}
