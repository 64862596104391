import {Action, ActionsMenu, Color, ContentBlock, IconTooltip, Loadable, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CmpConfigVendorList} from "../../../api/model/cmp/config/CmpConfigVendorList";
import {PurposeField} from "../../../api/model/cmp/list/global-vendor-list/PurposeField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {CmpPurpose} from "../../../utils/cmp/CmpPurpose";
import {CmpPurposeType} from "../../../utils/cmp/CmpPurposeType";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {Tag} from "../index";

type CmpConfigurationPurposesProps = {
    partnerId: number;
    vendorList: CmpConfigVendorList;
    onChangeVendorList: (vendorList: CmpConfigVendorList) => void;
};

const CmpConfigurationPurposes: FunctionComponent<CmpConfigurationPurposesProps> = ({partnerId, vendorList, onChangeVendorList}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [purposes, setPurposes] = useState<CmpPurpose[]>([]);

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const globalVendorList = await session.restCmpList.getGlobalVendorList();
                const premiumPartners = await session.restPartner.getCmpPremiums();
                const isPremium = premiumPartners.includes(partnerId);

                const selectedVendorIds = [...vendorList.vendors || []];
                if (!isPremium) {
                    const freemiumList = await session.restCmpList.getFreemiumList();
                    selectedVendorIds.push(...freemiumList.vendors);
                }

                const purposes = globalVendorList.purposes.map((it) => CmpPurpose.create(it, CmpPurposeType.PURPOSE));
                const specialFeatures = globalVendorList.specialFeatures.map((it) => CmpPurpose.create(it, CmpPurposeType.SPECIAL_FEATURE));
                setPurposes([...purposes, ...specialFeatures]);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("purposes", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [partnerId, vendorList.vendors, alert]);

    const isPurposeDisabled = (purpose: CmpPurpose): boolean => {
        return (purpose.type === CmpPurposeType.PURPOSE && !!vendorList.disabledPurposes?.includes(purpose.id)) ||
                (purpose.type === CmpPurposeType.SPECIAL_FEATURE && !!vendorList.disabledSpecialFeatures?.includes(purpose.id));
    };

    const handleTogglePurpose = (purposeId: number) => {
        let newDisabledPurposes = vendorList.disabledPurposes ? [...vendorList.disabledPurposes] : [];
        if (newDisabledPurposes.includes(purposeId)) {
            newDisabledPurposes.splice(newDisabledPurposes.findIndex((it) => it === purposeId), 1);
        } else {
            newDisabledPurposes.push(purposeId);
        }

        const newVendorList = new CmpConfigVendorList(vendorList);
        newVendorList.disabledPurposes = newDisabledPurposes.length ? newDisabledPurposes : undefined;
        onChangeVendorList(newVendorList);
    };

    const handleToggleSpecialFeature = (specialFeatureId: number) => {
        const newDisabledSpecialFeatures = vendorList.disabledSpecialFeatures ? [...vendorList.disabledSpecialFeatures] : [];
        if (newDisabledSpecialFeatures.includes(specialFeatureId)) {
            newDisabledSpecialFeatures.splice(newDisabledSpecialFeatures.findIndex((it) => it === specialFeatureId), 1);
        } else {
            newDisabledSpecialFeatures.push(specialFeatureId);
        }

        const newVendorList = new CmpConfigVendorList(vendorList);
        newVendorList.disabledSpecialFeatures = newDisabledSpecialFeatures.length ? newDisabledSpecialFeatures : undefined;
        onChangeVendorList(newVendorList);
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock header={{title: {label: textCmpConfigurations("section.partner_purposes")}}}>
                <Table
                    columns={[
                        {width: 20, label: textCmpConfigurations(`purposes.${PurposeField.ID}`)},
                        {width: 75, label: textCmpConfigurations(`purposes.${PurposeField.NAME}`)},
                        {width: 5}
                    ]}
                >
                    {purposes.map((item) =>
                        <TableRow key={`${item.type}-${item.id}`}>
                            <TableColumn>
                                <Tag label={`${textCmpConfigurations(`purposes.${item.type}`)} ${item.id}`} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                            </TableColumn>
                            <TableColumn>
                                <span>{item.name}</span>
                                {isPurposeDisabled(item) &&
                                <IconTooltip icon={{name: "block", colorIcon: Color.RED}} text={textCmpConfigurations("purposes.disabled")}/>
                                }
                            </TableColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                {item.type === CmpPurposeType.PURPOSE &&
                                <ActionsMenu
                                    iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                    items={[{
                                        label: isPurposeDisabled(item) ? textCmpConfigurations("purposes.action.allow_purpose") : textCmpConfigurations("purposes.action.block_purpose"),
                                        critical: !isPurposeDisabled(item),
                                        onClick: () => handleTogglePurpose(item.id)
                                    }]}
                                />
                                }
                                {item.type === CmpPurposeType.SPECIAL_FEATURE &&
                                <ActionsMenu
                                    iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                    items={[
                                        {
                                            label: isPurposeDisabled(item) ? textCmpConfigurations("purposes.action.allow_feature") : textCmpConfigurations("purposes.action.block_feature"),
                                            critical: !isPurposeDisabled(item),
                                            onClick: () => handleToggleSpecialFeature(item.id)
                                        }
                                    ]}
                                />
                                }
                            </TableActionColumn>
                        </TableRow>
                    )}
                </Table>
            </ContentBlock>
        </Loadable>
    );
};

export default CmpConfigurationPurposes;
