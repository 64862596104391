import {Action, Box, BoxProps, ContentBlock, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormLayoutTitle, FormValidationType, InputText, LayoutRows, Loadable} from "@sirdata/ui-lib";
import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {ApiAuthorization} from "../../api/model/auth/ApiAuthorization";
import {ApiProfile} from "../../api/model/auth/ApiProfile";
import {ApiProfileField} from "../../api/model/auth/ApiProfileField";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {sortItems} from "../../common/utils/helper";
import {detectChanges} from "../../common/utils/portal";
import ModalAuthDetails from "../../component/modal/authorizations/ModalAuthDetails";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {MainContentHeader, MainContentHeaderAction, UserProfileAuthorizations} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {AuthorizationType} from "../authorizations/AuthorizationsPartner";

function UserProfilesDetails() {
    const {t: textAuthorizations} = useTranslation(TranslationPortalFile.AUTHORIZATIONS);
    const {id} = useParams() as {id: string};
    const navigate = useNavigate();
    const alert = useAlert();

    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isShowModalDelete, setShowModalDelete] = useState(false);
    const [isShowModalDetails, setShowModalDetails] = useState(false);

    const FORM_ID = "form-edit-user-profile";
    const [profile, setProfile] = useState<ApiProfile>(new ApiProfile());
    const [initProfile, setInitProfile] = useState<ApiProfile>(new ApiProfile());
    const [authorizations, setAuthorizations] = useState<ApiAuthorization[]>([]);

    useEffect(() => {
        (async function () {
            try {
                const profile = await session.restUserProfile.get(+id);
                setProfile(profile);
                setInitProfile(new ApiProfile(profile));

                let userAuthorizations = await session.restUserAuthorization.list();
                userAuthorizations = sortItems(userAuthorizations, ApiProfileField.NAME);
                setAuthorizations(userAuthorizations.filter((authz) => authz.name !== `${Authorization.ADMIN.name}`));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("profile", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(profile, initProfile));
    }, [profile, initProfile]);

    const handleChangeAuthorization = (authorization: string) => {
        let newAuthorizations: string[] = [];
        if (profile.authorization_set.includes(authorization)) {
            newAuthorizations = profile.authorization_set.filter((item) => item !== authorization);
        } else {
            newAuthorizations = [...profile.authorization_set, authorization];
        }

        const newProfile = new ApiProfile(profile);
        newProfile.authorization_set = newAuthorizations;
        setProfile(newProfile);
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            profile.authorization_set = profile.authorization_set.filter((it) => authorizations.some((authz) => authz.name === it));

            await session.restUserProfile.update(profile);
            let newProfile = await session.restUserProfile.addAuthorizations(profile.id, profile.authorization_set);

            const removedAuthorizations = initProfile.authorization_set.filter((authz) => !profile.authorization_set.includes(authz));
            for (let i = 0; i < removedAuthorizations.length; i++) {
                newProfile = await session.restUserProfile.removeAuthorization(profile.id, removedAuthorizations[i]);
            }

            setProfile(newProfile);
            setInitProfile(new ApiProfile(newProfile));
            alert.updateWithSuccess("profile");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("profile", e.message);
            }
        }
    };

    const doDeleteUserProfile = async () => {
        try {
            await session.restUserProfile.delete(profile.id);
            navigate(Module.USER_PROFILES.path);
            alert.deleteWithSuccess("profile");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("profile", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.USER_PROFILES} element={initProfile.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <MainContentHeaderAction action={new Action(textAuthorizations("actions.see_users_with_profile"), {name: "group"})} onClick={() => setShowModalDetails(true)}/>
                <RestrictedContent allowedTo={Authorization.USERS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setShowModalDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <Loadable loading={isLoading}>
                            <ContentBlock>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <FormLayoutColumns>
                                            <FieldBlock label={textAuthorizations(`field.${ApiProfileField.NAME}`)} required>
                                                <InputText
                                                    value={profile.name}
                                                    onChange={(value) => setProfile((prevState) => new ApiProfile({...prevState, [ApiProfileField.NAME]: value}))}
                                                />
                                            </FieldBlock>
                                            <FieldBlock label={textAuthorizations(`field.${ApiProfileField.DESCRIPTION}`)}>
                                                <InputText
                                                    value={profile.description}
                                                    onChange={(value) => setProfile((prevState) => new ApiProfile({...prevState, [ApiProfileField.DESCRIPTION]: value}))}
                                                />
                                            </FieldBlock>
                                        </FormLayoutColumns>
                                        <FormLayoutTitle>{textAuthorizations(`field.${ApiProfileField.AUTHORIZATION_SET}`)}</FormLayoutTitle>
                                        <UserProfileAuthorizations
                                            authorizations={authorizations}
                                            selectedAuthorizations={profile.authorization_set}
                                            onChange={handleChangeAuthorization}
                                        />
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                    </LayoutRows>
                </Form>
                <ModalConfirmDelete
                    active={isShowModalDelete}
                    entity="profile"
                    confirm={doDeleteUserProfile}
                    cancel={() => setShowModalDelete(false)}
                />
                <ModalAuthDetails
                    active={isShowModalDetails}
                    authz={profile}
                    type={AuthorizationType.USER}
                    onClose={() => setShowModalDetails(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default UserProfilesDetails;
