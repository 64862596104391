import {Action, ActionsMenu, Color, Icon, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {CategoryGroup} from "../../../../api/model/audience/category/CategoryGroup";
import {CategorizerConfigEntry} from "../../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEnrichment} from "../../../../api/model/categorizer/config/CategorizerConfigEnrichment";
import {RestrictedContent} from "../../../../common/component/widget";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Tag} from "../../index";

type CategorizerConfigEnrichmentRowProps = {
    entry: CategorizerConfigEntry<CategorizerConfigEnrichment>;
    isEditable: boolean;
    onClick: () => void;
    onDelete: () => void;
    categoryGroups: CategoryGroup[];
};

const CategorizerConfigEnrichmentRow: FC<CategorizerConfigEnrichmentRowProps> = ({entry, isEditable, onClick, onDelete, categoryGroups}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);

    const getCategories = () => {
        const categories: CategoryGroup[] = [];
        entry.config.points.forEach((_, categoryGroupId) => {
            const categoryGroup = categoryGroups.find((it) => it.id === +categoryGroupId);
            if (categoryGroup) {
                categories.push(categoryGroup);
            }
        });
        return categories;
    };

    return (
        <TableRow onClick={onClick}>
            <TableColumn>{entry.domain || "-"}</TableColumn>
            <TableColumn>{entry.host || "-"}</TableColumn>
            <TableColumn>{entry.path || "-"}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <Tag label={textCategorizer(`enrichment_mode.${entry.config.mode}`)} style={TagStyle.PRIMARY_OCEAN}/>
            </TableColumn>
            <TableColumn>
                <ul>{getCategories().map((item) => <li key={item.id}>{item.name}</li>)}</ul>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <Icon name="circle" colorIcon={entry.config.propagate_points ? Color.GREEN : Color.RED}/>
            </TableColumn>
            <TableActionColumn>
                {isEditable &&
                    <RestrictedContent allowedTo={Authorization.CATEGORIZER.update}>
                        <ActionsMenu
                            iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                            items={[
                                {label: textCommon(Action.DELETE.labelKey), critical: true, separator: true, onClick: onDelete}
                            ]}
                        />
                    </RestrictedContent>
                }
            </TableActionColumn>
        </TableRow>
    );
};

export default CategorizerConfigEnrichmentRow;
