import {Rest} from "../../common/api/rest/Rest";
import {PartnerOrganization} from "../model/partner/organization/PartnerOrganization";
import {Partner} from "../model/partner/Partner";
import {PartnerAccount} from "../model/partner/PartnerAccount";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {PartnerOrganizationProperties} from "../model/partner/organization/PartnerOrganizationProperties";

export class RestPartnerOrganization extends Rest implements RestService<PartnerOrganization> {
    rootPath = "/console-api/partner/organization";

    search(params: any): Promise<SearchResult<PartnerOrganization>> {
        return new Promise<SearchResult<PartnerOrganization>>(() => {}); // TODO Implement route
    }

    list(): Promise<PartnerOrganization[]> {
        return this._client.get(`${this.rootPath}`, PartnerOrganization) as Promise<PartnerOrganization[]>;
    }

    create(organization: PartnerOrganization): Promise<PartnerOrganization> {
        return this._client.post(`${this.rootPath}`, organization, PartnerOrganization) as Promise<PartnerOrganization>;
    }

    get(organizationId: number): Promise<PartnerOrganization> {
        return this._client.get(`${this.rootPath}/${organizationId}`, PartnerOrganization) as Promise<PartnerOrganization>;
    }

    update(organization: PartnerOrganization): Promise<PartnerOrganization> {
        return this._client.put(`${this.rootPath}/${organization.id}`, organization, PartnerOrganization) as Promise<PartnerOrganization>;
    }

    delete(organizationId: number) {
        return this._client.delete(`${this.rootPath}/${organizationId}`);
    }

    updateProperties(organizationId: number, properties: PartnerOrganizationProperties): Promise<PartnerOrganization> {
        return this._client.patch(`${this.rootPath}/${organizationId}/properties`, properties, PartnerOrganization) as Promise<PartnerOrganization>;
    }

    getPartners(organization: PartnerOrganization): Promise<Partner[]> {
        return this._client.get(`${this.rootPath}/${organization.id}/partner`, Partner) as Promise<Partner[]>;
    }

    getPartnerAccounts(organization: PartnerOrganization): Promise<PartnerAccount[]> {
        return this._client.get(`${this.rootPath}/${organization.id}/partner-account`, PartnerAccount) as Promise<PartnerAccount[]>;
    }

    addPartner(organizationId: number, partnerId: number): Promise<PartnerOrganization> {
        return this._client.post(`${this.rootPath}/${organizationId}/partner/${partnerId}`) as Promise<PartnerOrganization>;
    }

    removePartner(organization: PartnerOrganization, partnerId: number) {
        return this._client.delete(`${this.rootPath}/${organization.id}/partner/${partnerId}`);
    }
}
