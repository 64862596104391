import React, {FunctionComponent} from "react";
import {IconProps, Tag as LibTag, TagSize, TagStyle} from "@sirdata/ui-lib";

type TagProps = {
    label: string;
    icon?: IconProps;
    style?: TagStyle;
    customColor?: {
        backgroundColor?: string;
        color?: string;
        borderColor?: string;
    };
    cssClass?: string;
};

const Tag: FunctionComponent<TagProps> = ({label, icon, style, customColor, cssClass}) => {
    return (
        <LibTag
            size={TagSize.XSMALL}
            style={style}
            cssClass={cssClass}
            label={label}
            icon={icon}
            customColor={{
                backgroundColor: customColor?.backgroundColor,
                color: customColor?.color,
                borderColor: customColor?.borderColor || customColor?.backgroundColor
            }}
        />
    );
};

export default Tag;
