import {Origin} from "../common/api/model/Origin";
import {PortalConfig} from "../common/api/PortalConfig";
import {pathHome} from "../common/utils/constants";
import {UIEventManager} from "../common/utils/UIEventManager";
import {ModalTicketingUiEvent} from "../component/modal/ModalTicketing";
import {MODULES_GROUPS} from "../component/snippet/Modules";

export const PORTAL = new PortalConfig(Origin.CONSOLE, pathHome)
    .withMenuItems(MODULES_GROUPS.map((group) => ({
        label: `groups.${group.label}`,
        icon: {name: "circle"},
        path: group.label,
        items: group.modules.map((it) => it.toMenuItem())
    })))
    .withShortcuts([{label: "menu.ticketing", target: () => UIEventManager.emit(ModalTicketingUiEvent), icon: {name: "support_agent"}}]);
