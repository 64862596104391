export enum SegmentLinkCategoryField {
    SEGMENT_ID = "segment_id",
    CATEGORY_ID = "category_id",
    CATEGORY_ID_GROUP = "category_id_group",
    CATEGORY_CODE_NAME = "category_code_name",
    CATEGORY_NAME = "category_name",
    CATEGORY_TYPE = "category_type",
    TAXONOMY = "taxonomy",
    VOLUME = "volume",
    MIN_SCORE = "min_score",
    AUTO_MIN_SCORE = "auto_min_score",
    POPULATION = "population",
    HAS_KEYWORDS = "has_keywords",
    HAS_BQ_REQUEST = "has_bq_request",
    HAS_BQ_REQUEST_ACTIVE = "has_bq_request_active",
    EXPIRATION_DAY = "expiration_day",
    PERCENTILE = "percentile"
}
