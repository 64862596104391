import {Accordion, AccordionItem, AccordionItemContent, FieldBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentLayout, FlexContentMobileDirection, FlexContentSpacing, FormLayoutColumns, FormLayoutRows, Select, TagStyle, ToggleSwitch} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettings} from "../../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CMP_CONFIG_THEME_BORDER_RADIUS, CmpConfigThemeBorderRadius} from "../../../api/model/cmp/config/theme/CmpConfigThemeBorderRadius";
import {CmpConfigThemeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeField";
import {CMP_CONFIG_THEME_FONT_FAMILIES, CmpConfigThemeFontFamily} from "../../../api/model/cmp/config/theme/CmpConfigThemeFontFamily";
import {CmpConfigThemeModeField} from "../../../api/model/cmp/config/theme/CmpConfigThemeModeField";
import {CMP_CONFIG_THEME_TEXT_SIZES, CmpConfigThemeTextSize} from "../../../api/model/cmp/config/theme/CmpConfigThemeTextSize";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigurationPreview, CmpConfigurationThemeColors, CmpConfigurationThemePosition, Tag} from "../index";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigTheme} from "../../../api/model/cmp/config/theme/CmpConfigTheme";
import {CmpConfigThemeMode} from "../../../api/model/cmp/config/theme/CmpConfigThemeMode";

type CmpConfigurationSettingsAppearanceProps = {
    cmpConfig: CmpConfig;
    settings: CmpConfigSettings;
    onChange: (settings: CmpConfigSettings) => void;
};

const CmpConfigurationSettingsAppearance: FunctionComponent<CmpConfigurationSettingsAppearanceProps> = ({cmpConfig, settings, onChange}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    const handleChangeSettings = (field: CmpConfigSettingsField, value: any) => {
        onChange(new CmpConfigSettings({...settings, [field]: value}));
    };

    const handleChangeSettingsTheme = (field: CmpConfigThemeField, value: any) => {
        handleChangeSettings(CmpConfigSettingsField.THEME, new CmpConfigTheme({...settings.theme, [field]: value}));
    };

    const handleChangeSettingsThemeMode = (mode: CmpConfigThemeField.LIGHT_MODE | CmpConfigThemeField.DARK_MODE, field: CmpConfigThemeModeField, value: string) => {
        handleChangeSettingsTheme(mode, new CmpConfigThemeMode({...settings.theme[mode], [field]: value.trim()}));
    };

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            alignment={FlexContentAlignment.START}
            spacing={FlexContentSpacing.LARGE_PLUS}
            layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}
            mobileDirection={FlexContentMobileDirection.COLUMN}
        >
            <Accordion>
                <AccordionItem heading={<Tag label={textCmpConfigurations("section.layout")} icon={{name: "devices", outlined: true}} style={TagStyle.DEFAULT_OCEAN}/>} startOpen>
                    <AccordionItemContent>
                        <FormLayoutRows>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.POSITION}.label`)}>
                                <CmpConfigurationThemePosition
                                    value={settings.theme.position}
                                    onChange={(value) => handleChangeSettingsTheme(CmpConfigThemeField.POSITION, value)}
                                />
                            </FieldBlock>
                            <FormLayoutColumns>
                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TEXT_SIZE}.label`)}>
                                    <Select
                                        value={settings.theme.textSize}
                                        options={CMP_CONFIG_THEME_TEXT_SIZES.map((it) => ({
                                            value: it,
                                            label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.TEXT_SIZE}.${it}`)
                                        }))}
                                        onChange={(option) => handleChangeSettingsTheme(CmpConfigThemeField.TEXT_SIZE, option?.value as CmpConfigThemeTextSize)}
                                    />
                                </FieldBlock>
                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.BORDER_RADIUS}.label`)}>
                                    <Select
                                        value={settings.theme.borderRadius}
                                        options={CMP_CONFIG_THEME_BORDER_RADIUS.map((it) => ({
                                            value: it,
                                            label: textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.BORDER_RADIUS}.${it}`)
                                        }))}
                                        onChange={(option) => handleChangeSettingsTheme(CmpConfigThemeField.BORDER_RADIUS, option?.value as CmpConfigThemeBorderRadius)}
                                    />
                                </FieldBlock>
                            </FormLayoutColumns>
                        </FormLayoutRows>
                    </AccordionItemContent>
                </AccordionItem>
                <AccordionItem heading={<Tag label={textCmpConfigurations("section.colors")} icon={{name: "palette", outlined: true}} style={TagStyle.DEFAULT_OCEAN}/>}>
                    <AccordionItemContent>
                        <FormLayoutRows>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.MAIN_COLOR}`)}>
                                <CmpConfigurationThemeColors
                                    theme={settings.theme}
                                    field={CmpConfigThemeModeField.MAIN_COLOR}
                                    onChange={handleChangeSettingsThemeMode}
                                />
                            </FieldBlock>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.BACKGROUND_COLOR}`)}>
                                <CmpConfigurationThemeColors
                                    theme={settings.theme}
                                    field={CmpConfigThemeModeField.BACKGROUND_COLOR}
                                    onChange={handleChangeSettingsThemeMode}
                                />
                            </FieldBlock>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.BORDER_COLOR}`)}>
                                <CmpConfigurationThemeColors
                                    theme={settings.theme}
                                    field={CmpConfigThemeModeField.BORDER_COLOR}
                                    onChange={handleChangeSettingsThemeMode}
                                />
                            </FieldBlock>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.TITLE_COLOR}`)}>
                                <CmpConfigurationThemeColors
                                    theme={settings.theme}
                                    field={CmpConfigThemeModeField.TITLE_COLOR}
                                    onChange={handleChangeSettingsThemeMode}
                                />
                            </FieldBlock>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.TEXT_COLOR}`)}>
                                <CmpConfigurationThemeColors
                                    theme={settings.theme}
                                    field={CmpConfigThemeModeField.TEXT_COLOR}
                                    onChange={handleChangeSettingsThemeMode}
                                />
                            </FieldBlock>
                        </FormLayoutRows>
                    </AccordionItemContent>
                </AccordionItem>
                <AccordionItem heading={<Tag label={textCmpConfigurations("section.fonts")} icon={{name: "font_download", outlined: true}} style={TagStyle.DEFAULT_OCEAN}/>}>
                    <AccordionItemContent>
                        <FormLayoutColumns>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.FONT_FAMILY_TITLE}`)}>
                                <Select
                                    value={settings.theme.fontFamilyTitle}
                                    options={CMP_CONFIG_THEME_FONT_FAMILIES.map((it) => ({value: it, label: it}))}
                                    onChange={(option) => handleChangeSettingsTheme(CmpConfigThemeField.FONT_FAMILY_TITLE, option?.value as CmpConfigThemeFontFamily)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.FONT_FAMILY}`)}>
                                <Select
                                    value={settings.theme.fontFamily}
                                    options={CMP_CONFIG_THEME_FONT_FAMILIES.map((it) => ({value: it, label: it}))}
                                    onChange={(option) => handleChangeSettingsTheme(CmpConfigThemeField.FONT_FAMILY, option?.value as CmpConfigThemeFontFamily)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </AccordionItemContent>
                </AccordionItem>
                <AccordionItem heading={<Tag label={textCmpConfigurations("section.overlay")} icon={{name: "blur_on"}} style={TagStyle.DEFAULT_OCEAN}/>}>
                    <AccordionItemContent>
                        <FormLayoutRows>
                            <ToggleSwitch
                                name={`${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.OVERLAY}`}
                                label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeField.OVERLAY}`)}
                                checked={settings.theme.overlay}
                                onChange={(value) => handleChangeSettingsTheme(CmpConfigThemeField.OVERLAY, value)}
                            />
                            {settings.theme.overlay &&
                                <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.THEME}.${CmpConfigThemeModeField.OVERLAY_COLOR}`)}>
                                    <CmpConfigurationThemeColors
                                        theme={settings.theme}
                                        field={CmpConfigThemeModeField.OVERLAY_COLOR}
                                        onChange={handleChangeSettingsThemeMode}
                                    />
                                </FieldBlock>
                            }
                        </FormLayoutRows>
                    </AccordionItemContent>
                </AccordionItem>
            </Accordion>
            <CmpConfigurationPreview cmpConfig={cmpConfig}/>
        </FlexContent>
    );
};

export default CmpConfigurationSettingsAppearance;
