import {CmpLocaleConfigItem} from "./CmpLocaleConfigItem";

export class CmpLocaleItem {
    name: string = "";
    value: string = "";

    constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }

    isInvalid(config: CmpLocaleConfigItem): boolean {
        if (!this.value) {
            return true;
        }

        return config.tags?.some((it) => {
            if (it.has_body) {
                const tagWithBody = new RegExp(`<${it.name}>.+</${it.name}>`, "g");
                return !this.value.match(tagWithBody);
            }

            const tag = new RegExp(`<${it.name}/>`, "g");
            return !this.value.match(tag);
        });
    }
}