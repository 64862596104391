import {FC} from "react";
import {useTranslation} from "react-i18next";
import {ContentBlock, Table, TableColumn, TableRow} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersion";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list/SirdataListLanguage";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersionField";
import {SirdataListPurposeField} from "../../../api/model/cmp/list/sirdata-list/SirdataListPurposeField";
import {CmpSirdataListEntityState} from "../index";

type CmpSirdataListPurposesNewVersionProps = {
    latestVersion: SirdataListVersion;
    listLanguage: SirdataListLanguage;
};

const CmpSirdataListPurposesNewVersion: FC<CmpSirdataListPurposesNewVersionProps> = ({latestVersion, listLanguage}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const filteredPurposes = listLanguage.purposes.filter((purpose) => !!purpose.getState(latestVersion));

    return (
        <ContentBlock header={{title: {label: textCmpSirdataList(`field.${SirdataListVersionField.PURPOSES}`)}}}>
            <Table
                columns={[
                    {width: 5},
                    {width: 5, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.ID}`)},
                    {width: 90, label: textCmpSirdataList(`field.purpose.${SirdataListPurposeField.NAME}`)}
                ]}
                messageIfEmpty={textCmpSirdataList("message.no_purposes")}
            >
                {filteredPurposes.map((purpose) =>
                    <TableRow key={purpose.id}>
                        <TableColumn>
                            <CmpSirdataListEntityState state={purpose.getState(latestVersion)}/>
                        </TableColumn>
                        <TableColumn>{purpose.id}</TableColumn>
                        <TableColumn>{purpose.name}</TableColumn>
                    </TableRow>
                )}
            </Table>
        </ContentBlock>
    );
};

export default CmpSirdataListPurposesNewVersion;
