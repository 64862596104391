export class CustomerCountingSortField {
    static CREATION_TS = new CustomerCountingSortField("creation_ts");
    static ID = new CustomerCountingSortField("id");
    static NAME = new CustomerCountingSortField("name");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CustomerCountingSortField[] {
        return [
            CustomerCountingSortField.CREATION_TS,
            CustomerCountingSortField.ID,
            CustomerCountingSortField.NAME
        ];
    }

    static getByName(name?: string): CustomerCountingSortField {
        return this.values().find((it) => it.name === name) || CustomerCountingSortField.CREATION_TS;
    }
}
