import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CmpCloudflareRequest extends ApiModel {
    urls: string[] = [];
    global_purge: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            urls: this.urls,
            global_purge: this.global_purge
        };
    }
}
