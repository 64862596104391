import {FC} from "react";
import {Code, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing} from "@sirdata/ui-lib";
import {sortNumbers} from "../../../common/utils/helper";

type CmpSirdataListPurposesListProps = {
    title?: string;
    values: number[];
};

const CmpSirdataListPurposesList: FC<CmpSirdataListPurposesListProps> = ({title, values}) => {
    if (!values.length) return <></>;

    return (
        <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER} allowWrap>
            {title && <span>{title} :</span>}
            {sortNumbers(Array.from(new Set(values).keys())).map((id) =>
                <Code key={id} value={id}/>
            )}
        </FlexContent>
    );
};

export default CmpSirdataListPurposesList;
