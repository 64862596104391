import {Box, BoxRadius, BoxSpacing, InputText, InputUrl, ToggleSwitch} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigExternalField} from "../../../api/model/cmp/config/CmpConfigExternalField";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigExternal} from "../../../api/model/cmp/config/CmpConfigExternal";
import {CmpConfigExternalUtiqField} from "../../../api/model/cmp/config/CmpConfigExternalUtiqField";
import {CmpConfigExternalUtiq} from "../../../api/model/cmp/config/CmpConfigExternalUtiq";

type CmpConfigurationExternalProps = {
    external: CmpConfigExternal;
    onChangeExternal: (external: CmpConfigExternal) => void;
};

type CmpConfigurationExternalItem = {
    field: CmpConfigExternalField;
    title: string;
    logo: string;
}

const EXTERNAL_ITEMS: CmpConfigurationExternalItem[] = [
    {field: CmpConfigExternalField.GOOGLE_ANALYTICS, title: "Google<br/>Consent Mode", logo: "/images/logos/google-consent-mode.png"},
    {field: CmpConfigExternalField.GOOGLE_AD_SENSE, title: "Google<br/>AdSense", logo: "/images/logos/google-ad-sense.png"},
    {field: CmpConfigExternalField.GOOGLE_AD_MANAGER, title: "Google<br/>Ad Manager", logo: "/images/logos/google-ad-manager.png"},
    {field: CmpConfigExternalField.GTM_DATA_LAYER, title: "Google<br/>Tag Manager", logo: "/images/logos/google-tag-manager.png"},
    {field: CmpConfigExternalField.SHOPIFY, title: "Shopify<br/>Customer Privacy", logo: "/images/logos/shopify.png"},
    {field: CmpConfigExternalField.MICROSOFT_UET, title: "Microsoft UET<br/>Consent Mode", logo: "/images/logos/microsoft.png"},
    {field: CmpConfigExternalField.CONSENT_MODE_FROM_TCF, title: "TCF/Google<br/>Consent Mode", logo: "/images/logos/google-ads.png"},
    {field: CmpConfigExternalField.UTIQ, title: "Utiq", logo: "/images/logos/utiq.png"}
];

const CmpConfigurationExternal: FunctionComponent<CmpConfigurationExternalProps> = ({external, onChangeExternal}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    const handleChange = (field: CmpConfigExternalField, value: any) => {
        onChangeExternal(new CmpConfigExternal({...external, [field]: value}));
    };

    const handleChangeUtiq = (field: CmpConfigExternalUtiqField, value: any) => {
        handleChange(CmpConfigExternalField.UTIQ, new CmpConfigExternalUtiq({...external.utiq, [field]: value}));
    };

    const buildItemFields = (item: CmpConfigurationExternalItem) => {
        switch (item.field) {
            case CmpConfigExternalField.GTM_DATA_LAYER:
                return (
                    <InputText
                        placeholder={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.EXTERNAL}.${CmpConfigExternalField.GTM_DATA_LAYER}`)}
                        value={external.gtmDataLayer}
                        onChange={(value) => handleChange(CmpConfigExternalField.GTM_DATA_LAYER, value)}
                    />
                );
            case CmpConfigExternalField.UTIQ:
                return (
                    <>
                        <ToggleSwitch
                            name={`${CmpConfigExternalField.UTIQ}.${CmpConfigExternalUtiqField.ACTIVE}`}
                            label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.EXTERNAL}.${CmpConfigExternalField.UTIQ}.${CmpConfigExternalUtiqField.ACTIVE}`)}
                            checked={external.utiq.active}
                            onChange={(value) => handleChangeUtiq(CmpConfigExternalUtiqField.ACTIVE, value)}
                        />
                        <InputText
                            value={external.utiq.controller}
                            placeholder={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.EXTERNAL}.${CmpConfigExternalField.UTIQ}.${CmpConfigExternalUtiqField.CONTROLLER}`)}
                            onChange={(value) => handleChangeUtiq(CmpConfigExternalUtiqField.CONTROLLER, value)}
                            disabled={!external.utiq.active}
                        />
                        <InputUrl
                            value={external.utiq.noticeUrl}
                            placeholder={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.EXTERNAL}.${CmpConfigExternalField.UTIQ}.${CmpConfigExternalUtiqField.NOTICE_URL}`)}
                            onChange={(value) => handleChangeUtiq(CmpConfigExternalUtiqField.NOTICE_URL, value)}
                            disabled={!external.utiq.active}
                        />
                    </>
                );
            default:
                return (
                    <ToggleSwitch
                        name={item.field}
                        label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.EXTERNAL}.${item.field}`)}
                        checked={Boolean(external[item.field])}
                        onChange={(value) => handleChange(item.field, value)}
                    />
                );
        }
    };

    return (
        <>
            {EXTERNAL_ITEMS.map((item) => (
                <Box
                    key={item.field}
                    radius={BoxRadius.MD}
                    spacing={BoxSpacing.MEDIUM}
                    cssClass="cmp-add-ons__item"
                >
                    <div className="cmp-add-ons__item__header">
                        <span className="h3" dangerouslySetInnerHTML={{__html: item.title}}/>
                        <div className="cmp-add-ons__item__image-container">
                            <img src={item.logo} alt={""}/>
                        </div>
                    </div>
                    <div className="cmp-add-ons__item__fields">
                        {buildItemFields(item)}
                    </div>
                </Box>
            ))}
        </>
    );
};

export default CmpConfigurationExternal;
