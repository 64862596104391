import {TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {LabelStatus} from "../../index";
import {CmpWebinar} from "../../../../api/model/cmp/webinar/CmpWebinar";
import {Formatter} from "../../../../common/utils/Formatter";
import {Locale} from "../../../../common/utils/Locale";

type CmpWebinarRowProps = {
    item: CmpWebinar;
}

const CmpWebinarRow: FunctionComponent<CmpWebinarRowProps> = ({item}) => {
    return (
        <TableRow onClick={{link: item.getRoute()}}>
            <TableColumn>
                <LabelStatus status={item.getStatus()}/>
            </TableColumn>
            <TableColumn styles={[TableColumnStyle.ALIGN_CENTER]}>
                {item.id}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {Formatter.formatDate(item.date)}
            </TableColumn>
            <TableColumn>
                {item.info?.get(Locale.getCurrentLocale())?.title}
            </TableColumn>
        </TableRow>
    );
};

export default CmpWebinarRow;
