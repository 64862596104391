export class CustomerCountingSegmentFilterType {
    static NONE: CustomerCountingSegmentFilterType = new CustomerCountingSegmentFilterType("NONE");
    static SEGMENT: CustomerCountingSegmentFilterType = new CustomerCountingSegmentFilterType("SEGMENT");
    static SEGMENT_TYPE: CustomerCountingSegmentFilterType = new CustomerCountingSegmentFilterType("SEGMENT_TYPE");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CustomerCountingSegmentFilterType[] {
        return [
            CustomerCountingSegmentFilterType.NONE,
            CustomerCountingSegmentFilterType.SEGMENT,
            CustomerCountingSegmentFilterType.SEGMENT_TYPE
        ];
    }

    static getByName(name: string): CustomerCountingSegmentFilterType | undefined {
        return this.values().find((it) => it.name === name);
    }
}
