import {Action, ActionsMenu, Color, IconTooltip, InputText, TableActionColumn, TableColumn, TableColumnStyle, TableImage, TableRow, ToggleSwitch, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {CategoryLinkExternal} from "../../../../api/model/audience/category/CategoryLinkExternal";
import {CategoryLinkExternalField} from "../../../../api/model/audience/category/CategoryLinkExternalField";
import {RestrictedContent} from "../../../../common/component/widget";
import {LabelStatus} from "../../index";
import {AudiencePlatform} from "../../../../api/model/audience/AudiencePlatform";

type CategoryLinkExternalRowProps = {
    link: CategoryLinkExternal;
    onChangeStatus: (link: CategoryLinkExternal) => void;
    onChangeValue: (link: CategoryLinkExternal) => void;
    onRemove: () => void;
}

const CategoryLinkExternalRow: FunctionComponent<CategoryLinkExternalRowProps> = ({link, onChangeStatus, onChangeValue, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    const buildPlatform = () => {
        const platform = AudiencePlatform.getByName(link.map_name);
        if (platform) {
            return (
                <>
                    <TableImage image={platform.logo}/>
                    {platform.tooltip && <IconTooltip icon={{name: "help", outlined: true, colorIcon: Color.OCEAN}} text={platform.tooltip}/>}
                </>
            );
        }
        return (<span>{link.map_name}</span>);
    };

    return (
        <TableRow>
            <TableColumn>
                <LabelStatus status={link.getStatus()}/>
            </TableColumn>
            <TableColumn>
                {buildPlatform()}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.NO_VERTICAL_PADDING}>
                <InputText
                    value={link.value}
                    onChange={(value) => onChangeValue(new CategoryLinkExternal({...link, [CategoryLinkExternalField.VALUE]: value}))}
                    small
                />
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <ToggleSwitch
                    checked={link.active}
                    onChange={(value) => onChangeStatus(new CategoryLinkExternal({...link, [CategoryLinkExternalField.ACTIVE]: value}))}
                />
                <RestrictedContent allowedTo={Authorization.CATEGORIES.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={[
                            {label: textCommon(Action.REMOVE.labelKey), onClick: onRemove, critical: true, separator: true}
                        ]}
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default CategoryLinkExternalRow;
