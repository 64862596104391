import {ApiModel} from "../../../../common/api/model/ApiModel";
import {RevenueImportStatus} from "./RevenueImportStatus";
import {Status} from "../../../../utils/Status";
import {RevenueImportDateType} from "./RevenueImportDateType";

export class RevenueImport extends ApiModel {
    uuid: string = "";
    user: string = "";
    name: string = "";
    storage_path: string = "";
    count_row_imported: number = 0;
    total_revenue: number = 0;
    status: RevenueImportStatus = RevenueImportStatus.CREATE;
    error_message: string = "";
    created_at: string = "";
    updated_at: string = "";
    platform_id: string = "";
    date_type: RevenueImportDateType = RevenueImportDateType.DAY;
    period_start: string = "";
    period_end: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getStatus(): Status {
        switch (this.status) {
            case RevenueImportStatus.SUCCESS:
                return Status.SUCCESS;
            case RevenueImportStatus.DELETED:
                return Status.DELETED;
            case RevenueImportStatus.ERROR:
                return Status.ERROR;
            default:
                return Status.BLOCKED;
        }
    }
}
