import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchQuery} from "../interface/SearchQuery";
import {SearchResult} from "../interface/SearchResult";
import {DataleaksVersion} from "../model/dataleaks/version/DataleaksVersion";
import {DataleaksVersionScoring} from "../model/dataleaks/version/DataleaksVersionScoring";

export class RestDataleaksVersion extends Rest implements RestService<DataleaksVersion> {
    rootPath = "/console-api/dataleaks/version";

    search(params: SearchQuery): Promise<SearchResult<DataleaksVersion>> {
        return new Promise<SearchResult<DataleaksVersion>>(() => {});
    };

    list(): Promise<DataleaksVersion[]> {
        return this._client.get(`${this.rootPath}`, DataleaksVersion) as Promise<DataleaksVersion[]>;
    };

    create(version: DataleaksVersion): Promise<DataleaksVersion> {
        return this._client.post(`${this.rootPath}`, version, DataleaksVersion) as Promise<DataleaksVersion>;
    }

    get(versionId: number): Promise<DataleaksVersion> {
        return this._client.get(`${this.rootPath}/${versionId}`, DataleaksVersion) as Promise<DataleaksVersion>;
    }

    getVersionScoring(versionId: number): Promise<DataleaksVersionScoring> {
        return this._client.get(`${this.rootPath}/${versionId}/scoring`, DataleaksVersionScoring) as Promise<DataleaksVersionScoring>;
    }

    update(version: DataleaksVersion): Promise<DataleaksVersion> {
        return new Promise<DataleaksVersion>(() => {});
    }

    delete(versionId: number) {
        return this._client.delete(`${this.rootPath}/${versionId}`);
    }
}
