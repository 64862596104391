import {FC} from "react";
import {useTranslation} from "react-i18next";
import {ContentBlock, Table, TableColumn, TableRow} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersion";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list/SirdataListLanguage";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersionField";
import {SirdataListStackField} from "../../../api/model/cmp/list/sirdata-list/SirdataListStackField";
import {CmpSirdataListEntityState} from "../index";

type CmpSirdataListStacksNewVersionProps = {
    latestVersion: SirdataListVersion;
    listLanguage: SirdataListLanguage;
};

const CmpSirdataListStacksNewVersion: FC<CmpSirdataListStacksNewVersionProps> = ({latestVersion, listLanguage}) => {
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const filteredStacks = listLanguage.stacks.filter((stack) => !!stack.getState(latestVersion));

    return (
        <ContentBlock header={{title: {label: textCmpSirdataList(`field.${SirdataListVersionField.STACKS}`)}}}>
            <Table
                columns={[
                    {width: 5},
                    {width: 5, label: textCmpSirdataList(`field.stack.${SirdataListStackField.ID}`)},
                    {width: 90, label: textCmpSirdataList(`field.stack.${SirdataListStackField.NAME}`)}
                ]}
                messageIfEmpty={textCmpSirdataList("message.no_stacks")}
            >
                {filteredStacks.map((stack) =>
                    <TableRow key={stack.id}>
                        <TableColumn>
                            <CmpSirdataListEntityState state={stack.getState(latestVersion)}/>
                        </TableColumn>
                        <TableColumn>{stack.id}</TableColumn>
                        <TableColumn>{stack.name}</TableColumn>
                    </TableRow>
                )}
            </Table>
        </ContentBlock>
    );
};

export default CmpSirdataListStacksNewVersion;
