import {ApiModel} from "../../../../common/api/model/ApiModel";
import {ContentElement} from "../../../interface/ContentElement";
import {Status} from "../../../../utils/Status";
import {PartnerOrganizationRequestStatus} from "./PartnerOrganizationRequestStatus";
import {PartnerAccount} from "../PartnerAccount";
import {User} from "../../user/User";

export class PartnerOrganizationRequest extends ApiModel {
    id: number = 0;
    status: string = PartnerOrganizationRequestStatus.REQUESTED.name;
    name: string = "";
    group_name: string = "";
    email: string = "";
    partner_account: PartnerAccount | undefined;
    user: User | undefined;
    created_at: string = "";
    updated_at: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            group_name: this.group_name
        };
    }

    getStatus(): Status {
        if (this.status === PartnerOrganizationRequestStatus.REJECTED.name) {
            return Status.REJECTED;
        } else if (this.status === PartnerOrganizationRequestStatus.VALIDATED.name) {
            return Status.APPROVED;
        } else {
            return Status.PENDING;
        }
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        element.status = this.getStatus();
        element.lastUpdated = new Date(this.updated_at);
        return element;
    }
}
