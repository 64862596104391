import {
    Action,
    ContentBlock,
    LayoutRows,
    Loadable,
    SearchError,
    SearchField,
    SearchToolbar,
    Table,
    TableColumnStyle
} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ApiService} from "../../api/model/ApiService";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {PortalContentField} from "../../api/model/portal-content/PortalContentField";
import {PORTAL_CONTENT_STATUSES} from "../../api/model/portal-content/PortalContentStatus";
import {PortalSetting} from "../../api/model/portal-setting/PortalSetting";
import {PortalSettingSearchQuery} from "../../api/model/portal-setting/PortalSettingSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {
    MainContentHeader,
    MainContentHeaderAction,
    PortalSettingsRow,
    SelectService,
    SelectStatus
} from "../../component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreatePortalSetting from "../../component/modal/portal-settings/ModalCreatePortalSetting";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {Status} from "../../utils/Status";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";

function PortalSettings() {
    const {t: textPortalSettings} = useTranslation(TranslationPortalFile.PORTAL_SETTINGS);
    const {t: textPortalContent} = useTranslation(TranslationPortalFile.PORTAL_CONTENT);
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreatePortalSettings, setShowModalCreatePortalSettings] = useState(false);
    const {setSearchResult, ...search} = useSearch(PortalSetting, PortalSettingSearchQuery);
    const alert = useAlert();

    useEffect(() => {
        (async function () {
            try {
                const newSearchResult = await session.restPortalContent.search(PortalSetting, search.searchQuery);
                setSearchResult(newSearchResult);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("portal settings", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [search.searchQuery, setSearchResult, alert]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.PORTAL_SETTINGS}>
                <RestrictedContent allowedTo={Authorization.PORTAL_SETTINGS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreatePortalSettings(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textPortalSettings("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters
                        >
                            <SearchField label={textPortalContent(`field.${PortalContentField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={PORTAL_CONTENT_STATUSES.filter((it) => it !== Status.DELETED)}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textPortalContent(`field.${PortalContentField.SERVICE}`)}>
                                <SelectService
                                    value={search.searchQuery.service}
                                    services={ApiService.portals()}
                                    onChange={(service) => search.changeParam(SearchParamsField.SERVICE, service?.name)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textPortalContent(`field.${PortalContentField.STATUS}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textPortalContent(`field.${PortalContentField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textPortalContent(`field.${PortalContentField.SERVICE}`)},
                                        {width: 75, label: textPortalContent(`field.${PortalContentField.TITLE}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((item) => (
                                        <PortalSettingsRow
                                            key={item.id}
                                            item={item}
                                        />
                                    ))}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreatePortalSetting
                    active={isShowModalCreatePortalSettings}
                    onClose={() => setShowModalCreatePortalSettings(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default PortalSettings;