import {FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentJustify, FlexContentSpacing, Icon, Paragraph} from "@sirdata/ui-lib";
import clsx from "clsx";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";
import {DataleaksQuestionTextsField} from "../../../api/model/dataleaks/question/DataleaksQuestionTextsField";
import {Locale} from "../../../common/utils/Locale";
import {TranslationPortalFile} from "../../../utils/constants";

type DataleaksAuditReportQuestionProps = {
    question: DataleaksQuestion;
    answer: boolean | undefined;
    locale: Locale;
}

const DataleaksAuditReportQuestion: FunctionComponent<DataleaksAuditReportQuestionProps> = ({question, answer, locale}) => {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS, {lng: locale.toString()});
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS, {lng: locale.toString()});

    const getText = (field: DataleaksQuestionTextsField): string => {
        const texts = question.texts.get(locale);
        if (!texts) return "";
        return texts![field];
    };

    return (
        <FlexContent
            direction={FlexContentDirection.COLUMN}
            spacing={FlexContentSpacing.XSMALL}
            cssClass={"dataleaks__report__question"}
        >
            <FlexContent
                direction={FlexContentDirection.ROW}
                alignment={FlexContentAlignment.START}
                justify={FlexContentJustify.SPACE_BETWEEN}
                spacing={FlexContentSpacing.LARGE}
            >
                <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.START} spacing={FlexContentSpacing.XSMALL}>
                    <span className="h3 dataleaks__report__question__title">
                        {getText(DataleaksQuestionTextsField.TITLE)} <span className="dataleaks__report__question__tag">({question.regulations.map((it) => textDataleaksQuestions(`regulation.${it}`)).join(", ")})</span>
                    </span>
                </FlexContent>
                {answer !== undefined && (
                    question.isValidAnswer(answer) ?
                        <div className={clsx("dataleaks__report__question__answer", "dataleaks__report__question__answer--success")}>
                            <span className="dataleaks__report__question__answer__label">{textDataleaksAudits(answer ? "answer.yes" : "answer.no")}</span>
                            <Icon name="task_alt" cssClass="dataleaks__report__question__answer__icon"/>
                        </div> :
                        <div className={clsx("dataleaks__report__question__answer", "dataleaks__report__question__answer--failure")}>
                            <span className="dataleaks__report__question__answer__label">{textDataleaksAudits(answer ? "answer.yes" : "answer.no")}</span>
                            <Icon name="report" cssClass="dataleaks__report__question__answer__icon"/>
                        </div>
                )}
            </FlexContent>
            <Paragraph cssClass="dataleaks__report__question__text">
                {getText(DataleaksQuestionTextsField.DESCRIPTION_LEGAL)}
            </Paragraph>
        </FlexContent>
    );
};

export default DataleaksAuditReportQuestion;
