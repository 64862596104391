import {
    Action,
    Button,
    ButtonLinkCancel,
    ButtonStyle,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    InputAutocomplete,
    InputText,
    Loadable,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {TagWithAction} from "../../snippet";

type ModalEditTagsProps = {
    initTags: string[];
    onSubmit: (tags: string[]) => void;
    onClose: () => void;
    active: boolean;
};

const ModalEditTags: FunctionComponent<ModalEditTagsProps> = ({initTags, onSubmit, onClose, active}) => {
    const alert = useAlert();
    const {t: textTags} = useTranslation(TranslationPortalFile.TAXONOMY_TAGS);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const [isLoading, setLoading] = useState(true);
    const [tags, setTags] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [tag, setTag] = useState<string>("");
    const FORM_ID = "form-edit-tags";

    useEffect(() => {
        setSelectedTags(initTags);
        setTag("");
        if (!active) return;
        (async () => {
            try {
                setTags(await session.restList.getTaxonomyTags());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("tags", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [active, initTags, alert]);

    const handleAddTag = (tag: string) => {
        setTag("");
        if (selectedTags.includes(tag)) {
            alert.failTo("add tag", textTags("message.tag_already_selected"));
            return;
        }
        setSelectedTags((prevState) => {
            const newState = [...prevState, tag];
            newState.sort();
            return newState;
        });
        setTags((prevState) => prevState.filter((it) => it !== tag));
    };

    const handleRemoveTag = (tag: string) => {
        setSelectedTags((prevState) => prevState.filter((it) => it !== tag));
        setTags((prevState) => {
            const newState = [...prevState, tag];
            newState.sort();
            return newState;
        });
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        selectedTags.sort();
        onSubmit(selectedTags);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textTags("modal.edit")}/>
            </ModalHeader>
            <ModalContent>
                <Loadable loading={isLoading}>
                    <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                        <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                            <FormLayoutColumns>
                                <FieldBlock label={textTags("field.search_tag")}>
                                    <InputAutocomplete
                                        options={tags.filter((tag) => !selectedTags.includes(tag)).map((tag) => ({label: tag, value: tag}))}
                                        onChange={(option) => handleAddTag(option!.value as string)}
                                    />
                                </FieldBlock>
                                <FieldBlock label={textTags("field.create_tag")}>
                                    <InputText value={tag} onChange={setTag}/>
                                    <Button style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={() => handleAddTag(tag)}>
                                        {textCommon(Action.ADD.labelKey)}
                                    </Button>
                                </FieldBlock>
                            </FormLayoutColumns>
                            <ElementList placeholder={textTags("placeholder.no_tag_selected")} size={ElementListSize.MEDIUM} inline>
                                {selectedTags.map((item) =>
                                    <TagWithAction key={item} value={item} onDelete={() => handleRemoveTag(item)} active/>
                                )}
                            </ElementList>
                        </FormLayoutRows>
                    </Form>
                </Loadable>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditTags;
