import {ApiModel} from "../../../../common/api/model/ApiModel";
import {PAGE_SIZE} from "../../../../utils/constants";

export enum RevenueImportSearchParamOrder {
    ASCENDANT = "ASC",
    DESCENDANT = "DESC"
}

export class RevenueImportSearchParam extends ApiModel {
    page: number = 0;
    size: number = PAGE_SIZE;
    order:RevenueImportSearchParamOrder = RevenueImportSearchParamOrder.DESCENDANT;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            size: this.size,
            page: this.page,
            order: this.order
        };
    }
}
