import {SearchParams} from "../../../utils/SearchParams";
import {SearchParamsField} from "../../../utils/SearchParamsField";
import {SearchQuery} from "../../interface/SearchQuery";

export class NotificationSearchQuery extends SearchQuery {
    service: string | undefined;
    type: string | undefined;
    owner_id: number = 0;
    status: string | undefined;

    withSearchParams(params: SearchParams): NotificationSearchQuery {
        let newQuery = super.withSearchParams(params) as NotificationSearchQuery;
        newQuery.service = params.getString(SearchParamsField.SERVICE);
        newQuery.type = params.getString(SearchParamsField.TYPE);
        newQuery.owner_id = params.getNumber(SearchParamsField.OWNER) || 0;
        newQuery.status = params.getString(SearchParamsField.STATUS);
        return newQuery;
    }
}
