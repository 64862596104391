import {ApiModel} from "../../../../common/api/model/ApiModel";

export class PricingStep extends ApiModel {
    fee: number = 0;
    limit?: number;
    volume_cost_per_unit?: number;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            fee: this.fee,
            limit: this.limit,
            volume_cost_per_unit: this.volume_cost_per_unit
        };
    }
}
