import {Box, BoxProps, ContentBlock, IconTooltip, LayoutRows, Loadable, SearchField, SearchToolbar, Select, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {DataleaksAuditField} from "../../api/model/dataleaks/audit/DataleaksAuditField";
import {DataleaksAuditInfosField} from "../../api/model/dataleaks/audit/DataleaksAuditInfosField";
import {DataleaksMechanism} from "../../api/model/dataleaks/DataleaksMechanism";
import {DataleaksRegulation} from "../../api/model/dataleaks/DataleaksRegulation";
import {DataleaksTrait} from "../../api/model/dataleaks/DataleaksTrait";
import {DataleaksVersion} from "../../api/model/dataleaks/version/DataleaksVersion";
import {DataleaksVersionField} from "../../api/model/dataleaks/version/DataleaksVersionField";
import {DataleaksVersionScoring} from "../../api/model/dataleaks/version/DataleaksVersionScoring";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {sortItems} from "../../common/utils/helper";
import {DataleaksScore, DataleaksScoreSize, DataleaksVersionScoringDetailsRow, MainContentHeader} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";

function DataleaksStatistics() {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);
    const {t: textDataleaksStatistics} = useTranslation(TranslationPortalFile.DATALEAKS_STATISTICS);
    const {t: textDataleaksVersions} = useTranslation(TranslationPortalFile.DATALEAKS_VERSIONS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [versions, setVersions] = useState<DataleaksVersion[]>([]);
    const [currentVersion, setCurrentVersion] = useState<DataleaksVersion>(new DataleaksVersion());
    const [versionScoring, setVersionScoring] = useState<DataleaksVersionScoring>(new DataleaksVersionScoring());

    const QUESTION_IDS = [11, 23, 24, 25];

    useEffect(() => {
        (async () => {
            try {
                const list = await session.restDataleaksVersion.list();
                const orderedVersions = sortItems(list, DataleaksVersionField.ID, true);
                setVersions(orderedVersions);
                setCurrentVersion(orderedVersions[0]);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("versions", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        if (!currentVersion.id) return;
        setLoading(true);
        (async () => {
            try {
                setVersionScoring(await session.restDataleaksVersion.getVersionScoring(currentVersion.id));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("statistics", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, currentVersion]);

    const buildRegulationScoring = (regulation: DataleaksRegulation) => {
        const score = versionScoring.regulations.get(regulation) || 0;
        return (
            <div className="dataleaks__scoring__item">
                <span className="h3">{textDataleaksQuestions(`regulation.${regulation}`)}</span>
                <div className="dataleaks__scoring__item__score">
                    <DataleaksScore value={score} size={DataleaksScoreSize.BIG}/>
                </div>
            </div>
        );
    };

    const getColumns = () => {
        return [
            {width: 14, label: `${textDataleaksQuestions(`regulation.${DataleaksRegulation.GDPR}`)} (${textDataleaksAudits("with_smart_tags")})`, styles: TableColumnStyle.ALIGN_CENTER},
            {width: 14, label: `${textDataleaksQuestions(`regulation.${DataleaksRegulation.EPRIVACY}`)} (${textDataleaksAudits("with_smart_tags")})`, styles: TableColumnStyle.ALIGN_CENTER},
            ...QUESTION_IDS.map((id) => ({width: 6, label: <>Q{id} <IconTooltip icon={{name: "info"}} text={textDataleaksStatistics(`tooltip.question.${id}`)}/></>, styles: TableColumnStyle.ALIGN_CENTER})),
            {width: 7, label: textDataleaksQuestions(`trait.${DataleaksTrait.FREE}`), styles: TableColumnStyle.ALIGN_CENTER},
            {width: 7, label: textDataleaksQuestions(`trait.${DataleaksTrait.INFORMED}`), styles: TableColumnStyle.ALIGN_CENTER},
            {width: 7, label: textDataleaksQuestions(`trait.${DataleaksTrait.SPECIFIC}`), styles: TableColumnStyle.ALIGN_CENTER},
            {width: 7, label: textDataleaksQuestions(`trait.${DataleaksTrait.UNAMBIGUOUS}`), styles: TableColumnStyle.ALIGN_CENTER}
        ];
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.DATALEAKS_STATISTICS}/>
            <MainContent>
                <LayoutRows>
                    <SearchToolbar>
                        <SearchField label={textDataleaksVersions(`field.${DataleaksVersionField.ID}`)}>
                            <Select
                                value={currentVersion.id}
                                options={versions.map((it) => ({label: it.label, value: it.id, version: it}))}
                                onChange={(option) => setCurrentVersion(option!.version as DataleaksVersion)}
                            />
                        </SearchField>
                    </SearchToolbar>
                    <Loadable loading={isLoading}>
                        <ContentBlock header={{title: {label: textDataleaksStatistics("section.global_scoring")}}}>
                            <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                <div className="dataleaks__scoring">
                                    <div className="dataleaks__scoring__item">
                                        <span className="h3">{textDataleaksStatistics("audits")}</span>
                                        <span className="dataleaks__scoring__item__score__big-value">{versionScoring.total_audits}</span>
                                    </div>
                                    {buildRegulationScoring(DataleaksRegulation.GDPR)}
                                    {buildRegulationScoring(DataleaksRegulation.EPRIVACY)}
                                </div>
                            </Box>
                        </ContentBlock>
                        <ContentBlock header={{title: {label: textDataleaksStatistics("section.scoring_by_mechanism", {count: versionScoring.scoring_mechanisms.total_audits})}}}>
                            <Table
                                columns={[
                                    {width: 20, label: textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.MECHANISM}`)},
                                    ...getColumns()
                                ]}
                            >
                                {Object.values(DataleaksMechanism).map((mechanism) =>
                                    <DataleaksVersionScoringDetailsRow
                                        key={mechanism}
                                        label={textDataleaksAudits(`mechanism.${mechanism}`)}
                                        scoringDetails={versionScoring.scoring_mechanisms.mechanisms.get(mechanism)}
                                        questionIds={QUESTION_IDS}
                                    />
                                )}
                            </Table>
                        </ContentBlock>
                        <ContentBlock header={{title: {label: textDataleaksStatistics("section.scoring_by_cmp", {count: versionScoring.scoring_cmps.total_audits})}}}>
                            <Table
                                columns={[
                                    {width: 20, label: textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.CMP_NAME}`)},
                                    ...getColumns()
                                ]}
                            >
                                {Array.from(versionScoring.scoring_cmps.cmps.keys()).map((cmpName) =>
                                    <DataleaksVersionScoringDetailsRow
                                        key={cmpName}
                                        label={cmpName}
                                        scoringDetails={versionScoring.scoring_cmps.cmps.get(cmpName)}
                                        questionIds={QUESTION_IDS}
                                    />
                                )}
                            </Table>
                        </ContentBlock>
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default DataleaksStatistics;
