import {
    Action,
    Box,
    BoxProps,
    Button,
    ButtonLink,
    ButtonSize,
    ContentBlock,
    FieldBlock,
    FlexContent,
    FlexContentDirection,
    FlexContentJustify,
    FormLayoutColumns,
    FormLayoutRows,
    InputText,
    LayoutRows,
    Loadable,
    SelectMultiple,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {CategorizerConfigEntry} from "../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryParams} from "../../api/model/categorizer/CategorizerConfigEntryParams";
import {CategorizerConfigEntryParamsField} from "../../api/model/categorizer/CategorizerConfigEntryParamsField";
import {CategorizerConfigType} from "../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfig} from "../../api/model/categorizer/config/CategorizerConfig";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {MainContentHeader} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {CategorizerConfigEntriesProps} from "../../utils/categorizer/CategorizerConfigEntriesProps";
import {ContentElement} from "../../api/interface/ContentElement";

function CategorizerAdvancedSearch() {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const alert = useAlert();

    const [params, setParams] = useState<CategorizerConfigEntryParams>({types: []});
    const [entries, setEntries] = useState<Map<string, CategorizerConfigEntry<CategorizerConfig>[]>>(new Map<string, CategorizerConfigEntry<CategorizerConfig>[]>());

    const [isLoading, setLoading] = useState(false);
    const [activeDeleteEntry, setActiveDeleteEntry] = useState<CategorizerConfigEntry<CategorizerConfig>>();

    const loadConfigs = useCallback(async () => {
        setLoading(true);
        const newEntries = new Map<string, CategorizerConfigEntry<CategorizerConfig>[]>();
        params.types?.forEach((type) => newEntries.set(type, []));
        try {
            const entryList = await session.restCategorizerEntry.searchEntries(params);
            entryList.forEach((entry) => {
                const items = newEntries.get(entry.type) || [];
                items.push(entry);
                newEntries.set(entry.type, items);
            });
            setEntries(newEntries);
        } catch (e) {
            if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                alert.failToLoad("configurations", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [params, alert]);

    const handleSearch = () => {
        (async () => {
            await loadConfigs();
        })();
    };

    const handleChangeParam = (field: CategorizerConfigEntryParamsField, value: any) => {
        setParams((prevState) => ({...prevState, [field]: !!value ? value : undefined}));
        setEntries(new Map<string, CategorizerConfigEntry<CategorizerConfig>[]>());
    };

    const handleDeleteEntry = async () => {
        if (!activeDeleteEntry) return;
        try {
            await session.restCategorizerEntry.delete(activeDeleteEntry);
            alert.deleteWithSuccess("configuration");
            await loadConfigs();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("configuration", e.message);
            }
        } finally {
            setActiveDeleteEntry(undefined);
        }
    };

    const getComponent = (configType?: CategorizerConfigType) => {
        if (!configType?.component) return;

        const props: CategorizerConfigEntriesProps<any> = {
            configs: entries.get(configType.name) || [],
            params: params,
            onSubmit: loadConfigs,
            onDelete: setActiveDeleteEntry,
            editable: true
        };
        return React.createElement(configType.component, {key: configType.name, ...props});
    };

    const hasEmptyField = () => {
        return !(params.domain || params.host || params.path) || !params.types?.length;
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CATEGORIZER} element={new ContentElement("", textCategorizer("action.advanced_search"))}/>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                            <FormLayoutRows>
                                <FormLayoutColumns>
                                    <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryParamsField.DOMAIN}`)} required>
                                        <InputText
                                            value={params.domain}
                                            onChange={(value) => handleChangeParam(CategorizerConfigEntryParamsField.DOMAIN, value)}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryParamsField.HOST}`)} required>
                                        <InputText
                                            value={params.host}
                                            onChange={(value) => handleChangeParam(CategorizerConfigEntryParamsField.HOST, value)}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryParamsField.PATH}`)} required>
                                        <InputText
                                            value={params.path}
                                            onChange={(value) => handleChangeParam(CategorizerConfigEntryParamsField.PATH, value)}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FieldBlock
                                    label={textCategorizer(`field.${CategorizerConfigEntryParamsField.TYPES}`)}
                                    actions={
                                        <ButtonLink
                                            key="select_all_types"
                                            onClick={() => handleChangeParam(CategorizerConfigEntryParamsField.TYPES, CategorizerConfigType.values(true).map((it) => it.name))}
                                        >
                                            {textCategorizer("search.select_all")}
                                        </ButtonLink>
                                    }
                                    required
                                >
                                    <SelectMultiple
                                        placeholder={textCategorizer("placeholder.select_types")}
                                        values={params.types}
                                        options={CategorizerConfigType.values(true).map((it) => ({label: `${textCategorizer(`config_type.${it.name}`)}`, value: it.name}))}
                                        onChange={(options) => handleChangeParam(CategorizerConfigEntryParamsField.TYPES, options.map((it) => it.value))}
                                    />
                                </FieldBlock>
                                <FlexContent direction={FlexContentDirection.ROW} justify={FlexContentJustify.END}>
                                    <Button size={ButtonSize.MEDIUM} onClick={handleSearch} disabled={hasEmptyField()}>
                                        {textCommon(Action.SEARCH.labelKey)}
                                    </Button>
                                </FlexContent>
                            </FormLayoutRows>
                        </Box>
                    </ContentBlock>
                    <Loadable loading={isLoading}>
                        {Array.from(entries.keys()).map((type) => getComponent(CategorizerConfigType.getByName(type)))}
                    </Loadable>
                </LayoutRows>
                <ModalConfirmDelete
                    active={!!activeDeleteEntry}
                    entity={textCategorizer(`config_type.${activeDeleteEntry?.type}`) + " configuration"}
                    confirm={handleDeleteEntry}
                    cancel={() => setActiveDeleteEntry(undefined)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default CategorizerAdvancedSearch;
