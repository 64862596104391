import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Status} from "../../../../utils/Status";
import {AudiencePlatform} from "../AudiencePlatform";

export class CategoryLinkExternal extends ApiModel {
    id: number = 0;
    category_id: number = 0;
    map_name: string = AudiencePlatform.ADOBE.name;
    value: string = "";
    active: boolean = false;

    constructor(o?: {}) {
        super();
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            category_id: this.category_id,
            map_name: this.map_name,
            value: this.value,
            active: this.active
        };
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }

    getPlatformName(): string {
        const platform = AudiencePlatform.getByName(this.map_name);
        return platform?.label || this.map_name;
    }
}
