import {ApiModel} from "../../../common/api/model/ApiModel";
import {ContentElement} from "../../interface/ContentElement";
import {MediaStatus} from "./MediaStatus";
import {Status} from "../../../utils/Status";
import {MediaType} from "./MediaType";
import {Module} from "../../../utils/Module";

export class Media extends ApiModel {
    id: number = 0;
    user_id: number | undefined;
    name: string = "";
    title: string = "";
    type: string = MediaType.IMAGE.name;
    mime_type: string = "";
    file_size: number = 0;
    cdn_host: string = "";
    url_path: string = "";
    status: MediaStatus = MediaStatus.ONLINE;
    creation_ts: string = "";
    last_update_ts: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get url(): string {
        return `https://${this.cdn_host}/${this.url_path}`;
    }

    getFormData(): FormData {
        const formData = new FormData();
        formData.append("type", this.type);
        formData.append("mime_type", this.mime_type);
        formData.append("title", this.title);
        return formData;
    }

    getRoute(): string {
        return Module.MEDIAS.buildRoute(this.id);
    }

    getStatus(): Status {
        return this.status === MediaStatus.ONLINE ? Status.ONLINE : Status.DELETED;
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.title);
        element.status = this.getStatus();
        element.createdAt = new Date(this.creation_ts);
        element.lastUpdated = new Date(this.last_update_ts);
        if (this.status === MediaStatus.ONLINE) {
            element.url = this.url;
        }
        return element;
    }
}
