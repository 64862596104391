import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {Status} from "../../../../utils/Status";
import {SearchQuery} from "../../../interface/SearchQuery";

export enum DistributionSearchParamsField {
    PLATFORM = "platform",
    PLATFORM_TYPE = "platform_type",
    TAXONOMY = "taxonomy",
}

export class DistributionSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    status?: string;
    platform?: string;
    platform_type?: string;
    taxonomy?: string;

    withSearchParams(params: SearchParams): DistributionSearchQuery {
        let newQuery = super.withSearchParams(params) as DistributionSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS);
        newQuery.platform = params.getString(DistributionSearchParamsField.PLATFORM);
        newQuery.platform_type = params.getString(DistributionSearchParamsField.PLATFORM_TYPE);
        newQuery.taxonomy = params.getString(DistributionSearchParamsField.TAXONOMY);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            query: this.query,
            sort: this.sortBy,
            reverse: this.reverseOrder,
            page: this.page,
            size: this.size,
            active: !!this.status ? `${this.status === Status.ACTIVE.name}` : undefined,
            platform: this.platform,
            platform_type: this.platform_type,
            taxonomy: this.taxonomy
        };
    }
}