import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {sortItems} from "../../../../../common/utils/helper";
import {CategoryGroupLinkKeyword} from "../CategoryGroupLinkKeyword";
import {CategoryGroupLinkKeywordField} from "../CategoryGroupLinkKeywordField";
import {CategoryTaxonomy} from "../CategoryTaxonomy";
import moment from "moment";

export class CategoryGroupKeywords extends ApiModel {
    taxonomy: string = CategoryTaxonomy.SIRDATA.name;
    group_id: number = 0;
    group_code_name: string = "";
    group_name: string = "";
    keywords: CategoryGroupLinkKeyword[] = [];

    constructor(o?: {}) {
        super();
        this.load(o);
    }

    get lastUpdate(): string {
        return this.keywords.reduce((previousValue, k) => moment(previousValue).isAfter(moment(k.last_update)) ? previousValue : k.last_update, "");
    }

    get lastIdOwner(): number {
        if (!this.keywords.length) {
            return 0;
        }

        const items = sortItems(this.keywords, CategoryGroupLinkKeywordField.LAST_UPDATE, true);
        return items[0].id_owner || 0;
    }
}
