import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Language} from "../../../../common/api/model/Language";
import {ContentElement} from "../../../interface/ContentElement";
import {TaxonomyTagCount} from "./TaxonomyTagCount";
import {TaxonomyTagPurpose} from "./TaxonomyTagPurpose";
import {Module} from "../../../../utils/Module";

export class TaxonomyTag extends ApiModel {
    name: string = "";
    language: string = Language.ENGLISH.name;
    purpose: TaxonomyTagPurpose = TaxonomyTagPurpose.SEARCH;
    description?: string;
    count: TaxonomyTagCount = new TaxonomyTagCount();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            language: this.language,
            purpose: this.purpose,
            description: this.description
        };
    }

    getRoute(): string {
        return Module.TAXONOMY_TAGS.buildRoute(this.name);
    }

    toContentElement(): ContentElement {
        return new ContentElement("", this.name);
    }
}
