import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Select, Table, TableColumnStyle} from "@sirdata/ui-lib";
import moment from "moment";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {ApiService} from "../../api/model/ApiService";
import {Notification} from "../../api/model/notifications/Notification";
import {NotificationField} from "../../api/model/notifications/NotificationField";
import {NotificationSearchQuery} from "../../api/model/notifications/NotificationSearchQuery";
import {NOTIFICATION_TYPES} from "../../api/model/notifications/NotificationType";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreateNotification from "../../component/modal/notifications/ModalCreateNotification";
import {MainContentHeader, MainContentHeaderAction, NotificationRow, SelectService, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {Status} from "../../utils/Status";

function Notifications() {
    const alert = useAlert();
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);
    const [isLoading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [isShowModalCreateNotification, setShowModalCreateNotification] = useState(false);
    const {buildSearchResult, ...search} = useSearch(Notification, NotificationSearchQuery);

    useEffect(() => {
        let currentNotifications = (notifications || []);
        if (search.searchQuery.service) {
            currentNotifications = currentNotifications.filter(({service}) => service === search.searchQuery.service);
        }
        if (search.searchQuery.type) {
            currentNotifications = currentNotifications.filter(({type}) => type === search.searchQuery.type);
        }
        if (search.searchQuery.status) {
            if (search.searchQuery.status === Status.ACTIVE.name) {
                currentNotifications = currentNotifications.filter(({expires_at}) => !expires_at || moment(expires_at).diff(moment()) > 0);
            } else currentNotifications = currentNotifications.filter(({expires_at}) => expires_at && moment(expires_at).diff(moment()) < 0);
        }
        if (!!search.searchQuery.query) {
            currentNotifications = currentNotifications.filter(({id, name}) => `${id} ${name}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentNotifications);
    }, [notifications, search.searchQuery, buildSearchResult]);

    useEffect(() => {
        (async () => {
            try {
                const notifications = await session.restNotification.list();
                setNotifications(notifications);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("notifications", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.NOTIFICATIONS}>
                <RestrictedContent allowedTo={Authorization.NOTIFICATIONS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateNotification(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textNotifications("search.placeholder"), value: search.searchQuery.query || "", onSubmit: search.changeQuery}}
                            canHideFilters
                        >
                            <SearchField label={textNotifications("field.status")}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textNotifications(`field.${NotificationField.SERVICE}`)}>
                                <SelectService
                                    value={search.searchQuery.service}
                                    services={ApiService.portals()}
                                    onChange={(service) => search.changeParam(SearchParamsField.SERVICE, service?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textNotifications(`field.${NotificationField.TYPE}`)}>
                                <Select
                                    value={search.searchQuery.type}
                                    options={NOTIFICATION_TYPES.map((it) => ({label: textNotifications(`type.${it}`), value: it}))}
                                    onChange={(option) => search.changeParam(SearchParamsField.TYPE, option?.value)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textNotifications("field.status"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textNotifications(`field.${NotificationField.ID}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: NotificationField.ID}},
                                        {width: 10, label: textNotifications(`field.${NotificationField.SERVICE}`), sort: {field: NotificationField.SERVICE}},
                                        {width: 15, label: textNotifications(`field.${NotificationField.TYPE}`), sort: {field: NotificationField.TYPE}},
                                        {width: 40, label: textNotifications(`field.${NotificationField.NAME}`), sort: {field: NotificationField.NAME}},
                                        {width: 15, label: textNotifications(`field.${NotificationField.EXPIRES_AT}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM, sort: {field: NotificationField.EXPIRES_AT}},
                                        {width: 5, label: textNotifications(`field.${NotificationField.IS_BROADCAST}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {
                                        search.searchResult.elements.map((item) => (
                                            <NotificationRow key={item.id} notification={item}/>
                                        ))
                                    }
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateNotification
                    active={isShowModalCreateNotification}
                    onClose={() => setShowModalCreateNotification(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Notifications;
