import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DistributionTechnicalMatchingTable} from "./DistributionTechnicalMatchingTable";

export class DistributionTechnical extends ApiModel {
    retention_in_days: number = 0;
    pixel_synchro: string = "";
    double_synchro: boolean = false;
    double_synchro_url?: string;
    trello_card_url: string = "";
    matching_table?: DistributionTechnicalMatchingTable;
    used_id: string = "";
    integration_type: string = "";
    consent_string: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            retention_in_days: this.retention_in_days,
            pixel_synchro: this.pixel_synchro,
            double_synchro: this.double_synchro,
            double_synchro_url: this.double_synchro ? this.double_synchro_url : undefined,
            trello_card_url: this.trello_card_url,
            matching_table: this.matching_table,
            used_id: this.used_id,
            integration_type: this.integration_type,
            consent_string: this.consent_string
        };
    }
}