import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Locale} from "../../../common/utils/Locale";
import {TranslationPortalFile} from "../../../utils/constants";
import {Box, BoxRadius, LayoutColumns} from "@sirdata/ui-lib";

type DataleaksAuditReportContactProps = {
    locale: Locale;
};

const DataleaksAuditReportContact: FunctionComponent<DataleaksAuditReportContactProps> = ({locale}) => {
    const {t: textDataleaksAuditsReport} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS_REPORT, {lng: locale.toString()});
    const contactEmail = "privacysolutions@sirdata.com";

    return (
        <div className="dataleaks__report__section">
            <div className="dataleaks__report__section__title">{textDataleaksAuditsReport("section.contact")}</div>
            <div className="dataleaks__report__contact">
                <div className="dataleaks__report__contact__title" dangerouslySetInnerHTML={{__html: textDataleaksAuditsReport("contact.title", {email: contactEmail})}}/>
                <LayoutColumns>
                    <Box radius={BoxRadius.SM} cssClass="dataleaks__report__contact__section">
                        <span className="h3 dataleaks__report__contact__section__title">
                            {textDataleaksAuditsReport("contact.free_meeting_title")}
                        </span>
                        <p className="dataleaks__report__contact__section__text">
                            {textDataleaksAuditsReport("contact.free_meeting_text")}
                        </p>
                    </Box>
                    <Box radius={BoxRadius.SM} cssClass="dataleaks__report__contact__section">
                        <span className="h3 dataleaks__report__contact__section__title">
                            {textDataleaksAuditsReport("contact.receive_full_audit_title")}
                        </span>
                        <ul>
                            {(textDataleaksAuditsReport("contact.receive_full_audit_items", {returnObjects: true}) as string[]).map((item) =>
                                <li key={item}>{item}</li>
                            )}
                        </ul>
                    </Box>
                </LayoutColumns>
            </div>
        </div>
    );
};

export default DataleaksAuditReportContact;
