import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {CmpConfigPublisherField} from "./CmpConfigPublisherField";
import {CmpConfigPublisherStandardPurpose} from "./CmpConfigPublisherStandardPurpose";
import {CmpConfigPublisherCustomPurpose} from "./CmpConfigPublisherCustomPurpose";

export class CmpConfigPublisher extends ApiModel {
    standardPurposes: CmpConfigPublisherStandardPurpose[] | undefined;
    customPurposes: CmpConfigPublisherCustomPurpose[] | undefined;

    constructor(o?: {}) {
        super(o);
        this.load(o);
        this.loadObjects(CmpConfigPublisherField.STANDARD_PURPOSES, CmpConfigPublisherStandardPurpose);
        this.loadObjects(CmpConfigPublisherField.CUSTOM_PURPOSES, CmpConfigPublisherCustomPurpose);
    }

    getJsonParameters(): {} {
        return {
            standardPurposes: this.standardPurposes,
            customPurposes: this.customPurposes?.map((purpose) => purpose.getJsonParameters())
        };
    }
}
