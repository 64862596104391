import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutRows, FormValidationType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, ToggleSwitch} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useAlert from "../../../utils/hooks/useAlert";
import {TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {AdsAccess} from "../../../api/model/audience/ads/AdsAccess";
import {AdsAccessField} from "../../../api/model/audience/ads/AdsAccessField";
import {SelectPartner} from "../../snippet";

type ModalCreateAdsAccessProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateAdsAccess: FunctionComponent<ModalCreateAdsAccessProps> = ({active, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textAdsAccess} = useTranslation(TranslationPortalFile.ADS_ACCESS);
    const [access, setAccess] = useState<AdsAccess>(new AdsAccess());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-ads-access";

    useEffect(() => {
        if (active) {
            setAccess(new AdsAccess());
        }
    }, [active]);

    const handleChange = (field: AdsAccessField, value: any) => {
        setAccess((prevState) => new AdsAccess({...prevState, [field]: value}));
    };


    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const newAccess = await session.restAdsAccess.create(access);
            navigate(newAccess.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("ADS access", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textAdsAccess("create_ads_access")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FieldBlock label={textAdsAccess(`field.${AdsAccessField.NAME}`)} required>
                            <InputText
                                value={access.name}
                                onChange={(value) => handleChange(AdsAccessField.NAME, value)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textAdsAccess(`field.${AdsAccessField.PARTNER_ID}`)} required>
                            <SelectPartner
                                value={access.partner_id}
                                onChange={(partner) => handleChange(AdsAccessField.PARTNER_ID, partner?.id || 0)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textAdsAccess(`field.${AdsAccessField.COLLECT_DATA}`)} name={AdsAccessField.COLLECT_DATA}>
                            <ToggleSwitch
                                checked={access.collect_data}
                                onChange={(value) => handleChange(AdsAccessField.COLLECT_DATA, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateAdsAccess;
