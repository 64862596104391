import {Action, ActionsMenu, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {CategoryLinkSegment} from "../../../../api/model/audience/category/CategoryLinkSegment";
import {RestrictedContent} from "../../../../common/component/widget";
import {TagSegmentDataType} from "../../index";
import {SegmentDataType} from "../../../../api/model/audience/segment/SegmentDataType";

type CategoryLinkSegmentRowProps = {
    link: CategoryLinkSegment;
    onRemove: (link: CategoryLinkSegment) => void;
};

const CategoryLinkSegmentRow: FunctionComponent<CategoryLinkSegmentRowProps> = ({link, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <TableRow onClick={{link: link.getRoute()}}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{link.segment_id}</TableColumn>
            <TableColumn><TagSegmentDataType type={SegmentDataType.getByName(link.data_type)}/></TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{link.segment_name}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{link.min_score}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{link.expiration_time}</TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <RestrictedContent allowedTo={Authorization.CATEGORIES.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                        items={[
                            {label: textCommon(Action.REMOVE.labelKey), onClick: () => onRemove(link), critical: true, separator: true}
                        ]}
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default CategoryLinkSegmentRow;
