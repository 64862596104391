export enum SearchParamsField {
    QUERY = "q",
    PAGE = "p",
    REVERSE_ORDER = "reverse_order",
    SORT_BY = "sort_by",
    SIZE = "size",

    STATUS = "status",
    TYPE = "type",
    PARTNER = "partner",
    OWNER = "owner",
    SERVICE = "service",
    PLATFORM = "platform",
    ORGANIZATION = "organization"
}
