export enum CategoryField {
    ID = "id",
    ID_GROUP = "id_group",
    TYPE = "type",
    ACTIVE = "active",
    PUBLIC = "public",
    ID_PARENT = "id_parent",
    NAME = "name",
    CODE_NAME = "code_name",
    PRICE = "price",
    PRICE_GBP = "price_gbp",
    PRICE_USD = "price_usd",
}
