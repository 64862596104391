import {CmpLocaleConfigTag} from "./CmpLocaleConfigTag";

export class CmpLocaleConfigItem {
    tags: CmpLocaleConfigTag[] = [];
    long_text: boolean = false;

    constructor(tags: CmpLocaleConfigTag[] = [], long_text: boolean = false) {
        this.tags = tags;
        this.long_text = long_text;
    }

    getHTMLTags(): string[] {
        return this.tags.reduce((tags: string[], tag) => {
            if (tag.has_body) {
                tags.push(`<${tag.name}>text</${tag.name}>`);
            } else {
                tags.push(`<${tag.name}/>`);
            }
            return tags;
        }, []);
    }
}
