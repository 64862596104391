import React, {FunctionComponent, useEffect, useState} from "react";
import {SelectAutocomplete} from "@sirdata/ui-lib";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {User} from "../../../api/model/user/User";
import {UserField} from "../../../api/model/user/UserField";
import useAlert from "../../../utils/hooks/useAlert";

type SelectUserProps = {
    value: string | number | undefined;
    onChange: ((user?: User) => void) | undefined;
    field?: UserField;
    includeInactive?: boolean;
    excludedUsers?: User[];
    onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
    onInvalid?: (e: React.FormEvent<HTMLInputElement>) => void;
    small?: boolean;
    disabled?: boolean;
};

const SelectUser: FunctionComponent<SelectUserProps> = ({value, onChange, field, includeInactive, excludedUsers, ...rest}) => {
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);

    useEffect(() => {
        (async () => {
            try {
                if (!!includeInactive) {
                    setUsers(await session.getUsers());
                } else {
                    setUsers(await session.getActiveUsers());
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("users", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [includeInactive, alert]);

    useEffect(() => {
        if (!!excludedUsers?.length) {
            const newFilteredUsers = users.filter((user) => !excludedUsers?.some((it) => it.id === user.id));
            setFilteredUsers(newFilteredUsers);
        } else {
            setFilteredUsers(users);
        }
    }, [users, excludedUsers]);

    return (
        <SelectAutocomplete
            value={value}
            options={filteredUsers.map((it) => ({
                value: it[field as string || UserField.ID],
                label: it.fullName,
                user: it
            }))}
            onChange={(option) => onChange && onChange(option?.user)}
            isLoading={isLoading}
            clearable
            {...rest}
        />
    );
};

export default SelectUser;
