import {Color, IconProps} from "@sirdata/ui-lib";
import {TaxonomyTargetingType} from "./TaxonomyTargetingType";

export class TaxonomyTag {
    static CONTEXTUAL: TaxonomyTag = new TaxonomyTag(TaxonomyTargetingType.CONTEXTUAL, Color.PURPLE, {name: "wallpaper"});
    static USER: TaxonomyTag = new TaxonomyTag(TaxonomyTargetingType.USER, Color.CYAN, {name: "person"});

    name: TaxonomyTargetingType;
    color: Color;
    icon: IconProps;

    constructor(name: TaxonomyTargetingType, color: Color, icon: IconProps) {
        this.name = name;
        this.color = color;
        this.icon = icon;
    }

    static values() : TaxonomyTag[] {
        return [TaxonomyTag.CONTEXTUAL, TaxonomyTag.USER];
    }

    static getTaxonomyTag(tag: TaxonomyTargetingType): TaxonomyTag | undefined {
        return TaxonomyTag.values().find((it) => it.name === tag);
    }
}
