export enum SirdataListVendorField {
    ID = "id",
    NAME = "name",
    PURPOSES = "purposes",
    LEG_INT_PURPOSES = "legIntPurposes",
    EXTRA_PURPOSES = "extraPurposes",
    LEG_INT_EXTRA_PURPOSES = "legIntExtraPurposes",
    POLICY_URL = "policyUrl",
    PREMIUM = "premium",
    DELETED_DATE = "deletedDate"
}
