export class MediaType {
    static FONT: MediaType = new MediaType("FONT", [".woff", ".woff2", ".ttf", ".otf"]);
    static IMAGE: MediaType = new MediaType("IMAGE", ["image/*"]);
    static VIDEO: MediaType = new MediaType("VIDEO", ["video/*"]);

    name: string;
    acceptTypes: string[];

    private constructor(name: string, acceptTypes: string[]) {
        this.name = name;
        this.acceptTypes = acceptTypes;
    }

    static values(): MediaType[] {
        return [
            MediaType.IMAGE,
            MediaType.VIDEO,
            MediaType.FONT
        ];
    }

    static getByName(name: string): MediaType | undefined {
        return this.values().find((it) => it.name === name);
    }
}
