import React, {FunctionComponent} from "react";
import clsx from "clsx";
import {Color} from "@sirdata/ui-lib";
import {formatPercentage} from "../../utils/string";

type ScoreCircleProps = {
    title: string;
    value: number;
    color: Color;
    small?: boolean;
};

const ScoreCircle: FunctionComponent<ScoreCircleProps> = ({title, value, color, small}) => {
    return (
        <div className={clsx("score-circle-container", {"score-circle-container--small": small})}>
            <div className={clsx("score-circle", `p${Math.round(value)}`)}>
                <div className="score-circle-value">
                    <span className="score-circle-label">{title}</span>
                    <span className="score-circle-number" style={{color: color}}>{formatPercentage(value)}</span>
                </div>
                <div className="score-circle-track" style={{borderColor: color}}>
                    <div className="score-circle-run"/>
                    <div className="score-circle-fixed"/>
                </div>
            </div>
        </div>
    );
};

export default ScoreCircle;
