export enum CustomerStorageField {
    ID = "id",
    NAME = "name",
    CREATION_TS = "creation_ts",
    LAST_UPDATE_TS = "last_update_ts",
    EXPIRATION_TS = "expiration_ts",
    PARTNER_ID = "partner_id",
    PARTNER_ACCOUNT_ID = "partner_account_id",
    UPLOADED_FILE = "uploaded_file",
    MAPPING_TYPE = "mapping_type",
    TABLE = "table",
    TABLE_INTERSECT = "table_intersect",
    CSV_OPTIONS = "csv_options",
    STATUS = "status",
    ERROR_CODE = "error_code",
    ERROR_MESSAGE = "error_message"
}
