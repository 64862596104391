import {IconTooltip, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Deal} from "../../../../api/model/deal/Deal";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Status} from "../../../../utils/Status";
import {LabelStatus} from "../../index";

type DealRowProps = {
    deal: Deal;
}

const DealRow: FunctionComponent<DealRowProps> = ({deal}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);

    return (
        <TableRow onClick={{link: deal.getRoute()}}>
            <TableColumn>
                <LabelStatus status={deal.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{deal.id}</TableColumn>
            <TableColumn>
                <span>{deal.name}</span>
                {deal.private &&
                <IconTooltip
                    icon={{name: "lock"}}
                    text={t(`status.${Status.PRIVATE.name}`)}
                />
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{deal.tiers.tiers1}</TableColumn>
        </TableRow>
    );
};

export default DealRow;
