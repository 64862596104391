import {Action, ContentBlock, ContentBlockAction, ElementList, ElementListSize, IconTooltip, Loadable, SearchBarClearable, TableColumn, TableRow, TagStyle} from "@sirdata/ui-lib";
import moment from "moment/moment";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../../api/ApiSession";
import {CategoryGroupLinkKeyword} from "../../../../api/model/audience/category/CategoryGroupLinkKeyword";
import {CategoryGroupLinkKeywordField} from "../../../../api/model/audience/category/CategoryGroupLinkKeywordField";
import {CategoryTaxonomy} from "../../../../api/model/audience/category/CategoryTaxonomy";
import {CategoryGroupKeywords} from "../../../../api/model/audience/category/keywords/CategoryGroupKeywords";
import {User} from "../../../../api/model/user/User";
import {ErrorResponse} from "../../../../common/api/http/ErrorResponse";
import {LoggedAccount} from "../../../../common/api/interface/LoggedAccount";
import {sortItems} from "../../../../common/utils/helper";
import {detectChanges} from "../../../../common/utils/portal";
import {TranslationPortalFile} from "../../../../utils/constants";
import useAlert from "../../../../utils/hooks/useAlert";
import ModalAddCategoryGroupLinksKeywords from "../../../modal/categories/ModalAddCategoryGroupLinksKeywords";
import {Tag, TagWithAction} from "../../index";
import {Formatter} from "../../../../common/utils/Formatter";

type CategoryGroupKeywordsRowExpandedProps = {
    categoryGroupKeywords: CategoryGroupKeywords;
    currentQuery: string;
    onChangeKeywordCount: (count: number) => void;
};

const CategoryGroupKeywordsRowExpanded: FunctionComponent<CategoryGroupKeywordsRowExpandedProps> = ({categoryGroupKeywords, currentQuery, onChangeKeywordCount}) => {
    const {t: textKeywords} = useTranslation(TranslationPortalFile.CATEGORY_KEYWORDS);
    const alert = useAlert();

    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [user, setUser] = useState<LoggedAccount>();
    const [users, setUsers] = useState<User[]>([]);
    const [query, setQuery] = useState("");

    const [currentLinksKeywords, setCurrentLinksKeywords] = useState<CategoryGroupLinkKeyword[]>([]);
    const [linksKeywords, setLinksKeywords] = useState<CategoryGroupLinkKeyword[]>([]);
    const [initLinksKeywords, setInitLinksKeywords] = useState<CategoryGroupLinkKeyword[]>([]);
    const [isShowModalAddCategoryGroupLinksKeywords, setShowModalAddCategoryGroupLinksKeywords] = useState(false);

    const isEditable = categoryGroupKeywords.taxonomy === CategoryTaxonomy.SIRDATA.name;

    const LIMIT_KEYWORDS_SMALL = 20;
    const LIMIT_KEYWORDS_MEDIUM = 50;

    useEffect(() => {
        (async () => {
            try {
                setUser(await session.getAccount());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("user", e.message);
                }
            }
            try {
                setUsers(await session.getUsers());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("users", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const sortedKeywords = sortItems(categoryGroupKeywords.keywords, CategoryGroupLinkKeywordField.LAST_UPDATE, true);
            setLinksKeywords(sortedKeywords);
            setCurrentLinksKeywords([...sortedKeywords]);
            setInitLinksKeywords([...sortedKeywords]);
            setLoading(false);
        })();
    }, [categoryGroupKeywords.keywords]);

    useEffect(() => {
        setCurrentLinksKeywords(linksKeywords.filter((it) => it.keyword.match(query)));
    }, [linksKeywords, query]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(linksKeywords, initLinksKeywords));
    }, [linksKeywords, initLinksKeywords]);

    const getElementListSize = (): ElementListSize => {
        return linksKeywords.length < LIMIT_KEYWORDS_SMALL ? ElementListSize.SMALL : linksKeywords.length > LIMIT_KEYWORDS_MEDIUM ? ElementListSize.BIG : ElementListSize.MEDIUM;
    };

    const handleAddLinks = async (keywordsToAdd: string[], threshold: number) => {
        const newLinks = keywordsToAdd.filter((keyword) => !linksKeywords.find((it) => it.keyword === keyword))
            .map((keyword) => {
                const newLink = new CategoryGroupLinkKeyword();
                newLink.id_group_category = categoryGroupKeywords.group_id;
                newLink.keyword = keyword;
                newLink.id_owner = user?.id;
                newLink.threshold = threshold;
                newLink.last_update = moment().format(Formatter.API_DATETIME_FORMAT);
                return newLink;
            });
        setShowModalAddCategoryGroupLinksKeywords(false);

        try {
            const savedKeywords = await session.restCategoryGroupLinkKeyword.saveKeywords(newLinks);
            for (const savedKeyword of savedKeywords) {
                const index = newLinks.findIndex((it) => it.keyword === savedKeyword.keyword);
                if (index !== -1) {
                    newLinks[index] = savedKeyword;
                }
            }
            alert.actionWithSuccess(textKeywords("message.keyword_added", {count: savedKeywords.length}));
            const newKeywords = [...savedKeywords, ...linksKeywords];
            setLinksKeywords(newKeywords);
            setInitLinksKeywords([...newKeywords]);
            onChangeKeywordCount(newKeywords.length);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("keywords", e.message);
            }
        }
    };

    const handleDeleteKeyword = (keyword: string) => {
        setLinksKeywords((prevState) => [...prevState.filter((k) => k.keyword !== keyword)]);
    };

    const handleSave = async () => {
        try {
            const deletedKeywords = initLinksKeywords.filter((k) => linksKeywords.findIndex((it) => it.keyword === k.keyword) === -1);
            if (deletedKeywords.length) {
                await session.restCategoryGroupLinkKeyword.deleteKeywords(deletedKeywords);
                alert.actionWithSuccess(textKeywords("message.keyword_deleted", {count: deletedKeywords.length}));
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("keywords", e.message);
            }
        } finally {
            setInitLinksKeywords([...linksKeywords]);
            onChangeKeywordCount(linksKeywords.length);
        }
    };

    const getOwner = (userId?: number) => {
        const user = users.find((it) => it.id === userId);
        return user ? ` (${user.initials.toUpperCase()})` : "";
    };

    return (
        <TableRow>
            <TableColumn colSpan={7}>
                <ContentBlock
                    cssClass="keywords"
                    header={{
                        actions: [
                            <SearchBarClearable key="search-bar" value={query} onSubmit={(q) => setQuery(q)}/>,
                            (isEditable ?
                                <ContentBlockAction
                                    key="action-add"
                                    action={Action.ADD}
                                    onClick={() => setShowModalAddCategoryGroupLinksKeywords(true)}
                                /> :
                                <IconTooltip icon={{name: "info"}} text={textKeywords("message.cannot_add")}/>
                            ),
                            (isEditable &&
                                <ContentBlockAction
                                    key="action-save"
                                    action={Action.SAVE}
                                    isDisabled={!isUnsavedChanges}
                                    onClick={handleSave}
                                />
                            )
                        ]
                    }}
                >
                    <Loadable loading={isLoading}>
                        <ElementList inline size={getElementListSize()}>
                            {currentLinksKeywords.map((link) =>
                                isEditable ?
                                    <TagWithAction
                                        key={link.id}
                                        value={`${link.keyword}${getOwner(link.id_owner)}`}
                                        active={!!currentQuery && link.keyword.includes(currentQuery.toLowerCase())}
                                        onDelete={() => handleDeleteKeyword(link.keyword)}
                                    />
                                    : (
                                        <Tag
                                            key={link.id}
                                            label={link.keyword}
                                            style={(!!currentQuery && link.keyword.includes(currentQuery.toLowerCase())) ? TagStyle.PRIMARY_MIDNIGHT_LIGHT : TagStyle.DEFAULT_MIDNIGHT_LIGHT}
                                        />
                                    )
                            )}
                        </ElementList>
                    </Loadable>
                </ContentBlock>
            </TableColumn>
            <ModalAddCategoryGroupLinksKeywords
                active={isShowModalAddCategoryGroupLinksKeywords}
                categoryGroupId={categoryGroupKeywords.group_id}
                existingKeywords={currentLinksKeywords.map((it) => it.keyword)}
                onSubmit={handleAddLinks}
                onClose={() => setShowModalAddCategoryGroupLinksKeywords(false)}
            />
        </TableRow>
    );
};

export default CategoryGroupKeywordsRowExpanded;
