import {
    Action,
    Button,
    ButtonLinkCancel,
    ButtonSize,
    ButtonStyle,
    ButtonValidate,
    FieldBlock,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutRows,
    ModalActions,
    ModalContent,
    ModalDescription,
    ModalDescriptionAlignment,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    SelectAutocomplete,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {PartnerOrganization} from "../../../api/model/partner/organization/PartnerOrganization";
import {PartnerOrganizationField} from "../../../api/model/partner/organization/PartnerOrganizationField";
import {PartnerOrganizationGroup} from "../../../api/model/partner/organization/PartnerOrganizationGroup";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {session} from "../../../api/ApiSession";
import {sortItems} from "../../../common/utils/helper";

export type ModalAddOrganizationToOrganizationGroupProps = {
    onSubmit: (organizationGroup: PartnerOrganizationGroup) => void;
    onClose: () => void;
    active: boolean;
    organizationGroup: PartnerOrganizationGroup;
};

const ModalAddOrganizationToOrganizationGroup: FunctionComponent<ModalAddOrganizationToOrganizationGroupProps> = ({onSubmit, onClose, active, organizationGroup}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const alert = useAlert();
    const [organizations, setOrganizations] = useState<PartnerOrganization[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<PartnerOrganization>();
    const [isShowModalConfirmAddOrganization, setShowModalConfirmAddOrganization] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        setSelectedOrganization(undefined);
    }, [active]);

    useEffect(() => {
        (async () => {
            try {
                let organizations = await session.restPartnerOrganization.list();
                organizations = sortItems(organizations, PartnerOrganizationField.NAME);
                setOrganizations(organizations);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("organizations", e.message);
                }
            }
        })();
    }, [alert]);

    const handleAddOrganization = async () => {
        if (!selectedOrganization) {
            return;
        }

        setSubmitting(true);
        try {
            selectedOrganization.group_id = organizationGroup.id;
            await session.restPartnerOrganization.update(selectedOrganization);

            const newOrganizationGroup = new PartnerOrganizationGroup(organizationGroup);
            newOrganizationGroup.organizations.push(selectedOrganization);
            newOrganizationGroup.organizations = sortItems(newOrganizationGroup.organizations, PartnerOrganizationField.NAME);
            onSubmit(newOrganizationGroup);
            setShowModalConfirmAddOrganization(false);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("add organization", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    const handleSubmit = async () => {
        if (selectedOrganization?.group_id === organizationGroup.id) {
            alert.failTo("add organization", textOrganizations("message.already_associated"));
            setSelectedOrganization(undefined);
            return;
        }

        if (selectedOrganization?.group_id) {
            setShowModalConfirmAddOrganization(true);
            return;
        }
        await handleAddOrganization();
    };

    return (
        <>
            <ModalNew onClose={onClose} active={active}>
                <ModalHeader>
                    <ModalHeaderTitle title={textOrganizations("add_organization")}/>
                </ModalHeader>
                <ModalContent>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={2}>
                            <FieldBlock label={textOrganizations("field.organization")} required>
                                <SelectAutocomplete
                                    value={selectedOrganization?.id}
                                    options={organizations.map((it) => ({value: it.id, label: it.name, organization: it}))}
                                    onChange={(option) => setSelectedOrganization(option?.organization)}
                                    clearable
                                />
                            </FieldBlock>
                            {selectedOrganization?.group &&
                                <FieldBlock label={textOrganizations("field.organization_group")}>
                                    <Select
                                        value={selectedOrganization.group_id}
                                        options={[{value: selectedOrganization.group_id, label: selectedOrganization.group.name}]}
                                        onChange={() => {}}
                                        disabled
                                    />
                                </FieldBlock>
                            }
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </ModalContent>
                <ModalActions>
                    <ButtonLinkCancel onClick={onClose}/>
                    <ButtonValidate onClick={handleSubmit} disabled={!selectedOrganization} loading={isSubmitting}/>
                </ModalActions>
            </ModalNew>
            <ModalNew active={isShowModalConfirmAddOrganization}>
                <ModalContent>
                    <FormLayoutRows>
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span dangerouslySetInnerHTML={{__html: textOrganizations("message.confirm_add_organization", {organizationGroup: selectedOrganization?.group?.name})}}/>
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                onClick={() => setShowModalConfirmAddOrganization(false)}
                            >
                                {textCommon(Action.CANCEL.labelKey)}
                            </Button>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.DEFAULT_MIDNIGHT}
                                onClick={handleAddOrganization}
                                loading={isSubmitting}
                            >
                                {textCommon(Action.ADD.labelKey)}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </>
    );
};

export default ModalAddOrganizationToOrganizationGroup;
