import {ApiModel} from "../../../common/api/model/ApiModel";
import {Model} from "../../../common/api/interface/Model";

export class PartnerAccountIdBody extends ApiModel implements Model {
    partner_account_id: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            partner_account_id: this.partner_account_id
        };
    }
}
