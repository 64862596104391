import {
    Alert,
    AlertSeverity,
    Button,
    ButtonSize,
    ButtonStyle,
    ButtonValidate,
    FieldBlock,
    FlexContentSpacing,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    InputText,
    ModalActions,
    ModalContent,
    ModalDescription,
    ModalDescriptionAlignment,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    Table,
    TableColumn,
    TableColumnStyle,
    TableRow,
    TagStyle,
    ToggleSwitch
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PartnerOrganizationRequest} from "../../../api/model/partner/organization/PartnerOrganizationRequest";
import {TranslationPortalFile} from "../../../utils/constants";
import {PartnerOrganizationRequestDetails} from "../../../api/model/partner/organization/PartnerOrganizationRequestDetails";
import {session} from "../../../api/ApiSession";
import {LabelService, SelectUser, Tag} from "../../snippet";
import {PartnerOrganizationField} from "../../../api/model/partner/organization/PartnerOrganizationField";
import {PartnerOrganizationGroupField} from "../../../api/model/partner/organization/PartnerOrganizationGroupField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {PartnerOrganization} from "../../../api/model/partner/organization/PartnerOrganization";
import {PartnerOrganizationRequestValidation} from "../../../api/model/partner/organization/PartnerOrganizationRequestValidation";
import {PartnerOrganizationGroup} from "../../../api/model/partner/organization/PartnerOrganizationGroup";
import useAlert from "../../../utils/hooks/useAlert";
import {PartnerOrganizationPropertiesField} from "../../../api/model/partner/organization/PartnerOrganizationPropertiesField";
import {PartnerOrganizationType} from "../../../api/model/partner/organization/PartnerOrganizationType";
import {PartnerField} from "../../../api/model/partner/PartnerField";

export type ModalVerifyOrganizationRequestProps = {
    request: PartnerOrganizationRequest | undefined;
    requestList: PartnerOrganizationRequest[];
    onUpdate: () => void;
    onClose: () => void;
};

const ModalVerifyOrganizationRequest: FunctionComponent<ModalVerifyOrganizationRequestProps> = ({request, requestList, onUpdate, onClose}) => {
    const alert = useAlert();
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);

    const [requestDetails, setRequestDetails] = useState<PartnerOrganizationRequestDetails>(new PartnerOrganizationRequestDetails());
    const [requestValidation, setRequestValidation] = useState<PartnerOrganizationRequestValidation>(new PartnerOrganizationRequestValidation());
    const [similarRequests, setSimilarRequests] = useState<PartnerOrganizationRequest[]>([]);
    const [isShowModalWarningSimilarRequests, setShowModalWarningSimilarRequests] = useState(false);

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                if (request?.id) {
                    let newRequestDetails = await session.restPartnerOrganizationRequest.getDetails(request);
                    setRequestDetails(newRequestDetails);
                    setRequestValidation(PartnerOrganizationRequestValidation.fromRequestDetails(newRequestDetails));

                    const similarRequests = requestList.filter((it) => it.id !== request.id && ((it.email.includes(newRequestDetails.email_domain) && !newRequestDetails.is_domain_reserved) || it.name.toLowerCase() === newRequestDetails.organization?.name.toLowerCase()));
                    setSimilarRequests(similarRequests);
                }
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("details", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [request, requestList, alert]);

    if (!request) return <></>;

    const doValidate = async (associateAllPartners?: boolean) => {
        setSubmitting(true);
        try {
            let newOrganization = await session.restPartnerOrganizationRequest.validate(request, requestValidation);
            newOrganization = await session.restPartnerOrganization.updateProperties(newOrganization.id, requestValidation.organization!.properties);
            alert.actionWithSuccess("organization validated");

            if (associateAllPartners) {
                for (let i = 0; i < similarRequests.length; i++) {
                    const newRequest = similarRequests[i];
                    if (newRequest.partner_account?.partner) {
                        try {
                            await session.restPartnerOrganization.addPartner(newOrganization.id, newRequest.partner_account.partner.id);
                            await session.restPartnerOrganizationRequest.reject(newRequest);
                            alert.actionWithSuccess(`partner ${newRequest.partner_account.fullName} added to organization`);
                        } catch (e) {
                            if (e instanceof ErrorResponse) {
                                alert.failTo(`add partner ${newRequest.partner_account.fullName} to organization`, e.message);
                            }
                        }
                    }
                }
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("validate organization", e.message);
            }
        } finally {
            setSubmitting(false);
            onUpdate();
            onClose();
            setShowModalWarningSimilarRequests(false);
        }
    };

    const handleValidate = async () => {
        if (!!similarRequests.length) {
            setShowModalWarningSimilarRequests(true);
        } else {
            doValidate();
        }
    };

    const handleReject = async () => {
        try {
            await session.restPartnerOrganizationRequest.reject(request);
            alert.actionWithSuccess("organization rejected");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("reject organization", e.message);
            }
        } finally {
            onUpdate();
            onClose();
        }
    };

    const handleChangeOrganizationProperty = (field: PartnerOrganizationPropertiesField, value: any) => {
        setRequestValidation((prevState) => {
            const request = new PartnerOrganizationRequestValidation({...prevState});
            request.organization = new PartnerOrganization({
                ...request.organization,
                [PartnerOrganizationField.PROPERTIES]: {
                    ...request.organization?.properties,
                    [field]: value
                }
            });
            return request;
        });
    };

    const hasEmptyField = () => {
        if (requestValidation.organization) {
            return !requestValidation.organization.name || !requestValidation.organization.type || !requestValidation.organization.owner_id;
        }
        if (requestValidation.organization_group) {
            return !requestValidation.organization_group.name || !requestValidation.organization_group.type || !requestValidation.organization_group.owner_id;
        }
    };

    return (
        <>
            <ModalNew onClose={onClose} active={!!request && !isShowModalWarningSimilarRequests} loading={isLoading}>
                <ModalHeader>
                    <ModalHeaderTitle title={textOrganizations("modal.verify_request.title")}/>
                </ModalHeader>
                <ModalContent>
                    <FormLayoutRows>
                        <FormLayoutTitle>{textOrganizations("modal.verify_request.partner_details")}</FormLayoutTitle>
                        <FormLayoutRows inline spacing={FlexContentSpacing.SMALL}>
                            <FieldBlock label={textPartners(`field.${PartnerField.ID}`)} content={{noFullWidth: true}}>
                                <Tag label={requestDetails.partner_account?.partner?.id.toString() || ""} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                            </FieldBlock>
                            <FieldBlock label={textPartners(`field.${PartnerField.EMAIL}`)}>
                                {requestDetails.partner_account?.email || ""}
                            </FieldBlock>
                            <FieldBlock label={textPartners(`field.${PartnerField.CREATION_SERVICE}`)} content={{noFullWidth: true}}>
                                <LabelService service={requestDetails.partner_account?.partner?.creation_service || ""}/>
                            </FieldBlock>
                        </FormLayoutRows>
                        {requestValidation.existing_organization &&
                            <>
                                <FormLayoutSeparator/>
                                <Alert
                                    fullWidth
                                    severity={AlertSeverity.WARNING}
                                    text={textOrganizations("modal.verify_request.already_exists", {organization: requestValidation.existing_organization?.name})}
                                />
                                <FormLayoutTitle>{textOrganizations("modal.verify_request.existing_organization")}</FormLayoutTitle>
                                <FormLayoutColumns>
                                    <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.NAME}`)}>
                                        <InputText
                                            value={requestValidation.existing_organization.name}
                                            onChange={() => {}}
                                            disabled
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.TYPE}`)} required>
                                        <Select
                                            value={requestValidation.existing_organization.type}
                                            options={PartnerOrganizationType.values().map((it) => ({value: it.name, label: textOrganizations(`type.${it.name}`)}))}
                                            onChange={() => {}}
                                            disabled
                                            clearable
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.OWNER_ID}`)} required>
                                        <SelectUser
                                            value={requestValidation.existing_organization.owner_id}
                                            onChange={() => {}}
                                            disabled
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.PROPERTIES}.${PartnerOrganizationPropertiesField.FRESHSALES_ID}`)}>
                                        <InputText
                                            value={requestValidation.organization?.properties?.freshsales_id || ""}
                                            onChange={() => {}}
                                            disabled
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                            </>
                        }
                        {requestValidation.organization &&
                            <>
                                <FormLayoutSeparator/>
                                <FormLayoutTitle>{textOrganizations("modal.verify_request.verify_organization")}</FormLayoutTitle>
                                <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                    <FormLayoutRows inline spacing={FlexContentSpacing.SMALL}>
                                        <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.NAME}`)}>
                                            <InputText
                                                value={requestValidation.organization.name}
                                                onChange={() => {}}
                                                disabled
                                            />
                                        </FieldBlock>
                                        <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.TYPE}`)} required>
                                            <Select
                                                value={requestValidation.organization.type}
                                                options={PartnerOrganizationType.values().map((it) => ({value: it.name, label: textOrganizations(`type.${it.name}`)}))}
                                                onChange={(option) => setRequestValidation((prevState) => new PartnerOrganizationRequestValidation({
                                                    ...prevState,
                                                    organization: new PartnerOrganization({...prevState.organization, [PartnerOrganizationField.TYPE]: `${option?.value || ""}`})
                                                }))}
                                                clearable
                                            />
                                        </FieldBlock>
                                        <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.OWNER_ID}`)} required>
                                            <SelectUser
                                                value={requestValidation.organization.owner_id}
                                                onChange={(user) => setRequestValidation((prevState) => new PartnerOrganizationRequestValidation({
                                                    ...prevState,
                                                    organization: new PartnerOrganization({...prevState.organization, [PartnerOrganizationField.OWNER_ID]: user?.id || 0})
                                                }))}
                                            />
                                        </FieldBlock>
                                        <FieldBlock label={textOrganizations(`field.${PartnerOrganizationField.PROPERTIES}.${PartnerOrganizationPropertiesField.FRESHSALES_ID}`)}>
                                            <InputText
                                                value={requestValidation.organization?.properties?.freshsales_id || ""}
                                                onChange={(value) => handleChangeOrganizationProperty(PartnerOrganizationPropertiesField.FRESHSALES_ID, value)}
                                            />
                                        </FieldBlock>
                                    </FormLayoutRows>
                                    {(!requestDetails.domain_organizations.length && !requestDetails.is_domain_reserved) &&
                                        <ToggleSwitch
                                            name={"associate_email_domain_to_organization"}
                                            label={textOrganizations("modal.verify_request.associate_email_domain_to_organization", {domain: requestDetails.email_domain})}
                                            checked={requestValidation.associate_email_domain_to_organization}
                                            onChange={(value) => setRequestValidation((prevState) => new PartnerOrganizationRequestValidation({...prevState, "associate_email_domain_to_organization": value}))}
                                        />
                                    }
                                    {!!requestDetails.domain_organizations.length ?
                                        <Alert
                                            text={textOrganizations("modal.verify_request.domain_associated_to_organization", {count: requestDetails.domain_organizations.length})}
                                            severity={AlertSeverity.WARNING}
                                        /> :
                                        requestDetails.is_domain_reserved &&
                                        <Alert
                                            text={textOrganizations("modal.verify_request.domain_reserved")}
                                            severity={AlertSeverity.WARNING}
                                        />
                                    }
                                    {!!requestDetails.domain_organizations.length &&
                                    <Table
                                        columns={[
                                            {width: 10, label: textOrganizations(`field.${PartnerOrganizationField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                            {width: 30, label: textOrganizations(`field.${PartnerOrganizationField.TYPE}`)},
                                            {width: 30, label: textOrganizations(`field.${PartnerOrganizationField.NAME}`)},
                                            {width: 30, label: textOrganizations(`field.${PartnerOrganizationField.GROUP}`)}
                                        ]}
                                    >
                                        {requestDetails.domain_organizations.map((organization) =>
                                            <TableRow key={organization.id} onClick={() => window.open(new PartnerOrganization(organization).getRoute(), "_blank")}>
                                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{organization.id}</TableColumn>
                                                <TableColumn>
                                                    {organization.type &&
                                                        <Tag label={textOrganizations(`type.${organization.type}`)} style={TagStyle.PRIMARY_OCEAN}/>
                                                    }
                                                </TableColumn>
                                                <TableColumn>{organization.name}</TableColumn>
                                                <TableColumn>{organization.group?.name}</TableColumn>
                                            </TableRow>
                                        )}
                                    </Table>
                                    }
                                </FormLayoutRows>
                            </>
                        }
                        {(!requestDetails.existing_organization && requestValidation.organization_group) &&
                            <>
                                <FormLayoutSeparator/>
                                <FormLayoutTitle>{textOrganizations("modal.verify_request.verify_organization_group")}</FormLayoutTitle>
                                <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                    <FormLayoutRows inline spacing={FlexContentSpacing.SMALL}>
                                        <FieldBlock label={textOrganizations(`field.${PartnerOrganizationGroupField.NAME}`)}>
                                            <InputText
                                                value={requestValidation.organization_group.name}
                                                onChange={() => {}}
                                                disabled
                                            />
                                        </FieldBlock>
                                        <FieldBlock label={textOrganizations(`field.${PartnerOrganizationGroupField.TYPE}`)} required>
                                            <Select
                                                value={requestValidation.organization_group.type}
                                                options={PartnerOrganizationType.values().map((it) => ({value: it.name, label: textOrganizations(`type.${it.name}`)}))}
                                                onChange={(option) => setRequestValidation((prevState) => new PartnerOrganizationRequestValidation({
                                                    ...prevState,
                                                    organization_group: new PartnerOrganizationGroup({
                                                        ...prevState.organization_group,
                                                        [PartnerOrganizationGroupField.TYPE]: `${option?.value || ""}`
                                                    })
                                                }))}
                                                disabled={!!requestValidation.organization_group.id}
                                                clearable
                                            />
                                        </FieldBlock>
                                        <FieldBlock label={textOrganizations(`field.${PartnerOrganizationGroupField.OWNER_ID}`)} required>
                                            <SelectUser
                                                value={requestValidation.organization_group.owner_id}
                                                onChange={(user) => setRequestValidation((prevState) => new PartnerOrganizationRequestValidation({
                                                    ...prevState,
                                                    organization_group: new PartnerOrganizationGroup({
                                                        ...prevState.organization_group,
                                                        [PartnerOrganizationGroupField.OWNER_ID]: user?.id || 0
                                                    })
                                                }))}
                                                disabled={!!requestValidation.organization_group.id}
                                            />
                                        </FieldBlock>
                                    </FormLayoutRows>
                                    {(!requestDetails.domain_organization_group && !requestDetails.is_domain_reserved) &&
                                        <ToggleSwitch
                                            name={"associate_email_domain_to_organization_group"}
                                            label={textOrganizations("modal.verify_request.associate_email_domain_to_organization_group", {domain: requestDetails.email_domain})}
                                            checked={requestValidation.associate_email_domain_to_organization_group}
                                            onChange={(value) => setRequestValidation((prevState) => new PartnerOrganizationRequestValidation({...prevState, "associate_email_domain_to_organization_group": value}))}
                                        />
                                    }
                                    {!!requestDetails.domain_organization_group ?
                                        <Alert
                                            text={textOrganizations("modal.verify_request.domain_associated_to_organization_group")}
                                            severity={AlertSeverity.WARNING}
                                        /> :
                                        requestDetails.is_domain_reserved &&
                                        <Alert
                                            text={textOrganizations("modal.verify_request.domain_reserved")}
                                            severity={AlertSeverity.WARNING}
                                        />
                                    }
                                    {!!requestDetails.domain_organization_group &&
                                    <Table
                                        columns={[
                                            {width: 10, label: textOrganizations(`field.${PartnerOrganizationField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                            {width: 30, label: textOrganizations(`field.${PartnerOrganizationField.TYPE}`)},
                                            {width: 60, label: textOrganizations(`field.${PartnerOrganizationField.NAME}`)}
                                        ]}
                                    >
                                        {[
                                            <TableRow
                                                key={requestDetails.domain_organization_group.id}
                                                onClick={() => window.open(requestDetails.domain_organization_group?.getRoute(), "_blank")}
                                            >
                                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{requestDetails.domain_organization_group.id}</TableColumn>
                                                <TableColumn>
                                                    {requestDetails.domain_organization_group.type &&
                                                        <Tag label={textOrganizations(`type.${requestDetails.domain_organization_group.type}`)} style={TagStyle.PRIMARY_OCEAN}/>
                                                    }
                                                </TableColumn>
                                                <TableColumn>{requestDetails.domain_organization_group.name}</TableColumn>
                                            </TableRow>
                                        ]}
                                    </Table>
                                    }
                                </FormLayoutRows>
                            </>
                        }
                    </FormLayoutRows>
                </ModalContent>
                <ModalActions>
                    <Button onClick={handleReject} size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_MIDNIGHT}>{textOrganizations("actions.reject")}</Button>
                    {requestValidation.organization &&
                        <ButtonValidate onClick={handleValidate} disabled={hasEmptyField()} loading={isSubmitting}/>
                    }
                </ModalActions>
            </ModalNew>
            <ModalNew active={isShowModalWarningSimilarRequests}>
                <ModalContent>
                    <FormLayoutRows>
                        <Alert
                            severity={AlertSeverity.WARNING}
                            text={textOrganizations("modal.verify_request.similar_request", {count: similarRequests.length, organization: requestValidation.organization?.name})}
                        />
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span dangerouslySetInnerHTML={{__html: textOrganizations("modal.verify_request.similar_request_confirm", {count: similarRequests.length})}}/>
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button size={ButtonSize.BIG} style={ButtonStyle.DEFAULT_MIDNIGHT} onClick={() => doValidate()} loading={isSubmitting}>
                                {textOrganizations("modal.verify_request.do_not_associate")}
                            </Button>
                            <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={() => doValidate(true)} loading={isSubmitting}>
                                {textOrganizations("modal.verify_request.associate_all")}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </>
    );
};

export default ModalVerifyOrganizationRequest;
