import {useState} from "react";

function useFormValidator<T extends string>() {
    const [errors, setErrors] = useState<{ [key in T]?: boolean; }>({});
    const [isShowErrors, setShowErrors] = useState(false);

    const isError = (field: T): boolean => {
        if (!isShowErrors) {
            return false;
        }
        return errors[field] || false;
    };

    const hasErrors = (): boolean => {
        for (let key in errors) {
            if (isError(key)) {
                return true;
            }
        }
        return false;
    };

    return {
        setErrors,
        isError,
        hasErrors,
        setShowErrors
    };
}

export default useFormValidator;
