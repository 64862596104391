export class CmpConfigScope {
    static PROVIDER: CmpConfigScope = new CmpConfigScope("PROVIDER");
    static DOMAIN: CmpConfigScope = new CmpConfigScope("DOMAIN");
    static LOCAL: CmpConfigScope = new CmpConfigScope("LOCAL");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CmpConfigScope[] {
        return [
            // CmpConfigScope.PROVIDER,
            CmpConfigScope.DOMAIN,
            CmpConfigScope.LOCAL
        ];
    }
}
