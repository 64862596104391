import {Action, FormatterFormat, Icon, IconTooltip, TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent, MouseEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {CategoryGroup} from "../../../../api/model/audience/category/CategoryGroup";
import {CategoryGroupKeywords} from "../../../../api/model/audience/category/keywords/CategoryGroupKeywords";
import {User} from "../../../../api/model/user/User";
import {Formatter} from "../../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelUser, Tag} from "../../index";
import CategoryGroupKeywordsRowExpanded from "./CategoryGroupKeywordsRowExpanded";

type CategoryGroupKeywordsProps = {
    categoryGroupKeywords: CategoryGroupKeywords;
    currentQuery: string;
    owner?: User;
};

const CategoryGroupKeywordsRow: FunctionComponent<CategoryGroupKeywordsProps> = ({categoryGroupKeywords, currentQuery, owner}) => {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const {t: textKeywords} = useTranslation(TranslationPortalFile.CATEGORY_KEYWORDS);

    const [keywordsCount, setKeywordsCount] = useState(categoryGroupKeywords.keywords.length);
    const [isExpanded, setExpanded] = useState(false);

    const openCategoryGroupDetails = (e: MouseEvent) => {
        e.stopPropagation();
        window.open(new CategoryGroup({id: categoryGroupKeywords.group_id}).getRoute(), "_blank");
    };

    return (
        <>
            <TableRow key={categoryGroupKeywords.group_id} onClick={() => setExpanded((prevState) => !prevState)}>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                    <Icon {...(isExpanded ? Action.COLLAPSE.icon : Action.EXPAND.icon)}/>
                </TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{categoryGroupKeywords.group_id}</TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                    <Tag label={textCategories(`taxonomy.${categoryGroupKeywords.taxonomy}`)} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                </TableColumn>
                <TableColumn>
                    <span>{categoryGroupKeywords.group_code_name}</span>
                    <IconTooltip
                        icon={Action.OPEN.icon}
                        text={textKeywords("action.see_category")}
                        onClick={openCategoryGroupDetails}
                    />
                </TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                    <Tag label={keywordsCount.toString()} style={TagStyle.PRIMARY_GREY_LIGHT}/>
                </TableColumn>
                <TableColumn>{Formatter.formatDate(categoryGroupKeywords.lastUpdate, FormatterFormat.DATE_TIME)}</TableColumn>
                <TableColumn>
                    <LabelUser user={owner}/>
                </TableColumn>
            </TableRow>
            {isExpanded &&
                <CategoryGroupKeywordsRowExpanded
                    categoryGroupKeywords={categoryGroupKeywords}
                    currentQuery={currentQuery}
                    onChangeKeywordCount={setKeywordsCount}
                />
            }
        </>
    );
};

export default CategoryGroupKeywordsRow;
