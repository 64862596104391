import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutRows, FormValidationType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, ToggleSwitch} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ApiAuthorization} from "../../../api/model/auth/ApiAuthorization";
import {ApiAuthorizationField} from "../../../api/model/auth/ApiAuthorizationField";
import {TranslationPortalFile} from "../../../utils/constants";
import SelectService from "../../snippet/field/SelectService";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {ApiService} from "../../../api/model/ApiService";

type ModalCreatePartnerAuthorizationProps = {
    active: boolean;
    onClose: (refresh: boolean) => void;
};

const ModalCreatePartnerAuthorization: FunctionComponent<ModalCreatePartnerAuthorizationProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t: textAuthorizations} = useTranslation(TranslationPortalFile.AUTHORIZATIONS);
    const [authorization, setAuthorization] = useState<ApiAuthorization>(new ApiAuthorization());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "modal-create-partner-authorization";

    useEffect(() => {
        if (active) {
            setAuthorization(new ApiAuthorization({[ApiAuthorizationField.SERVICE]: ApiService.ACCOUNT.name}));
        }
    }, [active]);

    const handleChange = (field: ApiAuthorizationField, value: any) => {
        setAuthorization((prevState) => new ApiAuthorization({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const newAuthorization = new ApiAuthorization(authorization);
            newAuthorization.name = `${authorization.service}.${authorization.name.toUpperCase()}`;
            await session.restAuthorizationPartner.create(newAuthorization);
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("authorization", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textAuthorizations("create_partner_authorization")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutRows inline>
                            <FieldBlock label={textAuthorizations(`field.${ApiAuthorizationField.SERVICE}`)} required>
                                <SelectService
                                    value={authorization.service}
                                    services={ApiAuthorization.partnerServices()}
                                    onChange={(service) => handleChange(ApiAuthorizationField.SERVICE, `${service?.name || ""}`)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textAuthorizations(`field.${ApiAuthorizationField.NAME}`)} required>
                                <span>{`${authorization.service}.`}</span>
                                <InputText
                                    value={authorization.name}
                                    onChange={(value) => handleChange(ApiAuthorizationField.NAME, value.toUpperCase())}
                                    required
                                />
                            </FieldBlock>
                        </FormLayoutRows>
                        <FieldBlock name={ApiAuthorizationField.ON_SERVICE_ACTIVATION} label={textAuthorizations(`field.${ApiAuthorizationField.ON_SERVICE_ACTIVATION}`)}>
                            <ToggleSwitch
                                checked={authorization.on_service_activation}
                                onChange={(value) => handleChange(ApiAuthorizationField.ON_SERVICE_ACTIVATION, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreatePartnerAuthorization;
