import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Select, Table, TableColumnStyle, ToggleSwitch} from "@sirdata/ui-lib";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {CategoryGroupLinkKeywordField} from "../../api/model/audience/category/CategoryGroupLinkKeywordField";
import {CategoryTaxonomy} from "../../api/model/audience/category/CategoryTaxonomy";
import {CategoryTopTier} from "../../api/model/audience/category/CategoryTopTier";
import {CategoryGroupKeywords} from "../../api/model/audience/category/keywords/CategoryGroupKeywords";
import {CategoryGroupKeywordsField} from "../../api/model/audience/category/keywords/CategoryGroupKeywordsField";
import {CategoryGroupKeywordsSearchParamsField, CategoryGroupKeywordsSearchQuery} from "../../api/model/audience/category/keywords/CategoryGroupKeywordsSearchQuery";
import {CategoryGroupKeywordsSearchResult} from "../../api/model/audience/category/keywords/CategoryGroupKeywordsSearchResult";
import {User} from "../../api/model/user/User";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalAddCategoryGroupsLinksKeywords from "../../component/modal/categories/ModalAddCategoryGroupsLinksKeywords";
import {CategoryGroupKeywordsRow, MainContentHeader, MainContentHeaderAction} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";

function Keywords() {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const {t: textKeywords} = useTranslation(TranslationPortalFile.CATEGORY_KEYWORDS);
    const [isLoading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);
    const alert = useAlert();
    const {setSearchResult, ...search} = useSearch(CategoryGroupKeywords, CategoryGroupKeywordsSearchQuery, CategoryGroupKeywordsSearchResult);
    const [isShowModalAddCategoryGroupsLinksKeywords, setShowModalAddCategoryGroupsLinksKeywords] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setUsers(await session.getUsers());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("users", e.message);
                }
            }
        })();
    }, [alert]);

    const loadCategoryGroupsKeywords = useCallback(async () => {
        try {
            setLoading(true);
            const result = await session.restCategoryGroupLinkKeyword.search(search.searchQuery);
            setSearchResult(result);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.statusCode !== HttpStatusCode.NOT_FOUND) {
                    alert.failToLoad("categories with keywords", e.message);
                }
            }
        } finally {
            setLoading(false);
        }
    }, [alert, search.searchQuery, setSearchResult]);

    useEffect(() => {
        (async () => {
            await loadCategoryGroupsKeywords();
        })();
    }, [loadCategoryGroupsKeywords]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.KEYWORDS}>
                <RestrictedContent allowedTo={Authorization.KEYWORDS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalAddCategoryGroupsLinksKeywords(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textKeywords("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters={true}
                            onClearFilters={search.clearFilters}
                        >
                            <SearchField label={textKeywords("search.strict")} shortWidth>
                                <ToggleSwitch
                                    checked={search.searchQuery.strict}
                                    onChange={(value) => search.changeParam(CategoryGroupKeywordsSearchParamsField.STRICT, value)}
                                />
                            </SearchField>
                            <SearchField label={textCategories(`field.${CategoryGroupKeywordsSearchParamsField.TIER1}`)}>
                                <Select
                                    value={search.searchQuery.tier1}
                                    options={CategoryTopTier.values().map((tier) => ({value: tier.name, label: tier.name}))}
                                    onChange={(option) => search.changeParam(CategoryGroupKeywordsSearchParamsField.TIER1, option?.value)}
                                    disabled={!!search.searchQuery.taxonomy && search.searchQuery.taxonomy !== CategoryTaxonomy.SIRDATA}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 1},
                                        {width: 5, label: textKeywords(`field.${CategoryGroupKeywordsField.GROUP_ID}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: CategoryGroupKeywordsField.GROUP_ID, reverseOrder: false}},
                                        {width: 5, label: textKeywords(`field.${CategoryGroupKeywordsField.TAXONOMY}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 49, label: textKeywords(`field.${CategoryGroupKeywordsField.GROUP_CODE_NAME}`), sort: {field: CategoryGroupKeywordsField.GROUP_CODE_NAME, reverseOrder: true}},
                                        {width: 10, label: textKeywords(`field.${CategoryGroupKeywordsField.KEYWORDS}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: CategoryGroupKeywordsField.KEYWORDS, reverseOrder: true}},
                                        {width: 15, label: textKeywords(`field.${CategoryGroupLinkKeywordField.LAST_UPDATE}`), sort: {field: CategoryGroupLinkKeywordField.LAST_UPDATE, reverseOrder: false}},
                                        {width: 15, label: textKeywords(`field.${CategoryGroupLinkKeywordField.ID_OWNER}`)}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((categoryKeywords: CategoryGroupKeywords) =>
                                        <CategoryGroupKeywordsRow
                                            key={categoryKeywords.group_id}
                                            categoryGroupKeywords={categoryKeywords}
                                            currentQuery={search.searchQuery.query}
                                            owner={users.find((user) => user.id === categoryKeywords.lastIdOwner)}
                                        />
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                        <ModalAddCategoryGroupsLinksKeywords
                            active={isShowModalAddCategoryGroupsLinksKeywords}
                            onClose={() => setShowModalAddCategoryGroupsLinksKeywords(false)}
                        />
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Keywords;
