import {
    ButtonLinkCancel,
    ButtonValidate,
    FieldBlock,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    Select,
    ToggleSwitch
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerSanitizerVersion} from "../../../api/model/categorizer/CategorizerSanitizerVersion";
import {CategorizerConfigField} from "../../../api/model/categorizer/config/CategorizerConfigField";
import {CategorizerConfigSanitizer} from "../../../api/model/categorizer/config/CategorizerConfigSanitizer";
import {CategorizerConfigSanitizerField} from "../../../api/model/categorizer/config/CategorizerConfigSanitizerField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {CategorizerConfigEntryInfo} from "../../snippet";
import {CategorizerConfigEntryParams} from "../../../api/model/categorizer/CategorizerConfigEntryParams";

type ModalAddCategorizerConfigSanitizerProps = {
    params: CategorizerConfigEntryParams;
    active: boolean;
    onSubmit: () => void;
    onClose: () => void;
};

const ModalAddCategorizerConfigSanitizer: FunctionComponent<ModalAddCategorizerConfigSanitizerProps> = ({params, active, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const [isLoading, setLoading] = useState(true);
    const [entry, setEntry] = useState<CategorizerConfigEntry<CategorizerConfigSanitizer>>(CategorizerConfigEntry.forType(CategorizerConfigType.SANITIZER, params));
    const [versions, setVersions] = useState<CategorizerSanitizerVersion[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-add-categorizer-config-sanitizer";

    useEffect(() => {
        if (!active || versions.length) return;
        (async () => {
            try {
                setVersions(await session.restCategorizer.getSanitizerVersions());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("sanitizer versions", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [active, alert, versions.length]);

    useEffect(() => {
        if (active) {
            setEntry(CategorizerConfigEntry.forType(CategorizerConfigType.SANITIZER, params));
        }
    }, [active, params]);

    const handleChange = (field: CategorizerConfigEntryField, value: any) => {
        setEntry((prevState) => new CategorizerConfigEntry<CategorizerConfigSanitizer>({...prevState, [field]: value}));
    };

    const handleChangeConfig = (field: CategorizerConfigSanitizerField | CategorizerConfigField, value: any) => {
        handleChange(CategorizerConfigEntryField.CONFIG, new CategorizerConfigSanitizer({...entry.config, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await session.restCategorizerEntry.create(entry);
            alert.createWithSuccess(`${textCategorizer(`config_type.${CategorizerConfigType.SANITIZER.name}`)} configuration`);
            onSubmit();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate(`${textCategorizer(`config_type.${CategorizerConfigType.SANITIZER.name}`)} configuration`, e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategorizer("modal.add_new_configuration", {type: textCategorizer(`config_type.${CategorizerConfigType.SANITIZER.name}`)})}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <CategorizerConfigEntryInfo currentEntry={entry}/>
                        <FormLayoutColumns>
                            <FieldBlock
                                label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigField.DISABLED}`)}
                                name={`${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigField.DISABLED}`}
                            >
                                <ToggleSwitch
                                    checked={entry.config.disabled}
                                    onChange={(value) => handleChangeConfig(CategorizerConfigField.DISABLED, value)}
                                />
                            </FieldBlock>
                            {!entry.config.disabled &&
                                <FieldBlock label={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigSanitizerField.SANITIZER_VERSION}`)}>
                                    <Select
                                        value={entry.config.sanitizer_version}
                                        options={versions.map((item) => ({label: CategorizerSanitizerVersion.getLabelForVersion(item.version), value: item.version}))}
                                        onChange={(option) => handleChangeConfig(CategorizerConfigSanitizerField.SANITIZER_VERSION, option?.value)}
                                        isLoading={isLoading}
                                    />
                                </FieldBlock>
                            }
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddCategorizerConfigSanitizer;
