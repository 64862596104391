import {Action, ContentBlock, ContentBlockAction, ElementList, ElementListSize, Tag as LibTag, TagSize, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {TranslationPortalFile} from "../../../utils/constants";
import {ApiAuthorization} from "../../../api/model/auth/ApiAuthorization";
import ModalEditUserAuthorizations from "../../modal/authorizations/ModalEditUserAuthorizations";
import useAlert from "../../../utils/hooks/useAlert";
import {sortItems} from "../../../common/utils/helper";
import {ApiAuthorizationField} from "../../../api/model/auth/ApiAuthorizationField";

const UserAuthorizations: FunctionComponent<{ userId: number }> = ({userId}) => {
    const alert = useAlert();
    const {t: textUsers} = useTranslation(TranslationPortalFile.USERS);
    const [isShowModalEditUserAuthorizations, setShowModalEditUserAuthorizations] = useState(false);
    const [userAuthorizations, setUserAuthorizations] = useState<ApiAuthorization[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const authorizations = await session.restUserAuthorization.list();
                const authorizationDetails = await session.restUserAuthorization.getDetails(userId);
                setUserAuthorizations(authorizations.filter((it) => authorizationDetails.authorization_set.includes(it.name)));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("user's authorizations", e.message);
                }
            }
        })();
    }, [userId, alert]);

    return (
        <ContentBlock
            header={{
                title: {label: textUsers("section.authorizations")},
                actions: (
                    <RestrictedContent allowedTo={Authorization.USERS.update}>
                        <ContentBlockAction action={Action.EDIT} onClick={() => setShowModalEditUserAuthorizations(true)}/>
                    </RestrictedContent>
                )
            }}
        >
            <ElementList placeholder={textUsers("placeholder.no_authorization_granted")} size={ElementListSize.BIG}>
                {sortItems(userAuthorizations, ApiAuthorizationField.NAME).map((authorization) =>
                    <LibTag
                        key={authorization.name}
                        size={TagSize.MEDIUM}
                        style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}
                        label={authorization.label}
                    />
                )}
            </ElementList>
            <ModalEditUserAuthorizations
                userId={userId}
                userAuthorizations={userAuthorizations}
                onSubmit={(authorizations) => {
                    setUserAuthorizations(authorizations);
                    setShowModalEditUserAuthorizations(false);
                }}
                onClose={() => setShowModalEditUserAuthorizations(false)}
                active={isShowModalEditUserAuthorizations}
            />
        </ContentBlock>
    );
};

export default UserAuthorizations;
