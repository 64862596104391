import {Action, ActionsMenu, ContentBlock, ContentBlockAction, Loadable, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FC, RefObject, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {SegmentLinkAreaCodes} from "../../../api/model/audience/segment/SegmentLinkAreaCodes";
import {CountryCode} from "../../../common/api/model/Country";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {copyApiModelArray} from "../../../common/utils/helper";
import {HandleSaveRef} from "../../../utils/audience/HandleSaveRef";
import {TranslationPortalFile} from "../../../utils/constants";
import ModalAddSegmentLinksAreaCodes from "../../modal/segments/ModalAddSegmentLinksAreaCodes";
import ModalEditSegmentLinksAreaCodes from "../../modal/segments/ModalEditSegmentLinksAreaCodes";
import ModalImportSegmentLinksAreaCodes from "../../modal/segments/ModalImportSegmentLinksAreaCodes";
import ModalConfirmRemove from "../../modal/ModalConfirmRemove";
import {CountryFlag, LabelStatus, Tag} from "../index";
import {detectChanges} from "../../../common/utils/portal";
import {SegmentLinkAreaCodesField} from "../../../api/model/audience/segment/SegmentLinkAreaCodesField";
import useAlert from "../../../utils/hooks/useAlert";
import {SegmentsDetailsSubModule} from "../../../screen/segments/SegmentsDetails";

type SegmentAreaCodesLinksProps = {
    segmentId: number;
    handleSaveRef: RefObject<HandleSaveRef>;
    onChange: (module: SegmentsDetailsSubModule, hasChanges: boolean) => void;
};

const SegmentAreaCodesLinks: FC<SegmentAreaCodesLinksProps> = ({segmentId, handleSaveRef, onChange}) => {
    const alert = useAlert();
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const [isLoading, setLoading] = useState(true);
    const [isMounted, setMounted] = useState(false);
    const [areaCodeLinks, setAreaCodeLinks] = useState<SegmentLinkAreaCodes[]>([]);
    const [initAreaCodeLinks, setInitAreaCodeLinks] = useState<SegmentLinkAreaCodes[]>([]);
    const [isShowModalImportAreaCodes, setShowModalImportAreaCodes] = useState(false);
    const [isShowModalAddAreaCodes, setShowModalAddAreaCodes] = useState(false);
    const [activeEditAreaCodes, setActiveEditAreaCodes] = useState<SegmentLinkAreaCodes>();
    const [activeRemoveLink, setActiveRemoveLink] = useState<SegmentLinkAreaCodes>();

    useEffect(() => {
        if (isMounted || !segmentId) return;
        (async () => {
            try {
                const newAreaCodeLinks = await session.restSegmentLinkAreaCode.list(segmentId);
                setAreaCodeLinks(newAreaCodeLinks);
                setInitAreaCodeLinks(copyApiModelArray(SegmentLinkAreaCodes, newAreaCodeLinks));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("area codes", e.message);
                }
            } finally {
                setLoading(false);
                setMounted(true);
            }
        })();
    }, [isMounted, segmentId, alert]);

    useEffect(() => {
        onChange(SegmentsDetailsSubModule.LINKS_AREA_CODES, detectChanges(areaCodeLinks, initAreaCodeLinks));
    }, [onChange, areaCodeLinks, initAreaCodeLinks]);

    useImperativeHandle(handleSaveRef, () => ({
        handleSave: async () => {
            setLoading(true);
            try {
                const currentIds = areaCodeLinks.map((it) => it.id);
                for (const deletedLink of initAreaCodeLinks.filter((link) => !currentIds.includes(link.id))) {
                    await session.restSegmentLinkAreaCode.delete(segmentId, deletedLink);
                }

                const newAreaCodeLinks = [];
                for (const link of areaCodeLinks) {
                    if (!!link.id) {
                        const updatedLink = await session.restSegmentLinkAreaCode.update(segmentId, link);
                        newAreaCodeLinks.push(updatedLink);
                    } else {
                        const newLink = await session.restSegmentLinkAreaCode.add(segmentId, link);
                        newAreaCodeLinks.push(newLink);
                    }
                }
                setAreaCodeLinks(newAreaCodeLinks);
                setInitAreaCodeLinks(copyApiModelArray(SegmentLinkAreaCodes, newAreaCodeLinks));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToUpdate("area codes", e.message);
                }
            } finally {
                setLoading(false);
            }
        }
    }));

    const handleAddAreaCodeLink = (link: SegmentLinkAreaCodes) => {
        setShowModalAddAreaCodes(false);
        const newLinksAreaCodes = [...areaCodeLinks];
        if (newLinksAreaCodes.some((it) => it.type === link.type && it.iso_country === link.iso_country)) {
            alert.failTo("add area codes", textSegments("message.area_codes_already_added", {country: CountryCode.getCountryFromCode(link.iso_country)?.name, type: link.type}));
            return;
        }
        newLinksAreaCodes.push(link);
        setAreaCodeLinks(newLinksAreaCodes);
    };

    const handleEditAreaCodeLink = (link: SegmentLinkAreaCodes) => {
        const newLinksAreaCodes = areaCodeLinks.map((it) => it.id === link.id ? link : it);
        setAreaCodeLinks(newLinksAreaCodes);
        setActiveEditAreaCodes(undefined);
    };

    const handleAddAreaCodeLinks = (links: SegmentLinkAreaCodes[]) => {
        for (const link of links) {
            handleAddAreaCodeLink(link);
        }
        setShowModalImportAreaCodes(false);
    };

    const handleRemoveLink = () => {
        if (!activeRemoveLink) return;

        const newLinksAreaCodes = areaCodeLinks.filter((it) => !(it.iso_country === activeRemoveLink.iso_country && it.type === activeRemoveLink.type));
        setAreaCodeLinks(newLinksAreaCodes);
        setActiveRemoveLink(undefined);
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{title: {label: textSegments("section.area_codes")}, actions: [
                    <RestrictedContent key="action-import" allowedTo={Authorization.SEGMENTS.update}>
                        <ContentBlockAction
                            action={Action.IMPORT}
                            onClick={() => setShowModalImportAreaCodes(true)}
                        />
                    </RestrictedContent>,
                    <RestrictedContent key="action-add" allowedTo={Authorization.SEGMENTS.update}>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddAreaCodes(true)}/>
                    </RestrictedContent>
                ]}}
            >
                <Table
                    columns={[
                        {label: textSegments("field.status"), width: 20, styles: TableColumnStyle.ALIGN_CENTER},
                        {label: textSegments(`field.area_codes.${SegmentLinkAreaCodesField.TYPE}`), width: 25},
                        {label: textSegments(`field.area_codes.${SegmentLinkAreaCodesField.ISO_COUNTRY}`), width: 30},
                        {label: textSegments(`field.area_codes.${SegmentLinkAreaCodesField.CODES}`), width: 20},
                        {width: 5}
                    ]}
                >
                    {areaCodeLinks.map((link, index) =>
                        <TableRow key={`area-code-link-${index.toString()}`}>
                            <TableColumn>
                                <LabelStatus status={link.getStatus()}/>
                            </TableColumn>
                            <TableColumn>
                                <Tag label={link.type} style={TagStyle.PRIMARY_OCEAN}/>
                            </TableColumn>
                            <TableColumn>
                                <CountryFlag code={link.iso_country}/>
                            </TableColumn>
                            <TableColumn>
                                {textSegments("count_codes", {count: link.getCodes().length})}
                            </TableColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                <RestrictedContent allowedTo={Authorization.SEGMENTS.update}>
                                    <ActionsMenu
                                        iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                        items={[
                                            {label: textCommon(Action.EDIT.labelKey), onClick: () => setActiveEditAreaCodes(link)},
                                            {label: textCommon(Action.REMOVE.labelKey), onClick: () => setActiveRemoveLink(link), critical: true, separator: true}
                                        ]}
                                    />
                                </RestrictedContent>
                            </TableActionColumn>
                        </TableRow>
                    )}
                </Table>
                <ModalAddSegmentLinksAreaCodes
                    active={isShowModalAddAreaCodes}
                    onSubmit={handleAddAreaCodeLink}
                    onClose={() => setShowModalAddAreaCodes(false)}
                />
                <ModalEditSegmentLinksAreaCodes
                    initLink={activeEditAreaCodes}
                    onSubmit={handleEditAreaCodeLink}
                    onClose={() => setActiveEditAreaCodes(undefined)}
                />
                <ModalImportSegmentLinksAreaCodes
                    active={isShowModalImportAreaCodes}
                    onSubmit={handleAddAreaCodeLinks}
                    onClose={() => setShowModalImportAreaCodes(false)}
                />
                <ModalConfirmRemove
                    active={!!activeRemoveLink}
                    entity="area codes"
                    confirm={handleRemoveLink}
                    cancel={() => setActiveRemoveLink(undefined)}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default SegmentAreaCodesLinks;
