import {ButtonLinkCancel, ButtonValidate, FieldBlock, FormLayoutRows, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, RadioButtons, Select} from "@sirdata/ui-lib";
import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigPublisherField} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherField";
import {CmpConfigPublisherLegalBasis} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherLegalBasis";
import {CmpConfigPublisherStandardPurpose} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherStandardPurpose";
import {CmpConfigPublisherStandardPurposeField} from "../../../api/model/cmp/config/publisher/CmpConfigPublisherStandardPurposeField";
import {Purpose} from "../../../api/model/cmp/list/global-vendor-list/Purpose";
import {TranslationPortalFile} from "../../../utils/constants";

type ModalAddStandardPurposeProps = {
    active: boolean;
    purposes: Purpose[];
    onSubmit: (restrict: CmpConfigPublisherStandardPurpose) => void;
    onClose: () => void;
};

const ModalAddStandardPurpose: FunctionComponent<ModalAddStandardPurposeProps> = ({active, purposes, onClose, onSubmit}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [purpose, setPurpose] = useState<CmpConfigPublisherStandardPurpose>(new CmpConfigPublisherStandardPurpose());
    const PURPOSE_1_ID = 1;

    const handleChangePurposeId = (purposeId: number) => {
        const newPurpose = new CmpConfigPublisherStandardPurpose({...purpose, [CmpConfigPublisherStandardPurposeField.ID]: purposeId});
        if (purposeId === PURPOSE_1_ID) {
            newPurpose.legalBasis = CmpConfigPublisherLegalBasis.CONSENT;
        }
        setPurpose(newPurpose);
    };

    const hasEmptyField = () => !(purpose.id && purpose.legalBasis);

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpConfigurations("purposes.action.add_standard_purpose")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows inline>
                    <FieldBlock label={textCmpConfigurations("purposes.purpose")}>
                        <Select
                            value={purpose.id}
                            options={purposes.map((it) => ({label: `${textCmpConfigurations("purposes.purpose")} ${it.id} - ${it.name}`, value: it.id}))}
                            onChange={(option) => option && handleChangePurposeId(+option.value)}
                        />
                    </FieldBlock>
                    <FieldBlock label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PUBLISHER_PURPOSES}.${CmpConfigPublisherField.STANDARD_PURPOSES}.${CmpConfigPublisherStandardPurposeField.LEGAL_BASIS}`)}>
                        <RadioButtons
                            id={CmpConfigPublisherStandardPurposeField.LEGAL_BASIS}
                            value={purpose.legalBasis}
                            options={[
                                {value: CmpConfigPublisherLegalBasis.CONSENT, label: textCmpConfigurations(`purposes.${CmpConfigPublisherLegalBasis.CONSENT}`)},
                                {value: CmpConfigPublisherLegalBasis.LEGITIMATE_INTEREST, label: textCmpConfigurations(`purposes.${CmpConfigPublisherLegalBasis.LEGITIMATE_INTEREST}`)}
                            ]}
                            onChange={(value) => setPurpose((prevState) => new CmpConfigPublisherStandardPurpose({...prevState, [CmpConfigPublisherStandardPurposeField.LEGAL_BASIS]: value}))}
                            disabled={purpose.id === PURPOSE_1_ID}
                        />
                    </FieldBlock>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate onClick={() => onSubmit(purpose)} disabled={hasEmptyField()}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddStandardPurpose;
