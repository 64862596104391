import {Rest} from "../../common/api/rest/Rest";
import {CmpLocale} from "../model/cmp/translation/CmpLocale";
import {CmpConfigVersion} from "../model/cmp/config/CmpConfigVersion";
import {CmpConfigLanguage} from "../model/cmp/config/CmpConfigLanguage";
import {CmpLocaleConfig} from "../model/cmp/translation/CmpLocaleConfig";

export class RestCmpTranslations extends Rest {
    rootPath = "/console-api/cmp/locale";

    getLocale(version: CmpConfigVersion, language: CmpConfigLanguage): Promise<CmpLocale> {
        return this._client.get(`${this.rootPath}/${version.name}/language/${language.name}`, CmpLocale) as Promise<CmpLocale>;
    }

    getTemplate(version: CmpConfigVersion): Promise<CmpLocaleConfig> {
        return this._client.get(`${this.rootPath}/${version.name}/template`, CmpLocaleConfig) as Promise<CmpLocaleConfig>;
    }
}