export class SegmentTopTier {
    static APPAREL: SegmentTopTier = new SegmentTopTier("Apparel");
    static ARTS_AND_ENTERTAINMENT: SegmentTopTier = new SegmentTopTier("Arts & Entertainment");
    static AUTO_AND_VEHICLES: SegmentTopTier = new SegmentTopTier("Auto & Vehicles");
    static BANK_AND_INSURANCE: SegmentTopTier = new SegmentTopTier("Bank & Insurance");
    static BEAUTY: SegmentTopTier = new SegmentTopTier("Beauty");
    static BRANDED: SegmentTopTier = new SegmentTopTier("Branded");
    static BUSINESS: SegmentTopTier = new SegmentTopTier("Business");
    static CHILDCARE: SegmentTopTier = new SegmentTopTier("Childcare");
    static CONSUMER_NON_PACKAGED_GOODS: SegmentTopTier = new SegmentTopTier("Consumer Non Packaged Goods");
    static CONSUMER_PACKAGED_GOODS: SegmentTopTier = new SegmentTopTier("Consumer Packaged Goods");
    static CUSTOM_AUDIENCE: SegmentTopTier = new SegmentTopTier("Custom Audience");
    static DEMOGRAPHICS: SegmentTopTier = new SegmentTopTier("Demographics");
    static EDUCATION: SegmentTopTier = new SegmentTopTier("Education");
    static FASHION: SegmentTopTier = new SegmentTopTier("Fashion");
    static FOOD_AND_DRINK: SegmentTopTier = new SegmentTopTier("FMCG I Food & Drink");
    static HABITS_AND_LIFESTYLE: SegmentTopTier = new SegmentTopTier("Habits & Lifestyle");
    static HIGH_TECH: SegmentTopTier = new SegmentTopTier("High Tech");
    static HOME_AND_GARDEN: SegmentTopTier = new SegmentTopTier("Home & Garden");
    static LIFE_EVENTS: SegmentTopTier = new SegmentTopTier("Life Events");
    static LOCATION_AREAS: SegmentTopTier = new SegmentTopTier("Location Areas");
    static ML_COMPUTED: SegmentTopTier = new SegmentTopTier("ML Computed");
    static NEWS: SegmentTopTier = new SegmentTopTier("News");
    static PERSONAL_CARE_AND_BEAUTY: SegmentTopTier = new SegmentTopTier("Personal Care and Beauty");
    static PETS_AND_ANIMAL: SegmentTopTier = new SegmentTopTier("Pets & Animal");
    static RETARGETING: SegmentTopTier = new SegmentTopTier("Retargeting");
    static SPECIAL_EVENTS: SegmentTopTier = new SegmentTopTier("Special Events");
    static SPORTS: SegmentTopTier = new SegmentTopTier("Sports");
    static TRAVEL: SegmentTopTier = new SegmentTopTier("Travel");
    static WELLBEING: SegmentTopTier = new SegmentTopTier("Wellbeing");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): SegmentTopTier[] {
        return [
            SegmentTopTier.APPAREL,
            SegmentTopTier.ARTS_AND_ENTERTAINMENT,
            SegmentTopTier.AUTO_AND_VEHICLES,
            SegmentTopTier.BANK_AND_INSURANCE,
            SegmentTopTier.BEAUTY,
            SegmentTopTier.BRANDED,
            SegmentTopTier.BUSINESS,
            SegmentTopTier.CHILDCARE,
            SegmentTopTier.CONSUMER_NON_PACKAGED_GOODS,
            SegmentTopTier.CONSUMER_PACKAGED_GOODS,
            SegmentTopTier.CUSTOM_AUDIENCE,
            SegmentTopTier.DEMOGRAPHICS,
            SegmentTopTier.EDUCATION,
            SegmentTopTier.FASHION,
            SegmentTopTier.FOOD_AND_DRINK,
            SegmentTopTier.HABITS_AND_LIFESTYLE,
            SegmentTopTier.HIGH_TECH,
            SegmentTopTier.HOME_AND_GARDEN,
            SegmentTopTier.LIFE_EVENTS,
            SegmentTopTier.LOCATION_AREAS,
            SegmentTopTier.ML_COMPUTED,
            SegmentTopTier.NEWS,
            SegmentTopTier.PERSONAL_CARE_AND_BEAUTY,
            SegmentTopTier.PETS_AND_ANIMAL,
            SegmentTopTier.RETARGETING,
            SegmentTopTier.SPECIAL_EVENTS,
            SegmentTopTier.SPORTS,
            SegmentTopTier.TRAVEL,
            SegmentTopTier.WELLBEING
        ];
    }
}
