import {DealFacet} from "./DealFacet";

export class DealFacets {
    device_types: DealFacet[] = [];
    inventory_types: DealFacet[] = [];
    platform_names: DealFacet[] = [];
    target_origin_types: DealFacet[] = [];
    tiers1: DealFacet[] = [];
    tiers2: DealFacet[] = [];
}
