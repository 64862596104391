export class DataleaksQuestionStep {
    static INFORMATION: DataleaksQuestionStep = new DataleaksQuestionStep("INFORMATION");
    static TEXTS: DataleaksQuestionStep = new DataleaksQuestionStep("TEXTS");

    name: string = "";

    private constructor(name: string) {
        this.name = name;
    }

    static steps(): DataleaksQuestionStep[] {
        return [
            DataleaksQuestionStep.INFORMATION,
            DataleaksQuestionStep.TEXTS
        ];
    }

    static firstStep(): DataleaksQuestionStep {
        return this.steps()[0];
    }

    static lastStep(): DataleaksQuestionStep {
        return this.steps()[this.steps().length - 1];
    }

    get index(): number {
        return DataleaksQuestionStep.steps().findIndex((it) => it.name === this.name);
    }

    get previous(): DataleaksQuestionStep | undefined {
        const stepIndex = this.index;
        if (stepIndex > 0) {
            return DataleaksQuestionStep.steps()[stepIndex - 1];
        }
        return undefined;
    }

    get next(): DataleaksQuestionStep | undefined {
        const stepIndex = this.index;
        if (stepIndex < DataleaksQuestionStep.steps().length - 1) {
            return DataleaksQuestionStep.steps()[stepIndex + 1];
        }
        return undefined;
    }
}
