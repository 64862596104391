import {Action, ActionsMenu, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {DealPlatform} from "../../../../api/model/deal/DealPlatform";
import {DealRecommendationFormatType} from "../../../../api/model/deal/DealRecommendationFormatType";
import {RestrictedContent} from "../../../../common/component/widget";
import {Formatter} from "../../../../common/utils/Formatter";
import {LabelStatus} from "../../index";

type DealPlatformRowProps = {
    platform: DealPlatform;
    onRemove: () => void;
    onDuplicate: () => void;
    onEdit: () => void;
}

const DealPlatformRow: FunctionComponent<DealPlatformRowProps> = ({platform, onEdit, onDuplicate, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    const getRecommendation = (type: DealRecommendationFormatType) => {
        const recommendation = platform.recommendation.find((it) => it.format_type === type);
        if (!recommendation) {
            return "";
        }

        return `${Formatter.formatNumber(recommendation.impression)} imps / ${recommendation.cpm_eur/100}€ / ${recommendation.cpm_usd/100}$`;
    };

    return (
        <TableRow>
            <TableColumn>
                <LabelStatus status={platform.getStatus()}/>
            </TableColumn>
            <TableColumn>{platform.label}</TableColumn>
            <TableColumn>{platform.deal_id}</TableColumn>
            <TableColumn>
                {getRecommendation(DealRecommendationFormatType.BANNER)}
            </TableColumn>
            <TableColumn>
                {getRecommendation(DealRecommendationFormatType.NATIVE)}
            </TableColumn>
            <TableColumn>
                {getRecommendation(DealRecommendationFormatType.VIDEO)}
            </TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <RestrictedContent allowedTo={Authorization.DEALS.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.EDIT.icon, text: textCommon(Action.EDIT.labelKey)}}
                        items={[
                            {label: textCommon(Action.EDIT.labelKey), onClick: onEdit},
                            {label: textCommon(Action.DUPLICATE.labelKey), onClick: onDuplicate},
                            {label: textCommon(Action.REMOVE.labelKey), onClick: onRemove, critical: true, separator: true}
                        ]}
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default DealPlatformRow;
