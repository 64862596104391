import {
    Accordion,
    AccordionItem,
    AccordionItemContent,
    Action,
    ActionMenuItem,
    Box,
    BoxProps,
    Color,
    ContentBlock,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentLayout,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    FormValidationType,
    IconTooltip,
    InputDate,
    InputDateType,
    InputEmail,
    InputPhone,
    InputText,
    InputUrl,
    LayoutColumns,
    LayoutRows,
    Loadable,
    RadioButtons,
    Select,
    SelectAutocomplete,
    Textarea
} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {MainHeader} from "../../common/component/snippet";
import {
    DataleaksAuditQuestion,
    DataleaksScore,
    DataleaksScoreSize,
    DataleaksSeverityFlag,
    ImagePreview,
    MainContentHeader,
    MainContentHeaderAction,
    MainContentHeaderActionsMenu,
    SelectPartner,
    SelectUser
} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import React, {FormEvent, useEffect, useState} from "react";
import {DataleaksAudit} from "../../api/model/dataleaks/audit/DataleaksAudit";
import {useNavigate, useParams} from "react-router-dom";
import useAlert from "../../utils/hooks/useAlert";
import {session} from "../../api/ApiSession";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Authorization} from "../../api/model/account/Authorization";
import {detectChanges} from "../../common/utils/portal";
import {DataleaksAuditCompanyField} from "../../api/model/dataleaks/audit/DataleaksAuditCompanyField";
import {DataleaksAuditField} from "../../api/model/dataleaks/audit/DataleaksAuditField";
import {DataleaksAuditCompany} from "../../api/model/dataleaks/audit/DataleaksAuditCompany";
import {DataleaksAuditContact} from "../../api/model/dataleaks/audit/DataleaksAuditContact";
import {DataleaksAuditContactField} from "../../api/model/dataleaks/audit/DataleaksAuditContactField";
import {DataleaksAuditInfosField} from "../../api/model/dataleaks/audit/DataleaksAuditInfosField";
import {DataleaksAuditInfos} from "../../api/model/dataleaks/audit/DataleaksAuditInfos";
import {DataleaksVersion} from "../../api/model/dataleaks/version/DataleaksVersion";
import {DataleaksQuestion} from "../../api/model/dataleaks/question/DataleaksQuestion";
import {DataleaksMechanism} from "../../api/model/dataleaks/DataleaksMechanism";
import {DataleaksScope} from "../../api/model/dataleaks/DataleaksScope";
import {ConsentManagementPlatform} from "../../api/model/cmp/list/cmp-list/ConsentManagementPlatform";
import {sortItems} from "../../common/utils/helper";
import {ConsentManagementPlatformField} from "../../api/model/cmp/list/cmp-list/ConsentManagementPlatformField";
import {Formatter} from "../../common/utils/Formatter";
import {DataleaksRegulation} from "../../api/model/dataleaks/DataleaksRegulation";
import {DataleaksTrait} from "../../api/model/dataleaks/DataleaksTrait";
import {DataleaksSeverity} from "../../api/model/dataleaks/DataleaksSeverity";
import {DataleaksQuestionGroup} from "../../utils/dataleaks/DataleaksQuestionGroup";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {TranslationCommonFile} from "../../common/utils/constants";
import {Language} from "../../common/api/model/Language";

function DataleaksAuditsDetails() {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);
    const {t: textDataleaksAuditsReport} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS_REPORT);
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);
    const {id} = useParams() as {id: string};
    const alert = useAlert();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);

    const FORM_ID = "form-edit-dataleaks-audit";
    const [audit, setAudit] = useState<DataleaksAudit>(new DataleaksAudit());
    const [initAudit, setInitAudit] = useState<DataleaksAudit>(new DataleaksAudit());
    const [version, setVersion] = useState<DataleaksVersion>(new DataleaksVersion());
    const [questions, setQuestions] = useState<DataleaksQuestion[]>([]);
    const [cmps, setCmps] = useState<ConsentManagementPlatform[]>([]);
    const [isActiveDelete, setActiveDelete] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const audit = await session.restDataleaksAudit.get(+id);
                setInitAudit(new DataleaksAudit(audit));
                setAudit(audit);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("audit", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        if (!audit.id) return;

        (async () => {
            try {
                const version = await session.restDataleaksVersion.get(audit.version_id);
                setVersion(version);

                const questions = await session.restDataleaksQuestion.list();
                const versionQuestions: DataleaksQuestion[] = [];
                version.questions.forEach((it) => {
                    const question = questions.find((item) => item.id === it.question_id);
                    if (question) {
                        versionQuestions.push(question);
                    }
                });
                setQuestions(versionQuestions);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("version", e.message);
                }
            }

            try {
                const cmpList = await session.restCmpList.getCmpList();
                setCmps(sortItems(cmpList.cmps, ConsentManagementPlatformField.NAME));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("CMP list", e.message);
                }
            }
        })();
    }, [audit, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(audit, initAudit));
    }, [audit, initAudit]);

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await session.restDataleaksAudit.update(audit);
            setInitAudit(new DataleaksAudit(audit));
            alert.updateWithSuccess("audit");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("audit", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restDataleaksAudit.delete(audit.id);
            navigate(Module.DATALEAKS.path);
            alert.deleteWithSuccess("audit");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("audit", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    const handleChange = (field: DataleaksAuditField, value: any) => {
        setAudit((prevState) => new DataleaksAudit({...prevState, [field]: value}));
    };

    const handleChangeCompany = (field: DataleaksAuditCompanyField, value: any) => {
        handleChange(DataleaksAuditField.COMPANY, new DataleaksAuditCompany({...audit.company, [field]: value}));
    };

    const handleChangeContact = (field: DataleaksAuditContactField, value: any) => {
        handleChange(DataleaksAuditField.CONTACT, new DataleaksAuditContact({...audit.contact, [field]: value}));
    };

    const handleChangeInfos = (field: DataleaksAuditInfosField, value: any) => {
        let newDataleaksAuditInfos = new DataleaksAuditInfos({...audit.infos, [field]: value});

        if (field === DataleaksAuditInfosField.MECHANISM) {
            switch (value) {
                case DataleaksMechanism.NONE:
                case DataleaksMechanism.BANNER:
                    newDataleaksAuditInfos.scope = "";
                    newDataleaksAuditInfos.tc_string = "";
                    newDataleaksAuditInfos.cmp_id = 0;
                    newDataleaksAuditInfos.cmp_name = "";
                    break;
                case DataleaksMechanism.NON_TCF_CMP:
                    newDataleaksAuditInfos.scope = "";
                    newDataleaksAuditInfos.tc_string = "";
                    newDataleaksAuditInfos.cmp_id = 0;
                    break;
            }
        }

        handleChange(DataleaksAuditField.INFOS, newDataleaksAuditInfos);
    };

    const handleChangeInfosCmp = (id: number = 0, name: string) => {
        handleChange(DataleaksAuditField.INFOS, new DataleaksAuditInfos({
            ...audit.infos,
            [DataleaksAuditInfosField.CMP_ID]: id,
            [DataleaksAuditInfosField.CMP_NAME]: name
        }));
    };

    const handleChangeAnswers = (questionId: number, value: boolean) => {
        const newAnswers = new Map(audit.answers);
        newAnswers.set(questionId, value);
        handleChange(DataleaksAuditField.ANSWERS, newAnswers);
    };

    const buildRegulationScoring = (regulation: DataleaksRegulation) => {
        const score = audit.scoring.regulations.get(regulation) || 0;
        const scoreSmartTags = audit.scoring.regulations_smart_tags.get(regulation) || 0;

        return (
            <div className="dataleaks__scoring__item">
                <span className="h3">{textDataleaksQuestions(`regulation.${regulation}`)}</span>
                <FlexContent direction={FlexContentDirection.COLUMN} cssClass="dataleaks__scoring__item__score">
                    <DataleaksScore value={score} size={DataleaksScoreSize.BIG}/>
                    {scoreSmartTags && scoreSmartTags !== score &&
                        <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                            <DataleaksScore value={scoreSmartTags} size={DataleaksScoreSize.SMALL}/>
                            <span>{textDataleaksAudits("with_smart_tags")}</span>
                        </FlexContent>
                    }
                </FlexContent>
            </div>
        );
    };

    const buildTraitScoring = (trait: DataleaksTrait) => {
        const score = audit.scoring.traits.get(trait) || 0;

        return (
            <div className="dataleaks__scoring__item">
                <span className="h3">{textDataleaksQuestions(`trait.${trait}`)}</span>
                <FlexContent direction={FlexContentDirection.COLUMN} cssClass="dataleaks__scoring__item__score">
                    <DataleaksScore value={score} size={DataleaksScoreSize.BIG}/>
                </FlexContent>
            </div>
        );
    };

    const buildSeveritiesDetails = () => {
        const mapSeverityScoring = new Map<DataleaksSeverity, string>();

        let validAnswersCount = 0;
        Object.values(DataleaksSeverity).forEach((severity) => {
            const severityQuestions = version.questions.filter((it) => it.severity === severity);
            let count = 0;
            severityQuestions.forEach((item) => {
                const question = questions.find((it) => it.id === item.question_id);
                if (question) {
                    const answer = audit.answers.get(question.id);
                    if (answer !== undefined) {
                        if (question.isValidAnswer(answer)) {
                            count++;
                        }
                    }
                }
            });
            mapSeverityScoring.set(severity, `${count} / ${severityQuestions.length}`);
            validAnswersCount += count;
        });

        return (
            <div className="dataleaks__severities">
                {Object.values(DataleaksSeverity).map((severity) =>
                    <div className="dataleaks__severities__item" key={severity}>
                        <DataleaksSeverityFlag severity={severity}/>
                        <span className="dataleaks__severities__item__label">{mapSeverityScoring.get(severity)}</span>
                    </div>
                )}
                <div className="dataleaks__severities__item dataleaks__severities__item--total">
                    <IconTooltip
                        icon={{name: "task_alt", colorIcon: Color.GREEN}}
                        text={textDataleaksAudits("total")}
                    />
                    <span className="dataleaks__severities__item__label">{validAnswersCount} / {version.questions.length}</span>
                </div>
            </div>
        );
    };

    const buildReportMenuItems = () => {
        const items: ActionMenuItem[] = [];
        Language.values().forEach((language) => {
            items.push(
                {
                    label: `${textMain(`language.${language.name}`)} - ${textDataleaksAuditsReport("light_version")}`,
                    onClick: () => window.open(audit.getRouteReport(language, true), "_blank")
                },
                {
                    label: `${textMain(`language.${language.name}`)} - ${textDataleaksAuditsReport("full_version")}`,
                    onClick: () => window.open(audit.getRouteReport(language), "_blank")
                }
            );
        });
        return items;
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.DATALEAKS} element={initAudit.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <MainContentHeaderActionsMenu
                    action={new Action(textDataleaksAuditsReport("title"), {name: "assignment"})}
                    items={buildReportMenuItems()}
                />
                <RestrictedContent allowedTo={Authorization.DATALEAKS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textDataleaksAudits("section.partner_info")}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.COMPANY}.`)} required>
                                                    <InputText
                                                        value={audit.company.name}
                                                        onChange={(value) => handleChangeCompany(DataleaksAuditCompanyField.NAME, value)}
                                                    />
                                                </FieldBlock>
                                                <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.COMPANY}.${DataleaksAuditCompanyField.ADDRESS}`)}>
                                                        <InputText
                                                            value={audit.company.address}
                                                            onChange={(value) => handleChangeCompany(DataleaksAuditCompanyField.ADDRESS, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.COMPANY}.${DataleaksAuditCompanyField.SIREN}`)}>
                                                        <InputText
                                                            value={audit.company.siren}
                                                            onChange={(value) => handleChangeCompany(DataleaksAuditCompanyField.SIREN, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.COMPANY}.${DataleaksAuditCompanyField.LOGO}`)}>
                                                        <InputUrl
                                                            value={audit.company.logo}
                                                            onChange={(url) => handleChangeCompany(DataleaksAuditCompanyField.LOGO, url)}
                                                        />
                                                    </FieldBlock>
                                                    <ImagePreview url={audit.company.logo}/>
                                                </FormLayoutColumns>
                                                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.PARTNER_ID}`)}>
                                                    <SelectPartner
                                                        value={audit.partner_id}
                                                        onChange={(partner) => handleChange(DataleaksAuditField.PARTNER_ID, partner?.id)}
                                                    />
                                                </FieldBlock>
                                                <FormLayoutSeparator/>
                                                <FormLayoutTitle>{textDataleaksAudits(`field.${DataleaksAuditField.CONTACT}.`)}</FormLayoutTitle>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.CONTACT}.${DataleaksAuditContactField.FIRST_NAME}`)}>
                                                        <InputText
                                                            value={audit.contact.first_name}
                                                            onChange={(value) => handleChangeContact(DataleaksAuditContactField.FIRST_NAME, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.CONTACT}.${DataleaksAuditContactField.LAST_NAME}`)}>
                                                        <InputText
                                                            value={audit.contact.last_name}
                                                            onChange={(value) => handleChangeContact(DataleaksAuditContactField.LAST_NAME, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.CONTACT}.${DataleaksAuditContactField.POSITION}`)}>
                                                        <InputText
                                                            value={audit.contact.position}
                                                            onChange={(value) => handleChangeContact(DataleaksAuditContactField.POSITION, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.CONTACT}.${DataleaksAuditContactField.EMAIL}`)}>
                                                        <InputEmail
                                                            value={audit.contact.email}
                                                            onChange={(value) => handleChangeContact(DataleaksAuditContactField.EMAIL, value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.CONTACT}.${DataleaksAuditContactField.PHONE}`)}>
                                                        <InputPhone
                                                            value={audit.contact.phone}
                                                            onChange={(value) => handleChangeContact(DataleaksAuditContactField.PHONE, value || "")}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textDataleaksAudits("section.website_info")}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.DOMAIN}`)} required>
                                                    <InputText
                                                        value={audit.domain}
                                                        onChange={(value) => handleChange(DataleaksAuditField.DOMAIN, value)}
                                                        disabled
                                                    />
                                                </FieldBlock>
                                                <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.MECHANISM}`)}>
                                                        <RadioButtons
                                                            id={DataleaksAuditInfosField.MECHANISM}
                                                            value={audit.infos.mechanism}
                                                            options={Object.values(DataleaksMechanism).map((it) => ({value: it, label: textDataleaksAudits(`mechanism.${it}`)}))}
                                                            onChange={(value) => handleChangeInfos(DataleaksAuditInfosField.MECHANISM, value)}
                                                        />
                                                    </FieldBlock>
                                                    {audit.infos.mechanism === DataleaksMechanism.TCF_CMP &&
                                                        <Box>
                                                            <FormLayoutRows>
                                                                <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                                    <FieldBlock
                                                                        label={textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.CMP_ID}`)}
                                                                    >
                                                                        <SelectAutocomplete
                                                                            value={audit.infos.cmp_id}
                                                                            options={cmps.map((it) => ({value: it.id, label: `${it.id} - ${it.name}`, cmp: it}))}
                                                                            onChange={(option) => handleChangeInfosCmp(option?.cmp?.id, option?.cmp?.name)}
                                                                            clearable
                                                                        />
                                                                    </FieldBlock>
                                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.SCOPE}`)}>
                                                                        <Select
                                                                            value={audit.infos.scope}
                                                                            options={Object.values(DataleaksScope).map((it) => ({value: it, label: textDataleaksAudits(`scope.${it}`)}))}
                                                                            onChange={(option) => handleChangeInfos(DataleaksAuditInfosField.SCOPE, option?.value as DataleaksScope)}
                                                                            clearable
                                                                        />
                                                                    </FieldBlock>
                                                                </FormLayoutColumns>
                                                                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.TC_STRING}`)}>
                                                                    <InputText
                                                                        value={audit.infos.tc_string}
                                                                        onChange={(value) => handleChangeInfos(DataleaksAuditInfosField.TC_STRING, value)}
                                                                    />
                                                                </FieldBlock>
                                                            </FormLayoutRows>
                                                        </Box>
                                                    }
                                                    {audit.infos.mechanism === DataleaksMechanism.NON_TCF_CMP &&
                                                        <Box>
                                                            <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.CMP_NAME}`)}>
                                                                <InputText
                                                                    value={audit.infos.cmp_name}
                                                                    onChange={(value) => handleChangeInfosCmp(0, value)}
                                                                />
                                                            </FieldBlock>
                                                        </Box>
                                                    }
                                                </FormLayoutRows>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                    <ContentBlock header={{title: {label: textDataleaksAudits("section.audit_info")}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.VERSION_ID}`)}>
                                                        <Select
                                                            value={audit.version_id}
                                                            options={[{value: version.id, label: version.label}]}
                                                            onChange={() => {
                                                            }}
                                                            disabled
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.USER_ID}`)}>
                                                        <SelectUser
                                                            value={audit.user_id}
                                                            onChange={(user) => handleChange(DataleaksAuditField.USER_ID, user?.id)}
                                                            disabled
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.NOTIFICATION_DATE}`)}>
                                                        <InputDate
                                                            type={InputDateType.DATETIME_LOCAL}
                                                            value={audit.notification_date ? Formatter.convertUTCToDate(audit.notification_date).format(Formatter.API_DATETIME_FORMAT) : undefined}
                                                            onChange={(value) => handleChange(DataleaksAuditField.NOTIFICATION_DATE, value ? Formatter.convertUTCToDate(value).format(Formatter.API_DATETIME_FORMAT) : undefined)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.COMMENT}`)}>
                                                    <Textarea
                                                        value={audit.comment}
                                                        rows={3}
                                                        onChange={(value) => handleChange(DataleaksAuditField.COMMENT, value)}
                                                        isExpandable
                                                    />
                                                </FieldBlock>
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                        </LayoutColumns>
                        <Loadable loading={isLoading}>
                            <ContentBlock header={{title: {label: textDataleaksAudits("section.scoring")}}}>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutRows>
                                        <div className="dataleaks__scoring">
                                            {buildRegulationScoring(DataleaksRegulation.GDPR)}
                                            {buildRegulationScoring(DataleaksRegulation.EPRIVACY)}
                                        </div>
                                        <FieldBlock label={textDataleaksAudits("section.scoring_by_trait")}>
                                            <div className="dataleaks__scoring">
                                                {Object.values(DataleaksTrait).map((trait) =>
                                                    <React.Fragment key={trait}>
                                                        {buildTraitScoring(trait)}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            </ContentBlock>
                            <ContentBlock header={{title: {label: textDataleaksAudits("section.questions")}, actions: buildSeveritiesDetails()}}>
                                <Accordion>
                                    {DataleaksQuestionGroup.values().map((group) =>
                                        <AccordionItem key={group.label} heading={textDataleaksAudits(group.label)} startOpen>
                                            <AccordionItemContent>
                                                <FormLayoutRows>
                                                    {version.questions.filter((it) => group.questions.includes(it.question_id)).map((item) =>
                                                        <DataleaksAuditQuestion
                                                            key={item.question_id}
                                                            item={item}
                                                            question={questions.find((it) => it.id === item.question_id) || new DataleaksQuestion()}
                                                            answer={audit.answers.get(item.question_id)}
                                                            onChange={handleChangeAnswers}
                                                        />
                                                    )}
                                                </FormLayoutRows>
                                            </AccordionItemContent>
                                        </AccordionItem>
                                    )}
                                </Accordion>
                            </ContentBlock>
                        </Loadable>
                    </LayoutRows>
                </Form>
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="audit"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default DataleaksAuditsDetails;
