import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {CustomerCountingSegmentFilterSegment} from "./CustomerCountingSegmentFilterSegment";
import {CustomerCountingSegmentFilterType} from "./CustomerCountingSegmentFilterType";

export class CustomerCountingSegmentFilter extends ApiModel {
    segment: CustomerCountingSegmentFilterSegment[] = [];
    segment_modeling: CustomerCountingSegmentFilterSegment[] = [];
    private _data_type: string[] | null = [];
    private _tiers1: string[] | null = [];
    private _tiers2: string[] | null = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get data_type(): string[] {
        return this._data_type ? this._data_type : [];
    }

    set data_type(dataType: string[]) {
        this._data_type = !!dataType ? dataType : null;
    }

    get tiers1(): string[] {
        return this._tiers1 ? this._tiers1 : [];
    }

    set tiers1(tiers1: string[]) {
        this._tiers1 = !!tiers1.length ? tiers1 : null;
    }

    get tiers2(): string[] {
        return this._tiers2 ? this._tiers2 : [];
    }

    set tiers2(tiers2: string[]) {
        this._tiers2 = !!tiers2.length ? tiers2 : null;
    }

    getType(): CustomerCountingSegmentFilterType {
        if (!!this.data_type?.length) {
            return CustomerCountingSegmentFilterType.SEGMENT_TYPE;
        }
        if (!!this.segment.length || !!this.segment_modeling.length) {
            return CustomerCountingSegmentFilterType.SEGMENT;
        }
        return CustomerCountingSegmentFilterType.NONE;
    }

    getSegmentIds(): number[] {
        const segmentIds = this.segment.map(({id}) => id);
        const segmentModelingIds = this.segment_modeling.map(({id}) => id);
        return [...segmentIds, ...segmentModelingIds];
    }

    getJsonParameters(): {} {
        return {
            segment: this.segment,
            data_type: this.data_type,
            tiers1: this.tiers1,
            tiers2: this.tiers2
        };
    }
}
