import {Box, BoxRadius, ContentBlock} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";

type ChangelogItem = {
    date: string;
    items: string[];
}

const CHANGELOG_ITEMS: ChangelogItem[] = [
    {
        date: "15/11/2024",
        items: [
            "CMP Configurations : Modification des options de réaffichage de la CMP",
            "CMP Configurations : Ajout de la configuration Utiq",
            "CMP Configurations : Correction de l'affichage de la preview sur iPhone",
            "CMP Webinars : Correction sur l'édition de la FAQ",
            "Revenues Imports : Correction de l'import des revenus",
            "Authorizations : Amélioration de la gestion des autorisations utilisateurs"
        ]
    },
    {
        date: "12/11/2024",
        items: [
            "CMP Configurations : Correction d'un bug sur l'édition des textes personnalisés",
            "CMP Configurations : Désactivation du scope Consent Framework",
            "Deals : Amélioration de l'édition des targeting origins",
            "Notifications : Amélioration de la création des dispatches",
            "Partners : Ajout d'un champ \"VAT Type\"",
            "Revenues Imports : Passage du système d'import en asynchrone",
            "sGTM Containers : Ajout d'un champ \"Container hosting\"",
            "Tags : Amélioration de l'édition des audiences"
        ]
    },
    {
        date: "04/11/2024",
        items: [
            "ADS : Amélioration de l'édition des Shared Taxonomy et Global Taxonomy",
            "Partners : Ajout d'un champ \"AWS identifier\"",
            "UI/UX : Amélioration de l'édition des liens textuels",
            "UI/UX : Validation des formulaires sur les pages d'édition (en cours)"
        ]
    }
];

const Changelog: FunctionComponent = () => {
    const {t: textHome} = useTranslation(TranslationPortalFile.HOME);

    return (
        <ContentBlock header={{title: {label: textHome("changelog.title").toUpperCase()}}} cssClass={"changelog"}>
            <Box radius={BoxRadius.LG} cssClass="changelog__content">
                {CHANGELOG_ITEMS.map((item) =>
                    <div key={item.date} className="changelog__item">
                        <div className="changelog__item__date">{item.date}</div>
                        <ul className="changelog__item__content">
                            {item.items.map((it) =>
                                <li key={it} dangerouslySetInnerHTML={{__html: it}}/>
                            )}
                        </ul>
                    </div>
                )}
            </Box>
        </ContentBlock>
    );
};

export default Changelog;
