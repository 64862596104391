import {Rest} from "../../common/api/rest/Rest";
import {Formatter} from "../../common/utils/Formatter";
import {PartnerLicense} from "../model/partner/license/PartnerLicense";
import {PartnerLicenseDetailsResponse} from "../model/partner/license/PartnerLicenseDetailsResponse";
import {PartnerLicenseLinkPartner} from "../model/partner/license/PartnerLicenseLinkPartner";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";

export class RestPartnerLicense extends Rest implements RestService<PartnerLicense> {
    rootPath = "/console-api/partner-license";

    search(params: any): Promise<SearchResult<PartnerLicense>> {
        return new Promise<SearchResult<PartnerLicense>>(() => {}); // TODO Implement route
    }

    list(): Promise<PartnerLicense[]> {
        return this._client.get(`${this.rootPath}`, PartnerLicense) as Promise<PartnerLicense[]>;
    }

    create(license: PartnerLicense): Promise<PartnerLicense> {
        return this._client.post(`${this.rootPath}`, license, PartnerLicense) as Promise<PartnerLicense>;
    }

    get(licenseId: number): Promise<PartnerLicense> {
        return this._client.get(`${this.rootPath}/${licenseId}`, PartnerLicense) as Promise<PartnerLicense>;
    }

    getDetails(licenseId: number): Promise<PartnerLicenseDetailsResponse> {
        return this._client.get(`${this.rootPath}/${licenseId}/details`, PartnerLicenseDetailsResponse) as Promise<PartnerLicenseDetailsResponse>;
    }

    update(license: PartnerLicense): Promise<PartnerLicense> {
        return this._client.put(`${this.rootPath}/${license.id}`, license, PartnerLicense) as Promise<PartnerLicense>;
    }

    delete(licenseId: number) {
        // TODO Implement route
    }

    getLicensesForPartner(partnerId: number): Promise<PartnerLicenseLinkPartner[]> {
        return this._client.get(`${this.rootPath}/partner/${partnerId}`, PartnerLicenseLinkPartner) as Promise<PartnerLicenseLinkPartner[]>;
    }

    addPartnerLink(link: PartnerLicenseLinkPartner): Promise<PartnerLicenseLinkPartner> {
        let path = `${this.rootPath}/${link.license.id}/partner/${link.partner_id}?activate=${link.active}`;
        if (link.active && link.expires_at) {
            path += `&expires_at=${Formatter.convertDateToUTC(link.expires_at)}`;
        }
        return this._client.post(path, link, PartnerLicenseLinkPartner) as Promise<PartnerLicenseLinkPartner>;
    }

    activate(licenseId: number, partnerId: number, expiresAt?: string): Promise<PartnerLicenseLinkPartner> {
        let path = `${this.rootPath}/${licenseId}/partner/${partnerId}/activate`;
        if (expiresAt) {
            path += `?expires_at=${Formatter.convertDateToUTC(expiresAt)}`;
        }
        return this._client.post(path, undefined, PartnerLicenseLinkPartner) as Promise<PartnerLicenseLinkPartner>;
    }

    deactivate(licenseId: number, partnerId: number): Promise<PartnerLicenseLinkPartner> {
        return this._client.post(`${this.rootPath}/${licenseId}/partner/${partnerId}/deactivate`, undefined, PartnerLicenseLinkPartner) as Promise<PartnerLicenseLinkPartner>;
    }
}

