export class DataleaksAuditStep {
    static PARTNER: DataleaksAuditStep = new DataleaksAuditStep("PARTNER");
    static CONTACT: DataleaksAuditStep = new DataleaksAuditStep("CONTACT");
    static WEBSITE: DataleaksAuditStep = new DataleaksAuditStep("WEBSITE");
    static QUESTIONS: DataleaksAuditStep = new DataleaksAuditStep("QUESTIONS");

    name: string = "";

    private constructor(name: string) {
        this.name = name;
    }

    static steps(): DataleaksAuditStep[] {
        return [
            DataleaksAuditStep.PARTNER,
            DataleaksAuditStep.CONTACT,
            DataleaksAuditStep.WEBSITE,
            DataleaksAuditStep.QUESTIONS
        ];
    }

    static firstStep(): DataleaksAuditStep {
        return this.steps()[0];
    }

    static lastStep(): DataleaksAuditStep {
        return this.steps()[this.steps().length - 1];
    }

    get index(): number {
        return DataleaksAuditStep.steps().findIndex((it) => it.name === this.name);
    }

    get previous(): DataleaksAuditStep | undefined {
        const stepIndex = this.index;
        if (stepIndex > 0) {
            return DataleaksAuditStep.steps()[stepIndex - 1];
        }
        return undefined;
    }

    get next(): DataleaksAuditStep | undefined {
        const stepIndex = this.index;
        if (stepIndex < DataleaksAuditStep.steps().length - 1) {
            return DataleaksAuditStep.steps()[stepIndex + 1];
        }
        return undefined;
    }
}
