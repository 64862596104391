import {Box, BoxRadius, BoxSpacing, FlexContent, FlexContentDirection, FlexContentSpacing, ToggleSwitch} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CmpConfigCookieWallField} from "../../../api/model/cmp/config/CmpConfigCookieWallField";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettings} from "../../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {TranslationPortalFile} from "../../../utils/constants";
import {CmpConfigurationExternal} from "../index";
import {CmpConfigCookieWall} from "../../../api/model/cmp/config/CmpConfigCookieWall";

type CmpConfigurationSettingsAddOnsProps = {
    settings: CmpConfigSettings;
    onChange: (settings: CmpConfigSettings) => void;
};

const CmpConfigurationSettingsAddOns: FunctionComponent<CmpConfigurationSettingsAddOnsProps> = ({settings, onChange}) => {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);

    const handleChangeSettings = (field: CmpConfigSettingsField, value: any) => {
        onChange(new CmpConfigSettings({...settings, [field]: value}));
    };

    const handleChangeSettingsCookieWall = (field: CmpConfigCookieWallField, value: any) => {
        handleChangeSettings(CmpConfigSettingsField.COOKIEWALL, new CmpConfigCookieWall({...settings.cookieWall, [field]: value}));
    };

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            spacing={FlexContentSpacing.SMALL}
            cssClass="cmp-add-ons"
            allowWrap
        >
            <CmpConfigurationExternal
                external={settings.external}
                onChangeExternal={(external) => handleChangeSettings(CmpConfigSettingsField.EXTERNAL, external)}
            />
            <Box
                radius={BoxRadius.MD}
                spacing={BoxSpacing.MEDIUM}
                cssClass="cmp-add-ons__item"
            >
                <div className="cmp-add-ons__item__header">
                    <span className="h3">Cookie Wall</span>
                    <div className="cmp-add-ons__item__image-container">
                        <img src="/images/cmp/config/cookie.png" alt=""/>
                    </div>
                </div>
                <div className="cmp-add-ons__item__fields">
                    <ToggleSwitch
                        name={CmpConfigSettingsField.COOKIEWALL}
                        label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.COOKIEWALL}.${CmpConfigCookieWallField.ACTIVE}`)}
                        checked={settings.cookieWall.active}
                        onChange={(value) => handleChangeSettingsCookieWall(CmpConfigCookieWallField.ACTIVE, value)}
                    />
                </div>
            </Box>
            <Box
                radius={BoxRadius.MD}
                spacing={BoxSpacing.MEDIUM}
                cssClass="cmp-add-ons__item"
            >
                <div className="cmp-add-ons__item__header">
                    <span className="h3">Transparency & Consent Framework</span>
                    <div className="cmp-add-ons__item__image-container">
                        <img src="/images/cmp/config/logo-iab-tcf.png" alt=""/>
                    </div>
                </div>
                <div className="cmp-add-ons__item__fields">
                    <ToggleSwitch
                        name={CmpConfigSettingsField.DISABLE_TCF}
                        label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.DISABLE_TCF}`)}
                        checked={settings.disableTcf}
                        onChange={(value) => handleChangeSettings(CmpConfigSettingsField.DISABLE_TCF, value)}
                    />
                </div>
            </Box>
        </FlexContent>
    );
};

export default CmpConfigurationSettingsAddOns;
