import {FC} from "react";
import {FlexContent, FlexContentDirection, FlexContentSpacing, FormLayoutRows, InputText, Textarea} from "@sirdata/ui-lib";

type CmpSirdataListTranslationItemFieldProps = {
    value: string;
    onChange: (value: any) => void;
    placeholder?: string;
}

type CmpSirdataListTranslationItemProps = {
    id: number;
    name: CmpSirdataListTranslationItemFieldProps;
    description: CmpSirdataListTranslationItemFieldProps;
};

const CmpSirdataListTranslationItem: FC<CmpSirdataListTranslationItemProps> = ({id, name, description}) => {
    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            spacing={FlexContentSpacing.XSMALL}
            cssClass="sirdata-list__translations__item"
        >
            <span className="sirdata-list__translations__item__chip">{id}</span>
            <FormLayoutRows spacing={FlexContentSpacing.SMALL} cssClass="sirdata-list__translations__item__form">
                <InputText
                    value={name.value}
                    placeholder={name.placeholder}
                    onChange={name.onChange}
                />
                <Textarea
                    value={description.value}
                    placeholder={description.placeholder}
                    onChange={description.onChange}
                    rows={5}
                    isExpandable
                />
            </FormLayoutRows>
        </FlexContent>
    );
};

export default CmpSirdataListTranslationItem;
