export enum DistributionPlatformType {
    DMP = "DMP",
    DSP = "DSP",
    SSP = "SSP",
    CURATION = "Curation",
}

export const DISTRIBUTION_PLATFORM_TYPES: DistributionPlatformType[] = [
    DistributionPlatformType.DMP,
    DistributionPlatformType.DSP,
    DistributionPlatformType.SSP,
    DistributionPlatformType.CURATION
];