import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {NotificationItem} from "../../../../api/model/notifications/NotificationItem";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Action, ActionsMenu, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {Authorization} from "../../../../api/model/account/Authorization";
import {RestrictedContent} from "../../../../common/component/widget";
import {Formatter} from "../../../../common/utils/Formatter";
import {LabelPartner, LabelStatus, LabelUser} from "../../index";
import {Module} from "../../../../utils/Module";

type NotificationItemRowProps = {
    item: NotificationItem;
    onDelete: () => void;
}

const NotificationItemRow: FunctionComponent<NotificationItemRowProps> = ({item, onDelete}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);

    return (
        <TableRow>
            <TableColumn>
                <LabelStatus status={item.getStatus()}/>
            </TableColumn>
            <TableColumn>{item.dispatch_date ? Formatter.formatUTCDate(item.dispatch_date, Formatter.DATETIME_FORMAT) : "-"}</TableColumn>
            {!!item.user_id ?
                <>
                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                        <LabelUser user={item.user_id}/>
                        <IconTooltip
                            icon={Action.OPEN.icon}
                            text={textNotifications("actions.view_user")}
                            onClick={() => window.open(Module.USERS.buildRoute(item.user_id!), "_blank")}
                        />
                    </TableColumn>
                </> :
                <>
                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                        <LabelPartner partner={item.partner_id} withLink/>
                    </TableColumn>
                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{item.partner_account_id}</TableColumn>
                </>
            }
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <RestrictedContent allowedTo={Authorization.NOTIFICATIONS.update}>
                    <ActionsMenu
                        iconTooltip={{icon: Action.EDIT.icon, text: textCommon(Action.EDIT.labelKey)}}
                        items={[
                            {label: textCommon(Action.DELETE.labelKey), onClick: onDelete, critical: true, separator: true}
                        ]}
                    />
                </RestrictedContent>
            </TableActionColumn>
        </TableRow>
    );
};

export default NotificationItemRow;
