import moment from "moment";
import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Status} from "../../../../utils/Status";
import {ContentElement} from "../../../interface/ContentElement";
import {DistributionField} from "./DistributionField";
import {DistributionProperties} from "./DistributionProperties";
import {Module} from "../../../../utils/Module";

type DistributionLinkCount = {
    category: number;
    segment: number;
};

export class Distribution extends ApiModel {
    active: boolean = true;
    name: string = "";
    label: string = "";
    on_creation: boolean = false;
    visible_to_audience: boolean = false;
    used_for_category: boolean = false;
    image_id?: number;
    platform_name?: string;
    destination_name?: string;
    properties: DistributionProperties = new DistributionProperties();
    readonly updated_at: string = "";
    readonly link_count: DistributionLinkCount = {category: 0, segment: 0};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                switch (key) {
                    case DistributionField.PROPERTIES:
                        this[key] = new DistributionProperties(o[key]);
                        break;
                    default:
                        this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            active: this.active,
            name: this.name,
            label: this.label,
            on_creation: this.on_creation,
            visible_to_audience: this.visible_to_audience,
            used_for_category: this.used_for_category,
            image_id: this.image_id,
            platform_name: this.platform_name,
            properties: this.properties.getJsonParameters()
        };
    }

    getRoute(): string {
        return Module.DISTRIBUTIONS.buildRoute(this.name);
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }

    toContentElement(): ContentElement {
        const element = new ContentElement("", `${this.label || this.name}`);
        element.status = this.getStatus();
        element.lastUpdated = moment().utcOffset(this.updated_at).toDate();
        return element;
    }
}
