import {ApiModel} from "../../common/api/model/ApiModel";
import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {CategorizerConfigEntry} from "../model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryParams} from "../model/categorizer/CategorizerConfigEntryParams";
import {CategorizerConfig} from "../model/categorizer/config/CategorizerConfig";

export class RestCategorizerEntry extends Rest implements RestService<CategorizerConfigEntry<CategorizerConfig>> {
    rootPath = "/console-api/categorizer/entry";

    search(params: ApiModel): Promise<SearchResult<CategorizerConfigEntry<CategorizerConfig>>> {
        return new Promise<SearchResult<CategorizerConfigEntry<CategorizerConfig>>>(() => {});
    }

    searchEntries(params: CategorizerConfigEntryParams): Promise<CategorizerConfigEntry<CategorizerConfig>[]> {
        return this._client.get(`${this.rootPath}/search`, CategorizerConfigEntry, {queryParams: params}) as Promise<CategorizerConfigEntry<CategorizerConfig>[]>;
    }

    get(id: number): Promise<CategorizerConfigEntry<CategorizerConfig>> {
        return this._client.get(`${this.rootPath}/${id}`, CategorizerConfigEntry) as Promise<CategorizerConfigEntry<CategorizerConfig>>;
    }

    create(entry: CategorizerConfigEntry<CategorizerConfig>): Promise<CategorizerConfigEntry<CategorizerConfig>> {
        return this._client.post(`${this.rootPath}`, entry, CategorizerConfigEntry);
    }

    update(entry: CategorizerConfigEntry<CategorizerConfig>): Promise<CategorizerConfigEntry<CategorizerConfig>> {
        return this._client.put(`${this.rootPath}/${entry.id}`, entry, CategorizerConfigEntry);
    }

    delete(entry: CategorizerConfigEntry<CategorizerConfig>) {
        return this._client.delete(`${this.rootPath}/${entry.id}`);
    }
}
