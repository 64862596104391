import {ApiModel} from "../../../../common/api/model/ApiModel";

export class TaxonomyTagLinkCategoryGroup extends ApiModel {
    category_group_id: number = 0;
    tag_name: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
