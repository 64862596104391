export class RevenueFileFormat {
    static EXCEL: RevenueFileFormat = new RevenueFileFormat("EXCEL", ".xlsx");
    static CSV: RevenueFileFormat = new RevenueFileFormat("CSV", ".csv");

    name: string;
    extension: string;

    private constructor(name: string, extension: string) {
        this.name = name;
        this.extension = extension;
    }

    static values(): RevenueFileFormat[] {
        return [
            RevenueFileFormat.EXCEL,
            RevenueFileFormat.CSV
        ];
    }

    static getByName(name: string): RevenueFileFormat | undefined {
        return this.values().find((it) => it.name === name);
    }
}
