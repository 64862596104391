export enum PartnerOrganizationGroupField {
    ID = "id",
    TYPE = "type",
    NAME = "name",
    DESCRIPTION = "description",
    OWNER_ID = "owner_id",
    DOMAINS = "domains",
    ORGANIZATIONS = "organizations",
    CREATED_AT = "created_at",
    UPDATED_AT = "updated_at"
}
