import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DataleaksMechanism} from "../DataleaksMechanism";
import {DataleaksVersionScoringDetails} from "./DataleaksVersionScoringDetails";

export class DataleaksVersionScoringMechanism extends ApiModel {
    total_audits: number = 0;
    _mechanisms: Map<DataleaksMechanism, DataleaksVersionScoringDetails> = new Map<DataleaksMechanism, DataleaksVersionScoringDetails>();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get mechanisms(): Map<DataleaksMechanism, DataleaksVersionScoringDetails> {
        return this._mechanisms;
    }

    set mechanisms(mechanisms: Map<DataleaksMechanism, DataleaksVersionScoringDetails>) {
        if (Object.entries(mechanisms).length) {
            const values = new Map<DataleaksMechanism, DataleaksVersionScoringDetails>();
            Object.entries(mechanisms).forEach(([mechanism, details]) => {
                values.set(mechanism as DataleaksMechanism, new DataleaksVersionScoringDetails(details));
            });
            this._mechanisms = values;
        } else {
            this._mechanisms = new Map<DataleaksMechanism, DataleaksVersionScoringDetails>();
        }
    }
}
