import {Action} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import ModalConfirmMessage, {ModalConfirmMessageProps} from "./ModalConfirmMessage";

type ModalConfirmRemoveProps = Omit<ModalConfirmMessageProps, "message" | "confirmAction"> & {
    entity: string;
};

const ModalConfirmRemove: FunctionComponent<ModalConfirmRemoveProps> = ({entity, ...props}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);

    return (
        <ModalConfirmMessage
            message={t("message.confirm_remove", {entity: entity})}
            confirmAction={Action.REMOVE}
            {...props}
        />
    );
};

export default ModalConfirmRemove;
