import {Action, ContentBlock, ContentBlockAction, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Authorization} from "../../../api/model/account/Authorization";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigEntryGroup} from "../../../api/model/categorizer/CategorizerConfigEntryGroup";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigLinks} from "../../../api/model/categorizer/config/CategorizerConfigLinks";
import {CategorizerConfigLinksField} from "../../../api/model/categorizer/config/CategorizerConfigLinksField";
import {RestrictedContent} from "../../../common/component/widget";
import {CategorizerConfigEntriesProps} from "../../../utils/categorizer/CategorizerConfigEntriesProps";
import {TranslationPortalFile} from "../../../utils/constants";
import ModalEditCategorizerConfigLinks from "../../modal/categorizer/ModalEditCategorizerConfigLinks";
import ModalAddCategorizerConfigLinks from "../../modal/categorizer/ModalAddCategorizerConfigLinks";
import {CategorizerConfigEntryGroupField} from "../../../api/model/categorizer/CategorizerConfigEntryGroupField";
import {CategorizerConfigLinksRow} from "../../snippet";

const CategorizerConfigLinksEntries: FC<CategorizerConfigEntriesProps<CategorizerConfigLinks>> = ({configs, params, onSubmit, onDelete, editable}) => {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const navigate = useNavigate();
    const [activeEditCategorizerConfigEntry, setActiveEditCategorizerConfigEntry] = useState<CategorizerConfigEntry<CategorizerConfigLinks>>();
    const [isShowModalAddCategorizerConfigLinks, setShowModalAddCategorizerConfigLinks] = useState(false);

    const hasConfig = (): boolean => {
        return configs.some((config) => config.match(params));
    };

    const handleClickLinksRow = (entry: CategorizerConfigEntry<CategorizerConfigLinks>) => {
        if (entry.match(params) || editable) {
            setActiveEditCategorizerConfigEntry(entry);
            return;
        }
        const group = new CategorizerConfigEntryGroup({
            [CategorizerConfigEntryGroupField.DOMAIN]: entry.domain,
            [CategorizerConfigEntryGroupField.HOST]: entry.host,
            [CategorizerConfigEntryGroupField.PATH]: entry.path
        });
        navigate(group.getRoute());
    };

    return (
        <ContentBlock
            header={{
                title: {label: textCategorizer(`config_type.${CategorizerConfigType.LINKS.name}`)},
                actions: [
                    !hasConfig() &&
                    <RestrictedContent key="add_config_links" allowedTo={Authorization.CATEGORIZER.update}>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddCategorizerConfigLinks(true)}/>
                    </RestrictedContent>
                ]
            }}
        >
            <Table
                columns={[
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.DOMAIN}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.HOST}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {width: 15, label: textCategorizer(`field.${CategorizerConfigEntryField.PATH}`), styles: TableColumnStyle.FIXED_WIDTH},
                    {label: textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${CategorizerConfigLinksField.CSS_QUERY}`), styles: TableColumnStyle.ALIGN_CENTER},
                    {width: 2}
                ]}
            >
                {configs.map((entry) =>
                    <CategorizerConfigLinksRow
                        key={entry.id}
                        entry={entry}
                        isEditable={entry.match(params) || !!editable}
                        onClick={() => handleClickLinksRow(entry)}
                        onDelete={() => onDelete(entry)}
                    />
                )}
            </Table>
            <ModalAddCategorizerConfigLinks
                params={params}
                active={isShowModalAddCategorizerConfigLinks}
                onSubmit={() => {
                    onSubmit();
                    setShowModalAddCategorizerConfigLinks(false);
                }}
                onClose={() => setShowModalAddCategorizerConfigLinks(false)}
            />
            <ModalEditCategorizerConfigLinks
                initEntry={activeEditCategorizerConfigEntry}
                onSubmit={() => {
                    onSubmit();
                    setActiveEditCategorizerConfigEntry(undefined);
                }}
                onClose={() => setActiveEditCategorizerConfigEntry(undefined)}
            />
        </ContentBlock>
    );
};

export default CategorizerConfigLinksEntries;
