import {Rest} from "../../common/api/rest/Rest";
import {SegmentLinkExternal} from "../model/audience/segment/SegmentLinkExternal";

export class RestSegmentLinkExternal extends Rest {
    rootPath = "/console-api/segment";

    list(segmentId: number): Promise<SegmentLinkExternal[]> {
        return this._client.get(`${this.rootPath}/${segmentId}/external`, SegmentLinkExternal) as Promise<SegmentLinkExternal[]>;
    }

    update(segmentId: number, links: SegmentLinkExternal[]): Promise<SegmentLinkExternal[]> {
        return this._client.post(`${this.rootPath}/${segmentId}/external`, links, SegmentLinkExternal) as Promise<SegmentLinkExternal[]>;
    }
}
