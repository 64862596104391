import {ApiModel} from "../../../../common/api/model/ApiModel";

export class PartnerDomainTargetServices extends ApiModel {
    elements: string[] = [];

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            this.elements = Object.keys(o);
        }
    }
}