import {PAGE_SIZE} from "../../../../../utils/constants";
import {SearchParams} from "../../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../../interface/SearchQuery";
import {CustomerOrderSortOrder} from "./CustomerOrderSortOrder";
import {OrderSortType} from "./CustomerOrderSortType";

export enum CustomerOrderSearchParamsField  {
    STORAGE_ID = "storage_id",
    FILTER_ID = "filter_id",
    START_DATE = "start_date",
    END_DATE = "end_date",
}

export class CustomerOrderSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    partner_id: number | undefined;
    storage_id: number | undefined;
    filter_id: number | undefined;
    private _status: string | undefined;
    private _start_date: string | undefined;
    private _end_date: string | undefined;

    constructor(o?: {}) {
        super();
        this.load(o);
    }

    withSearchParams(params: SearchParams): CustomerOrderSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(OrderSortType.CREATION, true) as CustomerOrderSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS) || "";
        newQuery.partner_id = params.getNumber(SearchParamsField.PARTNER);
        newQuery.storage_id = params.getNumber(CustomerOrderSearchParamsField.STORAGE_ID);
        newQuery.filter_id = params.getNumber(CustomerOrderSearchParamsField.FILTER_ID);
        newQuery.start_date = params.getString(CustomerOrderSearchParamsField.START_DATE) || "";
        newQuery.end_date = params.getString(CustomerOrderSearchParamsField.END_DATE) || "";
        return newQuery;
    }

    get status(): string {
        return this._status ? this._status : "";
    }

    set status(status: string) {
        this._status = !!status ? status : undefined;
    }

    get start_date(): string {
        return this._start_date ? this._start_date : "";
    }

    set start_date(start_date: string) {
        this._start_date = !!start_date ? start_date : undefined;
    }

    get end_date(): string {
        return this._end_date ? this._end_date : "";
    }

    set end_date(end_date: string) {
        this._end_date = !!end_date ? end_date : undefined;
    }

    isActive(): boolean {
        return !!(this.partner_id || this.storage_id || this.filter_id || this._status || this._start_date || this._end_date);
    }

    getJsonParameters(): {} {
        return {
            partner_id: this.partner_id,
            storage_id: this.storage_id,
            filter_id: this.filter_id,
            size: this.size,
            page: this.page,
            order_by: this.sortBy,
            sort_order: this.reverseOrder ? CustomerOrderSortOrder.DESCENDANT : CustomerOrderSortOrder.ASCENDANT,
            status: this._status,
            search: this.query,
            start_date: this._start_date,
            end_date: this._end_date
        };
    }

}
