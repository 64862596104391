export enum AdsAccessConfigurationField {
    MODELING_ACCESS = "modeling_access",
    REJECT_TAXONOMY = "reject_taxonomy",
    REJECT_TIERS = "reject_tiers",
    RETARGETING_PARTNERS = "retargeting_partners",
    RESTRICT_PUBLIC_ACCESS = "restrict_public_access",
    SEGMENT_ACCESS = "segment_access",
    ORIGIN_IGNORED = "origin_ignored",
    ORIGIN = "origin",
    GLOBAL_TAXONOMY = "global_taxonomy",
    SHARED_TAXONOMY = "shared_taxonomy",
    USE_HTTP_REQUEST_CACHE = "use_http_request_cache",
    SEGMENT_TAXONOMY_ID = "segment_taxonomy_id",
    CATEGORY_TAXONOMY_ID = "category_taxonomy_id"
}
