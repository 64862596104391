export enum CmpConfigThemePosition {
    TOP = "TOP",
    CENTER = "CENTER",
    BOTTOM = "BOTTOM",
    BOTTOM_LEFT = "BOTTOM_LEFT",
    BOTTOM_RIGHT = "BOTTOM_RIGHT"
}

export const CMP_CONFIG_THEME_POSITIONS: CmpConfigThemePosition[] = [
    CmpConfigThemePosition.CENTER,
    CmpConfigThemePosition.BOTTOM_LEFT,
    CmpConfigThemePosition.BOTTOM_RIGHT,
    CmpConfigThemePosition.BOTTOM,
    CmpConfigThemePosition.TOP
];
