import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";
import {RevenueImportSearchParamOrder} from "./RevenueImportSearchParam";

export class RevenueImportSearchQuery extends SearchQuery {
    owner: string = "";
    size: number = PAGE_SIZE;

    withSearchParams(params: SearchParams): RevenueImportSearchQuery {
        let newQuery = super.withSearchParams(params) as RevenueImportSearchQuery;
        newQuery.owner = params.getString(SearchParamsField.OWNER) || "";
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            size: this.size,
            page: this.page,
            order: RevenueImportSearchParamOrder.DESCENDANT
        };
    }
}
