import {
    Action,
    Box,
    BoxProps,
    BoxSpacing,
    ContentBlock,
    FieldBlock,
    FlexContentLayout,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    Icon,
    IconTooltip,
    InputText,
    InputUrl,
    LayoutColumns,
    LayoutRows,
    Loadable
} from "@sirdata/ui-lib";
import copy from "copy-to-clipboard";
import {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Media} from "../../api/model/media/Media";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Formatter} from "../../common/utils/Formatter";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {MainContentHeader, MainContentHeaderAction} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {detectChanges} from "../../common/utils/portal";
import {MediaField} from "../../api/model/media/MediaField";
import useAlert from "../../utils/hooks/useAlert";
import {MediaType} from "../../api/model/media/MediaType";

function MediasDetails() {
    const {t: textMedias} = useTranslation(TranslationPortalFile.MEDIAS);
    const [isLoading, setLoading] = useState(true);
    const {id} = useParams() as {id: string};
    const navigate = useNavigate();
    const alert = useAlert();

    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isShowModalDelete, setShowModalDelete] = useState(false);

    const FORM_ID = "form-edit-media";
    const [media, setMedia] = useState<Media>(new Media());
    const [initMedia, setInitMedia] = useState<Media>(new Media());

    useEffect(() => {
        (async () => {
            try {
                const media = await session.restMedia.get(+id);
                setInitMedia(media);
                setMedia(new Media({...media}));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("media", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(media, initMedia));
    }, [media, initMedia]);

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newMedia = await session.restMedia.update(media);
            setMedia(newMedia);
            setInitMedia(new Media(newMedia));
            alert.updateWithSuccess("media");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("media", e.message);
            }
        }
    };

    const doDeleteMedia = async () => {
        try {
            await session.restMedia.delete(media.id);
            navigate(Module.MEDIAS.path);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("media", e.message);
            }
        }
    };

    const onCopyUrl = () => {
        copy(media.url);
        alert.copied("URL");
    };

    const handleChange = (field: MediaField, value: string) => {
        setMedia((prevState) => new Media({...prevState, [field]: value}));
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.MEDIAS} element={initMedia.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.MEDIAS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setShowModalDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <Loadable loading={isLoading}>
                                <Box {...BoxProps.SECTION_BLOCK} spacing={BoxSpacing.NONE} cssClass="media-box">
                                    {media.type === MediaType.IMAGE.name && <img src={media.url} alt={media.name}/>}
                                    {media.type === MediaType.FONT.name && <Icon name="format_size" cssClass="media-box__icon"/>}
                                    {media.type === MediaType.VIDEO.name && <video className="logo" src={media.url} controls autoPlay={false}/>}
                                </Box>
                            </Loadable>
                            <Loadable loading={isLoading}>
                                <ContentBlock>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FieldBlock label={textMedias(`field.${MediaField.TITLE}`)} required>
                                                <InputText
                                                    value={media.title}
                                                    onChange={(value) => handleChange(MediaField.TITLE, value)}
                                                />
                                            </FieldBlock>
                                            <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                <FieldBlock label={textMedias(`field.${MediaField.NAME}`)}>
                                                    <InputText
                                                        value={media.name.split("/").pop() || ""}
                                                        onChange={() => {}}
                                                        disabled
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textMedias(`field.${MediaField.FILE_SIZE}`)}>
                                                    {Formatter.formatBytes(media.file_size)}
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock
                                                label={textMedias(`field.${MediaField.URL_PATH}`)}
                                                actions={
                                                    <IconTooltip
                                                        icon={Action.COPY.icon}
                                                        text={textMedias("actions.copy_url")}
                                                        onClick={onCopyUrl}
                                                        cssClass="media-container__footer__icon"
                                                    />
                                                }
                                            >
                                                <InputUrl
                                                    value={media.url}
                                                    onChange={() => {}}
                                                    disabled
                                                />
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                        </LayoutColumns>
                    </LayoutRows>
                </Form>
                <ModalConfirmDelete
                    active={isShowModalDelete}
                    entity="media"
                    confirm={doDeleteMedia}
                    cancel={() => setShowModalDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default MediasDetails;
