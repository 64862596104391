export enum RevenueImportRequestField {
    PLATFORM = "platform",
    PERIOD_TYPE = "period_type",
    START_DATE = "start_date",
    END_DATE = "end_date",
    DELETE = "delete",
    FILE_BUFFER = "file_buffer",
    FILE_EXTENSION = "file_extension",
    USER = "user",
}
