import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, ButtonStyle, LayoutColumns, LayoutRows, Loadable, Tabs} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {session} from "../../api/ApiSession";
import useAlert from "../../utils/hooks/useAlert";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {CmpSirdataListCurrentVersion, CmpSirdataListPurposesEditable, CmpSirdataListStacksEditable, CmpSirdataListVendorsEditable, MainContentHeader, MainContentHeaderAction} from "../../component/snippet";
import {GlobalVendorList} from "../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataListVersion} from "../../api/model/cmp/list/sirdata-list/SirdataListVersion";
import {SirdataListLanguage} from "../../api/model/cmp/list/sirdata-list/SirdataListLanguage";
import {SirdataListVendor} from "../../api/model/cmp/list/sirdata-list/SirdataListVendor";
import {CmpConfigLanguage} from "../../api/model/cmp/config/CmpConfigLanguage";
import {Authorization} from "../../api/model/account/Authorization";
import ModalCreateCmpSirdataListVersion from "../../component/modal/cmp-sirdata-list/ModalCreateCmpSirdataListVersion";

function CmpSirdataList() {
    const alert = useAlert();
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const [isLoading, setLoading] = useState(true);
    const [latestVersion, setLatestVersion] = useState<SirdataListVersion>(new SirdataListVersion());
    const [globalVendorList, setGlobalVendorList] = useState<GlobalVendorList>(new GlobalVendorList());
    const [listLanguages, setListLanguages] = useState<SirdataListLanguage[]>([]);
    const [defaultListLanguage, setDefaultListLanguage] = useState<SirdataListLanguage>(new SirdataListLanguage());
    const [listVendors, setListVendors] = useState<SirdataListVendor[]>([]);
    const [isShowModalCreateCmpSirdataListVersion, setShowModalCreateCmpSirdataListVersion] = useState(false);

    const loadLatestVersion = useCallback(async () => {
        try {
            const latestVersion = await session.restCmpSirdataListVersion.getLatestVersion();
            setLatestVersion(latestVersion);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("latest version", e.message);
            }
        }
    }, [alert]);

    const loadListLanguages = useCallback(async () => {
        try {
            const listLanguages = await session.restCmpSirdataListLanguage.list();
            setListLanguages(listLanguages);
            setDefaultListLanguage(listLanguages.find((it) => it.language === CmpConfigLanguage.ENGLISH.name) as SirdataListLanguage);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("languages", e.message);
            }
        }
    }, [alert]);

    const loadListVendors = useCallback(async () => {
        try {
            const vendors = await session.restCmpSirdataListVendor.list();
            setListVendors(vendors);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("vendors", e.message);
            }
        }
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                const globalVendorList = await session.restCmpList.getGlobalVendorList(CmpConfigLanguage.ENGLISH);
                setGlobalVendorList(globalVendorList);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("Global Vendor List", e.message);
                }
            }
            await loadLatestVersion();
            await loadListLanguages();
            await loadListVendors();

            setLoading(false);
        })();
    }, [alert, loadLatestVersion, loadListLanguages, loadListVendors]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_SIRDATA_LIST} element={latestVersion.toContentElement()}>
                <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST_PUBLISH.name}>
                    <MainContentHeaderAction
                        action={new Action(textCmpSirdataList("action.publish"), {name: "publish"}, ButtonStyle.PRIMARY_GREEN)}
                        onClick={() => setShowModalCreateCmpSirdataListVersion(true)}
                    />
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Loadable loading={isLoading}>
                    <Tabs
                        headers={[
                            {label: textCmpSirdataList("tabs.latest_version")},
                            {label: textCmpSirdataList("tabs.edit_next_version")}
                        ]}
                    >
                        <CmpSirdataListCurrentVersion defaultLatestVersion={latestVersion}/>
                        <LayoutRows>
                            <LayoutColumns>
                                <CmpSirdataListStacksEditable
                                    latestVersion={latestVersion}
                                    globalVendorList={globalVendorList}
                                    listLanguage={defaultListLanguage}
                                    onChange={async () => await loadListLanguages()}
                                />
                                <CmpSirdataListPurposesEditable
                                    latestVersion={latestVersion}
                                    listLanguage={defaultListLanguage}
                                    onChange={async () => await loadListLanguages()}
                                />
                            </LayoutColumns>
                            <CmpSirdataListVendorsEditable
                                latestVersion={latestVersion}
                                globalVendorList={globalVendorList}
                                listLanguage={defaultListLanguage}
                                listVendors={listVendors}
                                onChange={async () => await loadListVendors()}
                            />
                        </LayoutRows>
                    </Tabs>
                </Loadable>
                <ModalCreateCmpSirdataListVersion
                    latestVersion={latestVersion}
                    listLanguages={listLanguages}
                    listVendors={listVendors}
                    active={isShowModalCreateCmpSirdataListVersion}
                    onClose={async (refresh) => {
                        setShowModalCreateCmpSirdataListVersion(false);
                        if (refresh) {
                            await loadLatestVersion();
                            await loadListVendors();
                        }
                    }}
                />
            </MainContent>
        </Wrapper>
    );
}

export default CmpSirdataList;
