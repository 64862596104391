export enum CountryIsoCode {
    AUSTRIA = "AT",
    BELGIUM = "BE",
    BULGARIA = "BG",
    CROATIA = "HR",
    CYPRUS = "CY",
    CZECHIA = "CZ",
    DENMARK = "DK",
    ESTONIA = "EE",
    FINLAND = "FI",
    FRANCE = "FR",
    GERMANY = "DE",
    GREECE = "GR",
    HUNGARY = "HU",
    IRELAND = "IE",
    ITALY = "IT",
    LATVIA = "LV",
    LITHUANIA = "LT",
    LUXEMBOURG = "LU",
    MALTA = "MT",
    NETHERLANDS = "NL",
    POLAND = "PL",
    PORTUGAL = "PT",
    ROMANIA = "RO",
    SLOVAKIA = "SK",
    SLOVENIA = "SI",
    SPAIN = "ES",
    SWEDEN = "SE",
    UNITED_KINGDOM = "GB"
}

export const COUNTRY_ISO_CODES_EU = [
    CountryIsoCode.AUSTRIA,
    CountryIsoCode.BELGIUM,
    CountryIsoCode.BULGARIA,
    CountryIsoCode.CZECHIA,
    CountryIsoCode.CYPRUS,
    CountryIsoCode.CROATIA,
    CountryIsoCode.DENMARK,
    CountryIsoCode.ESTONIA,
    CountryIsoCode.FINLAND,
    CountryIsoCode.FRANCE,
    CountryIsoCode.GERMANY,
    CountryIsoCode.GREECE,
    CountryIsoCode.HUNGARY,
    CountryIsoCode.IRELAND,
    CountryIsoCode.ITALY,
    CountryIsoCode.LATVIA,
    CountryIsoCode.LITHUANIA,
    CountryIsoCode.LUXEMBOURG,
    CountryIsoCode.MALTA,
    CountryIsoCode.NETHERLANDS,
    CountryIsoCode.POLAND,
    CountryIsoCode.PORTUGAL,
    CountryIsoCode.ROMANIA,
    CountryIsoCode.SLOVAKIA,
    CountryIsoCode.SLOVENIA,
    CountryIsoCode.SPAIN,
    CountryIsoCode.SWEDEN,
    CountryIsoCode.UNITED_KINGDOM
];
