import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchQuery} from "../../../interface/SearchQuery";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {AdsAccessField} from "./AdsAccessField";
import {AdsAccessConfigurationField} from "./AdsAccessConfigurationField";

export class AdsAccessSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    partner_id?: number;
    feature_access?: string;
    shared_taxonomy?: number;
    public_taxonomy?: number;

    withSearchParams(params: SearchParams): AdsAccessSearchQuery {
        let newQuery = super.withSearchParams(params) as AdsAccessSearchQuery;
        newQuery.partner_id = params.getNumber(SearchParamsField.PARTNER);
        newQuery.feature_access = params.getString(AdsAccessField.FEATURE_ACCESS);
        newQuery.shared_taxonomy = params.getNumber(AdsAccessConfigurationField.SHARED_TAXONOMY);
        newQuery.public_taxonomy = params.getNumber(AdsAccessField.PUBLIC_TAXONOMY);
        return newQuery;
    }

    withPublicTaxonomy(): AdsAccessSearchQuery {
        this.public_taxonomy = 1;
        return this;
    }

    getJsonParameters(): {} {
        return {
            size: this.size,
            page: this.page,
            search: this.query,
            partner_id: this.partner_id,
            feature_access: this.feature_access,
            shared_taxonomy: this.shared_taxonomy,
            public_taxonomy: this.public_taxonomy
        };
    }
}
