import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CustomerOrderSeat extends ApiModel {
    id: number = 0;
    seat_id: string = "";
    organization_id: number = 0;
    organization_name: string = "";
    organization_type: string = "";
    owner_id: number = 0;
    owner_name: string = "";
    buyer_type: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
