export class CategorizerConfigIntentConfidencePreset {
    static NONE: CategorizerConfigIntentConfidencePreset = new CategorizerConfigIntentConfidencePreset("none", 0);
    static REVIEW: CategorizerConfigIntentConfidencePreset = new CategorizerConfigIntentConfidencePreset("review", 0.3);
    static COMPARISON: CategorizerConfigIntentConfidencePreset = new CategorizerConfigIntentConfidencePreset("comparison", 0.6);
    static MERCHANT: CategorizerConfigIntentConfidencePreset = new CategorizerConfigIntentConfidencePreset("merchant", 1);
    static CUSTOM: CategorizerConfigIntentConfidencePreset = new CategorizerConfigIntentConfidencePreset("custom", 0);

    name: string;
    intentConfidence: number;

    private constructor(preset: string, intentConfidence: number) {
        this.name = preset;
        this.intentConfidence = intentConfidence;
    }

    static values(): CategorizerConfigIntentConfidencePreset[] {
        return [
            CategorizerConfigIntentConfidencePreset.NONE,
            CategorizerConfigIntentConfidencePreset.REVIEW,
            CategorizerConfigIntentConfidencePreset.COMPARISON,
            CategorizerConfigIntentConfidencePreset.MERCHANT
        ];
    }

    static getByIntentConfidence(intentConfidence: number): CategorizerConfigIntentConfidencePreset {
        return this.values().find((it) => it.intentConfidence === intentConfidence) || CategorizerConfigIntentConfidencePreset.CUSTOM;
    }
}
