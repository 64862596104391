import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Action, ActionMenuItem, ActionsMenu, TranslationLibFile} from "@sirdata/ui-lib";
import clsx from "clsx";

type MainContentHeaderActionsMenuProps = {
    action: Action;
    label?: string;
    items: ActionMenuItem[];
}

const MainContentHeaderActionsMenu: FunctionComponent<MainContentHeaderActionsMenuProps> = ({action, label, items}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <ActionsMenu
            iconTooltip={{
                icon: action.icon,
                text: clsx(textCommon(action.labelKey), label),
                cssClass: clsx("tools__actions__button", "tools__actions__button--actions-menu")
            }}
            items={items}
        />
    );
};

export default MainContentHeaderActionsMenu;
