import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Status} from "../../../../utils/Status";
import {isValidDomain} from "../../../../utils/string";

export class SgtmContainerHostDetail extends ApiModel {
    fqdn: string = "";
    domain: string = "";
    subdomain: string = "";
    status: string = Status.OK.name;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static statuses(): Status[] {
        return [
            Status.OK,
            Status.MISCONFIGURED,
            Status.NOT_FOUND
        ];
    }

    getStatus(): Status {
        return SgtmContainerHostDetail.statuses().find((it) => it.name === this.status) || Status.NOT_FOUND;
    }

    getHostname(): string {
        return `${this.subdomain}.${this.domain}`;
    }

    hasHostnameFilled(): boolean {
        return (!!this.subdomain && !!this.domain);
    }

    hasValidHostname(): boolean {
        return isValidDomain(this.domain) && isValidDomain(this.getHostname());
    }
}
