import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {Segment} from "../model/audience/segment/Segment";
import {TaxonomyTagLinkSegment} from "../model/taxonomy/tag/TaxonomyTagLinkSegment";

export class RestSegmentLinkTag extends Rest implements RestService<TaxonomyTagLinkSegment>{
    rootPath = "/console-api/taxonomy-tag";

    getTags(segment: Segment): Promise<string[]> {
        return this._client.get(`${this.rootPath}/segment/${segment.id}`) as Promise<string[]>;
    }

    search(params: any): Promise<SearchResult<TaxonomyTagLinkSegment>> {
        return new Promise(() => {});
    }

    create(link: TaxonomyTagLinkSegment) {
        return this._client.post(`${this.rootPath}/${link.tag_name}/segment/${link.id_segment}`);
    }

    get(id: string | number): Promise<TaxonomyTagLinkSegment> {
        return new Promise(() => {});
    }

    update(obj: TaxonomyTagLinkSegment): Promise<TaxonomyTagLinkSegment> {
        return new Promise(() => {});
    }

    delete(link: TaxonomyTagLinkSegment) {
        return this._client.delete(`${this.rootPath}/${link.tag_name}/segment/${link.id_segment}`);
    }

    updateTagsForSegment(segment: Segment, tags: string[]): Promise<string[]> {
        return this._client.post(`${this.rootPath}/segment/${segment.id}`, tags) as Promise<string[]>;
    }
}
