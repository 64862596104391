import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputEmail, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select} from "@sirdata/ui-lib";
import {User} from "../../../api/model/user/User";
import {TranslationPortalFile} from "../../../utils/constants";
import {UserField} from "../../../api/model/user/UserField";
import {UserPropertiesField} from "../../../api/model/user/UserPropertiesField";
import {USER_TEAMS, UserTeam} from "../../../api/model/user/UserTeam";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {useNavigate} from "react-router-dom";

export type ModalCreateUserProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateUser: FunctionComponent<ModalCreateUserProps> = ({active, onClose}) => {
    const alert = useAlert();
    const {t: textUsers} = useTranslation(TranslationPortalFile.USERS);
    const navigate = useNavigate();
    const [user, setUser] = useState<User>(new User());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-user";

    useEffect(() => {
        if (active) {
            setUser(new User({
                [UserField.EMAIL]: "@sirdata.fr",
                [UserField.PROPERTIES]: {
                    [UserPropertiesField.TEAM]: UserTeam.DATA
                }
            }));
        }
    }, [active]);

    const handleChange = (field: UserField, value: any) => {
        setUser((prevState) => new User({...prevState, [field]: value}));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const newUser = await session.restUser.create(user);
            navigate(newUser.getRoute());
            onClose();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("user", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textUsers("create_user")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={2}>
                            <FieldBlock label={textUsers(`field.${UserField.EMAIL}`)} required>
                                <InputEmail
                                    value={user.email}
                                    onChange={(value) => handleChange(UserField.EMAIL, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textUsers(`field.${UserField.LAST_NAME}`)} required>
                                <InputText
                                    value={user.last_name}
                                    onChange={(value) => handleChange(UserField.LAST_NAME, value)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textUsers(`field.${UserField.FIRST_NAME}`)} required>
                                <InputText
                                    value={user.first_name}
                                    onChange={(value) => handleChange(UserField.FIRST_NAME, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FormLayoutColumns>
                            <FieldBlock label={textUsers(`field.${UserField.PROPERTIES}.${UserPropertiesField.TEAM}`)}>
                                <Select
                                    value={user.properties.team}
                                    options={USER_TEAMS.map((it) => ({value: it, label: textUsers(`team.${it}`)}))}
                                    onChange={(option) => handleChange(UserField.PROPERTIES, {...user.properties, [UserPropertiesField.TEAM]: option?.value})}
                                />
                            </FieldBlock>
                            <FieldBlock label={textUsers(`field.${UserField.POSITION}`)}>
                                <InputText
                                    value={user.position}
                                    onChange={(value) => handleChange(UserField.POSITION, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateUser;
