import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";

export class CmpWebinarSearchQuery extends SearchQuery {
    status?: string;

    withSearchParams(params: SearchParams): CmpWebinarSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting("date", true) as CmpWebinarSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS);
        return newQuery;
    }
}
