import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CategoryType} from "../category/CategoryType";
import {CategoryTaxonomy} from "../category/CategoryTaxonomy";
import {Module} from "../../../../utils/Module";

export class SegmentLinkCategory extends ApiModel {
    segment_id: number = 0;
    category_id: number = 0;
    category_id_group: number = 0;
    category_code_name: string = "";
    category_name: string = "";
    category_type: string = CategoryType.INTENT.name;
    taxonomy: string = CategoryTaxonomy.SIRDATA.name;
    volume: number = 0;
    min_score: number = 0;
    auto_min_score?: number;
    population?: string;
    has_keywords: boolean = false;
    has_bq_request: boolean = true;
    has_bq_request_active: boolean = false;
    expiration_day: number = 0;
    percentile?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            segment_id: this.segment_id,
            category_id: this.category_id,
            min_score: this.min_score,
            expiration_day: this.expiration_day,
            percentile: this.percentile
        };
    }

    getRoute(): string {
        return Module.CATEGORIES.buildRoute(this.category_id_group);
    }
}
