import {
    Action,
    ContentBlock,
    LayoutRows,
    Loadable,
    SearchError,
    SearchField,
    SearchToolbar,
    Select,
    SelectAutocomplete,
    Table,
    ToggleSwitch
} from "@sirdata/ui-lib";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {RevenuePlatform} from "../../api/model/revenue/RevenuePlatform";
import {RevenueSeat} from "../../api/model/revenue/seat/RevenueSeat";
import {RevenueSeatField} from "../../api/model/revenue/seat/RevenueSeatField";
import {RevenueSeatSearchParamsField, RevenueSeatSearchQuery} from "../../api/model/revenue/seat/RevenueSeatSearchQuery";
import {RevenueSeatSearchResult} from "../../api/model/revenue/seat/RevenueSeatSearchResult";
import {REVENUE_SEAT_STATUSES} from "../../api/model/revenue/seat/RevenueSeatStatus";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {downloadCSV} from "../../common/utils/portal";
import ModalCreateSeat from "../../component/modal/seats/ModalCreateSeat";
import {MainContentHeader, MainContentHeaderAction, RevenueSeatRow, SelectUser} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";

function Seats() {
    const alert = useAlert();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textSeats} = useTranslation(TranslationPortalFile.SEATS);
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreateSeat, setShowModalCreateSeat] = useState(false);
    const [currentValidatingSeatId, setCurrentValidatingSeatId] = useState<number>();
    const [platforms, setPlatforms] = useState<RevenuePlatform[]>([]);
    const {setSearchResult, ...search} = useSearch(RevenueSeat, RevenueSeatSearchQuery, RevenueSeatSearchResult);

    const loadSeats = useCallback(async () => {
        try {
            const newSearchResult = await session.restSeat.search(search.searchQuery);
            setSearchResult(newSearchResult);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("seats", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [search.searchQuery, setSearchResult, alert]);

    useEffect(() => {
        (async () => {
            await loadSeats();
        })();
    }, [loadSeats]);

    useEffect(() => {
        (async () => {
            try {
                let platforms = await session.restRevenuesImport.getPlatforms();
                platforms = platforms.filter((platform) => !platform.isCurator()); // remove curator platforms
                setPlatforms(platforms);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("platforms", e.message);
                }
            }
        })();
    }, [alert]);

    const handleDownloadSeats = async () => {
        try {
            let searchQuery: RevenueSeatSearchQuery = new RevenueSeatSearchQuery();
            searchQuery.size = 10000;
            const fileContent = await session.restSeat.download(searchQuery);
            downloadCSV("seats", fileContent);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("download seats", e.message);
            }
        }
    };

    const handleValidateSeat = async (seat: RevenueSeat) => {
        try {
            setCurrentValidatingSeatId(seat.id);
            await session.restSeat.validate(seat);
            await loadSeats();
            alert.actionWithSuccess("seat validated");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("validate seat", e.message);
            }
        } finally {
            setCurrentValidatingSeatId(undefined);
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.SEATS}>
                <MainContentHeaderAction action={Action.DOWNLOAD_ALL} onClick={handleDownloadSeats}/>
                <RestrictedContent allowedTo={Authorization.SEATS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateSeat(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textSeats("search.placeholder"), value: search.searchQuery.query || "", onSubmit: search.changeQuery}}
                            canHideFilters
                        >
                            <RestrictedContent allowedTo={Authorization.SEATS_VALIDATE.name}>
                                <SearchField label={textSeats(`field.${RevenueSeatField.STATUS}`)}>
                                    <Select
                                        value={search.searchQuery.status}
                                        options={REVENUE_SEAT_STATUSES.map((it) => ({value: it.name, label: t(`status.${it.name}`)}))}
                                        onChange={(option) => search.changeParam(SearchParamsField.STATUS, option?.value)}
                                        clearable
                                    />
                                </SearchField>
                            </RestrictedContent>
                            <SearchField label={textSeats(`field.${RevenueSeatField.PLATFORM}`)}>
                                <SelectAutocomplete
                                    value={search.searchQuery.platform}
                                    options={platforms.map((it) => ({value: it.name, label: it.name}))}
                                    onChange={(option) => search.changeParam(SearchParamsField.PLATFORM, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textSeats(`field.${RevenueSeatField.OWNER_ID}`)}>
                                <SelectUser
                                    value={search.searchQuery.owner_id}
                                    onChange={(user) => search.changeParam(SearchParamsField.OWNER, user?.id)}
                                    includeInactive
                                />
                            </SearchField>
                            <SearchField label={textSeats(`search.${RevenueSeatSearchParamsField.NEW_BUSINESS}`)} shortWidth name={RevenueSeatSearchParamsField.NEW_BUSINESS}>
                                <ToggleSwitch
                                    checked={search.searchQuery.new_business}
                                    onChange={(value) => search.changeParam(RevenueSeatSearchParamsField.NEW_BUSINESS, value)}
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textSeats(`field.${RevenueSeatField.STATUS}`), sort: {field: RevenueSeatField.STATUS, reverseOrder: false}},
                                        {width: 10, label: textSeats(`field.${RevenueSeatField.PLATFORM}`), sort: {field: RevenueSeatField.PLATFORM}},
                                        {width: 10, label: textSeats(`field.${RevenueSeatField.SEAT_ID}`), sort: {field: RevenueSeatField.SEAT_ID}},
                                        {width: 15, label: textSeats(`field.${RevenueSeatField.ORGANIZATION}`)},
                                        {width: 15, label: textSeats(`field.${RevenueSeatField.ORGANIZATION_GROUP}`)},
                                        {width: 15, label: textSeats(`field.${RevenueSeatField.ADVERTISER}`), sort: {field: RevenueSeatField.ADVERTISER}},
                                        {width: 15, label: textSeats(`field.${RevenueSeatField.OWNER_ID}`)},
                                        {width: 1}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {
                                        search.searchResult.elements.map((seat: RevenueSeat) =>
                                            <RevenueSeatRow
                                                key={seat.id}
                                                seat={seat}
                                                user={seat.owner}
                                                onValidate={handleValidateSeat}
                                                isLoading={currentValidatingSeatId === seat.id}
                                            />
                                        )
                                    }
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateSeat
                    active={isShowModalCreateSeat}
                    onClose={() => setShowModalCreateSeat(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Seats;
