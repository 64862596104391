import {Rest} from "../../common/api/rest/Rest";
import {AccountWithAuthorizationItem} from "../model/auth/AccountWithAuthorizationItem";
import {ApiAuthorization} from "../model/auth/ApiAuthorization";
import {SearchResult} from "../interface/SearchResult";
import {RestService} from "../interface/RestService";

export class RestAuthorizationUser extends Rest implements RestService<ApiAuthorization> {
    rootPath = "/console-api/api-authorizations/user";

    search(params: any): Promise<SearchResult<ApiAuthorization>> {
        return new Promise<SearchResult<ApiAuthorization>>(() => {}); // TODO Implement route
    }

    create(authorization: ApiAuthorization): Promise<ApiAuthorization> {
        return this._client.put(`${this.rootPath}`, authorization, ApiAuthorization) as Promise<ApiAuthorization>;
    }

    get(name: string): Promise<ApiAuthorization> {
        return new Promise<ApiAuthorization>(() => {}); // TODO Implement route
    }

    update(authorization: ApiAuthorization): Promise<ApiAuthorization> {
        return new Promise<ApiAuthorization>(() => {}); // TODO Implement route
    }

    delete(name: string) {
        return this._client.delete(`${this.rootPath}/${name}`);
    }

    getDetails(authorization: ApiAuthorization): Promise<AccountWithAuthorizationItem[]> {
        return this._client.get(`${this.rootPath}/authorization/${authorization.name}/user`, AccountWithAuthorizationItem) as Promise<AccountWithAuthorizationItem[]>;
    }
}
