import {Rest} from "../../common/api/rest/Rest";
import {Deal} from "../model/deal/Deal";
import {DealSearchQuery} from "../model/deal/DealSearchQuery";
import {DealSearchResult} from "../model/deal/DealSearchResult";
import {RestService} from "../interface/RestService";

export class RestDeal extends Rest implements RestService<Deal> {
    rootPath = "/console-api/curated-deal";

    search(params: DealSearchQuery): Promise<DealSearchResult> {
        return this._client.get(`${this.rootPath}/search`, DealSearchResult, {queryParams: params.getJsonParameters()}) as Promise<DealSearchResult>;
    }

    list(): Promise<Deal[]> {
        return this._client.get(`${this.rootPath}`, Deal) as Promise<Deal[]>;
    }

    create(deal: Deal): Promise<Deal> {
        return this._client.post(`${this.rootPath}`, deal, Deal) as Promise<Deal>;
    }

    get(dealId: string|number): Promise<Deal> {
        return this._client.get(`${this.rootPath}/${dealId}`, Deal) as Promise<Deal>;
    }

    update(deal: Deal): Promise<Deal> {
        return this._client.put(`${this.rootPath}/${deal.id}`, deal, Deal) as Promise<Deal>;
    }

    delete(dealId: number) {
        return this._client.delete(`${this.rootPath}/${dealId}`);
    }
}
