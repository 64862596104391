import {Action, ActionsMenu, Color, Icon, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {CategorizerConfigEntry} from "../../../../api/model/categorizer/CategorizerConfigEntry";
import {RestrictedContent} from "../../../../common/component/widget";
import {CategorizerConfigScraperApi} from "../../../../api/model/categorizer/config/CategorizerConfigScraperApi";

type CategorizerConfigScraperApiRowProps = {
    entry: CategorizerConfigEntry<CategorizerConfigScraperApi>;
    isEditable: boolean;
    onClick: () => void;
    onDelete: () => void;
};

const CategorizerConfigScraperApiRow: FC<CategorizerConfigScraperApiRowProps> = ({entry, isEditable, onClick, onDelete}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <TableRow onClick={onClick}>
            <TableColumn>{entry.domain || "-"}</TableColumn>
            <TableColumn>{entry.host || "-"}</TableColumn>
            <TableColumn>{entry.path || "-"}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <Icon name="circle" colorIcon={entry.config.premium ? Color.GREEN : Color.RED}/>
            </TableColumn>
            <TableActionColumn>
                {isEditable &&
                    <RestrictedContent allowedTo={Authorization.CATEGORIZER.update}>
                        <ActionsMenu
                            iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                            items={[
                                {label: textCommon(Action.DELETE.labelKey), critical: true, separator: true, onClick: onDelete}
                            ]}
                        />
                    </RestrictedContent>
                }
            </TableActionColumn>
        </TableRow>
    );
};

export default CategorizerConfigScraperApiRow;
