import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DataleaksRegulation} from "../DataleaksRegulation";
import {DataleaksVersionScoringCmps} from "./DataleaksVersionScoringCmps";
import {DataleaksVersionScoringField} from "./DataleaksVersionScoringField";
import {DataleaksVersionScoringMechanism} from "./DataleaksVersionScoringMechanism";

export class DataleaksVersionScoring extends ApiModel {
    total_audits: number = 0;
    _regulations: Map<DataleaksRegulation, number> = new Map<DataleaksRegulation, number>();
    scoring_mechanisms: DataleaksVersionScoringMechanism = new DataleaksVersionScoringMechanism();
    scoring_cmps: DataleaksVersionScoringCmps = new DataleaksVersionScoringCmps();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                switch (key) {
                    case DataleaksVersionScoringField.SCORING_MECHANISMS:
                        this[key] = new DataleaksVersionScoringMechanism(o[key]);
                        break;
                    case DataleaksVersionScoringField.SCORING_CMPS:
                        this[key] = new DataleaksVersionScoringCmps(o[key]);
                        break;
                    default:
                        this[key] = o[key];
                }
            }
        }
    }

    get regulations(): Map<DataleaksRegulation, number> {
        return this._regulations;
    }

    set regulations(regulations: Map<DataleaksRegulation, number>) {
        if (Object.entries(regulations).length) {
            const values = new Map<DataleaksRegulation, number>();
            Object.entries(regulations).forEach(([regulation, score]) => {
                values.set(regulation as DataleaksRegulation, score);
            });
            this._regulations = values;
        } else {
            this._regulations = regulations;
        }
    }
}
