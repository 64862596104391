import {ApiModel} from "../../../../common/api/model/ApiModel";
import {HelperPrivacyConfigurationField} from "./HelperPrivacyConfigurationField";
import {HelperPrivacyConfigurationUtm} from "./HelperPrivacyConfigurationUtm";
import {HelperPrivacyConfigurationClickId} from "./HelperPrivacyConfigurationClickId";
import {HelperPrivacyConfigurationReferer} from "./HelperPrivacyConfigurationReferer";
import {HelperPrivacyConfigurationTransactionId} from "./HelperPrivacyConfigurationTransactionId";
import {HelperPrivacyConfigurationTruncateIp} from "./HelperPrivacyConfigurationTruncateIp";
import {HelperPrivacyConfigurationUserAgent} from "./HelperPrivacyConfigurationUserAgent";
import {HelperPrivacyConfigurationUserId} from "./HelperPrivacyConfigurationUserId";
import {HelperPrivacyConfigurationUrl} from "./HelperPrivacyConfigurationUrl";

export class HelperPrivacyConfiguration extends ApiModel {
    truncate_ip: HelperPrivacyConfigurationTruncateIp = HelperPrivacyConfigurationTruncateIp.TRUNCATE_ONE_BYTE;
    user_id: HelperPrivacyConfigurationUserId = HelperPrivacyConfigurationUserId.PSEUDONYMISE;
    referer: HelperPrivacyConfigurationReferer = HelperPrivacyConfigurationReferer.NO_ALTER;
    url: HelperPrivacyConfigurationUrl = HelperPrivacyConfigurationUrl.NO_ALTER;
    transaction_id: HelperPrivacyConfigurationTransactionId = HelperPrivacyConfigurationTransactionId.NO_ALTER;
    click_id: HelperPrivacyConfigurationClickId = HelperPrivacyConfigurationClickId.NO_ALTER;
    user_agent: HelperPrivacyConfigurationUserAgent = HelperPrivacyConfigurationUserAgent.NO_ALTER;
    utm: HelperPrivacyConfigurationUtm = new HelperPrivacyConfigurationUtm();
    ignore_tcf: boolean = false;
    domain_excluded_to_hash: boolean = false;
    disable_cookieless_tracking: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === HelperPrivacyConfigurationField.UTM) {
                    this.utm = new HelperPrivacyConfigurationUtm(o[key]);
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            truncate_ip: this.truncate_ip,
            user_id: this.user_id,
            referer: this.referer,
            url: this.url,
            transaction_id: this.transaction_id,
            click_id: this.click_id,
            user_agent: this.user_agent,
            utm: this.utm.getJsonParameters(),
            ignore_tcf: this.ignore_tcf,
            domain_excluded_to_hash: this.domain_excluded_to_hash,
            disable_cookieless_tracking: this.disable_cookieless_tracking
        };
    }
}

