import {ApiModel} from "../../../common/api/model/ApiModel";

export class CategorizerSanitizerVersion extends ApiModel {
    version: number = CategorizerSanitizerVersion.DEFAULT_VERSION;
    default: boolean = false;

    static DEFAULT_VERSION = 2;

    static getLabelForVersion(version: number): string {
        let label = "";
        switch (version) {
            case 0:
                label = "MIN";
                break;
            case 1:
                label = "SPECIFIC";
                break;
            case 2:
                label = "UNIVERSAL";
                break;
            default:
                return "";
        }
        return `${label} (${version})`;
    }
}
