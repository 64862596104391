import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {Locales as libLocales} from "@sirdata/ui-lib";
import {TRANSLATION_COMMON_FILES} from "../common/utils/constants";
import {TRANSLATION_PORTAL_FILES, TranslationPortalFile} from "../utils/constants";
import {Locale} from "../common/utils/Locale";

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: libLocales,
        detection: {
            order: ["navigator", "localStorage", "cookie"]
        },
        lng: Locale.ENGLISH, // Do not touch for Console as only English is supported
        preload: [Locale.FRENCH],
        interpolation: {escapeValue: false},
        partialBundledLanguages: true,
        debug: false,
        ns: [
            ...TRANSLATION_COMMON_FILES,
            ...TRANSLATION_PORTAL_FILES
        ],
        defaultNS: TranslationPortalFile.TRANSLATION,
        backend: {
            loadPath: (lng: string, ns: string) => {
                let path = "/locales/{{lng}}";
                if (TRANSLATION_COMMON_FILES.find((it) => it === ns.toString())) {
                    path = "/common" + path;
                }
                path += "/{{ns}}.json";
                return path;
            }
        }
    })
    .then(() => {
        const pluralsRule = {
            numbers: [0, 1, 2],
            plurals: function plurals(n: number) {
                return Number(n >= 2 ? 2 : n);
            }
        };
        i18n.services.pluralResolver.addRule(
            Locale.ENGLISH,
            pluralsRule
        );
        i18n.services.pluralResolver.addRule(
            Locale.FRENCH,
            pluralsRule
        );
    });

export default i18n;
