import React, {FC, useEffect, useState} from "react";
import {SelectAutocomplete} from "@sirdata/ui-lib";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";

type SelectCategoryProps = {
    value: number | undefined;
    onChange: ((category?: Category) => void) | undefined;
    isLoading?: boolean;
    type?: CategoryType;
    excludedCategories?: Category[];
    keepMenuOpen?: boolean;
    keepSearchAfterSelect?: boolean;
    onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
    onInvalid?: (e: React.FormEvent<HTMLInputElement>) => void;
    small?: boolean;
    disabled?: boolean;
};

const SelectCategory: FC<SelectCategoryProps> = ({value, onChange, isLoading, type, excludedCategories, keepMenuOpen, keepSearchAfterSelect, ...rest}) => {
    const alert = useAlert();
    const [isLoadingCategories, setLoadingCategories] = useState(true);
    const [categories, setCategories] = useState<Category[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);

    useEffect(() => {
        (async () => {
            try {
                setCategories(await session.getCategoriesByType(type));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("categories", e.message);
                }
            } finally {
                setLoadingCategories(false);
            }
        })();
    }, [type, alert]);

    useEffect(() => {
        if (!!excludedCategories?.length) {
            const newFilteredCategories = categories.filter((category) => !excludedCategories?.some((it) => it.id === category.id));
            setFilteredCategories(newFilteredCategories);
        } else {
            setFilteredCategories(categories);
        }
    }, [categories, excludedCategories]);

    return (
        <SelectAutocomplete
            value={value}
            options={filteredCategories.map((it) => ({
                value: it.id,
                label: `${it.id} - ${it.fullName}`,
                category: it
            }))}
            onChange={(option) => onChange && onChange(option?.category)}
            isLoading={isLoading || isLoadingCategories}
            clearable
            closeMenuOnSelect={!keepMenuOpen}
            resetInputOnSelect={!keepSearchAfterSelect}
            {...rest}
        />
    );
};

export default SelectCategory;
