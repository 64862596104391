import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table} from "@sirdata/ui-lib";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {ApiAuthorization} from "../../api/model/auth/ApiAuthorization";
import {ApiAuthorizationField} from "../../api/model/auth/ApiAuthorizationField";
import {AuthorizationSearchQuery} from "../../api/model/auth/AuthorizationSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreatePartnerAuthorization from "../../component/modal/authorizations/ModalCreatePartnerAuthorization";
import ModalAuthDetails from "../../component/modal/authorizations/ModalAuthDetails";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {ApiAuthorizationRow, MainContentHeader, MainContentHeaderAction, SelectService} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";

export enum AuthorizationType {
    PARTNER = "partner",
    USER = "user"
}

function AuthorizationsPartner() {
    const {t: textAuthorizations} = useTranslation(TranslationPortalFile.AUTHORIZATIONS);
    const [isLoading, setLoading] = useState(true);

    const [authorizations, setAuthorizations] = useState<ApiAuthorization[]>([]);

    const {buildSearchResult, ...search} = useSearch(ApiAuthorization, AuthorizationSearchQuery);
    const alert = useAlert();

    const [currentItem, setCurrentItem] = useState<ApiAuthorization>();
    const [isShowModalCreatePartnerAuthorization, setShowModalCreatePartnerAuthorization] = useState(false);
    const [isShowModalDelete, setShowModalDelete] = useState(false);
    const [isShowModalDetails, setShowModalDetails] = useState(false);

    const loadAuthorizations = useCallback(async () => {
        try {
            let result = await session.restPartnerAuthorization.list();
            setAuthorizations(result);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("authorizations", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [alert]);

    useEffect(() => {
        (async () => {
            await loadAuthorizations();
        })();
    }, [loadAuthorizations]);

    useEffect(() => {
        let currentAuthorizations = (authorizations || []);
        if (search.searchQuery.query) {
            currentAuthorizations = currentAuthorizations.filter(({name}) => name.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        if (search.searchQuery.service) {
            currentAuthorizations = currentAuthorizations.filter((it) => it.service === search.searchQuery.service);
        }
        buildSearchResult(currentAuthorizations);
    }, [authorizations, search.searchQuery, buildSearchResult]);

    const handleShowDetails = (authorization: ApiAuthorization) => {
        setCurrentItem(authorization);
        setShowModalDetails(true);
    };

    const handleDeleteAuthorization = (authorization: ApiAuthorization) => {
        setCurrentItem(authorization);
        setShowModalDelete(true);
    };

    const handleCancelDelete = () => {
        setShowModalDelete(false);
        setCurrentItem(undefined);
    };

    const doDeleteApiAuthorization = async () => {
        if (!currentItem) return;
        try {
            await session.restAuthorizationPartner.delete(currentItem.name);
            const newAuthorizations = authorizations.filter((item) => item.name !== currentItem.name);
            setAuthorizations(newAuthorizations);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("authorization", e.message);
            }
        } finally {
            setShowModalDelete(false);
            setCurrentItem(undefined);
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.AUTHORIZATIONS_PARTNER}>
                <RestrictedContent allowedTo={Authorization.AUTHORIZATIONS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreatePartnerAuthorization(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textAuthorizations("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters={true}
                        >
                            <SearchField label={textAuthorizations(`field.${ApiAuthorizationField.SERVICE}`)}>
                                <SelectService
                                    value={search.searchQuery.service}
                                    services={ApiAuthorization.partnerServices()}
                                    onChange={(service) => search.changeParam(SearchParamsField.SERVICE, service?.name)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 15, label: textAuthorizations(`field.${ApiAuthorizationField.SERVICE}`), sort: {field: ApiAuthorizationField.SERVICE, reverseOrder: false}},
                                        {width: 35, label: textAuthorizations(`field.${ApiAuthorizationField.NAME}`), sort: {field: ApiAuthorizationField.NAME, reverseOrder: false}},
                                        {width: 35, label: textAuthorizations("field.label")},
                                        {width: 5}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((item) =>
                                        <ApiAuthorizationRow
                                            key={item.name}
                                            authorization={item}
                                            type={AuthorizationType.PARTNER}
                                            onShowDetails={handleShowDetails}
                                            onDelete={handleDeleteAuthorization}
                                        />
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreatePartnerAuthorization
                    active={isShowModalCreatePartnerAuthorization}
                    onClose={(refresh) => {
                        setShowModalCreatePartnerAuthorization(false);
                        refresh && loadAuthorizations();
                    }}
                />
                <ModalConfirmDelete
                    active={isShowModalDelete}
                    entity={`authorization "${currentItem?.name}"`}
                    confirm={doDeleteApiAuthorization}
                    cancel={handleCancelDelete}
                />
                <ModalAuthDetails
                    active={isShowModalDetails}
                    authz={currentItem}
                    type={AuthorizationType.PARTNER}
                    onClose={() => {
                        setCurrentItem(undefined);
                        setShowModalDetails(false);
                    }}
                />
            </MainContent>
        </Wrapper>
    );
}

export default AuthorizationsPartner;
