export enum DataSelectionTheme {
    BESTYS = "BESTYS",
    CURRENT_FAVOURITES = "CURRENT_FAVOURITES",
    TIMELESS = "TIMELESS",
    SPECIAL_EVENTS = "SPECIAL_EVENTS",
}

export const DATA_SELECTION_THEMES = [
    DataSelectionTheme.SPECIAL_EVENTS,
    DataSelectionTheme.TIMELESS,
    DataSelectionTheme.BESTYS,
    DataSelectionTheme.CURRENT_FAVOURITES
];
