import {PortalContentSearchQuery} from "../../portal-content/PortalContentSearchQuery";
import {DataSelection} from "./DataSelection";
import {ApiService} from "../../ApiService";
import {SearchParams} from "../../../../utils/SearchParams";

export enum DataSelectionSearchParamsField {
    THEME = "theme"
}

export class DataSelectionSearchQuery extends PortalContentSearchQuery {
    withSearchParams(params: SearchParams): DataSelectionSearchQuery {
        let newQuery = super.withSearchParams(params);
        newQuery.setSearchIndex(DataSelectionSearchParamsField.THEME, params.getString(DataSelectionSearchParamsField.THEME) || "");
        return newQuery;
    };

    constructor(o?: {}) {
        super(o);
        this.load(o);
        this.type = DataSelection.TYPE;
        this.service = ApiService.AUDIENCE.name;
    }
}
