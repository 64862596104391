export enum DistributionTechnicalField {
    RETENTION_IN_DAYS = "retention_in_days",
    PIXEL_SYNCHRO = "pixel_synchro",
    DOUBLE_SYNCHRO = "double_synchro",
    DOUBLE_SYNCHRO_URL = "double_synchro_url",
    TRELLO_CARD_URL = "trello_card_url",
    MATCHING_TABLE = "matching_table",
    USED_ID = "used_id",
    INTEGRATION_TYPE = "integration_type",
    CONSENT_STRING = "consent_string"
}
