import {ApiModel} from "../../../common/api/model/ApiModel";
import {HeaderModule} from "../../../common/utils/HeaderModule";

export class PortalSettingContentHeader extends ApiModel {
    modules: string[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    hasModule(headerModule: HeaderModule): boolean {
        return this.modules.some((module) => module === headerModule.name);
    }

    getJsonParameters(): {} {
        return {
            modules: this.modules
        };
    }
}
