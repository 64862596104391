import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ApiService} from "../../../api/model/ApiService";
import {TranslationPortalFile} from "../../../utils/constants";
import {SelectPartner} from "../../../component/snippet";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigSettings} from "../../../api/model/cmp/config/CmpConfigSettings";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigSettingsField} from "../../../api/model/cmp/config/CmpConfigSettingsField";
import {ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputDomain, InputText, InputUrl, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew} from "@sirdata/ui-lib";
import {CmpConfigPropertiesCreationType} from "../../../api/model/cmp/config/CmpConfigPropertiesCreationType";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {useNavigate} from "react-router-dom";

export type ModalCreateCmpConfigurationProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateCmpConfiguration: FunctionComponent<ModalCreateCmpConfigurationProps> = ({active, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [cmpConfig, setCmpConfig] = useState<CmpConfig>(new CmpConfig());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-cmp-configuration";

    useEffect(() => {
        if (active) {
            setCmpConfig(new CmpConfig());
        }
    }, [active]);

    const handleChange = (field: CmpConfigField, value: any) => {
        setCmpConfig((prevState) => new CmpConfig({...prevState, [field]: value}));
    };

    const handleChangeSettings = (field: CmpConfigSettingsField, value: any) => {
        handleChange(CmpConfigField.SETTINGS, new CmpConfigSettings({
            ...cmpConfig.settings,
            [field]: value
        }));
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            cmpConfig.properties.creation_type = CmpConfigPropertiesCreationType.ADVANCED;
            const newCmpConfig = await session.restCmpConfiguration.create(cmpConfig);
            navigate(newCmpConfig.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("CMP configuration", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpConfigurations("modal.create_cmp_configuration")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns>
                            <FieldBlock
                                label={textCmpConfigurations(`field.${CmpConfigField.NAME}`)}
                                required
                            >
                                <InputText
                                    value={cmpConfig.name}
                                    placeholder={textCmpConfigurations(`placeholder.${CmpConfigField.NAME}`)}
                                    onChange={(value) => handleChange(CmpConfigField.NAME, value)}
                                />
                            </FieldBlock>
                            <FieldBlock
                                label={textCmpConfigurations(`field.${CmpConfigField.DOMAIN}`)}
                                required
                            >
                                <InputDomain
                                    value={cmpConfig.domain}
                                    placeholder={textCmpConfigurations(`placeholder.${CmpConfigField.DOMAIN}`)}
                                    onChange={(value) => handleChange(CmpConfigField.DOMAIN, value)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock
                            label={textCmpConfigurations(`field.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PRIVACY_POLICY}`)}
                            required
                        >
                            <InputUrl
                                value={cmpConfig.settings.privacyPolicy}
                                onChange={(value) => handleChangeSettings(CmpConfigSettingsField.PRIVACY_POLICY, value)}
                                placeholder={textCmpConfigurations(`placeholder.${CmpConfigField.SETTINGS}.${CmpConfigSettingsField.PRIVACY_POLICY}`)}
                            />
                        </FieldBlock>
                        <FieldBlock
                            label={textCmpConfigurations(`field.${CmpConfigField.ID_PARTNER}`)}
                            required
                        >
                            <SelectPartner
                                value={cmpConfig.id_partner}
                                onChange={(partner) => handleChange(CmpConfigField.ID_PARTNER, partner?.id || 0)}
                                service={ApiService.CMP}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateCmpConfiguration;
