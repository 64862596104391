import {
    Accordion,
    AccordionItem,
    Action,
    Alert,
    AlertSeverity,
    Button,
    ButtonLinkCancel,
    ButtonStyle,
    ButtonValidate,
    ElementList,
    FieldBlock,
    FlexContentSpacing,
    Form,
    FormLayoutRows,
    FormValidationType,
    InputText,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CategorizerConfigEntry} from "../../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigType} from "../../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfigUrlCleaner} from "../../../api/model/categorizer/config/CategorizerConfigUrlCleaner";
import {CategorizerConfigUrlCleanerField} from "../../../api/model/categorizer/config/CategorizerConfigUrlCleanerField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {CategorizerConfigEntryInfo, TagWithAction} from "../../snippet";

type ModalEditCategorizerConfigUrlCleanerProps = {
    initEntry: CategorizerConfigEntry<CategorizerConfigUrlCleaner> | undefined;
    onSubmit: () => void;
    onClose: () => void;
};

const ModalEditCategorizerConfigUrlCleaner: FunctionComponent<ModalEditCategorizerConfigUrlCleanerProps> = ({initEntry, onSubmit, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const [entry, setEntry] = useState<CategorizerConfigEntry<CategorizerConfigUrlCleaner>>(CategorizerConfigEntry.forType(CategorizerConfigType.URL_CLEANER));
    const [inputValues, setInputValues] = useState<Map<CategorizerConfigUrlCleanerField, string>>(new Map<CategorizerConfigUrlCleanerField, string>());
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-edit-categorizer-config-url-cleaner";

    useEffect(() => {
        if (initEntry) {
            setEntry(initEntry);
        }
    }, [initEntry]);

    const handleChange = (field: CategorizerConfigEntryField, value: any) => {
        setEntry((prevState) => new CategorizerConfigEntry<CategorizerConfigUrlCleaner>({...prevState, [field]: value}));
    };

    const handleChangeConfig = (field: CategorizerConfigUrlCleanerField, value: any) => {
        handleChange(CategorizerConfigEntryField.CONFIG, new CategorizerConfigUrlCleaner({...entry.config, [field]: value}));
    };

    const handleChangeInputValue = (field: CategorizerConfigUrlCleanerField, value: string) => {
        setInputValues((prevState) => {
            const newState = new Map<CategorizerConfigUrlCleanerField, string>(prevState);
            newState.set(field, value);
            return newState;
        });
    };

    const handleAdd = (field: CategorizerConfigUrlCleanerField) => {
        let inputValue = inputValues.get(field);
        if (!inputValue) return;

        if (field === CategorizerConfigUrlCleanerField.REJECTED_PATHS) {
            if (!inputValue.startsWith("/")) {
                inputValue = "/" + inputValue;
            }
        }

        if (!entry.config[field].includes(inputValue)) {
            const newConfig = new CategorizerConfigUrlCleaner({...entry.config});
            newConfig[field].push(inputValue);

            if (field === CategorizerConfigUrlCleanerField.EXCLUDED_QUERY_ARGS) {
                newConfig.retained_query_args = [];
            } else if (field === CategorizerConfigUrlCleanerField.RETAINED_QUERY_ARGS) {
                newConfig.excluded_query_args = [];
            }
            handleChange(CategorizerConfigEntryField.CONFIG, newConfig);
        }
        handleChangeInputValue(field, "");
    };

    const handleDelete = (field: CategorizerConfigUrlCleanerField, value: string) => {
        const newValues = entry.config[field].filter((it) => it !== value);
        handleChangeConfig(field, newValues);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await session.restCategorizerEntry.update(entry);
            alert.updateWithSuccess(`${textCategorizer(`config_type.${CategorizerConfigType.URL_CLEANER.name}`)} configuration`);
            onSubmit();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate(`${textCategorizer(`config_type.${CategorizerConfigType.URL_CLEANER.name}`)} configuration`, e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={!!initEntry}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategorizer("modal.edit_configuration", {type: textCategorizer(`config_type.${CategorizerConfigType.URL_CLEANER.name}`)})}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <CategorizerConfigEntryInfo currentEntry={entry}/>
                        <Accordion>
                            {Object.values(CategorizerConfigUrlCleanerField).map((field) =>
                                <AccordionItem key={field} heading={textCategorizer(`field.${CategorizerConfigEntryField.CONFIG}.${field}`)}>
                                    <FormLayoutRows key={field} spacing={FlexContentSpacing.SMALL}>
                                        <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                            <FieldBlock>
                                                <InputText
                                                    value={inputValues.get(field)}
                                                    onChange={(value) => handleChangeInputValue(field, value)}
                                                    placeholder={textCategorizer(`placeholder.${CategorizerConfigType.URL_CLEANER.name}.${field}`)}
                                                />
                                                <Button style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={() => handleAdd(field)} disabled={!inputValues.get(field)}>
                                                    {textCommon(Action.ADD.labelKey)}
                                                </Button>
                                            </FieldBlock>
                                            {[CategorizerConfigUrlCleanerField.EXCLUDED_QUERY_ARGS, CategorizerConfigUrlCleanerField.RETAINED_QUERY_ARGS].includes(field) &&
                                                <Alert text={textCategorizer(`tooltip.${field}`)} severity={AlertSeverity.INFO}/>
                                            }
                                        </FormLayoutRows>
                                        <ElementList inline>
                                            {entry.config[field].map((it) =>
                                                <TagWithAction
                                                    key={`${field}_${it}`}
                                                    value={it}
                                                    onDelete={() => handleDelete(field, it)}
                                                    active
                                                />
                                            )}
                                        </ElementList>
                                    </FormLayoutRows>
                                </AccordionItem>
                            )}
                        </Accordion>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditCategorizerConfigUrlCleaner;
