import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {Status} from "../../../../utils/Status";
import {SearchQuery} from "../../../interface/SearchQuery";

export class HelperDomainSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    partner_id: number | undefined;
    status: string | undefined;

    withSearchParams(params: SearchParams): HelperDomainSearchQuery {
        let newQuery = super.withSearchParams(params) as HelperDomainSearchQuery;
        newQuery.partner_id = params.getNumber(SearchParamsField.PARTNER);
        newQuery.status = params.getString(SearchParamsField.STATUS);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            search: this.query,
            size: this.size,
            page: this.page,
            partner_id: this.partner_id,
            status: this.status || [Status.ACTIVE.name, Status.INACTIVE.name]
        };
    }
}
