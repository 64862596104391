import {Action, ButtonLink, ButtonLinkCancel, ButtonValidate, ElementList, ElementListSize, FieldBlock, FlexContentSpacing, FormLayoutRows, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, TranslationLibFile} from "@sirdata/ui-lib";
import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {AdsAccess} from "../../../api/model/audience/ads/AdsAccess";
import {AdsAccessConfigurationField} from "../../../api/model/audience/ads/AdsAccessConfigurationField";
import {AdsAccessField} from "../../../api/model/audience/ads/AdsAccessField";
import {AdsAccessSearchQuery} from "../../../api/model/audience/ads/AdsAccessSearchQuery";
import {Partner} from "../../../api/model/partner/Partner";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {SearchItems, TagPartnerRow} from "../../snippet";

type ModalAddSharedTaxonomyPartnersProps = {
    active: boolean;
    access: AdsAccess;
    onClose: (refresh: boolean) => void;
};

const ModalAddSharedTaxonomyPartners: FunctionComponent<ModalAddSharedTaxonomyPartnersProps> = ({active, access, onClose}) => {
    const alert = useAlert();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textAdsAccess} = useTranslation(TranslationPortalFile.ADS_ACCESS);
    const [isLoading, setLoading] = useState(false);
    const [partners, setPartners] = useState<Partner[]>([]);
    const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
    const [highlightedPartners, setHighlightedPartners] = useState<Partner[]>();
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (active) {
            (async () => {
                try {
                    setLoading(true);
                    setSelectedPartners([]);

                    const searchQuery = new AdsAccessSearchQuery().withPublicTaxonomy();
                    const searchResult = await session.restAdsAccess.search(searchQuery);
                    const searchResultPartnerIds = new Set(searchResult.elements.map((element) => element.partner_id));

                    const partners = await session.getPartners();
                    const filteredPartners = partners.filter((partner) => searchResultPartnerIds.has(partner.id) && !(access.configuration.shared_taxonomy || []).includes(partner.id));
                    setPartners(filteredPartners);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("partners", e.message);
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [active, access.configuration.shared_taxonomy, alert]);

    const handleAddPartners = (partners: Partner[]) => {
        setSelectedPartners((prevState) => [...prevState, ...partners]);
        setHighlightedPartners(partners);
        setTimeout(() => {
            setHighlightedPartners([]);
        }, 1000);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            const partnerIds = Array.from(new Set([
                ...access.configuration[AdsAccessConfigurationField.SHARED_TAXONOMY] || [],
                ...selectedPartners.map((partner) => partner.id)
            ]));
            await session.restAdsAccess.updateSharedTaxonomy(access.id, partnerIds);
            alert.updateWithSuccess(textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.SHARED_TAXONOMY}`));
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate(textAdsAccess(`field.${AdsAccessField.CONFIGURATION}.${AdsAccessConfigurationField.SHARED_TAXONOMY}`), e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <ModalNew onClose={() => onClose(false)} active={active}>
                <ModalHeader>
                    <ModalHeaderTitle title={textAdsAccess("modal.add_shared_taxonomy_partners.title")}/>
                </ModalHeader>
                <ModalContent>
                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                        <SearchItems
                            items={partners}
                            selectedItems={selectedPartners}
                            searchField="nameWithCompany"
                            onSubmit={handleAddPartners}
                            loading={isLoading}
                        />
                        <FieldBlock
                            label={textAdsAccess("modal.partners.selected", {count: selectedPartners.length})}
                            actions={
                                selectedPartners.length && <ButtonLink onClick={() => setSelectedPartners([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>
                            }
                        >
                            <ElementList placeholder={textAdsAccess("modal.partners.no_selected")} size={ElementListSize.BIG}>
                                {selectedPartners.map((partner) =>
                                    <TagPartnerRow
                                        key={partner.id}
                                        partner={partner}
                                        isHighlighted={highlightedPartners?.some(({id}) => partner.id === id)}
                                        onRemove={() => setSelectedPartners(selectedPartners.filter(({id}) => partner.id !== id))}
                                    />
                                )}
                            </ElementList>
                        </FieldBlock>
                    </FormLayoutRows>
                </ModalContent>
                <ModalActions>
                    <ButtonLinkCancel onClick={() => onClose(false)}/>
                    <ButtonValidate onClick={handleSubmit} disabled={!selectedPartners.length} loading={isSubmitting}/>
                </ModalActions>
            </ModalNew>
        </>
    );
};

export default ModalAddSharedTaxonomyPartners;
