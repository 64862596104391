import {Action, IconTooltip, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import clsx from "clsx";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CategoryGroup} from "../../../api/model/audience/category/CategoryGroup";
import {Tag} from "../index";

type TagCategoryGroupRowProps = {
    categoryGroup: CategoryGroup;
    isHighlighted?: boolean;
    onRemove?: () => void;
};

const TagCategoryGroupRow: FunctionComponent<TagCategoryGroupRowProps> = ({categoryGroup, isHighlighted, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <div className={clsx("element-list__tag-row", {"element-list__tag-row--highlighted": isHighlighted})}>
            <span className="element-list__tag-row__infos">
                <Tag label={categoryGroup.topTier || categoryGroup.name} style={TagStyle.DEFAULT_OCEAN}/>
                <span>{categoryGroup.name} ({categoryGroup.id})</span>
            </span>
            {onRemove && <IconTooltip icon={Action.REMOVE.icon} text={textCommon(Action.REMOVE.labelKey)} onClick={onRemove}/>}
        </div>
    );
};

export default TagCategoryGroupRow;
