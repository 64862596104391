import {Rest} from "../../common/api/rest/Rest";
import {SegmentLinkAreaCodes} from "../model/audience/segment/SegmentLinkAreaCodes";

export class RestSegmentLinkAreaCode extends Rest {
    rootPath = "/console-api/segment";

    list(segmentId: number): Promise<SegmentLinkAreaCodes[]> {
        return this._client.get(`${this.rootPath}/${segmentId}/area-codes`, SegmentLinkAreaCodes) as Promise<SegmentLinkAreaCodes[]>;
    }

    add(segmentId: number, link: SegmentLinkAreaCodes): Promise<SegmentLinkAreaCodes> {
        return this._client.post(`${this.rootPath}/${segmentId}/area-codes`, link, SegmentLinkAreaCodes) as Promise<SegmentLinkAreaCodes>;
    }

    update(segmentId: number, link: SegmentLinkAreaCodes): Promise<SegmentLinkAreaCodes> {
        return this._client.put(`${this.rootPath}/${segmentId}/area-codes/${link.id}`, link, SegmentLinkAreaCodes) as Promise<SegmentLinkAreaCodes>;
    }

    delete(segmentId: number, link: SegmentLinkAreaCodes) {
        return this._client.delete( `${this.rootPath}/${segmentId}/area-codes/${link.id}`);
    }
}
