import {ApiModel} from "../../../common/api/model/ApiModel";
import {JiraIssueType} from "./JiraIssueType";
import {JiraUser} from "./JiraUser";

export class JiraProject extends ApiModel {
    id: string = "";
    name: string = "";
    key: string = "";
    issue_types: JiraIssueType[] = [];
    users: JiraUser[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static FRONT_PROJECT_ID = "10027";
}
