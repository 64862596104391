import {TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {PartnerOrganizationGroup} from "../../../../api/model/partner/organization/PartnerOrganizationGroup";
import {User} from "../../../../api/model/user/User";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelUser, Tag} from "../../index";

type PartnerOrganizationGroupRowProps = {
    organizationGroup: PartnerOrganizationGroup;
}

const PartnerOrganizationGroupRow: FunctionComponent<PartnerOrganizationGroupRowProps> = ({organizationGroup}) => {
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);

    return (
        <TableRow onClick={{link: organizationGroup.getRoute()}}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{organizationGroup.id}</TableColumn>
            <TableColumn>
                {organizationGroup.type &&
                    <Tag label={textOrganizations(`type.${organizationGroup.type}`)} style={TagStyle.PRIMARY_OCEAN}/>
                }
            </TableColumn>
            <TableColumn>{organizationGroup.name}</TableColumn>
            <TableColumn>{organizationGroup.description}</TableColumn>
            <TableColumn>
                {organizationGroup.domains.map((domain) =>
                    <Tag key={domain} label={domain} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                )}
            </TableColumn>
            <TableColumn><LabelUser user={new User(organizationGroup.owner)}/></TableColumn>
        </TableRow>
    );
};

export default PartnerOrganizationGroupRow;
