import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CurrencyType} from "../../currency/Currency";
import {DistributionMethod} from "./DistributionMethod";

export class DistributionBilling extends ApiModel {
    invoicing: boolean = false;
    type?: DistributionMethod;
    currency: CurrencyType = CurrencyType.EUR;
    comment: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            invoicing: this.invoicing,
            type: this.invoicing ? this.type : undefined,
            currency: this.currency,
            comment: this.comment
        };
    }
}