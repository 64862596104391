import {Rest} from "../../common/api/rest/Rest";
import {CategorizerConfigEntryGroup} from "../model/categorizer/CategorizerConfigEntryGroup";
import {CategorizerConfigSearchResultItem} from "../model/categorizer/CategorizerConfigSearchResultItem";
import {CategorizerConfigType} from "../model/categorizer/CategorizerConfigType";

export class RestCategorizerDomain extends Rest {
    rootPath = "/console-api/categorizer/domain";

    list(): Promise<CategorizerConfigSearchResultItem[]> {
        return this._client.get(`${this.rootPath}`, CategorizerConfigSearchResultItem, {queryParams: {types: CategorizerConfigType.values().map((it) => it.name)}}) as Promise<CategorizerConfigSearchResultItem[]>;
    }

    getEntryGroup(domain: string): Promise<CategorizerConfigEntryGroup[]> {
        return this._client.get(`${this.rootPath}/${domain || "''"}`, CategorizerConfigEntryGroup, {queryParams: {types: CategorizerConfigType.values().map((it) => it.name)}}) as Promise<CategorizerConfigEntryGroup[]>;
    }
}
