import React, {IframeHTMLAttributes} from "react";
import {Box, BoxRadius, BoxSpacing} from "@sirdata/ui-lib";
import {Property} from "csstype";

type IframeBlockProps = IframeHTMLAttributes<HTMLIFrameElement> & {
    radius?: BoxRadius;
    height?: Property.Height;
}

const IframeBlock: React.FC<IframeBlockProps> = ({radius, height, title, ...rest}) => {
    return (
        <Box spacing={BoxSpacing.NONE} radius={radius}>
            <div className="iframe-block" style={{paddingBottom: height}}>
                <iframe
                    title={title}
                    {...rest}
                />
            </div>
        </Box>
    );
};

export default IframeBlock;
