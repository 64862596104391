import {ApiModel} from "../../../../common/api/model/ApiModel";

export class RevenueImportResponse extends ApiModel {
    job_uuid: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
