import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DataleaksMechanism} from "../DataleaksMechanism";
import {DataleaksScope} from "../DataleaksScope";

export class DataleaksAuditInfos extends ApiModel {
    mechanism: DataleaksMechanism = DataleaksMechanism.NONE;
    cmp_id: number = 0;
    cmp_name: string = "";
    tc_string: string = "";
    scope: DataleaksScope | "" = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
