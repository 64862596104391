import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DataleaksSeverity} from "../DataleaksSeverity";

export class DataleaksVersionSeveritiesWeights extends ApiModel {
    [DataleaksSeverity.CRITICAL]: number = 0;
    [DataleaksSeverity.MAJOR]: number = 0;
    [DataleaksSeverity.MINOR]: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    setWeight(severity: DataleaksSeverity, value: number) {
        this[severity] = value;
    }

    getWeight(severity: DataleaksSeverity): number {
        return this[severity];
    }
}
