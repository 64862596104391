import {PAGE_SIZE} from "../../../../../utils/constants";
import {SearchParams} from "../../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../../utils/SearchParamsField";
import {Status} from "../../../../../utils/Status";
import {SearchQuery} from "../../../../interface/SearchQuery";

export enum DistributionLicenseSearchParamsField {
    EXTERNAL_MAP_NAME = "external_map_name"
}

export class DistributionLicenseSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    status?: string;
    external_map_name?: string;

    withSearchParams(params: SearchParams): DistributionLicenseSearchQuery {
        let newQuery = super.withSearchParams(params) as DistributionLicenseSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS);
        newQuery.external_map_name = params.getString(DistributionLicenseSearchParamsField.EXTERNAL_MAP_NAME);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            query: this.query,
            sort: this.sortBy,
            reverse: this.reverseOrder,
            page: this.page,
            size: this.size,
            active: !!this.status ? `${this.status === Status.ACTIVE.name}` : undefined,
            external_map_name: this.external_map_name
        };
    }
}