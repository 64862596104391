import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {Category} from "../model/audience/category/Category";
import {CategoryGroup} from "../model/audience/category/CategoryGroup";
import {CategoryGroupSearchQuery} from "../model/audience/category/CategoryGroupSearchQuery";
import {CategoryGroupSearchResult} from "../model/audience/category/CategoryGroupSearchResult";

export class RestCategoryGroup extends Rest implements RestService<CategoryGroup> {
    rootPath = "/console-api/category/group";

    search(params: CategoryGroupSearchQuery): Promise<CategoryGroupSearchResult> {
        return this._client.get(`${this.rootPath}`, CategoryGroupSearchResult, {queryParams: params.getJsonParameters()}) as Promise<CategoryGroupSearchResult>;
    }

    create(categoryGroup: CategoryGroup): Promise<CategoryGroup> {
        return this._client.post(`${this.rootPath}`, categoryGroup, CategoryGroup) as Promise<CategoryGroup>;
    }

    get(categoryGroupId: number): Promise<CategoryGroup> {
        return this._client.get(`${this.rootPath}/${categoryGroupId}`, CategoryGroup) as Promise<CategoryGroup>;
    }

    update(categoryGroup: CategoryGroup): Promise<CategoryGroup> {
        return this._client.put(`${this.rootPath}/${categoryGroup.id}`, categoryGroup, CategoryGroup) as Promise<CategoryGroup>;
    }

    delete(categoryGroupId: number) {
        return this._client.delete(`${this.rootPath}/${categoryGroupId}`);
    }

    export(params: CategoryGroupSearchQuery): Promise<Blob> {
        return this._client.get(`${this.rootPath}/export?${params.toQueryString()}`) as Promise<Blob>;
    }

    getWithKeywords(): Promise<number[]> {
        return this._client.get(`${this.rootPath}/with-keywords`) as Promise<number[]>;
    }

    getWithBigQueryRequests(): Promise<number[]> {
        return this._client.get(`${this.rootPath}/with-bq-requests`) as Promise<number[]>;
    }

    getEnrichmentConfigs(categoryGroupIds: number[]): Promise<Map<string, object | null>> {
        return this._client.get(`${this.rootPath}/with-enrichment`, undefined, {queryParams: {category_group_ids: categoryGroupIds.join(",")}}) as Promise<Map<string, object | null>>;
    }

    addCategoryType(category: Category): Promise<Category> {
        return this._client.post(`${this.rootPath}/${category.id_group}/category`, category, Category) as Promise<Category>;
    }
}
