import {TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import {FC} from "react";
import {DataleaksRegulation} from "../../../../api/model/dataleaks/DataleaksRegulation";
import {DataleaksTrait} from "../../../../api/model/dataleaks/DataleaksTrait";
import {DataleaksVersionScoringDetails} from "../../../../api/model/dataleaks/version/DataleaksVersionScoringDetails";
import {formatPercentage} from "../../../../utils/string";
import {DataleaksScore} from "../../index";

type DataleaksVersionScoringDetailsRowProps = {
    label: string;
    scoringDetails: DataleaksVersionScoringDetails | undefined;
    questionIds: number[];
};

const DataleaksVersionScoringDetailsRow: FC<DataleaksVersionScoringDetailsRowProps> = ({label, scoringDetails, questionIds}) => {
    if (!scoringDetails) return <></>;

    const buildRegulationScores = (regulation: DataleaksRegulation) => {
        const rate = scoringDetails.regulations.get(regulation);
        const rateSmartTags = scoringDetails.regulations_smart_tags.get(regulation);
        if (!rate) return;

        return (
            <>
                <DataleaksScore value={rate}/>
                {rateSmartTags && rateSmartTags !== rate &&
                    <span> (<DataleaksScore value={rateSmartTags}/>)</span>
                }
            </>
        );
    };

    return (
        <TableRow>
            <TableColumn>{`${label} (${formatPercentage(scoringDetails.rate)})`}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {buildRegulationScores(DataleaksRegulation.GDPR)}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {buildRegulationScores(DataleaksRegulation.EPRIVACY)}
            </TableColumn>
            {questionIds.map((id) =>
                <TableColumn key={id} styles={TableColumnStyle.ALIGN_CENTER}>
                    <DataleaksScore value={scoringDetails.questions.get(id) || 0}/>
                </TableColumn>
            )}
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <DataleaksScore value={scoringDetails.traits.get(DataleaksTrait.FREE)}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <DataleaksScore value={scoringDetails.traits.get(DataleaksTrait.INFORMED)}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <DataleaksScore value={scoringDetails.traits.get(DataleaksTrait.SPECIFIC)}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <DataleaksScore value={scoringDetails.traits.get(DataleaksTrait.UNAMBIGUOUS)}/>
            </TableColumn>
        </TableRow>
    );
};

export default DataleaksVersionScoringDetailsRow;
