import {
    Accordion,
    AccordionItem,
    AccordionItemContent,
    AlertSeverity,
    Color,
    ContentBlock,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentSpacing,
    Icon,
    InputNumber,
    Table,
    TableColumn,
    TableRow,
    TagStyle
} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksVersionField} from "../../../api/model/dataleaks/version/DataleaksVersionField";
import {DataleaksVersion} from "../../../api/model/dataleaks/version/DataleaksVersion";
import {DataleaksTrait} from "../../../api/model/dataleaks/DataleaksTrait";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";
import {Tag} from "../index";
import {DataleaksQuestionTextsField} from "../../../api/model/dataleaks/question/DataleaksQuestionTextsField";
import {Locale} from "../../../common/utils/Locale";
import {DataleaksVersionQuestionField} from "../../../api/model/dataleaks/version/DataleaksVersionQuestionField";
import {FormLayoutMessage} from "../../../common/component/snippet";

type DataleaksVersionTraitWeightsProps = {
    version: DataleaksVersion;
    questions: DataleaksQuestion[];
    onChange: (questionId: number, value: any) => void;
};

const DataleaksVersionTraitWeights: FunctionComponent<DataleaksVersionTraitWeightsProps> = ({version, questions, onChange}) => {
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);
    const {t: textDataleaksVersions} = useTranslation(TranslationPortalFile.DATALEAKS_VERSIONS);

    const getQuestionsByTrait = (trait: DataleaksTrait): DataleaksQuestion[] => {
        return questions.filter((question) => question.trait === trait);
    };

    const getTotalWeightForTrait = (trait: DataleaksTrait): number => {
        let total = 0;
        const questionIds = getQuestionsByTrait(trait).map((it) => it.id);
        version.questions.filter((it) => questionIds.includes(it.question_id)).forEach((it) => {
            total += it.trait_weight;
        });
        return total;
    };

    const getText = (question: DataleaksQuestion, field: DataleaksQuestionTextsField): string => {
        const texts = question.texts.get(Locale.FRENCH);
        if (!texts) return "";
        return texts![field];
    };

    return (
        <ContentBlock header={{title: {label: textDataleaksVersions("section.weights_per_trait")}}}>
            <Accordion>
                {Object.values(DataleaksTrait).map((trait) =>
                    <AccordionItem
                        key={trait}
                        heading={
                            <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                                <Tag label={textDataleaksQuestions(`trait.${trait}`)} style={TagStyle.PRIMARY_OCEAN}/>
                                {getTotalWeightForTrait(trait) !== 100 &&
                                    <Icon name="error" outlined colorIcon={Color.DANGER_MAIN}/>
                                }
                            </FlexContent>
                        }
                    >
                        <AccordionItemContent>
                            <Table
                                columns={[
                                    {width: 5, label: textDataleaksVersions(`field.${DataleaksVersionField.QUESTIONS}.${DataleaksVersionQuestionField.INDEX}`)},
                                    {width: 70, label: textDataleaksVersions(`field.${DataleaksVersionField.QUESTIONS}.${DataleaksVersionQuestionField.QUESTION_ID}`)},
                                    {width: 25, label: textDataleaksVersions(`field.${DataleaksVersionField.QUESTIONS}.${DataleaksVersionQuestionField.TRAIT_WEIGHT}`)}
                                ]}
                            >
                                {getQuestionsByTrait(trait).map((question) =>
                                    <TableRow key={question.id}>
                                        <TableColumn>
                                            {version.questions.find((it) => it.question_id === question.id)?.index}
                                        </TableColumn>
                                        <TableColumn>
                                            {getText(question, DataleaksQuestionTextsField.TITLE)}
                                        </TableColumn>
                                        <TableColumn>
                                            <InputNumber
                                                value={version.questions.find((it) => it.question_id === question.id)!.trait_weight}
                                                onChange={(value) => onChange(question.id, value)}
                                                min={1}
                                                max={100}
                                                small
                                            />
                                            <span>%</span>
                                        </TableColumn>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableColumn colSpan={2}>
                                        <strong>{textDataleaksVersions("total")}</strong>
                                    </TableColumn>
                                    <TableColumn>
                                        <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                                            <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                                                <InputNumber
                                                    value={getTotalWeightForTrait(trait)}
                                                    onChange={() => {}}
                                                    small
                                                    max={100}
                                                    disabled
                                                />
                                                <span>%</span>
                                            </FlexContent>
                                            {getTotalWeightForTrait(trait) !== 100 &&
                                                <FormLayoutMessage
                                                    message={textDataleaksVersions("message.invalid_total_weight")}
                                                    severity={AlertSeverity.DANGER}
                                                    small
                                                />
                                            }
                                        </FieldBlock>
                                    </TableColumn>
                                </TableRow>
                            </Table>
                        </AccordionItemContent>
                    </AccordionItem>
                )}
            </Accordion>
        </ContentBlock>
    );
};

export default DataleaksVersionTraitWeights;
