export enum CmpConfigThemeBorderRadius {
    NONE = "NONE",
    LIGHT = "LIGHT",
    AVERAGE = "AVERAGE",
    STRONG = "STRONG"
}

export const CMP_CONFIG_THEME_BORDER_RADIUS: CmpConfigThemeBorderRadius[] = [
    CmpConfigThemeBorderRadius.NONE,
    CmpConfigThemeBorderRadius.LIGHT,
    CmpConfigThemeBorderRadius.AVERAGE,
    CmpConfigThemeBorderRadius.STRONG
];
