export enum CmpConfigThemeFontFamily {
    HELVETICA = "Helvetica",
    IBM_PLEX_SANS = "IBM Plex Sans",
    MONTSERRAT = "Montserrat",
    ROBOTO = "Roboto",
    ROBOTO_SLAB = "Roboto Slab",
    MANROPE = "Manrope",
    QUICKSAND = "Quicksand"
}

export const CMP_CONFIG_THEME_FONT_FAMILIES: CmpConfigThemeFontFamily[] = [
    CmpConfigThemeFontFamily.HELVETICA,
    CmpConfigThemeFontFamily.IBM_PLEX_SANS,
    CmpConfigThemeFontFamily.MONTSERRAT,
    CmpConfigThemeFontFamily.ROBOTO,
    CmpConfigThemeFontFamily.ROBOTO_SLAB,
    CmpConfigThemeFontFamily.MANROPE,
    CmpConfigThemeFontFamily.QUICKSAND
];
