import {CategorizerConfig} from "./CategorizerConfig";
import {CategorizerConfigEnrichmentField} from "./CategorizerConfigEnrichmentField";
import {CategorizerConfigEnrichmentMode} from "./CategorizerConfigEnrichmentMode";

export class CategorizerConfigEnrichment extends CategorizerConfig {
    mode: string = CategorizerConfigEnrichmentMode.SUBSTITUTE.name;
    points: Map<string, Map<string, number>> = new Map<string, Map<string, number>>();
    propagate_points: boolean = false;

    static MIN_POINTS_VALUE = 0;
    static MAX_POINTS_VALUE = 1000;
    static POINT_STEP = 100;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === CategorizerConfigEnrichmentField.POINTS) {
                    this.points = new Map<string, Map<string, number>>();
                    let mapCategoryGroups: Map<string, object> = (o[key] instanceof Map) ? o[key] : new Map(Object.entries(o[key]));
                    // @ts-ignore
                    for (let [groupId, mapCategories] of mapCategoryGroups) {
                        let mapCategoryPoints = new Map<string, number>();
                        let prevMapCategories: Map<string, number> = (mapCategories instanceof Map) ? mapCategories : new Map(Object.entries(mapCategories));
                        // @ts-ignore
                        for (let [catId, points] of prevMapCategories) {
                            mapCategoryPoints.set(catId, points);
                        }
                        this.points.set(groupId, mapCategoryPoints);
                    }
                } else this[key] = o[key];
            }
        }
    }

    getPointsAsJson(): { [key: string]: object } {
        let pointsAsJson: { [key: string]: { [groupId: string]: number } } = {};
        this.points.forEach((mapCategories, groupId) => {
            if (mapCategories.size) {
                pointsAsJson[groupId] = {};
                mapCategories.forEach((point, categoryId) => {
                    pointsAsJson[groupId][categoryId] = point;
                });
            }
        });
        return pointsAsJson;
    }

    getJsonParameters(): {} {
        return {
            mode: this.mode,
            points: this.getPointsAsJson(),
            propagate_points: this.propagate_points
        };
    }
}
