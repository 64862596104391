export enum PartnerAccountField {
    ID = "id",
    ACTIVE = "active",
    EMAIL = "email",
    EMAIL_UPDATE = "email_update",
    FIRST_NAME = "first_name",
    LAST_NAME = "last_name",
    POSTIION = "position",
    PHONE = "phone",
    PHONE_REGION = "phone_region",
    TYPE = "type",
    PARTNER_ID = "partner_id",
    PROPERTIES = "properties"
}
