import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, ContentBlock, ContentBlockAction, SearchError, SearchToolbar, Table} from "@sirdata/ui-lib";
import {PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import {RestrictedContent} from "../../../common/component/widget";
import {Authorization} from "../../../api/model/account/Authorization";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {sortItems} from "../../../common/utils/helper";
import {CmpSirdataListVendorRow} from "../../../component/snippet";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataListLanguage} from "../../../api/model/cmp/list/sirdata-list/SirdataListLanguage";
import {SirdataListVendor} from "../../../api/model/cmp/list/sirdata-list/SirdataListVendor";
import {SirdataListVendorField} from "../../../api/model/cmp/list/sirdata-list/SirdataListVendorField";
import {SirdataListVersionField} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersionField";
import ModalCreateCmpSirdataListVendor from "../../modal/cmp-sirdata-list/ModalCreateCmpSirdataListVendor";
import ModalEditCmpSirdataListVendor from "../../modal/cmp-sirdata-list/ModalEditCmpSirdataListVendor";
import ModalConfirmDelete from "../../modal/ModalConfirmDelete";
import ModalConfirmMessage from "../../modal/ModalConfirmMessage";
import {SirdataListVersion} from "../../../api/model/cmp/list/sirdata-list/SirdataListVersion";
import {Formatter} from "../../../common/utils/Formatter";

type CmpSirdataListVendorsEditableProps = {
    latestVersion: SirdataListVersion;
    globalVendorList: GlobalVendorList;
    listLanguage: SirdataListLanguage;
    listVendors: SirdataListVendor[];
    onChange: () => void;
};

const CmpSirdataListVendorsEditable: FC<CmpSirdataListVendorsEditableProps> = ({latestVersion, globalVendorList, listLanguage, listVendors, onChange}) => {
    const alert = useAlert();
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const [query, setQuery] = useState("");
    const [filteredVendors, setFilteredVendors] = useState<SirdataListVendor[]>([]);
    const [isShowModalCreateCmpSirdataListVendor, setShowModalCreateCmpSirdataListVendor] = useState(false);
    const [activeEditVendor, setActiveEditVendor] = useState<SirdataListVendor>();
    const [activeDeleteVendor, setActiveDeleteVendor] = useState<SirdataListVendor>();
    const [activeRestoreVendor, setActiveRestoreVendor] = useState<SirdataListVendor>();

    useEffect(() => {
        const filteredVendors = listVendors.filter((vendor) => vendor.name.toLowerCase().includes(query.toLowerCase()));
        setFilteredVendors(filteredVendors);
        handleSortVendors(SirdataListVendorField.ID, true);
    }, [listVendors, query]);

    const handleSortVendors = (field: string, reverseOrder?: boolean) => {
        setFilteredVendors((prevState) => sortItems([...prevState], field, reverseOrder));
    };

    const handleDeleteVendor = async () => {
        if (!activeDeleteVendor) {
            return;
        }
        try {
            const versionVendor = latestVersion.vendors.find((it) => it.id === activeDeleteVendor.id);
            if (versionVendor) {
                activeDeleteVendor.deletedDate = versionVendor.deletedDate || Formatter.convertDateToUTC(new Date());
                await session.restCmpSirdataListVendor.update(activeDeleteVendor);
            } else {
                await session.restCmpSirdataListVendor.delete(activeDeleteVendor.id);
            }
            alert.deleteWithSuccess("vendor");
            onChange();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("vendor", e.message);
            }
        } finally {
            setActiveDeleteVendor(undefined);
        }
    };

    const handleRestoreVendor = async () => {
        if (!activeRestoreVendor) {
            return;
        }
        try {
            const newVendor = new SirdataListVendor({
                ...activeRestoreVendor,
                [SirdataListVendorField.DELETED_DATE]: undefined
            });
            await session.restCmpSirdataListVendor.update(newVendor);
            alert.actionWithSuccess("vendor restored");
            onChange();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("restore vendor", e.message);
            }
        } finally {
            setActiveRestoreVendor(undefined);
        }
    };

    return (
        <ContentBlock
            header={{
                title: {label: textCmpSirdataList(`field.${SirdataListVersionField.VENDORS}`)},
                actions: (
                    <RestrictedContent allowedTo={Authorization.CMP_SIRDATA_LIST.update}>
                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalCreateCmpSirdataListVendor(true)}/>
                    </RestrictedContent>
                )
            }}
        >
            <SearchToolbar
                searchBar={{
                    value: query,
                    placeholder: textCmpSirdataList(`search.placeholder.${SirdataListVersionField.VENDORS}`),
                    onSubmit: setQuery
                }}
            />
            {!!filteredVendors.length || !query ?
                <Table
                    onSort={handleSortVendors}
                    columns={[
                        {width: 5, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.ID}`), sort: {field: SirdataListVendorField.ID}},
                        {width: 30, label: textCmpSirdataList(`field.vendor.${SirdataListVendorField.NAME}`)},
                        {width: 35, label: textCmpSirdataList("field.tcf_purposes")},
                        {width: 25, label: textCmpSirdataList("field.sirdata_purposes")},
                        {width: 5}
                    ]}
                    pagination={PAGE_SIZE}
                    messageIfEmpty={textCmpSirdataList("message.no_vendors")}
                >
                    {filteredVendors.map((vendor) =>
                        <CmpSirdataListVendorRow
                            key={vendor.id}
                            vendor={vendor}
                            latestDeletedDate={latestVersion.vendors.find((it) => it.id === vendor.id)?.deletedDate}
                            state={vendor.getState(latestVersion)}
                            onEdit={() => setActiveEditVendor(vendor)}
                            onDelete={() => setActiveDeleteVendor(vendor)}
                            onRestore={() => setActiveRestoreVendor(vendor)}
                        />
                    )}
                </Table> :
                <SearchError query={query}/>
            }
            <ModalCreateCmpSirdataListVendor
                purposes={globalVendorList.purposes}
                extraPurposes={listLanguage.purposes}
                active={isShowModalCreateCmpSirdataListVendor}
                onClose={async (refresh) => {
                    setShowModalCreateCmpSirdataListVendor(false);
                    refresh && onChange();
                }}
            />
            <ModalEditCmpSirdataListVendor
                initVendor={activeEditVendor}
                purposes={globalVendorList.purposes}
                extraPurposes={listLanguage.purposes}
                onClose={async (refresh) => {
                    setActiveEditVendor(undefined);
                    refresh && onChange();
                }}
            />
            <ModalConfirmDelete
                active={!!activeDeleteVendor}
                entity={`vendor ${activeDeleteVendor?.name}`}
                confirm={handleDeleteVendor}
                cancel={() => setActiveDeleteVendor(undefined)}
            />
            <ModalConfirmMessage
                active={!!activeRestoreVendor}
                message={textCmpSirdataList("message.confirm_vendor_restore", {vendor: activeRestoreVendor?.name})}
                confirm={handleRestoreVendor}
                confirmAction={textCmpSirdataList("action.restore")}
                cancel={() => setActiveRestoreVendor(undefined)}
            />
        </ContentBlock>
    );
};

export default CmpSirdataListVendorsEditable;
