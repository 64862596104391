import {TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {Distribution} from "../../../../api/model/audience/distribution/Distribution";
import {LabelStatus, Tag} from "../../index";

type DistributionRowProps = {
    distribution: Distribution;
};

const DistributionRow: FunctionComponent<DistributionRowProps> = ({distribution}) => {
    return (
        <TableRow onClick={{link: distribution.getRoute()}}>
            <TableColumn>
                <LabelStatus status={distribution.getStatus()}/>
            </TableColumn>
            <TableColumn>{distribution.label}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {distribution.platform_name ?
                    <Tag label={distribution.platform_name} style={TagStyle.PRIMARY_OCEAN}/> : "-"
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {distribution.properties.platform_types.length > 0 ?
                    distribution.properties.platform_types.map((type) =>
                        <Tag key={type} label={type} style={TagStyle.DEFAULT_OCEAN}/>
                    ) : "-"
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {distribution.link_count.segment || "-"}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {distribution.link_count.category || "-"}
            </TableColumn>
        </TableRow>
    );
};

export default DistributionRow;
