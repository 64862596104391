export class CategoryTaxonomy {
    static BRAND_SAFETY: CategoryTaxonomy = new CategoryTaxonomy("BRAND_SAFETY");
    static IAB: CategoryTaxonomy = new CategoryTaxonomy("IAB");
    static PARTNER: CategoryTaxonomy = new CategoryTaxonomy("PARTNER");
    static SIRDATA: CategoryTaxonomy = new CategoryTaxonomy("SIRDATA");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CategoryTaxonomy[] {
        return [
            CategoryTaxonomy.BRAND_SAFETY,
            CategoryTaxonomy.IAB,
            CategoryTaxonomy.PARTNER,
            CategoryTaxonomy.SIRDATA
        ];
    }

    static getByName(name: string): CategoryTaxonomy {
        return this.values().find((it) => it.name === name) || CategoryTaxonomy.SIRDATA;
    }
}
