import {ApiModel} from "../../../../common/api/model/ApiModel";

export class PartnerLicenseLinkActivation extends ApiModel {
    created_at: string = "";
    private _expires_at: string | undefined;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get expires_at(): string {
        return this._expires_at ? this._expires_at : "";
    }

    set expires_at(expires_at: string) {
        this._expires_at = !!expires_at ? expires_at : undefined;
    }
}
