import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchQuery} from "../interface/SearchQuery";
import {SearchResult} from "../interface/SearchResult";
import {DataleaksQuestion} from "../model/dataleaks/question/DataleaksQuestion";

export class RestDataleaksQuestion extends Rest implements RestService<DataleaksQuestion> {
    rootPath = "/console-api/dataleaks/question";

    search(params: SearchQuery): Promise<SearchResult<DataleaksQuestion>> {
        return new Promise<SearchResult<DataleaksQuestion>>(() => {});
    };

    list(): Promise<DataleaksQuestion[]> {
        return this._client.get(`${this.rootPath}`, DataleaksQuestion) as Promise<DataleaksQuestion[]>;
    };

    create(question: DataleaksQuestion): Promise<DataleaksQuestion> {
        return this._client.post(`${this.rootPath}`, question, DataleaksQuestion) as Promise<DataleaksQuestion>;
    }

    get(questionId: number): Promise<DataleaksQuestion> {
        return this._client.get(`${this.rootPath}/${questionId}`, DataleaksQuestion) as Promise<DataleaksQuestion>;
    }

    update(question: DataleaksQuestion): Promise<DataleaksQuestion> {
        return this._client.put(`${this.rootPath}/${question.id}`, question, DataleaksQuestion) as Promise<DataleaksQuestion>;
    }

    delete(questionId: number) {
        return this._client.delete(`${this.rootPath}/${questionId}`);
    }
}
