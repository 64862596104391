import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";
import {PartnerOrganization} from "../model/partner/organization/PartnerOrganization";
import {PartnerOrganizationRequest} from "../model/partner/organization/PartnerOrganizationRequest";
import {PartnerOrganizationRequestDetails} from "../model/partner/organization/PartnerOrganizationRequestDetails";
import {PartnerOrganizationRequestRejectResponse} from "../model/partner/organization/PartnerOrganizationRequestRejectResponse";
import {PartnerOrganizationRequestStatus} from "../model/partner/organization/PartnerOrganizationRequestStatus";
import {PartnerOrganizationRequestValidation} from "../model/partner/organization/PartnerOrganizationRequestValidation";

export class RestPartnerOrganizationRequest extends Rest implements RestService<PartnerOrganizationRequest> {
    rootPath = "/console-api/partner/organization/request";

    search(params: any): Promise<SearchResult<PartnerOrganizationRequest>> {
        return new Promise<SearchResult<PartnerOrganizationRequest>>(() => {}); // TODO Implement route
    }

    list(status?: PartnerOrganizationRequestStatus): Promise<PartnerOrganizationRequest[]> {
        return this._client.get(`${this.rootPath}`, PartnerOrganizationRequest, {queryParams: {status: status?.name}}) as Promise<PartnerOrganizationRequest[]>;
    }

    create(request: PartnerOrganizationRequest): Promise<PartnerOrganizationRequest> {
        return new Promise<PartnerOrganizationRequest>(() => {}); // TODO Implement route
    }

    get(requestId: number): Promise<PartnerOrganizationRequest> {
        return this._client.get(`${this.rootPath}/${requestId}`, PartnerOrganizationRequest) as Promise<PartnerOrganizationRequest>;
    }

    update(request: PartnerOrganizationRequest): Promise<PartnerOrganizationRequest> {
        return new Promise<PartnerOrganizationRequest>(() => {}); // TODO Implement route
    }

    delete(requestId: string) {
        // TODO Implement route
    }

    getDetails(request: PartnerOrganizationRequest): Promise<PartnerOrganizationRequestDetails> {
        return this._client.get(`${this.rootPath}/${request.id}/verify`, PartnerOrganizationRequestDetails) as Promise<PartnerOrganizationRequestDetails>;
    }

    validate(request: PartnerOrganizationRequest, requestValidation: PartnerOrganizationRequestValidation): Promise<PartnerOrganization> {
        return this._client.post(`${this.rootPath}/${request.id}/validate`, requestValidation, PartnerOrganizationRequestValidation) as Promise<PartnerOrganization>;
    }

    reject(request: PartnerOrganizationRequest): Promise<PartnerOrganizationRequestRejectResponse> {
        return this._client.post(`${this.rootPath}/${request.id}/reject`) as Promise<PartnerOrganizationRequestRejectResponse>;
    }
}
