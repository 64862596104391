export class CustomerStorageSortField {
    static CREATION_TS = new CustomerStorageSortField("creation_ts");
    static ID = new CustomerStorageSortField("id");
    static NAME = new CustomerStorageSortField("name");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CustomerStorageSortField[] {
        return [
            CustomerStorageSortField.CREATION_TS,
            CustomerStorageSortField.ID,
            CustomerStorageSortField.NAME
        ];
    }

    static getByName(name?: string): CustomerStorageSortField {
        return this.values().find((it) => it.name === name) || CustomerStorageSortField.CREATION_TS;
    }
}
