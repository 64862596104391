import {usePreprod} from "../../common/utils/helper";
import {CmpConfigVersion} from "../../api/model/cmp/config/CmpConfigVersion";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";

export class CmpPreviewHelper {
    static path = "/cmp-preview.html?";
    static cmpApiRoot = usePreprod ? "https://api-preprod.consentframework.com" : "https://api.consentframework.com";

    static getPath(): string {
        return usePreprod ? `${this.path}&apiRoot=${this.cmpApiRoot}` : this.path;
    }

    static getUrlForVersion(version: CmpConfigVersion, forCCPA?: boolean, forToolbar?: boolean): string {
        const uiVersion = forCCPA ? version.ccpaUIVersion : version.gdprUIVersion;
        return `${this.getPath()}&version=${uiVersion.name}${forToolbar ? "&toolbar" : ""}`;
    }

    static openPreview(cmpConfig: CmpConfig, version?: CmpConfigVersion, forCCPA?: boolean) {
        if (!version) {
            version = cmpConfig.getConfigVersion();
        }

        const url = `${this.getUrlForVersion(version, forCCPA)}&pa=${cmpConfig.id_partner}&c=${cmpConfig.id}`;
        window.open(url, "_blank");
    }
}
