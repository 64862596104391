import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CmpConfigExternalUtiq} from "./CmpConfigExternalUtiq";
import {CmpConfigExternalField} from "./CmpConfigExternalField";

export class CmpConfigExternal extends ApiModel {
    googleAdSense: boolean = false;
    googleAdManager: boolean = false;
    googleAnalytics: boolean = false;
    gtmDataLayer: string = "";
    audienceProfiling: boolean = false;
    shopify: boolean = false;
    microsoftUet: boolean = false;
    consentModeFromTcf: boolean = false;
    utiq: CmpConfigExternalUtiq = new CmpConfigExternalUtiq();

    constructor(o?: {}) {
        super(o);
        this.load(o);
        if (o === undefined) {
            this.googleAdSense = true;
            this.googleAnalytics = true;
        }
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                switch (key) {
                    case CmpConfigExternalField.UTIQ:
                        this[key] = new CmpConfigExternalUtiq(o[key]);
                        break;
                    default:
                        this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            googleAdSense: this.googleAdSense,
            googleAdManager: this.googleAdManager,
            googleAnalytics: this.googleAnalytics,
            gtmDataLayer: this.gtmDataLayer,
            audienceProfiling: this.audienceProfiling,
            shopify: this.shopify,
            microsoftUet: this.microsoftUet,
            consentModeFromTcf: this.consentModeFromTcf,
            utiq: this.utiq
        };
    }
}
