import {
    Action,
    ButtonLink,
    ButtonLinkCancel,
    ButtonValidate,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    Form,
    FormLayoutRows,
    FormValidationType,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CategoryGroup} from "../../../api/model/audience/category/CategoryGroup";
import {CategoryTaxonomy} from "../../../api/model/audience/category/CategoryTaxonomy";
import {TranslationPortalFile} from "../../../utils/constants";
import {TagCategoryGroupRow} from "../../snippet";
import SelectCategoryGroup from "../../snippet/field/SelectCategoryGroup";

type ModalEditCategoryGroupLinkedCategoryGroupsProps = {
    active: boolean;
    taxonomy: CategoryTaxonomy;
    currentLinks: CategoryGroup[];
    onSubmit: (categoryGroups: CategoryGroup[]) => void;
    onClose: () => void;
};

const ModalEditCategoryGroupLinkedCategoryGroups: FunctionComponent<ModalEditCategoryGroupLinkedCategoryGroupsProps> = ({active, taxonomy, currentLinks, onSubmit, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const [selectedCategoryGroups, setSelectedCategoryGroups] = useState<CategoryGroup[]>([]);
    const FORM_ID = "form-edit-category-group-linked-category-groups";

    useEffect(() => {
        setSelectedCategoryGroups(currentLinks);
    }, [currentLinks]);

    const handleAddCategoryGroup = (categoryGroup: CategoryGroup) => {
        setSelectedCategoryGroups((prevState) => ([categoryGroup, ...prevState]));
    };

    const handleRemoveCategoryGroup = (group: CategoryGroup) => {
        setSelectedCategoryGroups((prevState) => prevState.filter((it) => it.id !== group.id));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit(selectedCategoryGroups);
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategories("modal.links.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                        <SelectCategoryGroup
                            value={undefined}
                            onChange={(categoryGroup) => categoryGroup && handleAddCategoryGroup(categoryGroup)}
                            taxonomy={taxonomy}
                            excludedCategoryGroups={selectedCategoryGroups}
                        />
                        <FieldBlock
                            label={textCategories("modal.links.selected", {count: selectedCategoryGroups.length})}
                            actions={
                                <ButtonLink onClick={() => setSelectedCategoryGroups([])}>{textCommon(Action.REMOVE_ALL.labelKey)}</ButtonLink>
                            }
                        >
                            <ElementList placeholder={textCategories("modal.links.search_result")} size={ElementListSize.BIG}>
                                {selectedCategoryGroups.map((item) =>
                                    <TagCategoryGroupRow
                                        key={item.id}
                                        categoryGroup={item}
                                        onRemove={() => handleRemoveCategoryGroup(item)}
                                    />
                                )}
                            </ElementList>
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditCategoryGroupLinkedCategoryGroups;
