import {FunctionComponent} from "react";
import {Action, ButtonStyle, ModalConfirmMessage as ModalConfirmMessageLib, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

export type ModalConfirmMessageProps = {
    active: boolean;
    message: string;
    confirm: () => void;
    confirmAction: Action | string;
    cancel: () => void;
};

const ModalConfirmMessage: FunctionComponent<ModalConfirmMessageProps> = ({active, message, confirm, confirmAction, cancel}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <ModalConfirmMessageLib
            active={active}
            message={message}
            confirm={{onClick: confirm, style: ButtonStyle.DEFAULT_MIDNIGHT, children: textCommon(confirmAction instanceof Action ? confirmAction.labelKey : confirmAction)}}
            cancel={{onClick: cancel, style: ButtonStyle.PRIMARY_MIDNIGHT}}
        />
    );
};

export default ModalConfirmMessage;
