export class AdsAccessAuthorization {
    static CONTEXTUAL = new AdsAccessAuthorization("SIRDATA.LIVE_TARGETING.CONTEXTUAL", "CONTEXTUAL");
    static CONTEXTUAL_MODELED = new AdsAccessAuthorization("SIRDATA.LIVE_TARGETING.CONTEXTUAL_MODELED", "CONTEXTUAL MODELED");
    static COOKIEBASED = new AdsAccessAuthorization("SIRDATA.LIVE_TARGETING.COOKIEBASED", "COOKIEBASED");
    static COOKIELESS = new AdsAccessAuthorization("SIRDATA.LIVE_TARGETING.COOKIELESS", "COOKIELESS");
    static COOKIELESS_CONSENT_BASED = new AdsAccessAuthorization("SIRDATA.LIVE_TARGETING.COOKIELESS_CONSENT_BASED", "COOKIELESS CONSENT BASED");
    static REQUEST_STATS = new AdsAccessAuthorization("SIRDATA.LIVE_TARGETING.REQUEST_STATS", "REQUEST STATS");

    name: string = "";
    label: string = "";

    constructor(name: string, label: string) {
        this.name = name;
        this.label = label;
    }

    static values(): AdsAccessAuthorization[] {
        return [
            AdsAccessAuthorization.CONTEXTUAL,
            AdsAccessAuthorization.CONTEXTUAL_MODELED,
            AdsAccessAuthorization.COOKIEBASED,
            AdsAccessAuthorization.COOKIELESS,
            AdsAccessAuthorization.COOKIELESS_CONSENT_BASED,
            AdsAccessAuthorization.REQUEST_STATS
        ];
    }
}
