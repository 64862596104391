import {LayoutRows, Loadable, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {DataleaksVersion} from "../../api/model/dataleaks/version/DataleaksVersion";
import {DataleaksVersionField} from "../../api/model/dataleaks/version/DataleaksVersionField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {sortItems} from "../../common/utils/helper";
import {DataleaksVersionRow, MainContentHeader} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {MainContent, Wrapper} from "../../common/component/widget";

function DataleaksVersions() {
    const {t: textDataleaksVersions} = useTranslation(TranslationPortalFile.DATALEAKS_VERSIONS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [versions, setVersions] = useState<DataleaksVersion[]>([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const newVersions = await session.restDataleaksVersion.list();
                setVersions(sortItems(newVersions, DataleaksVersionField.ID, true));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("versions", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.DATALEAKS_VERSIONS}/>
            <MainContent>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <Table
                            columns={[
                                {width: 10, label: textDataleaksVersions(`field.${DataleaksVersionField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 50, label: textDataleaksVersions(`field.${DataleaksVersionField.COMMENT}`)},
                                {width: 10, label: textDataleaksVersions(`field.${DataleaksVersionField.QUESTIONS}.`)},
                                {width: 15, label: textDataleaksVersions(`field.${DataleaksVersionField.CREATED_AT}`)},
                                {width: 15, label: textDataleaksVersions(`field.${DataleaksVersionField.UPDATED_AT}`)}
                            ]}
                        >
                            {versions.map((version) =>
                                <DataleaksVersionRow key={version.id} version={version}/>
                            )}
                        </Table>
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default DataleaksVersions;
