import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CmpConfigCCPA extends ApiModel {
    lspa: boolean = false;
    disabled: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            lspa: this.lspa,
            disabled: this.disabled
        };
    }
}
