import React, {Dispatch, FunctionComponent, SetStateAction} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksAudit} from "../../../api/model/dataleaks/audit/DataleaksAudit";
import {DataleaksAuditField} from "../../../api/model/dataleaks/audit/DataleaksAuditField";
import {DataleaksAuditContact} from "../../../api/model/dataleaks/audit/DataleaksAuditContact";
import {DataleaksAuditContactField} from "../../../api/model/dataleaks/audit/DataleaksAuditContactField";
import {FieldBlock, FormLayoutColumns, InputEmail, InputPhone, InputText} from "@sirdata/ui-lib";

type ModalCreateDataleaksAuditContactProps = {
    audit: DataleaksAudit;
    setAudit: Dispatch<SetStateAction<DataleaksAudit>>;
};

const ModalCreateDataleaksAuditContact: FunctionComponent<ModalCreateDataleaksAuditContactProps> = ({audit, setAudit}) => {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);

    const handleChange = (field: DataleaksAuditField, value: any) => {
        setAudit((prevState) => new DataleaksAudit({...prevState, [field]: value}));
    };

    const handleChangeContact = (field: DataleaksAuditContactField, value: any) => {
        handleChange(DataleaksAuditField.CONTACT, new DataleaksAuditContact({...audit.contact, [field]: value}));
    };

    return (
        <>
            <FormLayoutColumns>
                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.CONTACT}.${DataleaksAuditContactField.FIRST_NAME}`)}>
                    <InputText
                        value={audit.contact.first_name}
                        onChange={(value) => handleChangeContact(DataleaksAuditContactField.FIRST_NAME, value)}
                    />
                </FieldBlock>
                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.CONTACT}.${DataleaksAuditContactField.LAST_NAME}`)}>
                    <InputText
                        value={audit.contact.last_name}
                        onChange={(value) => handleChangeContact(DataleaksAuditContactField.LAST_NAME, value)}
                    />
                </FieldBlock>
                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.CONTACT}.${DataleaksAuditContactField.POSITION}`)}>
                    <InputText
                        value={audit.contact.position}
                        onChange={(value) => handleChangeContact(DataleaksAuditContactField.POSITION, value)}
                    />
                </FieldBlock>
            </FormLayoutColumns>
            <FormLayoutColumns>
                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.CONTACT}.${DataleaksAuditContactField.EMAIL}`)}>
                    <InputEmail
                        value={audit.contact.email}
                        onChange={(value) => handleChangeContact(DataleaksAuditContactField.EMAIL, value)}
                    />
                </FieldBlock>
                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.CONTACT}.${DataleaksAuditContactField.PHONE}`)}>
                    <InputPhone
                        value={audit.contact.phone}
                        onChange={(value) => handleChangeContact(DataleaksAuditContactField.PHONE, value)}
                    />
                </FieldBlock>
            </FormLayoutColumns>
        </>
    );
};

export default ModalCreateDataleaksAuditContact;
