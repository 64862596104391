export class DealCurationPlatform {
    static SMART: DealCurationPlatform = new DealCurationPlatform("Smart", "Smart");
    static XANDR: DealCurationPlatform = new DealCurationPlatform("Nexus", "Xandr");

    name: string;
    label: string;

    private constructor(name: string, label: string) {
        this.name = name;
        this.label = label;
    }

    static values(): DealCurationPlatform[] {
        return [
            DealCurationPlatform.SMART,
            DealCurationPlatform.XANDR
        ];
    }

    static getByName(name: string): DealCurationPlatform | undefined {
        return this.values().find((it) => it.name === name);
    }
}
