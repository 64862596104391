import {FieldBlock, FormLayoutColumns, RadioButtons, Select, SelectMultiple} from "@sirdata/ui-lib";
import {Dispatch, FunctionComponent, SetStateAction} from "react";
import {useTranslation} from "react-i18next";
import {DataleaksRegulation} from "../../../api/model/dataleaks/DataleaksRegulation";
import {DataleaksTrait} from "../../../api/model/dataleaks/DataleaksTrait";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";
import {DataleaksQuestionField} from "../../../api/model/dataleaks/question/DataleaksQuestionField";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksAnswer} from "../../../utils/dataleaks/DataleaksAnswer";

type ModalCreateDataleaksQuestionInformationProps = {
    question: DataleaksQuestion;
    setQuestion: Dispatch<SetStateAction<DataleaksQuestion>>;
};

const ModalCreateDataleaksQuestionInformation: FunctionComponent<ModalCreateDataleaksQuestionInformationProps> = ({question, setQuestion}) => {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);
    const {t: textDataleaksQuestions} = useTranslation(TranslationPortalFile.DATALEAKS_QUESTIONS);

    const handleChange = (field: DataleaksQuestionField, value: any) => {
        setQuestion((prevState) => new DataleaksQuestion({...prevState, [field]: value}));
    };

    return (
        <>
            <FormLayoutColumns>
                <FieldBlock
                    label={textDataleaksQuestions(`field.${DataleaksQuestionField.REGULATIONS}`)}
                    required
                >
                    <SelectMultiple
                        values={question.regulations}
                        options={Object.values(DataleaksRegulation).map((it) => ({label: textDataleaksQuestions(`regulation.${it}`), value: it}))}
                        onChange={(options) => handleChange(DataleaksQuestionField.REGULATIONS, options.map((it) => it.value))}
                    />
                </FieldBlock>
                <FieldBlock label={textDataleaksQuestions(`field.${DataleaksQuestionField.TRAIT}`)}>
                    <Select
                        value={question.trait}
                        options={Object.values(DataleaksTrait).map((it) => ({label: textDataleaksQuestions(`trait.${it}`), value: it}))}
                        onChange={(option) => handleChange(DataleaksQuestionField.TRAIT, option?.value)}
                        clearable
                    />
                </FieldBlock>
            </FormLayoutColumns>
            <FormLayoutColumns>
                <FieldBlock
                    label={textDataleaksQuestions("field.good_answer")}
                    content={{noFullWidth: true}}
                >
                    <RadioButtons
                        id="answer"
                        value={question.reverse_answer ? DataleaksAnswer.NO : DataleaksAnswer.YES}
                        options={Object.values(DataleaksAnswer).map((it) => ({label: textDataleaksAudits(`answer.${it}`), value: it}))}
                        onChange={(value) => handleChange(DataleaksQuestionField.REVERSE_ANSWER, value === DataleaksAnswer.NO)}
                    />
                </FieldBlock>
            </FormLayoutColumns>
        </>
    );
};

export default ModalCreateDataleaksQuestionInformation;
