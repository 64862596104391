import {ApiModel} from "../../../../../common/api/model/ApiModel";

export class DistributionLicenseSeat extends ApiModel {
    id: number = 0;
    seat_id: string = "";
    organization_name: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            seat_id: this.seat_id,
            organization_name: this.organization_name
        };
    }
}
