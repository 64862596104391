import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DistributionDataLicenseType} from "./DistributionDataLicenseType";
import {DistributionDataRateCard} from "./DistributionDataRateCard";
import {DistributionDataType} from "./DistributionDataType";
import {DistributionMethod} from "./DistributionMethod";

export class DistributionData extends ApiModel {
    taxonomies: string[] = [];
    countries: string[] = [];
    distribution_types: DistributionDataType[] = [];
    license_types: DistributionDataLicenseType[] = [];
    ui: boolean = false;
    ui_url?: string;
    mapping_taxonomy?: DistributionMethod;
    data_transfer?: DistributionMethod;
    rate_card?: DistributionDataRateCard;
    rate_card_custom?: string;
    usage_report: boolean = false;
    report_type?: DistributionMethod;
    looker_integration: boolean = false;

    static ALL_COUNTRIES_VALUE = "ALL";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            taxonomies: this.taxonomies,
            countries: this.countries,
            distribution_types: this.distribution_types,
            license_types: this.license_types,
            ui: this.ui,
            ui_url: this.ui ? this.ui_url : undefined,
            mapping_taxonomy: this.mapping_taxonomy,
            data_transfer: this.data_transfer,
            rate_card: this.rate_card,
            rate_card_custom: this.rate_card === DistributionDataRateCard.CUSTOM ? this.rate_card_custom : undefined,
            usage_report: this.usage_report,
            report_type: this.usage_report ? this.report_type : undefined,
            looker_integration: this.looker_integration
        };
    }
}