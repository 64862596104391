export enum DistributionDataField {
    TAXONOMIES = "taxonomies",
    COUNTRIES = "countries",
    DISTRIBUTION_TYPES = "distribution_types",
    LICENSE_TYPES = "license_types",
    UI = "ui",
    UI_URL = "ui_url",
    MAPPING_TAXONOMY = "mapping_taxonomy",
    DATA_TRANSFER = "data_transfer",
    RATE_CARD = "rate_card",
    RATE_CARD_CUSTOM = "rate_card_custom",
    USAGE_REPORT = "usage_report",
    REPORT_TYPE = "report_type",
    LOOKER_INTEGRATION = "looker_integration"
}
