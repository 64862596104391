import {Status} from "../../../../utils/Status";

export const CUSTOMER_ORDER_STATUSES = [
    Status.ACCEPTED,
    Status.CANCELED,
    Status.ERROR,
    Status.PENDING,
    Status.PROCESSING,
    Status.REJECTED,
    Status.WAITING_VALIDATION
];
