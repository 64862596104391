import {Box, BoxRadius, FlexContent, FlexContentDirection, FlexContentLayout, FlexContentSpacing, Formatter, SvgSirdataLogoMidnight} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksAudit} from "../../../api/model/dataleaks/audit/DataleaksAudit";
import {Locale} from "../../../common/utils/Locale";

type DataleaksAuditReportInformationProps = {
    audit: DataleaksAudit;
    locale: Locale;
};

const DataleaksAuditReportInformation: FunctionComponent<DataleaksAuditReportInformationProps> = ({audit, locale}) => {
    const {t: textDataleaksAuditsReport} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS_REPORT, {lng: locale.toString()});

    return (
        <FlexContent direction={FlexContentDirection.ROW} layout={FlexContentLayout.COLUMNS}>
            <div className="dataleaks__report__logo">
                <SvgSirdataLogoMidnight/>
            </div>
            <Box radius={BoxRadius.SM} cssClass="dataleaks__report__infos">
                <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.SMALL}>
                    {!!audit.company.logo &&
                        <div className="dataleaks__report__infos__logo">
                            <img src={audit.company.logo} alt={audit.company.name}/>
                        </div>
                    }
                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                        <div className="dataleaks__report__infos__item">
                            <span className="h3 dataleaks__report__infos__item__name">{textDataleaksAuditsReport("field.company")}</span>
                            <span className="dataleaks__report__infos__item__value">{audit.company.name}</span>
                        </div>
                        <div className="dataleaks__report__infos__item">
                            <span className="h3 dataleaks__report__infos__item__name">{textDataleaksAuditsReport("field.contact")}</span>
                            <span className="dataleaks__report__infos__item__value">{`${audit.contact.first_name} ${audit.contact.last_name}`}</span>
                        </div>
                        <div className="dataleaks__report__infos__item">
                            <span className="h3 dataleaks__report__infos__item__name">{textDataleaksAuditsReport("field.domain")}</span>
                            <span className="dataleaks__report__infos__item__value">{audit.domain}</span>
                        </div>
                        <div className="dataleaks__report__infos__item">
                            <span className="h3 dataleaks__report__infos__item__name">{textDataleaksAuditsReport("field.date")}</span>
                            <span className="dataleaks__report__infos__item__value">{Formatter.formatDate(audit.created_at)}</span>
                        </div>
                    </FlexContent>
                </FlexContent>
            </Box>
        </FlexContent>
    );
};

export default DataleaksAuditReportInformation;
