import {CommonApiClient} from "../common/api/CommonApiClient";
import axios, {AxiosResponse} from "axios";
import {session} from "./ApiSession";
import {ErrorResponse} from "../common/api/http/ErrorResponse";
import {GoogleAuthResponse} from "./model/account/google/GoogleAuthResponse";
import {GoogleAuthParams} from "./model/account/google/GoogleAuthParams";
import {Cookie} from "../common/api/model/Cookie";

export class ApiClient extends CommonApiClient {
    async checkLogged(): Promise<boolean> {
        try {
            if (!Cookie.read(session._tokenCookie)) return false;

            let opts = this.buildRequestConfig(undefined);
            await this.httpClient.get("/console-api/auth/logged", opts);
            return true;
        } catch (e) {
            session.clean();
            return false;
        }
    }

    async loginGoogle(code: string, redirect_uri: string): Promise<GoogleAuthResponse> {
        let model = new GoogleAuthResponse();
        try {
            const resp = await this.httpClient.post("/console-api/auth/login", {code: code, redirect_uri: redirect_uri}) as AxiosResponse<GoogleAuthResponse>;
            const token = resp.data.token;
            if (token === undefined || token === "") {
                throw new Error("no token returned");
            }
            session.setToken(token);
            session.setAccount(resp.data.user);
            return model;
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    }

    // return sso-token
    async loginGoogleAsPartner(params: GoogleAuthParams): Promise<string> {
        try {
            const resp = await this.httpClient.post("/console-api/auth/login-partner", params) as AxiosResponse<GoogleAuthResponse>;
            const token = resp.data.token;
            if (token === undefined || token === "") {
                throw new Error("no token returned");
            }
            return token;
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    }
}
