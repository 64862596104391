import {Color, Icon} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksAudit} from "../../../api/model/dataleaks/audit/DataleaksAudit";
import {Locale} from "../../../common/utils/Locale";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";

type DataleaksAuditReportResultsProps = {
    audit: DataleaksAudit;
    questions: DataleaksQuestion[];
    locale: Locale;
};

const DataleaksAuditReportResults: FunctionComponent<DataleaksAuditReportResultsProps> = ({audit, questions, locale}) => {
    const {t: textDataleaksAuditsReport} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS_REPORT, {lng: locale.toString()});

    const getQuestionsByAnswer = (valid: boolean): DataleaksQuestion[] => {
        return questions.filter((question) => {
            const answer = audit.answers.get(question.id);
            return question.isValidAnswer(answer!) === valid;
        });
    };

    return (
        <div className="dataleaks__report__section">
            <div className="dataleaks__report__section__title">{textDataleaksAuditsReport("section.results")}</div>
            <div className="dataleaks__report__results">
                <p className="dataleaks__report__results__description" dangerouslySetInnerHTML={{__html: textDataleaksAuditsReport("results.description", {joinArrays: "<br/>"})}}/>
                <div className="dataleaks__report__results__section">
                    <span className="h3 dataleaks__report__results__section__title">
                        <Icon
                            name="circle"
                            colorIcon={Color.GREEN}
                            cssClass="dataleaks__report__results__section__title__icon"
                        />
                        {textDataleaksAuditsReport("results.positive_points")}
                    </span>
                    <ul>
                        {getQuestionsByAnswer(true).map((it) =>
                            <li key={it.id}>{it.texts.get(locale)?.text_success}</li>
                        )}
                    </ul>
                </div>
                <div className="dataleaks__report__results__section">
                    <span className="h3 dataleaks__report__results__section__title">
                        <Icon
                            name="circle"
                            colorIcon={Color.RED}
                            cssClass="dataleaks__report__results__section__title__icon"
                        />
                        {textDataleaksAuditsReport("results.negative_points")}
                    </span>
                    <ul>
                        {getQuestionsByAnswer(false).map((it) =>
                            <li key={it.id}>{it.texts.get(locale)?.text_failed}</li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default DataleaksAuditReportResults;
