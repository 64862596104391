import {HttpContentType} from "../api/http/HttpContentType";
import {Formatter} from "./Formatter";

export const detectChanges = <T extends { [key: string]: any }>(obj: T | T[], initObj: T | T[]): boolean => {
    if (!obj || !initObj) {
        return obj !== initObj;
    }

    if (Array.isArray(obj)) {
        if (obj.length !== initObj.length) {
            return true;
        }

        const sortedValue = obj.sort();
        const sortedInitValue = initObj.sort();
        for (let i = 0; i < sortedValue.length; i++) {
            if (detectChanges(sortedValue[i], sortedInitValue[i])) {
                return true;
            }
        }
    }

    for (let [key, value] of Object.entries(obj)) {
        if (value === null) continue;
        const initValue = (initObj as T)[key];

        if (value instanceof Map) {
            const initValueAsMap = new Map(initValue);
            if (value.size !== initValueAsMap.size) {
                return true;
            }

            for (let [k, v] of value as any) {
                if (Array.isArray(v)) {
                    if (detectChanges(v, initValueAsMap.get(k) as any)) {
                        return true;
                    }
                } else if (v instanceof Object) {
                    if (detectChanges(v, initValueAsMap.get(k) as any)) {
                        return true;
                    } else if (v !== initValueAsMap.get(k)) {
                        return true;
                    }
                } else if (v !== initValueAsMap.get(k)) {
                    return true;
                }
            }
        } else if (Array.isArray(value) || value instanceof Object) {
            if (detectChanges(value, initValue)) {
                return true;
            }
        } else if (typeof value === "string" || typeof value === "number") {
            const valueAsNumber = Number(value);
            const initValueAsNumber = Number(initValue);

            if (!isNaN(valueAsNumber) && !isNaN(initValueAsNumber)) {
                if (valueAsNumber !== initValueAsNumber) {
                    return true;
                }
            } else if ((value || "") !== (initValue || "")) {
                return true;
            }
        } else if (value !== initValue) {
            return true;
        }
    }
    return false;
};

export const downloadCSV = (fileName: string, content: BlobPart) => {
    let blob = new Blob([content], {type: `${HttpContentType.CSV};charset=utf-8`});
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}-${Formatter.formatDate(Date.now(), Formatter.FILE_DATETIME_FORMAT)}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
};

export const buildAndDownloadCSV = (fileName: string, headers: string[], items: any[][]) => {
    const separator = ";";
    const headersRow = headers.join(separator);
    const itemsRows = items.map((it) => it.join(separator));

    let universalBOM = "\uFEFF";
    let content = universalBOM + [headersRow, ...itemsRows].join("\r\n");
    downloadCSV(fileName, content);
};
