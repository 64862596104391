export enum DealField {
    ACTIVE = "active",
    CONTEXTUAL_RELEVANCY = "contextual_relevancy",
    COUNTRY = "country",
    DESCRIPTION = "description",
    DEVICE_TYPE = "device_type",
    FLOOR_PRICE = "floor_price",
    FLOOR_PRICE_EUR = "eur",
    FLOOR_PRICE_USD = "usd",
    ID = "id",
    INVENTORY_TYPE = "inventory_type",
    NAME = "name",
    PARTNER_ID = "partner_id",
    PLATFORM = "platform",
    PRIVATE = "private",
    TARGETING_ORIGIN = "targeting_origin",
    TIERS = "tiers",
    VIEWABILITY = "viewability"
}
