import React, {FunctionComponent} from "react";
import {IconTooltip} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import clsx from "clsx";
import {DataleaksSeverity} from "../../../api/model/dataleaks/DataleaksSeverity";

type DataleaksSeverityFlagProps = {
    severity: DataleaksSeverity;
    cssClass?: string;
}

const DataleaksSeverityFlag: FunctionComponent<DataleaksSeverityFlagProps> = ({severity, cssClass}) => {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);

    return (
        <IconTooltip
            cssClass={clsx( `dataleaks__flag--${severity.toLowerCase()}`, cssClass)}
            icon={{name: "flag"}}
            text={textDataleaksAudits(`severity.${severity}`)}
        />
    );
};

export default DataleaksSeverityFlag;
