import {
    Action,
    AlertSeverity,
    Box,
    BoxProps,
    Checkbox,
    ContentBlock,
    ContentBlockAction,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentLayout,
    FlexContentSpacing,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    FormValidationType,
    IconEdit,
    InputText,
    InputTextNumber,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    Table,
    TableColumnStyle,
    ToggleSwitch
} from "@sirdata/ui-lib";
import {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Category} from "../../api/model/audience/category/Category";
import {CategoryType} from "../../api/model/audience/category/CategoryType";
import {Segment} from "../../api/model/audience/segment/Segment";
import {Deal} from "../../api/model/deal/Deal";
import {DealCountry} from "../../api/model/deal/DealCountry";
import {DEAL_DEVICES, DealDeviceType} from "../../api/model/deal/DealDeviceType";
import {DealField} from "../../api/model/deal/DealField";
import {DealInventoryType} from "../../api/model/deal/DealInventoryType";
import {DealPlatform} from "../../api/model/deal/DealPlatform";
import {DealTargetingOrigin} from "../../api/model/deal/DealTargetingOrigin";
import {DealTargetingOriginField} from "../../api/model/deal/DealTargetingOriginField";
import {DealTopTier} from "../../api/model/deal/DealTopTier";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {ApiService} from "../../api/model/ApiService";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalEditDealPlatform from "../../component/modal/deals/ModalEditDealPlatform";
import ModalEditDealTargetingOriginContextual from "../../component/modal/deals/ModalEditDealTargetingOriginContextual";
import ModalEditDealTargetingOriginUserCentric from "../../component/modal/deals/ModalEditDealTargetingOriginUserCentric";
import {DealPlatformRow, MainContentHeader, MainContentHeaderAction, SelectPartner, SelectStatus, TagCategoryRow, TagSegmentRow} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {FormLayoutMessage, MainHeader} from "../../common/component/snippet";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {detectChanges} from "../../common/utils/portal";
import {DealPlatformField} from "../../api/model/deal/DealPlatformField";
import useAlert from "../../utils/hooks/useAlert";
import {Status} from "../../utils/Status";
import {DealTiers} from "../../api/model/deal/DealTiers";
import {DealTiersField} from "../../api/model/deal/DealTiersField";
import {Currency} from "../../api/model/currency/Currency";
import ModalAddDealPlatform from "../../component/modal/deals/ModalAddDealPlatform";
import ModalDuplicateDealPlatform from "../../component/modal/deals/ModalDuplicateDealPlatform";
import useFormValidator from "../../utils/hooks/useFormValidator";

function DealsDetails() {
    const {t: textDeals} = useTranslation(TranslationPortalFile.DEALS);
    const {id} = useParams() as {id: string};
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const alert = useAlert();
    const [isShowModalAddPlatform, setShowModalAddPlatform] = useState(false);

    const FORM_ID = "form-edit-deal";
    const [deal, setDeal] = useState<Deal>(new Deal());
    const [initDeal, setInitDeal] = useState<Deal>(new Deal());
    const [segments, setSegments] = useState<Segment[]>([]);
    const [selectedSegments, setSelectedSegments] = useState<Segment[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
    const [activeEditPlatform, setActiveEditPlatform] = useState<DealPlatform>();
    const [activeDuplicatePlatform, setActiveDuplicatePlatform] = useState<DealPlatform>();
    const [activeEditTargetingOriginField, setActiveEditTargetingOriginField] = useState<DealTargetingOriginField>();
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<DealField>();

    useEffect(() => {
        const newSelectedSegments = segments.filter(({id}) => deal.targeting_origin.user_centric?.some((it) => Number(it.id) === id));
        setSelectedSegments(newSelectedSegments);
    }, [deal.targeting_origin.user_centric, segments]);

    useEffect(() => {
        const newSelectedCategories = categories.filter(({id}) => deal.targeting_origin.contextual?.some((it) => Number(it.id) === id));
        setSelectedCategories(newSelectedCategories);
    }, [deal.targeting_origin.contextual, categories]);

    useEffect(() => {
        (async () => {
            try {
                const deal = await session.restDeal.get(id);
                setDeal(deal);
                setInitDeal(new Deal(deal));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("deal", e.message);
                        return;
                    }
                }
            }
            try {
                const segments = await session.getSegments();
                setSegments(segments);

                const categories = await session.getCategoriesByType(CategoryType.CONTEXTUAL);
                setCategories(categories);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("targeting origins", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(deal, initDeal));
    }, [deal, initDeal]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [DealField.TARGETING_ORIGIN]: !deal.targeting_origin?.user_centric?.length && !deal.targeting_origin?.contextual?.length
        }));
    }, [setErrors, deal]);

    const handleChange = (field: DealField, value: any) => {
        setDeal((prevState) => new Deal({...prevState, [field]: value}));
    };

    const handleChangeTiers = (field: DealTiersField, value: any) => {
        handleChange(DealField.TIERS, new DealTiers({...deal.tiers, [field]: value}));
    };

    const handleChangeDeviceType = (deviceType: DealDeviceType, active: boolean) => {
        let newDeviceTypes = deal.device_type ? [...deal.device_type] : [];
        if (active) {
            newDeviceTypes = [...newDeviceTypes, deviceType];
        } else {
            newDeviceTypes = newDeviceTypes.filter((it) => it !== deviceType);
        }
        handleChange(DealField.DEVICE_TYPE, newDeviceTypes);
    };

    const handleChangeFloorPrice = (field: DealField.FLOOR_PRICE_EUR | DealField.FLOOR_PRICE_USD, value: any) => {
        handleChange(DealField.FLOOR_PRICE, {...deal.floor_price, [field]: value});
    };

    const handleUpdateTargetingOrigin = (items: Segment[] | Category[]) => {
        if (!activeEditTargetingOriginField) return;
        const newTargetingOrigin = new DealTargetingOrigin({
            ...deal.targeting_origin,
            [activeEditTargetingOriginField]: !!items.length ? items.map(({id}) => ({id: id.toString()})) : undefined
        });
        handleChange(DealField.TARGETING_ORIGIN, newTargetingOrigin);
        setActiveEditTargetingOriginField(undefined);
    };

    const handleAddPlatform = (platform: DealPlatform) => {
        setShowModalAddPlatform(false);
        let newPlatforms: Map<string, DealPlatform[]> = new Map(deal.platform);
        const items = newPlatforms.get(platform.name) || [];
        if (items.some((it) => platform.deal_id === it.deal_id)) {
            alert.failTo("add platform", textDeals("deal_id_already_added", {id: platform.deal_id}));
            return;
        }
        newPlatforms.set(platform.name, [...items, platform]);
        handleChange(DealField.PLATFORM, newPlatforms);
    };

    const handleEditPlatform = (platform: DealPlatform) => {
        if (!activeEditPlatform) return;
        let newPlatforms: Map<string, DealPlatform[]> = new Map(deal.platform);
        const items = newPlatforms.get(platform.name) || [];
        items.forEach((it, index) => {
            if (it.deal_id === activeEditPlatform?.deal_id) {
                items[index] = platform;
            }
        });
        newPlatforms.set(platform.name, items);
        handleChange(DealField.PLATFORM, newPlatforms);
        setActiveEditPlatform(undefined);
    };

    const handleDuplicatePlatform = (platform: DealPlatform) => {
        setActiveDuplicatePlatform(undefined);
        let newPlatforms: Map<string, DealPlatform[]> = new Map(deal.platform);
        const items = newPlatforms.get(platform.name) || [];
        if (items.some((it) => platform.deal_id === it.deal_id)) {
            alert.failTo("duplicate platform", textDeals("deal_id_already_added", {id: platform.deal_id}));
            return;
        }
        newPlatforms.set(platform.name, [...items, platform]);
        handleChange(DealField.PLATFORM, newPlatforms);
    };

    const handleDeletePlatform = (platform: DealPlatform) => {
        let newPlatforms: Map<string, DealPlatform[]> = new Map(deal.platform);
        const items = newPlatforms.get(platform.name) || [];
        const newItems = items.filter((it) => it.deal_id !== platform.deal_id);
        newPlatforms.set(platform.name, newItems);
        handleChange(DealField.PLATFORM, newPlatforms);
    };

    const handleSaveCapture = () => {
        setShowErrors(true);
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        try {
            const newDeal = await session.restDeal.update(deal);
            setDeal(newDeal);
            setInitDeal(new Deal(newDeal));
            alert.updateWithSuccess("deal");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("deal", e.message);
            }
        } finally {
            setShowErrors(false);
        }
    };

    const handleDuplicate = async () => {
        try {
            const newDeal = new Deal({...deal});
            newDeal.name += " (copy)";
            const copy = await session.restDeal.create(newDeal);
            window.open(copy.getRoute(), "_blank");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDuplicate("deal", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.DEALS} element={initDeal.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.DEALS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DUPLICATE} onClick={handleDuplicate} disabled={isUnsavedChanges}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmitCapture={handleSaveCapture} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textDeals("section.information")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns columns={4}>
                                                <FieldBlock label={textDeals("field.status")}>
                                                    <SelectStatus
                                                        value={deal.active ? Status.ACTIVE.name : Status.INACTIVE.name}
                                                        statuses={Status.getActiveStatuses()}
                                                        onChange={(status) => handleChange(DealField.ACTIVE, status === Status.ACTIVE)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textDeals(`field.${DealField.NAME}`)} required>
                                                    <InputText
                                                        value={deal.name}
                                                        onChange={(value) => handleChange(DealField.NAME, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textDeals(`field.${DealField.TIERS}.${DealTiersField.TIERS1}`)} required>
                                                    <Select
                                                        value={deal.tiers.tiers1}
                                                        options={DealTopTier.values().map((it) => ({label: it.name, value: it.name}))}
                                                        onChange={(option) => handleChangeTiers(DealTiersField.TIERS1, `${option?.value || ""}`)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textDeals(`field.${DealField.DESCRIPTION}`)} required>
                                                <InputText
                                                    value={deal.description}
                                                    onChange={(value) => handleChange(DealField.DESCRIPTION, value)}
                                                />
                                            </FieldBlock>
                                            <FormLayoutSeparator/>
                                            <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                <FieldBlock label={textDeals(`field.${DealField.PARTNER_ID}`)}>
                                                    <SelectPartner
                                                        value={deal.partner_id}
                                                        onChange={(partner) => handleChange(DealField.PARTNER_ID, partner?.id || 0)}
                                                        service={ApiService.AUDIENCE}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock name={DealField.PRIVATE} label={textDeals(`field.${DealField.PRIVATE}`)}>
                                                    <ToggleSwitch
                                                        checked={deal.private}
                                                        onChange={(value) => handleChange(DealField.PRIVATE, value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutSeparator/>
                                            <FormLayoutTitle>{textDeals("section.settings")}</FormLayoutTitle>
                                            <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                <FormLayoutRows inline>
                                                    <FieldBlock label={textDeals(`field.${DealField.COUNTRY}`)}>
                                                        <Select
                                                            value={deal.country[0]}
                                                            options={DealCountry.values().map((it) => ({value: it.name, label: textDeals(`country.${it.name}`)}))}
                                                            onChange={(option) => handleChange(DealField.COUNTRY, [option?.value || ""])}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDeals(`field.${DealField.INVENTORY_TYPE}`)}>
                                                        <Select
                                                            value={deal.inventory_type[0]}
                                                            options={DealInventoryType.values().map((it) => ({value: it.name, label: textDeals(`inventory_type.${it.name}`)}))}
                                                            onChange={(option) => handleChange(DealField.INVENTORY_TYPE, [option?.value || ""])}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textDeals(`field.${DealField.VIEWABILITY}`)}>
                                                        <InputTextNumber
                                                            value={deal.viewability}
                                                            min={0}
                                                            max={100}
                                                            onChange={(value) => handleChange(DealField.VIEWABILITY, value)}
                                                        />
                                                        <span>%</span>
                                                    </FieldBlock>
                                                    <FieldBlock label={textDeals(`field.${DealField.CONTEXTUAL_RELEVANCY}`)}>
                                                        <InputTextNumber
                                                            value={deal.contextual_relevancy}
                                                            min={0}
                                                            max={100}
                                                            onChange={(value) => handleChange(DealField.CONTEXTUAL_RELEVANCY, value)}
                                                        />
                                                        <span>%</span>
                                                    </FieldBlock>
                                                </FormLayoutRows>
                                                <FormLayoutRows>
                                                    <FieldBlock label={textDeals(`field.${DealField.DEVICE_TYPE}`)} content={{direction: FlexContentDirection.COLUMN}}>
                                                        {DEAL_DEVICES.map((device) =>
                                                            <Checkbox
                                                                key={device}
                                                                name={`${DealField.DEVICE_TYPE}-${device}`}
                                                                label={textDeals(`device_type.${device}`)}
                                                                checked={deal.device_type.includes(device)}
                                                                onChange={(value) => handleChangeDeviceType(device, value)}
                                                            />
                                                        )}
                                                    </FieldBlock>
                                                    <FieldBlock label={textDeals(`field.${DealField.FLOOR_PRICE}`)} content={{direction: FlexContentDirection.COLUMN}}>
                                                        <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                                                            <InputTextNumber
                                                                placeholder={deal.floor_price.eur.toString()}
                                                                value={deal.floor_price.eur}
                                                                onChange={(value) =>  handleChangeFloorPrice(DealField.FLOOR_PRICE_EUR, value)}
                                                            />
                                                            <span>{textDeals("currency_cents", {currency: Currency.EUR.currency})}</span>
                                                        </FlexContent>
                                                        <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                                                            <InputTextNumber
                                                                placeholder={deal.floor_price.usd.toString()}
                                                                value={deal.floor_price.usd}
                                                                onChange={(value) =>  handleChangeFloorPrice(DealField.FLOOR_PRICE_USD, value)}
                                                            />
                                                            <span>{textDeals("currency_cents", {currency: Currency.USD.currency})}</span>
                                                        </FlexContent>
                                                    </FieldBlock>
                                                </FormLayoutRows>
                                            </FormLayoutColumns>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                            <LayoutRows>
                                <Loadable loading={isLoading}>
                                    <ContentBlock header={{title: {label: textDeals(`field.${DealField.TARGETING_ORIGIN}`)}}}>
                                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                            <FormLayoutRows>
                                                <FieldBlock
                                                    label={textDeals("targeting_origin.user_centric.label")}
                                                    actions={
                                                        <RestrictedContent allowedTo={Authorization.DEALS.update}>
                                                            <IconEdit onClick={() => setActiveEditTargetingOriginField(DealTargetingOriginField.USER_CENTRIC)}/>
                                                        </RestrictedContent>
                                                    }
                                                >
                                                    <ElementList size={ElementListSize.BIG} placeholder={textDeals("targeting_origin.user_centric.list_empty")}>
                                                        {selectedSegments.map((segment) => (
                                                            <TagSegmentRow key={segment.id} segment={segment}/>
                                                        ))}
                                                    </ElementList>
                                                </FieldBlock>
                                                <FieldBlock
                                                    label={textDeals("targeting_origin.contextual.label")}
                                                    actions={
                                                        <RestrictedContent allowedTo={Authorization.DEALS.update}>
                                                            <IconEdit onClick={() => setActiveEditTargetingOriginField(DealTargetingOriginField.CONTEXTUAL)}/>
                                                        </RestrictedContent>
                                                    }
                                                >
                                                    <ElementList size={ElementListSize.BIG} placeholder={textDeals("targeting_origin.contextual.list_empty")}>
                                                        {selectedCategories.map((category) => (
                                                            <TagCategoryRow key={category.id} category={category}/>
                                                        ))}
                                                    </ElementList>
                                                </FieldBlock>
                                                {formValidator.isError(DealField.TARGETING_ORIGIN) &&
                                                    <FormLayoutMessage message={textDeals(`${DealField.TARGETING_ORIGIN}.field_required`)} severity={AlertSeverity.DANGER} small/>
                                                }
                                            </FormLayoutRows>
                                        </Box>
                                    </ContentBlock>
                                </Loadable>
                            </LayoutRows>
                        </LayoutColumns>
                        <Loadable loading={isLoading}>
                            <ContentBlock
                                header={{title: {label: textDeals("section.platforms")}, actions: (
                                    <RestrictedContent allowedTo={Authorization.DEALS.update}>
                                        <ContentBlockAction action={Action.ADD} onClick={() => setShowModalAddPlatform(true)}/>
                                    </RestrictedContent>
                                )}}
                            >
                                <Table
                                    columns={[
                                        {width: 10, label: textDeals("field.status"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textDeals(`field.platform.${DealPlatformField.LABEL}`)},
                                        {width: 15, label: textDeals(`field.platform.${DealPlatformField.DEAL_ID}`)},
                                        {width: 20, label: textDeals("recommendation_format_type.banner")},
                                        {width: 20, label: textDeals("recommendation_format_type.native")},
                                        {width: 20, label: textDeals("recommendation_format_type.video")},
                                        {width: 5}
                                    ]}
                                >
                                    {deal.getPlatformItems().map((platform) =>
                                        <DealPlatformRow
                                            key={`${platform.name}_${platform.deal_id}`}
                                            platform={platform}
                                            onEdit={() => setActiveEditPlatform(platform)}
                                            onRemove={() => handleDeletePlatform(platform)}
                                            onDuplicate={() => setActiveDuplicatePlatform(platform)}
                                        />
                                    )}
                                </Table>
                            </ContentBlock>
                        </Loadable>
                    </LayoutRows>
                </Form>
                <ModalEditDealTargetingOriginUserCentric
                    active={activeEditTargetingOriginField === DealTargetingOriginField.USER_CENTRIC}
                    segments={segments}
                    initialSegments={selectedSegments}
                    onSubmit={(values) => handleUpdateTargetingOrigin(values)}
                    onClose={() => setActiveEditTargetingOriginField(undefined)}
                />
                <ModalEditDealTargetingOriginContextual
                    active={activeEditTargetingOriginField === DealTargetingOriginField.CONTEXTUAL}
                    categories={categories}
                    initialCategories={selectedCategories}
                    onSubmit={(values) => handleUpdateTargetingOrigin(values)}
                    onClose={() => setActiveEditTargetingOriginField(undefined)}
                />
                <ModalAddDealPlatform
                    active={isShowModalAddPlatform}
                    onSubmit={handleAddPlatform}
                    onClose={() => setShowModalAddPlatform(false)}
                />
                <ModalEditDealPlatform
                    initPlatform={activeEditPlatform}
                    onSubmit={handleEditPlatform}
                    onClose={() => setActiveEditPlatform(undefined)}
                />
                <ModalDuplicateDealPlatform
                    initPlatform={activeDuplicatePlatform}
                    onSubmit={handleDuplicatePlatform}
                    onClose={() => setActiveDuplicatePlatform(undefined)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default DealsDetails;
