import {Rest} from "../../common/api/rest/Rest";
import {CmpWebinar} from "../model/cmp/webinar/CmpWebinar";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";

export class RestCmpWebinar extends Rest implements RestService<CmpWebinar> {
    rootPath = "/console-api/cmp/webinar";

    search(params: any): Promise<SearchResult<CmpWebinar>> {
        return new Promise<SearchResult<CmpWebinar>>(() => {}); // TODO Implement route
    }

    list(): Promise<CmpWebinar[]> {
        return this._client.get(`${this.rootPath}`, CmpWebinar) as Promise<CmpWebinar[]>;
    }

    create(cmpWebinar: CmpWebinar): Promise<CmpWebinar> {
        return this._client.post(`${this.rootPath}`, cmpWebinar, CmpWebinar) as Promise<CmpWebinar>;
    }

    get(webinarId: string): Promise<CmpWebinar> {
        return this._client.get(`${this.rootPath}/${webinarId}`, CmpWebinar) as Promise<CmpWebinar>;
    }

    update(cmpWebinar: CmpWebinar): Promise<CmpWebinar> {
        return this._client.put(`${this.rootPath}/${cmpWebinar.id}`, cmpWebinar, CmpWebinar) as Promise<CmpWebinar>;
    }

    delete(webinarId: string) {
        // TODO Implement route
    }
}
