import {Dispatch, FunctionComponent, SetStateAction} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {DataleaksAudit} from "../../../api/model/dataleaks/audit/DataleaksAudit";
import {DataleaksAuditField} from "../../../api/model/dataleaks/audit/DataleaksAuditField";
import {DataleaksAuditInfosField} from "../../../api/model/dataleaks/audit/DataleaksAuditInfosField";
import {DataleaksAuditInfos} from "../../../api/model/dataleaks/audit/DataleaksAuditInfos";
import {ConsentManagementPlatform} from "../../../api/model/cmp/list/cmp-list/ConsentManagementPlatform";
import {Box, FieldBlock, FlexContentLayout, FlexContentSpacing, FormLayoutColumns, FormLayoutRows, InputDomain, InputText, RadioButtons, Select, SelectAutocomplete} from "@sirdata/ui-lib";
import {DataleaksMechanism} from "../../../api/model/dataleaks/DataleaksMechanism";
import {DataleaksScope} from "../../../api/model/dataleaks/DataleaksScope";

type ModalCreateDataleaksAuditWebsiteProps = {
    audit: DataleaksAudit;
    setAudit: Dispatch<SetStateAction<DataleaksAudit>>;
    cmps: ConsentManagementPlatform[];
};

const ModalCreateDataleaksAuditWebsite: FunctionComponent<ModalCreateDataleaksAuditWebsiteProps> = ({audit, setAudit, cmps}) => {
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);

    const handleChange = (field: DataleaksAuditField, value: any) => {
        setAudit((prevState) => new DataleaksAudit({...prevState, [field]: value}));
    };

    const handleChangeInfos = (field: DataleaksAuditInfosField, value: any) => {
        handleChange(DataleaksAuditField.INFOS, new DataleaksAuditInfos({...audit.infos, [field]: value}));
    };

    const handleChangeInfosCmp = (id: number = 0, name: string) => {
        handleChange(DataleaksAuditField.INFOS, new DataleaksAuditInfos({
            ...audit.infos,
            [DataleaksAuditInfosField.CMP_ID]: id,
            [DataleaksAuditInfosField.CMP_NAME]: name
        }));
    };

    return (
        <>
            <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.DOMAIN}`)} required>
                <InputDomain
                    value={audit.domain}
                    onChange={(value) => handleChange(DataleaksAuditField.DOMAIN, value)}
                />
            </FieldBlock>
            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.MECHANISM}`)}>
                    <RadioButtons
                        id={DataleaksAuditInfosField.MECHANISM}
                        value={audit.infos.mechanism}
                        options={Object.values(DataleaksMechanism).map((it) => ({value: it, label: textDataleaksAudits(`mechanism.${it}`)}))}
                        onChange={(value) => handleChangeInfos(DataleaksAuditInfosField.MECHANISM, value)}
                    />
                </FieldBlock>
                {audit.infos.mechanism === DataleaksMechanism.TCF_CMP &&
                <Box>
                    <FormLayoutRows>
                        <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                            <FieldBlock
                                label={textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.CMP_ID}`)}
                            >
                                <SelectAutocomplete
                                    value={audit.infos.cmp_id}
                                    options={cmps.map((it) => ({value: it.id, label: `${it.id} - ${it.name}`, cmp: it}))}
                                    onChange={(option) => handleChangeInfosCmp(option?.cmp?.id, option?.cmp?.name)}
                                    clearable
                                />
                            </FieldBlock>
                            <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.SCOPE}`)}>
                                <Select
                                    value={audit.infos.scope}
                                    options={Object.values(DataleaksScope).map((it) => ({value: it, label: textDataleaksAudits(`scope.${it}`)}))}
                                    onChange={(option) => handleChangeInfos(DataleaksAuditInfosField.SCOPE, option?.value as DataleaksScope)}
                                    clearable
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.TC_STRING}`)}>
                            <InputText
                                value={audit.infos.tc_string}
                                onChange={(value) => handleChangeInfos(DataleaksAuditInfosField.TC_STRING, value)}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Box>
                }
                {audit.infos.mechanism === DataleaksMechanism.NON_TCF_CMP &&
                <Box>
                    <FieldBlock label={textDataleaksAudits(`field.${DataleaksAuditField.INFOS}.${DataleaksAuditInfosField.CMP_NAME}`)}>
                        <InputText
                            value={audit.infos.cmp_name}
                            onChange={(value) => handleChangeInfosCmp(0, value)}
                        />
                    </FieldBlock>
                </Box>
                }
            </FormLayoutRows>
        </>
    );
};

export default ModalCreateDataleaksAuditWebsite;
