import {Color, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {Segment} from "../../../../api/model/audience/segment/Segment";
import {Formatter} from "../../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelStatus, Tag, TagSegmentDataType, ValueCopier} from "../../index";
import {Status} from "../../../../utils/Status";
import {SegmentDataType} from "../../../../api/model/audience/segment/SegmentDataType";

type SegmentRowProps = {
    segment: Segment;
}

const SegmentRow: FunctionComponent<SegmentRowProps> = ({segment}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);

    return (
        <TableRow onClick={{link: segment.getRoute()}}>
            <TableColumn>
                <LabelStatus status={segment.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{segment.id}</TableColumn>
            <TableColumn>
                <TagSegmentDataType type={SegmentDataType.getByName(segment.data_type)}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>
                <span>{segment.name}</span>
                {segment.private &&
                    <IconTooltip icon={{name: "lock", colorIcon: Color.OCEAN}} text={t(`status.${Status.PRIVATE.name}`)}/>
                }
                {segment.isNew() &&
                    <Tag label={textSegments("status.new")} customColor={{backgroundColor: Color.RED}}/>
                }
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{Formatter.formatNumber(segment.price / 100)} €</TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <span>
                    <ValueCopier value={segment.platform_id} tooltip={textSegments("actions.copy_id")}/>
                </span>
            </TableActionColumn>
        </TableRow>
    );
};

export default SegmentRow;
