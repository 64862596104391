import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Select, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {Authorization} from "../../api/model/account/Authorization";
import {CmpConfigSearchParamsField, CmpConfigSearchQuery} from "../../api/model/cmp/config/CmpConfigSearchQuery";
import {CMP_CONFIG_STATUSES} from "../../api/model/cmp/config/CmpConfigStatus";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {ApiService} from "../../api/model/ApiService";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {CmpConfigRow, MainContentHeader, MainContentHeaderAction, SelectPartner, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import useSearch from "../../utils/hooks/useSearch";
import {CmpConfigField} from "../../api/model/cmp/config/CmpConfigField";
import useAlert from "../../utils/hooks/useAlert";
import ModalCreateCmpConfiguration from "../../component/modal/cmp-configurations/ModalCreateCmpConfiguration";
import ModalFlushCmpConfigurations from "../../component/modal/cmp-configurations/ModalFlushCmpConfigurations";
import {CmpConfigPropertiesField} from "../../api/model/cmp/config/CmpConfigPropertiesField";
import {CmpConfigPropertiesCreationType} from "../../api/model/cmp/config/CmpConfigPropertiesCreationType";

function CmpConfigurations() {
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const [isLoading, setLoading] = useState(true);
    const alert = useAlert();
    const {buildSearchResult, ...search} = useSearch(CmpConfig, CmpConfigSearchQuery);
    const [isShowModalCreateCmpConfiguration, setShowModalCreateCmpConfiguration] = useState(false);
    const [isShowModalFlushCmpConfigurations, setShowModalFlushCmpConfigurations] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                let configs = await session.restCmpConfiguration.list(search.searchQuery);
                if (search.searchQuery.query) {
                    configs = configs.filter(({id, id_partner, name, domain}) => `${id} ${id_partner} ${name} ${domain}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
                }
                buildSearchResult(configs);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("configurations", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_CONFIGURATIONS}>
                <RestrictedContent allowedTo={Authorization.CMP_CONFIGURATIONS.update}>
                    <MainContentHeaderAction action={new Action(textCmpConfigurations("actions.flush_all_configurations"), {name: "cleaning_services"})} onClick={() => setShowModalFlushCmpConfigurations(true)}/>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreateCmpConfiguration(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textCmpConfigurations("search.placeholder"), value: search.searchQuery.query || "", onSubmit: search.changeQuery}}
                            canHideFilters={true}
                        >
                            <SearchField label={textCmpConfigurations(`field.${CmpConfigField.STATUS}`)}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={CMP_CONFIG_STATUSES}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textCmpConfigurations(`field.${CmpConfigField.ID_PARTNER}`)}>
                                <SelectPartner
                                    value={search.searchQuery.partner_id}
                                    onChange={(partner) => search.changeParam(SearchParamsField.PARTNER, partner?.id)}
                                    service={ApiService.CMP}
                                />
                            </SearchField>
                            <SearchField label={textCmpConfigurations(`field.${CmpConfigField.PROPERTIES}.${CmpConfigPropertiesField.CREATION_TYPE}`)}>
                                <Select
                                    value={search.searchQuery.creation_type}
                                    options={Object.values(CmpConfigPropertiesCreationType).map((it) => ({
                                        label: textCmpConfigurations(`${CmpConfigPropertiesField.CREATION_TYPE}.${it}`),
                                        value: it
                                    }))}
                                    onChange={(option) => search.changeParam(CmpConfigSearchParamsField.CREATION_TYPE, option?.value)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textCmpConfigurations(`field.${CmpConfigField.STATUS}`), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.FIXED_WIDTH]},
                                        {width: 5, label: textCmpConfigurations(`field.${CmpConfigField.ID}`), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.FIXED_WIDTH]},
                                        {width: 10, label: textCmpConfigurations(`field.${CmpConfigField.ID_PARTNER}`), styles: [TableColumnStyle.ALIGN_CENTER, TableColumnStyle.FIXED_WIDTH], sort: {field: CmpConfigField.ID_PARTNER}},
                                        {width: 30, label: textCmpConfigurations(`field.${CmpConfigField.NAME}`), styles: TableColumnStyle.HIDE_SCREEN_SMALL, sort: {field: CmpConfigField.NAME}},
                                        {width: 30, label: textCmpConfigurations(`field.${CmpConfigField.DOMAIN}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM, sort: {field: CmpConfigField.DOMAIN}},
                                        {width: 10, label: textCmpConfigurations(`field.${CmpConfigField.CREATION_TIME}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM, sort: {field: CmpConfigField.CREATION_TIME}},
                                        {width: 10, label: textCmpConfigurations(`field.${CmpConfigField.PROPERTIES}.${CmpConfigPropertiesField.CREATION_TYPE}`), styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((config) =>
                                        <CmpConfigRow item={config} key={config.id}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateCmpConfiguration
                    active={isShowModalCreateCmpConfiguration}
                    onClose={() => setShowModalCreateCmpConfiguration(false)}
                />
                <ModalFlushCmpConfigurations
                    active={isShowModalFlushCmpConfigurations}
                    onClose={() => setShowModalFlushCmpConfigurations(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default CmpConfigurations;
