import {ApiModel} from "../../../../common/api/model/ApiModel";

export class DistributionSegmentsStatusUpdate extends ApiModel {
    active: boolean = false;
    all_segment: boolean = false;
    segments: number[] = [];
    link_external_name: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            status: this.active,
            all_segment: this.all_segment,
            segments: this.segments,
            link_external_name: this.link_external_name
        };
    }
}
