export enum CustomerOrderField {
    ID = "id",
    STATUS = "status",
    PARTNER_ID = "partner_id",
    FILTER_ID = "filter_id",
    COMMENT = "comment",
    LIMIT = "limit",
    CREATION_TS = "creation_ts",
    LAST_UPDATE_TS = "last_update_ts",
    COST = "cost",
    DISCOUNT = "discount",
    DISCOUNT_REASON = "discount_reason",
    CURRENCY = "currency",
    ERROR_CODE = "error_code",
    ERROR_MESSAGE = "error_message",
    ORGANIZATION_ID = "organization_id",
    PRODUCT = "product",
    DATA_PROVIDER_ORGANIZATION_ID = "data_provider_organization_id",
    SEAT_ID = "seat_id",
}
