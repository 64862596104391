import {TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {Partner} from "../../../../api/model/partner/Partner";
import {SgtmContainer} from "../../../../api/model/sgtm/container/SgtmContainer";
import {Formatter} from "../../../../common/utils/Formatter";
import {LabelPartner, LabelStatus, Tag} from "../../index";

type SgtmContainerRowProps = {
    container: SgtmContainer;
    partner?: Partner;
};

const SgtmContainerRow: FunctionComponent<SgtmContainerRowProps> = ({container, partner}) => {
    return (
        <TableRow onClick={{link: container.getRoute()}}>
            <TableColumn>
                <LabelStatus status={container.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{container.id}</TableColumn>
            <TableColumn>{container.name}</TableColumn>
            <TableColumn><LabelPartner partner={partner}/></TableColumn>
            <TableColumn>
                {container.instance_hosts.map((host) =>
                    <Tag key={host} label={host} style={TagStyle.DEFAULT_OCEAN}/>
                )}
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{Formatter.formatDate(container.creation_ts, Formatter.DATETIME_FORMAT)}</TableColumn>
        </TableRow>
    );
};

export default SgtmContainerRow;
