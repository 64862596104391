import {Rest} from "../../common/api/rest/Rest";
import {User} from "../model/user/User";
import {UserProperties} from "../model/user/UserProperties";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";

export class RestUser extends Rest implements RestService<User> {
    rootPath = "/console-api/user";

    search(params: any): Promise<SearchResult<User>> {
        return new Promise<SearchResult<User>>(() => {}); // TODO Implement route
    }

    list(): Promise<User[]> {
        return this._client.get(`${this.rootPath}`, User) as Promise<User[]>;
    }

    get(userId: number): Promise<User> {
        return this._client.get(`${this.rootPath}/${userId}`, User) as Promise<User>;
    }

    create(user: User): Promise<User> {
        return this._client.post(`${this.rootPath}`, user, User) as Promise<User>;
    }

    update(user: User): Promise<User> {
        return this._client.put(`${this.rootPath}/${user.id}`, user, User) as Promise<User>;
    }

    delete(userId: number) {
        return this._client.delete(`${this.rootPath}/${userId}/active`);
    }

    updateProperties(userID: number, properties: UserProperties): Promise<User> {
        return this._client.patch(`${this.rootPath}/${userID}/properties`, properties, User) as Promise<User>;
    }
}
