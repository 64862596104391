export class CategorizerConfigEnrichmentMode {
    static BLOCK: CategorizerConfigEnrichmentMode = new CategorizerConfigEnrichmentMode("block");
    static DISABLED: CategorizerConfigEnrichmentMode = new CategorizerConfigEnrichmentMode("disabled");
    static POST: CategorizerConfigEnrichmentMode = new CategorizerConfigEnrichmentMode("post");
    static PRE: CategorizerConfigEnrichmentMode = new CategorizerConfigEnrichmentMode("pre");
    static PRE_AND_POST: CategorizerConfigEnrichmentMode = new CategorizerConfigEnrichmentMode("pre_and_post");
    static SUBSTITUTE: CategorizerConfigEnrichmentMode = new CategorizerConfigEnrichmentMode("substitute");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CategorizerConfigEnrichmentMode[] {
        return [
            CategorizerConfigEnrichmentMode.BLOCK,
            CategorizerConfigEnrichmentMode.DISABLED,
            CategorizerConfigEnrichmentMode.POST,
            CategorizerConfigEnrichmentMode.PRE,
            CategorizerConfigEnrichmentMode.PRE_AND_POST,
            CategorizerConfigEnrichmentMode.SUBSTITUTE
        ];
    }
}
