import {
    ButtonLinkCancel,
    ButtonValidate,
    FormLayoutRows,
    FieldBlock,
    FormLayoutColumns,
    InputDate,
    InputDateType,
    InputTextNumber,
    ModalActions,
    ModalContent,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    ToggleSwitch,
    Box,
    BoxRadius,
    FlexContentSpacing
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {Formatter} from "../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../utils/constants";
import {PartnerPricing} from "../../../api/model/partner/pricing/PartnerPricing";
import {PartnerPricingField} from "../../../api/model/partner/pricing/PartnerPricingField";
import useAlert from "../../../utils/hooks/useAlert";
import {LabelPartner, LabelService} from "../../snippet";
import {PricingField} from "../../../api/model/partner/pricing/PricingField";

export type ModalEditPartnerPricingProps = {
    initialValue: PartnerPricing | undefined;
    onClose: (refresh: boolean) => void;
};

const ModalEditPartnerPricing: FunctionComponent<ModalEditPartnerPricingProps> = ({initialValue, onClose}) => {
    const {t: textPricings} = useTranslation(TranslationPortalFile.PRICINGS);
    const alert = useAlert();
    const [partnerPricing, setPartnerPricing] = useState<PartnerPricing>(new PartnerPricing());
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (!initialValue) return;
        setPartnerPricing(initialValue);
    }, [initialValue]);

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            partnerPricing.start_date = partnerPricing.start_date ? Formatter.formatDate(partnerPricing.start_date, Formatter.API_DATE_FORMAT) : "";
            partnerPricing.end_date = partnerPricing.end_date ? Formatter.formatDate(partnerPricing.end_date, Formatter.API_DATE_FORMAT) : "";
            await session.restPartnerPricing.create(partnerPricing);
            alert.updateWithSuccess("rate card setting for partner");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("rate card setting for partner", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={!!initialValue}>
            <ModalHeader>
                <ModalHeaderTitle title={textPricings("edit_partner_pricing", {partner: `${partnerPricing.id_partner}`})}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows>
                    <Box radius={BoxRadius.MD}>
                        <FormLayoutRows spacing={FlexContentSpacing.SMALL} inline>
                            <FieldBlock label={textPricings(`field.${PricingField.API_SERVICE}`)} content={{noFullWidth: true}}>
                                <LabelService service={partnerPricing.pricing.api_service}/>
                            </FieldBlock>
                            <FieldBlock label={textPricings(`field.partner.${PartnerPricingField.ID_PRICING}`)}>
                                {partnerPricing.pricing.name}
                            </FieldBlock>
                            <FieldBlock label={textPricings("field.partner.info_partner")}>
                                <LabelPartner partner={partnerPricing.partner}/>
                            </FieldBlock>
                        </FormLayoutRows>
                    </Box>
                    <FormLayoutColumns>
                        <FieldBlock label={textPricings(`field.partner.${PartnerPricingField.START_DATE}`)} required>
                            <InputDate
                                type={InputDateType.DATE}
                                value={partnerPricing.start_date}
                                onChange={(value) => setPartnerPricing((prevState) => new PartnerPricing({...prevState, [PartnerPricingField.START_DATE]: value}))}
                            />
                        </FieldBlock>
                        <FieldBlock label={textPricings(`field.partner.${PartnerPricingField.END_DATE}`)}>
                            <InputDate
                                type={InputDateType.DATE}
                                value={partnerPricing.end_date}
                                onChange={(value) => setPartnerPricing((prevState) => new PartnerPricing({...prevState, [PartnerPricingField.END_DATE]: value}))}
                            />
                        </FieldBlock>
                        <FieldBlock label={textPricings(`field.partner.${PartnerPricingField.DISCOUNT}`)}>
                            <InputTextNumber
                                value={partnerPricing.discount}
                                min={0}
                                max={100}
                                onChange={(value) => setPartnerPricing((prevState) => new PartnerPricing({...prevState, [PartnerPricingField.DISCOUNT]: value}))}
                            />
                            <span>%</span>
                        </FieldBlock>
                    </FormLayoutColumns>
                    <ToggleSwitch
                        name={PartnerPricingField.ACTIVE}
                        label={textPricings(`field.partner.${PartnerPricingField.ACTIVE}`)}
                        checked={partnerPricing.active}
                        onChange={(value) => setPartnerPricing((prevState) => new PartnerPricing({...prevState, [PartnerPricingField.ACTIVE]: value}))}
                    />
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate
                    onClick={handleSubmit}
                    disabled={!partnerPricing.start_date}
                    loading={isSubmitting}
                />
            </ModalActions>
        </ModalNew>
    );
};

export default ModalEditPartnerPricing;
