import {
    Action,
    Alert,
    AlertSeverity,
    Box,
    Button,
    ButtonLink,
    ButtonSize,
    ButtonStyle,
    ElementList,
    ElementListSize,
    FieldBlock,
    FlexContentSpacing,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutRows,
    InputDate,
    InputDateType,
    ModalActions,
    ModalContent,
    ModalDescription,
    ModalDescriptionAlignment,
    ModalHeader,
    ModalHeaderTitle,
    ModalNew,
    RadioButtons,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {NotificationDispatch} from "../../../api/model/notifications/NotificationDispatch";
import {User} from "../../../api/model/user/User";
import {Formatter} from "../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../utils/constants";
import {SearchItems, TagUserRow} from "../../snippet";
import {NotificationDispatchField} from "../../../api/model/notifications/NotificationDispatchField";
import useAlert from "../../../utils/hooks/useAlert";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";

type ModalCreateUserDispatchesProps = {
    onSubmit: (notificationDispatch: NotificationDispatch, broadcast: boolean) => void;
    onClose: () => void;
    active: boolean;
};

enum ModalCreateUserDispatchesRecipientType {
    ALL = "ALL",
    LIST = "LIST",
}

type ModalCreateUserDispatchesForm = {
    recipientType: ModalCreateUserDispatchesRecipientType;
    dispatchDate?: string;
}

const ModalCreateUserDispatches: FunctionComponent<ModalCreateUserDispatchesProps> = ({onSubmit, onClose, active}) => {
    const alert = useAlert();
    const {t: textNotifications} = useTranslation(TranslationPortalFile.NOTIFICATIONS);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const [isLoading, setLoading] = useState(false);
    const [dispatchForm, setDispatchForm] = useState<ModalCreateUserDispatchesForm>({recipientType: ModalCreateUserDispatchesRecipientType.LIST});
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [highlightedUsers, setHighlightedUsers] = useState<User[]>();
    const [isShowModalConfirmDispatch, setShowModalConfirmDispatch] = useState(false);

    useEffect(() => {
        setDispatchForm({recipientType: ModalCreateUserDispatchesRecipientType.LIST});
        setSelectedUsers([]);
        if (active) {
            (async () => {
                try {
                    setLoading(true);
                    const users = await session.getActiveUsers();
                    setUsers(users);
                } catch (e) {
                    if (e instanceof ErrorResponse) {
                        alert.failToLoad("users", e.message);
                    }
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [active, alert]);

    const handleAddUsers = (users: User[]) => {
        setSelectedUsers((prevState) => [...prevState, ...users]);
        setHighlightedUsers(users);
        setTimeout(() => {
            setHighlightedUsers([]);
        }, 1000);
    };

    const handleSubmit = () => {
        const userIds = dispatchForm.recipientType === ModalCreateUserDispatchesRecipientType.LIST ? selectedUsers.map((it) => it.id) : [];
        const notificationDispatch = new NotificationDispatch({
            [NotificationDispatchField.IDS]: userIds,
            [NotificationDispatchField.DISPATCH_DATE]: Formatter.convertDateToUTC(dispatchForm.dispatchDate)
        });
        onSubmit(notificationDispatch, dispatchForm.recipientType === ModalCreateUserDispatchesRecipientType.ALL);
        setShowModalConfirmDispatch(false);
    };

    const hasEmptyField = () => {
        return (dispatchForm.recipientType === ModalCreateUserDispatchesRecipientType.LIST && !selectedUsers.length) || !dispatchForm.dispatchDate;
    };

    return (
        <>
            <ModalNew onClose={onClose} active={active}>
                <ModalHeader>
                    <ModalHeaderTitle title={textNotifications("create_dispatches_to_users")}/>
                </ModalHeader>
                <ModalContent>
                    <FormLayoutRows>
                        <Alert text={textNotifications("message.recipients_mention")} severity={AlertSeverity.WARNING}/>
                        <FormLayoutColumns columns={3}>
                            <FieldBlock
                                label={textNotifications("field.dispatch_date")}
                                tooltip={textNotifications("tooltip.dispatch_date")}
                                required
                            >
                                <InputDate
                                    type={InputDateType.DATETIME_LOCAL}
                                    value={dispatchForm.dispatchDate}
                                    onChange={(value) => setDispatchForm((prevState) => ({...prevState, dispatchDate: value}) as ModalCreateUserDispatchesForm)}
                                />
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textNotifications("field.recipients")}>
                            <RadioButtons
                                id="recipientType"
                                value={dispatchForm.recipientType}
                                options={[
                                    {value: ModalCreateUserDispatchesRecipientType.LIST, label: textNotifications(`users.${ModalCreateUserDispatchesRecipientType.LIST}`)},
                                    {value: ModalCreateUserDispatchesRecipientType.ALL, label: textNotifications(`users.${ModalCreateUserDispatchesRecipientType.ALL}`)}
                                ]}
                                onChange={(value) => setDispatchForm((prevState) => ({...prevState, recipientType: value}) as ModalCreateUserDispatchesForm)}
                            />
                        </FieldBlock>
                        {dispatchForm.recipientType === ModalCreateUserDispatchesRecipientType.LIST &&
                            <Box>
                                <FormLayoutRows spacing={FlexContentSpacing.MEDIUM}>
                                    <SearchItems
                                        items={users}
                                        selectedItems={selectedUsers}
                                        searchField="fullName"
                                        onSubmit={handleAddUsers}
                                        loading={isLoading}
                                    />
                                    <FieldBlock
                                        label={textNotifications("users.selected", {count: selectedUsers.length})}
                                        actions={!!selectedUsers.length &&
                                            <ButtonLink onClick={() => setSelectedUsers([])} >
                                                {textCommon(Action.REMOVE_ALL.labelKey)}
                                            </ButtonLink>
                                        }
                                    >
                                        <ElementList placeholder={textNotifications("users.no_selected")} size={ElementListSize.BIG}>
                                            {selectedUsers.map((user) =>
                                                <TagUserRow
                                                    key={user.id}
                                                    user={user}
                                                    isHighlighted={highlightedUsers?.some(({id}) => user.id === id)}
                                                    onRemove={() => setSelectedUsers(selectedUsers.filter(({id}) => user.id !== id))}
                                                />
                                            )}
                                        </ElementList>
                                    </FieldBlock>
                                </FormLayoutRows>
                            </Box>
                        }
                    </FormLayoutRows>
                </ModalContent>
                <ModalActions>
                    <Button
                        size={ButtonSize.BIG}
                        style={ButtonStyle.PRIMARY_GREEN}
                        onClick={() => setShowModalConfirmDispatch(true)}
                        disabled={hasEmptyField()}
                    >
                        {textCommon(Action.SEND.labelKey)}
                    </Button>
                </ModalActions>
            </ModalNew>
            <ModalNew active={isShowModalConfirmDispatch}>
                <ModalContent>
                    <FormLayoutRows>
                        {dispatchForm.recipientType === ModalCreateUserDispatchesRecipientType.ALL ?
                            <Alert
                                severity={AlertSeverity.WARNING}
                                text={textNotifications("message.create_summary_all", {
                                    dispatchDate: Formatter.formatDate(dispatchForm.dispatchDate, Formatter.DATETIME_FORMAT)
                                })}
                            /> :
                            <Alert
                                severity={AlertSeverity.WARNING}
                                text={textNotifications("message.create_summary_specific", {
                                    dispatchDate: Formatter.formatDate(dispatchForm.dispatchDate, Formatter.DATETIME_FORMAT),
                                    count: selectedUsers?.length
                                })}
                            />
                        }
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            <span dangerouslySetInnerHTML={{__html: textNotifications("message.create_summary_confirm")}}/>
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                onClick={() => setShowModalConfirmDispatch(false)}
                            >
                                {textCommon(Action.CANCEL.labelKey)}
                            </Button>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.DEFAULT_MIDNIGHT}
                                onClick={handleSubmit}
                            >
                                {textNotifications("actions.create_dispatch")}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
        </>
    );
};

export default ModalCreateUserDispatches;
