export enum DistributionPropertiesField {
    PLATFORM_TYPES = "platform_types",
    BUSINESS_MODEL = "business_model",
    DOCUMENTATION_URL = "documentation_url",
    COMMENT = "comment",
    CONTACTS = "contacts",
    BILLING = "billing",
    DATA = "data",
    TECHNICAL = "technical"
}
