import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DataleaksSeverity} from "../DataleaksSeverity";

export class DataleaksVersionQuestion extends ApiModel {
    question_id: number = 0;
    index: number = 0;
    severity: DataleaksSeverity = DataleaksSeverity.MINOR;
    trait_weight: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
