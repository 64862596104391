import {ApiModel} from "../../../../common/api/model/ApiModel";

export class CustomerOrderTable extends ApiModel {
    project_id: string = "";
    dataset_id: string = "";
    table_id: string = "";
    row_count: number = 0;
    volume_per_limit: Map<string, number> = new Map<string, number>();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
