import {Color, IconProps} from "@sirdata/ui-lib";

export class CategoryType {
    static BRAND_SAFETY: CategoryType = new CategoryType("BRAND_SAFETY", Color.CORAL_LIGHTER, Color.CORAL, Color.CORAL_LIGHT, {name: "security"});
    static CONTEXTUAL: CategoryType = new CategoryType("CONTEXTUAL", Color.PURPLE, Color.WHITE, Color.PURPLE, {name: "wallpaper"});
    static DECLARATIVE: CategoryType = new CategoryType("DECLARATIVE", Color.CYAN_LIGHTER, Color.CYAN, Color.CYAN_LIGHT, {name: "fact_check", outlined: true});
    static INTENT: CategoryType = new CategoryType("INTENT", Color.CYAN_LIGHTER, Color.CYAN, Color.CYAN_LIGHT, {name: "ads_click"}).withAutoMinScore();
    static INTEREST: CategoryType = new CategoryType("INTEREST", Color.CYAN_LIGHTER, Color.CYAN, Color.CYAN_LIGHT, {name: "lightbulb", outlined: true}).withAutoMinScore();
    static MODELING: CategoryType = new CategoryType("MODELING", Color.CYAN_LIGHTER, Color.CYAN, Color.CYAN_LIGHT, {name: "view_in_ar", outlined: true});

    name: string;
    backgroundColor: Color;
    textColor: Color;
    borderColor: Color;
    icon: IconProps;
    useAutoMinScore?: boolean;

    private constructor(name: string, backgroundColor: Color, textColor: Color, borderColor: Color, icon: IconProps) {
        this.name = name;
        this.backgroundColor = backgroundColor;
        this.textColor = textColor;
        this.borderColor = borderColor;
        this.icon = icon;
    }

    static values(): CategoryType[] {
        return [
            CategoryType.INTENT,
            CategoryType.INTEREST,
            CategoryType.DECLARATIVE,
            CategoryType.CONTEXTUAL,
            CategoryType.MODELING,
            CategoryType.BRAND_SAFETY
        ];
    }

    static getByName(name: string): CategoryType | undefined {
        return this.values().find((it) => it.name === name);
    }

    withAutoMinScore(): CategoryType {
        this.useAutoMinScore = true;
        return this;
    }

    getIconColor(): Color {
        return this.name === CategoryType.CONTEXTUAL.name ? this.borderColor : this.textColor;
    }
}
