import {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Alert, AlertSeverity, ButtonLinkCancel, ButtonValidate, FieldBlock, Form, FormLayoutRows, FormValidationType, InputText, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Textarea} from "@sirdata/ui-lib";
import {TranslationPortalFile} from "../../../utils/constants";
import {SirdataListStackField} from "../../../api/model/cmp/list/sirdata-list/SirdataListStackField";
import {SirdataListStack} from "../../../api/model/cmp/list/sirdata-list/SirdataListStack";
import {CmpSirdataListPurposesCheckboxes} from "../../snippet";
import {session} from "../../../api/ApiSession";
import useAlert from "../../../utils/hooks/useAlert";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {GlobalVendorList} from "../../../api/model/cmp/list/global-vendor-list/GlobalVendorList";
import {SirdataListPurpose} from "../../../api/model/cmp/list/sirdata-list/SirdataListPurpose";
import {CmpConfigLanguage} from "../../../api/model/cmp/config/CmpConfigLanguage";

type ModalCreateCmpSirdataListStackProps = {
    active: boolean;
    globalVendorList: GlobalVendorList;
    extraPurposes: SirdataListPurpose[];
    onClose: (refresh?: boolean) => void;
};

const ModalCreateCmpSirdataListStack: FunctionComponent<ModalCreateCmpSirdataListStackProps> = ({active, globalVendorList, extraPurposes, onClose}) => {
    const alert = useAlert();
    const {t: textCmpSirdataList} = useTranslation(TranslationPortalFile.CMP_SIRDATA_LIST);
    const [isSubmitting, setSubmitting] = useState(false);
    const [stack, setStack] = useState<SirdataListStack>(new SirdataListStack());
    const [selectedPurposes, setSelectedPurposes] = useState<number[]>([]);
    const [selectedRequiredPurposes, setSelectedRequiredPurposes] = useState<number[]>([]);
    const [selectedSpecialFeatures, setSelectedSpecialFeatures] = useState<number[]>([]);
    const [selectedExtraPurposes, setSelectedExtraPurposes] = useState<number[]>([]);
    const FORM_ID = "form-create-cmp-sirdata-list-stack";

    useEffect(() => {
        if (active) {
            setStack(new SirdataListStack());
            setSelectedPurposes([]);
            setSelectedRequiredPurposes([]);
            setSelectedSpecialFeatures([]);
            setSelectedExtraPurposes([]);
        }
    }, [active]);

    const handleChange = (field: SirdataListStackField, value: any) => {
        setStack((prevState) => new SirdataListStack({...prevState, [field]: value}));
    };

    const handleTogglePurposes = (purposes: number[]) => {
        setSelectedPurposes(purposes);
        handleChange(SirdataListStackField.PURPOSES, purposes);

        if (selectedRequiredPurposes.some((id) => purposes.includes(id))) {
            const updatedRequiredPurposes = selectedRequiredPurposes.filter((id) => !purposes.includes(id));
            setSelectedRequiredPurposes(updatedRequiredPurposes);
            handleChange(SirdataListStackField.REQUIRED_PURPOSES, updatedRequiredPurposes);
        }
    };

    const handleToggleRequiredPurposes = (requiredPurposes: number[]) => {
        setSelectedRequiredPurposes(requiredPurposes);
        handleChange(SirdataListStackField.REQUIRED_PURPOSES, requiredPurposes);

        if (selectedPurposes.some((id) => requiredPurposes.includes(id))) {
            const updatedPurposes = selectedPurposes.filter((id) => !requiredPurposes.includes(id));
            setSelectedPurposes(updatedPurposes);
            handleChange(SirdataListStackField.PURPOSES, updatedPurposes);
        }
    };

    const handleToggleSpecialFeatures = (specialFeatures: number[]) => {
        setSelectedSpecialFeatures(specialFeatures);
        handleChange(SirdataListStackField.SPECIAL_FEATURES, specialFeatures);
    };

    const handleToggleExtraPurposes = (extraPurposes: number[]) => {
        setSelectedExtraPurposes(extraPurposes);
        handleChange(SirdataListStackField.EXTRA_PURPOSES, extraPurposes);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            for (const language of CmpConfigLanguage.values()) {
                const newStack = new SirdataListStack(stack);
                if (language !== CmpConfigLanguage.ENGLISH) {
                    newStack.name = "";
                    newStack.description = "";
                }
                await session.restCmpSirdataListLanguage.upsertStack(language.name, newStack);
            }
            alert.createWithSuccess("stack");
            onClose(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("stack", e.message);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={() => onClose(false)} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCmpSirdataList("create_stack")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <Alert
                            severity={AlertSeverity.INFO}
                            text={textCmpSirdataList("message.create_stack_info")}
                        />
                        <FieldBlock label={textCmpSirdataList(`field.stack.${SirdataListStackField.NAME}`)} required>
                            <InputText
                                value={stack.name}
                                onChange={(value) => handleChange(SirdataListStackField.NAME, value)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.stack.${SirdataListStackField.DESCRIPTION}`)} required>
                            <Textarea
                                value={stack.description}
                                onChange={(value) => handleChange(SirdataListStackField.DESCRIPTION, value)}
                                rows={5}
                                isExpandable
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.stack.${SirdataListStackField.PURPOSES}`)}>
                            <CmpSirdataListPurposesCheckboxes
                                purposes={globalVendorList.purposes}
                                selectedPurposes={selectedPurposes}
                                onChange={handleTogglePurposes}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.stack.${SirdataListStackField.REQUIRED_PURPOSES}`)}>
                            <CmpSirdataListPurposesCheckboxes
                                purposes={globalVendorList.purposes}
                                selectedPurposes={selectedRequiredPurposes}
                                onChange={handleToggleRequiredPurposes}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.stack.${SirdataListStackField.SPECIAL_FEATURES}`)}>
                            <CmpSirdataListPurposesCheckboxes
                                purposes={globalVendorList.specialFeatures}
                                selectedPurposes={selectedSpecialFeatures}
                                onChange={handleToggleSpecialFeatures}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCmpSirdataList(`field.stack.${SirdataListStackField.EXTRA_PURPOSES}`)}>
                            <CmpSirdataListPurposesCheckboxes
                                purposes={extraPurposes}
                                selectedPurposes={selectedExtraPurposes}
                                onChange={handleToggleExtraPurposes}
                            />
                        </FieldBlock>
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={() => onClose(false)}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateCmpSirdataListStack;
