export enum RevenueSeatField {
    ADVERTISER = "advertiser",
    ADVERTISER_ID = "advertiser_id",
    BRAND = "brand",
    BUYER = "buyer",
    CLIENT_SIDE = "client_side",
    COUNTRY = "country",
    CREATED_AT = "created_at",
    CUSTOM_FIELDS = "custom_fields",
    END_DATE_NEW_BUSINESS = "end_date_new_business",
    HUBSPOT_ID = "hubspot_id",
    ID = "id",
    ORGANIZATION = "organization",
    ORGANIZATION_GROUP = "organization_group",
    ORGANIZATION_ID = "organization_id",
    ORGANIZATION_OWNER = "organization_owner",
    ORGANIZATION_TYPE = "organization_type",
    OWNER = "owner",
    OWNER_ID = "owner_id",
    PARTNER_ID = "partner_id",
    PLATFORM = "platform",
    SEAT_ID = "seat_id",
    STATUS = "status",
    UPDATED_AT = "updated_at"
}
