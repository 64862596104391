import {Action, ActionsMenu, ContentBlock, ContentBlockAction, SearchToolbar, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SearchQuery} from "../../../api/interface/SearchQuery";
import {SearchResult} from "../../../api/interface/SearchResult";
import {Authorization} from "../../../api/model/account/Authorization";
import {CategoryGroup} from "../../../api/model/audience/category/CategoryGroup";
import {CategoryGroupField} from "../../../api/model/audience/category/CategoryGroupField";
import {CategoryTaxonomy} from "../../../api/model/audience/category/CategoryTaxonomy";
import {RestrictedContent} from "../../../common/component/widget";
import {MODAL_PAGE_SIZE, TranslationPortalFile} from "../../../utils/constants";
import ModalEditCategoryGroupLinkedCategoryGroups from "../../modal/categories/ModalEditCategoryGroupLinkedCategoryGroups";
import ModalConfirmRemove from "../../modal/ModalConfirmRemove";
import {Tag} from "../index";

type CategoryGroupLinkedCategoryGroupsProps = {
    links: CategoryGroup[];
    taxonomy: CategoryTaxonomy;
    onChange: (categoryGroups: CategoryGroup[]) => void;
    onRemove: (categoryGroup: CategoryGroup) => void;
};

const CategoryGroupLinkedCategoryGroups: FC<CategoryGroupLinkedCategoryGroupsProps> = ({links, taxonomy, onChange, onRemove}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const [isShowModalLinkedCategories, setShowModalEditLinkedCategories] = useState(false);
    const [linkCategoryToRemove, setLinkCategoryToRemove] = useState<CategoryGroup>();
    const [searchQuery, setSearchQuery] = useState<SearchQuery>(new SearchQuery({sortBy: CategoryGroupField.ID}));
    const [searchResult, setSearchResult] = useState<SearchResult<CategoryGroup>>(new SearchResult(CategoryGroup));

    useEffect(() => {
        let filteredLinks = (links || []);
        if (searchQuery.query) {
            filteredLinks = filteredLinks.filter((it) => `${it.id} ${it.name}`.toLowerCase().includes(searchQuery.query.toLowerCase()));
        }
        filteredLinks = searchQuery.sortItems(filteredLinks);
        const result = SearchResult.buildFromList(CategoryGroup, filteredLinks, searchQuery.page, MODAL_PAGE_SIZE);
        setSearchResult(result);
    }, [links, searchQuery]);

    const handleChangeSortOrder = (sortBy: string, reverseOrder?: boolean) => {
        setSearchQuery((prevState) => new SearchQuery({...prevState, sortBy: sortBy, reverseOrder: reverseOrder}));
    };

    const handleChangeQuery = (value: string) => {
        setSearchQuery((prevState) => new SearchQuery({...prevState, query: value, page: 0}));
    };

    const handleChangePage = (page: number) => {
        setSearchQuery((prevState) => new SearchQuery({...prevState, page: page - 1}));
    };

    const handleRemoveLinkCategory = () => {
        if (!linkCategoryToRemove) return;
        onRemove(linkCategoryToRemove);
        setLinkCategoryToRemove(undefined);
    };

    return (
        <ContentBlock
            header={{
                title: {label: textCategories("section.mapped_categories")},
                actions: [
                    <RestrictedContent key="handle_Linked_cats" allowedTo={Authorization.CATEGORIES.update}>
                        <ContentBlockAction action={Action.EDIT} onClick={() => setShowModalEditLinkedCategories(true)}/>
                    </RestrictedContent>
                ]
            }}
        >
            <SearchToolbar searchBar={{placeholder: textCategories("search.placeholder"), value: searchQuery.query, onSubmit: handleChangeQuery}}/>
            <Table
                pagination={searchResult.getPagination(handleChangePage)}
                onSort={handleChangeSortOrder}
                columns={[
                    {label: textCategories(`field.${CategoryGroupField.TAXONOMY}`), width: 10, styles: TableColumnStyle.ALIGN_CENTER},
                    {label: textCategories(`field.${CategoryGroupField.ID}`), width: 10, styles: TableColumnStyle.ALIGN_CENTER, sort: {field: CategoryGroupField.ID, reverseOrder: false}},
                    {label: textCategories(`field.${CategoryGroupField.CODE_NAME}`), width: 80, sort: {field: CategoryGroupField.CODE_NAME, reverseOrder: false}},
                    {width: 5}
                ]}
            >
                {searchResult.elements.map((link) =>
                    <TableRow key={link.id} onClick={{link: link.getRoute()}}>
                        <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                            <Tag label={textCategories(`taxonomy.${link.taxonomy}`)} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
                        </TableColumn>
                        <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{link.id}</TableColumn>
                        <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{link.fullName}</TableColumn>
                        <TableActionColumn>
                            <RestrictedContent allowedTo={Authorization.CATEGORIES.update}>
                                <ActionsMenu
                                    iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                                    items={[
                                        {label: textCommon(Action.REMOVE.labelKey), onClick: () => setLinkCategoryToRemove(link), critical: true, separator: true}
                                    ]}
                                />
                            </RestrictedContent>
                        </TableActionColumn>
                    </TableRow>
                )}
            </Table>
            <ModalEditCategoryGroupLinkedCategoryGroups
                active={isShowModalLinkedCategories}
                taxonomy={taxonomy.name === CategoryTaxonomy.SIRDATA.name ? CategoryTaxonomy.IAB : CategoryTaxonomy.SIRDATA}
                currentLinks={links}
                onSubmit={(categoryGroups) => {
                    onChange(categoryGroups);
                    setShowModalEditLinkedCategories(false);
                }}
                onClose={() => setShowModalEditLinkedCategories(false)}
            />
            <ModalConfirmRemove
                active={!!linkCategoryToRemove}
                entity={`category "${linkCategoryToRemove?.fullName}"`}
                confirm={handleRemoveLinkCategory}
                cancel={() => setLinkCategoryToRemove(undefined)}
            />
        </ContentBlock>
    );
};

export default CategoryGroupLinkedCategoryGroups;
