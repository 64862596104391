import {ApiModel} from "../../../../common/api/model/ApiModel";
import {DataleaksVersionScoringDetails} from "./DataleaksVersionScoringDetails";

export class DataleaksVersionScoringCmps extends ApiModel {
    total_audits: number = 0;
    _cmps: Map<string, DataleaksVersionScoringDetails> = new Map<string, DataleaksVersionScoringDetails>();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get cmps(): Map<string, DataleaksVersionScoringDetails> {
        return this._cmps;
    }

    set cmps(cmps: Map<string, DataleaksVersionScoringDetails>) {
        if (Object.entries(cmps).length) {
            const values = new Map<string, DataleaksVersionScoringDetails>();
            Object.entries(cmps).forEach(([cmpName, details]) => {
                values.set(cmpName, new DataleaksVersionScoringDetails(details));
            });
            this._cmps = values;
        } else {
            this._cmps = new Map<string, DataleaksVersionScoringDetails>();
        }
    }
}
