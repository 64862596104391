import {ApiModel} from "../../../common/api/model/ApiModel";

export class ApiDocumentationItem extends ApiModel {
    name: string = "";
    publicPath?: string;
    privatePath?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
