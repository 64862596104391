import moment from "moment/moment";
import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Module} from "../../../../utils/Module";
import {Status} from "../../../../utils/Status";
import {ContentElement} from "../../../interface/ContentElement";
import {CustomerStorageCsvOptions} from "./CustomerStorageCsvOptions";
import {CUSTOMER_STORAGE_STATUSES} from "./CustomerStorageStatus";
import {CustomerStorageTable} from "./CustomerStorageTable";
import {CustomerStorageTableIntersect} from "./CustomerStorageTableIntersect";
import {CustomerStorageUploadedFile} from "./CustomerStorageUploadedFile";

export const CUSTOMER_STORAGE_MAPPING_TYPE_EMAIL_HASH = "EMAIL_HASH";

export class CustomerStorage extends ApiModel {
    id: number = 0;
    name: string = "";
    creation_ts: string = "";
    last_update_ts: string = "";
    expiration_ts: string = "";
    partner_id: number = 0;
    partner_account_id: number = 0;
    uploaded_file: CustomerStorageUploadedFile = new CustomerStorageUploadedFile();
    mapping_type: string = CUSTOMER_STORAGE_MAPPING_TYPE_EMAIL_HASH;
    table: CustomerStorageTable = new CustomerStorageTable();
    table_intersect: CustomerStorageTableIntersect = new CustomerStorageTableIntersect();
    csv_options: CustomerStorageCsvOptions = new CustomerStorageCsvOptions();
    status: string = Status.PENDING.name;
    error_code: string = "";
    error_message: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getStatus(): Status {
        return CUSTOMER_STORAGE_STATUSES.find((it) => it.name === this.status) || Status.PENDING;
    }

    canBeReloaded(): boolean {
        if ([Status.OK.name, Status.DELETED.name].includes(this.status)) {
            return false;
        }
        return moment(this.expiration_ts).isAfter(moment());
    }

    getRoute(): string {
        return Module.CUSTOMER_STORAGES.buildRoute(this.id);
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        element.status = this.getStatus();
        element.createdAt = new Date(this.creation_ts);
        element.lastUpdated = new Date(this.last_update_ts);
        return element;
    }
}

