import {Action, ActionsMenu, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, Icon, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Authorization} from "../../../../api/model/account/Authorization";
import {RevenueImport} from "../../../../api/model/revenue/import/RevenueImport";
import {RestrictedContent} from "../../../../common/component/widget";
import {TranslationPortalFile} from "../../../../utils/constants";
import {LabelStatus, LabelUser, Tag} from "../../index";
import {Formatter} from "../../../../common/utils/Formatter";

type RevenueImportRowProps = {
    revenueImport: RevenueImport;
    onDelete: () => void;
    onUpload: () => void;
}

const RevenueImportRow: FunctionComponent<RevenueImportRowProps> = ({revenueImport, onDelete, onUpload}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textRevenuesImports} = useTranslation(TranslationPortalFile.REVENUES_IMPORTS);
    const [isOpenMore, setOpenMore] = useState<boolean>();

    return (
        <>
            <TableRow onClick={() => setOpenMore((prev) => !prev)}>
                <TableColumn>
                    <LabelStatus status={revenueImport.getStatus()}/>
                </TableColumn>
                <TableColumn>{revenueImport.platform_id}</TableColumn>
                <TableColumn><LabelUser user={revenueImport.user}/></TableColumn>
                <TableColumn>{revenueImport.name}</TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{revenueImport.count_row_imported}</TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{revenueImport.total_revenue}</TableColumn>
                <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                    <Tag label={textRevenuesImports(`date_type.${revenueImport.date_type}`)} style={TagStyle.PRIMARY_OCEAN}/>
                </TableColumn>
                <TableColumn>
                    <FlexContent direction={FlexContentDirection.COLUMN}>
                        <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                            <Icon name="event_available"/>
                            <span>{revenueImport.period_start ? Formatter.formatDate(revenueImport.period_start) : "-"}</span>
                        </FlexContent>
                        <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                            <Icon name="event_busy"/>
                            <span>{revenueImport.period_end ? Formatter.formatDate(revenueImport.period_end) : "-"}</span>
                        </FlexContent>
                    </FlexContent>
                </TableColumn>
                <TableColumn>
                    <FlexContent direction={FlexContentDirection.COLUMN}>
                        <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                            <Icon name="schedule" outlined/>
                            <span>{Formatter.formatUTCDate(revenueImport.created_at, Formatter.DATETIME_FORMAT)}</span>
                        </FlexContent>
                        {revenueImport.updated_at &&
                        <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                            <Icon name="update"/>
                            <span>{Formatter.formatUTCDate(revenueImport.updated_at, Formatter.DATETIME_FORMAT)}</span>
                        </FlexContent>
                        }
                    </FlexContent>
                </TableColumn>
                <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                    <RestrictedContent allowedTo={Authorization.REVENUES_IMPORTS.update}>
                        <ActionsMenu
                            iconTooltip={{icon: Action.EDIT.icon, text: textCommon(Action.EDIT.labelKey)}}
                            items={[
                                {label: textRevenuesImports("actions.reupload_revenues"), onClick: onUpload},
                                {label: textCommon(Action.DELETE.labelKey), onClick: onDelete, critical: true, separator: true}
                            ]}
                        />
                    </RestrictedContent>
                </TableActionColumn>
            </TableRow>
            {isOpenMore &&
            <TableRow>
                <TableColumn colSpan={8}>{revenueImport.error_message}</TableColumn>
            </TableRow>
            }
        </>
    );

};

export default RevenueImportRow;
