import {Rest} from "../../common/api/rest/Rest";
import {AccountWithAuthorizationItem} from "../model/auth/AccountWithAuthorizationItem";
import {ApiProfile} from "../model/auth/ApiProfile";
import {RestService} from "../interface/RestService";
import {SearchResult} from "../interface/SearchResult";

export class RestUserProfile extends Rest implements RestService<ApiProfile> {
    rootPath = "/console-api/api-authorizations/user/profile";

    search(params: any): Promise<SearchResult<ApiProfile>> {
        return new Promise<SearchResult<ApiProfile>>(() => {}); // TODO Implement route
    }

    list(): Promise<ApiProfile[]> {
        return this._client.get(`${this.rootPath}`, ApiProfile) as Promise<ApiProfile[]>;
    }

    create(profile: ApiProfile): Promise<ApiProfile> {
        return this._client.post(`${this.rootPath}`, profile, ApiProfile) as Promise<ApiProfile>;
    }

    get(profileId: number): Promise<ApiProfile> {
        return this._client.get(`${this.rootPath}/${profileId}`, ApiProfile) as Promise<ApiProfile>;
    }

    update(profile: ApiProfile): Promise<ApiProfile> {
        return this._client.put(`${this.rootPath}/${profile.id}`, profile, ApiProfile) as Promise<ApiProfile>;
    }

    delete(profileId: number) {
        return this._client.delete(`${this.rootPath}/${profileId}`);
    }

    addAuthorizations(profileId: number, authorizations: string[]): Promise<ApiProfile> {
        return this._client.post(`${this.rootPath}/${profileId}/authorization-set`, authorizations, ApiProfile) as Promise<ApiProfile>;
    }

    removeAuthorization(profileId: number, authorization: string): Promise<ApiProfile> {
        return this._client.delete(`${this.rootPath}/${profileId}/authorization/${authorization}`) as Promise<ApiProfile>;
    }

    getDetails(profileId: number): Promise<AccountWithAuthorizationItem[]> {
        return this._client.get(`${this.rootPath}/${profileId}/user`, AccountWithAuthorizationItem) as Promise<AccountWithAuthorizationItem[]>;
    }
}
