import {Rest} from "../../common/api/rest/Rest";
import {CmpConfigLanguage} from "../model/cmp/config/CmpConfigLanguage";
import {ConsentManagementPlatformList} from "../model/cmp/list/cmp-list/ConsentManagementPlatformList";
import {FreemiumList} from "../model/cmp/list/FreemiumList";
import {GlobalVendorList} from "../model/cmp/list/global-vendor-list/GlobalVendorList";
import {GlobalVendorListVersion} from "../model/cmp/list/global-vendor-list/GlobalVendorListVersion";
import {GoogleACProviderList} from "../model/cmp/list/google-ac-provider-list/GoogleACProviderList";
import {NetworkList} from "../model/cmp/list/network-list/NetworkList";
import {SirdataListVersion} from "../model/cmp/list/sirdata-list/SirdataListVersion";

export class RestCmpList extends Rest {
    rootPath = "/console-api/cmp/list";

    getCmpList(): Promise<ConsentManagementPlatformList> {
        return this._client.get(`${this.rootPath}/cmp`, ConsentManagementPlatformList) as Promise<ConsentManagementPlatformList>;
    }

    getGlobalVendorList(language?: CmpConfigLanguage): Promise<GlobalVendorList> {
        return this._client.get(`${this.rootPath}/gvl/${GlobalVendorListVersion.V3}?lang=${language?.name || ""}`, GlobalVendorList) as Promise<GlobalVendorList>;
    }

    getGlobalVendorListArchive(version: number): Promise<GlobalVendorList> {
        return this._client.get(`${this.rootPath}/gvl/${GlobalVendorListVersion.V3}/version/${version}`, GlobalVendorList) as Promise<GlobalVendorList>;
    }

    getGoogleACProviderList(): Promise<GoogleACProviderList> {
        return this._client.get(`${this.rootPath}/google-ac`, GoogleACProviderList) as Promise<GoogleACProviderList>;
    }

    getNetworkList(): Promise<NetworkList> {
        return this._client.get(`${this.rootPath}/networks`, NetworkList) as Promise<NetworkList>;
    }

    getSirdataList(language?: CmpConfigLanguage): Promise<SirdataListVersion> {
        return this._client.get(`${this.rootPath}/sirdata?lang=${language?.name || ""}`, SirdataListVersion) as Promise<SirdataListVersion>;
    }

    getFreemiumList(): Promise<FreemiumList> {
        return this._client.get(`${this.rootPath}/freemium`, FreemiumList) as Promise<FreemiumList>;
    }
}
