import {CategorizerConfig} from "./CategorizerConfig";
import {CategorizerConfigPrivacyMode} from "./CategorizerConfigPrivacyMode";

export class CategorizerConfigPrivacy extends CategorizerConfig {
    safety: string = CategorizerConfigPrivacyMode.SAFE.name;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            safety: this.safety
        };
    }
}
