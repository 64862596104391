import {TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import React, {FC} from "react";
import {DataleaksVersion} from "../../../../api/model/dataleaks/version/DataleaksVersion";
import {Formatter} from "../../../../common/utils/Formatter";
import Tag from "../../tag/Tag";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../../utils/constants";

type DataleaksVersionRowProps = {
    version: DataleaksVersion;
};

const DataleaksVersionRow: FC<DataleaksVersionRowProps> = ({version}) => {
    const {t: textDataleaksVersions} = useTranslation(TranslationPortalFile.DATALEAKS_VERSIONS);

    return (
        <TableRow onClick={{link: version.getRoute()}}>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <Tag label={`${version.label}`} style={TagStyle.PRIMARY_MIDNIGHT_LIGHT}/>
            </TableColumn>
            <TableColumn>{version.comment}</TableColumn>
            <TableColumn>{textDataleaksVersions("question_count", {count: version.questions.length})}</TableColumn>
            <TableColumn>{Formatter.formatDate(version.created_at, Formatter.DATETIME_FORMAT)}</TableColumn>
            <TableColumn>{Formatter.formatDate(version.updated_at, Formatter.DATETIME_FORMAT)}</TableColumn>
        </TableRow>
    );
};

export default DataleaksVersionRow;
