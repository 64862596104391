import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {SearchQuery} from "../../../interface/SearchQuery";
import {SgtmContainerField} from "./SgtmContainerField";
import {SgtmContainerSortOrder} from "./SgtmContainerSortOrder";

export class SgtmContainerSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    status?: string;
    partner_id?: number;

    withSearchParams(params: SearchParams): SgtmContainerSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(SgtmContainerField.CREATION_TS, true) as SgtmContainerSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS);
        newQuery.partner_id = params.getNumber(SearchParamsField.PARTNER);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            sort_by: this.sortBy,
            sort_order: this.reverseOrder ? SgtmContainerSortOrder.DESCENDANT : SgtmContainerSortOrder.ASCENDANT,
            search: this.query,
            size: this.size,
            page: this.page,
            status: this.status,
            partner_id: this.partner_id
        };
    }
}
