import {TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {PortalSetting} from "../../../../api/model/portal-setting/PortalSetting";
import {LabelService, LabelStatus} from "../../index";

type PortalSettingsRowProps = {
    item: PortalSetting;
}

const PortalSettingsRow: FunctionComponent<PortalSettingsRowProps> = ({item}) => {
    return (
        <TableRow onClick={{link: item.getRoute()}}>
            <TableColumn>
                <LabelStatus status={item.getStatus()}/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{item.id}</TableColumn>
            <TableColumn>
                <LabelService service={item.service}/>
            </TableColumn>
            <TableColumn>{item.title}</TableColumn>
        </TableRow>
    );
};

export default PortalSettingsRow;