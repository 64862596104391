import {ApiModel} from "../../../common/api/model/ApiModel";
import {CountryCode} from "../../../common/api/model/Country";

export class AccountWithAuthorizationItem extends ApiModel {
    id: number = 0;
    company: string = "";
    name: string = "";
    country_id: number = CountryCode.FRANCE.id;
    creation_service: string = "";
    authorization_set: string[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
