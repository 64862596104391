import {ButtonLinkCancel, ButtonValidate, FlexContentSpacing, FormLayoutRows, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CategoryLinkExternal} from "../../../api/model/audience/category/CategoryLinkExternal";
import {Distribution} from "../../../api/model/audience/distribution/Distribution";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {sortItems} from "../../../common/utils/helper";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {CategoryLinkExternalRow} from "../../snippet";

export type ModalAddCategoryLinksExternalProps = {
    active: boolean;
    categoryId: number;
    initLink?: CategoryLinkExternal;
    currentLinks: CategoryLinkExternal[];
    onSubmit: (links: CategoryLinkExternal[]) => void;
    onClose: () => void;
};

const ModalAddCategoryLinksExternal: FunctionComponent<ModalAddCategoryLinksExternalProps> = ({active, categoryId, currentLinks, onSubmit, onClose}) => {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const alert = useAlert();

    const [distributions, setDistributions] = useState<Distribution[]>([]);
    const [availableDistributions, setAvailableDistributions] = useState<Distribution[]>([]);
    const [currentDistributions, setCurrentDistributions] = useState<Distribution[]>([]);
    const [currentItems, setCurrentItems] = useState<CategoryLinkExternal[]>([]);

    useEffect(() => {
        if (!active) {
            setCurrentDistributions([]);
            setCurrentItems([]);
        }
    }, [active]);

    useEffect(() => {
        (async () => {
            try {
                setDistributions(await session.restList.getDistributions());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("distributions", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        const currentLinksDistributions = currentLinks.map((it) => it.map_name);
        setAvailableDistributions(distributions.filter((item) => item.used_for_category && !currentLinksDistributions.includes(item.name)));
    }, [distributions, currentLinks]);

    useEffect(() => {
        setCurrentDistributions(() => {
            const currentSelectedDistributions = currentItems.map((it) => it.map_name);
            const items = availableDistributions.filter((it) => !currentSelectedDistributions.includes(it.name));
            return sortItems(items, "label");
        });
    }, [currentItems, availableDistributions]);

    const addDistribution = (mapName: string) => {
        if (mapName === "") return;

        const newLink = new CategoryLinkExternal();
        newLink.category_id = categoryId;
        newLink.map_name = mapName;
        setCurrentItems((prevState) => ([newLink, ...prevState]));
    };

    const handleChange = (link: CategoryLinkExternal) => {
        setCurrentItems((prevState) => {
            const newState = [...prevState];
            const index = newState.findIndex((it) => it.map_name === link.map_name);
            newState[index] = link;
            return newState;
        });
    };

    const handleRemove = (item: CategoryLinkExternal) => {
        setCurrentItems((prevState) => prevState.filter((it) => it.map_name !== item.map_name));
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategories("modal.add_distributions")}/>
            </ModalHeader>
            <ModalContent>
                <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                    <Select
                        options={currentDistributions.map((it) => ({label: it.label, value: it.name}))}
                        onChange={(option) => addDistribution(`${option?.value || ""}`)}
                        placeholder={textCategories("action.select_distribution")}
                    />
                    <Table
                        columns={[
                            {label: textCategories("field.external.status"), width: 20, styles: TableColumnStyle.ALIGN_CENTER},
                            {label: textCategories("field.external.platform"), width: 30},
                            {label: textCategories("field.external.platform_id"), width: 45},
                            {width: 5}
                        ]}
                    >
                        {currentItems.map((item) =>
                            <CategoryLinkExternalRow
                                key={item.id}
                                link={item}
                                onChangeStatus={handleChange}
                                onChangeValue={handleChange}
                                onRemove={() => handleRemove(item)}
                            />
                        )}
                    </Table>
                </FormLayoutRows>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate onClick={() => onSubmit(currentItems)} disabled={!currentItems.length}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalAddCategoryLinksExternal;
