import {
    ButtonBack,
    ButtonNext,
    ButtonSize,
    ButtonValidate,
    Form,
    FormLayoutRows,
    FormLayoutTitle,
    FormValidationType,
    ModalActions,
    ModalActionsAlignment,
    ModalContent,
    ModalHeader,
    ModalHeaderDirection,
    ModalHeaderTitle,
    ModalNew,
    ModalNewSize,
    ModalStepper
} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";
import {DataleaksAudit} from "../../../api/model/dataleaks/audit/DataleaksAudit";
import {useNavigate} from "react-router-dom";
import {DataleaksVersion} from "../../../api/model/dataleaks/version/DataleaksVersion";
import {DataleaksQuestion} from "../../../api/model/dataleaks/question/DataleaksQuestion";
import {sortItems} from "../../../common/utils/helper";
import {ConsentManagementPlatformField} from "../../../api/model/cmp/list/cmp-list/ConsentManagementPlatformField";
import {ConsentManagementPlatform} from "../../../api/model/cmp/list/cmp-list/ConsentManagementPlatform";
import {DataleaksVersionField} from "../../../api/model/dataleaks/version/DataleaksVersionField";
import {DataleaksAuditStep} from "../../../utils/dataleaks/DataleaksAuditStep";
import ModalCreateDataleaksAuditPartner from "./ModalCreateDataleaksAuditPartner";
import ModalCreateDataleaksAuditWebsite from "./ModalCreateDataleaksAuditWebsite";
import ModalCreateDataleaksAuditQuestions from "./ModalCreateDataleaksAuditQuestions";
import ModalCreateDataleaksAuditContact from "./ModalCreateDataleaksAuditContact";

type ModalCreateDataleaksAuditProps = {
    active: boolean;
    onClose: () => void;
};

const ModalCreateDataleaksAudit: FunctionComponent<ModalCreateDataleaksAuditProps> = ({active, onClose}) => {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textDataleaksAudits} = useTranslation(TranslationPortalFile.DATALEAKS_AUDITS);
    const [audit, setAudit] = useState<DataleaksAudit>(new DataleaksAudit());
    const [version, setVersion] = useState<DataleaksVersion>(new DataleaksVersion());
    const [questions, setQuestions] = useState<DataleaksQuestion[]>([]);
    const [cmps, setCmps] = useState<ConsentManagementPlatform[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const FORM_ID = "form-create-dataleaks-audit";
    const [currentStep, setCurrentStep] = useState<DataleaksAuditStep>(DataleaksAuditStep.firstStep());

    useEffect(() => {
        if (active) {
            setAudit(new DataleaksAudit());
            setCurrentStep(DataleaksAuditStep.firstStep());
        }
    }, [active]);

    useEffect(() => {
        (async () => {
            try {
                let versions = await session.restDataleaksVersion.list();
                if (!versions.length) {
                    alert.failToLoad("form", "no version available");
                    return;
                }

                versions = sortItems(versions, DataleaksVersionField.ID, true);
                const version = versions[0];
                setVersion(version);

                const questions = await session.restDataleaksQuestion.list();
                const versionQuestions: DataleaksQuestion[] = [];
                version.questions.forEach((it) => {
                    const question = questions.find((item) => item.id === it.question_id);
                    if (question) {
                        versionQuestions.push(question);
                    }
                });
                setQuestions(versionQuestions);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("form", e.message);
                }
            }

            try {
                const cmpList = await session.restCmpList.getCmpList();
                setCmps(sortItems(cmpList.cmps, ConsentManagementPlatformField.NAME));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("CMP list", e.message);
                }
            }
        })();
    }, [alert]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const nextStep = currentStep.next;
        if (nextStep) {
            setCurrentStep(nextStep);
        } else {
            setSubmitting(true);
            try {
                audit.user_id = session.userId;
                audit.version_id = version.id;
                const newAudit = await session.restDataleaksAudit.create(audit);
                navigate(newAudit.getRoute());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToCreate("audit", e.message);
                }
            } finally {
                setSubmitting(false);
            }
        }
    };

    const handleBack = () => {
        const previousStep = currentStep.previous;
        if (previousStep) {
            setCurrentStep(previousStep);
        }
    };

    const buildStepComponent = () => {
        switch (currentStep) {
            case DataleaksAuditStep.PARTNER:
                return (
                    <ModalCreateDataleaksAuditPartner
                        audit={audit}
                        setAudit={setAudit}
                    />
                );
            case DataleaksAuditStep.CONTACT:
                return (
                    <ModalCreateDataleaksAuditContact
                        audit={audit}
                        setAudit={setAudit}
                    />
                );
            case DataleaksAuditStep.WEBSITE:
                return (
                    <ModalCreateDataleaksAuditWebsite
                        audit={audit}
                        setAudit={setAudit}
                        cmps={cmps}
                    />
                );
            case DataleaksAuditStep.QUESTIONS:
                return (
                    <ModalCreateDataleaksAuditQuestions
                        audit={audit}
                        setAudit={setAudit}
                        version={version}
                        questions={questions}
                    />
                );
        }
        return <></>;
    };

    return (
        <ModalNew onClose={onClose} active={active} size={currentStep === DataleaksAuditStep.QUESTIONS ? ModalNewSize.FULLSCREEN : undefined}>
            <ModalHeader direction={ModalHeaderDirection.COLUMN}>
                <ModalStepper activeStep={currentStep.index} steps={DataleaksAuditStep.steps().length}/>
                <ModalHeaderTitle title={textDataleaksAudits("modal_create.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutTitle>{textDataleaksAudits(`modal_create.step.${currentStep.name}`)}</FormLayoutTitle>
                        {buildStepComponent()}
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions alignment={ModalActionsAlignment.SPACE_BETWEEN}>
                <span style={{visibility: currentStep.previous ? "visible" : "hidden"}}>
                    <ButtonBack onClick={handleBack} noIcon/>
                </span>
                {currentStep === DataleaksAuditStep.lastStep() ?
                    <ButtonValidate
                        form={FORM_ID}
                        size={ButtonSize.MEDIUM}
                        disabled={audit.answers.size < version.questions.length}
                        loading={isSubmitting}
                    /> :
                    <ButtonNext form={FORM_ID} noIcon/>
                }
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateDataleaksAudit;
