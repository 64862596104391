export enum CustomerCountingField {
    ID = "id",
    NAME = "name",
    CREATION_TS = "creation_ts",
    LAST_UPDATE_TS = "last_update_ts",
    STATUS = "status",
    TYPE = "type",
    ERROR_CODE = "error_code",
    ERROR_MESSAGE = "error_message",
    STORAGE_ID = "storage_id",
    PARTNER_ID = "partner_id",
    STORAGE = "storage",
    TABLE = "table",
    DIMENSION_INDEXES = "dimension_indexes",
    STORAGE_FILTER = "storage_filter",
    SEGMENT_FILTER = "segment_filter",
    COLUMN_FILTER = "column_filter"
}
