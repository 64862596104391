import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {CmpConfigLanguage} from "../../config/CmpConfigLanguage";
import {SirdataListLanguageField} from "./SirdataListLanguageField";
import {SirdataListPurpose} from "./SirdataListPurpose";
import {SirdataListStack} from "./SirdataListStack";
import {detectChanges} from "../../../../../common/utils/portal";

export class SirdataListLanguage extends ApiModel {
    language: string = CmpConfigLanguage.ENGLISH.name;
    tcfPolicyVersion: number = 0;
    lastUpdated: string = "";
    purposes: SirdataListPurpose[] = [];
    stacks: SirdataListStack[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) {
            return;
        }
        super.load(o);
        this.loadObjects(SirdataListLanguageField.PURPOSES, SirdataListPurpose);
        this.loadObjects(SirdataListLanguageField.STACKS, SirdataListStack);
    }

    getPurposesJson(): { [key: string]: object } | undefined {
        if (!this.purposes) {
            return undefined;
        }
        let purposesJson: { [key: string]: object } = {};
        this.purposes.forEach((purpose) => {
            purposesJson[purpose.id] = purpose.getJsonParameters();
        });

        return purposesJson;
    }

    getStacksJson(): { [key: string]: object } | undefined {
        if (!this.stacks) {
            return undefined;
        }
        let stacksJson: { [key: string]: object } = {};
        this.stacks.forEach((stack) => {
            stacksJson[stack.id] = stack.getJsonParameters();
        });

        return stacksJson;
    }

    getJsonParameters(): {} {
        return {
            language: this.language,
            tcfPolicyVersion: this.tcfPolicyVersion,
            lastUpdated: this.lastUpdated,
            purposes: this.getPurposesJson(),
            stacks: this.getStacksJson()
        };
    }

    isValid(): boolean {
        return !(this.purposes.some((it) => !it.isValid()) || this.stacks.some((it) => !it.isValid()));
    }

    isAlike(listLanguage: SirdataListLanguage): boolean {
        if (listLanguage.tcfPolicyVersion !== this.tcfPolicyVersion) {
            return false;
        }

        if (listLanguage.purposes.length !== this.purposes.length
            || listLanguage.stacks.length !== this.stacks.length) {
            return false;
        }

        for (let purpose of listLanguage.purposes) {
            const item = this.purposes.find((it) => it.id === purpose.id);
            if (!item) {
                return false;
            }
        }

        for (let stack of listLanguage.stacks) {
            const item = this.stacks.find((it) => it.id === stack.id);
            if (!item) {
                return false;
            }

            if (detectChanges(stack.purposes, item.purposes)
                || detectChanges(stack.extraPurposes, item.extraPurposes)
                || detectChanges(stack.specialFeatures, item.specialFeatures)
                || detectChanges(stack.requiredPurposes, item.requiredPurposes)) {
                return false;
            }
        }

        return true;
    }
}
