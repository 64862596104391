export enum VendorField {
    ID = "id",
    NAME = "name",
    PURPOSES = "purposes",
    LEG_INT_PURPOSES = "legIntPurposes",
    FLEXIBLE_PURPOSES = "flexiblePurposes",
    SPECIAL_PURPOSES = "specialPurposes",
    FEATURES = "features",
    SPECIAL_FEATURES = "specialFeatures",
    POLICY_URL = "policyUrl",
    DELETED_DATE = "deletedDate",
    USES_COOKIES = "usesCookies",
    COOKIE_MAX_AGE_SECONDS = "cookieMaxAgeSeconds",
    COOKIE_REFRESH = "cookieRefresh",
    USES_NON_COOKIE_ACCESS = "usesNonCookieAccess",
    DEVICE_STORAGE_DISCLOSURE_URL = "deviceStorageDisclosureUrl"
}
