export class CmpConfigLanguage {
    static BULGARIAN: CmpConfigLanguage = new CmpConfigLanguage("bg");
    static CZECH: CmpConfigLanguage = new CmpConfigLanguage("cs");
    static DANISH: CmpConfigLanguage = new CmpConfigLanguage("da");
    static DUTCH: CmpConfigLanguage = new CmpConfigLanguage("nl");
    static ENGLISH: CmpConfigLanguage = new CmpConfigLanguage("en");
    static FRENCH: CmpConfigLanguage = new CmpConfigLanguage("fr");
    static GERMAN: CmpConfigLanguage = new CmpConfigLanguage("de");
    static GREEK: CmpConfigLanguage = new CmpConfigLanguage("el");
    static HUNGARIAN: CmpConfigLanguage = new CmpConfigLanguage("hu");
    static ITALIAN: CmpConfigLanguage = new CmpConfigLanguage("it");
    static NORWEGIAN: CmpConfigLanguage = new CmpConfigLanguage("no");
    static POLISH: CmpConfigLanguage = new CmpConfigLanguage("pl");
    static PORTUGUESE: CmpConfigLanguage = new CmpConfigLanguage("pt");
    static PORTUGUESE_BRAZIL: CmpConfigLanguage = new CmpConfigLanguage("pt-BR");
    static PORTUGUESE_PORTUGAL: CmpConfigLanguage = new CmpConfigLanguage("pt-PT");
    static ROMANIAN: CmpConfigLanguage = new CmpConfigLanguage("ro");
    static SPANISH: CmpConfigLanguage = new CmpConfigLanguage("es");
    static SWEDISH: CmpConfigLanguage = new CmpConfigLanguage("sv");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CmpConfigLanguage[] {
        return [
            CmpConfigLanguage.BULGARIAN,
            CmpConfigLanguage.CZECH,
            CmpConfigLanguage.DANISH,
            CmpConfigLanguage.DUTCH,
            CmpConfigLanguage.ENGLISH,
            CmpConfigLanguage.FRENCH,
            CmpConfigLanguage.GERMAN,
            CmpConfigLanguage.GREEK,
            CmpConfigLanguage.HUNGARIAN,
            CmpConfigLanguage.ITALIAN,
            CmpConfigLanguage.NORWEGIAN,
            CmpConfigLanguage.POLISH,
            CmpConfigLanguage.PORTUGUESE,
            CmpConfigLanguage.PORTUGUESE_BRAZIL,
            CmpConfigLanguage.PORTUGUESE_PORTUGAL,
            CmpConfigLanguage.ROMANIAN,
            CmpConfigLanguage.SPANISH,
            CmpConfigLanguage.SWEDISH
        ];
    }

    static getByName(name: string): CmpConfigLanguage {
        return this.values().find((it) => it.name === name) || CmpConfigLanguage.ENGLISH;
    }
}
