export class PartnerBillingPaymentMethod {
    static MONTHLY: PartnerBillingPaymentMethod = new PartnerBillingPaymentMethod("monthly");
    static ANNUAL: PartnerBillingPaymentMethod = new PartnerBillingPaymentMethod("annual");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): PartnerBillingPaymentMethod[] {
        return [
            PartnerBillingPaymentMethod.MONTHLY,
            PartnerBillingPaymentMethod.ANNUAL
        ];
    }

    static getByName(name: string): PartnerBillingPaymentMethod | undefined {
        return this.values().find((it) => it.name === name);
    }
}
