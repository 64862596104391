import React, {FC, useEffect, useState} from "react";
import {SelectAutocomplete} from "@sirdata/ui-lib";
import {session} from "../../../api/ApiSession";
import {CategoryGroup} from "../../../api/model/audience/category/CategoryGroup";
import {CategoryTaxonomy} from "../../../api/model/audience/category/CategoryTaxonomy";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import useAlert from "../../../utils/hooks/useAlert";

type SelectCategoryGroupProps = {
    value: number | undefined;
    onChange: ((categoryGroup?: CategoryGroup) => void) | undefined;
    taxonomy?: CategoryTaxonomy;
    excludedCategoryGroups?: CategoryGroup[];
    onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
    onInvalid?: (e: React.FormEvent<HTMLInputElement>) => void;
    small?: boolean;
    disabled?: boolean;
};

const SelectCategoryGroup: FC<SelectCategoryGroupProps> = ({value, onChange, taxonomy, excludedCategoryGroups, ...rest}) => {
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [categoryGroups, setCategoryGroups] = useState<CategoryGroup[]>([]);
    const [filteredCategoryGroups, setFilteredCategoryGroups] = useState<CategoryGroup[]>([]);

    useEffect(() => {
        (async () => {
            try {
                let newCategoryGroups = await session.getCategoryGroups();
                newCategoryGroups = newCategoryGroups.filter((it) => !taxonomy || it.taxonomy === taxonomy.name);
                setCategoryGroups(newCategoryGroups);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("category groups", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [taxonomy, alert]);

    useEffect(() => {
        if (!!excludedCategoryGroups?.length) {
            const newFilteredCategoryGroups = categoryGroups.filter((categoryGroup) => !excludedCategoryGroups?.some((it) => it.id === categoryGroup.id));
            setFilteredCategoryGroups(newFilteredCategoryGroups);
        } else {
            setFilteredCategoryGroups(categoryGroups);
        }
    }, [categoryGroups, excludedCategoryGroups]);

    return (
        <SelectAutocomplete
            value={value}
            options={filteredCategoryGroups.map((it) => ({
                value: it.id,
                label: `${it.id} - ${it.fullName}`,
                categoryGroup: it
            }))}
            onChange={(option) => onChange && onChange(option?.categoryGroup)}
            isLoading={isLoading}
            clearable
            {...rest}
        />
    );
};

export default SelectCategoryGroup;
