import {Rest} from "../../common/api/rest/Rest";
import {CmpCloudflareRequest} from "../model/cmp/cloudflare/CmpCloudflareRequest";

export class RestCmpCloudflare extends Rest {
    rootPath = "/console-api/cmp/cloudflare";

    flush(request: CmpCloudflareRequest) {
        return this._client.post(`${this.rootPath}`, request);
    }
}
