import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CategorizerConfigUrlCategorizationExtractionMode} from "./CategorizerConfigUrlCategorizationExtractionMode";

export class CategorizerConfigUrlCategorizationExtraction extends ApiModel {
    mode: string = CategorizerConfigUrlCategorizationExtractionMode.DOMAIN.name;
    conf?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            mode: this.mode,
            conf: this.conf
        };
    }
}
