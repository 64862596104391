import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {TranslationPortalFile} from "../../../utils/constants";
import {Tag} from "../index";

type TagCategoryTypeProps = {
    type: CategoryType | undefined;
};

const TagCategoryType: FunctionComponent<TagCategoryTypeProps> = ({type}) => {
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);

    if (!type) return (<></>);

    return (
        <Tag
            label={textCategories(`category_type.${type.name}`)}
            icon={type.icon}
            customColor={{
                backgroundColor: type.backgroundColor,
                color: type.textColor,
                borderColor: type.borderColor
            }}
        />
    );
};

export default TagCategoryType;
