import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {User} from "../../api/model/user/User";
import {UserSearchQuery} from "../../api/model/user/UserSearchQuery";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import ModalCreateUser from "../../component/modal/users/ModalCreateUser";
import {MainContentHeader, MainContentHeaderAction, SelectStatus, UserRow} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {Status} from "../../utils/Status";
import useSearch from "../../utils/hooks/useSearch";
import {UserField} from "../../api/model/user/UserField";
import {UserPropertiesField} from "../../api/model/user/UserPropertiesField";
import useAlert from "../../utils/hooks/useAlert";

function Users() {
    const {t: textUsers} = useTranslation(TranslationPortalFile.USERS);
    const [isLoading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);
    const [isShowModalCreation, setShowModalCreation] = useState(false);
    const {buildSearchResult, ...search} = useSearch(User, UserSearchQuery);
    const alert = useAlert();

    useEffect(() => {
        (async function () {
            try {
                const result = await session.restUser.list();
                setUsers(result);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("users", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        let currentUsers = (users || []);
        if (search.searchQuery.query) {
            currentUsers = currentUsers.filter(({id, name, email}) => `${id} ${name} ${email}`.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        if (!!search.searchQuery.status) {
            currentUsers = currentUsers.filter((it) => it.active === (search.searchQuery.status === Status.ACTIVE.name));
        }
        buildSearchResult(currentUsers);
    }, [users, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.USERS}>
                <RestrictedContent allowedTo={Authorization.USERS.update}>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreation(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textUsers("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters={true}
                        >
                            <SearchField label={textUsers("field.status")}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textUsers("field.status"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 10, label: textUsers(`field.${UserField.ID}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: UserField.ID}},
                                        {width: 25, label: textUsers(`field.${UserField.NAME}`), sort: {field: UserField.NAME}},
                                        {width: 25, label: textUsers(`field.${UserField.EMAIL}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM, sort: {field: UserField.EMAIL}},
                                        {width: 20, label: textUsers(`field.${UserField.PROPERTIES}.${UserPropertiesField.TEAM}`)},
                                        {width: 20, label: textUsers(`field.${UserField.POSITION}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((user: User) =>
                                        <UserRow key={user.id} user={user}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreateUser active={isShowModalCreation} onClose={() => setShowModalCreation(false)}/>
            </MainContent>
        </Wrapper>
    );
}

export default Users;
