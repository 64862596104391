import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {SirdataListVersion} from "./SirdataListVersion";
import {SirdataListEntityState} from "./SirdataListEntityState";
import {detectChanges} from "../../../../../common/utils/portal";

export class SirdataListPurpose extends ApiModel {
    id: number = 0;
    name: string = "";
    description: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            name: this.name,
            description: this.description
        };
    }

    isValid(): boolean {
        return !(!this.name || !this.description);
    }

    getState(latestVersion: SirdataListVersion): SirdataListEntityState | undefined {
        const purpose = latestVersion.purposes.find((it) => it.id === this.id);
        if (!purpose) {
            return SirdataListEntityState.ADDED;
        } else if (detectChanges(purpose, this)) {
            return SirdataListEntityState.UPDATED;
        }
    }
}
