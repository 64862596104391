export enum JiraIssueField {
    PROJECT_ID = "project_id",
    ISSUETYPE_ID = "issuetype_id",
    PRIORITY_ID = "priority_id",
    REPORTER_ID = "reporter_id",
    SUMMARY = "summary",
    DESCRIPTION = "description",
    SCOPE = "scope",
    BUSINESS_IMPACT_ID = "business_impact_id"
}
