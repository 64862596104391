import {Action, ContentBlockAction, LayoutRows, Loadable} from "@sirdata/ui-lib";
import React, {useCallback, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {CategorizerConfigEntry} from "../../api/model/categorizer/CategorizerConfigEntry";
import {CategorizerConfigEntryField} from "../../api/model/categorizer/CategorizerConfigEntryField";
import {CategorizerConfigEntryGroup} from "../../api/model/categorizer/CategorizerConfigEntryGroup";
import {CategorizerConfigEntryParams} from "../../api/model/categorizer/CategorizerConfigEntryParams";
import {CategorizerConfigType} from "../../api/model/categorizer/CategorizerConfigType";
import {CategorizerConfig} from "../../api/model/categorizer/config/CategorizerConfig";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {MainContentHeader} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {CategorizerConfigEntriesProps} from "../../utils/categorizer/CategorizerConfigEntriesProps";

function CategorizerDetails() {
    const {t: textCategorizer} = useTranslation(TranslationPortalFile.CATEGORIZER);
    const alert = useAlert();

    const [searchParams] = useSearchParams();
    const [params, setParams] = useState<CategorizerConfigEntryParams>({domain: ""});
    const [entries, setEntries] = useState<Map<string, CategorizerConfigEntry<CategorizerConfig>[]>>(new Map<string, CategorizerConfigEntry<CategorizerConfig>[]>());

    const [isLoading, setLoading] = useState(true);
    const [activeDeleteEntry, setActiveDeleteEntry] = useState<CategorizerConfigEntry<CategorizerConfig>>();

    const DEMO_URL = "http://demo.sirddata.com";

    const loadConfigs = useCallback(async () => {
        if (!params.domain) return;
        try {
            const result = await session.restCategorizerDomain.getEntryGroup(params.domain);
            const entryGroup = result.find((it) => it.domain === params.domain && it.host === params.host && it.path === params.path);
            if (entryGroup) {
                setEntries(entryGroup.configs);
            }
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("domain configurations", e.message);
            }
        } finally {
            setLoading(false);
        }
    }, [params, alert]);

    useEffect(() => {
        setParams({
            domain: searchParams.get(CategorizerConfigEntryField.DOMAIN) || "",
            host: searchParams.get(CategorizerConfigEntryField.HOST) || undefined,
            path: searchParams.get(CategorizerConfigEntryField.PATH) || undefined
        });
    }, [searchParams]);

    useEffect(() => {
        (async () => {
            await loadConfigs();
        })();
    }, [params, loadConfigs]);

    const handleDeleteEntry = async () => {
        if (!activeDeleteEntry) return;
        try {
            await session.restCategorizerEntry.delete(activeDeleteEntry);
            alert.deleteWithSuccess("configuration");
            await loadConfigs();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("configuration", e.message);
            }
        } finally {
            setActiveDeleteEntry(undefined);
        }
    };

    const getComponent = (configType?: CategorizerConfigType) => {
        if (!configType?.component) return;

        const props: CategorizerConfigEntriesProps<any> = {
            configs: entries.get(configType.name) || [],
            params: params,
            onSubmit: loadConfigs,
            onDelete: setActiveDeleteEntry
        };
        return React.createElement(configType.component, props);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CATEGORIZER} element={CategorizerConfigEntryGroup.buildFromSearchParams(searchParams).toContentElement()}>
                <ContentBlockAction
                    action={Action.OPEN}
                    label={"demo.sirddata.com"}
                    onClick={() => window.open(DEMO_URL, "_blank")}
                />
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        {CategorizerConfigType.values().map((configType) =>
                            <React.Fragment key={configType.name}>
                                {getComponent(configType)}
                            </React.Fragment>
                        )}
                    </Loadable>
                </LayoutRows>
                <ModalConfirmDelete
                    active={!!activeDeleteEntry}
                    entity={textCategorizer(`config_type.${activeDeleteEntry?.type}`) + " configuration"}
                    confirm={handleDeleteEntry}
                    cancel={() => setActiveDeleteEntry(undefined)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default CategorizerDetails;
