export class CategorizerConfigPrivacyMode {
    static SAFE: CategorizerConfigPrivacyMode = new CategorizerConfigPrivacyMode("safe");
    static UNSAFE: CategorizerConfigPrivacyMode = new CategorizerConfigPrivacyMode("unsafe");
    static DISABLED: CategorizerConfigPrivacyMode = new CategorizerConfigPrivacyMode("disabled");

    name: string;

    private constructor(name: string) {
        this.name = name;
    }

    static values(): CategorizerConfigPrivacyMode[] {
        return [
            CategorizerConfigPrivacyMode.SAFE,
            CategorizerConfigPrivacyMode.UNSAFE,
            CategorizerConfigPrivacyMode.DISABLED
        ];
    }
}
