import React, {FunctionComponent} from "react";
import {StatusLabel} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {Status} from "../../../utils/Status";

export type LabelStatusProps = {
    status: Status;
};

const LabelStatus: FunctionComponent<LabelStatusProps> = ({status}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);

    return (
        <StatusLabel icon={status.icon} label={t(`status.${status.name}`)}/>
    );
};

export default LabelStatus;
