import {ApiModel} from "../../../../../common/api/model/ApiModel";

export class CmpConfigPublisherCustomPurposeVendor extends ApiModel {
    name: string = "";
    policyUrl: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            policyUrl: this.policyUrl
        };
    }
}
