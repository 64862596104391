import {CmpUIVersion} from "./CmpUIVersion";

export class CmpConfigVersion {
    static V3: CmpConfigVersion = new CmpConfigVersion("3", CmpUIVersion.GDPR_V3, CmpUIVersion.CCPA_V3);
    static DEFAULT = this.V3;

    name: string;
    gdprUIVersion: CmpUIVersion;
    ccpaUIVersion: CmpUIVersion;

    private constructor(name: string, gdprUIVersion: CmpUIVersion, ccpaUIVersion: CmpUIVersion) {
        this.name = name;
        this.gdprUIVersion = gdprUIVersion;
        this.ccpaUIVersion = ccpaUIVersion;
    }

    get label() {
        return `v${this.name}`;
    }

    static values(): CmpConfigVersion[] {
        return [
            CmpConfigVersion.V3
        ];
    }

    static getByName(name: string): CmpConfigVersion | undefined {
        return this.values().find((it) => it.name === name);
    }
}
